import React, {useState} from 'react';
import {Grid, Input, MenuItem, Select, Typography} from '@mui/material';
import {ArrowDropDown, Close, Search} from '@mui/icons-material';
import {ButtonComponent} from '../../components/mui-button';

const FiltroMotoristas = ({handleFiltrar}) => {
  const [showFiltros, setShowFiltros] = useState(false);
  const [filtros, setFiltros] = useState({});
  const [search, setSearch] = useState('');

  const changeShowFiltros = () => {
    setShowFiltros(!showFiltros);
  };

  const onCancel = () => {
    limparFiltros();
    setShowFiltros(false);
  };

  const onFiltrar = () => {
    const keysFiltered = Object.keys(filtros).filter(key => key !== 'nomeMotorista' && filtros[key]);
    const search = keysFiltered?.length > 0 ? keysFiltered
      .map(key => `${key}=${filtros[key]}`)
      .reduce((acc, valor) => `${acc} | ${valor}`) : '';

    handleFiltrar(filtros);
    setSearch(search);
    setShowFiltros(false);
  };

  const limparFiltros = () => {
    setFiltros(undefined);
    setSearch('');
    handleFiltrar({});
  };

  const _handleKeyUp = ({key}) => {
    if (key === 'Enter') {
      onFiltrar();
    }
  }

  return (
    <Grid sx={styles.filtroInputContainer} id="filtroMotoristaSearch" style={showFiltros ? {backgroundColor: '#fff', borderRadius: '6px 6px 0 0', boxShadow: '0px 1px 2px rgb(0 0 0 / 10%), 0px 8px 16px rgb(0 0 0 / 20%)'} : null}>
      <Search sx={styles.filtroSearchIcon} onClick={onFiltrar} />
      <Input
        sx={styles.filtroInput}
        value={filtros?.nomeMotorista || ''}
        placeholder={search}
        name="nome"
        onChange={({target}) => setFiltros(prev => ({...prev, nomeMotorista: target.value}))}
        onKeyUp={_handleKeyUp}
      />
      {(filtros?.nomeMotorista || search) && (
        <Close sx={styles.filtroCloseIcon} onClick={limparFiltros} />
      )}
      <ArrowDropDown sx={styles.filtroDropIcon} style={showFiltros ? {transform: 'rotate(180deg)'} : null} onClick={changeShowFiltros} />
      <Grid container sx={styles.filtrosModal} style={showFiltros ? null : {display: 'none'}}>
        <Grid item xs={12} sx={styles.filtrosModalHeader}>
          <Typography sx={styles.filtrarLabel}>Filtrar</Typography>
          <Typography sx={styles.redefinirLabel} onClick={limparFiltros}>Redefinir filtros</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={styles.inputLabel}>Status do Anúncio</Typography>
            <Select
              value={filtros?.anuncioSituacao || ''}
              sx={styles.filtroSelectInput}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    padding: '12px'
                  }
                }
              }}
              inputProps={{
                sx: {
                  padding: '6px'
                }
              }}
              displayEmpty
              onChange={({target}) => setFiltros(prev => ({...prev, anuncioSituacao: target.value || null}))}
            >
              <MenuItem value="">Todas</MenuItem>
              <MenuItem value="ATIVO">Ativo</MenuItem>
              <MenuItem value="AGUARDANDO_REMOCAO">Aguardando remoção</MenuItem>
              <MenuItem value="FINALIZADO">Finalizado</MenuItem>
              <MenuItem value="SUSPENSO">Suspenso</MenuItem>
              <MenuItem value="AGUARDANDO_IMPRESSAO">Aguardando impressão</MenuItem>
              <MenuItem value="AGUARDANDO_INSTALACAO">Aguardando instalação</MenuItem>
              <MenuItem value="AGUARDANDO_AGENDAMENTO_INSTALACAO">Aguardando agendamento da instalação</MenuItem>
              <MenuItem value="AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO">Aguardando aceite do agendamento da instalação</MenuItem>
              <MenuItem value="AGUARDANDO_TROCA_MOTORISTA">Aguardando troca de motorista</MenuItem>
              <MenuItem value="AGUARDANDO_ACEITE_MOTORISTA">Aguardando aceite do motorista</MenuItem>
              <MenuItem value="AGUARDANDO_IMAGEM_INSTALACAO">Aguardando imagem de instalação</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6} style={{paddingRight: 8}}>
            <Typography sx={styles.inputLabel}>Marca</Typography>
            <Input
              sx={styles.filtroModalInput}
              value={filtros?.nomeMarcaVeiculo || ''}
              name="nomeMarcaVeiculo"
              onChange={({target}) => setFiltros(prev => ({...prev, nomeMarcaVeiculo: target.value}))}
            />
          </Grid>
          <Grid item xs={6} style={{paddingLeft: 8}}>
            <Typography sx={styles.inputLabel}>Modelo</Typography>
            <Input
              sx={styles.filtroModalInput}
              value={filtros?.nomeVeiculo || ''}
              name="nomeVeiculo"
              onChange={({target}) => setFiltros(prev => ({...prev, nomeVeiculo: target.value}))}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} pr="4px">
            <ButtonComponent sx={styles.cancelButton} onClick={onCancel} value="Cancelar" />
          </Grid>
          <Grid item xs={6} pl="4px">
            <ButtonComponent sx={styles.filtrarButton} onClick={onFiltrar} value="Filtrar" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  filtroInputContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    height: '48px',
    width: '40%',
    maxWidth: '500px',
    borderRadius: '8px',
    background: '#F2F2F2',
  },
  filtroSearchIcon: {
    color: '#868686',
    width: '44px',
    height: '44px',
    padding: '10px',
    transition: 'background-color 800ms',
    borderRadius: '16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#efecec'
    },
    '&:active': {
      backgroundColor: '#a0a0a0'
    }
  },
  filtroCloseIcon: {
    color: '#868686',
    width: 22,
    height: 22,
  },
  filtroDropIcon: {
    color: '#868686',
    width: 44,
    height: 44,
    padding: '10px',
    cursor: 'pointer',
    transition: 'transform 300ms',
  },
  filtroInput: {
    height: 48,
    width: '100%',
    color: '#868686',
    fontSize: 14,
    background: 'none',
    '&::after': {
      borderBottom: 'none !important'
    },
    '&::before': {
      borderBottom: 'none !important'
    },
  },
  filtrosModal: {
    position: 'absolute',
    width: '100%',
    height: 300,
    background: '#fff',
    top: 50,
    zIndex: 10,
    borderRadius: '0 0 6px 6px',
    padding: '24px',
    paddingBottom: 0,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)',
  },
  filtrarLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#5F6368',
  },
  redefinirLabel: {
    fontSize: 14,
    textDecorationLine: 'underline',
    color: '#404040',
    cursor: 'pointer'
  },
  inputLabel: {
    fontSize: 14,
    color: '#868686'
  },
  filtroSelectInput: {
    width: '100%',
    border: 'none',
    color: '#333',
    fontSize: 16,
    height: 36,
    paddingRight: '16px',
    '> fieldset': {
      border: 'none',
      borderBottom: '1px solid #868686',
      borderWidth: '1px !important',
      borderRadius: '0'
    },
  },
  filtroModalInput: {
    width: '100%',
    border: 'none',
    color: '#333',
    fontSize: 16,
    height: 36,
    paddingRight: '16px',
    '&::after': {
      borderBottom: '1px solid #868686',
    },
    '&::before': {
      borderBottom: '1px solid #868686',
    },
  },
  filtrosModalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '30px'
  },
  cancelButton: {
    backgroundColor: '#fff',
    color: '#505050',
    fontWeight: '500',
    border: 'none',
    '&:hover': {
      background: '#0001',
      boxShadow: 'none'
    }
  },
  filtrarButton: {
    backgroundColor: '#0083E6',
    color: '#fff',
    fontWeight: '500',
    borderRadius: '6px'
  }
};

export default FiltroMotoristas;