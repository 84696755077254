import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_BY_ID_OFICINA} from '../../graphql/queries';
import FormularioAgencia from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarOficina = () => {
  const {oficinaId} = useParams();

  const oficinaQuery = useQuery(FIND_BY_ID_OFICINA, {
    variables: {
      id: oficinaId,
    },
  });

  const {data = {}} = oficinaQuery;
  const {oficina = {}} = data;

  const refetch = () => {
    oficinaQuery.refetch();
  };

  if (oficinaQuery.loading || oficinaQuery.error) {
    return (
      <LoadingPage loading={oficinaQuery.loading} error={oficinaQuery.error} />
    );
  }

  return (
    <FormularioAgencia oficina={oficina} refetch={refetch} />
  );
};

export default EditarOficina;
