import {useLazyQuery, useMutation} from '@apollo/client';
import {Box, Grid, Paper, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';
import ImagemCrop from '../../components/crop-image';
import ImagemContainer from '../../components/imagem-container';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../components/mui-button';
import {toastNotification} from '../../components/toastify';
import {auncioImagemSituacao} from '../../constants/enum-labels';
import {ModalWithInput} from '../../containers/modais/aviso-modal/modais-aviso';
import {
  ALTERAR_IMAGENS_VALIDACAO,
  APROVAR_IMAGENS_INSTALACAO,
  APROVAR_IMAGENS_MENSAL,
  APROVAR_IMAGENS_REMOCAO,
  APROVAR_MOVIMENTACAO_MOTORISTA,
  REPROVAR_IMAGENS,
  REPROVAR_MOVIMENTACAO_MOTORISTA,
  REVERTER_IMAGEM_ANUNCIO,
} from '../../graphql/mutation';
import {
  FIND_VALOR_MOVIMENTACAO_MOTORISTA,
  FIND_VALOR_ODOMETRO_ANTERIOR,
} from '../../graphql/queries';
import {uploadAmazonS3} from '../../services/UploadService';
import {LIGHT_COLORS} from '../../theme/colors/mui-colors';
import Data from '../../utils/data';
import Number from '../../utils/number';

const FormularioValidacaoImagens = ({
  anuncio = {},
  imagensDoVeiculo = {},
  refetch,
}) => {
  const [salvando, setSalvando] = useState(false);

  const [imagemOdometro, setImagemOdometro] = useState(
    imagensDoVeiculo.imagemOdometro,
  );
  const [imagemDiagonalDireitaFrontal, setImagemDiagonalDireita] = useState(
    imagensDoVeiculo.imagemDiagonalDireitaFrontal,
  );
  const [imagemDiagonalEsquerdaTraseira, setImagemDiagonalEsquerda] = useState(
    imagensDoVeiculo.imagemDiagonalEsquerdaTraseira,
  );
  const [imagemEsquerda, setImagemEsquerda] = useState(
    imagensDoVeiculo.imagemLateralEsquerda,
  );
  const [imagemDireita, setImagemDireita] = useState(
    imagensDoVeiculo.imagemLateralDireita,
  );
  const [imagemFrente, setImagemFrente] = useState(
    imagensDoVeiculo.imagemFrente,
  );
  const [imagemInterna, setImagemInterna] = useState(
    imagensDoVeiculo.imagemInterna,
  );
  const [imagemTraseira, setImagemTraseira] = useState(
    imagensDoVeiculo.imagemTraseira,
  );
  const [valorOdometro, setValorOdometro] = useState(
    imagensDoVeiculo.valorOdometro || undefined,
  );
  const [valorOdometroAnterior, setValorOdometroAnterior] = useState('');
  const [imagemSelecionada, setImagemSelecionada] = useState(null);
  const [openModalConfirmPagamento, setOpenModalConfirmPagamento] =
    useState(false);
  const [motoristaPagamento, setMotoristaPagamento] = useState(
    anuncio?.campanha?.valorMensalMotorista || 0,
  );
  const [imagemModal, setImagemModal] = useState({
    visible: false,
    id: null,
    titulo: '',
  });

  const [aprovarImagensInstalacao] = useMutation(APROVAR_IMAGENS_INSTALACAO);
  const [aprovarImagensRemocao] = useMutation(APROVAR_IMAGENS_REMOCAO);
  const [aprovarImagensMensal] = useMutation(APROVAR_IMAGENS_MENSAL);
  const [aprovarMovimentacaoMotorista] = useMutation(
    APROVAR_MOVIMENTACAO_MOTORISTA,
  );
  const [reprovarMovimentacaoMotorista] = useMutation(
    REPROVAR_MOVIMENTACAO_MOTORISTA,
  );
  const [reprovarImagens] = useMutation(REPROVAR_IMAGENS);
  const [alterarImagens] = useMutation(ALTERAR_IMAGENS_VALIDACAO);
  const [reverterImagens] = useMutation(REVERTER_IMAGEM_ANUNCIO);

  const [loadOdometroAnterior, valorOdometroAnteriorQuery] = useLazyQuery(
    FIND_VALOR_ODOMETRO_ANTERIOR,
  );
  const [loadValorMovimentacao, loadValorMovimentacaoQuery] = useLazyQuery(
    FIND_VALOR_MOVIMENTACAO_MOTORISTA,
  );

  useEffect(() => {
    if (!imagensDoVeiculo?.id || imagensDoVeiculo.tipo === 'INSTALACAO') return;

    loadOdometroAnterior({
      variables: {
        id: imagensDoVeiculo.id,
      },
    });

    if (imagensDoVeiculo.situacao !== 'PENDENTE') return;

    loadValorMovimentacao({
      variables: {
        imagem: {
          id: imagensDoVeiculo.id,
        },
      },
    });
  }, [imagensDoVeiculo, loadOdometroAnterior, loadValorMovimentacao]);

  useEffect(() => {
    if (valorOdometroAnteriorQuery.loading) return;

    setValorOdometroAnterior(valorOdometroAnteriorQuery?.data?.valor || '');
  }, [valorOdometroAnteriorQuery]);

  useEffect(() => {
    if (loadValorMovimentacaoQuery.loading) return;

    setMotoristaPagamento(
      loadValorMovimentacaoQuery?.data?.valor ||
        anuncio?.campanha?.valorMensalMotorista ||
        0,
    );
  }, [loadValorMovimentacaoQuery, anuncio]);

  function handleClickImage(fotoId, titulo = '', name) {
    setImagemModal({
      visible: !!fotoId,
      id: fotoId,
      titulo,
      name,
    });

    setImagemSelecionada(name);
  }

  const handleFotoUpload = (id) => {
    switch (imagemSelecionada) {
      case 'imagemLateralEsquerda':
        return setImagemEsquerda(id);
      case 'imagemLateralDireita':
        return setImagemDireita(id);
      case 'imagemDiagonalEsquerdaTraseira':
        return setImagemDiagonalEsquerda(id);
      case 'imagemDiagonalDireitaFrontal':
        return setImagemDiagonalDireita(id);
      case 'imagemInterna':
        return setImagemInterna(id);
      case 'imagemFrente':
        return setImagemFrente(id);
      case 'imagemTraseira':
        return setImagemTraseira(id);
      case 'imagemOdometro':
        return setImagemOdometro(id);
      default:
        break;
    }

    setImagemSelecionada(null);
  };

  const uploadFotos = async (file) => {
    let uploadData;
    let idsUpload = {};
    try {
      uploadData = await uploadAmazonS3(file);
      idsUpload = {[imagemSelecionada]: uploadData.id};
    } catch (err) {
      toastNotification({
        message: 'Não foi possível enviar as imangens',
        type: 'error',
      });
      return;
    }

    setImagemModal({visible: false, id: null, titulo: '', name: ''});

    alterarImagens({
      variables: {
        anuncioImagem: {
          id: imagensDoVeiculo.id,
          ...idsUpload,
        },
      },
    })
      .then(() => handleFotoUpload(uploadData.id))
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  async function handleAprovar() {
    try {
      setSalvando(true);

      if (imagensDoVeiculo.tipo === 'MENSAL') {
        await aprovarImagensMensal({
          variables: {
            anuncio: {
              id: anuncio.id,
            },
            imagensMensal: {
              id: imagensDoVeiculo.id,
              valorOdometro: valorOdometro || null,
            },
          },
        });
      }

      if (imagensDoVeiculo.tipo === 'INSTALACAO') {
        await aprovarImagensInstalacao({
          variables: {
            anuncio: {
              id: anuncio.id,
            },
            imagensInstalacao: {
              id: imagensDoVeiculo.id,
              valorOdometro: valorOdometro || null,
            },
          },
        });
      }

      if (imagensDoVeiculo.tipo === 'REMOCAO') {
        await aprovarImagensRemocao({
          variables: {
            anuncio: {
              id: anuncio.id,
            },
            imagensRemocao: {
              id: imagensDoVeiculo.id,
            },
          },
        });
      }
      toastNotification({
        message: 'As imagens foram aprovadas!',
        type: 'success',
      });
      refetch && refetch();
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setSalvando(false);
    }
  }

  const handleClickAprovar = () => {
    if (!valorOdometro) {
      return toastNotification({
        message: 'Valor odômetro é obrigatório',
        type: 'error',
      });
    }

    if (imagensDoVeiculo.tipo !== 'MENSAL') {
      confirmAlertMui({
        title: 'Confimar aprovação das imagens?',
        onConfirm: handleAprovar,
      });
      return;
    }

    confirmAlertMui({
      title: 'O valor está correto?',
      message: `Total no período ${Number.format(
        Number.stringToFloat(valorOdometro) - valorOdometroAnterior,
      )} km (de ${anuncio.kmTotalPorMes} km)`,
      onConfirm: handleClickAprovarMotoristaPagamento,
    });
  };

  async function handleClickAprovarMotoristaPagamento() {
    try {
      setSalvando(true);

      if (imagensDoVeiculo.tipo === 'MENSAL') {
        await aprovarImagensMensal({
          variables: {
            anuncio: {
              id: anuncio.id,
            },
            imagensMensal: {
              id: imagensDoVeiculo.id,
              valorOdometro: valorOdometro || null,
            },
          },
        });
      }

      toastNotification({
        message: 'As imagens foram aprovadas!',
        type: 'success',
      });
      refetch && refetch();

      setOpenModalConfirmPagamento(true);
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setSalvando(false);
    }
  }

  async function handleClickReprovar() {
    confirmAlertMui({
      title: 'Deseja mesmo reprovar as imagens?',
      onConfirm: handleReprovar,
    });
  }

  async function handleReprovar() {
    setSalvando(true);
    try {
      await reprovarImagens({
        variables: {
          anuncio: {
            id: anuncio.id,
          },
          imagens: {
            id: imagensDoVeiculo.id,
          },
        },
      });

      toastNotification({
        message: 'As imagens foram reprovadas!',
        type: 'success',
      });
      refetch && refetch();
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    }
    setSalvando(false);
  }
  async function handleAprovarPagamento() {
    setSalvando(true);
    try {
      await aprovarMovimentacaoMotorista({
        variables: {
          imagem: {
            id: imagensDoVeiculo.id,
          },
          valor: Number.currencyToFloat(motoristaPagamento),
        },
      });

      toastNotification({
        message: 'O pagamento foi aprovado!',
        type: 'success',
      });
      setOpenModalConfirmPagamento(false);
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    }
    setSalvando(false);
  }
  async function handleReprovarPagamento() {
    setSalvando(true);
    try {
      await reprovarMovimentacaoMotorista({
        variables: {
          imagem: {
            id: imagensDoVeiculo.id,
          },
        },
      });
      toastNotification({
        message: 'O pagamento foi reprovado!',
        type: 'success',
      });
      setOpenModalConfirmPagamento(false);
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    }
    setSalvando(false);
  }

  async function handleClickReverter() {
    confirmAlertMui({
      title: 'Deseja mesmo reverter as imagens?',
      onConfirm: handleReverter,
    });
  }

  async function handleReverter() {
    setSalvando(true);
    try {
      await reverterImagens({
        variables: {
          anuncioImagem: {
            id: imagensDoVeiculo.id,
          },
        },
      });

      setValorOdometro('');
      toastNotification({
        message: 'A reversão foi concluída!',
        type: 'success',
      });
      refetch && refetch();
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    }
    setSalvando(false);
  }

  const isMotoristaSubstituido =
    anuncio.motorista?.id !== imagensDoVeiculo.motorista?.id;

  const montarVeiculo = () => {
    return (
      `${imagensDoVeiculo.veiculo?.modelo?.marca?.nome || ''} ` +
      `${imagensDoVeiculo.veiculo?.modelo?.nome || ''} ` +
      `[${imagensDoVeiculo.veiculo?.cor || ''}] - ${
        imagensDoVeiculo.veiculo?.placa || ' '
      }`
    );
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputV2
              disabled
              label="Motorista"
              value={
                imagensDoVeiculo.motorista?.nome +
                (isMotoristaSubstituido ? `  (${anuncio.motorista?.nome})` : '')
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputV2 disabled label="Veículo" value={montarVeiculo()} />
          </Grid>
          <Grid item xs={3}>
            <InputV2 disabled label="Tipo" value={imagensDoVeiculo.tipo} />
          </Grid>
          <Grid item xs={2}>
            <InputV2
              disabled
              label="Data de Envio"
              value={Data.dataFormat(imagensDoVeiculo.data, 'DD/MM/YYYY')}
            />
          </Grid>
          {imagensDoVeiculo?.situacao !== 'PENDENTE' && (
            <Grid item xs={2}>
              <InputV2
                disabled
                label="Data Confirmação"
                value={Data.dataFormat(
                  imagensDoVeiculo.dataConfirmacao,
                  'DD/MM/YYYY',
                )}
              />
            </Grid>
          )}
          {imagensDoVeiculo?.situacao !== 'PENDENTE' && (
            <Grid item xs={3}>
              <InputV2
                disabled
                label="Usuário"
                value={imagensDoVeiculo?.usuarioConfirmou?.nome || ''}
              />
            </Grid>
          )}
          {imagensDoVeiculo?.situacao !== 'PENDENTE' && (
            <Grid item xs={2}>
              <TextField
                sx={
                  imagensDoVeiculo?.situacao === 'APROVADO'
                    ? styles.situacaoAprovado
                    : styles.situacaoReprovado
                }
                disabled
                label="Situação"
                value={auncioImagemSituacao[imagensDoVeiculo?.situacao]}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <InputV2
              disabled
              label="Localização"
              value={`${imagensDoVeiculo.latitude}, ${imagensDoVeiculo.longitude}`}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              {imagemEsquerda && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    image={imagemEsquerda}
                    name={'imagemLateralEsquerda'}
                    label={'Lateral esquerda'}
                    onClickImage={handleClickImage}
                    onSetImagem={setImagemEsquerda}
                  />
                </Box>
              )}
              {imagemDireita && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    image={imagemDireita}
                    name={'imagemLateralDireita'}
                    label={'Lateral direita'}
                    onClickImage={handleClickImage}
                    onSetImagem={setImagemDireita}
                  />
                </Box>
              )}
              {imagemTraseira && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    image={imagemTraseira}
                    name={'imagemTraseira'}
                    label={'Traseira'}
                    onClickImage={handleClickImage}
                    onSetImagem={setImagemTraseira}
                  />
                </Box>
              )}
              {imagemFrente && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    image={imagemFrente}
                    name={'imagemFrente'}
                    label={'Frente'}
                    onClickImage={handleClickImage}
                    onSetImagem={setImagemFrente}
                  />
                </Box>
              )}
              {imagemDiagonalDireitaFrontal && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    image={imagemDiagonalDireitaFrontal}
                    name={'imagemDiagonalDireitaFrontal'}
                    label={'Diagonal direita'}
                    onClickImage={handleClickImage}
                    onSetImagem={setImagemDiagonalDireita}
                  />
                </Box>
              )}
              {imagemDiagonalEsquerdaTraseira && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    image={imagemDiagonalEsquerdaTraseira}
                    name={'imagemDiagonalEsquerdaTraseira'}
                    label={'Diagonal esquerda'}
                    onClickImage={handleClickImage}
                    onSetImagem={setImagemDiagonalEsquerda}
                  />
                </Box>
              )}
              {imagemInterna && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    image={imagemInterna}
                    name={'imagemInterna'}
                    label={'Mídia interna'}
                    onClickImage={handleClickImage}
                    onSetImagem={setImagemInterna}
                  />
                </Box>
              )}
              <Box sx={styles.imagemColumn}>
                <ImagemContainer
                  image={imagemOdometro}
                  name={'imagemOdometro'}
                  label={'Odômetro'}
                  onClickImage={handleClickImage}
                  onSetImagem={setImagemOdometro}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={2} justifyContent={'flex-end'}>
                {imagensDoVeiculo.tipo === 'MENSAL' && (
                  <Grid item xs={2}>
                    <InputV2
                      label="Valor Odômetro Anterior"
                      value={Number.format(valorOdometroAnterior)}
                      disabled
                    />
                  </Grid>
                )}
                <Grid item xs={2}>
                  <InputV2
                    label="Valor Odômetro"
                    value={valorOdometro}
                    onChange={(e) => {
                      setValorOdometro(e.target.value);
                    }}
                    disabled={imagensDoVeiculo?.situacao !== 'PENDENTE'}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={2}>
                {imagensDoVeiculo?.situacao === 'PENDENTE' && (
                  <Grid item xs={12}>
                    <Box>
                      <Grid
                        container
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <ButtonComponent
                          id="button-cancel"
                          sx={styles.buttons}
                          value={'Reprovar'}
                          loading={salvando}
                          onClick={handleClickReprovar}
                          disabled={salvando}
                        />
                        <ButtonComponent
                          id="button-green"
                          value={'Aprovar'}
                          loading={salvando}
                          onClick={handleClickAprovar}
                          disabled={salvando}
                          sx={styles.buttons}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                )}
                {imagensDoVeiculo?.situacao !== 'PENDENTE' && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}>
                    <ButtonComponent
                      value={'Reverter'}
                      loading={salvando}
                      disabled={salvando}
                      onClick={handleClickReverter}
                      sx={styles.buttons}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <ModalWithInput
        openModal={openModalConfirmPagamento}
        input
        titulo="Aprovar pagamento?"
        label="Valor"
        styleTitle={{
          fontFamily: (theme) => theme.typography.fontFamily.patternBold,
          textAlign: 'center',
        }}
        onCancel={handleReprovarPagamento}
        onClose={() => null}
        onClick={handleAprovarPagamento}
        inputValue={motoristaPagamento}
        mask="moeda"
        ButtonPrimary="Reprovar"
        ButtonSecundary="Aprovar"
        inputOnChange={(e) => setMotoristaPagamento(e)}
        loadingButton={salvando}
        idButtonSecundary="button-green"
        idButtonPrimary="button-cancel"
        width="300px"
      />
      <ImagemCrop
        open={!!imagemModal.visible}
        onClose={() => setImagemModal({visible: false, id: null, titulo: ''})}
        imagemId={imagemModal.id}
        onUploadImage={uploadFotos}
        disabled={imagensDoVeiculo?.situacao !== 'PENDENTE'}
      />
    </Grid>
  );
};

const styles = {
  buttons: {
    width: '256px',
  },
  imagemColumn: {
    padding: '0 10px',
    width: '-webkit-fill-available',
  },
  situacaoAprovado: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& label': {
      color: `${LIGHT_COLORS.green} !important`,
    },
    '& input': {
      '-webkit-text-fill-color': `${LIGHT_COLORS.green} !important`,
    },
    '& fieldset': {
      borderColor: `${LIGHT_COLORS.green} !important`,
    },
  },
  situacaoReprovado: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& label': {
      color: `${LIGHT_COLORS.cancel} !important`,
    },
    '& input': {
      '-webkit-text-fill-color': `${LIGHT_COLORS.cancel} !important`,
    },
    '& fieldset': {
      borderColor: `${LIGHT_COLORS.cancel} !important`,
    },
  },
};

export default FormularioValidacaoImagens;
