import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';

const store = initialState =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState
  });

export default store;
