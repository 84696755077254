import React from 'react';
import {Routes, Route} from 'react-router-dom';
import CadastrarMarcaVeiculo from './cadastrar';
import ConsultaMarcaVeiculo from './consulta';
import EditarMarcaVeiculo from './editar';

const MarcaVeiculo = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaMarcaVeiculo />} />
      <Route path={`/:marcaId/editar`} element={<EditarMarcaVeiculo />} />
      <Route path={`/criar`} element={<CadastrarMarcaVeiculo />} />
    </Routes>
  );
};

export default MarcaVeiculo;
