import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaCidades from './consulta';
import EditarCidade from './editar';

const Cidades = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaCidades />} />
      <Route path={`/:cidadeId/editar`} element={<EditarCidade />} />
    </Routes>
  );
};

export default Cidades;
