import React, {useEffect, useState} from 'react';
import {Paper, Grid, Box, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TablePagination} from '@mui/material';
import {WhatsApp} from '@mui/icons-material';
import {useQuery} from '@apollo/client';
import moment from 'moment';
import {FIND_ANUNCIOS_PARADOS_A_MAIS_DE} from '../graphql/queries';
import InputV2 from '../components/input-v2/mui-input-v2';
import TableCellOrdered from '../components/table-cell-ordered';
import String from '../utils/string';
import Number from '../utils/number';
import {toastNotification} from '../components/toastify';

const columns = [
  {id: 'campanhaTitulo', label: 'Campanha'},
  {id: 'nome', label: 'Nome do motorista', campo: 'motorista.nome'},
  {id: 'celular', label: 'Celular do motorista', campo: 'motorista.celular'},
  {id: 'cidadeAtuacao', label: 'Cidade de atuação'},
  {id: 'kmTotal', label: 'Km / período'},
  {id: 'quantidadeDeDiasParados', label: 'Dias parados', campo: 'localizacaoAtual.data'},
];
const whatsIcon = {
  color: '#1ebea5',
  width: '20px ',
  height: '20px ',
  marginLeft: 1,
  marginBottom: -1,
  marginTop: -9,
};

const AnunciosParados = ({pageSize = 20}) => {
  const [quantidadeDeDias, setQuantidadeDeDias] = useState(2);
  const [anuncios, setAnuncios] = useState([]);
  const [orderBy, setOrderBy] = useState({
    campo: 'localizacaoAtual.data',
    asc: true,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(pageSize);

  const anunciosParadosQuery = useQuery(FIND_ANUNCIOS_PARADOS_A_MAIS_DE, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      quantidadeDeDias: Number.safeParseInt(quantidadeDeDias) || 1,
    },
  });

  useEffect(() => {
    if (anunciosParadosQuery.loading || anunciosParadosQuery.error) return;

    setAnuncios(
      anunciosParadosQuery.data?.findAnunciosParadosAMaisDe?.content || [],
    );
  }, [anunciosParadosQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Paper id="filtro-paper">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <InputV2
                label="Parados há pelo menos"
                endAdornment="dias"
                value={quantidadeDeDias}
                onChange={({target}) => {
                  setQuantidadeDeDias(target.value);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anuncios.map((anuncio) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={anuncio.id}
                        sx={{cursor: 'default'}}>
                        <TableCell key={'campanha'}>
                          {anuncio.campanha.titulo}
                        </TableCell>
                        <TableCell key={'motorista'}>
                          {anuncio.motorista.nome}
                        </TableCell>
                        <TableCell
                          key={'celular'}
                          onClick={() => {
                            toastNotification({
                              message: 'Copiado para área de transferência!',
                              type: 'success',
                            });
                            navigator.clipboard.writeText(
                              anuncio.motorista.celular,
                            );
                          }}
                          style={{cursor: 'pointer'}}>
                          {String.formatCelular(
                            anuncio.motorista?.celular,
                          ).replace('+55', '')}
                          <WhatsApp
                            sx={whatsIcon}
                            onClick={() =>
                              window.open(
                                `https://api.whatsapp.com/send?phone=${anuncio.motorista.celular}`,
                              )
                            }
                          />
                        </TableCell>
                        <TableCell key={'cidade'}>
                          {`${anuncio.motorista.cidadeAtuacao?.nome}/${anuncio.motorista.cidadeAtuacao?.uf}`}
                        </TableCell>
                        <TableCell key={'kmPeriodo'}>
                          {`${Number.formatQuilometro(
                            anuncio?.kmRodadoMes,
                          )} / ${Number.formatQuilometro(anuncio?.kmTotalPorMes)}`}
                        </TableCell>
                        <TableCell key={'dias'}>
                          {moment().diff(
                            moment(anuncio.localizacaoAtual?.data),
                            'days',
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={
                anunciosParadosQuery.data?.findAnunciosParadosAMaisDe
                  ?.totalElements || 0
              }
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnunciosParados;
