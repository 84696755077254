import React from 'react';
import FormularioInstalador from './formulario';

const CadastrarInstalador = ({role}) => {
  return (
    <FormularioInstalador role={role} />
  );
};

export default CadastrarInstalador;
