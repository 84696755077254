import React from 'react';
import {Box, Typography} from '@mui/material';
import File from '../utils/file';
import {CameraAlt} from '@mui/icons-material';

const ImagemContainer = ({image, name, label, onSetImagem, onClickImage}) => {
  const isBase64 = image?.includes('base64');

  return (
    <>
      <Typography sx={styles.label} className="disabled">
        {label}
      </Typography>
      <Box sx={styles.fotoContainer}>
        {image ? (
          <img
            style={styles.fotoImg}
            src={isBase64 ? image : File.getImageAuth(image)}
            alt={label}
            onClick={() => onClickImage(image, label, name)}
            onError={() => onSetImagem(null)}
          />
        ) : (
          <Box
            style={{textAlign: 'center'}}
            onClick={() => onClickImage(image, label, name)}>
            <CameraAlt />
            <p>Nenhuma foto disponível</p>
          </Box>
        )}
      </Box>
    </>
  );
};
const styles = {
  fotoContainer: {
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(207, 212, 223, 0.2)',
    border: ' 1px solid #afb7c9',
    borderRadius: '6px',
    marginBottom: '10px',

    '& svg': {
      color: '#8995b1',
    },

    ' & p': {
      color: '#8995b1',
      fontSize: '13px',
      marginBottom: 'px',
      textAlign: 'center',
    },
  },
  fotoImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
  },
  label: {
    display: 'block',
    color: '#8995b1',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '3px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};


export default ImagemContainer;
