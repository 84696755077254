import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik, Field} from 'formik';
import {useMutation} from '@apollo/client';
import {Paper, Grid, Box} from '@mui/material';
import {ButtonComponent} from '../../components/mui-button';
import {
  CRIAR_MODELO_VEICULO,
  ATUALIZAR_MODELO_VEICULO,
} from '../../graphql/mutation';
import {SelectMarcasVeiculo} from '../../containers/selects/mui-marca-veiculo';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {toastNotification} from '../../components/toastify';

const FormularioModeloVeiculo = ({modelo = {}}) => {
  const [modeloVeiculo, setModeloVeiculo] = useState(modelo);
  const [criarModelo] = useMutation(CRIAR_MODELO_VEICULO);
  const [atualizarModelo] = useMutation(ATUALIZAR_MODELO_VEICULO);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  async function handleSubmitDadosModelo() {
    try {
      setSubmitting(true);
      if (modeloVeiculo.id) {
        await atualizarModelo({
          variables: {
            modelo: {
              id: modeloVeiculo.id,
              nome: modeloVeiculo.nome,
              marca: {
                id: modeloVeiculo.marca.id,
              },
            },
          },
        });
      } else {
        await criarModelo({
          variables: {
            modelo: {
              nome: modeloVeiculo.nome,
              marca: {
                id: modeloVeiculo.marca.id,
              },
            },
          },
        });
      }

      toastNotification({message: 'Modelo cadastrado!', type: 'success'});
      navigate('/modelo-veiculo');
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          validationSchema={ModeloScheme}
          initialValues={{
            nome: modeloVeiculo.nome || '',
            marca: modeloVeiculo.marca || null,
          }}
          enableReinitialize>
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputV2
                  label="Modelo"
                  value={modeloVeiculo.nome}
                  onChange={(e) =>
                    setModeloVeiculo({...modeloVeiculo, nome: e.target.value})
                  }
                  className="input-filtro"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={SelectMarcasVeiculo}
                  label="Marca"
                  name="marca"
                  className="select"
                  isClearable
                  value={modeloVeiculo.marca}
                  onChange={(e) => {
                    setModeloVeiculo({...modeloVeiculo, marca: e});
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <ButtonComponent
                  type="button"
                  value={'Salvar'}
                  sx={{width: '256px'}}
                  onClick={handleSubmitDadosModelo}
                  loading={submitting}
                  disabled={submitting}
                />
              </Grid>
            </Grid>
          </Box>
        </Formik>
      </Paper>
    </Grid>
  );
};

const ModeloScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  marca: Yup.string().required('Campo obrigatório'),
});

export default FormularioModeloVeiculo;
