import React, {useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';
import {styled} from '@mui/styles';
import {Formik, Field} from 'formik';
import {useMutation} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import UploadableFotoPerfil from '../../components/mui-uploadable-foto-perfil';
import Data from '../../utils/data';
import {DELETE_ANUNCIANTE} from '../../graphql/mutation';
import {toastNotification} from '../../components/toastify';
import {ButtonComponent} from '../../components/mui-button';
import LottieIcon from '../../components/lottie-icon';
import {DeleteForever} from '@mui/icons-material';
import {LoadingCircle} from '../../assets/lotties';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';

const FormularioCadastroAnunciante = ({anunciante = {}, refetch}) => {
  const navigate = useNavigate();

  const [previewImage] = useState(null);
  const [fotoPerfil, setFotoPerfil] = useState(anunciante.fotoPerfil);

  const [deleteAnunciante, {loading: excluindo}] = useMutation(DELETE_ANUNCIANTE);

  const handleClickDeleteAnunciante = () => {
    confirmAlertMui({
      message: 'Deseja realmente excluir o anunciante?',
      onConfirm: () => handleDeleteAnunciante(),
      onCancel: () => null,
    });
  };

  const handleDeleteAnunciante = () => {
    deleteAnunciante({
      variables: {
        anunciante: {
          id: anunciante.id,
        },
      },
    })
      .then(() => {
        navigate('/anunciantes');
        toastNotification({message: 'Excluído!', type: 'success'});
      })
      .catch(error => toastNotification({message: error.message, type: 'error'}));
  };

  return (
    <Box>
      <Formik
        initialValues={{
          nomeRazao: anunciante.nomeRazao || '',
          nomeFantasia: anunciante.nomeFantasia || '',
          cnpj: anunciante.cnpj || '',
          inscricaoEstadual: anunciante.inscricaoEstadual || '',
          dataCadastro: Data.dataFormat(anunciante.dataCadastro),
          cep: anunciante.endereco?.cep || '',
          segmentoAtuacao: anunciante.segmentoAtuacao || '',
          cidade: anunciante.endereco?.cidade,
          cidadeNome: anunciante.endereco?.cidade?.nomeEUf || '',
          logradouro: anunciante.endereco?.logradouro || '',
          bairro: anunciante.endereco?.bairro || '',
          numero: anunciante.endereco?.numero || '',
          complemento: anunciante.endereco?.complemento || '',
          nomeContato: anunciante.nomeContato || '',
          telefone: anunciante.telefone || '',
          email: anunciante?.email || anunciante.usuario?.email || '',
          comercial: anunciante.comercial,
          comercialNome: anunciante.comercial?.nomeFantasia || '',
          agencia: anunciante.agencia,
          agenciaNome: anunciante.agencia?.nomeFantasia || '',
          executivo: anunciante.executivo,
          executivoNome: anunciante.executivo?.nome || '',
          celular: anunciante.celular || '',
          site: anunciante.site || '',
          financeiroNome: anunciante.financeiroNome || '',
          financeiroEmail: anunciante.financeiroEmail || '',
          financeiroTelefone: anunciante.financeiroTelefone || '',
          financeiroNomeAdicional: anunciante.financeiroNomeAdicional || '',
          financeiroTelefoneAdicional:
            anunciante.financeiroTelefoneAdicional || '',
          financeiroEmailAdicional: anunciante.financeiroEmailAdicional || '',
          marketingNome: anunciante.marketingNome || '',
          marketingEmail: anunciante.marketingEmail || '',
          marketingTelefone: anunciante.marketingTelefone || '',
          marketingNomeAdicional: anunciante.marketingNomeAdicional || '',
          marketingTelefoneAdicional:
            anunciante.marketingTelefoneAdicional || '',
          marketingEmailAdicional: anunciante.marketingEmailAdicional || '',
          emailUsuario: anunciante.usuario?.email || '',
        }}
        enableReinitialize>
        {({values, isSubmitting, handleSubmit, setFieldValue}) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <UploadableFotoPerfil
                  label="Foto perfil"
                  image={previewImage || fotoPerfil}
                  setImage={setFotoPerfil}
                  readOnly
                />
              </Grid>
              <Grid item xs={10}>
                <Box marginTop="23px" marginBottom="36px">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        component={InputV2Formik}
                        name="nomeRazao"
                        label="Razão Social"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={InputV2Formik}
                        name="nomeFantasia"
                        label="Nome Fantasia"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Field
                        component={InputV2Formik}
                        name="cnpj"
                        label="CNPJ"
                        mask="cnpj"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        component={InputV2Formik}
                        name="inscricaoEstadual"
                        label="Insrição estadual"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={InputV2Formik}
                        name="dataCadastro"
                        label="Data cadastro"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="telefone"
                  label="Telefone / Empresa"
                  mask="phone"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="email"
                  label="E-mail"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="site"
                  label="Site"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" id="formulario-subtitle">
                  Endereço
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Field
                  component={InputV2Formik}
                  name="logradouro"
                  label="Logradouro"
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={InputV2Formik}
                  name="numero"
                  label="Número"
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={InputV2Formik}
                  name="complemento"
                  label="Complemento"
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={InputV2Formik}
                  name="bairro"
                  label="Bairro"
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={InputV2Formik}
                  name="cidadeNome"
                  label="Cidade"
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={InputV2Formik}
                  name="cep"
                  label="CEP"
                  mask="cep"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" id="formulario-subtitle">
                  Marketing
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="marketingNome"
                  label="Contato / Marketing 1"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="marketingTelefone"
                  label="Telefone"
                  mask="phone"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="marketingEmail"
                  label="E-mail"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="marketingNomeAdicional"
                  label="Contato / Marketing 2"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="marketingTelefoneAdicional"
                  label="Telefone"
                  mask="phone"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="marketingEmailAdicional"
                  label="E-mail"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" id="formulario-subtitle">
                  Financeiro
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="financeiroNome"
                  label="Contato / Financeiro 1"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="financeiroTelefone"
                  label="Telefone"
                  mask="phone"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="financeiroEmail"
                  label="E-mail"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="financeiroNomeAdicional"
                  label="Contato / Financeiro 2"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="financeiroTelefoneAdicional"
                  label="Telefone"
                  mask="phone"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="financeiroEmailAdicional"
                  label="E-mail"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" id="formulario-subtitle">
                  Usuário
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={InputV2Formik}
                  name="emailUsuario"
                  label="Usuário email"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sx={styles.buttonContainer} style={{marginRight: 'auto'}}>
                <ButtonComponent
                  type="button"
                  value="Excluir"
                  icon={
                    excluindo ? (
                      <LottieIcon icon={LoadingCircle} />
                    ) : (
                      <DeleteForever />
                    )
                  }
                  onClick={handleClickDeleteAnunciante}
                  style={{background: 'red'}}
                  sx={styles.buttonWrapper}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const Form = styled('form')(() => { });

const styles = {
  buttonContainer: {
    marginTop: 'auto !important',
    paddingTop: '36px !important'
  },
  buttonWrapper: {
    width: '256px',
  },
}

export default FormularioCadastroAnunciante;
