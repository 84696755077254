import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {Box, Grid, Paper} from '@mui/material';
import {Delete, Edit, Save} from '@mui/icons-material';
import {ButtonComponent} from '../../components/mui-button';
import {
  DELETE_SEGMENTO_ATUACAO,
  UPDATE_SEGMENTO_ATUACAO,
} from '../../graphql/mutation';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {toastNotification} from '../../components/toastify';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';

const FormularioSegmento = ({segmento = {}}) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState(segmento);
  const [readOnly, setReadOnly] = useState(!!segmento.id);
  const [updateSegmento, {loading}] = useMutation(UPDATE_SEGMENTO_ATUACAO);
  const [deleteSegmento, {loading: deleteLoading}] = useMutation(
    DELETE_SEGMENTO_ATUACAO,
  );

  const handleSubmitDados = () => {
    if (!formData.descricao) {
      toastNotification({
        message: 'Descrição é um campo obrigatório!',
        type: 'warning',
      });
      return;
    }

    updateSegmento({
      variables: {
        segmento: {
          id: segmento?.id || undefined,
          descricao: formData.descricao,
        },
      },
    })
      .then(({data}) => {
        toastNotification({
          message: 'O segmento de atuação foi salvo!',
          type: 'success',
        });
        setReadOnly(true);
        navigate(`/segmento-atuacao/${data.segmento.id}/editar`);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleClickDelete = () => {
    confirmAlertMui({
      message: 'Deseja mesmo exluir este segmento?',
      onConfirm: handleDelete,
    });
  };

  const handleDelete = () => {
    if (!segmento.id) return;

    deleteSegmento({
      variables: {
        segmento: {
          id: segmento.id,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'O segmento de atuação foi excluído',
          type: 'success',
        });
        navigate(`/segmento-atuacao`);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Box component="form" onSubmit={handleSubmitDados}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputV2
                label="Descrição"
                value={formData.descricao}
                onChange={({target}) =>
                  setFormData((f) => ({...f, descricao: target.value}))
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="space-between">
              {segmento?.id && (
                <ButtonComponent
                  id="button-cancel"
                  type="button"
                  value={'Excluir'}
                  loading={deleteLoading}
                  icon={<Delete />}
                  onClick={handleClickDelete}
                  sx={styles.button}
                  disabled={!readOnly || deleteLoading}
                />
              )}
              {readOnly ? (
                <ButtonComponent
                  type="button"
                  value={'Editar'}
                  icon={<Edit />}
                  sx={styles.button}
                  onClick={() => setReadOnly(false)}
                />
              ) : (
                <ButtonComponent
                  type="button"
                  value={'Salvar'}
                  loading={loading}
                  icon={<Save />}
                  onClick={handleSubmitDados}
                  sx={styles.button}
                  disabled={loading}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

const styles = {
  button: {
    width: '271px',
  },
}

export default FormularioSegmento;
