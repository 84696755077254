import React from 'react';
import {Grid, Typography} from '@mui/material';
import moment from 'moment';
import styles from '../styles';
import Number from '../../../utils/number';
import {movimentacaoSituacao} from '../../../constants/enum-labels';

const DetalhesInstalador = ({dados = {}}) => {
  return (
    <Grid sx={styles.detalhesContainer} className={`styled-scroll`}>
      <Typography sx={styles.blockHeader}>
        Dados lançamento
      </Typography>
      <Grid container sx={styles.blockContainer}>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Comercial</Typography>
          <Typography sx={styles.inputValue} >
            {dados.campanha?.comercial?.nomeRazaoResponsavel || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Anunciante</Typography>
          <Typography sx={styles.inputValue} >
            {dados.campanha?.anunciante?.nomeFantasia || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Campanha</Typography>
          <Typography sx={styles.inputValue} >
            {`[${dados.campanha?.numero}] ${dados.campanha?.titulo}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Modelo anúncio</Typography>
          <Typography sx={styles.inputValue} >
            {dados.campanha?.modeloAnuncio?.nome || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Período</Typography>
          <Typography sx={styles.inputValue} >
            {dados.dataInicioPeriodo && dados.dataFimPeriodo
              ? `${moment(dados.dataInicioPeriodo).format('DD/MM/YYYY')} - ${moment(dados.dataFimPeriodo).format('DD/MM/YYYY')}`
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Data Lançamento</Typography>
          <Typography sx={styles.inputValue} >
            {dados && dados.dataLcto
              ? moment(dados.dataLcto).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Vencimento</Typography>
          <Typography sx={styles.inputValue} >
            {dados && dados.dataPrevisaoPgto
              ? moment(dados.dataPrevisaoPgto).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography sx={styles.label} >Tipo</Typography>
          <Typography sx={styles.inputValue} >
            {dados && dados.tipo ? dados.tipo : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Valor</Typography>
          <Typography sx={styles.inputValue} >
            {dados && dados.valor
              ? Number.currencyFormat(dados.valor)
              : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Typography sx={styles.blockHeader}>
        {dados?.tipo === 'CREDITO' ?
          'Dados pagador' : 'Dados recebedor'}
      </Typography>
      <Grid container sx={styles.blockContainer}>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Nome do Recebedor</Typography>
          <Typography sx={styles.inputValue} >
            {dados.instalador?.nome || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Motorista</Typography>
          <Typography sx={styles.inputValue} >
            {dados.anuncio?.motorista?.nome || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Veículo</Typography>
          <Typography sx={styles.inputValue} >
            {dados.anuncio
              ? `${dados.anuncio.veiculoModelo} ${dados.anuncio.veiculoAnoFabricacao}/${dados.anuncio.veiculoAnoModelo}`
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label} >Placa do Veículo</Typography>
          <Typography sx={styles.inputValue} >
            {dados.anuncio?.veiculoPlaca || '-'}
          </Typography>
        </Grid>
      </Grid>
      {['FINALIZADO', 'REPROVADO'].includes(dados.situacao) && (
        <>
          <Typography sx={styles.blockHeader}>
            Dados pagamento
          </Typography>
          <Grid>
            <Grid container sx={styles.blockContainer}>
              <Grid item xs={2}>
                <Typography sx={styles.label} >Data pagamento</Typography>
                <Typography sx={styles.inputValue} >
                  {dados.dataPgto ? moment(dados.dataPgto).format('DD/MM/YYYY') : ''}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.label}>Situação</Typography>
                <Typography sx={styles.inputValue}>
                  {movimentacaoSituacao[dados.situacao]}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.label} >
                  {dados.tipo === 'CREDITO' ? 'Valor Recebido' : 'Valor Pago'}
                </Typography>
                <Typography sx={styles.inputValue} >
                  {dados.valorPago ? Number.currencyFormat(dados.valorPago) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label} >Código do Comprovante</Typography>
                <Typography sx={styles.inputValue} >{dados.codigoComprovante || ''}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.label} >Usuário</Typography>
                <Typography sx={styles.inputValue} >{dados.usuarioBaixa?.nome || ''}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label} >Observação</Typography>
                <Typography sx={styles.inputValue} >{dados.obs || ''}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>)
      }
    </Grid>
  );
}

export default DetalhesInstalador;