import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  cidade: [],
  filtrosCidade: {
    filtros: {},
    page: 0,
    orderBy: {campo: 'nome', asc: true},
  },
  loadingAtualizar: false,
  hasMore: false,
  totalCidade: 0,
  refetchCidade: false,
};

const acaoAtualizarCidade = (state) => {
  state.refetchCidade = true;
};

const acaoPersistCidade = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.cidade = content || [] ;
  state.hasMore = !last;
  state.totalCidade = totalElements || 0;
};

const acaoPersistFiltrosCidade = (state, action) => {
  state.filtrosCidade = action.payload;
};

const acaoPersistLoadingAtualizar = (state, action) => {
  state.loadingAtualizar = action.payload;
};

const resumeSlice = createSlice({
  name: 'cidade',
  initialState: initialState,
  reducers: {
    persistCidade: acaoPersistCidade,
    atualizarCidade: acaoAtualizarCidade,
    persistFiltrosCidade: acaoPersistFiltrosCidade,
    persistLoadingAtualizar: acaoPersistLoadingAtualizar,
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistCidade, atualizarCidade, persistFiltrosCidade, persistLoadingAtualizar} = actions;
