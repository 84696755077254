import {FormControl, InputLabel, FormHelperText, } from '@mui/material';
import React from 'react';
import InputV2 from '../input-v2/mui-input-v2';
import Select from 'react-select';
import styled from '@emotion/styled';

const MultiSelect = ({
  label,
  className,
  mensagemSuporte,
  value,
  disabled,
  inputAdornment,
  ...props
}) => {
  if (disabled) {
    return (
      <InputV2
        label={label}
        value={value?.label}
        disabled
        {...props}
        shrink={value ? true : undefined}
      />
    );
  }

  return (
    <FormControl
      fullWidth
      style={{marginBottom: '12px'}}
      className={'select-input'}
      size="small"
    >
      <InputLabel
        variant="outlined"
        htmlFor="input-v2"
        size="small"
        id="input-label-v2"
        shrink={value ? true : undefined}
        sx={styles.label}
        className={`${props.errors ? 'error' : ''}`}
      >
        {label}
      </InputLabel>
      <SelectStyled
        id="input-v2"
        className={`${value === 0 || value ? 'withValue' : ''}${props.errors ? '-error' : ''}`}
        value={value || ''}
        label={label}
        notched={value ? true : undefined}
        classNamePrefix="react-select"
        {...props}
      />
      {props.errors && (
        <FormHelperText
          sx={{color: (theme) => theme.palette.cancel.main}}
          id="support-message-id"
        >
          {mensagemSuporte}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const SelectStyled = styled(Select)`
  .react-select__value-container--is-multi {
    padding: 5px 8px 2px 8px !important;
  }
  .react-select__control {
    border-color: #0083e6;
    &:hover {
      border-color: #8995b1;
    }

    ${(props) => {
      if (props.errors) {
        return `
          border: 1px solid #ff5252 !important;
        `;
      }
    }}
  }
  .react-select__control--is-focused {
    border: 1px solid #8995b1;
  }
  .react-select__menu {
    z-index: 999;
  }
`;

const styles = {
  label: {
    background: '#fff',
    padding: '0 8px',
    marginLeft: '-4px'
  },
}

export default React.memo(MultiSelect);
