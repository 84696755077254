import React from 'react';
import moment from 'moment';
import {makeStyles} from '@mui/styles';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Number from '../../utils/number';

import 'moment/locale/pt-br';

const Divisor = require('../../assets/images/divisor-relatorio.png');

const ImpressaoRelatorioCampanhasVendidas = ({campanhas = [], periodo}) => {
  const classes = useStyles();

  const totalVeiculos = campanhas
    .map((campanha) => campanha?.veiculos)
    .reduce((acc = 0, value) => acc + parseFloat(value), 0);
  const valorTotalBruto = campanhas
    .map(({precoBruto}) => precoBruto)
    .reduce((acc = 0, value) => acc + parseFloat(value), 0);
  const valorTotalAgencia = campanhas
    .map((campanha) => campanha?.valorAgencia)
    .reduce((acc = 0, value) => acc + parseFloat(value), 0);
  const valorTotalComercial = campanhas
    .map((campanha) => campanha?.valorTerceiros)
    .reduce((acc = 0, value) => acc + parseFloat(value), 0);
  const valorTotalLiquido = campanhas
    .map(({precoLiquido}) => precoLiquido)
    .reduce((acc = 0, value) => acc + parseFloat(value), 0);
  const totalCidades = campanhas
    .map(({cidades}) => cidades)
    .reduce((acc = 0, value) => acc + parseFloat(value), 0);

  return (
    <Grid item xs={12} className={classes.reportContainer}>
      <Grid item xs={12} style={{margin: '0 10px'}}>
        <img
          alt={'divisor'}
          style={{width: '100%', margin: '5px 0'}}
          src={Divisor}
        />
      </Grid>
      <Grid item xs={12} className={classes.reportHeader}>
        <Grid item xs={10}>
          <Typography
            className={classes.titleReport}
            style={{fontWeight: '700'}}>
            Campanhas vendidas
          </Typography>
          <Typography className={classes.titleReport}>
            {periodo
              ? `Período: ${moment(periodo.inicio).format(
                  'MMMM/YYYY',
                )} - ${moment(periodo.fim).format('MMMM/YYYY')}`
              : ' - '}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <KmMidiaLogo style={{width: '80%'}} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{fontWeight: '700'}}>
              <TableCell className={classes.tableHead}>Nº</TableCell>
              <TableCell className={classes.tableHead}>Anunciante</TableCell>
              <TableCell className={classes.tableHead}>Campanha</TableCell>
              <TableCell className={classes.tableHead}>Cidades</TableCell>
              <TableCell className={classes.tableHead}>Veículos</TableCell>
              <TableCell
                className={classes.tableHead}
                align="right"
                style={{whiteSpace: 'nowrap'}}>
                V. veículo
              </TableCell>
              <TableCell className={classes.tableHead} align="right">
                T. bruto
              </TableCell>
              <TableCell className={classes.tableHead} align="right">
                Agência
              </TableCell>
              <TableCell className={classes.tableHead} align="right">
                Terceiros
              </TableCell>
              <TableCell className={classes.tableHead} align="right">
                T. líquido
              </TableCell>
              <TableCell className={classes.tableHead} align="right">
                V. veículo líquido
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campanhas.map((item, index) => (
              <TableRow tabIndex={-1} key={`item${index}`}>
                <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                <TableCell className={classes.tableCell}>
                  {item.nomeFantasia}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.titulo}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.cidades}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.veiculos}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{whiteSpace: 'nowrap'}}>
                  {Number.currencyFormat(item.valorVeiculo)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{whiteSpace: 'nowrap'}}>
                  {Number.currencyFormat(item.precoBruto)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{whiteSpace: 'nowrap'}}>
                  {Number.currencyFormat(item.valorAgencia)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{whiteSpace: 'nowrap'}}>
                  {Number.currencyFormat(item.valorTerceiros)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{whiteSpace: 'nowrap'}}>
                  {Number.currencyFormat(item.precoLiquido)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{whiteSpace: 'nowrap'}}>
                  {Number.currencyFormat(item.valorVeiculoLiquido)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow tabIndex={-1}>
              <TableCell className={classes.tableHead} style={{maxWidth: 5}}>
                Total
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell className={classes.tableHead}>
                {totalCidades}
              </TableCell>
              <TableCell className={classes.tableHead}>
                {totalVeiculos}
              </TableCell>
              <TableCell />
              <TableCell
                className={classes.tableHead}
                align="right"
                style={{whiteSpace: 'nowrap'}}>
                {Number.currencyFormat(valorTotalBruto)}
              </TableCell>
              <TableCell
                className={classes.tableHead}
                align="right"
                style={{whiteSpace: 'nowrap'}}>
                {Number.currencyFormat(valorTotalAgencia)}
              </TableCell>
              <TableCell
                className={classes.tableHead}
                align="right"
                style={{whiteSpace: 'nowrap'}}>
                {Number.currencyFormat(valorTotalComercial)}
              </TableCell>
              <TableCell
                className={classes.tableHead}
                align="right"
                style={{whiteSpace: 'nowrap'}}>
                {Number.currencyFormat(valorTotalLiquido)}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell style={{whiteSpace: 'nowrap', border: 'none'}}>
                <Typography className={classes.footerText} style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid
        item
        xs={12}
        className={`${classes.footer} page-footer`}
        style={{display: 'none'}}>
        <Grid item xs={12} className={classes.footerLogoContainer}>
          <KmMidiaLogo className={classes.footerLogo} />
          <Typography className={classes.footerText}>
            Valorizando sua marca a cada KM rodado!
          </Typography>
        </Grid>
        <img
          alt={'divisor'}
          style={{width: '100%', margin: '10px 0'}}
          src={Divisor}
        />
        <Grid className={classes.footerTextContainer}>
          <Typography className={classes.footerTextRodape}>
            {`© Copyright ${moment().format(
              'YYYY',
            )}, KM Mídia CNPJ: 33.793.168/0001-50. Todos os direitos reservados.`}
          </Typography>
          <Typography className={classes.footerTextRodape}>
            {`Emissão: ${moment().format('DD/MM/YYYY')}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const tableCell = {
  fontSize: '10px',
  color: '#000',
};

const useStyles = makeStyles(() => ({
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 14,
    color: '#031663',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  tableHead: {
    ...tableCell,
    fontWeight: '700',
  },
  tableCell: {
    ...tableCell,
  },
  table: {
    margin: '30px 0',
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '700px',
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
    fontWeight: '700',
  },
  footerLogo: {
    height: '30px',
    width: '130px',
    margin: '0 20px 0px -5px',
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  observacoes: {
    backgroundColor: '#cecece',
    breakInside: 'avoid',
    width: '100%',
    paddingBottom: 100,
    zIndex: 999,
  },
  footerTextRodape: {
    fontSize: '11px',
  },
  footerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ' space-between',
    marginRight: '15px',
  },
}));

export default ImpressaoRelatorioCampanhasVendidas;
