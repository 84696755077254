import React from 'react';
import {Stack, Typography} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {ButtonComponent} from '../mui-button';
import {theme} from '../../theme/mui-theme';

function CustomUi({
  title,
  onConfirm,
  onClose,
  onCancel,
  message,
  loading,
  idButtonPrimary = 'button-cancel',
  idButtonSecundary = 'button-green',
}) {
  const handleCancelClick = () => {
    onCancel && onCancel();
    onClose();
  };

  const handleConfirmCLick = () => {
    onConfirm && onConfirm();
    onClose();
  };

  return (
    <Stack
      direction={'column'}
      spacing={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff',
        padding: '15px',
        borderRadius: '4px',
      }}
    >
      <ThemeProvider theme={theme}>
        <Typography
          sx={{
            fontFamily: (theme) => theme.typography.fontFamily.patternMedium,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: (theme) => theme.typography.fontFamily.pattern,
            textAlign: 'center',
            whiteSpace: 'pre-line',
          }}
        >
          {message}
        </Typography>
        <Stack direction={'row'} spacing={2} justifyContent="space-between">
          <ButtonComponent
            id={`${idButtonPrimary}`}
            onClick={handleCancelClick}
            sx={{width: '150px'}}
            value={'Cancelar'}
          />
          <ButtonComponent
            id={`${idButtonSecundary}`}
            onClick={handleConfirmCLick}
            sx={{width: '150px'}}
            autoFocus
            loading={loading}
            value={'Aceitar'}
          />
        </Stack>
      </ThemeProvider>
    </Stack>
  );
}

export default CustomUi;
