import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Aprovar from './aprovar';
import CadastrarValidacaoImagens from './cadastrar';
import Consulta from './consulta';

const ImagensPendentesDeValidacao = () => {
  const {role} = useSelector((store) => store.Auth);

  return (
    <Routes>
      <Route exact path="/" element={<Consulta />} />
      {['ROLE_ADMINISTRADOR', 'ROLE_GERENTE_OPERACIONAL', 'ROLE_OPERACIONAL'].includes(role) && (
        <Route exact path={`/criar`} element={<CadastrarValidacaoImagens />} />
      )}
      <Route path={`/:anuncioImagemVeiculoId/aprovar`} element={<Aprovar />} />
    </Routes>
  );
};

export default ImagensPendentesDeValidacao;
