import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  administradorList: [],
  filtrosAdministrador: {
    filtros: {
      ativo:{value: true, label: 'Ativo'},
      search: undefined
    },
    page: 0,
    orderBy: {campo: 'nome', asc: true}
  },
  hasMore: false,
  totalAdministrador: 0,
  refetchAdministrador: false
};
const acaoPersistFiltrosAdministrador = (state, action) =>{
  state.filtrosAdministrador = action.payload;
};
const acaoAtualizarAdministrador = state => {
  state.refetchAdministrador = true;
};

const acaoPersistAdministrador = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.administradorList = content;
  state.hasMore = !last;
  state.totalAdministrador = totalElements || 0;
};

const resumeSlice = createSlice({
  name: 'administrador',
  initialState: initialState,
  reducers: {
    persistAdministrador: acaoPersistAdministrador,
    atualizarAdministrador: acaoAtualizarAdministrador,
    persistFiltrosAdministrador: acaoPersistFiltrosAdministrador
  }
});

export const { actions, reducer } = resumeSlice;
export const { persistAdministrador, atualizarAdministrador, persistFiltrosAdministrador } = actions;
