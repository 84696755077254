import React, {useState} from 'react';
import {Grid, Typography, Box} from '@mui/material';
import {useMutation} from '@apollo/client';
import {ButtonComponent} from '../../../components/mui-button';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {TextAreaV2} from '../../../components/textarea/mui-textarea';
import motoristaStyles from '../styles';
import File from '../../../utils/file';
import {REPROVA_MOTORISTA_COMPROVANTE_RESIDENCIA} from '../../../graphql/mutation';
import {toastNotification} from '../../../components/toastify';
import {CameraAlt} from '@mui/icons-material';

const DadosEndereco = ({motorista = {}, alteracoes, handleClickImage, role}) => {
  const [endereco] = useState(motorista.endereco || {});
  const [fotoComprovante, setFotoComprovante] = useState(
    motorista.fotoComprovanteResidencia,
  );
  const [aprovouFotoComprovante, setAprovouFotoComprovante] = useState(
    motorista.aprovouFotoComprovanteResidencia,
  );
  const [obsFotoComprovante, setObsFotoComprovante] = useState(
    motorista.obsFotoComprovanteResidencia || '',
  );
  const [rejeitandoFoto, setRejeitandoFoto] = useState(false);

  const [reprovaMotoristaComprovante] = useMutation(
    REPROVA_MOTORISTA_COMPROVANTE_RESIDENCIA,
  );

  const handleClickReprovaMotoristaComprovante = () => {
    setRejeitandoFoto(true);
    reprovaMotoristaComprovante({
      variables: {
        obs: obsFotoComprovante,
        motoristaId: motorista.id,
      },
    })
      .then(() => {
        toastNotification({
          message: 'O comprovante do motorista foi reprovado!',
          type: 'success',
        });
        setAprovouFotoComprovante(false);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setRejeitandoFoto(false));
  };

  const isAtualizado = (campo, entity) => {
    const alterado = alteracoes
      ?.map((a) => a[campo] !== entity[campo])
      ?.reduce((acc, atual) => acc || atual, false);
    return alterado ? {borderColor: '#00398E'} : null;
  };

  const isIdAtualizado = (campo, entity) => {
    const alterado = alteracoes
      ?.map((a) => a[campo]?.id && a[campo].id !== entity[campo].id)
      ?.reduce((acc, atual) => acc || atual, false);
    return alterado ? {borderColor: '#00398E'} : null;
  };

  const permiteAcoes = () => {
    return !['ROLE_GERENTE_COMERCIAL', 'ROLE_FINANCEIRO'].includes(role);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        <Typography sx={styles.label}>Comprovante de residência</Typography>
        {fotoComprovante ? (
          <div>
            <Box
              sx={styles.fotoContainer}
              style={isAtualizado('fotoComprovanteResidencia', motorista)}>
              <img
                style={styles.fotoImg}
                src={File.getImageAuth(fotoComprovante)}
                alt="foto-comprovante-residencia"
                onClick={() =>
                  handleClickImage(
                    fotoComprovante,
                    'Foto Comprovante Residência',
                  )
                }
                onError={() => setFotoComprovante(null)}
              />
            </Box>
          </div>
        ) : (
          <Box sx={styles.fotoContainer}>
            <CameraAlt />
            <p>Nenhuma foto disponível</p>
          </Box>
        )}
        {!aprovouFotoComprovante && motorista.situacao !== 'APROVADO' && permiteAcoes() && (
          <div style={{marginTop: 10}}>
            <TextAreaV2
              row={3}
              label={'Observação'}
              placeholder="Digite uma observação para a rejeição"
              value={obsFotoComprovante}
              onChange={(e) => {
                setObsFotoComprovante(e.target.value);
                setAprovouFotoComprovante(null);
              }}
            />
            {aprovouFotoComprovante === null && (
              <ButtonComponent
                id="button-cancel-empty"
                value={'Reprovar'}
                onClick={handleClickReprovaMotoristaComprovante}
                icon={rejeitandoFoto}
                disabled={rejeitandoFoto}
              />
            )}
            {aprovouFotoComprovante === false && (
              <ButtonComponent
                id="button-cancel-empty"
                value="Reprovado"
                disabled
              />
            )}
          </div>
        )}
      </Grid>
      <Grid item xs={12} md={10}>
        <Box sx={{margin: '23px 0px 47px 0px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <InputV2
                label="CEP"
                value={endereco.cep}
                mask="cep"
                style={isAtualizado('cep', endereco)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputV2
                label="Logradouro"
                value={endereco.logradouro}
                style={isAtualizado('logradouro', endereco)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputV2
                label="Número"
                value={endereco.numero}
                style={isAtualizado('numero', endereco)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputV2
                label="Complemento"
                value={endereco.complemento}
                style={isAtualizado('complemento', endereco)}
                disabled
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputV2
                label="Bairro"
                value={endereco.bairro}
                style={isAtualizado('bairro', endereco)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputV2
                label="Cidade"
                value={endereco.cidade?.nomeEUf || ''}
                style={isIdAtualizado('cidade', endereco)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputV2
                label="Cidade de atuação"
                value={motorista.cidadeAtuacao?.nomeEUf || ''}
                style={isIdAtualizado('cidadeAtuacao', motorista)}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const styles = {
  ...motoristaStyles,
};

export default DadosEndereco;
