import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaCampanhas from './consulta-campanhas';
import ConsultaAnuncios from './consulta-anuncios';

const AnunciosAguardandoRemocao = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaCampanhas />} />
      <Route path={`/:campanhaCidadeId/anuncios`} element={<ConsultaAnuncios />} />
    </Routes>
  );
};

export default AnunciosAguardandoRemocao;
