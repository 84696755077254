import React from 'react';
import {Field, Formik} from 'formik';
import {Typography} from '@mui/material';
import {Modal, Grid, Box} from '@mui/material';
import {SelectInstaladores} from '../../../containers/selects/mui-instaladores';
import {ButtonComponent} from '../../../components/mui-button';

const InstaladorModal = ({
  openModal,
  instalador,
  onClose,
  onChange,
  iniciarCampanha,
  message = '',
}) => {
  return (
    <Modal
      open={openModal}
      onClose={() => onClose()}
      sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
      <Box width="400px" backgroundColor="#fff" padding="20px">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{fontFamily: (theme) => theme.typography.fontFamily.patternMedium}}>
              Selecione o instalador
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{fontFamily: (theme) => theme.typography.fontFamily.pattern}} >{message}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Formik initialValues={{instalador}} enableReinitialize>
              <Field
                component={SelectInstaladores}
                label="Instalador"
                name="instalador"
                className="select"
                isClearable
                onChange={(e) => onChange(e)}
              />
            </Formik>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ButtonComponent
                id="button-cancel"
                value="Cancelar"
                onClick={() => onClose()}
              />
            </Grid>
            <Grid item xs={6}>
              <ButtonComponent
                id="button"
                value="Iniciar campanha"
                onClick={iniciarCampanha}
                disabled={!instalador}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default InstaladorModal;
