import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import {Paper, Grid, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Box} from '@mui/material';
import {useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {Add, Visibility} from '@mui/icons-material';
import {Field, Formik} from 'formik';
import {
  persistFiltrosInstalador,
  persistInstalador,
} from '../../redux/Instalador/slice';
import {FIND_ALL_INSTALADOR} from '../../graphql/queries';
import {Button} from '@mui/material';
import {RoundTooltipButton, ButtonComponent} from '../../components/mui-button';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import TableCellOrdered from '../../components/table-cell-ordered';
import Select from '../../components/select/mui-select';
import {SelectEstados} from '../../containers/selects/mui-estados';
const columns = [
  {id: 'nomeFantasia', label: 'Nome Fantasia'},
  {id: 'nome', label: 'Razão Social'},
  {
    id: 'endereco',
    label: 'Cidade',
    campo: 'endereco.cidade.nome',
    format: (endereco) =>
      endereco?.cidade ? `${endereco.cidade.nome} / ${endereco.cidade.uf}` : '',
  },
  {
    id: 'ativo',
    label: 'Situação',
    format: (ativo) => (ativo ? 'Ativo' : 'Inativo'),
  },
];

const ConsultaInstalador = ({pageSize = 20, role}) => {
  const {
    instaladorList,
    totalInstalador,
    refetchInstalador,
    filtrosInstalador,
  } = useSelector((store) => store.Instalador);

  const [rowsPerPage] = useState(pageSize);
  const [page, setPage] = useState(filtrosInstalador.page);
  const [orderBy, setOrderBy] = useState(filtrosInstalador.orderBy);
  const [filtros, setFiltros] = useState(filtrosInstalador.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const instaladorQuery = useQuery(FIND_ALL_INSTALADOR, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        cpfCnpj: searchDTO.cpfCnpj,
        estadoId: searchDTO.estado?.value,
        cidadeId: searchDTO.cidade?.value,
        ativo: searchDTO.ativo?.value,
      },
    },
  });

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  useEffect(() => {
    if (
      !instaladorQuery.loading &&
      !instaladorQuery.error &&
      instaladorQuery.data
    ) {
      dispatch(persistInstalador(instaladorQuery.data.findAllInstalador));
      dispatch(persistFiltrosInstalador({filtros, page, orderBy}));
    }
    if (refetchInstalador) {
      instaladorQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instaladorQuery, dispatch, refetchInstalador]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const showCadastrar = () => {
    return role.includes('GERENTE_OPERACIONAL') || role.includes('ADMINISTRADOR');
  }

  return (
    <Grid id="page-container" container>
      <Button
        startIcon={<Add />}
        onClick={() => navigate(`criar`)}
        id="button-green"
        toolbar="true"
        style={{visibility: showCadastrar() ? 'visible' : 'hidden'}}
      >
        Cadastrar novo
      </Button>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputV2
                  label="Nome Fantasia / Razão Social"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.value || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Situação"
                  options={[
                    {label: 'Todos'},
                    {value: true, label: 'Ativo'},
                    {value: false, label: 'Inativo'},
                  ]}
                  value={filtros.ativo}
                  onChange={(e) => setFiltros({...filtros, ativo: e})}
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  id="button"
                  value="Filtrar"
                  loading={instaladorQuery.loading}
                  fullWidth
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell key="acoes" align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instaladorList.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <RoundTooltipButton
                            id="icon-primary"
                            title={'Visualizar'}
                            onClick={() => navigate(`${row.id}/editar`)}
                            icon={<Visibility />}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalInstalador}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'página anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaInstalador;
