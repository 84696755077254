import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  anunciosAguardandoInstalacao: [],
  hasMore: false,
  totalAnunciosAguardandoInstalacao: 0,
  refetchAnunciosAguardandoInstalacao: false
};

const acaoAtualizarAnunciosAguardandoInstalacao = state => {
  state.refetchAnunciosAguardandoInstalacao = true;
};

const acaoPersistAnunciosAguardandoInstalacao = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.anunciosAguardandoInstalacao = content;
  state.hasMore = !last;
  state.totalAnunciosAguardandoInstalacao = totalElements || 0;
};

const resumeSlice = createSlice({
  name: 'anunciosAguardandoInstalacao',
  initialState: initialState,
  reducers: {
    persistAnunciosAguardandoInstalacao: acaoPersistAnunciosAguardandoInstalacao,
    atualizarAnunciosAguardandoInstalacao: acaoAtualizarAnunciosAguardandoInstalacao
  }
});

export const { actions, reducer } = resumeSlice;
export const {
  persistAnunciosAguardandoInstalacao,
  atualizarAnunciosAguardandoInstalacao
} = actions;
