import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  estado: [],
  filtrosEstado: {
    filtros: {},
    page: 0,
    orderBy: {campo: 'nome', asc: true},
  },
  hasMore: false,
  totalEstado: 0,
  refetchEstado: false,
};

const acaoAtualizarEstado = (state) => {
  state.refetchEstado = true;
};

const acaoPersistEstado = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.estado = content;
  state.hasMore = !last;
  state.totalEstado = totalElements || 0;
};

const acaoPersistFiltrosEstado = (state, action) => {
  state.filtrosEstado = action.payload;
};

const resumeSlice = createSlice({
  name: 'estado',
  initialState: initialState,
  reducers: {
    persistEstado: acaoPersistEstado,
    atualizarEstado: acaoAtualizarEstado,
    persistFiltrosEstado: acaoPersistFiltrosEstado,
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistEstado, atualizarEstado, persistFiltrosEstado} = actions;
