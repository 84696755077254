import React from 'react';
import {Box, Typography} from '@mui/material';

const Lane = ({label, total, id, titleStyle, labelTitleStyle}) => {
  return (
    <Box sx={styles.gridLaneTitulo} id={id} style={titleStyle}>
      <Typography sx={styles.laneTitulo} style={labelTitleStyle}>{label}</Typography>
      <Typography sx={styles.laneSubtitulo}>{total}</Typography>
    </Box>
  );
};

const styles = {
  gridLaneTitulo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '40px',
    cursor: 'default',
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
  },
  laneTitulo: {
    fontFamily: 'AktivGrotesk-Bold',
    FontSize: '14px',
  },
  laneSubtitulo: {
    fontFamily: 'AktivGrotesk-Bold',
    FontSize: '14px',
  },
};

export default Lane;
