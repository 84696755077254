import {useMutation} from '@apollo/client';
import {Edit, Key, Save} from '@mui/icons-material';
import {Box, Grid, Paper, Typography} from '@mui/material';
import {Field, Formik} from 'formik';
import {CheckboxWithLabel} from 'formik-mui';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';
import InputV2 from '../../components/input-v2/mui-input-v2';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import {ButtonComponent} from '../../components/mui-button';
import UploadableFotoPerfil from '../../components/mui-uploadable-foto-perfil';
import SelectFormik from '../../components/select/mui-select-formik';
import {TextAreaV2} from '../../components/textarea/mui-textarea';
import {toastNotification} from '../../components/toastify';
import {tipoContaBancaria} from '../../constants/enum-labels';
import {SelectBancos} from '../../containers/selects/mui-bancos';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {
  ATUALIZAR_INSTALADOR,
  CRIAR_INSTALADOR,
  GERAR_NOVA_SENHA_INSTALADOR,
} from '../../graphql/mutation';
import {uploadAmazonS3} from '../../services/UploadService';
import Number from '../../utils/number';
import String from '../../utils/string';


const FormularioInstalador = ({instalador = {ativo: true}, refetch, role}) => {
  const [uploadImage, setUploadImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fotoPerfil, setFotoPerfil] = useState(instalador.fotoPerfil);
  const [observacao, setObservacao] = useState(instalador.observacao || '');
  const [observacaoAlterada, setObservacaoAlterada] = useState(false);
  const [readOnly, setReadOnly] = useState(!!instalador?.id)

  const [gerarNovaSenha] = useMutation(GERAR_NOVA_SENHA_INSTALADOR);
  const [criarInstalador] = useMutation(CRIAR_INSTALADOR);
  const [atualizarInstalador] = useMutation(ATUALIZAR_INSTALADOR);

  useEffect(() => {
    if (readOnly) {
      return setObservacao(instalador.observacao || '');
    }

    const administrador = JSON.parse(window.localStorage.getItem('usuario'));

    setObservacao(
      (instalador.observacao ? `${instalador.observacao}\n\n` : '') +
      `[${administrador?.nome}: ${moment().format('DD/MM/YY HH[h]mm')}]\n`,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, instalador?.observacao]);

  function handleChangeUploadImage(e) {
    const file = Array.from(e.target.files)[0];
    setUploadImage(file);
    handlePreviewImage(file);
  }

  function handlePreviewImage(file) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setPreviewImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function handleSubmitDadosInstalador(
    {cep, logradouro, cidade, bairro, numero, complemento, banco, bancoCidade, ...values},
    {setSubmitting},
  ) {
    const dadosInstalador = {
      ...values,
      cpfCnpj: String.removeSpecialChars(values.cpfCnpj),
      telefone: String.removeSpecialChars(values.telefone),
      celular: String.removeSpecialChars(values.celular),
      valorLateralCompleta: Number.currencyToFloat(values.valorLateralCompleta),
      valorLateralRecorte: Number.currencyToFloat(values.valorLateralRecorte),
      valorCapo: Number.currencyToFloat(values.valorCapo),
      valorVidroTrazeiro: Number.currencyToFloat(values.valorVidroTrazeiro),
      valorBanner: Number.currencyToFloat(values.valorBanner),
      valorBonusMotorista: Number.currencyToFloat(values.valorBonusMotorista),
      observacao: observacaoAlterada ? observacao : instalador.observacao,
      endereco: {
        cep: String.removeSpecialChars(cep),
        logradouro,
        bairro,
        cidade: {
          id: cidade.value,
        },
        numero,
        complemento,
      },
      banco: !banco?.value ? undefined : {id: banco.value},
      tipoContaBancaria: values.tipoContaBancaria?.value,
      cpfCnpjFavorecido: String.removeSpecialChars(values.cpfCnpjFavorecido),
      bancoCidade: !bancoCidade?.value ? undefined : {id: bancoCidade.value},
    };

    try {
      let uploadData = null;
      if (uploadImage) {
        uploadData = await uploadAmazonS3(uploadImage);
      }

      if (instalador.id) {
        dadosInstalador.id = instalador.id;

        await atualizarInstalador({
          variables: {
            instalador: {
              ...dadosInstalador,
              fotoPerfil: uploadData ? uploadData.id : undefined,
            },
          },
        });
      } else {
        await criarInstalador({
          variables: {
            instalador: {
              ...dadosInstalador,
              fotoPerfil: uploadData ? uploadData.id : undefined,
            },
          },
        });
      }

      refetch && refetch();
      setReadOnly(true);
      setObservacaoAlterada(false);
      toastNotification({
        message: 'Os dados cadastrados foram salvos!',
        type: 'success',
      });
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setSubmitting(false);
    }
  }

  const gerarUsuarioSenhaInstalador = () => {
    confirmAlertMui({
      message: 'Deseja mesmo gerar uma nova senha?',
      onConfirm: gerarUsuarioSenha
    })
  };

  const gerarUsuarioSenha = () => {
    gerarNovaSenha({
      variables: {
        instalador: {
          id: instalador.id,
        },
      },
    })
      .then(({data}) => {
        toastNotification({
          titulo: `Nova senha: ${data.senha}`,
          message: 'Copiada para área de transferência!',
          type: 'success',
          autoClose: false,
        });
        navigator.clipboard.writeText(data.senha);

      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const showEditar = () => {
    return role.includes('GERENTE_OPERACIONAL') || role.includes('ADMINISTRADOR');
  }

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          validationSchema={InstaladorScheme}
          initialValues={{
            nome: instalador.nome || '',
            nomeFantasia: instalador.nomeFantasia || '',
            cpfCnpj: instalador.cpfCnpj || '',
            cep: instalador.endereco ? instalador.endereco.cep || '' : '',
            cidade: instalador.endereco?.cidade || undefined,
            logradouro: instalador.endereco?.logradouro || '',
            bairro: instalador.endereco?.bairro || '',
            numero: instalador.endereco?.numero || '',
            complemento: instalador.endereco?.complemento || '',
            nomeContato: instalador.nomeContato || '',
            telefone: instalador.telefone || '',
            email: instalador.email || '',
            financeiroNome: instalador.financeiroNome || '',
            financeiroEmail: instalador.financeiroEmail || '',
            financeiroTelefone: instalador.financeiroTelefone || '',
            celular: instalador.celular || '',
            valorLateralCompleta: Number.currencyFormat(instalador.valorLateralCompleta),
            valorLateralRecorte: Number.currencyFormat(instalador.valorLateralRecorte),
            valorCapo: Number.currencyFormat(instalador.valorCapo),
            valorVidroTrazeiro: Number.currencyFormat(instalador.valorVidroTrazeiro),
            valorBanner: Number.currencyFormat(instalador.valorBanner),
            valorBonusMotorista: Number.currencyFormat(instalador.valorBonusMotorista),
            banco: !instalador.banco ? null : {
              value: instalador.banco.id,
              label: instalador.banco.codigoENome
            },
            agencia: instalador.agencia || '',
            tipoContaBancaria: !instalador.tipoContaBancaria ? null : {
              value: instalador.tipoContaBancaria,
              label: tipoContaBancaria[instalador.tipoContaBancaria]
            },
            conta: instalador.conta || '',
            chavePix: instalador.chavePix || '',
            cpfCnpjFavorecido: instalador.cpfCnpjFavorecido || '',
            nomeFavorecido: instalador.nomeFavorecido || '',
            bancoCidade: !instalador.bancoCidade ? null : {
              ...instalador.bancoCidade,
              value: instalador.bancoCidade.id,
              label: instalador.bancoCidade.nomeEUf,
            },
            ativo: instalador.ativo,
          }}
          enableReinitialize
          onSubmit={handleSubmitDadosInstalador}>
          {({isSubmitting, handleSubmit, values, setValues}) => (
            <Box component="form" sx={styles.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <UploadableFotoPerfil
                    image={previewImage || fotoPerfil}
                    setImage={setFotoPerfil}
                    handleChangeUploadImage={handleChangeUploadImage}
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid container item xs={10} direction={'row'} spacing={2} alignContent={'space-around'}>
                  <Grid item xs={12}>
                    <Field
                      component={InputV2Formik}
                      name="nome"
                      label="Nome"
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Field
                      component={InputV2Formik}
                      name="nomeFantasia"
                      label="Nome Fantasia"
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Field
                      component={InputV2Formik}
                      name="cpfCnpj"
                      label="CPF / CNPJ"
                      mask="cpfCnpj"
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Field
                    component={InputV2Formik}
                    name="nomeContato"
                    label="Nome Contato"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={3} xs={5}>
                  <Field
                    component={InputV2Formik}
                    name="email"
                    label="E-mail"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={3} xs={3.5}>
                  <Field
                    component={InputV2Formik}
                    name="telefone"
                    label="Telefone"
                    mask="phone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={3} xs={3.5}>
                  <Field
                    component={InputV2Formik}
                    name="celular"
                    label="Celular"
                    mask="phone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={4} xs={5}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroNome"
                    label="Contato / Financeiro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={4} xs={3.5}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroTelefone"
                    label="Telefone"
                    mask="phone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={4} xs={3.5}>
                  <Field
                    component={InputV2Formik}
                    name="financeiroEmail"
                    label="E-mail"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography id="formulario-subtitle">Endereço</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="cep"
                    label="CEP"
                    mask="cep"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  <Field
                    component={InputV2Formik}
                    name="logradouro"
                    label="Logradouro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={1} xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="numero"
                    label="Número"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Field
                    component={InputV2Formik}
                    name="complemento"
                    label="Complemento"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={InputV2Formik}
                    name="bairro"
                    label="Bairro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  {readOnly ? (
                    <InputV2
                      name="cidade"
                      label="Cidade/Estado"
                      value={values?.cidade?.nomeEUf || ''}
                      disabled
                    />
                  ) : (
                    <Field
                      component={SelectCidades}
                      name="cidade"
                      label="Cidade/Estado"
                      disabled={readOnly}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h7" id="formulario-subtitle">
                    Dados bancários
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  {readOnly ? (
                    <InputV2
                      disabled
                      label="Banco"
                      value={values.banco?.label}
                    />
                  ) : (
                    <Field component={SelectBancos} name="banco" label="Banco" />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="agencia"
                    label="Agência"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={2}>
                  {readOnly ? (
                    <InputV2
                      disabled
                      label="Tipo de conta"
                      value={values.tipoContaBancaria?.label || ''}
                    />
                  ) : (
                    <Field
                      component={SelectFormik}
                      name="tipoContaBancaria"
                      label="Tipo de conta"
                      options={[
                        {
                          value: 'CORRENTE',
                          label: 'Corrente',
                        },
                        {
                          value: 'POUPANCA',
                          label: 'Poupança',
                        },
                      ]}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="conta"
                    label="Conta"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="chavePix"
                    label="Chave PIX"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="cpfCnpjFavorecido"
                    label="CPF/CNPJ favorecido"
                    mask="cpfCnpj"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="nomeFavorecido"
                    label="Nome favorecido"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  {readOnly ? (
                    <InputV2
                      disabled
                      label="Cidade"
                      value={values.bancoCidade?.nomeEUf}
                    />
                  ) : (
                    <Field
                      component={SelectCidades}
                      name="bancoCidade"
                      label="Cidade"
                      disabled={readOnly}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h7" id="formulario-subtitle">
                    Valores de mídia
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={4} xl={2}>
                  <InputV2
                    label="Portas laterais (4 portas)"
                    mask="moeda"
                    defaultValue={'0'}
                    value={values.valorLateralCompleta}
                    onChange={({target}) => setValues((v) => ({...v, valorLateralCompleta: target.value}))}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6} lg={4} xl={2}>
                  <InputV2
                    label="Portas laterais (recorte)"
                    mask="moeda"
                    defaultValue={'0'}
                    value={values.valorLateralRecorte}
                    onChange={({target}) => setValues((v) => ({...v, valorLateralRecorte: target.value}))}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6} lg={4} xl={2}>
                  <InputV2
                    label="Capô"
                    mask="moeda"
                    defaultValue={'0'}
                    value={values.valorCapo}
                    onChange={({target}) => setValues((v) => ({...v, valorCapo: target.value}))}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6} lg={4} xl={2}>
                  <InputV2
                    label="Vidro trazeiro"
                    mask="moeda"
                    defaultValue={'0'}
                    value={values.valorVidroTrazeiro}
                    onChange={({target}) => setValues((v) => ({...v, valorVidroTrazeiro: target.value}))}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6} lg={4} xl={2}>
                  <InputV2
                    label="Banner interno"
                    mask="moeda"
                    defaultValue={'0'}
                    value={values.valorBanner}
                    onChange={({target}) => setValues((v) => ({...v, valorBanner: target.value}))}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6} lg={4} xl={2}>
                  <InputV2
                    label="Bônus ao motorista"
                    title="Bônus pago ao motorista para fazer a instalação"
                    mask="moeda"
                    defaultValue={'0'}
                    value={values.valorBonusMotorista}
                    onChange={({target}) => setValues((v) => ({...v, valorBonusMotorista: target.value}))}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h7" id="formulario-subtitle">
                    Observações
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextAreaV2
                    label="Observações"
                    placeholder="Digite uma observação"
                    value={observacao}
                    rows={5}
                    cols={100}
                    onChange={({target}) => {
                      setObservacao(target.value);
                      setObservacaoAlterada(true);
                    }}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="ativo"
                    color="primaryPatern"
                    Label={{
                      label: 'Ativo',
                      style: {
                        marginBottom: 30,
                      },
                    }}
                    disabled={readOnly}
                  />
                </Grid>
                {showEditar() && (
                  <>
                    <Grid item xs={6}>
                      {readOnly && (
                        <ButtonComponent
                          sx={styles.buttonWrapper}
                          type="button"
                          value={'Editar'}
                          icon={<Edit />}
                          onClick={() => setReadOnly(false)}
                        />
                      )}
                      {!readOnly && (
                        <ButtonComponent
                          sx={styles.buttonWrapper}
                          type="submit"
                          value={'Salvar'}
                          loading={isSubmitting}
                          icon={<Save />}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} display="flex" justifyContent="flex-end">
                      <ButtonComponent
                        sx={styles.buttonWrapper}
                        type="button"
                        id="button-green"
                        value="Gerar nova senha"
                        icon={<Key />}
                        onClick={gerarUsuarioSenhaInstalador}
                        disabled={!readOnly}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

const InstaladorScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  nomeFantasia: Yup.string().required('Campo obrigatório'),
  cpfCnpj: Yup.string().required('Campo obrigatório'),
  cep: Yup.string().required('Campo obrigatório'),
  cidade: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .nullable()
    .required('Campo obrigatório'),
  logradouro: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  numero: Yup.string().required('Campo obrigatório'),
  nomeContato: Yup.string().required('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  celular: Yup.string().required('Campo obrigatório'),
});

const styles = {
  buttonWrapper: {
    width: '256px',
  },
};

export default FormularioInstalador;
