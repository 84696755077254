import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  anunciosList: [],
  hasMore: false,
  totalValidacaoImagensRemocao: 0,
  refetchValidacaoImagensRemocao: false
};

const acaoAtualizarValidacaoImagensRemocao = state => {
  state.refetchValidacaoImagensRemocao = true;
};

const acaoPersistValidacaoImagensRemocao = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.anunciosList = content;
  state.hasMore = !last;
  state.totalValidacaoImagensRemocao = totalElements || 0;
};

const resumeSlice = createSlice({
  name: 'validacaoImagensRemocao',
  initialState: initialState,
  reducers: {
    persistValidacaoImagensRemocao: acaoPersistValidacaoImagensRemocao,
    atualizarValidacaoImagensRemocao: acaoAtualizarValidacaoImagensRemocao
  }
});

export const { actions, reducer } = resumeSlice;
export const {
  persistValidacaoImagensRemocao,
  atualizarValidacaoImagensRemocao
} = actions;
