import {useMutation} from '@apollo/client';
import {Edit, Key, Save} from '@mui/icons-material';
import {Box, Grid, Typography} from '@mui/material';
import {styled} from '@mui/styles';
import {Field, Formik} from 'formik';
import {CheckboxWithLabel} from 'formik-mui';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {confirmAlertMui} from '../../../components/confirm-alert/mui-confirm-alert';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import InputV2Formik from '../../../components/input-v2/mui-input-v2-formik';
import {ButtonComponent} from '../../../components/mui-button';
import UploadableFotoPerfil from '../../../components/mui-uploadable-foto-perfil';
import SelectFormik from '../../../components/select/mui-select-formik';
import {toastNotification} from '../../../components/toastify';
import {comercialRole, tipoContaBancaria} from '../../../constants/enum-labels';
import ImagemModal from '../../../containers/modais/imagem-modal';
import {SelectBancos} from '../../../containers/selects/mui-bancos';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import {SelectComercial} from '../../../containers/selects/mui-comercial';
import {
  CRIAR_COMERCIAL,
  GERAR_NOVA_SENHA_COMERCIAL,
} from '../../../graphql/mutation';
import {uploadAmazonS3} from '../../../services/UploadService';
import String from '../../../utils/string';

const FormularioCadastroLicencas = ({comercial = {}}) => {
  const navigate = useNavigate();

  const [uploadImage, setUploadImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fotoPerfil, setFotoPerfil] = useState(comercial.fotoPerfil);
  const [readOnly, setReadOnly] = useState(!!comercial?.id);
  const [imagemModal, setImagemModal] = useState({
    visible: false,
    id: null,
    titulo: '',
  });

  const [criarComercial] = useMutation(CRIAR_COMERCIAL);
  const [gerarNovaSenha] = useMutation(GERAR_NOVA_SENHA_COMERCIAL);

  function handleChangeUploadImage(e) {
    const file = Array.from(e.target.files)[0];
    setUploadImage(file);
    handlePreviewImage(file);
  }

  function handlePreviewImage(file) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setPreviewImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function handleSubmitDadosComercial(
    {
      cep,
      logradouro,
      cidade,
      bairro,
      numero,
      complemento,
      banco,
      agencia,
      tipoContaBancaria,
      conta,
      chavePix,
      documentoFavorecido,
      nomeFavorecido,
      bancoCidade,
      cidadesAtuacao,
      role,
      ...values
    },
    {setSubmitting},
  ) {
    const dadosComercial = {
      ...values,
      cpfCnpj: String.removeSpecialChars(values.cpfCnpj),
      telefone: String.removeSpecialChars(values.telefone),
      celular: String.removeSpecialChars(values.celular),
      cidadesAtuacao: cidadesAtuacao?.map((e) => ({id: e.id})),
      role: role.value,
      endereco: {
        cep: String.removeSpecialChars(cep),
        logradouro,
        bairro,
        cidade: {
          id: cidade.value,
        },
        numero,
        complemento,
      },
      dadoBancario: {
        banco: !banco?.id
          ? null
          : {
              id: banco.id,
            },
        agencia: agencia,
        tipoContaBancaria: tipoContaBancaria?.value,
        conta: conta,
        chavePix: chavePix,
        cpfCnpjFavorecido: String.removeSpecialChars(documentoFavorecido),
        nomeFavorecido: nomeFavorecido,
        cidade: !bancoCidade?.id
          ? null
          : {
              id: bancoCidade.id,
            },
      },
    };

    let uploadData = null;
    try {
      if (uploadImage) {
        uploadData = await uploadAmazonS3(uploadImage);
      }
    } catch (error) {
      console.log(error);
    }

    criarComercial({
      variables: {
        comercial: {
          ...dadosComercial,
          id: comercial?.id || undefined,
          fotoPerfil: uploadData?.id || comercial.fotoPerfil,
          comercialResponsavel:
            dadosComercial.role === 'LICENCA_COMERCIAL'
              ? {id: dadosComercial?.comercialResponsavel?.id}
              : undefined,
        },
      },
    })
      .then(({data}) => {
        setReadOnly(true);
        toastNotification({
          message: 'Os dados cadastrados foram salvos com sucesso!',
          type: 'success',
        });
        navigate(`/licencas/${data?.comercial?.id}/editar`);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSubmitting(false));
  }

  const handleOpenConfirmAlert = () => {
    confirmAlertMui({
      message: 'Deseja mesmo gerar uma nova senha?',
      onConfirm: gerarUsuarioSenha,
    });
  };

  const gerarUsuarioSenha = () => {
    gerarNovaSenha({
      variables: {
        comercial: {
          id: comercial.id,
        },
      },
    })
      .then(({data}) => {
        toastNotification({
          titulo: `Nova senha: ${data.senha}`,
          message: 'Copiada para área de transferência!',
          type: 'success',
          autoClose: false,
        });
        navigator.clipboard.writeText(data.senha);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Box>
      <Formik
        validationSchema={ComercialScheme}
        initialValues={{
          nomeRazao: comercial.nomeRazao || '',
          cpfCnpj: comercial.cpfCnpj || '',
          cep: comercial.endereco?.cep || '',
          cidade: !comercial.endereco?.cidade
            ? null
            : {
                ...comercial.endereco.cidade,
                value: comercial.endereco.cidade.id,
                label: comercial.endereco.cidade.nomeEUf,
              },
          logradouro: comercial.endereco?.logradouro || '',
          bairro: comercial.endereco?.bairro || '',
          numero: comercial.endereco?.numero || '',
          complemento: comercial.endereco?.complemento || '',
          nomeContato: comercial.nomeContato || '',
          telefone: comercial.telefone || '',
          email: comercial.email || '',
          celular: comercial.celular || '',
          porcentagemValorRepasse: comercial.porcentagemValorRepasse || 0,
          banco: !comercial.dadoBancario?.banco
            ? undefined
            : {
                ...comercial.dadoBancario.banco,
                value: comercial.dadoBancario.banco.id,
                label: comercial.dadoBancario.banco.codigoENome,
              },
          agencia: comercial.dadoBancario?.agencia || '',
          tipoContaBancaria: !comercial.dadoBancario?.tipoContaBancaria
            ? null
            : {
                value: comercial.dadoBancario?.tipoContaBancaria,
                label:
                  tipoContaBancaria[comercial.dadoBancario?.tipoContaBancaria],
              },
          conta: comercial.dadoBancario?.conta || '',
          chavePix: comercial.dadoBancario?.chavePix || '',
          documentoFavorecido: comercial.dadoBancario?.cpfCnpjFavorecido || '',
          nomeFavorecido: comercial.dadoBancario?.nomeFavorecido || '',
          bancoCidade: !comercial.dadoBancario?.cidade
            ? null
            : {
                ...comercial.dadoBancario?.cidade,
                value: comercial.dadoBancario?.cidade.id,
                label: comercial.dadoBancario?.cidade.nomeEUf,
              },
          ativo: comercial.ativo || '',
          role: comercial.role
            ? {value: comercial.role, label: comercialRole[comercial.role]}
            : {value: 'LICENCA_ADMINISTRADOR', label: 'Licença'},
          comercialResponsavel: comercial.comercialResponsavel || null,
        }}
        enableReinitialize
        onSubmit={handleSubmitDadosComercial}>
        {({values, isSubmitting, handleSubmit, setFieldValue}) => (
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignContent="flex-start"
              sx={{minHeight: 'calc(100vh - 275px)'}}>
              <Grid item xs={2}>
                <UploadableFotoPerfil
                  label="Foto perfil"
                  image={previewImage || fotoPerfil}
                  setImage={setFotoPerfil}
                  handleChangeUploadImage={handleChangeUploadImage}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={10}>
                <Box marginTop="25px" width="100%">
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Field
                        component={InputV2Formik}
                        name="nomeRazao"
                        label="Razão Social"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        component={InputV2Formik}
                        name="cpfCnpj"
                        label="CPF / CNPJ"
                        mask="cpfCnpj"
                        disabled={readOnly}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} marginTop={'25px'}>
                    <Grid item xs={3}>
                      <Field
                        component={InputV2Formik}
                        name="nomeContato"
                        label="Nome Contato"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={InputV2Formik}
                        name="email"
                        label="E-mail"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={InputV2Formik}
                        name="telefone"
                        label="Telefone"
                        mask="phone"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={InputV2Formik}
                        name="celular"
                        label="Celular"
                        mask="phone"
                        disabled={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid container item xs={12} gap={2}>
                <Grid item xs={2}>
                  <Field
                    component={SelectFormik}
                    name="role"
                    label="Tipo"
                    options={[
                      {
                        value: 'COMERCIAL',
                        label: 'Representante',
                      },
                      {
                        value: 'LICENCA_ADMINISTRADOR',
                        label: 'Licença',
                      },
                      {
                        value: 'LICENCA_COMERCIAL',
                        label: 'Executivo',
                      },
                    ]}
                    disabled={readOnly}
                  />
                </Grid>
                {values.role?.value === 'LICENCA_COMERCIAL' && (
                  <Grid item xs={4}>
                    <Field
                      component={({...props}) => (
                        <SelectComercial
                          searchDTO={{
                            comercialRoles: ['LICENCA_ADMINISTRADOR'],
                          }}
                          {...props}
                        />
                      )}
                      name="comercialResponsavel"
                      label="Licença responsável"
                      disabled={readOnly}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" id="formulario-subtitle">
                  Royalties
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={InputV2Formik}
                  name="porcentagemValorRepasse"
                  label="Km Mídia"
                  endAdornment="%"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" id="formulario-subtitle">
                  Endereço
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={InputV2Formik}
                  name="cep"
                  label="CEP"
                  mask="cep"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="logradouro"
                  label="Logradouro"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={1}>
                <Field
                  component={InputV2Formik}
                  name="numero"
                  label="Número"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="complemento"
                  label="Complemento"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="bairro"
                  label="Bairro"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                {readOnly ? (
                  <InputV2
                    label="Cidade/Estado"
                    value={comercial.endereco?.cidade?.nomeEUf}
                    title={comercial.endereco?.cidade?.nomeEUf}
                    disabled
                  />
                ) : (
                  <Field
                    component={SelectCidades}
                    name="cidade"
                    label="Cidade/Estado"
                    disabled={readOnly}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" id="formulario-subtitle">
                  Dados bancários
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {readOnly ? (
                  <InputV2
                    disabled
                    label="Banco"
                    value={values.banco?.codigoENome}
                  />
                ) : (
                  <Field component={SelectBancos} name="banco" label="Banco" />
                )}
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={InputV2Formik}
                  name="agencia"
                  label="Agência"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={2}>
                {readOnly ? (
                  <InputV2
                    disabled
                    label="Tipo de conta"
                    value={values.tipoContaBancaria?.label || ''}
                  />
                ) : (
                  <Field
                    component={SelectFormik}
                    name="tipoContaBancaria"
                    label="Tipo de conta"
                    options={[
                      {
                        value: 'CORRENTE',
                        label: 'Corrente',
                      },
                      {
                        value: 'POUPANCA',
                        label: 'Poupança',
                      },
                    ]}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="conta"
                  label="Conta"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={InputV2Formik}
                  name="chavePix"
                  label="Chave PIX"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={InputV2Formik}
                  name="documentoFavorecido"
                  label="CPF/CNPJ favorecido"
                  mask="cpfCnpj"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={InputV2Formik}
                  name="nomeFavorecido"
                  label="Nome favorecido"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                {readOnly ? (
                  <InputV2
                    disabled
                    label="Cidade"
                    value={values.bancoCidade?.nomeEUf}
                  />
                ) : (
                  <Field
                    component={SelectCidades}
                    name="bancoCidade"
                    label="Cidade"
                    disabled={readOnly}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" id="formulario-subtitle">
                  Usuário
                </Typography>
              </Grid>
              <Grid item xs={3} md={12}>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="ativo"
                  color="primaryPatern"
                  Label={{
                    label: 'Ativo',
                  }}
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{marginTop: 'auto', padding: '30px 0 0 16px'}}>
              <Grid item xs={3} style={{marginRight: 'auto'}}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row">
                  {readOnly && (
                    <ButtonComponent
                      sx={styles.buttonWrapper}
                      type="button"
                      value={'Editar'}
                      icon={<Edit />}
                      onClick={() => setReadOnly(false)}
                    />
                  )}
                  {!readOnly && (
                    <ButtonComponent
                      type="submit"
                      sx={styles.buttonWrapper}
                      value={'Salvar'}
                      loading={isSubmitting}
                      icon={<Save />}
                    />
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <ButtonComponent
                  type="button"
                  id="button-green"
                  sx={styles.buttonWrapper}
                  value="Gerar nova senha"
                  icon={<Key />}
                  onClick={handleOpenConfirmAlert}
                  disabled={!readOnly}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {imagemModal.visible && (
        <ImagemModal
          visible={imagemModal.visible}
          id={imagemModal.id}
          titulo={imagemModal.titulo}
          onClose={() => setImagemModal({visible: false, id: null, titulo: ''})}
        />
      )}
    </Box>
  );
};

const ComercialScheme = Yup.object().shape({
  nomeRazao: Yup.string().required('Campo obrigatório'),
  cpfCnpj: Yup.string().required('Campo obrigatório'),
  cep: Yup.string().required('Campo obrigatório'),
  cidade: Yup.object().nullable().required('Campo obrigatório'),
  role: Yup.object().nullable().required('Campo obrigatório'),
  logradouro: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  numero: Yup.string().required('Campo obrigatório'),
  nomeContato: Yup.string().required('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  celular: Yup.string().required('Campo obrigatório'),
  porcentagemValorRepasse: Yup.number().required('Campo obrigatório'),
});

const styles = {
  buttonWrapper: {
    width: '256px',

    '@media (max-width: 960px)': {
      width: '100%',
    },
  },
  icon: {
    fontSize: '18px',
    marginLeft: '10px',
  },
  multiselect: {
    '& .react-select__multi-value': {
      borderRadius: '6px',
      color: '#afb7c9',
    },
    '& .react-select__multi-value__label': {
      color: '#657496',
    },
  },
};

const Form = styled('form')(() => {});

export default FormularioCadastroLicencas;
