import React from 'react';
import {Typography} from '@mui/material';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const toastNotification = ({titulo, message, autoClose = 5000, ...props}) => {
  return toast((
    <div>
      {titulo && <Typography style={{fontWeight: '500'}}>{titulo}</Typography>}
      <Typography>{message?.replace('GraphQL error:', '')}</Typography>
    </div>
  ), {
    autoClose,
    hideProgressBar: true,
    ...props
  });
}

