import React, {useState} from 'react';

import {ModalWithInput} from '../../../containers/modais/aviso-modal/modais-aviso';
import {useEffect} from 'react';

const ModalMotivoReprovacao = ({submitMotivo, cancelar, openModal}) => {
  let [motivo, setMotivo] = useState('');

  const onClick = () => {
    if (!motivo?.trim())
      return;

    submitMotivo(motivo);
  }

  useEffect(() => {
    if (openModal) return;
    
    setMotivo('');
  }, [openModal]);

  return (
    <ModalWithInput
      titulo={'Motivo para a repovação:'}
      dividerTitle
      textArea
      inputOnChange={setMotivo}
      onClose={cancelar}
      onClick={onClick}
      inputValue={motivo}
      openModal={openModal}
      ButtonSecundary='Reprovar'
    />
  );
};

export default ModalMotivoReprovacao;
