import React from 'react';
import {FIND_ALL_ESTADOS as query} from '../../graphql/queries';
import {SelectBase} from './mui-base';

const transformOptions = ({content}) => {
  return Array.isArray(content)
    ? content.map((item) => ({
        ...item,
        label: `${item.nome} / ${item.uf}`,
        value: item.id,
      }))
    : [];
};

const getVariables = ({pageNumber, search = '', pageSize = 30}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nome', sortDir: 'ASC'},
    searchDTO: {search},
  };
};

export const SelectEstados = (props) => {
  return (
    <SelectBase
      label="Estado"
      responseObjectName="estados"
      transformOptions={transformOptions}
      query={query}
      getVariables={getVariables}
      {...props}
    />
  );
};
