import createNumberMask from 'text-mask-addons/src/createNumberMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/src/createAutoCorrectedDatePipe';
import String from '../utils/string';

const masks = {
  data: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  cnpj: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  cpf: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  cpfCnpj: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
  ],
  phone: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  cellPhone: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export const getMaskByType = (maskType, value) => {
  const rawValue = String.removeSpecialChars(value);
  if (
    maskType === 'phone' &&
    typeof rawValue === 'string' &&
    rawValue.length < 11
  ) {
    return masks.phone;
  }
  if (
    maskType === 'phone' &&
    typeof rawValue === 'string' &&
    rawValue.length >= 11
  ) {
    return masks.cellPhone;
  }

  if (
    maskType === 'cpfCnpj' &&
    typeof rawValue === 'string' &&
    rawValue.length <= 11
  ) {
    return masks.cpfCnpj;
  }
  if (
    maskType === 'cpfCnpj' &&
    typeof rawValue === 'string' &&
    rawValue.length > 11
  ) {
    return masks.cnpj;
  }

  if (maskType === 'moeda') {
    return createNumberMask({
      prefix: 'R$ ',
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
      allowDecimal: true,
    });
  }

  if (maskType === 'numero') {
    return createNumberMask({
      prefix: '',
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
      allowDecimal: false,
    });
  }

  if (maskType === 'decimal') {
    return createNumberMask({
      prefix: '',
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
      allowDecimal: true,
    });
  }

  if (maskType === 'porcento') {
    return createNumberMask({
      prefix: '',
      suffix: ' %',
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
      allowDecimal: true,
    });
  }

  if (maskType === 'data') {
    return {
      mask: masks.data,
      pipe: createAutoCorrectedDatePipe('dd/MM/yyyy'),
    };
  }

  return masks[maskType] || null;
};

export default masks;
