import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  monitorarCampanhas: [],
  filtrosMonitorarCampanhas: {
    filtros: {campanhaSituacao: {value: 'ATIVA', label: 'Ativa'}},
    page: 0,
    orderBy: {campo: 'dataInicioVeiculacao', asc: false}
  },
  hasMore: false,
  totalMonitorarCampanhas: 0,
  refetchMonitorarCampanhas: false,
};

const acaoAtualizarMonitorarCampanhas = state => {
  state.refetchMonitorarCampanhas = true;
};

const acaoPersistMonitorarCampanhas = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.monitorarCampanhas = content;
  state.hasMore = !last;
  state.totalMonitorarCampanhas = totalElements || 0;
  state.refetchMonitorarCampanhas = false;
};

const acaoPersistFiltrosMonitorarCampanha = (state, action) => {
  state.filtrosMonitorarCampanhas = action.payload;
};

const resumeSlice = createSlice({
  name: 'monitorarCampanha',
  initialState: initialState,
  reducers: {
    persistMonitorarCampanhas: acaoPersistMonitorarCampanhas,
    atualizarMonitorarCampanhas: acaoAtualizarMonitorarCampanhas,
    persistFiltrosMonitorarCampanha: acaoPersistFiltrosMonitorarCampanha
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistMonitorarCampanhas, atualizarMonitorarCampanhas, persistFiltrosMonitorarCampanha} = actions;
