import React, {useEffect, useState} from 'react';
import {Paper, Tab, Tabs, Grid} from '@mui/material';
import {Circle} from '@mui/icons-material';
import {Field, Formik} from 'formik';
import TabPanel from '../../../components/tab-painel';
import VeiculosOrcadosCidades from './tabela';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import {ButtonComponent} from '../../../components/mui-button';

const ConsultaVeiculosOrcadosCidades = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchDTO, setSearchDTO] = useState(filtros);

  const refetch = () => {
    setSearchDTO(filtros);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const a11yProps = (index) => {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  component={SelectCidades}
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={8} display="flex" justifyContent="flex-end">
                <ButtonComponent
                  sx={{width: '256px'}}
                  value="Filtrar"
                  loading={loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <Paper style={{padding: 15, position: 'relative'}}>
            <Tabs
              value={tabIndex}
              onChange={(event, index) => setTabIndex(index)}
              variant="standard"
              aria-label="nav tabs example"
              className="tabs">
              <Tab
                className="tab-button"
                iconPosition="start"
                icon={<Circle color="cancel" />}
                label={'Aguardando P.I.'}
                {...a11yProps(0)}
              />
              <Tab
                className="tab-button"
                iconPosition="start"
                icon={<Circle color="yellow" />}
                style={{zIndex: 4}}
                label={'Negociação'}
                {...a11yProps(1)}
              />
              <Tab
                className="tab-button"
                iconPosition="start"
                icon={<Circle color="green" />}
                style={{zIndex: 3}}
                label={'Prospecção'}
                {...a11yProps(2)}
              />
            </Tabs>
            <TabPanel sx={styles.tabPainel} value={tabIndex} index={0}>
              <VeiculosOrcadosCidades
                filtros={{situacao: 'AGUARDANDO_PI', cidade: searchDTO.cidade}}
                setLoading={setLoading}
              />
            </TabPanel>
            <TabPanel sx={styles.tabPainel} value={tabIndex} index={1}>
              <VeiculosOrcadosCidades
                filtros={{situacao: 'EM_NEGOCIACAO', cidade: searchDTO.cidade}}
                setLoading={setLoading}
              />
            </TabPanel>
            <TabPanel sx={styles.tabPainel} value={tabIndex} index={2}>
              <VeiculosOrcadosCidades
                filtros={{situacao: 'PROSPECCAO', cidade: searchDTO.cidade}}
                setLoading={setLoading}
              />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  tabPainel: {
    height: 'calc(100vh - 280px)',
    margin: '10px 0',
    overflowY: 'auto',

    '& .MuiBox-root': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'inherit',
    },
  },
};

export default ConsultaVeiculosOrcadosCidades;
