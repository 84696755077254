import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useQuery} from '@apollo/client';
import {Paper, Button, Grid, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination} from '@mui/material';
import {Add, Edit} from '@mui/icons-material';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import {FIND_ALL_MARCA_VEICULO} from '../../graphql/queries';
import InputV2 from '../../components/input-v2/mui-input-v2';
import TableCellOrdered from '../../components/table-cell-ordered';
import inputsEvents from '../../utils/inputsEvents';
import {
  persistFiltrosMarcasVeiculo,
  persistMarcasVeiculo,
} from '../../redux/MarcasVeiculo/slice';
const columns = [{id: 'nome', label: 'Marca'}];

const ConsultaMarcaVeiculo = ({pageSize = 20}) => {
  const {
    filtrosMarcasVeiculo,
    refetchMarcasVeiculo,
    totalMarcasVeiculo,
    marcasVeiculo,
  } = useSelector((store) => store.MarcasVeiculo);

  const [page, setPage] = useState(filtrosMarcasVeiculo.page);
  const [rowsPerPage] = useState(pageSize);
  const [orderBy, setOrderBy] = useState(filtrosMarcasVeiculo.orderBy);
  const [filtros, setFiltros] = useState(filtrosMarcasVeiculo.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const marcasQuery = useQuery(FIND_ALL_MARCA_VEICULO, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO,
    },
  });

  useEffect(() => {
    if (!marcasQuery.loading && !marcasQuery.erro && marcasQuery.data?.marcas) {
      dispatch(persistFiltrosMarcasVeiculo({filtros, page, orderBy}));
      dispatch(persistMarcasVeiculo(marcasQuery.data?.marcas || []));
    }
    if (refetchMarcasVeiculo) {
      marcasQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marcasQuery, dispatch, refetchMarcasVeiculo]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid id="page-container" container>
      <Button
        id="button-green"
        startIcon={<Add />}
        toolbar="true"
        onClick={() => navigate(`criar`)}
      >
        Cadastrar novo
      </Button>
      <Grid item xs={12} onKeyUp={({key}) => inputsEvents.handleKeyUp(key, refetch)}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <InputV2
                  label="Marcas"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  value="Filtrar"
                  loading={marcasQuery.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell id="acoes" key="acoes" align="right">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {marcasVeiculo.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="right">
                          <Grid marginRight="-5px">
                            <RoundTooltipButton
                              id="icon-primary"
                              title={'Editar'}
                              onClick={() => navigate(`${row.id}/editar`)}
                              icon={<Edit />}
                            />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalMarcasVeiculo}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'página anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default ConsultaMarcaVeiculo;
