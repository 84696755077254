import {useQuery} from '@apollo/client';
import {Add, Visibility} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {Field, Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import Select from '../../components/select/mui-select';
import TableCellOrdered from '../../components/table-cell-ordered';
import {
  auncioImagemSituacao,
  imagemAnuncioTipo,
} from '../../constants/enum-labels';
import {SelectCampanhas} from '../../containers/selects/mui-campanhas';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {IMAGENS_PENDENTES_DE_VALIDACAO} from '../../graphql/queries';
import {
  persistFiltroImagensPendentes,
  persistValidacaoImagens,
} from '../../redux/ValidacaoImagens/slice';

const columns = [
  {
    id: 'anuncio',
    label: 'Titulo',
    campo: 'anuncio.campanha.titulo',
    format: ({anuncio}) =>
      `[${anuncio?.campanha?.numero}] ${anuncio?.campanha?.titulo}`,
  },
  {
    id: 'cidade',
    label: 'Cidade',
    campo: 'anuncio.campanha.cidade.nome',
    format: ({anuncio}) =>
      anuncio?.campanha?.cidade
        ? `${anuncio.campanha.cidade.nome}/${anuncio.campanha.cidade.uf}`
        : '-',
  },
  {
    id: 'data',
    label: 'Data de Envio',
    format: ({data}) =>
      data ? moment(data).format('DD/MM/YYYY HH:mm:ss') : '-',
  },
  {
    id: 'motorista',
    label: 'Nome do Motorista',
    campo: 'motorista.nome',
    format: ({anuncio, motorista}) => {
      if (anuncio?.motorista?.id === motorista?.id)
        return motorista?.nome || '';

      return motorista?.nome + `  (${anuncio?.motorista?.nome})`;
    },
  },
  {
    id: 'veiculo',
    label: 'Modelo do Veículo',
    campo: 'veiculo.modelo.nome',
    format: ({veiculo}) => veiculo?.modelo?.nome || '-',
  },
  {
    id: 'placa',
    label: 'Placa do Veículo',
    campo: 'veiculo.placa',
    format: ({veiculo}) => veiculo?.placa?.toUpperCase() || '-',
  },
  {
    id: 'tipo',
    label: 'Tipo',
    format: ({tipo}) => imagemAnuncioTipo[tipo],
  },
  {
    id: 'situacao',
    label: 'Situação',
    format: ({situacao}) => auncioImagemSituacao[situacao],
  },
];

const ConsultaValidacaoImagens = ({pageSize = 20}) => {
  const {
    imagensList,
    totalValidacaoImagens,
    refetchValidacaoImagens,
    filtrosImagensPendentes,
  } = useSelector((store) => store.ValidacaoImagens);

  const [page, setPage] = useState(filtrosImagensPendentes.page);
  const [filtros, setFiltros] = useState(filtrosImagensPendentes.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosImagensPendentes.orderBy);
  const [rowsPerPage] = useState(pageSize);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {role} = useSelector((store) => store.Auth);

  const anunciosQuery = useQuery(IMAGENS_PENDENTES_DE_VALIDACAO, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        campanhaId: searchDTO.campanha?.id || undefined,
        nomeMotorista: searchDTO.nomeMotorista || undefined,
        nomeVeiculo: searchDTO.nomeVeiculo || undefined,
        placaVeiculo: searchDTO.placaVeiculo || undefined,
        tipoImagem: searchDTO.tipoImagem?.value || undefined,
        cidadeId: searchDTO.cidade?.id || undefined,
        situacao: searchDTO.situacao?.value || undefined,
      },
    },
  });

  useEffect(() => {
    if (!anunciosQuery.loading && !anunciosQuery.error) {
      dispatch(persistValidacaoImagens(anunciosQuery.data.findAllImagens));
      dispatch(persistFiltroImagensPendentes({filtros, page, orderBy}));
    }
    if (refetchValidacaoImagens) {
      anunciosQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anunciosQuery, dispatch, refetchValidacaoImagens]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };
  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      {[
        'ROLE_ADMINISTRADOR',
        'ROLE_GERENTE_OPERACIONAL',
        'ROLE_OPERACIONAL',
      ].includes(role) && (
        <Button
          startIcon={<Add style={{marginRight: 10}} />}
          id="button-green"
          toolbar="true"
          onClick={() => navigate(`criar`)}>
          Cadastrar novo
        </Button>
      )}
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  component={SelectCidades}
                  label="Cidade"
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={({...props}) => (
                    <SelectCampanhas
                      searchDTO={{
                        campanhaSituacoesIn: [
                          'ATIVA_PARCIAL',
                          'ATIVA',
                          'FINALIZADA',
                        ],
                        cidadeCampanhaId: filtros.cidade?.value,
                      }}
                      {...props}
                    />
                  )}
                  label="Campanha"
                  name="campanha"
                  onChange={(e) => setFiltros({...filtros, campanha: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Tipo"
                  value={filtros.tipoImagem}
                  onChange={(e) =>
                    setFiltros({...filtros, tipoImagem: e || null})
                  }
                  options={[
                    {label: 'Todos'},
                    {value: 'INSTALACAO', label: 'Instalação'},
                    {value: 'MENSAL', label: 'Mensal'},
                    {value: 'REMOCAO', label: 'Remoção'},
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Situação"
                  value={filtros.situacao}
                  onChange={(e) =>
                    setFiltros({...filtros, situacao: e || null})
                  }
                  options={[
                    {label: 'Todas'},
                    {value: 'PENDENTE', label: 'Pendente'},
                    {value: 'APROVADO', label: 'Aprovado'},
                    {value: 'REPROVADO', label: 'Reprovado'},
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  label="Motorista"
                  name="nomeMotorista"
                  onChange={({target}) =>
                    setFiltros({...filtros, nomeMotorista: target.value})
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={InputV2Formik}
                  label="Veículo"
                  name="nomeVeiculo"
                  onChange={({target}) =>
                    setFiltros({...filtros, nomeVeiculo: target.value})
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={InputV2Formik}
                  label="Placa"
                  name="placaVeiculo"
                  onChange={({target}) =>
                    setFiltros({...filtros, placaVeiculo: target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  value="Filtrar"
                  loading={anunciosQuery.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell id="acoes" key="acoes" align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {imagensList.map((row, i) => {
                    return (
                      <TableRow
                        key={i}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        onClick={() => navigate(`${row.id}/aprovar`)}
                        style={{cursor: 'pointer'}}>
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={index} align={column.align}>
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <RoundTooltipButton
                            id="icon-primary"
                            title={'Visualizar'}
                            onClick={() => navigate(`${row.id}/aprovar`)}
                            icon={<Visibility />}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalValidacaoImagens}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'página anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaValidacaoImagens;
