import React, {useEffect, useState} from 'react';
import {HashRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {GET_ADMINISTRADOR_LOGADO} from '../graphql/queries';
import {persistUser} from '../redux/Auth/slice';
import {SidebarComponent} from '../containers/sidebar';
import {ToolBarComponent} from '../containers/toolbar';
import {
  DASHBOARD_ROUTE,
  CAMPANHAS_ROUTE,
  MODELO_ANUNCIOS_ROUTE,
  MOTORISTAS_ROUTE,
  ANUNCIANTES_ROUTE,
  COMERCIAL_ROUTE,
  INSTALADOR_ROUTE,
  FALE_CONOSCO_ROUTE,
  VEICULOS_PENDENTES_INSTALACAO_ROUTE,
  VEICULOS_PENDENTES_REMOCAO_ROUTE,
  ADMINISTRADOR_ROUTE,
  ANUNCIOS_PARADOS_ROUTE,
  IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE,
  EXTRATO_REPASSE_ROUTE,
  CONFIGURACOES_ROUTE,
  MODELO_VEICULO_ROUTE,
  MARCA_VEICULO_ROUTE,
  ESTADOS_ROUTE,
  CIDADES_ROUTE,
  PROPOSTAS_ROUTE,
  RELATORIOS_ROUTE,
  NOTAS_FISCAIS_PENDENTES_ROUTE,
  NOTIFICAR_MOTORISTAS_ROUTE,
  MONITORAR_CAMPANHAS_ROUTE,
  OFICINAS_ROUTE,
  SEGMENTO_ATUACAO_ROUTE,
  MATERIAIS_VENDA_ROUTE,
  TABELA_PRECO_ROUTE,
  LICENCAS_ROUTE,
  CAMPANHAS_FALTANDO_VEICULOS_ROUTE
} from '../constants/routes';
import ScrollTop from './scroll-top';
import Components from '../views/components';
import HomeView from '../views/home';
import CampanhasView from '../views/campanhas';
import ModeloAnunciosView from '../views/modelo-anuncios';
import MotoristasView from '../views/motoristas';
import MateriaisVendaView from '../views/materiais-venda'
import AnunciantesView from '../views/anunciantes';
import ComercialView from '../views/comercial';
import AdministradorView from '../views/administrador';
import InstaladorView from '../views/instalador';
import FaleConoscoView from '../views/fale-conosco';
import AnunciosAguardandoInstalacaoView from '../views/anuncios-aguardando-instalacao';
import AnunciosAguardandoRemocaoView from '../views/anuncios-aguardando-remocao';
import ExtratoRepasseView from '../views/extrato-repasse';
import AnunciosParadosView from '../views/anuncios-parados';
import ImagensPendentesDeValidacaoView from '../views/imagens-pendentes-validacao';
import ConfiguracoesView from '../views/configuracoes';
import ModeloVeiculoView from '../views/modelo-veiculo';
import MarcaVeiculoView from '../views/marca-veiculo';
import EstadosView from '../views/estados';
import CidadesView from '../views/cidades';
import PropostasView from '../views/propostas';
import RelatoriosView from '../views/relatorios';
import NotasFiscaisPendentesView from '../views/notas-fiscais-pendentes';
import NotificarMotoristasView from '../views/notificar-motoristas';
import MonitorarCampanhasView from '../views/monitorar-campanhas';
import OficinasView from '../views/oficinas';
import SegmentoAtuacaoView from '../views/segmento-atuacao';
import TabelaPrecoView from '../views/tabela-preco';
import LicencasView from '../views/licencas';
import ConsultaCampanhasFaltandoVeiculos from '../views/campanhas-faltando-veiculos/consulta';

export const RouterComponent = () => {
  const dispatch = useDispatch();

  const {sidebarOpen} = useSelector(state => state.Sidebar);
  const [role, setRole] = useState(undefined);

  const administradorQuery = useQuery(GET_ADMINISTRADOR_LOGADO);

  useEffect(() => {
    if (administradorQuery.loading || !administradorQuery.data?.administrador) return;

    const admin = administradorQuery.data?.administrador;
    dispatch(persistUser(admin));
    window.localStorage.setItem('usuario', JSON.stringify(admin));
    setRole(admin?.usuario?.authorities?.at(0)?.nome);

  }, [administradorQuery, dispatch]);

  if (!role) return <Router />

  return (
    <Router>
      <FullWrapper>
        <SidebarComponent />
        <Content open={sidebarOpen}>
          <ToolBarComponent />
          <Main>
            <ScrollTop />
            <Routes>
              <Route path={`${DASHBOARD_ROUTE}/*`} element={<HomeView />} />
              <Route path={`${CAMPANHAS_ROUTE}/*`} element={<CampanhasView />} />
              <Route path={`${MODELO_ANUNCIOS_ROUTE}/*`} element={<ModeloAnunciosView />} />
              <Route path={`${MOTORISTAS_ROUTE}/*`} element={<MotoristasView />} />
              <Route path={`${ANUNCIANTES_ROUTE}/*`} element={<AnunciantesView />} />
              <Route path={`${COMERCIAL_ROUTE}/*`} element={<ComercialView />} />
              {['ROLE_ADMINISTRADOR', 'ROLE_GERENTE_OPERACIONAL'].includes(role) && (
                <Route path={`${ADMINISTRADOR_ROUTE}/*`} element={<AdministradorView />} />
              )}
              <Route path={`${INSTALADOR_ROUTE}/*`} element={<InstaladorView />} />
              <Route path={`${FALE_CONOSCO_ROUTE}/*`} element={<FaleConoscoView />} />
              <Route path={`${VEICULOS_PENDENTES_INSTALACAO_ROUTE}/*`} element={<AnunciosAguardandoInstalacaoView />} />
              <Route path={`${VEICULOS_PENDENTES_REMOCAO_ROUTE}/*`} element={<AnunciosAguardandoRemocaoView />} />
              <Route path={`${EXTRATO_REPASSE_ROUTE}/*`} element={<ExtratoRepasseView />} />
              <Route path={`${IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE}/*`} element={<ImagensPendentesDeValidacaoView />} />
              <Route path={`${ANUNCIOS_PARADOS_ROUTE}/*`} element={<AnunciosParadosView />} />
              <Route path={`${CONFIGURACOES_ROUTE}/*`} element={<ConfiguracoesView />} />
              <Route path={`${MODELO_VEICULO_ROUTE}/*`} element={<ModeloVeiculoView />} />
              <Route path={`${MARCA_VEICULO_ROUTE}/*`} element={<MarcaVeiculoView />} />
              <Route path={`${ESTADOS_ROUTE}/*`} element={<EstadosView />} />
              <Route path={`${CIDADES_ROUTE}/*`} element={<CidadesView />} />
              <Route path={`${PROPOSTAS_ROUTE}/*`} element={<PropostasView />} />
              <Route path={`${RELATORIOS_ROUTE}/*`} element={<RelatoriosView />} />
              <Route path={`${NOTAS_FISCAIS_PENDENTES_ROUTE}/*`} element={<NotasFiscaisPendentesView />} />
              <Route path={`${NOTIFICAR_MOTORISTAS_ROUTE}/*`} element={<NotificarMotoristasView />} />
              <Route path={`${MONITORAR_CAMPANHAS_ROUTE}/*`} element={<MonitorarCampanhasView />} />
              <Route path={`${OFICINAS_ROUTE}/*`} element={<OficinasView />} />
              <Route path={`${SEGMENTO_ATUACAO_ROUTE}/*`} element={<SegmentoAtuacaoView />} />
              <Route path={`${MATERIAIS_VENDA_ROUTE}/*`} element={<MateriaisVendaView />} />
              <Route path={`${TABELA_PRECO_ROUTE}/*`} element={<TabelaPrecoView />} />
              <Route path={`${LICENCAS_ROUTE}/*`} element={<LicencasView />} />
              <Route path={`${CAMPANHAS_FALTANDO_VEICULOS_ROUTE}/*`} element={<ConsultaCampanhasFaltandoVeiculos />} />
              <Route path="/components" element={<Components />} />
              <Route path="*" element={<Navigate to={DASHBOARD_ROUTE} />} />
            </Routes>
          </Main>
        </Content>
      </FullWrapper>
    </Router >
  );
};

const Content = styled.div`
  display: inline-block;
  width: 100%;
  transition: padding-left .2s ease-in-out;
  padding-left: ${({theme, open}) => open ? `calc(${theme.sidebarWidth} + 300px)` : theme.sidebarWidth}
`;

const FullWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.palette.paterns.background};
`;

const Main = styled.main`
  width: 100%;
  padding: 0;
  height: ${({theme}) => `calc(100vh - ${theme.toolbarHeight})`};
  overflow-y: auto;
`;