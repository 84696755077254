import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaCampanha from './consulta';
import EditarCampanha from './editar';
import TrocarMotoristaCampanha from './trocar-motorista';
import IniciarCampanhaManualmente from './iniciar-manualmente';

const Campanhas = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaCampanha />} />
      <Route path={`/:campanhaId/editar`} element={<EditarCampanha />} />
      <Route path={`/:campanhaId/adicionar-motoristas`} element={<IniciarCampanhaManualmente />} />
      <Route path={`/:campanhaId/iniciar-manualmente`} element={<IniciarCampanhaManualmente />} />
      <Route path={`/:campanhaId/trocar-motorista/:motoristaId`} element={<TrocarMotoristaCampanha />} />
    </Routes>
  );
};

export default Campanhas;
