import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  imagensList: [],
  filtrosImagensPendentes: {
    filtros: {
    
      situacao: {label:'Todas'},
      tipoImagem:{label: 'Todos'}
    },
    page: 0,
    orderBy: {campo: 'data', asc: false}
  },
  hasMore: false,
  totalValidacaoImagens: 0,
  refetchValidacaoImagens: false,
};

const acaoAtualizarValidacaoImagens = state => {
  state.refetchValidacaoImagens = true;
};

const acaoPersistValidacaoImagens = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.imagensList = [...content];
  state.hasMore = !last;
  state.totalValidacaoImagens = totalElements || 0;
};

const acaoPersistFiltroImagensPendentes = (state, action) => {
  state.filtrosImagensPendentes = action.payload;
};

const resumeSlice = createSlice({
  name: 'validacaoImagens',
  initialState: initialState,
  reducers: {
    persistValidacaoImagens: acaoPersistValidacaoImagens,
    atualizarValidacaoImagens: acaoAtualizarValidacaoImagens,
    persistFiltroImagensPendentes: acaoPersistFiltroImagensPendentes
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistValidacaoImagens, atualizarValidacaoImagens, persistFiltroImagensPendentes} = actions;
