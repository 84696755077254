import React from 'react';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {FIND_BY_ID_SEGMENTO} from '../../graphql/queries';
import FormularioAgencia from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarSegmento = () => {
  const {segmentoId} = useParams();

  const segmentoQuery = useQuery(FIND_BY_ID_SEGMENTO, {
    variables: {
      id: segmentoId,
    },
  });

  const {data = {}} = segmentoQuery;
  const {segmento = {}} = data;

  const refetch = () => {
    segmentoQuery.refetch();
  };

  if (segmentoQuery.loading || segmentoQuery.error) {
    return (
      <LoadingPage loading={segmentoQuery.loading} error={segmentoQuery.error} />
    );
  };
  return (
    <FormularioAgencia segmento={segmento} refetch={refetch} />
  );
};

export default EditarSegmento;
