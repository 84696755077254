import React from 'react';
import {Box, Grid, Modal, Typography} from '@mui/material';
import {Field, Formik} from 'formik';
import {SelectInstaladores} from '../../../containers/selects/mui-instaladores';
import {ButtonComponent} from '../../../components/mui-button';

const InstaladorModal = ({openModal, instalador, onClose, onChange, iniciarCampanha}) => {
  return (
    <Modal
      open={openModal}
      onClose={() => onClose()}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}
    >
      <Box>
        <Formik
          initialValues={{instalador}}
          enableReinitialize
        >
          <Grid sx={styles.modalBody}>
            <Typography sx={styles.modalTitle}>
              Selecione o instalador
            </Typography>
            <Field
              component={SelectInstaladores}
              label=""
              name="instalador"
              className="select"
              isClearable
              onChange={(e) => onChange(e)}
            />
            <Grid container sx={styles.modalButtonContainer} spacing={2}>
              <Grid item xs={6}>
                <ButtonComponent
                  id="button-cancel"
                  value="Cancelar"
                  onClick={() => onClose()}
                />
              </Grid>
              <Grid item xs={6}>
                <ButtonComponent
                  id="button"
                  value="Iniciar campanha"
                  onClick={iniciarCampanha}
                  disabled={!instalador}
                />
              </Grid>
            </Grid>
          </Grid>
        </Formik>
      </Box>
    </Modal>
  )
}

const styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalBody: {
    padding: '20px',
    background: '#fff',
    borderRadius: '5px',
    minWidth: '400px',
    display: 'flex',
    flexDirection: 'column'
  },
  modalTitle: {
    fontWeight: '700',
    marginBottom: '16px'
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5px'
  },
};

export default InstaladorModal;