import {Visibility} from '@mui/icons-material';
import {Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from '@mui/material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RoundTooltipButton} from '../../components/mui-button';

const columns = [
  {id: 'titulo', label: 'Título'},
  {id: 'descricao', label: 'Descrição'},
];

const ConsultaRelatorios = ({pageSize = 20}) => {
  const {role} = useSelector((store) => store.Auth);
  const isOperacional = role.includes('OPERACIONAL');
  const isComercial = role.includes('COMERCIAL');

  const [rowsPerPage] = useState(pageSize);
  const [page, setPage] = useState(0);
  const [relatorios] = useState([
    {id: 'fc800d0c', titulo: 'Carteira de clientes', descricao: 'Lista as agências e os anunciantes cadastrados', rota: 'carteira-clientes', hidden: isOperacional},
    {id: '04f8dca2', titulo: 'Impactos totais', descricao: 'Simulação do total de impactos de todos os motoristas aprovados no país', rota: 'impactos-totais', hidden: isOperacional},
    {id: '0942b62a', titulo: 'Motoristas pagos', descricao: 'Lista pagamentos feitos aos motoristas', rota: 'motoristas-pagos', hidden: isComercial},
    {id: '1625d232', titulo: 'Motoristas que indicaram', descricao: 'Lista de motoristas que fizeram indicações de motoristas', rota: 'motoristas-indicacoes', hidden: isComercial},
    {id: '19244428', titulo: 'Motoristas indicados', descricao: 'Lista de motoristas indicados', rota: 'motoristas-indicados', hidden: isComercial},
    {id: '1c13df04', titulo: 'Motoristas substituídos', descricao: 'Relatório de motoristas substituídos em campanha', rota: 'motoristas-substituidos', hidden: isComercial},
    {id: '1fe67308', titulo: 'Notas fiscais pendentes', descricao: 'Notas fiscais aguardando emissão', rota: 'notas-fiscais-pendentes', hidden: isComercial},
    {id: '23372728', titulo: 'Quilometro rodado por cidade', descricao: 'Relatório de quilometro rodado por cidade durante as campanhas', rota: 'quilometragem-cidade', hidden: isOperacional},
    {id: 'fd89be5e', titulo: 'Resultado das campanhas', descricao: 'Relatório impactos, quilometro, passageiros ', rota: 'campanhas-resultados'},
    {id: '2667e536', titulo: 'Vendas', descricao: 'Lista de campanhas vendidas', rota: 'campanhas-vendidas', hidden: isOperacional},
  ]);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell column={column} key={column.id}>
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell id="acoes" key="acoes" align="center">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {relatorios.filter(r => !r.hidden).map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      style={{cursor: 'pointer'}}
                      onClick={() => navigate(`${row.rota}`)}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="center">
                        <RoundTooltipButton
                          id="icon-primary"
                          title={'Visualizar'}
                          icon={<Visibility />}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={relatorios.filter(r => !r.hidden).length}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'próxima página',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ConsultaRelatorios;
