import {Loader} from '@googlemaps/js-api-loader';
import {useEffect, useRef, useState} from 'react';
import config from '../config/config';

const apiKey = config.GOOGLE_MAPS_API_KEY;
const eventsMapping = {
  onCenterChanged: ['center_changed', ({map}) => map.getCenter()],
  onBoundsChangerd: ['bounds_changed', ({map}) => map.getBounds()],
};

export default function useGoogleMap({
  zoom,
  center,
  mapTypeId = 'roadmap',
  events = {},
  options = {}
}) {
  const [mapState, setMapState] = useState({loading: true});
  const mapRef = useRef();
  useEffect(() => {
    new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["visualization"]
    }).load().then(google => {
      const map = new google.maps.Map(mapRef.current, {
        zoom,
        center,
        mapTypeId,
        streetViewControl: false,
        ...options
      });
      Object.keys(events).forEach(eventName =>
        map.addListener(eventsMapping[eventName][0], (event) =>
          events[eventName](eventsMapping[eventName][1]({map, event})),
        ),
      );

      setMapState({maps: google.maps, map, loading: false});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {mapRef, ...mapState};
}
