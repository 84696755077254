import {useEffect} from 'react';

const Place = require('../assets/icons/place.png');

const eventMapping = {
  onClick: 'click',
  onDoubleClick: 'dblclick',
  onMouseOver: 'mouseover',
  onMouseOut: 'mouseout',
};

export default function useGoogleMapMarker({
  position,
  active = false,
  maps,
  map,
  events,
  title,
  icon = Place
}) {
  useEffect(() => {
    const marker = new maps.Marker({
      position,
      map,
      title,
    });
    marker.setIcon(icon);
    Object.keys(events).forEach(eventName =>
      marker.addListener(eventMapping[eventName], () => {
        events[eventName](map, marker);
      }),
    );

    return function cleanup() {
      marker.setMap(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return null;
}
