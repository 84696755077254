import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
} from '@mui/material';
import {FIND_ALL_ANUNCIOS} from '../../../graphql/queries';
import {RoundTooltipButton} from '../../../components/mui-button';
import TableCellOrdered from '../../../components/table-cell-ordered';
import {StarHalf} from '@mui/icons-material';
import {Paper} from '@mui/material';
import Number from '../../../utils/number';
import AvaliacaoModal from '../modal/avaliacao-modal';

const columns = [
  {id: 'campanhaTitulo', label: 'Campanha'},
  {
    id: 'campanha',
    label: 'Cidade',
    campo: 'campanha.cidade.nome',
    format: ({campanha}) =>
      campanha?.cidade ? `${campanha.cidade.nome}/${campanha.cidade.uf}` : '-',
  },
  {
    id: 'veiculo',
    label: 'Veículo',
    campo: 'veiculo.modelo.marca.nome',
    format: ({veiculo}) =>
      veiculo?.modelo?.marca
        ? `${veiculo.modelo.marca.nome} - ${veiculo.modelo.nome} ${veiculo.anoFabricacao}/${veiculo.anoModelo}`
        : '-',
  },
  {
    id: 'quantidadeMeses',
    label: 'Períodos',
  },
  {
    id: 'kmTotalPorMes',
    label: 'Km/período',
    format: ({kmTotalPorMes}) => Number.format(parseInt(kmTotalPorMes)),
  },
  {
    id: 'kmRodado',
    label: 'Km rodado',
    format: ({kmRodado}) => Number.format(parseInt(kmRodado)),
  },
  {
    id: 'mediaKmRodado',
    label: 'Média Km rodado',
    calculado: true,
    format: ({kmRodado, quantidadeMeses = 1}) =>
      Number.format(parseInt(kmRodado / quantidadeMeses)),
  },
  {
    id: 'notaKmRodado',
    label: 'Avaliação Km',
    campo: 'avaliacaoMotorista.notaKmRodado',
    format: ({avaliacaoMotorista}) =>
      avaliacaoMotorista?.id
        ? parseFloat(avaliacaoMotorista.notaKmRodado).toFixed(1)
        : '',
  },
  {
    id: 'notaPessoal',
    label: 'Avaliação pessoal',
    campo: 'avaliacaoMotorista.notaPessoal',
    format: ({avaliacaoMotorista}) =>
      avaliacaoMotorista?.id
        ? parseFloat(avaliacaoMotorista.notaPessoal).toFixed(1)
        : '',
  },
  {
    id: 'nota',
    label: 'Avaliação final',
    campo: 'avaliacaoMotorista.nota',
    format: ({avaliacaoMotorista}) =>
      avaliacaoMotorista?.id
        ? parseFloat(avaliacaoMotorista.nota).toFixed(1)
        : '',
  },
  {
    id: 'pinturaAvariada',
    label: 'Pintura avariada',
    campo: 'avaliacaoMotorista.pinturaAvariada',
    format: ({avaliacaoMotorista}) => {
      if (!avaliacaoMotorista) return '';
      return avaliacaoMotorista?.pinturaAvariada ? 'Sim' : 'Não';
    },
  },
];

const DadosCampanhas = ({motorista = {}, refetchMotorista, role}) => {
  const [anuncios, setAnuncios] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);
  const [orderBy, setOrderBy] = useState({
    campo: 'dataInicioVeiculacao',
    asc: false,
  });
  const [anuncioSelecionado, setAnuncioSelecionado] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);

  const anunciosQuery = useQuery(FIND_ALL_ANUNCIOS, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        motoristaId: motorista.id,
      },
    },
  });

  useEffect(() => {
    if (anunciosQuery.loading || !!anunciosQuery.error) return;

    setAnuncios(anunciosQuery.data?.anuncios?.content || []);
  }, [anunciosQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const refetch = () => {
    anunciosQuery.refetch();
    refetchMotorista && refetchMotorista();
  };

  const handleClickOpenDetalhes = (anuncio) => {
    setAnuncioSelecionado(anuncio);
    setOpenModal(true);
  };

  const handleClickCloseDetalhes = () => {
    setAnuncioSelecionado(undefined);
    setOpenModal(false);
  };

  const permiteAcoes = () => {
    return !['ROLE_GERENTE_COMERCIAL', 'ROLE_FINANCEIRO'].includes(role);
  }

  return (
    <Grid container mt="16px" style={{padding: 0}}>
      <Grid item xs={12}>
        <Paper>
          <Box overflow="auto">
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                  {permiteAcoes() && (
                    <TableCell key="acoes" align="center">
                      Ações
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {anuncios.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                      {permiteAcoes() && (
                        <TableCell align="center">
                          <RoundTooltipButton
                            id="icon-yellow"
                            title={'Avaliação'}
                            onClick={() => handleClickOpenDetalhes(row)}
                            icon={<StarHalf />}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={anunciosQuery.data?.anuncios?.totalElements || 0}
            rowsPerPageOptions={[]}
            rowsPerPage={pageSize}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </Paper>
      </Grid>
      <AvaliacaoModal
        openModal={openModal}
        anuncio={anuncioSelecionado}
        motorista={motorista}
        onClose={handleClickCloseDetalhes}
        refetch={refetch}
      />
    </Grid>
  );
};

export default DadosCampanhas;
