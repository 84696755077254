import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  extratoRepasseComercialList: [],
  hasMore: false,
  totalExtratoRepasseComercial: 0,
  refetchExtratoRepasseComercial: false
};

const acaoAtualizarExtratoRepasseComercial = state => {
  state.refetchExtratoRepasseComercial = true;
};

const acaoPersistExtratoRepasseComercial = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.extratoRepasseComercialList = content;
  state.hasMore = !last;
  state.totalExtratoRepasseComercial = totalElements || 0;
  state.refetchExtratoRepasseComercial = false;
};

const resumeSlice = createSlice({
  name: 'extratoRepasseComercial',
  initialState: initialState,
  reducers: {
    persistExtratoRepasseComercial: acaoPersistExtratoRepasseComercial,
    atualizarExtratoRepasseComercial: acaoAtualizarExtratoRepasseComercial
  }
});

export const { actions, reducer } = resumeSlice;
export const {
  persistExtratoRepasseComercial,
  atualizarExtratoRepasseComercial
} = actions;
