import {useQuery} from '@apollo/client';
import {Add, OpenInNew, Visibility} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {Field, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import Select from '../../components/select/mui-select';
import TableCellOrdered from '../../components/table-cell-ordered';
import {comercialRole} from '../../constants/enum-labels';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {SelectEstados} from '../../containers/selects/mui-estados';
import {FIND_ALL_COMERCIAL} from '../../graphql/queries';
import {
  persistFiltrosLicencas,
  persistLicencas,
} from '../../redux/Licencas/slice';
import usePainelService from '../../services/PainelService';

const columns = [
  {id: 'nomeRazao', label: 'Razão Social'},
  {
    id: 'endereco',
    label: 'Cidade',
    campo: 'endereco.cidade.nome',
    format: (endereco) =>
      endereco?.cidade ? `${endereco.cidade.nome} / ${endereco.cidade.uf}` : '',
  },
  {
    id: 'role',
    label: 'Tipo',
    format: (tipo) => comercialRole[tipo],
  },
  {
    id: 'ativo',
    label: 'Situação',
    format: (ativo) => (ativo ? 'Ativo' : 'Inativo'),
  },
];

const ConsultaLicencas = ({pageSize = 20}) => {
  const {licencasList, totalLicencas, refetchLicencas, filtrosLicencas} =
    useSelector((store) => store.Licencas);
  const painelService = usePainelService();

  const [rowsPerPage] = useState(pageSize);
  const [page, setPage] = useState(filtrosLicencas.page);
  const [orderBy, setOrderBy] = useState(filtrosLicencas.orderBy);
  const [filtros, setFiltros] = useState(filtrosLicencas.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [loadingAccessComercial, setLoadingAccessComercial] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const licencasQuery = useQuery(FIND_ALL_COMERCIAL, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        cpfCnpj: searchDTO.cpfCnpj,
        estadoId: searchDTO.estado?.value,
        cidadeId: searchDTO.cidade?.value,
        ativo: searchDTO.ativo?.value,
        comercialRoles: searchDTO?.role?.value
          ? [searchDTO.role.value]
          : ['LICENCA_ADMINISTRADOR', 'LICENCA_COMERCIAL'],
      },
    },
  });

  useEffect(() => {
    if (!licencasQuery.loading && !licencasQuery.error && licencasQuery.data) {
      dispatch(persistLicencas(licencasQuery.data.findAllComercial));
      dispatch(persistFiltrosLicencas({filtros, page, orderBy}));
    }
    if (refetchLicencas) {
      licencasQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licencasQuery, dispatch, refetchLicencas]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      <Button
        id="button-green"
        startIcon={<Add style={{marginRight: 10}} />}
        onClick={() => navigate(`criar`)}
        toolbar="true">
        Cadastrar novo
      </Button>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <InputV2
                  label="Razão Social"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Tipo"
                  defaultValue={{label: 'Todos'}}
                  options={[
                    {label: 'Todos'},
                    {value: 'LICENCA_ADMINISTRADOR', label: 'Licença'},
                    {value: 'LICENCA_COMERCIAL', label: 'Executivo'},
                  ]}
                  value={filtros.role}
                  onChange={(e) => setFiltros({...filtros, role: e})}
                />
              </Grid>
              <Grid item xs={1}>
                <Select
                  label="Situação"
                  defaultValue={{label: 'Todos'}}
                  options={[
                    {label: 'Todos'},
                    {value: true, label: 'Ativo'},
                    {value: false, label: 'Inativo'},
                  ]}
                  value={filtros.ativo}
                  onChange={(e) => setFiltros({...filtros, ativo: e})}
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  id="button"
                  loading={licencasQuery.loading}
                  onClick={() => refetch()}
                  value="Filtrar"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell id="acoes" key="acoes" align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {licencasList.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center" style={{width: '100px'}}>
                          <Grid container spacing={0.5} width="100px">
                            <Grid item xs={6}>
                              <RoundTooltipButton
                                title={'Acessar painel'}
                                id="icon-gray"
                                onClick={() => {
                                  setLoadingAccessComercial(row);
                                  painelService.acessarPainelComercial(
                                    row.id,
                                    setLoadingAccessComercial,
                                  );
                                }}
                                loading={loadingAccessComercial?.id === row.id}
                                icon={<OpenInNew />}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <RoundTooltipButton
                                title="Visualizar"
                                onClick={() => navigate(`${row.id}/editar`)}
                                icon={<Visibility />}
                                id="icon-primary"
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalLicencas}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'página anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaLicencas;
