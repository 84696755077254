import React from 'react';
import {FIND_ALL_COMERCIAL as query} from '../../graphql/queries';
import {SelectBase} from './mui-base';

const getVariables = ({
  pageNumber,
  search = '',
  searchDTO = {comercialRoles: ['COMERCIAL', 'LICENCA_ADMINISTRADOR']},
  pageSize = 20,
}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nomeRazao', sortDir: 'ASC'},
    searchDTO: {
      ...searchDTO,
      search,
    },
  };
};

export const SelectComercial = (props) => {
  return (
    <SelectBase
      label="Comercial"
      responseObjectName="findAllComercial"
      labelProp="nomeRazao"
      query={query}
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      {...props}
    />
  );
};
