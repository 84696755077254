import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Field} from 'formik';
import {useQuery} from '@apollo/client';
import {Grid, Paper, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Collapse} from '@mui/material';
import {
  Visibility,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Description,
  Speed,
} from '@mui/icons-material';
import {
  persistMonitorarCampanhas,
  persistFiltrosMonitorarCampanha,
} from '../../redux/MonitorarCampanha/slice';
import {FIND_ALL_CAMPANHAS_MONITORAR} from '../../graphql/queries';
import {RoundTooltipButton, ButtonComponent} from '../../components/mui-button';
import {SelectAnunciantes} from '../../containers/selects/mui-anunciantes';
import {SelectComercial} from '../../containers/selects/mui-comercial';
import {SelectEstados} from '../../containers/selects/mui-estados';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import Number from '../../utils/number';
import Data from '../../utils/data';
import String from '../../utils/string';
import {toastNotification} from '../../components/toastify';
import ObservacaoAnuncioModal from './modais/observacao-anuncio-modal';
import AjustarKmModal from '../campanhas/modais/ajustar-km-modal';

const columns = [
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'anunciante.nomeFantasia',
    format: ({anunciante}) => anunciante?.nomeFantasia || '   ',
  },
  {
    id: 'titulo',
    label: 'Título',
    campo: 'campanha.titulo',
    format: ({titulo, numero}) => `[${numero}] ${titulo}`
  },
  {
    id: 'cidade',
    label: 'Cidade',
    campo: 'cidade.nome',
    format: ({cidade}) => (cidade.nomeEUf),
  },
  {id: 'quantidadeVeiculos', label: 'Veículos', campo: 'campanha.quantidadeVeiculos'},
  {id: 'quantidadeMeses', label: 'Meses', campo: 'campanha.quantidadeMeses'},
  {
    id: 'periodoVeiculacao',
    label: 'Veículação',
    campo: 'campanha.dataInicioVeiculacao',
    format: ({dataInicioVeiculacao, diasPeriodo, quantidadeMeses}) =>
      `${moment(dataInicioVeiculacao).format(
        'DD/MM/YYYY',
      )}  -  ${Data.dataFinalCampanha(
        dataInicioVeiculacao,
        diasPeriodo,
        quantidadeMeses,
        true,
      )}`,
  },
  {
    id: 'kmTotal',
    label: 'Km Total',
    campo: 'campanha.kmTotal',
    format: ({totalKmRodado, kmTotal}) =>
      `${Number.formatQuilometro(totalKmRodado)} / ${Number.formatQuilometro(
        kmTotal,
      )}`,
  },
  {
    id: 'kmPeriodo',
    label: 'Km período',
    campo: 'campanha.kmTotal',
    format: ({kmPeriodo, quantidadeKmMotoristaMes, quantidadeVeiculos}) =>
      `${Number.formatQuilometro(kmPeriodo)} / ${Number.formatQuilometro(
        quantidadeKmMotoristaMes * quantidadeVeiculos,
      )}`,
  },
  {
    id: 'mediaDia',
    label: 'Média dia',
    campo: 'campanha.kmTotal',
    format: ({mediaDiaria, metaDiaria}) =>
      `${Number.formatQuilometro(mediaDiaria)} / ${Number.formatQuilometro(
        metaDiaria,
      )}`,
  },
];

function sortByPrioridade(a, b) {
  if (a.prioridade > b.prioridade) {
    return 1;
  }
  if (a.prioridade < b.prioridade) {
    return -1;
  }

  return 0;
}

function sortByKmPeriodo(a, b) {
  if (a.kmRodadoMes > b.kmRodadoMes) {
    return 1;
  }
  if (a.kmRodadoMes < b.kmRodadoMes) {
    return -1;
  }

  return 0;
}

const ConsultaCampanha = ({pageSize = 100}) => {
  const {
    monitorarCampanhas,
    totalMonitorarCampanhas,
    refetchMonitorarCampanhas,
    filtrosMonitorarCampanhas,
  } = useSelector((store) => store.MonitorarCampanha);

  const [page, setPage] = useState(filtrosMonitorarCampanhas.page);
  const [filtros, setFiltros] = useState(filtrosMonitorarCampanhas.filtros);
  const [orderBy] = useState(filtrosMonitorarCampanhas.orderBy);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [rowsPerPage] = useState(pageSize);
  const [openObservacao, setOpenObservacao] = useState(false);

  const dispatch = useDispatch();

  const campanhasQuery = useQuery(FIND_ALL_CAMPANHAS_MONITORAR, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: 'campanha.dataInicioVeiculacao',
        sortDir: 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        anuncianteId: searchDTO.anunciante?.value,
        comercialId: searchDTO.comercial?.value,
        estadoId: searchDTO.estado?.value,
        cidadeCampanhaId: searchDTO.cidade?.value,
        campanhaSituacoesIn: ['ATIVA', 'ATIVA_PARCIAL'],
      },
    },
  });

  useEffect(() => {
    if (!campanhasQuery.loading && !campanhasQuery.error) {
      const campanhas = campanhasQuery.data?.campanhas?.content?.map(
        (cidade) => {
          const {campanha} = cidade;
          const totalKmRodado = cidade.totalKmRodado || 0;
          const kmPeriodo = cidade.totalKmRodadoMes || 0;

          const inicioPeriodo = Data.dataInicioPeriodoAtual(
            campanha.dataInicioVeiculacao,
            campanha.diasPeriodo,
            campanha.quantidadeMeses,
          );
          const dias = Data.dataFimPeriodoAtual(
            inicioPeriodo,
            campanha.diasPeriodo,
          ).diff(inicioPeriodo, 'days');
          const metaDiaria = cidade.quantidadeKmMotoristaMes / (dias + 1);
          const mediaDiaria =
            kmPeriodo /
            Data.diasPassadosPeriodo(inicioPeriodo) /
            (cidade.quantidadeVeiculos || 1);
          const anuncios = cidade.anuncios
            ?.filter((a) => !!a.dataInstalacao)
            ?.map((anuncio) => {
              const inicioPeriodo = Data.dataInicioPeriodoAtual(
                anuncio.dataInicioVeiculacao,
                campanha.diasPeriodo,
                anuncio.quantidadeMeses,
              );
              const dias = Data.dataFimPeriodoAtual(
                inicioPeriodo,
                campanha.diasPeriodo,
              ).diff(inicioPeriodo, 'days');
              const metaDiaria = (anuncio.kmTotalPorMes || 0) / (dias + 1 || 1);
              const mediaDiaria =
                anuncio.kmRodadoMes / Data.diasPassadosPeriodo(inicioPeriodo);

              const prioridade = mediaDiaria < metaDiaria ? 1 : 2;

              return {...anuncio, metaDiaria, mediaDiaria, prioridade};
            }) || [];
          const anuncioAbaixoMedia = anuncios
            ?.map((anuncio) => anuncio.prioridade === 1)
            .reduce((acc, abaixoMedia) => acc || abaixoMedia, false);

          const prioridade =
            mediaDiaria < metaDiaria ? 1 : anuncioAbaixoMedia ? 2 : 3;

          anuncios.sort(sortByKmPeriodo).sort(sortByPrioridade);
          return {
            ...campanha,
            quantidadeKmMotoristaMes: cidade.quantidadeKmMotoristaMes,
            quantidadeVeiculos: cidade.quantidadeVeiculos,
            kmTotal: cidade.kmTotal,
            anuncios,
            cidade: cidade?.cidade,
            totalKmRodado,
            kmPeriodo,
            metaDiaria,
            mediaDiaria,
            prioridade,
          };
        },
      );

      if (campanhas !== undefined) campanhas.sort(sortByPrioridade);

      dispatch(
        persistMonitorarCampanhas({
          ...campanhasQuery.data.campanhas,
          content: campanhas,
        }),
      );
      dispatch(persistFiltrosMonitorarCampanha({filtros, page, orderBy}));
    }
    if (refetchMonitorarCampanhas) {
      campanhasQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhasQuery, dispatch, refetchMonitorarCampanhas]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  label="Título"
                  name="search"
                  className="input-filtro"
                  onChange={(e) =>
                    setFiltros({...filtros, search: e?.target?.value || ''})
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectAnunciantes}
                  label="Anunciante"
                  name="anunciante"
                  onChange={(e) => setFiltros({...filtros, anunciante: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectComercial}
                  name="comercial"
                  onChange={(e) => setFiltros({...filtros, comercial: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  value="Filtrar"
                  loading={campanhasQuery.loading}
                  onClick={() => refetch()}
                  style={{minWidth: 0}}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      id="acoes"
                      key="status"
                      align="center"
                      style={{width: '100px'}}
                    />
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell id="acoes" key="acoes" align="center" sx={{width: '100px'}}>
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {monitorarCampanhas.map((campanha, i) => (
                    <CampanhaRow
                      openObservacao={openObservacao}
                      setsetOpenObservacao={setOpenObservacao}
                      key={i}
                      campanha={campanha}
                      refetch={() => campanhasQuery.refetch()}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalMonitorarCampanhas}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CampanhaRow = ({campanha, refetch}) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [color, setColor] = useState('');

  const checkColor = () => {
    const prioridades = campanha.anuncios.map((item) => item.prioridade);

    if (prioridades.every((value) => value === 1))
      return setColor('icon-cancel');

    if (prioridades.some((value) => value === 1))
      return setColor('icon-yellow');

    setColor('icon-green');
  };

  useEffect(() => {
    checkColor();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha]);

  return (
    <>
      <TableRow
        sx={{'& > *': {borderBottom: 'unset'}}}
        hover
        role="checkbox"
        tabIndex={-1}>
        <TableCell>
          <RoundTooltipButton
            id={color}
            title={'Ver anuncios'}
            icon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            onClick={() => setOpen(!open)}
          />
        </TableCell>
        {columns.map((column, index) => {
          const value = campanha[column.id];
          return (
            <TableCell key={index} align={column.align}>
              {column.format ? column.format(campanha) : value}
            </TableCell>
          );
        })}
        <TableCell align="center">
          <RoundTooltipButton
            id="icon-primary"
            title={'Ver campanha'}
            icon={<Visibility />}
            onClick={() => navigate(`campanhas/${campanha.id}/editar`)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} sx={{paddingBottom: 0, paddingTop: 0, paddingRight: 0}}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{height: '20px', paddingTop: '3px !important'}}
          >
            <Box>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{width: 25, padding: '0px 40px'}} />
                    <TableCell sx={styles.subtableHead}>Nome</TableCell>
                    <TableCell sx={styles.subtableHead}>Veículo</TableCell>
                    <TableCell sx={styles.subtableHead}>Celular</TableCell>
                    <TableCell sx={styles.subtableHead}>Veiculação</TableCell>
                    <TableCell sx={styles.subtableHead} align="right">Km Total</TableCell>
                    <TableCell sx={styles.subtableHead} align="right">Km período</TableCell>
                    <TableCell sx={styles.subtableHead} align="right">Média dia</TableCell>
                    <TableCell
                      key="anuncioAcoes"
                      align="center"
                      sx={{width: '75px'}}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campanha.anuncios.map((anuncio) => (
                    <AnuncioRow
                      key={anuncio.id}
                      anuncio={anuncio}
                      diasPeriodo={campanha.diasPeriodo}
                      quantidadeMeses={campanha.quantidadeMeses}
                      refetch={refetch}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const AnuncioRow = ({anuncio, diasPeriodo, quantidadeMeses, refetch}) => {
  const [openObservacao, setOpenObservacao] = useState(false);
  const [openAjustarKm, setOpenAjustarKm] = useState(false);

  return (
    <TableRow key={anuncio.id}>
      <TableCell sx={{width: 25, padding: '6px 0 6px 16px'}}>
        <Grid
          sx={[
            styles.statusIcon,
            {backgroundColor: theme => anuncio.prioridade === 1 ? theme.palette.paterns.cancel : theme.palette.paterns.green}
          ]}
        />
      </TableCell>
      <TableCell>{anuncio.motorista?.nome || ''}</TableCell>
      <TableCell>
        {`${anuncio.veiculo.modelo.marca.nome} - ${anuncio.veiculo.modelo.nome}`}
      </TableCell>
      <TableCell
        onClick={() => {
          toastNotification({
            message: 'Copiado para área de transferência!',
            type: 'success',
            autoClose: 500
          });
          navigator.clipboard.writeText(anuncio.motorista.celular);
        }}
        sx={{cursor: 'pointer'}}
      >
        {String.formatTelefone(anuncio.motorista.celular?.replace('55', ''))}
      </TableCell>
      <TableCell>
        {`${moment(anuncio.dataInicioVeiculacao).format(
          'DD/MM/YYYY',
        )}  -  ${Data.dataFinalCampanha(
          anuncio.dataInicioVeiculacao,
          diasPeriodo,
          quantidadeMeses,
          true,
        )}`}
      </TableCell>
      <TableCell align="right">
        {`${Number.formatQuilometro(
          anuncio?.kmRodado,
        )} / ${Number.formatQuilometro(anuncio?.kmTotal)}`}
      </TableCell>
      <TableCell align="right">
        {`${Number.formatQuilometro(
          anuncio?.kmRodadoMes,
        )} / ${Number.formatQuilometro(anuncio?.kmTotalPorMes)}`}
      </TableCell>
      <TableCell align="right">
        {`${Number.formatQuilometro(
          anuncio?.mediaDiaria,
        )} / ${Number.formatQuilometro(anuncio?.metaDiaria)}`}
      </TableCell>
      <TableCell>
        <Box sx={styles.actionButtonsContainer}>
          <RoundTooltipButton
            id="icon-green"
            title={'Ajustar km do período'}
            onClick={() => setOpenAjustarKm(true)}
            icon={<Speed />}
            sx={{transform: 'scale(0.9)'}}
          />
          <RoundTooltipButton
            id="icon-primary"
            title={'Observações'}
            icon={<Description style={{width: 20, height: 20}} />}
            onClick={() => setOpenObservacao(true)}
            sx={{transform: 'scale(0.9)'}}
          />
          <ObservacaoAnuncioModal
            openModal={openObservacao}
            anuncio={anuncio}
            refetch={refetch}
            onClose={() => setOpenObservacao(false)}
          />
          <AjustarKmModal
            openModal={openAjustarKm}
            anuncio={anuncio}
            onClose={() => {
              setOpenAjustarKm(false);
              refetch && refetch();
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

const styles = {
  statusIcon: {
    maxWidth: 20,
    minWidth: 20,
    height: 20,
    width: 20,
    borderRadius: 10,
  },
  columnHeadAcoes: {
    cursor: 'default',
    fontWeight: 'bold',
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    padding: 0,
  },
  subtableHead: {
    fontWeight: 700
  },
  actionButtonsAnuncio: {
    minWidth: 35,
    width: 35,
    height: 35,
  },
};

export default ConsultaCampanha;
