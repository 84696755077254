import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  avaliacaoMotoristas: [],
  hasMore: false,
  total: 0,
  searchDTO: {},
  pageable: {
    pageNumber: 0,
    pageSize: 20,
  },
};

const acaoPersistAvaliacaoMotoristas = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.avaliacaoMotoristas = content;
  state.hasMore = !last;
  state.total = totalElements || 0;
};

const acaoPersistSearchDTO = (state, {payload}) => {
  state.searchDTO = {
    ...payload,
  };
};
const acaoPersistPageable = (state, {payload}) => {
  state.pageable = {
    ...payload,
  };
};

const resumeSlice = createSlice({
  name: 'avaliacaoMotorista',
  initialState: initialState,
  reducers: {
    persistAvaliacaoMotoristas: acaoPersistAvaliacaoMotoristas,
    persistSearchDTOAvaliacaoMotorista: acaoPersistSearchDTO,
    persistPageableAvaliacaoMotorista: acaoPersistPageable,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistAvaliacaoMotoristas,
  persistSearchDTO,
  persistPageableAvaliacaoMotorista,
} = actions;
