import {useMutation} from '@apollo/client';
import {Edit, Key, Save} from '@mui/icons-material';
import {Grid, Paper, Typography} from '@mui/material';
import {Field, Formik} from 'formik';
import {CheckboxWithLabel} from 'formik-mui';
import moment from 'moment';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import InputV2 from '../../components/input-v2/mui-input-v2';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import {ButtonComponent} from '../../components/mui-button';
import SelectFormik from '../../components/select/mui-select-formik';
import {toastNotification} from '../../components/toastify';
import {usuarioRole} from '../../constants/enum-labels';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {
  ATUALIZAR_ADMINISTRADOR,
  CRIAR_ADMINISTRADOR,
  GERAR_SENHA_ADMINISTRADOR,
} from '../../graphql/mutation';
import {atualizarAdministrador as atualizarListaAdministrador} from '../../redux/Administrador/slice';
import Data from '../../utils/data';
import String from '../../utils/string';

const FormularioAdministrador = ({administrador = {ativo: true}}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [readOnly, setReadOnly] = useState(!!administrador?.id);

  const [criarAdministrador] = useMutation(CRIAR_ADMINISTRADOR);
  const [atualizarAdministrador] = useMutation(ATUALIZAR_ADMINISTRADOR);
  const [gerarSenhaAdministrador] = useMutation(GERAR_SENHA_ADMINISTRADOR);

  async function handleSubmitDadosAdministrador(
    {
      cpf,
      cep,
      logradouro,
      cidade,
      bairro,
      numero,
      complemento,
      dataNascimento,
      tipoUsuario,
      ...values
    },
    {setSubmitting},
  ) {
    const dadosAdministrador = {
      ...values,
      cpf: String.removeSpecialChars(cpf),
      dataNascimento: dataNascimento
        ? moment(dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      endereco: {
        cep: String.removeSpecialChars(cep),
        logradouro,
        bairro,
        cidade: {
          id: cidade.value,
        },
        numero,
        complemento,
      },
      usuario: {
        authorities: [{nome: tipoUsuario.value}],
      },
    };

    try {
      if (administrador.id) {
        dadosAdministrador.id = administrador.id;

        await atualizarAdministrador({
          variables: {
            administrador: {
              ...dadosAdministrador,
            },
          },
        });
      } else {
        const {data} = await criarAdministrador({
          variables: {
            administrador: {
              ...dadosAdministrador,
            },
          },
        });

        const senha = await gerarSenhaAdministrador({
          variables: {
            administrador: {
              id: data.createAdministrador?.id,
            },
          },
        });
        toastNotification({
          titulo: `Senha: ${senha?.data?.gerarSenhaAdminstrador}`,
          message: 'Copiada para área de transferência!',
          type: 'success',
          autoClose: false,
        });
        navigator.clipboard.writeText(senha?.data?.gerarSenhaAdminstrador);
      }
      toastNotification({
        message: 'Os dados cadastrados foram salvos!',
        type: 'success',
      });

      dispatch(atualizarListaAdministrador());
      navigate('/administrador');
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setSubmitting(false);
    }
  }

  const gerarNovaSenha = () => {
    gerarSenhaAdministrador({
      variables: {
        administrador: {
          id: administrador.id,
        },
      },
    })
      .then(({data}) => {
        toastNotification({
          titulo: `Senha: ${data?.gerarSenhaAdminstrador}`,
          message: 'Copiada para área de transferência!',
          type: 'success',
          autoClose: false,
        });
        navigator.clipboard.writeText(data?.gerarSenhaAdminstrador);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid id="page-container" item xs={12}>
      <Paper style={{padding: 20}}>
        <Formik
          validationSchema={AdministradorScheme}
          initialValues={{
            nome: administrador.nome || '',
            email: administrador.email || '',
            ativo: administrador.ativo,
            cpf: administrador.cpf || '',
            dataNascimento: Data.dataFormat(administrador.dataNascimento),
            cep: administrador.endereco?.cep || '',
            cidade: administrador.endereco?.cidade
              ? {
                  ...administrador.endereco.cidade,
                  value: administrador.endereco.cidade.id,
                  label: `${administrador.endereco.cidade.nome} / ${administrador.endereco.cidade.uf}`,
                }
              : null,
            logradouro: administrador.endereco?.logradouro || '',
            bairro: administrador.endereco?.bairro || '',
            numero: administrador.endereco?.numero || '',
            complemento: administrador.endereco?.complemento || '',
            tipoUsuario: {
              value: administrador.usuario?.authorities?.at(0)?.nome,
              label:
                usuarioRole[administrador.usuario?.authorities?.at(0)?.nome],
            },
          }}
          enableReinitialize
          onSubmit={handleSubmitDadosAdministrador}>
          {({isSubmitting, handleSubmit, values}) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    component={InputV2Formik}
                    name="nome"
                    label="Nome"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="cpf"
                    label="CPF"
                    mask="cpf"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="dataNascimento"
                    label="Data de Nascimento"
                    mask="data"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="email"
                    label="E-mail"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="telefone"
                    label="Telefone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography id="formulario-subtitle">Endereço</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputV2Formik}
                    name="cep"
                    label="CEP"
                    mask="cep"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="logradouro"
                    label="Logradouro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Field
                    component={InputV2Formik}
                    name="numero"
                    label="Número"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="complemento"
                    label="Complemento"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={InputV2Formik}
                    name="bairro"
                    label="Bairro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={SelectCidades}
                    name="cidade"
                    label="Cidade/Estado"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  {readOnly ? (
                    <InputV2
                      disabled
                      label="Perfil de usuário"
                      value={values.tipoUsuario?.label || ''}
                    />
                  ) : (
                    <Field
                      component={SelectFormik}
                      name="tipoUsuario"
                      label="Perfil de usuário"
                      options={[
                        {
                          value: 'ROLE_ADMINISTRADOR',
                          label: 'Administrador',
                        },
                        {
                          value: 'ROLE_GERENTE_COMERCIAL',
                          label: 'Ger. Comercial',
                        },
                        {
                          value: 'ROLE_GERENTE_OPERACIONAL',
                          label: 'Ger. Operacional',
                        },
                        {
                          value: 'ROLE_FINANCEIRO',
                          label: 'Financeiro',
                        },
                        {
                          value: 'ROLE_OPERACIONAL',
                          label: 'Operacional',
                        },
                      ]}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="ativo"
                    color="primaryPatern"
                    Label={{label: 'Ativo'}}
                    disabled={readOnly}
                  />
                </Grid>
                {readOnly && (
                  <Grid
                    container
                    justifyContent="space-between"
                    sx={styles.buttonContainer}>
                    <ButtonComponent
                      id="button"
                      type="button"
                      value={'Editar'}
                      onClick={() => setReadOnly(false)}
                      loading={isSubmitting}
                      icon={<Edit />}
                      sx={styles.button}
                    />
                    <ButtonComponent
                      id="button-green"
                      type="button"
                      value={'Gerar nova senha'}
                      onClick={gerarNovaSenha}
                      icon={<Key />}
                      sx={styles.button}
                    />
                  </Grid>
                )}
                {!readOnly && (
                  <Grid
                    container
                    justifyContent="space-between"
                    sx={styles.buttonContainer}>
                    <ButtonComponent
                      type="submit"
                      value={'Salvar'}
                      loading={isSubmitting}
                      icon={<Save />}
                      sx={styles.button}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

const styles = {
  buttonContainer: {
    padding: '30px 0 0 16px',
  },
  button: {
    width: '256px',
  },
};

const AdministradorScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  cep: Yup.string().required('Campo obrigatório'),
  cidade: Yup.object().required('Campo obrigatório'),
  logradouro: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  numero: Yup.string().required('Campo obrigatório'),
});

export default FormularioAdministrador;
