import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaExtratoRepasse from './consulta';

const ExtratoRepasse = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaExtratoRepasse />} />
    </Routes>
  );
};

export default ExtratoRepasse;
