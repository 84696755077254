import React, {useState} from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Collapse,
  TableHead,
  Box,
} from '@mui/material';
import {
  DeleteOutline,
  SaveOutlined,
  ExpandMore,
  ExpandLess,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {useMutation} from '@apollo/client';
import {RoundTooltipButton} from '../../components/mui-button';
import {
  SAVE_CIDADE_PALAVRA_CHAVE,
  DELETE_CIDADE_PALAVRA_CHAVE,
} from '../../graphql/mutation';
import {toastNotification} from '../../components/toastify';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';

const PalavraChave = ({
  cidade,
  novaPalavra,
  setNovaPalavra,
  loadingSave,
  refetch,
  setShowPoints,
  showPoints,
  setInvisiblePoints,
  invisiblePoints,
  cidadeInteresse,
}) => {
  const [salvando, setSalvando] = useState(false);
  const [visible, setVisible] = useState(false);
  const [savePalavraChave] = useMutation(SAVE_CIDADE_PALAVRA_CHAVE);
  const [deletePalavraChave] = useMutation(DELETE_CIDADE_PALAVRA_CHAVE);

  const handleSavePonto = () => {
    if (!novaPalavra.palavraChave) return;
    setSalvando(true);

    savePalavraChave({
      variables: {
        cidade: {
          id: cidade.id,
        },
        palavraChave: {
          palavraChave: novaPalavra.palavraChave,
          pontos: novaPalavra.pontos,
        },
      },
    })
      .then(() => {
        setNovaPalavra({});
        refetch && refetch();
        toastNotification({
          message: 'As palavras chaves foram salvas!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSalvando(false));
  };

  const handleDeletePonto = (palavraChave) => {
    if (!palavraChave?.id) return;

    delete palavraChave.__typename;

    deletePalavraChave({
      variables: {
        cidade: {
          id: cidade.id,
        },
        palavraChave: {
          id: palavraChave.id,
        },
      },
    })
      .then(() => {
        refetch && refetch();
        toastNotification({
          message: 'As palavras chaves foram deletadas!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };
  const handleMapDelete = (palavra) => {
    const pontoDel = [...invisiblePoints];

    var repeat = false;
    pontoDel.forEach((item) => {
      if (item.id === palavra.id) {
        repeat = true;
        pontoDel.splice(pontoDel.indexOf(item), 1);
      }
    });
    repeat
      ? setInvisiblePoints(pontoDel)
      : setInvisiblePoints([palavra, ...pontoDel]);

    if (showPoints === true) {
      setShowPoints(false);
    }
  };
  const handleMap = () => {
    if (showPoints) {
      setShowPoints(false);
      setInvisiblePoints([]);
    } else {
      setShowPoints(true);

      setInvisiblePoints([...cidadeInteresse]);
    }
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.Table}>
        <Box sx={styles.collapseMenu}>
          {showPoints ? (
            <VisibilityOff sx={styles.visibilityOf} onClick={handleMap} />
          ) : (
            <Visibility sx={styles.visibility} onClick={handleMap} />
          )}
          <Box
            sx={styles.textButtons}
            onClick={() => (visible ? setVisible(false) : setVisible(true))}>
            <Typography sx={styles.label}>Palavras-chave</Typography>

            {visible ? (
              <ExpandLess sx={styles.arrow} />
            ) : (
              <ExpandMore sx={styles.arrow} />
            )}
          </Box>
        </Box>
        <Collapse in={visible}>
          <Box sx={styles.lista} className="scroll">
            <Table>
              <TableHead>
                <TableRow key="input" sx={styles.itemContainer}>
                  <TableCell sx={styles.inputCell}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <input
                          value={novaPalavra.palavraChave || ''}
                          placeholder="Palavra-chave"
                          onChange={({target}) =>
                            setNovaPalavra((p) => ({
                              ...p,
                              palavraChave: target.value,
                            }))
                          }
                          style={styles.input}
                        />
                      </Grid>
                    </Grid>
                    <RoundTooltipButton
                      type="button"
                      id={'icon-primary'}
                      title={'Salvar'}
                      icon={<SaveOutlined />}
                      loading={loadingSave}
                      sx={styles.actionButton}
                      onClick={handleSavePonto}
                      disabled={salvando || loadingSave}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{...styles.itemLista, ...styles.tableBody}}>
                {cidade?.palavrasChave?.map((palavra) => {
                  var active;
                  for (var c = 0; c <= invisiblePoints.length - 1; c++) {
                    if (palavra.id === invisiblePoints[c].id) {
                      active = true;
                      break;
                    }
                  }
                  return (
                    <TableRow
                      key={palavra.id}
                      className={`tableRow`}
                      sx={styles.itemTable}>
                      <TableCell key="nome" sx={styles.itemDescricao}>
                        {active ? (
                          <VisibilityOff
                            sx={styles.visibilityOf}
                            onClick={() => handleMapDelete(palavra)}
                          />
                        ) : (
                          <Visibility
                            sx={styles.visibility}
                            onClick={() => handleMapDelete(palavra)}
                          />
                        )}
                        {palavra.palavraChave}
                      </TableCell>
                      <TableCell key="acoes" sx={styles.itemButton}>
                        <DeleteButton
                          onConfirm={() => handleDeletePonto(palavra)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

const DeleteButton = ({onConfirm}) => {

  const handleClickExcluir = () =>{
    confirmAlertMui({
      message:'Deseja excluir?',
      onConfirm:onConfirm
    })
  }
  return (
    <>
      <RoundTooltipButton
        type="button"
        title={'Excluir'}
        id="icon-cancel"
        icon={<DeleteOutline />}
        sx={styles.actionButton}
        onClick={handleClickExcluir}
      />
    </>
  );
};

const styles = {
  container: {
    padding: '10px',
    '& .scroll': (theme) => theme.styledScroll,
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    color: '#657496',
    fontSize: '14px',
    marginBottom: '3px',
    marginRight: '5px',
  },
  itemButton: {
    padding: '11px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lista: {
    borderRadius: 6,
    padding: '0 10px 10px',
    overflowY: 'auto',
    maxHeight: '340px',
  },
  tableBody: {
    '& tr:last-child': {
      border: '0',
      borderRadius: '5px',
    },
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #b5b5b5',
    background: 'none !important',

    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  itemTable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #b5b5b5',
    background: '#fff !important',

    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  itemHorario: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  itemDescricao: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0 16px 11px',
  },

  inputContainer: {
    paddingLeft: 8,
  },
  input: {
    lineHeight: 1.43,
    fontSize: '0.875rem',
    border: 'none',
    background: '#fffffffa',
    width: '100%',
    height: 33,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 6,
    paddingLeft: 10,
  },
  actionButton: {
    height: '30px',
    width: '30px',
    minWidth: '30px',
    margin: '0 15px 0 10px',
    transform: 'scale(0.8)',
  },
  excluirButton: {
    height: 30,
    width: 30,
    minWidth: 30,
    marginLeft: 10,
    backgroundColor: 'red',
  },
  excluirModalContainer: {
    background: '#fff8',
    padding: 10,
    borderRadius: 14,
    marginRight: -21,
    zIndex: 3,
  },
  arrow: {
    color: '#999',
  },
  itemLista: {
    alignItems: 'center',

    margin: '0px 0px 10px 0px',
  },
  Table: {
    background: '#F3F3F3',
    borderRadius: '5px',
  },
  excluirModalLabel: {
    color: '#000',
    fontWeight: 'bold',
    marginBottom: 5,
  },
  excluirModalButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  confirmarExcluir: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 0,
    right: 40,
  },
  exluirModalArrow: {
    color: '#fff',
    height: 50,
    width: 50,
    marginTop: 22,
    zIndex: 2,
  },
  cancelRoundedButton: {
    cursor: 'pointer',
    backgroundColor: 'red',
  },
  checkCircleRounded: {
    cursor: 'pointer',
    backgroundColor: 'green',
  },
  inputCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
  },
  textButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: 0,
  },
  collapseMenu: {
    display: 'flex',
    flexDirection: ' row',
    justifyContent: 'space-between',
    padding: '10px',
  },
  visibility: {
    color: '#0083e6',
    marginRight: '10px',
  },
  visibilityOf: {
    color: '#AED8F7',
    marginRight: '10px',
  },
};

export default PalavraChave;
