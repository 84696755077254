import React, {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {Field, Formik} from 'formik';
import {Box, Grid, Modal, Typography} from '@mui/material';
import InputDate from '../../../components/mui-input-date';
import {SelectInstaladores} from '../../../containers/selects/mui-instaladores';
import Data from '../../../utils/data';
import {AGENDAR_INSTALACAO_ANUNCIO} from '../../../graphql/mutation';
import {toastNotification} from '../../../components/toastify';
import {ButtonComponent} from '../../../components/mui-button';

const AgendamentoModal = ({openModal, anuncio, onClose}) => {
  const [dataHora, setDataHora] = useState(undefined);
  const [instalador, setInstalador] = useState(undefined);

  const [agendarInstalacaoAnuncio] = useMutation(AGENDAR_INSTALACAO_ANUNCIO);


  useEffect(() => {
    setDataHora(anuncio?.dataAgendamentoInstalacao && new Date(anuncio.dataAgendamentoInstalacao));
    setInstalador(anuncio?.instalador);
  }, [openModal, anuncio]);


  const agendarInstalacao = () => {
    if (!anuncio || !instalador?.value) return;

    agendarInstalacaoAnuncio({
      variables: {
        anuncio: {
          id: anuncio.id,
        },
        instalador: {
          id: instalador.value
        },
        dataHoraAgendamento: Data.dataFormat(dataHora, 'YYYY-MM-DDTHH:mm') || undefined,
      },
    })
      .then(() => {
        toastNotification({message: 'Instalação agendada', type: 'success', autoClose: 500})
        onClose && onClose();
      })
      .catch((error) => toastNotification({message: error.message, type: 'error'}));
  };

  return (
    <Modal
      open={openModal}
      onClose={() => onClose()}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}
    >
      <Box>
        <Formik initialValues={{instalador}} enableReinitialize>
          <Grid sx={styles.modalBody}>
            <Grid item>
              <Typography sx={styles.modalTitle}>
                Motorista: {anuncio?.motorista?.nome}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={styles.modalSubtitle}>
                Selecione o horário:
              </Typography>
            </Grid>
            <Grid item>
              <InputDate
                selected={dataHora}
                minDate={new Date()}
                dateFormat={'dd/MM/yyyy HH:mm'}
                showTimeSelect
                timeIntervals={5}
                timeCaption={'Horário'}
                onChange={date => setDataHora(date)}
                isClearable={true}
              />
            </Grid>
            <Grid item sx={styles.instaladorContainer}>
              <Field
                component={SelectInstaladores}
                name="instalador"
                label="Instalador"
                onChange={setInstalador}
              />
            </Grid>
            <Grid container item xs={12} spacing={2} pt={2}>
              <Grid item xs={6}>
                <ButtonComponent
                  id="button-cancel"
                  value="Cancelar"
                  onClick={onClose}
                />
              </Grid>
              <Grid item xs={6}>
                <ButtonComponent
                  id="button"
                  value={(anuncio?.dataAgendamentoInstalacao && !dataHora) ? "Salvar" : "Agendar"}
                  onClick={agendarInstalacao}
                />
              </Grid>
            </Grid>
          </Grid>
        </Formik>
      </Box>
    </Modal>
  )
}

const styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalBody: {
    padding: '20px',
    background: '#fff',
    borderRadius: '5px',
    minWidth: '400px',
    display: 'flex',
    flexDirection: 'column'
  },
  modalTitle: {
    fontWeight: '700',
    fontSize: '13px',
    marginBottom: '15px',
    color: '#afafaf'
  },
  modalSubtitle: {
    fontWeight: '500',
    marginBottom: '15px'
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5px'
  },
  instaladorContainer: {
    marginTop: '15px',
    textAlign: 'left'
  }
};

export default AgendamentoModal;