import React from 'react';
import FormularioAdministrador from './formulario';

const CadastrarAdministrador = () => {
  return (
    <FormularioAdministrador />
  );
};

export default CadastrarAdministrador;
