import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificacaoMotoristas: [],
  filtrosNotificacaoMotoristas: {
    filtros: {},
    page: 0,
    orderBy: {campo: 'titulo', asc: true}
  },
  totalNotificacaoMotoristas: 0,
  refetchNotificacaoMotoristas: false
  
  
};

const acaoAtualizarNotificacaoMotoristas = state => {
  state.refetchNotificacaoMotoristas = true;
};

const acaoPersistNotificacaoMotoristas = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.notificacaoMotoristas = content;
  state.hasMore = !last;
  state.totalNotificacaoMotoristas = totalElements || 0;
  state.refetchNotificacaoMotoristas = false;
};
const acaoPersistFiltrosNotificacaoMotoristas = (state, action) => {
  state.filtrosNotificacaoMotoristas = action.payload;
};

const resumeSlice = createSlice({
  name: 'notificacaoMotoristas',
  initialState: initialState,
  reducers: {
    persistNotificacaoMotoristas: acaoPersistNotificacaoMotoristas,
    atualizarNotificacaoMotoristas: acaoAtualizarNotificacaoMotoristas,
    persistFiltrosNotificacaoMotoristas: acaoPersistFiltrosNotificacaoMotoristas
  }
});


export const { actions, reducer } = resumeSlice;
export const { persistNotificacaoMotoristas, atualizarNotificacaoMotoristas, persistFiltrosNotificacaoMotoristas } = actions;
