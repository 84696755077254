import React from 'react';
import Select from './mui-select';

const SelectFormik = ({
  field,
  form: {setFieldValue, errors, touched},
  onChange,
  ...props
}) => {
  return (
    <Select
      {...field}
      {...props}
      onChange={(e) => {
        setFieldValue(field.name, e);
        onChange && onChange(e);
      }}
      value={field?.value}
      errors={errors[field.name] && touched[field.name] ? true : false}
      mensagemSuporte={
        errors[field.name] && touched[field.name] ? errors[field.name] : null
      }
    />
  );
};

export default SelectFormik;
