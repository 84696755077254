import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  extratoRepasseList: [],
  filtrosExtrato:{
    filtros:{
      movimentacaoOrigem:{
        value: 'MOTORISTA',
        label: 'Motorista',
      },
      dataPrevisaoPagamentoInicio: JSON.stringify(moment().startOf('month').toDate()),
      statusMovimentacaoFinanceira: {value: 'APROVADO', label: 'Aprovado'},
    },
    orderBy: {
      campo: 'dataAprovacao',
      asc: false,
    },
    page: 0
  },
  
  hasMore: false,
  totalExtratoRepasse: 0,
  refetchExtratoRepasse: false,
};

const acaoAtualizarExtratoRepasse = state => {
  state.refetchExtratoRepasse = true;
};

const acaoPersistExtratoRepasse = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.extratoRepasseList = content;
  state.hasMore = !last;
  state.totalExtratoRepasse = totalElements || 0;
  state.refetchExtratoRepasse = false;
};
const acaoPersistFiltrosExtratoRepasse = (state, action) =>{
  state.filtrosExtrato = action.payload;
};
const resumeSlice = createSlice({
  name: 'extratoRepasse',
  initialState: initialState,
  reducers: {
    persistExtratoRepasse: acaoPersistExtratoRepasse,
    atualizarExtratoRepasse: acaoAtualizarExtratoRepasse,
    persistFiltrosExtratoRepasse: acaoPersistFiltrosExtratoRepasse
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistExtratoRepasse, atualizarExtratoRepasse, persistFiltrosExtratoRepasse} = actions;
