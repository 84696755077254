import React from 'react';
import {makeStyles} from '@mui/styles';
import {Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import moment from 'moment';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import String from '../../utils/string';

import 'moment/locale/pt-br';

const Divisor = require('../../assets/images/divisor-relatorio.png');

const anuncianteColumns = [
  {id: 'nomeFantasia', label: 'Nome fantasia'},
  {id: 'nomeRazao', label: 'Razão social'},
  {
    id: 'cnpj',
    label: 'CNPJ',
    format: ({cnpj}) => String.formatCpfCnpj(cnpj),
  },
  {
    id: 'cidade',
    label: 'Cidade',
    format: ({endereco}) =>
      endereco?.cidade?.nomeEUf || '',
  },
];

const ImpressaoCarteiraClientesSegmento = ({segmentos = []}) => {
  const classes = useStyles();

  const renderSubrows = (row) => {
    return (
      <TableRow>
        <TableCell className={classes.subTable} colSpan={6}>
          <Table size="small" aria-label="purchases">
            <TableBody>
              {row.anunciantes?.map((anunciante) => (
                <TableRow key={anunciante.id} className={classes.subTableRow}>
                  {anuncianteColumns.map((column) => {
                    const value = anunciante[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableCell}>
                        {column.format ? column.format(anunciante) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid item xs={12} className={classes.reportContainer}>
      <Grid item xs={12} style={{margin: '0 10px'}}>
        <img
          alt={'divisor'}
          style={{width: '100%', margin: '5px 0'}}
          src={Divisor}
        />
      </Grid>
      <Grid item xs={12} className={classes.reportHeader}>
        <Grid item xs={10}>
          <Typography
            className={classes.titleReport}
            style={{fontWeight: '700'}}
          >
            Carteira de clientes
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <KmMidiaLogo style={{width: '75%'}} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{fontWeight: '700'}}>
              <TableCell className={classes.tableHead}>
                Nome fantasia
              </TableCell>
              <TableCell className={classes.tableHead}>
                Razão social
              </TableCell>
              <TableCell className={classes.tableHead}>CPF/CNPJ</TableCell>
              <TableCell className={classes.tableHead}>Cidade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {segmentos.map((item, index) => (
              <React.Fragment key={index}>
                <TableRow tabIndex={-1} key={`item${index}`}>
                  <TableCell
                    key="segmento"
                    style={{fontWeight: '700'}}
                    colSpan={5}
                  >
                    {item.descricao}
                  </TableCell>
                </TableRow>
                {renderSubrows(item)}
              </React.Fragment>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell style={{whiteSpace: 'nowrap', borderBottom: 'none'}}>
                <Typography
                  className={classes.footerText}
                  style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid
        item
        xs={12}
        className={`${classes.footer} page-footer`}
        style={{display: 'none'}}>
        <Grid item xs={12} className={classes.footerLogoContainer}>
          <KmMidiaLogo className={classes.footerLogo} />
          <Typography className={classes.footerText}>
            Valorizando sua marca a cada KM rodado!
          </Typography>
        </Grid>
        <img
          alt={'divisor'}
          style={{width: '100%', margin: '10px 0'}}
          src={Divisor}
        />
        <Grid className={classes.footerTextContainer}>
          <Typography
            className={
              classes.footerTextRodape}>{`© Copyright ${moment().format('YYYY',)}, KM Mídia CNPJ: 33.793.168/0001-50. Todos os direitos reservados.`}</Typography>
          <Typography className={classes.footerTextRodape}>{`Emissão: ${moment().format('DD/MM/YYYY',)}`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const tableCell = {
  fontSize: '12px',
  color: '#000',
};

const useStyles = makeStyles(() => ({
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 14,
    color: '#031663',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  tableHead: {
    ...tableCell,
    fontWeight: '700',
  },
  tableCell: {
    ...tableCell,
  },
  tableCellComercial: {
    fontWeight: '700',
    fontSize: 14,
    paddingTop: 40,
  },
  table: {
    margin: '30px 0',
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '697px',
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
    fontWeight: '700',
  },
  footerLogo: {
    height: '30px',
    width: '130px',
    margin: '6px 20px 0px -5px',
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  observacoes: {
    backgroundColor: '#cecece',
    breakInside: 'avoid',
    width: '100%',
    paddingBottom: 100,
    zIndex: 999,
  },
  subTable: {
    padding: '0 0 10px 25px',
    borderBottom: 'none',
  },
  footerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ' space-between',
    paddingRight: '20px',
  },
  subTableRow: {
    backgroundColor: 'transparent !important',
  },
  footerTextRodape: {
    fontSize: '11px',
  },
}));

export default ImpressaoCarteiraClientesSegmento;
