import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ConfiguracoesForm from './configuracoes-form';

const Configuracoes = () => {
  return (
    <Routes>
      <Route exact path='/' element={<ConfiguracoesForm />} />
    </Routes>
  );
}

export default Configuracoes;