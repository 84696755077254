import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  anunciantes: [],
  filtrosAnunciante: {
    filtros: {
      search: undefined,
      cpfCnpj: undefined,
      cadastroCompleto: {value: true, label: 'Completo'},
      campanhaAtiva: {value: undefined, label: 'Todos'},
    },
    page: 0,
    orderBy: {campo: 'nomeFantasia', asc: true},
  },
  hasMore: false,
  totalAnunciantes: 0,
  refetchAnunciantes: false,
  indexTab: 0,
};

const acaoAtualizarAnunciantes = (state) => {
  state.refetchAnunciantes = true;
};

const acaoPersistAnunciantes = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.anunciantes = content;
  state.hasMore = !last;
  state.totalAnunciantes = totalElements || 0;
  state.indexTab = 0;
};

const acaoPersistFiltrosAnunciante = (state, action) => {
  state.filtrosAnunciante = action.payload;
};

const acaoPersistIndexTabAnunciante = (state, action) => {
  state.indexTab = action.payload;
};

const resumeSlice = createSlice({
  name: 'anunciante',
  initialState: initialState,
  reducers: {
    persistAnunciantes: acaoPersistAnunciantes,
    atualizarAnunciantes: acaoAtualizarAnunciantes,
    persistFiltrosAnunciante: acaoPersistFiltrosAnunciante,
    persistIndexTabAnunciante: acaoPersistIndexTabAnunciante,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistAnunciantes,
  atualizarAnunciantes,
  persistFiltrosAnunciante,
  persistIndexTabAnunciante,
} = actions;
