import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Formik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useMutation, useQuery} from '@apollo/client';
import {Paper, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TablePagination, TableBody, Box} from '@mui/material';
import {Check, Clear} from '@mui/icons-material';
import {FIND_ALL_MOVIMENTACOES_EMITIR_NOTA} from '../../../graphql/queries';
import TableCellOrdered from '../../../components/table-cell-ordered';
import number from '../../../utils/number';
import {RoundTooltipButton} from '../../../components/mui-button';
import {
  MOVIMENTACAO_NOTA_EMITIDA,
  REVERTER_NOTA_EMITIDA,
} from '../../../graphql/mutation';
import {toastNotification} from '../../../components/toastify';
import InputDate from '../../../components/mui-input-date';
import Select from '../../../components/select/mui-select';
import {movimentacaoTipoParcela} from '../../../constants/enum-labels';
import {
  persistFiltrosNotasFiscaisPendentes,
  persistNotasFiscaisPendentes,
} from '../../../redux/notas-fiscais-pendentes/slice';
import {confirmAlertMui} from '../../../components/confirm-alert/mui-confirm-alert';

const columns = [
  {
    id: 'campanha',
    label: 'Campanha',
    campo: 'campanha.titulo',
    format: (campanha) => campanha?.titulo || '',
  },
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'anunciante.nomeFantasia',
    format: (anunciante) => anunciante?.nomeFantasia || '',
  },
  {
    id: 'valor',
    label: 'Valor',
    format: (valor) => number.currencyFormat(valor),
  },
  {
    id: 'dataEmitirNota',
    label: 'Data emissão',
    format: (data) => moment(data).format('DD/MM/YYYY'),
  },
  {
    id: 'parcelaTipo',
    label: 'Tipo',
    format: (tipo) => (tipo ? movimentacaoTipoParcela[tipo] : ' '),
  },
  {
    id: 'notaEmitida',
    label: 'Situação',
    format: (notaEmitida) => (notaEmitida ? 'Emitida' : 'Pendente'),
  },
];

const ConsultaNotasFiscaisPendentes = ({pageSize = 20}) => {
  const navigate = useNavigate();

  const {
    notasFiscaisPendentes,
    totalNotasFiscaisPendentes,
    refetchNotasFiscaisPendentes,
    filtrosNotasFiscaisPendentes,
  } = useSelector((store) => store.NotasFiscaisPendentes);

  const dispatch = useDispatch();

  const [page, setPage] = useState(filtrosNotasFiscaisPendentes.page);
  const [rowsPerPage] = useState(pageSize);
  const [searchDTO, setSearchDTO] = useState({
    ...filtrosNotasFiscaisPendentes.filtros,
    dataInicial:
      filtrosNotasFiscaisPendentes.filtros.dataInicial &&
      moment(JSON.parse(filtrosNotasFiscaisPendentes.filtros.dataInicial))
        .startOf('month')
        .toDate(),
  });
  const [orderBy, setOrderBy] = useState(filtrosNotasFiscaisPendentes.orderBy);
  const [loadingNotaEmitida, setLoadingNotaEmitida] = useState(null);

  const [marcarNotaEmitida] = useMutation(MOVIMENTACAO_NOTA_EMITIDA);
  const [reverterNotaEmitida] = useMutation(REVERTER_NOTA_EMITIDA);

  const movimentacoesQuery = useQuery(FIND_ALL_MOVIMENTACOES_EMITIR_NOTA, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        dataInicial: searchDTO.dataInicial
          ? moment(searchDTO.dataInicial).format('YYYY-MM-DD')
          : undefined,
        dataFinal: searchDTO.dataInicial
          ? moment(searchDTO.dataInicial).endOf('month').format('YYYY-MM-DD')
          : undefined,
        notaEmitida: searchDTO.situacaoNota?.value,
      },
    },
  });

  useEffect(() => {
    if (
      !movimentacoesQuery.loading &&
      !movimentacoesQuery.error &&
      movimentacoesQuery.data?.movimentacoes?.content
    ) {
      dispatch(
        persistNotasFiscaisPendentes(movimentacoesQuery.data?.movimentacoes),
      );
      dispatch(
        persistFiltrosNotasFiscaisPendentes({
          filtros: {
            ...searchDTO,
            dataInicial: JSON.stringify(searchDTO.dataInicial),
          },
          page,
          orderBy,
        }),
      );
    }
    if (refetchNotasFiscaisPendentes) {
      movimentacoesQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movimentacoesQuery, dispatch, refetchNotasFiscaisPendentes]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickNotaEmitida = (movimentacao) => {

    confirmAlertMui({
      title: 'Deseja mesmo marcar a nota como emitida?',
      onConfirm: () => handleNotaEmitida(movimentacao),
    })



  };
  const handleClickNotaNaoEmitida = (movimentacao) => {
    confirmAlertMui({
      title: 'Deseja mesmo marcar a nota como não emitida?',
      onConfirm: () => handleReverterNotaEmitida(movimentacao),
    })
  };

  const handleNotaEmitida = (movimentacao) => {
    if (!movimentacao?.id) return;

    setLoadingNotaEmitida(movimentacao);

    marcarNotaEmitida({
      variables: {
        movimentacao: {
          id: movimentacao.id,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'A nota fiscal foi marcada como emitida!',
          type: 'success',
        });
        movimentacoesQuery.refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => {
        setLoadingNotaEmitida(null);
      });
  };
  const handleReverterNotaEmitida = (movimentacao) => {
    if (!movimentacao?.id) return;

    setLoadingNotaEmitida(movimentacao);
    reverterNotaEmitida({
      variables: {
        movimentacao: {
          id: movimentacao.id,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'A nota fiscal foi marcada como não emitida!',
          type: 'success',
        });
        movimentacoesQuery.refetch();

      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setLoadingNotaEmitida(null));
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={searchDTO} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <InputDate
                  label="Período"
                  selected={searchDTO.dataInicial}
                  dateFormat="MMMM/yyyy"
                  showMonthYearPicker
                  onChange={(e) => {
                    setSearchDTO({...searchDTO, dataInicial: e});
                  }}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  options={[
                    {
                      value: undefined,
                      label: 'Todas',
                    },
                    {
                      value: true,
                      label: 'Emitidas',
                    },
                    {
                      value: false,
                      label: 'Pendentes',
                    },
                  ]}
                  label="Situação"
                  value={searchDTO.situacaoNota}
                  onChange={(e) => {
                    setSearchDTO({...searchDTO, situacaoNota: e});
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                  <TableCell id="acoes" key="acoes" align="center">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notasFiscaisPendentes?.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      style={{cursor: 'pointer'}}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={index}
                            align={column.align}
                            onClick={() =>
                              navigate(
                                `/campanhas/${row.campanha.id}/editar`,
                              )
                            }>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="center">
                        {!row.notaEmitida && (
                          <RoundTooltipButton
                            id="icon-primary"
                            title={'Marcar como emitida'}
                            onClick={() => handleClickNotaEmitida(row)}
                            icon={<Check />}
                            loading={loadingNotaEmitida?.id === row.id}
                          />
                        )}
                        {row.notaEmitida && (
                          <RoundTooltipButton
                            id="icon-cancel"
                            title={'Marcar como não emitida'}
                            onClick={() => handleClickNotaNaoEmitida(row)}
                            icon={<Clear />}
                            loading={loadingNotaEmitida?.id === row.id}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalNotasFiscaisPendentes || 0}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ConsultaNotasFiscaisPendentes;
