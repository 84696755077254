import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {useMutation, useQuery} from '@apollo/client';
import {Paper, Box, Collapse, Popover, Table, TableHead, TableRow, TableCell, TableBody, TablePagination} from '@mui/material';
import {
  Alarm,
  AttachMoney,
  Close,
  StarHalf,
  MoreVert,
  Phone,
  Speed,
  WarningRounded,
} from '@mui/icons-material';
import {ReactComponent as TrocarMotorista} from '../../../assets/icons/trocar-motorista.svg';
import {FIND_ALL_ANUNCIOS_CAMPANHA_CIDADE} from '../../../graphql/queries';
import {FINALIZAR_ANUNCIO} from '../../../graphql/mutation';
import {RoundTooltipButton} from '../../../components/mui-button';
import {anuncioSituacao} from '../../../constants/enum-labels';
import {toastNotification} from '../../../components/toastify';
import DetalhesMotoristaModal from '../modais/detalhes-motorista-modal';
import TableCellOrdered from '../../../components/table-cell-ordered';
import AvaliacaoModal from '../../motoristas/modal/avaliacao-modal';
import AjustarKmModal from '../modais/ajustar-km-modal';
import {confirmAlertMui} from '../../../components/confirm-alert/mui-confirm-alert';
import AgendamentoModal from '../modais/agendamento-modal';

const fotoWarningIcon = (statusApp) => {
  if (!statusApp) return undefined;
  return (<WarningRounded sx={styles.fotoWarningIcon} titleAccess='Imagens de validação pendente' />);
}

const columns = [
  {
    id: 'nome',
    label: 'Nome',
    campo: 'motorista.nome',
    format: ({motorista}) => motorista.nome,
  },
  {
    id: 'veiculo',
    label: 'Marca/Modelo/Ano',
    campo: 'veiculo.modelo.marca.nome',
    format: ({veiculo}) =>
      veiculo?.modelo?.marca
        ? `${veiculo.modelo.marca.nome} - ${veiculo.modelo.nome} ${veiculo.anoFabricacao}/${veiculo.anoModelo}`
        : '-',
    style: {maxWidth: '280px'}
  },
  {
    id: 'situacao',
    label: 'Situação',
    format: ({situacao, statusApp}) => (situacao ? <>{anuncioSituacao[situacao]} {fotoWarningIcon(statusApp)}</> : '-'),
  },
  {
    id: 'instalador',
    label: 'Instalador',
    campo: 'instalador.nome',
    format: ({instalador}) => instalador?.nome || ''
  },
  {
    id: 'dataAgendamentoInstalacao',
    label: 'Agendamento',
    style: {whiteSpace: 'nowrap'},
    format: ({dataAgendamentoInstalacao}) =>
      dataAgendamentoInstalacao
        ? moment(dataAgendamentoInstalacao).format('DD/MM/YY - HH:mm')
        : ' ',
  },
  {
    id: 'dataInicioVeiculacao',
    label: 'Veiculação',
    style: {whiteSpace: 'nowrap'},
    format: ({
      dataInstalacao,
      dataInicioVeiculacao,
      quantidadeMeses,
      campanha,
    }) => {
      if (!dataInstalacao || !dataInicioVeiculacao) return ' ';
      const dataInicio = moment(dataInicioVeiculacao).format('DD/MM/YYYY');
      const dataFim = moment(dataInicioVeiculacao)
        .add(quantidadeMeses * campanha.diasPeriodo - 1, 'days')
        .format('DD/MM/YYYY');
      return `${dataInicio} - ${dataFim}`;
    },
  },
];

const MotoristasCampanhaCidade = ({pageSize = 20, campanhaCidade = {}, search = {}, open}) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(pageSize);
  const [orderBy, setOrderBy] = useState({campo: 'motorista.nome', asc: true});
  const [searchDTO, setSearchDTO] = useState({});
  const [anuncios, setAnuncios] = useState([]);

  const anunciosQuery = useQuery(FIND_ALL_ANUNCIOS_CAMPANHA_CIDADE, {
    variables: {
      searchDTO: {
        campanhaCidadeId: campanhaCidade.id,
        ...searchDTO,
      },
      pageable: {
        pageNumber: page,
        pageSize,
        orderBy: [
          {
            campo: orderBy.campo,
            ordem: orderBy.asc ? 'ASC' : 'DESC',
          },
          {
            campo: 'motorista.nome',
            ordem: 'ASC',
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (anunciosQuery.loading || anunciosQuery.error) return;

    setAnuncios(anunciosQuery?.data?.anuncios || {});
  }, [anunciosQuery]);

  useEffect(() => {
    setPage(0);
    setSearchDTO(search);
  }, [search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableRow>
      <TableCell colSpan={12} sx={{padding: '0px'}}>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{height: '20px'}}
        >
          <Paper sx={{margin: '6px !important', overflow: 'hidden'}}>
            <Box overflow="auto">
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell id="acoes" key="acoes" align="center" style={{width: 20}} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anuncios?.content?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              onClick={() =>
                                navigate(`/motoristas/${row.motorista.id}`)
                              }
                              style={column.style}
                            >
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell
                          align="center"
                          style={{position: 'relative', width: 20}}
                        >
                          <MenuMotorista
                            anuncio={row}
                            campanha={campanhaCidade}
                            refetch={anunciosQuery.refetch}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={anuncios.totalElements || 0}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </Paper>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const MenuMotorista = ({anuncio, campanha, refetch}) => {
  const navigate = useNavigate();
  const {role} = useSelector((store) => store.Auth);

  const [openAgendamentoModal, setOpenAgendamentoModal] = useState(false);
  const [openAjustarKmModal, setOpenAjustarKmModal] = useState(false);
  const [openAvaliacaoModal, setOpenAvaliacaoModal] = useState(false);
  const [openDetalhesModal, setOpenDetalhesModal] = useState(false);
  const [openButtons, setOpenButtons] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [finalizarAnuncio] = useMutation(FINALIZAR_ANUNCIO);

  const handleTrocarMotorista = (anuncio) => {
    navigate(
      `/campanhas/${campanha.id}/trocar-motorista/${anuncio.motorista.id}`,
    );
  };

  const handleClickFinalizarAnuncio = (anuncio) => {
    confirmAlertMui({
      title: 'Deseja finalizar o anúncio?',
      onConfirm: () => handleFinalizarAnuncio(anuncio),
    });
  };

  const handleClickCloseAvaliacao = () => {
    setOpenAvaliacaoModal(false);
    refetch && refetch();
  };

  const handleFinalizarAnuncio = (anuncio) => {
    finalizarAnuncio({
      variables: {
        anuncio: {
          id: anuncio.id,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'O anuncio foi finalizado com sucesso',
          type: 'success',
        });
        refetch && refetch();
        setOpenAvaliacaoModal(true);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const operacoesStyle = () => {
    if (!role.includes('OPERACIONAL') && !role.includes('ADMINISTRADOR'))
      return {display: 'none'};

    return null;
  }

  return (
    <Box>
      <MoreVert onMouseEnter={(event) => {
        setOpenButtons(true);
        setAnchorEl(event.currentTarget);
      }}
      />
      <Popover
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            sx: styles.tableButtonsContainer,
            onMouseLeave: () => setOpenButtons(false),
          }
        }}
        anchorOrigin={{
          vertical: -10,
          horizontal: 'right'
        }}
        open={openButtons}
        onClose={() => setOpenButtons(false)}
      >
        {[
          'AGUARDANDO_ACEITE_MOTORISTA',
          'AGUARDANDO_AGENDAMENTO_INSTALACAO',
          'AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO',
          'AGUARDANDO_INSTALACAO',
          'AGUARDANDO_IMPRESSAO',
        ].includes(anuncio.situacao) && (
            <RoundTooltipButton
              title={'Agendar instalação'}
              id="icon-gray"
              onClick={() => {
                setOpenAgendamentoModal(true);
                setOpenButtons(false);
              }}
              icon={<Alarm />}
              sx={operacoesStyle()}
            />
          )}
        {!['FINALIZADO', 'AGUARDANDO_REMOCAO'].includes(anuncio.situacao) && (
          <RoundTooltipButton
            title={'Trocar motorista'}
            id="icon-purple"
            onClick={() => {
              setOpenButtons(false);
              handleTrocarMotorista(anuncio);
            }}
            icon={<TrocarMotorista />}
            sx={operacoesStyle()}
          />
        )}
        {['ATIVO'].includes(anuncio.situacao) && (
          <RoundTooltipButton
            id="icon-gray"
            title={'Ajustar km do período'}
            onClick={() => {
              setOpenAjustarKmModal(true);
              setOpenButtons(false);
            }}
            icon={<Speed />}
            sx={operacoesStyle()}
          />
        )}
        {['AGUARDANDO_REMOCAO'].includes(anuncio.situacao) && (
          <RoundTooltipButton
            title={'Finalizar anúncio'}
            id="icon-cancel"
            onClick={() => {
              handleClickFinalizarAnuncio(anuncio);
              setOpenButtons(false);
            }}
            icon={<Close />}
            sx={operacoesStyle()}
          />
        )}
        <RoundTooltipButton
          title={'Dados Bancários'}
          id="icon-green-tertiary"
          onClick={() => {
            setOpenDetalhesModal(true);
            setOpenButtons(false);
          }}
          icon={<AttachMoney />}
        />
        <RoundTooltipButton
          id="icon-yellow"
          title={'Avaliação'}
          onClick={() => {
            setOpenAvaliacaoModal(true);
            setOpenButtons(false);
          }}
          icon={<StarHalf />}
          sx={operacoesStyle()}
          style={
            !anuncio?.avaliacaoMotorista?.id ? {background: '#8995B0'} : null
          }
        />
        <RoundTooltipButton
          title={'Copiar telefone'}
          id="icon-green"
          onClick={() => {
            toastNotification({
              message: 'Copiado para área de transferência!',
              type: 'success',
            });
            navigator.clipboard.writeText(anuncio.motorista?.celular);
            setOpenButtons(false);
          }}
          icon={<Phone />}
        />
      </Popover>
      <AgendamentoModal
        openModal={openAgendamentoModal}
        anuncio={anuncio}
        onClose={() => {
          setOpenAgendamentoModal(false);
          refetch && refetch();
        }}
      />
      <DetalhesMotoristaModal
        openModal={openDetalhesModal}
        onClose={() => setOpenDetalhesModal(false)}
        anuncio={anuncio}
      />
      <AvaliacaoModal
        openModal={openAvaliacaoModal}
        anuncio={anuncio}
        motorista={anuncio?.motorista}
        onClose={handleClickCloseAvaliacao}
      />
      <AjustarKmModal
        openModal={openAjustarKmModal}
        anuncio={anuncio}
        onClose={() => setOpenAjustarKmModal(false)}
      />
    </Box>
  );
};

const styles = {
  tableButtonsContainer: {
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'flex-end',
    cursor: 'default',
    backgroundColor: '#ededed',
    padding: '6px 10px',
    borderRadius: 8,
    right: '80px',
    left: 'auto !important',
  },
  status: {
    fontSize: 'inherit',
    fontfamily: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
    margin: 'inherit',
    display: 'flex',
  },
  fotoWarningIcon: {
    color: (theme) => theme.palette.paterns.yellow,
    height: '15px',
    width: '15px',
    marginLeft: '4px',
    marginBottom: '-2px'
  }
};

export default MotoristasCampanhaCidade;
