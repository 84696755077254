import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';

const filtrosAnaliseCarteira = {
  filtros: {
    mesInicio: JSON.stringify(moment().startOf('year').toDate()),
    mesFim: JSON.stringify(moment().endOf('year').toDate()),
  },
  page: 0,
  orderBy: {campo: 'nomeRazao', asc: true},
};

const initialState = {
  comercialList: [],
  analiseCarteiraList: [],
  filtrosComercial: {
    filtros: {
      ativo: {label: 'Ativo', value: true},
    },
    page: 0,
    orderBy: {campo: 'nomeRazao', asc: true},
  },
  filtrosAnaliseCarteira,
  hasMore: false,
  totalComercial: 0,
  refetchComercial: false,
  hasMoreAnaliseCarteira: false,
  totalAnaliseCarteira: 0,
  refetchAnaliseCarteira: false,
  indexTab: 0,
};

const acaoAtualizarComercial = (state) => {
  state.refetchComercial = true;
};
const acaoAtualizarAnaliseCarteira = (state) => {
  state.refetchComercial = true;
};

const acaoPersistComercial = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.comercialList = content;
  state.hasMore = !last;
  state.totalComercial = totalElements || 0;
  state.indexTab = 0;

  state.filtrosAnaliseCarteira = filtrosAnaliseCarteira;
};
const acaoPersistAnaliseCarteira = (state, action) => {
  const {contentComercialVendasMetricas, last, totalElements} = action.payload;

  state.analiseCarteiraList = contentComercialVendasMetricas;
  state.hasMoreAnaliseCarteira = !last;
  state.totalAbaliseCarteira = totalElements || 0;
};

const acaoPersistFiltrosComercial = (state, action) => {
  state.filtrosComercial = action.payload;
};
const acaoPersistFiltrosAnaliseCarteira = (state, action) => {
  state.filtrosAnaliseCarteira = action.payload;
};
const acaoPersistIndexTabComercial = (state, action) => {
  state.indexTab = action.payload;
};

const resumeSlice = createSlice({
  name: 'comercial',
  initialState: initialState,
  reducers: {
    persistComercial: acaoPersistComercial,
    atualizarComercial: acaoAtualizarComercial,
    persistFiltrosComercial: acaoPersistFiltrosComercial,
    persistAnaliseCarteira: acaoPersistAnaliseCarteira,
    atualizarAnaliseCarteira: acaoAtualizarAnaliseCarteira,
    persistFiltrosAnaliseCarteira: acaoPersistFiltrosAnaliseCarteira,
    persistIndexTabComercial: acaoPersistIndexTabComercial,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistComercial,
  atualizarComercial,
  persistFiltrosComercial,
  persistAnaliseCarteira,
  atualizarAnaliseCarteira,
  persistFiltrosAnaliseCarteira,
  persistIndexTabComercial,
} = actions;
