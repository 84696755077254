import React, {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import {useQuery} from '@apollo/client';
import {FIND_ALL_VEICULOS_ORCAMENTOS_CIDADE} from '../../../graphql/queries';
import Number from '../../../utils/number';
import Objects from '../../../utils/objects';
import TableCellOrdered from '../../../components/table-cell-ordered';

const columns = [
  {
    id: 'cidadeNome',
    label: 'Cidade',
  },
  {
    id: 'veiculos',
    label: 'Veículos',
    format: ({veiculos}) => Number.format(veiculos),
    style: {width: 200},
  },
  {
    id: 'motoristas',
    label: 'Motoristas na cidade',
    format: ({motoristas}) => Number.format(motoristas),
    style: {width: 200},
  },
];

const VeiculosOrcadosCidades = ({filtros = {}, setLoading}) => {
  const [orderBy, setOrderBy] = useState({campo: 'cidadeNome', asc: true});
  const [cidades, setCidades] = useState([]);
  const [totalVeiculos, setTotalVeiculos] = useState(0);

  const cidadesQuery = useQuery(FIND_ALL_VEICULOS_ORCAMENTOS_CIDADE, {
    variables: {
      cidade: Objects.jsToEntity(filtros.cidade),
      situacao: filtros.situacao,
      pageable: {
        pageNumber: 0,
        pageSize: 10000,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
    },
  });

  useEffect(() => {
    setLoading && setLoading(cidadesQuery.loading);
    if (cidadesQuery.loading) return;
    const cidades = cidadesQuery?.data?.cidades || [];
    setCidades(cidades);
    setTotalVeiculos(
      cidades
        .map((c) => c.veiculos)
        .reduce((acc = 0, value) => acc + parseFloat(value), 0),
    );
  }, [cidadesQuery, setLoading]);

  return (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCellOrdered
              key={column.id}
              column={column}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody style={{maxHeight: 200, overflowY: 'auto'}}>
        {cidades?.map((row) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.cidadeId}>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}>
                    {column.format ? column.format(row) : row[column.id]}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={'total'}
          sx={{cursor: 'pointer'}}>
          <TableCell id="footer">
            {`Total: ${cidades?.length} cidades`}
          </TableCell>
          <TableCell id="footer">
            {`${Number.format(totalVeiculos || 0)} veículos`}
          </TableCell>
          <TableCell />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default VeiculosOrcadosCidades;
