import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Formik, Field} from 'formik';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  Box,
} from '@mui/material';
import {useQuery, useMutation, useLazyQuery} from '@apollo/client';
import {Close} from '@mui/icons-material';
import {
  FIND_ANUNCIOS_DA_CAMPANHA_CIDADE,
  FIND_BY_DISPONIBILIDADE_MOTORISTA,
  FIND_CAMPANHA_CIDADE_BY_ID,
} from '../../graphql/queries';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {SelectEstados} from '../../containers/selects/mui-estados';
import {ButtonComponent} from '../../components/mui-button';
import {
  INICIAR_CAMPANHA_MANUALMENTE,
  ADICIONAR_MOTORISTAS_CAMPANHA,
} from '../../graphql/mutation';
import InstaladorModal from './modais/instalador-modal';
import {toastNotification} from '../../components/toastify';
import TableCellOrdered from '../../components/table-cell-ordered';
import {motoristaSituacao} from '../../constants/enum-labels';
import Select from '../../components/select/mui-select';
import {persistIndexTabCampanha} from '../../redux/Campanha/slice';
import {LoadingPage} from '../../components/loading-page';

const columns = [
  {id: 'nome', label: 'Nome'},
  {
    id: 'marca',
    label: 'Marca/Modelo',
    campo: 'veiculoAtual.modelo.nome',
    format: ({veiculoAtual}) =>
      veiculoAtual?.modelo?.marca
        ? `${veiculoAtual.modelo.marca.nome} - ${veiculoAtual.modelo.nome}`
        : '-',
  },
  {
    id: 'ano',
    label: 'Ano',
    campo: 'veiculoAtual.anoFabricacao',
    format: ({veiculoAtual}) =>
      veiculoAtual?.anoFabricacao && veiculoAtual?.anoModelo
        ? `${veiculoAtual.anoFabricacao}/${veiculoAtual.anoModelo}`
        : '-',
  },
  {
    id: 'cidadeAtuacao',
    label: 'Cidade',
    campo: 'cidadeAtuacao.nome',
    format: ({cidadeAtuacao}) => cidadeAtuacao?.nomeEUf || '-',
  },
  {
    id: 'pinturaOriginal',
    label: 'Pintura Original?',
    campo: 'veiculoAtual.pinturaOriginal',
    format: ({veiculoAtual}) => {
      if (veiculoAtual?.pinturaOriginal) return 'Sim';

      if (veiculoAtual?.pinturaOriginal === false) return 'Não';

      return '-';
    },
  },
  {
    id: 'notaAvaliacao',
    label: 'Nota',
    format: ({notaAvaliacao}) =>
      notaAvaliacao ? parseFloat(notaAvaliacao).toFixed(1) : ' - ',
  },
  {
    id: 'situacao',
    label: 'Cadastro',
    format: ({situacao}) => motoristaSituacao[situacao],
  },
];

const EnhancedTableToolbar = (props) => {
  const {campanhaCidade, numSelected, handleClickIniciar, iniciando} = props;

  return (
    <Toolbar sx={[styles.header, numSelected ? styles.headerSelected : styles.headerEmpty]}>
      {numSelected > 0 ? (
        <Typography sx={styles.title} color="inherit" variant="subtitle1" style={{color: 'white'}}>
          {`${numSelected} motorista(s) selecionado(s) de (${campanhaCidade.quantidadeVeiculos})`}
        </Typography>
      ) : (
        <Typography sx={styles.title} variant="h6" id="tableTitle">
          {`Selecione o(s) motorista(s) (${campanhaCidade.quantidadeVeiculos})`}
        </Typography>
      )}
      {numSelected > 0 && (
        <ButtonComponent
          value={'Adicionar motoristas'}
          sx={{width: '256px'}}
          loading={iniciando}
          onClick={handleClickIniciar}
          disabled={iniciando}
        />
      )}
    </Toolbar>
  );
};

const IniciarCampanhaManualmente = ({pageSize = 20}) => {
  const dispatch = useDispatch();

  const [iniciando, setIniciando] = useState(false);
  const [filtros, setFiltros] = useState({search: ''});
  const [searchDTO, setSearchDTO] = useState({});
  const [motoristasSelecionados, setMotoristasSelecionados] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(pageSize);
  const [instalador, setInstalador] = useState();
  const [openInstaladorModal, setOpenInstaladorModal] = useState(false);
  const [campanhaCidade, setCampanhaCidade] = useState({});
  const [motoristas, setMotoristas] = useState([]);
  const [totalMotoristas, setTotalMotoristas] = useState(0);
  const [orderBy, setOrderBy] = useState({campo: 'notaAvaliacao', asc: false});

  const [iniciarCampanhaManualmente] = useMutation(
    INICIAR_CAMPANHA_MANUALMENTE,
  );

  const [adiconarMotoristasCampanha] = useMutation(
    ADICIONAR_MOTORISTAS_CAMPANHA,
  );

  const [findAnunciosDaCampanha, anunciosQuery] = useLazyQuery(
    FIND_ANUNCIOS_DA_CAMPANHA_CIDADE,
  );

  const {campanhaId} = useParams();
  const navigate = useNavigate();

  const campanhaQuery = useQuery(FIND_CAMPANHA_CIDADE_BY_ID, {
    variables: {
      id: campanhaId,
    },
  });

  const motoristasQuery = useQuery(FIND_BY_DISPONIBILIDADE_MOTORISTA, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        orderBy: [
          {campo: orderBy.campo, ordem: orderBy.asc ? 'ASC' : 'DESC'},
          {campo: 'dataAlteracao', ordem: 'DESC'},
        ],
      },
      searchDTO: {
        search: searchDTO.search,
        estadoId: searchDTO.estadoId,
        cidadeAtuacaoId: searchDTO.cidade?.value,
        possuiBorrachao: searchDTO.possuiBorrachao?.value,
        pinturaOriginal: searchDTO.pinturaOriginal?.value,
        tipoVeiculo: searchDTO.tipoVeiculo?.value || undefined,
      },
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const refetch = () => {
    const newPage = 0;
    setPage(newPage);
    setSearchDTO(filtros);
  };

  const handleClick = (row) => {
    if (motoristasSelecionados.filter((m) => m.id === row.id).length === 0 && motoristasSelecionados.length < campanhaCidade.quantidadeVeiculos)
      setMotoristasSelecionados([...motoristasSelecionados, row]);
    else
      setMotoristasSelecionados(
        motoristasSelecionados.filter((m) => m.id !== row.id),
      );
  };

  const handleClickIniciar = async () => {
    if (iniciando) return;

    setOpenInstaladorModal(false);

    try {
      setIniciando(true);
      await iniciarCampanhaManualmente({
        variables: {
          campanhaCidade: {
            id: campanhaId,
          },
          motoristas: motoristasSelecionados.map((motorista) => ({
            id: motorista.id,
          })),
          instalador: instalador ? {id: instalador.id} : null,
        },
      });

      dispatch(persistIndexTabCampanha(1));
      navigate(`/campanhas/${campanhaCidade.campanha.id}/editar`);
    } catch (error) {
      setIniciando(false);
      toastNotification({message: error.message, type: 'error'});
    }
  };

  const handleClickAdicionar = async () => {
    if (iniciando) return;

    try {
      setIniciando(true);
      await adiconarMotoristasCampanha({
        variables: {
          campanhaCidade: {
            id: campanhaId,
          },
          motoristas: motoristasSelecionados.map((motorista) => motorista.id),
        },
      });

      dispatch(persistIndexTabCampanha(1));
      navigate(-1);
    } catch (error) {
      setIniciando(false);
      toastNotification({message: error.message, type: 'error'});
    }
  };

  const nomeMotoristaSelecionado = (nome) => {
    if (nome.length > 31) {
      const stringArray = nome?.trim().split(' ');
      let newNome = stringArray[0];
      for (let i = 1; i < stringArray.length; i++) {
        const element = stringArray[i];
        if (i === stringArray.length - 1) {
          newNome = `${newNome} ${element}`;
        } else if (
          !['dos', 'das', 'dos', 'das', 'de', "d'", 'gi'].includes(element)
        ) {
          newNome = `${newNome} ${element.charAt(0)}.`;
        }
      }
      return newNome;
    }

    return nome;
  };

  useEffect(() => {
    if (campanhaQuery.loading) return;

    const campanhaCidade = campanhaQuery.data?.campanhaCidade;

    setCampanhaCidade(campanhaCidade);
    setFiltros({...filtros, cidade: campanhaCidade.cidade});
    setSearchDTO({...filtros, cidade: campanhaCidade.cidade});

    findAnunciosDaCampanha({
      variables: {
        campanhaCidade: {
          id: campanhaCidade?.id,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhaQuery]);

  useEffect(() => {
    if (anunciosQuery.loading || !anunciosQuery.data?.anuncios) return;

    const motoristas =
      anunciosQuery.data?.anuncios?.map((anuncio) => anuncio.motorista) || [];

    setMotoristasSelecionados(motoristasSelecionados.concat(motoristas));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anunciosQuery]);

  useEffect(() => {
    if (motoristasQuery.loading) return;

    setMotoristas(motoristasQuery.data?.motoristas?.content || []);
    setTotalMotoristas(motoristasQuery.data?.motoristas?.totalElements || 0);
  }, [motoristasQuery]);

  if (campanhaQuery.loading || campanhaQuery.error) {
    return (
      <LoadingPage loading={campanhaQuery.loading} error={campanhaQuery.error} />
    );
  }

  return (
    <Box sx={styles.container}>
      <Box>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <InputV2
                  label="Filtrar por Nome"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target?.value})
                  }
                  className="input-filtro"
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  className="button"
                  loading={motoristasQuery.loading}
                  value="Filtrar"
                  onClick={() => refetch()}
                  style={{minWidth: 0}}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  label="Estado de Atuação"
                  name="estado"
                  className="select"
                  isClearable
                  onChange={(e) =>
                    setFiltros({...filtros, estadoId: e?.value || null})
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectCidades}
                  label="Cidade de Atuação"
                  name="cidade"
                  className="select"
                  isClearable
                  onChange={(e) =>
                    setFiltros({...filtros, cidade: e || null})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Tipo do veículo"
                  options={[
                    {label: 'Todos'},
                    {value: 'SEDAN', label: 'Sedan'},
                    {value: 'HATCH', label: 'Hatch'},
                  ]}
                  value={filtros.tipoVeiculo}
                  className="select"
                  onChange={(e) => setFiltros({...filtros, tipoVeiculo: e})}
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Possui borrachão"
                  options={[
                    {label: 'Todos'},
                    {value: true, label: 'Sim'},
                    {value: false, label: 'Não'},
                  ]}
                  value={filtros.possuiBorrachao}
                  className="select"
                  onChange={(e) =>
                    setFiltros({...filtros, possuiBorrachao: e})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Pintura original"
                  options={[
                    {label: 'Todos'},
                    {value: true, label: 'Sim'},
                    {value: false, label: 'Não'},
                  ]}
                  value={filtros.pinturaOriginal}
                  className="select"
                  onChange={(e) =>
                    setFiltros({...filtros, pinturaOriginal: e})
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Box>
      <Box>
        <Paper>
          <EnhancedTableToolbar
            numSelected={motoristasSelecionados.length}
            handleClickIniciar={() => {
              if (campanhaCidade.situacao === 'PENDENTE')
                setOpenInstaladorModal(true);
              else
                handleClickAdicionar();
            }}
            iniciando={iniciando}
            campanhaCidade={campanhaCidade}
          />
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {motoristas.map((row, index) => {
                  const isItemSelected = motoristasSelecionados.findIndex((e) => e.id === row.id) !== -1;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      onClick={() => handleClick(row)}
                      tabIndex={-1}
                      key={index}
                      sx={{selected: styles.selected}}
                      selected={isItemSelected}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={index} align={column.align}>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalMotoristas}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
          <InstaladorModal
            openModal={openInstaladorModal}
            instalador={instalador}
            onClose={() => {
              setOpenInstaladorModal(false);
              setInstalador(null);
            }}
            onChange={setInstalador}
            iniciarCampanha={handleClickIniciar}
          />
        </Paper>
      </Box>
      <Box sx={styles.motoristaSelecionado}>
        <Typography sx={styles.selecionadosTitle}>
          {`Motoristas Selecionados (${motoristasSelecionados?.length || 0})`}
        </Typography>
        <Box sx={styles.motoristasList}>
          {motoristasSelecionados.map((motorista) => {
            const cidade = motorista?.cidadeAtuacao;
            const veiculo = motorista?.veiculoAtual;
            return (
              <Box sx={styles.cardMotorista} key={motorista.id}>
                {!motorista.anuncioAtivo && (
                  <Close
                    sx={styles.closeIcon}
                    onClick={() => handleClick(motorista)}
                  />
                )}
                <Typography sx={styles.typography} style={{marginRight: '25px'}}>
                  {nomeMotoristaSelecionado(motorista.nome)}
                </Typography>
                <Typography sx={styles.typography}>
                  {veiculo ? `${veiculo?.modelo?.marca?.nome || ' - '} - ${veiculo?.modelo?.nome}` : ' - '}
                </Typography>
                <Typography sx={styles.typography}>
                  {`${veiculo?.placa?.toUpperCase()} - ${cidade.nomeEUf || ' - '}`}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    padding: '0.5rem',
    marginLeft: 0,
    width: 'calc(100% - 285px)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 2,
    paddingRight: 1,
  },
  headerEmpty: {
    color: theme => theme.palette.paterns.text,
    backgroundColor: theme => `${theme.palette.paterns.silverChalice}40`,

  },
  headerSelected: {
    color: theme => theme.palette.paterns.white,
    backgroundColor: theme => theme.palette.paterns.purple,
  },
  headerTitle: {
    flex: '1 1 100%',
  },
  selected: {
    backgroundColor: '#CDCDCD !important',
  },
  closeIcon: {
    margin: '5px 5px -15px 0',
    alignSelf: 'flex-end',
    fontSize: '12px',
    background: '#FFF',
    borderRadius: '20px',
    color: '#6495ED',
  },
  motoristaSelecionado: {
    position: 'fixed',
    backgroundColor: ' #F7F7F7',
    margin: '-32px 0 -60px 0',
    top: '75px',
    right: '0px',
    width: '300px',
    height: 'calc(100vh - 45px)',
  },
  motoristasList: {
    padding: '5px 10px',
    height: 'calc(100vh - 86px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '7px',
      marginLeft: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(190, 214, 246, 0.5)',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(19, 72, 142, 0.5)',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(15, 54, 108, 1)',
    },
  },
  cardMotorista: {
    display: 'flex',
    flexDirection: 'column',
    background: '#AFB7C8',
    color: '#fff',
    borderRadius: '5px',
    padding: '5px',
    margin: '5px 0',
  },
  typography: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  selecionadosTitle: {
    color: '#0A2549',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '20px',
  },
};

export default IniciarCampanhaManualmente;
