import {useQuery} from '@apollo/client';
import {
  Campaign,
  Construction,
  DataThresholding,
  DirectionsCar,
  Image,
  Map,
} from '@mui/icons-material';
import {Button, Grid, Typography} from '@mui/material';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ButtonOutlineComponent} from '../../components/mui-button';
import {
  ANUNCIOS_PARADOS_ROUTE,
  CAMPANHAS_FALTANDO_VEICULOS_ROUTE,
  CAMPANHAS_ROUTE,
  CIDADES_ROUTE,
  IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE,
  INSTALADOR_ROUTE,
  MONITORAR_CAMPANHAS_ROUTE,
  MOTORISTAS_ROUTE,
  VEICULOS_PENDENTES_INSTALACAO_ROUTE,
  VEICULOS_PENDENTES_REMOCAO_ROUTE,
} from '../../constants/routes';
import {
  COUNT_ALL_ANUNCIOS_ATIVOS,
  COUNT_ALL_CAMPANHAS_ATIVAS,
  COUNT_ANUNCIOS_PARADOS_A_MAIS_DE,
  FIND_ALL_ANUNCIOS_SELECT,
  FIND_ALL_CAMPANHA_FALTANDO_MOTORISTAS,
} from '../../graphql/queries';
import {persistFiltrosCampanha} from '../../redux/Campanha/slice';

const HomeOperacional = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const anunciosParadosQuery = useQuery(COUNT_ANUNCIOS_PARADOS_A_MAIS_DE);
  const anunciosAtivosQuery = useQuery(COUNT_ALL_ANUNCIOS_ATIVOS);
  const campanhasAtivasQuery = useQuery(COUNT_ALL_CAMPANHAS_ATIVAS);

  const anunciosAguardandoInstalacaoQuery = useQuery(FIND_ALL_ANUNCIOS_SELECT, {
    variables: {
      pageable: {pageNumber: 0, pageSize: 1},
      searchDTO: {
        anuncioSituacaoIn: [
          'AGUARDANDO_INSTALACAO',
          'AGUARDANDO_ACEITE_MOTORISTA',
          'AGUARDANDO_AGENDAMENTO_INSTALACAO',
          'AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO',
        ],
      },
    },
  });

  const anunciosAguardandoRemocaoQuery = useQuery(FIND_ALL_ANUNCIOS_SELECT, {
    variables: {
      pageable: {pageNumber: 0, pageSize: 1},
      searchDTO: {
        anuncioSituacao: 'AGUARDANDO_REMOCAO',
      },
    },
  });

  const campanhasMotoristasQuery = useQuery(
    FIND_ALL_CAMPANHA_FALTANDO_MOTORISTAS,
  );

  return (
    <Grid container>
      <Grid container item xs={12} sx={styles.acessoContainer}>
        <Grid item sx={styles.buttonAcessoContainer}>
          <ButtonOutlineComponent
            value={`${
              anunciosAguardandoInstalacaoQuery.data?.findAllAnuncios
                ?.totalElements || '0'
            } Anúncios à instalar`}
            onClick={() => navigate(VEICULOS_PENDENTES_INSTALACAO_ROUTE)}
          />
        </Grid>
        <Grid item sx={styles.buttonAcessoContainer}>
          <ButtonOutlineComponent
            value={`${
              anunciosAguardandoRemocaoQuery.data?.findAllAnuncios
                ?.totalElements || '0'
            } Anúncios à finalizar`}
            onClick={() => navigate(VEICULOS_PENDENTES_REMOCAO_ROUTE)}
          />
        </Grid>
        <Grid item sx={styles.buttonAcessoContainer}>
          <ButtonOutlineComponent
            value={`${
              anunciosParadosQuery.data?.totalAnunciosParadosAMaisDe || '0'
            } Veículos parados`}
            onClick={() => navigate(ANUNCIOS_PARADOS_ROUTE)}
          />
        </Grid>
        <Grid item sx={styles.buttonAcessoContainer}>
          <ButtonOutlineComponent
            title="Campanhas aguardando motoristas"
            value={`${
              campanhasMotoristasQuery.data?.campanhas?.length || '0'
            } Aguardando motoristas`}
            onClick={() => {
              navigate(CAMPANHAS_FALTANDO_VEICULOS_ROUTE);
            }}
          />
        </Grid>
        <Grid item sx={styles.buttonAcessoContainer}>
          <ButtonOutlineComponent
            value={`${anunciosAtivosQuery.data?.anuncios || '0'} Veículos em ${
              campanhasAtivasQuery.data?.campanhas || '0'
            } Campanhas`}
            onClick={() => {
              dispatch(
                persistFiltrosCampanha({
                  filtros: {campanhaSituacao: {value: 'ATIVA', label: 'Ativa'}},
                  page: 0,
                  orderBy: {campo: 'dataInicioVeiculacao', asc: false},
                }),
              );
              navigate(CAMPANHAS_ROUTE);
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={styles.menusContainer}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6} lg={4} xl={2} sx={styles.buttonMenuContainer}>
            <Button
              sx={styles.menuButton}
              onClick={() => navigate(CAMPANHAS_ROUTE)}>
              <Campaign />
              <Typography>Campanhas</Typography>
            </Button>
          </Grid>
          <Grid item xs={6} lg={4} xl={2} sx={styles.buttonMenuContainer}>
            <Button
              sx={styles.menuButton}
              onClick={() => navigate(MONITORAR_CAMPANHAS_ROUTE)}>
              <DataThresholding />
              <Typography>Monitorar campanhas</Typography>
            </Button>
          </Grid>
          <Grid item xs={6} lg={4} xl={2} sx={styles.buttonMenuContainer}>
            <Button
              sx={styles.menuButton}
              onClick={() => navigate(IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE)}>
              <Image />
              <Typography>Imagens de validação</Typography>
            </Button>
          </Grid>
          <Grid item xs={6} lg={4} xl={2} sx={styles.buttonMenuContainer}>
            <Button
              sx={styles.menuButton}
              onClick={() => navigate(MOTORISTAS_ROUTE)}>
              <DirectionsCar />
              <Typography>Motoristas</Typography>
            </Button>
          </Grid>
          <Grid item xs={6} lg={4} xl={2} sx={styles.buttonMenuContainer}>
            <Button
              sx={styles.menuButton}
              onClick={() => navigate(INSTALADOR_ROUTE)}>
              <Construction />
              <Typography>Instaladores</Typography>
            </Button>
          </Grid>
          <Grid item xs={6} lg={4} xl={2} sx={styles.buttonMenuContainer}>
            <Button
              sx={styles.menuButton}
              onClick={() => navigate(CIDADES_ROUTE)}>
              <Map />
              <Typography>Cidades</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  acessoContainer: {
    justifyContent: 'flex-start',
    alignContent: 'center',
    marginTop: '8px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    padding: '12px 8px',
  },
  buttonAcessoContainer: {
    padding: '0 8px',
    maxWidth: '245px',
    width: 'calc(100% / 5)',
    '> button': {
      display: 'block',
      overflow: 'hidden',
      minWidth: '100%',
      fontWeight: 'bold',
      lineHeight: '15px',
      height: '60px',
      '&:hover': {
        backgroundColor: '#eff6fd',
      },
    },
  },
  menusContainer: {
    justifyContent: 'flex-start',
    alignContent: 'center',
    marginTop: '16px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    padding: '12px',
  },
  buttonMenuContainer: {
    '> button': {
      display: 'block',
      overflow: 'hidden',
      minWidth: '100%',
      fontWeight: 'bold',
      lineHeight: '15px',
    },
  },
  menuButton: {
    backgroundColor: '#fff',
    color: '#0083e6',
    border: 'solid 1px #0083e6',
    '.MuiTypography-root': {
      fontWeight: '500',
      textTransform: 'none',
    },
    svg: {
      fontSize: '34px'
    },
    '&:hover': {
      backgroundColor: '#eff6fd',
    },
  },
};

export default HomeOperacional;
