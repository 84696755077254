import React from 'react';
import {Routes, Route} from 'react-router-dom';
import CadastrarModeloAnuncio from './cadastrar';
import ConsultaModeloAnuncio from './consulta';
import EditarModeloAnuncio from './editar';

const ModeloAnuncios = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaModeloAnuncio />} />
      <Route path={`/:modeloAnuncioId/editar`} element={<EditarModeloAnuncio />} />
      <Route path={`/criar`} element={<CadastrarModeloAnuncio />} />
    </Routes>
  );
};

export default ModeloAnuncios;
