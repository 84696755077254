// Lights
const white = '#FFFFFF';
const catskillWhite = '#E5EAF4';
const silverChalice = '#AAAAAA';
const azureRadiance = '#0096FF';

// Darks
const black = '#000000';
const text = '#657496';
const navyBlue = '#00106B';
const cadetBlue = '#afb7c9';
const smalt = '#00398E';
const springGreen = '#00FA94';

// Colors
const error = '#10C571';
const success = '#66BE54';
const cornflowerBlue = '#5684EB';

//NewPatterns
const primary = '#0091FF';
const primaryHover = '#007BE1';
const primaryEmptyHover = '#CDE9FF';
const cancel = '#FF5252';
const cancelHover = '#DC3131';
const cancelEmpty = '#FFE7E7';
const green = '#10C571';
const greenHover = '#12AD65';
const blueGray = '#8995B0';
const blueGrayHover = '#738FD0';
const blueGraySecundary = '#CFD2D9';
const blueGraySecundaryHover = '#C1CBE3';
const greenSecundary = '#2CBDA5';
const greenSecundaryHover = '#138B77';
const greenTertiary = '#87BA69';
const greenTertiaryHover = '#55AB23';
const yellow = '#FFBA5A';
const yellowHover = '#FF9400';
const purple = '#7B61FF';
const purpleHover = '#563BDD';
const situacaoAgendado = '#0091FF';
const situacaoPendente = '#FF5252';
const situacaoPessoal = '#FFC800';

export const LIGHT_COLORS = {
  // General
  white,
  black,
  text,
  success,
  error,
  primary,
  primaryHover,
  primaryEmptyHover,
  cancel,
  cancelHover,
  cancelEmpty,
  green,
  greenHover,
  blueGray,
  blueGrayHover,
  blueGraySecundary,
  blueGraySecundaryHover,
  greenSecundary,
  greenSecundaryHover,
  greenTertiary,
  greenTertiaryHover,
  yellow,
  yellowHover,
  purple,
  purpleHover,
  situacaoAgendado,
  situacaoPendente,
  situacaoPessoal,
  silverChalice,
  navyBlue,

  background: catskillWhite,
  divider: silverChalice,
  inactiveItem: success,
  activeItem: cornflowerBlue,

  // sidebar
  sidebarBg: smalt,
  sidebarBorderRight: navyBlue,
  sidebarItem: azureRadiance,
  sidebarItemActive: springGreen,
  sidebarItemActiveBg: navyBlue,

  // input
  inputBorder: cadetBlue,
  inputBorderActive: navyBlue,
};
