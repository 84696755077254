import React from 'react'
import {makeStyles} from '@mui/styles';
import {Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import moment from 'moment';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Number from '../../utils/number';
import 'moment/locale/pt-br';
import String from '../../utils/string';

const Divisor = require('../../assets/images/divisor-relatorio.png');

const ImpressaoRelatorioMotoristasPagos = ({movimentacoes = [], periodo}) => {
  const classes = useStyles();

  const valorTotalMototristas = movimentacoes.length > 0 ? movimentacoes.map(movimentacao => movimentacao.valorPago)
    .reduce((acc = 0, value) => acc + parseFloat(value)) : 0;

  return (
    <>
      <Grid item xs={12} className={classes.reportContainer}>
        <Grid item xs={12} style={{margin: '0 10px'}}>
          <img alt={'divisor'} style={{width: '100%', margin: '10px 0'}} src={Divisor} />
        </Grid>
        <Grid item xs={12} className={classes.reportHeader}>
          <Grid item xs={10}>
            <Typography className={classes.titleReport} style={{fontWeight: '700'}}>Motoristas pagos</Typography>
            <Typography className={classes.titleReport}>{`Período: ${periodo ? moment(periodo).format('MMMM/YYYY') : ' - '}`}</Typography>
          </Grid>
          <Grid item xs={2}>
            <KmMidiaLogo style={{width: '100%'}} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{fontWeight: '700'}}>
                <TableCell className={classes.tableHead}>
                  Nº
                </TableCell>
                <TableCell className={classes.tableHead}>
                  Nome
                </TableCell>
                <TableCell className={classes.tableHead}>
                  CPF
                </TableCell>
                <TableCell className={classes.tableHead}>
                  Data
                </TableCell>
                <TableCell className={classes.tableHead} align="right">
                  Valor
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {movimentacoes.map((item, index) => (
                <TableRow
                  tabIndex={-1}
                  key={`item${index}`}
                >
                  <TableCell>
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    {item?.motorista?.nome}
                  </TableCell>
                  <TableCell style={{whiteSpace: 'nowrap'}}>
                    {String.formatCpfCnpj(item?.motorista?.cpf)}
                  </TableCell>
                  <TableCell style={{whiteSpace: 'nowrap'}}>
                    {moment(item?.dataPgto).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="right" style={{whiteSpace: 'nowrap'}}>
                    {Number.currencyFormat(item?.valorPago)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell style={{whiteSpace: 'nowrap', border: 'none'}}>
                  <Typography className={classes.footerText} style={{height: 50}}>
                    <br />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
        <Grid style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0 10px', marginTop: '-50px'}}>
          <Typography className={classes.footerText}>
            Total mensal bruto
          </Typography>
          <Typography className={classes.footerText}>
            {movimentacoes.length}
          </Typography>
          <Typography className={classes.footerText}>
            {Number.currencyFormat(valorTotalMototristas)}
          </Typography>
        </Grid>
        <Grid item xs={12} className={`${classes.footer} page-footer`} style={{display: 'none'}}>
          <Grid item xs={12} className={classes.footerLogoContainer}>
            <KmMidiaLogo className={classes.footerLogo} />
            <Typography className={classes.footerText}>
              Valorizando sua marca a cada KM rodado!
            </Typography>
          </Grid>
          <img alt={'divisor'} style={{width: '100%', margin: '10px 0'}} src={Divisor} />
          <Grid className={classes.footerTextContainer}>
            <Typography style={{fontSize: '11px'}}>
              {`© Copyright ${moment().format('YYYY')}, KM Mídia CNPJ: 33.793.168/0001-50. Todos os direitos reservados.`}
            </Typography>
            <Typography style={{fontSize: '11px'}}>
              {`Emissão: ${moment().format('DD/MM/YYYY')}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() => ({
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px'
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 18,
    color: '#031663',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  tableHead: {
    fontWeight: '700',
    fontSize: '0.9rem',
    color: '#000'
  },
  table: {
    margin: '30px 0'
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '1010px'
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 18,
    color: '#000',
    textAlign: 'center',
    fontWeight: '700'
  },
  footerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ' space-between',
    marginRight: '10px'
  },
  footerLogo: {
    height: '30px',
    width: '150px',
    margin: '0 20px 0px -5px'
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  observacoes: {
    backgroundColor: '#cecece',
    breakInside: 'avoid',
    width: '100%',
    paddingBottom: 100,
    zIndex: 999
  }
}));

export default ImpressaoRelatorioMotoristasPagos;