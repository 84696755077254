import {createSlice} from '@reduxjs/toolkit';
import {toastNotification} from '../../components/toastify';
import {
  getToken,
  logout as logoutService,
  redefinirSenha as redefinirSenhaService
} from './services';

const initialState = {
  token: undefined,
  refresh_token: '',
  expires_in: 0,
  logged: undefined,
  lastRefresh: undefined,
  loginError: false,
  redefinirSenhaError: null,
  redefinirSenhaSuccess: null,
  id: '',
  name: '',
  photo: '',
  email: '',
  phone: '',
  role: ''
};

const actionLoginSuccess = (state, {payload}) => {
  const {access_token, refresh_token, expires_in} = payload;

  state.token = access_token;
  state.refresh_token = refresh_token;
  state.expires_in = expires_in;
  state.logged = true;
  state.loginError = false;
  window.localStorage.setItem('auth', JSON.stringify(payload));
};

const actionLoginFail = (state, action) => {
  window.localStorage.clear();

  state.token = undefined;
  state.refresh_token = undefined;
  state.expires_in = 0;
  state.logged = false;
  state.lastRefresh = undefined;
  state.loginError = true;
};

const actionRedefinirSenhaSuccess = (state, {payload}) => {
  state.redefinirSenhaError = null;
  state.redefinirSenhaSuccess = payload;
};

const actionRedefinirSenhaFail = (state, {payload}) => {
  state.redefinirSenhaError = payload;
  state.redefinirSenhaSuccess = null;
};

const actionLogout = state => {
  window.localStorage.clear();

  Object.keys(initialState).forEach(key => {
    state[key] = initialState[key];
  });
};

const actionPersistUser = (state, {payload}) => {
  state.id = payload.id;
  state.name = payload.nome;
  state.usuario = payload.usuario;
  state.role = payload.usuario?.authorities?.at(0)?.nome
};

const actionPersistRole = (state, {payload}) => {
  state.role = payload
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginSuccess: actionLoginSuccess,
    loginFail: actionLoginFail,
    persistUser: actionPersistUser,
    logoutSuccess: actionLogout,
    redefinirSenhaSuccess: actionRedefinirSenhaSuccess,
    redefinirSenhaFail: actionRedefinirSenhaFail,
    persistRole: actionPersistRole,
  }
});

export const {actions, reducer} = authSlice;
export const {
  loginSuccess,
  loginFail,
  persistUser,
  logoutSuccess,
  redefinirSenhaSuccess,
  redefinirSenhaFail,
  persistRole
} = actions;

export const login = ({username, password}) => async dispatch => {
  try {
    const auth = await getToken(username, password);

    dispatch(loginSuccess(auth));
  } catch (err) {
    dispatch(loginFail());
    toastNotification({message: err.response?.data?.error_description, type: 'error'})
  }
};

export const redefinirSenha = email => async dispatch => {
  try {
    const message = await redefinirSenhaService(email);

    dispatch(redefinirSenhaSuccess(message));
  } catch (error) {
    dispatch(redefinirSenhaFail(error.message));
    console.log('Erro ao redefinir senha', error);
  }
};

export const logout = () => async (dispatch, getState) => {
  try {
    await logoutService(getState().Auth.token);

    dispatch(logoutSuccess());
  } catch (err) {
    dispatch(logoutSuccess());
    console.log('Error on Logout', err.response);
  }
};
