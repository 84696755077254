import React, {useEffect, useState, useRef} from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {Add, Delete, Download} from '@mui/icons-material';
import {useMutation} from '@apollo/client';
import {
  ButtonWithIcon,
  RoundTooltipButton,
} from '../../../components/mui-button';
import Data from '../../../utils/data';
import File from '../../../utils/file';
import {
  CADASTRAR_ARQUIVO_COMERCIAL,
  EXCLUIR_ARQUIVO_COMERCIAL,
} from '../../../graphql/mutation';
import {uploadAmazonS3} from '../../../services/UploadService';
import {toastNotification} from '../../../components/toastify';
import {confirmAlertMui} from '../../../components/confirm-alert/mui-confirm-alert';

const Arquivos = ({comercial = {}, refetch}) => {
  const inputRef = useRef(null);

  const [arquivos, setArquivos] = useState([]);
  const [selecionado, setSelecionado] = useState(undefined);
  const [cadastrando, setCadastrando] = useState(false);

  const [cadastrar] = useMutation(CADASTRAR_ARQUIVO_COMERCIAL);
  const [excluir, excluirQuery] = useMutation(EXCLUIR_ARQUIVO_COMERCIAL);

  useEffect(() => {
    setArquivos(comercial?.arquivos);
  }, [comercial, setArquivos]);

  const downloadArquivo = ({arquivo}) => {
    window.open(File.getFileAuth(arquivo.id, arquivo.nome));
  };

  const cadastrarArquivo = async (arquivo) => {
    let response;
    const file = Array.from(arquivo.target.files)[0];

    if (!file) return;
    setCadastrando(true);

    try {
      response = await uploadAmazonS3(file);
    } catch (e) {
      return setCadastrando(false);
    }

    cadastrar({
      variables: {
        arquivo: {
          titulo: file.name,
          arquivo: {
            id: response?.id,
          },
          comercial: {
            id: comercial.id,
          },
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Arquivo cadastrado!', type: 'success'});
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setCadastrando(false));
  };

  const handleExcluirArquivo = (arquivo) => {
    setSelecionado(arquivo);
    confirmAlertMui({
      title: 'Deseja realmente excluir o arquivo?',
      onConfirm: () => excluirArquivo(arquivo),
    });
  };

  const excluirArquivo = (arquivo) => {
    excluir({
      variables: {
        arquivo: {
          id: arquivo.id,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Arquivo excluído!', type: 'success'});
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const cadastrarNovo = () => {
    if (cadastrando) return;

    return inputRef.current?.click();
  };

  return (
    <Paper>
      <input
        style={{display: 'none'}}
        ref={inputRef}
        type="file"
        onChange={cadastrarArquivo}
      />
      <ButtonWithIcon
        id="button-green"
        toolbar="true"
        value="Cadastrar novo"
        startIcon={<Add style={{marginRight: 10}} />}
        onClick={cadastrarNovo}
        loading={cadastrando}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Arquivo</TableCell>
            <TableCell align="center">Cadastro</TableCell>
            <TableCell key="acoes" align="center" style={{width: 100}}>
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {arquivos.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{row.titulo}</TableCell>
              <TableCell align="center">
                {Data.dataFormat(row.dataAlteracao, 'DD/MM/YYYY')}
              </TableCell>
              <TableCell align="center">
                <Grid container spacing={0.5} width="100px">
                  <Grid item xs={6}>
                    <RoundTooltipButton
                      id="icon-primary"
                      title="Download"
                      icon={<Download />}
                      onClick={() => downloadArquivo(row)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RoundTooltipButton
                      id="icon-cancel"
                      title="Excluir"
                      icon={<Delete />}
                      onClick={() => handleExcluirArquivo(row)}
                      loading={
                        selecionado?.id === row.id && excluirQuery.loading
                      }
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default Arquivos;
