import React, {useState} from 'react';
import {Grid, Typography} from '@mui/material';
import {ButtonComponent} from '../components/mui-button';
import InputV2 from '../components/input-v2/mui-input-v2';
import Select from '../components/select/mui-select';
import Textarea from '../components/textarea/mui-textarea';

const Components = () => {
  const [inputValue, setInputValue] = useState('');
  const [selectOptions] = useState([
    {
      value: '1',
      label: 'Item 1'
    },
    {
      value: '2',
      label: 'Item 2'
    },
    {
      value: '3',
      label: 'Item 3'
    }
  ]);
  const [selectedOption] = useState({
    value: '1',
    label: 'Item 1'
  });
  return (
    <Grid container>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={4}>
          <Typography>Inputs v1</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Inputs v2</Typography>
          <InputV2
            label="Ocioso e vazio"
            placeholder="Placeholder"
            mensagemSuporte="Mensagem de suporte"
          />
          <InputV2
            label="Preenchendo"
            placeholder="Placeholder"
            value={inputValue}
            onInput={e => setInputValue(e.target.value)}
            mensagemSuporte="Mensagem de suporte"
          />

          <InputV2
            label="Erro"
            value="Input"
            mensagemSuporte="Mensagem de suporte!"
            error
          />

          <InputV2
            label="Desativado"
            mensagemSuporte="Mensagem de suporte"
            disabled
          />

          <InputV2
            label="Desativado e preenchido"
            value="Input"
            mensagemSuporte="Mensagem de suporte"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>Botões</Typography>

          <ButtonComponent id="button" value="button" />
          <ButtonComponent id="button-primary-empty" value="button outline" />
          <ButtonComponent id="button-cancel" value="button cancel" />
          <ButtonComponent id="button-green" value="button green" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={4}>
          <Typography>Text area</Typography>

          <Textarea
            label="Ocioso e vazio"
            value=""
            mensagemSuporte="Mensagem de suporte"
          />
          <Textarea
            label="Erro"
            value="Input"
            error
            mensagemSuporte="Mensagem de suporte"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography style={{opacity: 0}}>Text area</Typography>
          <Textarea
            label="Preenchendo"
            value={inputValue}
            onInput={e => setInputValue(e.target.value)}
            mensagemSuporte="Mensagem de suporte"
          />
          <Textarea
            label="Desativado"
            value="Input"
            disabled
            mensagemSuporte="Mensagem de suporte"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>Select</Typography>

          <Select placeholder="Ocioso" />
          <Select options={selectOptions} value={selectedOption} />
          <Select options={selectOptions} value={selectedOption} isDisabled />
          <Select options={selectOptions} value={selectedOption} menuIsOpen />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Components;
