import {useMutation} from '@apollo/client';
import {CancelRounded, Edit, Save, Send} from '@mui/icons-material';
import {
  Box,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {Field, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../components/mui-button';
import InputDate from '../../components/mui-input-date';
import Select from '../../components/select/mui-select';
import TextAreaV2 from '../../components/textarea/mui-textarea';
import {toastNotification} from '../../components/toastify';
import {motoristaSituacao} from '../../constants/enum-labels';
import {SelectCampanhas} from '../../containers/selects/mui-campanhas';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {SelectEstados} from '../../containers/selects/mui-estados';
import {SelectMotoristas} from '../../containers/selects/mui-motoristas';
import {
  ENVIAR_NOTIFICAO_MOTORISTAS,
  SALVAR_NOTIFICAO_MOTORISTAS,
} from '../../graphql/mutation';
import Data from '../../utils/data';
import Number from '../../utils/number';

const FormularioNotificacao = ({notificacao = {}, refetch}) => {
  const navigate = useNavigate();

  const [readOnly, setReadOnly] = useState(!!notificacao?.id);
  const [formData, setFormData] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const [salvarNotificao] = useMutation(SALVAR_NOTIFICAO_MOTORISTAS);
  const [enviarNotificao] = useMutation(ENVIAR_NOTIFICAO_MOTORISTAS);

  useEffect(() => {
    if (!notificacao?.id) return;

    setReadOnly(true);
    setFormData({
      ...notificacao,
      dataCadastroInicio: Data.momentToDate(notificacao.dataCadastroInicio),
      dataCadastroFim: Data.momentToDate(notificacao.dataCadastroFim),
      diasAnuncioParado: notificacao.diasAnuncioParado,
      anuncioAtivo: {
        value: notificacao.anuncioAtivo,
        label: notificacao.anuncioAtivo
          ? 'Sim'
          : notificacao.anuncioAtivo === false
          ? 'Não'
          : 'Todos',
      },
      situacao: {
        value: notificacao.situacao,
        label: notificacao.situacao
          ? motoristaSituacao[notificacao.situacao]
          : 'Todos',
      },
      diasSemana: notificacao.diasSemana
        ? notificacao.diasSemana.split('|').filter((d) => !!d)
        : [],
      horarios: notificacao.horarios
        ? notificacao.horarios.split('|').filter((h) => !!h)
        : [],
    });
  }, [notificacao]);

  const handleSubmitDados = async () => {
    if (!formData.titulo || !formData.mensagem) return;
    setSubmitting(true);

    salvarNotificao({
      variables: {
        notificacao: {
          id: formData.id,
          titulo: formData.titulo,
          mensagem: formData.mensagem,
          dataCadastroInicio:
            Data.dataFormat(formData.dataCadastroInicio, 'YYYY-MM-DD') || null,
          dataCadastroFim:
            Data.dataFormat(formData.dataCadastroFim, 'YYYY-MM-DD') || null,
          estado: formData.estado?.value ? {id: formData.estado?.value} : null,
          cidade: formData.cidade?.value ? {id: formData.cidade?.value} : null,
          motorista: formData.motorista?.value
            ? {id: formData.motorista?.value}
            : null,
          campanha: formData.campanha?.value
            ? {id: formData.campanha?.value}
            : null,
          anuncioAtivo: formData.anuncioAtivo?.value,
          situacao: formData.situacao?.value,
          diasAnuncioParado:
            Number.safeParseInt(formData.diasAnuncioParado) || undefined,
          diasSemana: formData.diasSemana?.length
            ? `|${formData.diasSemana.join('|')}|`
            : undefined,
          horarios: formData.horarios?.length
            ? `|${formData.horarios.join('|')}|`
            : undefined,
        },
      },
    })
      .then(({data}) => {
        setReadOnly(true);
        toastNotification({
          message: `A notificação foi salva!`,
          type: 'success',
        });
        if (!notificacao?.id)
          return navigate(
            `/notificar-motoristas/${data?.notificacao?.id}/editar`,
          );

        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSubmitting(false));
  };

  const handleClickEnviar = () => {
    confirmAlertMui({
      message: 'Deseja realmente enviar essa notificação?',
      onConfirm: handleEnviarNotificacao,
      onCancel: () => null,
    });
  };

  const handleEnviarNotificacao = async () => {
    if (!formData.titulo || !formData.mensagem) return;
    setSubmitting(true);

    enviarNotificao({
      variables: {
        titulo: formData.titulo,
        mensagem: formData.mensagem,
        searchDTO: {
          estadoId: formData.estado?.value,
          cidadeId: formData.cidade?.value,
          motoristaId: formData.motorista?.value,
          campanhaId: formData.campanha?.value,
          possuiAnuncioAtivo: formData.anuncioAtivo?.value,
          situacao: formData.situacao?.value,
          dataInicial:
            Data.dataFormat(formData.dataCadastroInicio, 'YYYY-MM-DD') || null,
          dataFinal:
            Data.dataFormat(formData.dataCadastroFim, 'YYYY-MM-DD') || null,
          diasAnuncioParado:
            Number.safeParseInt(formData.diasAnuncioParado) || undefined,
        },
      },
    })
      .then(({data}) =>
        toastNotification({
          message: `Notificação enviada para ${
            data.notificacoes || 0
          } motorista(s)`,
          type: 'success',
        }),
      )
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSubmitting(false));
  };

  const handleCancelar = () => {
    if (notificacao.id) setReadOnly(true);
    else navigate('/notificar-motoristas');
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik initialValues={formData} enableReinitialize>
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputV2
                  name="titulo"
                  label="Título"
                  value={formData.titulo}
                  onChange={({target}) =>
                    setFormData({...formData, titulo: target.value})
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <TextAreaV2
                  name="mensagem"
                  label="Mensagem"
                  rows="3"
                  value={formData.mensagem}
                  onChange={({target}) =>
                    setFormData({...formData, mensagem: target.value})
                  }
                  style={{maxWidth: '100%', minWidth: '100%'}}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography id="formulario-subtitle">Filtros</Typography>
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectCampanhas}
                  value={formData.campanha}
                  label="Campanha"
                  name="campanha"
                  isClearable
                  onChange={(e) => setFormData({...formData, campanha: e})}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectMotoristas}
                  value={formData.motorista}
                  label="Motorista"
                  name="motorista"
                  isClearable
                  onChange={(e) => setFormData({...formData, motorista: e})}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Situação Cadastro"
                  options={[
                    {
                      value: undefined,
                      label: 'Todos',
                    },
                    {
                      value: 'APROVADO',
                      label: 'Aprovado',
                    },
                    {
                      value: 'COMPLETO',
                      label: 'Completo',
                    },
                    {
                      value: 'ATUALIZADO',
                      label: 'Atualizado',
                    },
                    {
                      value: 'INCOMPLETO',
                      label: 'Incompleto',
                    },
                    {
                      value: 'REPROVADO',
                      label: 'Reprovado',
                    },
                    {
                      value: 'INATIVO',
                      label: 'Inativo',
                    },
                  ]}
                  value={formData.situacao}
                  onChange={(e) => setFormData({...formData, situacao: e})}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Anúncio Ativo"
                  options={[
                    {
                      value: undefined,
                      label: 'Todos',
                    },
                    {
                      value: false,
                      label: 'Não',
                    },
                    {
                      value: true,
                      label: 'Sim',
                    },
                  ]}
                  value={formData.anuncioAtivo}
                  onChange={(e) => setFormData({...formData, anuncioAtivo: e})}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  value={formData.estado}
                  label="Estado"
                  name="estado"
                  isClearable
                  onChange={(e) => setFormData({...formData, estado: e})}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: formData.estado?.value}}
                      {...props}
                    />
                  )}
                  value={formData.cidade}
                  label="Cidade"
                  name="cidade"
                  isClearable
                  onChange={(e) => setFormData({...formData, cidade: e})}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                <InputDate
                  label="Data Cadastro Inicio"
                  selected={formData.dataCadastroInicio}
                  onChange={(event) =>
                    setFormData({...formData, dataCadastroInicio: event})
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                <InputDate
                  label="Data cadastro fim"
                  selected={formData.dataCadastroFim}
                  onChange={(event) =>
                    setFormData({...formData, dataCadastroFim: event})
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                <InputV2
                  label="Parados há mais de"
                  endAdornment="dias"
                  value={formData.diasAnuncioParado || ''}
                  onChange={({target}) =>
                    setFormData((f) => ({
                      ...f,
                      diasAnuncioParado: target.value,
                    }))
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} marginTop="10px">
                <Typography id="formulario-subtitle">
                  Envio automático
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  aria-label="Dias da semana"
                  value={formData.diasSemana}
                  onChange={(e, diasSemana) =>
                    setFormData((f) => ({...f, diasSemana}))
                  }
                  disabled={readOnly}
                  sx={styles.agendamento}>
                  <ToggleButton aria-label="sunday" value="sunday">
                    Domingo
                  </ToggleButton>
                  <ToggleButton aria-label="monday" value="monday">
                    Segunda-feira
                  </ToggleButton>
                  <ToggleButton aria-label="tuesday" value="tuesday">
                    Terça-feira
                  </ToggleButton>
                  <ToggleButton aria-label="wednesday" value="wednesday">
                    Quarta-feira
                  </ToggleButton>
                  <ToggleButton aria-label="thursday" value="thursday">
                    Quinta-feira
                  </ToggleButton>
                  <ToggleButton aria-label="friday" value="friday">
                    Sexta-feira
                  </ToggleButton>
                  <ToggleButton aria-label="saturday" value="saturday">
                    Sábado
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  aria-label="Horários"
                  value={formData.horarios}
                  onChange={(e, horarios) =>
                    setFormData((f) => ({...f, horarios}))
                  }
                  disabled={readOnly}
                  sx={styles.agendamento}>
                  {Array.from(Array(Number.safeParseInt(24)).keys()).map(
                    (hora) => (
                      <ToggleButton key={hora} value={`${hora}`}>
                        {`${hora} h`}
                      </ToggleButton>
                    ),
                  )}
                </ToggleButtonGroup>
              </Grid>
              <Grid></Grid>
              <Grid item xs={12} marginTop="50px">
                <Box>
                  {readOnly ? (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <ButtonComponent
                          value={'Editar'}
                          sx={styles.buttonWrapper}
                          icon={<Edit />}
                          loading={submitting}
                          onClick={() => setReadOnly(false)}
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <ButtonComponent
                          id="button-green"
                          value={'Enviar'}
                          sx={styles.buttonWrapper}
                          icon={<Send />}
                          loading={submitting}
                          onClick={handleClickEnviar}
                          disabled={submitting}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <ButtonComponent
                          value={'Cancelar'}
                          id="button-cancel"
                          sx={styles.buttonWrapper}
                          icon={<CancelRounded />}
                          loading={submitting}
                          onClick={handleCancelar}
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <ButtonComponent
                          value={'Salvar'}
                          sx={styles.buttonWrapper}
                          icon={<Save />}
                          loading={submitting}
                          onClick={handleSubmitDados}
                          disabled={submitting}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Formik>
      </Paper>
    </Grid>
  );
};

const styles = {
  buttonWrapper: {
    width: '256px',
  },
  agendamento: {
    flexWrap: 'wrap',
    '> *': {
      textTransform: 'none !important',
      whiteSpace: 'nowrap',
      border: '1px solid #0000001f !important',
      borderRadius: '4px !important'
    },
    '.Mui-selected': {
      backgroundColor: (theme) =>
        `${theme.palette.paterns.blueGraySecundary} !important`,
    },
  },
};

export default FormularioNotificacao;
