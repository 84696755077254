import { useMutation, useQuery } from '@apollo/client';
import {
  OpenInNew,
  Speed,
  Visibility,
  WarningRounded,
} from '@mui/icons-material';
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { Field, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import { ButtonComponent, RoundTooltipButton } from '../../components/mui-button';
import Select from '../../components/select/mui-select';
import TableCellOrdered from '../../components/table-cell-ordered';
import { toastNotification } from '../../components/toastify';
import { campanhaSituacao } from '../../constants/enum-labels';
import { MONITORAR_CAMPANHAS_ROUTE } from '../../constants/routes';
import { SelectAnunciantes } from '../../containers/selects/mui-anunciantes';
import { SelectCidades } from '../../containers/selects/mui-cidades';
import { SelectComercial } from '../../containers/selects/mui-comercial';
import { SelectEstados } from '../../containers/selects/mui-estados';
import { INICIAR_CAMPANHA } from '../../graphql/mutation';
import {
  FIND_ALL_CAMPANHA,
} from '../../graphql/queries';
import {
  atualizarCampanhas,
  persistCampanhas,
  persistFiltrosCampanha,
} from '../../redux/Campanha/slice';
import usePainelService from '../../services/PainelService';
import InstaladorModal from './modais/mui-instalador-modal';


const getPeriodoVeiculacao = (campanha) => {
  const dataInicioCampanha = campanha.dataInicioVeiculacao;

  if (!dataInicioCampanha) return;

  const dataInicio = moment(dataInicioCampanha);
  const dataFinal = moment(dataInicioCampanha)
    .add(campanha?.diasPeriodo * campanha?.quantidadeMeses || 0, 'd')
    .subtract(1, 'd');

  return `${dataInicio.format('DD/MM/YY')} - ${dataFinal.format('DD/MM/YY')}`;
};

const getStatusConsulta = (status) => {
  if (!status) return null;

  switch (status) {
    case 'ATIVA':
      return ['ATIVA', 'ATIVA_PARCIAL'];
    case 'EM_ANDAMENTO':
      return [
        'ATIVA',
        'ATIVA_PARCIAL',
        'PENDENTE',
        'AGUARDANDO_IMPRESSAO',
        'AGUARDANDO_INSTALACAO',
      ];
    default:
      return [status];
  }
};

const columns = [
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'anunciante.nomeFantasia',
    format: ({anunciante}) => anunciante?.nomeFantasia || ' - ',
  },
  {
    id: 'comercial',
    label: 'Comercial',
    campo: 'comercial.nomeRazao',
    format: ({comercial}) => comercial?.nomeRazaoResponsavel || ' - ',
  },
  {
    id: 'cidades',
    label: 'Cidades',
    format: ({cidades}) => (cidades.length || 0),
    calculado: true
  },
  {
    id: 'titulo',
    label: 'Título',
    format: ({titulo, numero}) => `[${numero}] ${titulo}`
  },
  {
    id: 'quantidadeVeiculos',
    label: 'Veículos',
    format: ({situacao, quantidadeVeiculos, quantidadeVeiculosNaCampanha}) => {
      if (situacao === 'PENDENTE' || quantidadeVeiculos === quantidadeVeiculosNaCampanha)
        return quantidadeVeiculos;

      return <>{`${quantidadeVeiculosNaCampanha}/${quantidadeVeiculos}`}<WarningRounded sx={styles.warningIcon} titleAccess='Faltam motoristas na campanha!' /></>
    },
    style: {whiteSpace: 'nowrap'},
    calculado: true
  },
  {
    id: 'situacao',
    label: 'Situação',
    format: ({situacao}) => campanhaSituacao[situacao],
  },
  {
    id: 'periodoVeiculacao',
    label: 'Veículação',
    campo: 'dataInicioVeiculacao',
    format: (campanha) => getPeriodoVeiculacao(campanha),
  },
];

const ConsultaCampanha = ({pageSize = 20}) => {
  const {role} = useSelector((store) => store.Auth);
  const {campanhas, totalCampanhas, filtrosCampanha, refetchCampanhas} =
    useSelector((store) => store.Campanha);
  const painelService = usePainelService();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(filtrosCampanha.page);
  const [filtros, setFiltros] = useState(filtrosCampanha.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosCampanha.orderBy);
  const [iniciandoCampanha, setIniciandoCampanha] = useState(null);
  const [instalador, setInstalador] = useState(null);
  const [openInstaladorModal, setOpenInstaladorModal] = useState(false);
  const [rowsPerPage] = useState(pageSize);
  const [loadingAccessAnunciante, setLoadingAccessAnunciante] = useState(null);


  const [iniciarCampanha] = useMutation(INICIAR_CAMPANHA);

  const campanhasQuery = useQuery(FIND_ALL_CAMPANHA, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        anuncianteId: searchDTO.anunciante?.value,
        comercialId: searchDTO.comercial?.value,
        estadoId: searchDTO.estado?.value,
        cidadeCampanhaId: searchDTO.cidade?.value,
        campanhaSituacoesIn: getStatusConsulta(
          searchDTO.campanhaSituacao?.value,
        ),
        campanhaSituacoesDiferenteDe: ['AGUARDANDO_APROVACAO'],
      },
    },
  });

  useEffect(() => {
    if (
      !campanhasQuery.loading &&
      !campanhasQuery.error &&
      campanhasQuery.data
    ) {
      dispatch(persistCampanhas(campanhasQuery.data.findAllCampanha));
      dispatch(persistFiltrosCampanha({filtros, page, orderBy}));
    }
    if (refetchCampanhas) {
      campanhasQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhasQuery, dispatch, refetchCampanhas]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  async function handleIniciarCampanha(campanhaId) {
    try {
      setOpenInstaladorModal(false);
      await iniciarCampanha({
        variables: {
          campanha: {
            id: campanhaId,
          },
          instalador: instalador ? {id: instalador?.id} : null,
        },
      });
      dispatch(atualizarCampanhas());
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setIniciandoCampanha(null);
      setInstalador(null);
    }
  }

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const showMonitorar = () => {
    return role.includes('OPERACIONAL') || role.includes('ADMINISTRADOR');
  }

  return (
    <Grid id="page-container" container>
      <Button
        startIcon={<Speed />}
        id="button-gray-blue"
        toolbar="true"
        onClick={() => navigate(MONITORAR_CAMPANHAS_ROUTE)}
        style={{visibility: showMonitorar() ? 'visible' : 'hidden'}}
      >
        Monitorar campanhas
      </Button>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  label="Título"
                  name="search"
                  onChange={(e) =>
                    setFiltros({...filtros, search: e?.target?.value || ''})
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectAnunciantes}
                  label="Anunciante"
                  name="anunciante"
                  onChange={(e) => {
                    setFiltros({...filtros, anunciante: e});
                  }}
                  isClearable
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectComercial}
                  name="comercial"
                  onChange={(e) => setFiltros({...filtros, comercial: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Situação"
                  value={filtros.campanhaSituacao}
                  onChange={(e) =>
                    setFiltros({...filtros, campanhaSituacao: e || null})
                  }
                  options={[
                    {label: 'Todas'},
                    {value: 'ATIVA', label: 'Ativa'},
                    {value: 'EM_ANDAMENTO', label: 'Em andamento'},
                    {value: 'PENDENTE', label: 'Aguardando motoristas'},
                    {
                      value: 'AGUARDANDO_IMPRESSAO',
                      label: 'Aguardando impressão',
                    },
                    {
                      value: 'AGUARDANDO_INSTALACAO',
                      label: 'Aguardando instalação',
                    },
                    {value: 'FINALIZADA', label: 'Finalizada'},
                    {value: 'SUSPENSA', label: 'Suspensa'},
                    {value: 'CANCELADA', label: 'Cancelada'},
                    {value: 'NAO_APROVADA', label: 'Não aprovada'},
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  value="Filtrar"
                  fullWidth
                  onClick={() => refetch()}
                  loading={campanhasQuery.loading}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead tabIndex={-1}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell id="acoes" key="acoes" align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campanhas.map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={index} align={column.align} style={column.style}>
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center">
                            <RoundTooltipButton
                              title={'Painel anunciante'}
                              onClick={() => {
                                setLoadingAccessAnunciante(row);
                                painelService.acessarPainelAnunciante(row.anunciante?.id, setLoadingAccessAnunciante);
                              }}
                              loading={loadingAccessAnunciante?.id === row.id}
                              icon={<OpenInNew />}
                              id="icon-gray"
                            />
                            <RoundTooltipButton
                              title={'Visualizar'}
                              onClick={() => navigate(`${row.id}/editar`)}
                              icon={<Visibility />}
                              id="icon-primary"
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalCampanhas}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <InstaladorModal
        openModal={openInstaladorModal}
        instalador={instalador}
        onClose={() => {
          setOpenInstaladorModal(false);
          setInstalador(null);
          setIniciandoCampanha(null);
        }}
        onChange={setInstalador}
        iniciarCampanha={() => handleIniciarCampanha(iniciandoCampanha)}
      />
    </Grid>
  );
};

const styles = {
  warningIcon: {
    color: (theme) => theme.palette.paterns.yellow,
    height: '15px',
    width: '15px',
    marginLeft: '4px',
    marginBottom: '-2px'
  }
};

export default ConsultaCampanha;
