import React from 'react';
import { FIND_ALL_COMERCIAL_EXECUTIVO as query } from '../../graphql/queries';
import { SelectBase } from './mui-base';
const transformOptions = ({content}) => {
  return Array.isArray(content)
    ? content.map((item) => ({
      ...item,
      label: `${item.nome}`,
      value: item.id,
    }))
    : [];
};
const getVariables = ({
  pageNumber,
  search = '',
  searchDTO = {},
  pageSize = 20,
}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nome', sortDir: 'ASC'},
    searchDTO: {
     ...searchDTO,
     search
    },
  };
};

export const SelectExecutivos = (props) => {
  return (
    <SelectBase
      label="Executivo"
      responseObjectName="findAllComercialExecutivo"
      transformOptions={transformOptions}
      query={query}
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      {...props}
    />
  );
};
