import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_BY_ID_ADMINISTRADOR} from '../../graphql/queries';
import FormularioAdministrador from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarAdministrador = () => {
  const {administradorId} = useParams();

  const administradorQuery = useQuery(FIND_BY_ID_ADMINISTRADOR, {
    variables: {
      id: administradorId,
    },
  });

  const {data = {}} = administradorQuery;
  const {findByIdAdministrador: administrador = {}} = data;

  if (administradorQuery.loading || administradorQuery.error)
    return (<LoadingPage loading={administradorQuery.loading} error={administradorQuery.error} />);

  return (
    <FormularioAdministrador administrador={administrador} />
  );
};

export default EditarAdministrador;
