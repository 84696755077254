import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ConsultaAdministrador from './consulta';
import EditarAdministrador from './editar';
import CadastrarAdministrador from './cadastrar';

const Administrador = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaAdministrador />} />
      <Route path={`/:administradorId/editar`} element={<EditarAdministrador />} />
      <Route path={`/criar`} element={<CadastrarAdministrador />} />
    </Routes>
  );
};

export default Administrador;
