import React, {useEffect, useState, useRef} from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Box
} from '@mui/material';
import {PrintOutlined, KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {useQuery} from '@apollo/client';
import {Field, Formik} from 'formik';
import ReactToPrint from 'react-to-print';
import {Collapse} from '@mui/material';
import moment from 'moment';
import {FIND_ALL_CAMPANHA_TROCAS_MOTORISTA, FIND_ALL_ANUNCIOS_TROCAS_MOTORISTA, FIND_ALL_TROCAS_MOTORISTA_BY_ANUNCIO} from '../../../graphql/queries';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import {SelectEstados} from '../../../containers/selects/mui-estados';
import {SelectCampanhas} from '../../../containers/selects/mui-campanhas';
import {ButtonComponent, RoundTooltipButton} from '../../../components/mui-button';
import ImpressaoRelatorioMotoristasSubstituidos from '../../../components/impressao/impressao-motorista-substituido';

const pageStyle = `
  @page { 
    size: A4; 
    margin: 5mm 10mm 5mm 10mm; 
  }

  @media all {
    .page-footer {
      display: block !important;
    }
    div {
      -webkit-print-color-adjust: exact; 
    },
  }
`;

function RowSecundary({anuncioTrocaItem}) {
  const [openCollapsable, setOpenCollapsable] = useState(false)
  const [motorista, setMotorista] = useState([])

  const trocasMotoristaByAnuncioQuery = useQuery(FIND_ALL_TROCAS_MOTORISTA_BY_ANUNCIO, {
    variables: {
      anuncio: {
        id: anuncioTrocaItem?.anuncioId || undefined,
      }
    }
  });

  useEffect(() => {
    if (trocasMotoristaByAnuncioQuery.loading) return;
    setMotorista(
      trocasMotoristaByAnuncioQuery?.data?.findAllTrocasMotoristaByAnuncio || [],
    );
  }, [trocasMotoristaByAnuncioQuery]);

  return (
    <React.Fragment key={anuncioTrocaItem.anuncioId}>
      <TableRow tabIndex={-1} >
        <TableCell> {openCollapsable ?
          <KeyboardArrowUp onClick={() => setOpenCollapsable(false)} sx={{cursor: 'pointer'}} /> :
          <KeyboardArrowDown onClick={() => setOpenCollapsable(true)} sx={{cursor: 'pointer'}} />}</TableCell>
        <TableCell colSpan={2}>{anuncioTrocaItem.motoristaNome}</TableCell>
        <TableCell>
          {moment(anuncioTrocaItem.dataTroca).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell>
          {anuncioTrocaItem.numeroTrocas}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} sx={{padding: "0 8px"}}>
          <Collapse in={openCollapsable} timeout="auto" unmountOnExit>
            <Box sx={{margin: '8px 0px'}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Motorista origem</TableCell>
                    <TableCell>Motorista destino</TableCell>
                    <TableCell>Situação na troca</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Motorista destino instalou?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {motorista.map((item, index) => (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell>{item.motoristaOrigem?.nome}</TableCell>
                      <TableCell>{item.motoristaDestino?.nome}</TableCell>
                      <TableCell>{item.anuncioSituacao}</TableCell>
                      <TableCell>{moment(item.dataTroca).format("DD/MM/YYYY")}</TableCell>
                      <TableCell>{item.motoristaDestinoInstalado ? "Sim" : "Não"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function RowPrimary({campanhaTrocaItem}) {
  const [openCollapsable, setOpenCollapsable] = useState(false)
  const [anuncioTroca, setAnuncioTroca] = useState([])
  const anunciosTrocasMotoristaQuery = useQuery(
    FIND_ALL_ANUNCIOS_TROCAS_MOTORISTA,
    {
      variables: {
        campanhaId: campanhaTrocaItem.campanhaId || null,
      }
    },
  );

  useEffect(() => {

    if (anunciosTrocasMotoristaQuery.loading) return;

    setAnuncioTroca(
      anunciosTrocasMotoristaQuery?.data?.findAllAnunciosTrocasMotorista || [],
    );
  }, [anunciosTrocasMotoristaQuery]);

  return (
    <React.Fragment key={campanhaTrocaItem.campanhaId}>
      <TableRow tabIndex={-1}  >
        <TableCell> {openCollapsable ?
          <KeyboardArrowUp onClick={() => setOpenCollapsable(false)} sx={{cursor: 'pointer'}} /> :
          <KeyboardArrowDown onClick={() => setOpenCollapsable(true)} sx={{cursor: 'pointer'}} />}
        </TableCell>
        <TableCell>{campanhaTrocaItem.tituloCampanha}</TableCell>
        <TableCell>{campanhaTrocaItem.nomeCidade}/{campanhaTrocaItem.ufCidade}</TableCell>
        <TableCell>
          {moment(campanhaTrocaItem.dataTroca).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>
          {campanhaTrocaItem.numeroTrocas}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} sx={{padding: '0 8px'}}>
          <Collapse in={openCollapsable} timeout="auto" unmountOnExit>
            <Box sx={{margin: '8px 0px'}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell colSpan={2}>Motorista atual</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Trocas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anuncioTroca.map((item, index) => (
                    <RowSecundary anuncioTrocaItem={item} key={index} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const ConsultaSubstituidos = () => {
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [filtros, setFiltros] = useState({});
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [campanhaTroca, setCampanhaTroca] = useState([]);

  const campanhaTrocasMotoristaQuery = useQuery(
    FIND_ALL_CAMPANHA_TROCAS_MOTORISTA,
    {
      variables: {
        cidadeId: searchDTO.cidade?.id || null,
        estadoId: searchDTO.estado?.id || null,
        campanhaId: searchDTO.campanha?.id || null,
        pageable: {
          pageNumber: 0,
          pageSize: 30,
          sortField: 'dataTroca',
          sortDir: 'DESC'
        },
      }
    },
  );

  const refetch = () => {
    setSearchDTO(filtros);
  };

  useEffect(() => {
    if (campanhaTrocasMotoristaQuery.loading) return;
    setCampanhaTroca(
      campanhaTrocasMotoristaQuery?.data?.findAllCampanhaTrocasMotorista?.content || [],
    );
  }, [campanhaTrocasMotoristaQuery]);

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Motoristas substituídos'}`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() => (
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintOutlined />}
            loading={loading}
            loadingColor="grey"
          />
        )}
        content={() => componentRef.current}
      />
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  component={({...props}) => <SelectCampanhas {...props} />}
                  label="Campanha"
                  name="campanha"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, campanha: e})}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, estado: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, cidade: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  value="Filtrar"
                  loading={campanhaTrocasMotoristaQuery.loading}
                  onClick={refetch}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Campanha</TableCell>
                    <TableCell>Cidade</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Trocas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campanhaTroca.map((item, index) => (
                    <RowPrimary campanhaTrocaItem={item} key={index} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid overflow="hidden" height="0px">
        <Grid ref={componentRef} background="#fff">
          <ImpressaoRelatorioMotoristasSubstituidos campanhaTroca={campanhaTroca} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaSubstituidos;
