import React, {useEffect, useState} from 'react';
import {Grid, Paper, Box} from '@mui/material';
import {Edit, Save} from '@mui/icons-material';
import {Field, Formik} from 'formik';
import {useLazyQuery, useMutation} from '@apollo/client';
import {ButtonComponent} from '../../components/mui-button';
import {ATUALIZAR_CIDADE} from '../../graphql/mutation';
import Number from '../../utils/number';
import {SelectEstados} from '../../containers/selects/mui-estados';
import InputV2 from '../../components/input-v2/mui-input-v2';
import Mapa from './mapa';
import PontosInteresse from './pontosInteresse';
import config from '../../config/config';
import PalavraChave from './palavraChave';
import {BUSCAR_LUGARES_MAPS} from '../../graphql/queries';
import {toastNotification} from '../../components/toastify';

const FormularioCidade = ({cidade = {}, refetch}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({});
  const [novoPonto, setNovoPonto] = useState({});
  const [novaPalavra, setNovaPalavra] = useState({});
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [nextPageToken, setNextPageToken] = useState(undefined);
  const [loadingSave, setLoadingSave] = useState(false);
  const [invisiblePonto, setInvisiblePonto] = useState([]);
  const [invisibleRegiao, setInvisibleRegiao] = useState([]);
  const [showRegiao, setShowRegiao] = useState(false);
  const [showPonto, setShowPonto] = useState(false);
  const [showPalavra, setShowPalavra] = useState(false);
  const [invisiblePalavra, setInvisiblePalavra] = useState([]);
  const [updateMetricas, setUpdateMetricas] = useState(false);
  const [circle, setCircle] = useState(undefined);

  const [buscarLugares, lugarasQuery] = useLazyQuery(BUSCAR_LUGARES_MAPS);
  const [atualizarCidade] = useMutation(ATUALIZAR_CIDADE);

  const cidadeRegiao = cidade.pontosInteresse.filter(
    ({tipo}) => tipo === 'REGIAO',
  );
  const cidadePonto = cidade.pontosInteresse.filter(
    ({tipo}) => tipo === 'PONTO',
  );
  const cidadePalavra = cidade.palavrasChave;

  useEffect(() => {
    if (!updateMetricas) return;

    handleSubmitDados();

    setUpdateMetricas(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMetricas]);

  useEffect(() => {
    if (!cidade?.id) return;

    setFormData({
      ...cidade,
      impactadosKm: Number.format(cidade.impactadosKm || 0),
      kmPadrao: Number.format(cidade.kmPadrao || 0),
      raioPadrao: Number.format(cidade.raioPadrao || 0),
    });
  }, [cidade]);

  async function handleSubmitDados() {
    try {
      setSubmitting(true);
      await atualizarCidade({
        variables: {
          cidade: {
            id: cidade.id,
            nome: formData.nome,
            codigoIBGE: formData.codigoIBGE,
            uf: formData.estado?.uf,
            estado: {
              id: formData.estado?.id,
            },
            impactadosKm: Number.safeParseInt(
              Math.sqrt(formData.densidadeDemografica),
            ),
            kmPadrao: Number.stringToFloat(formData.kmPadrao),
            raioPadrao: formData.raioPadrao,
            campanhasRealizadas: formData?.campanhasRealizadas,
            kmMedio: formData?.kmMedio,
            mediaCorridas: Number.safeParseInt(formData.mediaCorridas),
            densidadeDemografica: formData.densidadeDemografica,
            populacao: formData.populacao,
            metricasAtualizadas: formData.metricasAtualizadas,
          },
        },
      });

      setReadOnly(true);
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setSubmitting(false);
      if (readOnly) return;
      toastNotification({
        message: 'Os dados cadastrados foram salvos!',
        type: 'success',
      });
    }
  }

  useEffect(() => {
    const circleObject = {...circle};
    const pontoDel = [...invisiblePonto];
    const regiaoDel = [...invisibleRegiao];

    cidadePonto.forEach((item) => {
      if (
        item.longitude === circleObject.longitude &&
        item.latitude === circleObject.latitude
      ) {
        const apaguePonto = item;
        setInvisiblePonto([apaguePonto, ...pontoDel]);
      }
    });

    cidadeRegiao.forEach((item) => {
      if (
        item.longitude === circleObject.longitude &&
        item.latitude === circleObject.latitude
      ) {
        const apagueRegiao = item;
        setInvisibleRegiao([apagueRegiao, ...regiaoDel]);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circle]);

  useEffect(() => {
    if (
      invisibleRegiao?.length === cidadeRegiao?.length &&
      cidadeRegiao?.length !== 0
    ) {
      setShowRegiao(true);
    }
    if (
      invisiblePonto?.length === cidadePonto?.length &&
      cidadePonto?.length !== 0
    ) {
      setShowPonto(true);
    }
    if (
      invisiblePalavra?.length === cidadePalavra?.length &&
      cidadePalavra?.length !== 0
    ) {
      setShowPalavra(true);
    }
  }, [
    invisibleRegiao,
    cidadeRegiao,
    invisiblePonto,
    cidadePonto,
    invisiblePalavra,
    cidadePalavra,
    circle,
  ]);

  const handleMapClick = (event) => {
    if (event.latLng.lat() && event.latLng.lng()) {
      setNovoPonto((p) => ({
        ...p,
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
        kmRaio: p.kmRaio || 1,
      }));
    }
  };

  const searchMapPlaces = async (search, nextPageToken) => {
    if (!nextPageToken) {
      setNovaPalavra((p) => ({...p, pontos: []}));
    }

    if (!search) return;

    setLoadingSave(true);

    buscarLugares({
      variables: {
        location: `${formData.latitude},${formData.longitude}`,
        radius: (Number.stringToFloat(formData.raioPadrao) || 1) * 600,
        palavraChave: search,
        nextPageToken,
        key: config.GOOGLE_MAPS_API_KEY,
      },
    });
  };

  useEffect(() => {
    if (lugarasQuery.loading || !lugarasQuery.data?.lugares) return;

    try {
      const response = JSON.parse(lugarasQuery.data?.lugares);

      if (response.status === 'INVALID_REQUEST' && nextPageToken) {
        setTimeout(
          () => searchMapPlaces(novaPalavra.palavraChave, nextPageToken),
          5000,
        );
        setNextPageToken(undefined);
        return;
      }

      setNextPageToken(response.next_page_token);

      setNovaPalavra((n) => ({
        ...n,
        pontos: [
          ...n.pontos,
          ...response.results?.map((a) => ({
            latitude: a.geometry.location.lat,
            longitude: a.geometry.location.lng,
            nome: a.name,
          })),
        ],
      }));

      if (response.next_page_token) {
        return;
      }

      setLoadingSave(false);
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lugarasQuery]);

  useEffect(() => {
    if (!nextPageToken) return;

    setTimeout(
      () => searchMapPlaces(novaPalavra.palavraChave, nextPageToken),
      1000,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPageToken]);

  useEffect(() => {
    if (!novaPalavra.palavraChave) {
      setNovaPalavra({});
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(function () {
        searchMapPlaces(novaPalavra.palavraChave);
      }, 1000),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novaPalavra.palavraChave]);

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik initialValues={formData} enableReinitialize>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <InputV2
                name="codigoIBGE"
                label="Código IBGE"
                value={formData.codigoIBGE}
                onChange={({target}) =>
                  setFormData((f) => ({...f, codigoIBGE: target.value}))
                }
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <InputV2
                name="nome"
                label="Nome"
                value={formData.nome}
                onChange={({target}) =>
                  setFormData((f) => ({...f, nome: target.value}))
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={2} xs={3}>
              <Field
                component={SelectEstados}
                name="estado"
                label="Estado"
                value={formData.estado}
                onChange={(e) => setFormData((f) => ({...f, estado: e}))}
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={2} xs={3}>
              <InputV2
                name="mediaCorridas"
                label="Média / Corridas"
                mask="decimal"
                value={formData.mediaCorridas}
                onChange={({target}) =>
                  setFormData((f) => ({...f, mediaCorridas: target.value}))
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={1} xs={2}>
              <InputV2
                name="kmPadrao"
                label="Km / Mês"
                mask="decimal"
                value={formData.kmPadrao}
                onChange={({target}) =>
                  setFormData((f) => ({...f, kmPadrao: target.value}))
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={1} xs={2}>
              <InputV2
                name="raioPadrao"
                label="Raio"
                mask="decimal"
                value={formData.raioPadrao}
                onChange={({target}) =>
                  setFormData((f) => ({...f, raioPadrao: target.value}))
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={2} xs={3}>
              <InputV2
                name="impactadosKm"
                label="Impactados / Km"
                mask="decimal"
                value={Number.safeParseInt(formData.impactadosKm)}
                disabled
              />
            </Grid>
            <Grid item xs={2}>
              <InputV2
                name="campanhas"
                label="Campanhas"
                value={Number.format(formData?.campanhasRealizadas)}
                disabled
              />
            </Grid>
            <Grid item md={2} xs={3}>
              <InputV2
                name="kmMedia"
                label="Km médio"
                value={`${Number.format(formData?.kmMedio)} Km`}
                disabled
              />
            </Grid>
            <Grid item md={2} xs={4}>
              <InputV2
                name="densidade"
                label="Densidade demográfica"
                value={Number.format(formData?.densidadeDemografica || 0)}
                disabled
              />
            </Grid>
            <Grid item xs={2}>
              <InputV2
                name="populacao"
                label="População"
                value={Number.format(formData?.populacao || 0)}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.mapaContainer}>
                {formData.raioPadrao &&
                  formData.latitude &&
                  formData.longitude && (
                    <Mapa
                      kmRaio={formData.raioPadrao}
                      latitude={formData.latitude || null}
                      longitude={formData.longitude || null}
                      pontosInteresse={[...cidadePonto, novoPonto]}
                      regiaoInteresse={[...cidadeRegiao, novoPonto]}
                      palavraInteresse={[...cidadePalavra]}
                      deletePonto={invisiblePonto}
                      invisibleRegiao={invisibleRegiao}
                      onMapClick={handleMapClick}
                      invisiblePalavra={invisiblePalavra}
                      onclick={(item) => {
                        setCircle({...item});
                      }}
                    />
                  )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <PontosInteresse
                cidade={cidade}
                cidadeInteresse={cidadeRegiao}
                tipo="REGIAO"
                refetch={refetch}
                novoPonto={novoPonto}
                setNovoPonto={setNovoPonto}
                setShowPoints={setShowRegiao}
                showPoints={showRegiao}
                setInvisiblePoints={setInvisibleRegiao}
                invisiblePoints={invisibleRegiao}
              />
              <PontosInteresse
                cidade={cidade}
                cidadeInteresse={cidadePonto}
                tipo="PONTO"
                refetch={refetch}
                novoPonto={novoPonto}
                setNovoPonto={setNovoPonto}
                setShowPoints={setShowPonto}
                showPoints={showPonto}
                setInvisiblePoints={setInvisiblePonto}
                invisiblePoints={invisiblePonto}
              />
              <PalavraChave
                cidade={cidade}
                cidadeInteresse={cidadePalavra}
                refetch={refetch}
                novaPalavra={novaPalavra}
                setNovaPalavra={setNovaPalavra}
                loadingSave={loadingSave}
                setInvisiblePoints={setInvisiblePalavra}
                invisiblePoints={invisiblePalavra}
                setShowPoints={setShowPalavra}
                showPoints={showPalavra}
              />
            </Grid>
            <Grid item xs={12}>
              {readOnly ? (
                <ButtonComponent
                  type="button"
                  sx={styles.button}
                  value={'Editar'}
                  icon={<Edit />}
                  onClick={() => setReadOnly(false)}
                  disabled={submitting}
                />
              ) : (
                <ButtonComponent
                  type="button"
                  sx={styles.button}
                  value={'Salvar'}
                  loading={submitting}
                  icon={<Save />}
                  onClick={handleSubmitDados}
                  disabled={submitting}
                />
              )}
            </Grid>
          </Grid>
        </Formik>
      </Paper>
    </Grid>
  );
};

const styles = {
  button: {
    width: '256px',
  },
  mapaContainer: {
    width: '100%',
    height: '36vh',

    '@media (min-width: 1367px)': {
      height: '40vh',
    },
  },
};

export default FormularioCidade;
