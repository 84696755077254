import React from 'react';
import {Route, Routes} from 'react-router-dom';
import CadastrarSegmento from './cadastrar';
import ConsultaSegmentos from './consulta';
import EditarSegmento from './editar';

const SegmentoAtuacao = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaSegmentos />} />
      <Route path={`/criar`} element={<CadastrarSegmento />} />
      <Route path={`/:segmentoId/editar`} element={<EditarSegmento />} />
    </Routes>
  )
};

export default SegmentoAtuacao;