import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {AppBar, Box, Grid, Typography} from '@mui/material';
import {ExitToApp} from '@mui/icons-material';
import {RoundTooltipButton} from '../components/mui-button';
import {logout} from '../redux/Auth';
import {routeLabel} from '../constants/routes';

export const ToolBarComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {name} = useSelector((store) => store.Auth);

  const handleClickLogout = async () => {
    dispatch(logout());
  };

  return (
    <AppBar sx={styles.appBarStyled} position="relative">
      <Grid container sx={styles.rotaContainer}>
        {location.pathname.split('/')[1] !== 'home' && (
          <Typography sx={styles.rotaEscolhida}>
            {routeLabel[`/${location.pathname.split('/')[1]}`]}
          </Typography>
        )}
        {location.pathname.split('/')[1] === 'home' && (
          <Typography sx={styles.rotaEscolhida}>
            {`Bem vindo(a) ${name}!`}
          </Typography>
        )}
      </Grid>
      <Box sx={styles.toolBarTools}>
        <RoundTooltipButton
          title={'Sair'}
          onClick={handleClickLogout}
          icon={<ExitToApp sx={styles.exitToAppStyled} />}
        />
      </Box>
    </AppBar>
  );
};

const styles = {
  appBarStyled: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: '#fff !important',
    width: '100% !important',
    height: (theme) => `${theme.toolbarHeight} !important`,
    zIndex: 5,
    padding: (theme) => `${theme.space[1]} 1rem`,
    boxShadow: ' -10px 6px 12px rgba(128, 152, 213, 0.2)',
    '& input': {
      height: '100%',
    },
  },
  toolBarTools: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    svg: {
      height: ' 100%',
    },
  },
  exitToAppStyled: {
    color: '#8995B0 !important',
    width: '30px !important',
    height: '30px !important',
    cursor: 'pointer',
  },
  rotaContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rotaEscolhida: {
    fontSize: '20px',
    color: '#00398e',
    fontWeight: '900'
  },
};
