import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useQuery} from '@apollo/client';
import {Paper, Tabs, Tab, Grid} from '@mui/material';
import {Campaign, DriveEta, ImageSearch} from '@mui/icons-material';
import {LoadingPage} from '../../components/loading-page';
import {FIND_BY_ID_CAMPANHA, PERMITE_EDITAR_CAMPANHA} from '../../graphql/queries';
import FormularioCampanha from './formulario';
import TabPanel from '../../components/tab-painel';
import ImagensCampanha from './imagens-campanha';
import Cidades from './cidades';

const EditarCampanha = () => {
  const {campanhaId} = useParams();

  const {role} = useSelector((store) => store.Auth);
  const {index} = useSelector((store) => store.Campanha);
  const [tabIndex, setTabIndex] = useState(index);

  const permiteEditarQuery = useQuery(PERMITE_EDITAR_CAMPANHA, {
    variables: {
      campanha: {
        id: campanhaId,
      }
    },
  });

  const campanhaQuery = useQuery(FIND_BY_ID_CAMPANHA, {
    variables: {
      id: campanhaId,
    },
  });

  const {data = {}} = campanhaQuery;
  const {findByIdCampanha: campanha} = data;
  const permiteEditar = permiteEditarQuery.data?.permite;

  const refetch = () => {
    campanhaQuery.refetch();
  };

  const a11yProps = (index) => {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  };

  if (campanhaQuery.loading || campanhaQuery.error) {
    return (
      <LoadingPage loading={campanhaQuery.loading} error={campanhaQuery.error} />
    );
  }

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Tabs
          value={tabIndex}
          onChange={(event, index) => setTabIndex(index)}
          variant="standard"
          aria-label="nav tabs campanha"
          className="tabs"
        >
          <Tab
            iconPosition="start"
            className="tab-button"
            icon={<Campaign />}
            label={<p classes="tabLabel">Campanha</p>}
            {...a11yProps(0)}
          />
          <Tab
            iconPosition="start"
            className="tab-button"
            icon={<DriveEta />}
            label={<p classes="tabLabel">Cidades</p>}
            {...a11yProps(1)}
          />
          <Tab
            className="tab-button"
            iconPosition="start"
            icon={<ImageSearch />}
            label={<p classes="tabLabel">Check-in / Check-out</p>}
            style={{visibility: role === 'ROLE_FINANCEIRO' ? 'hidden' : 'visible'}}
            {...a11yProps(2)}
          />
        </Tabs>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={0}>
          <FormularioCampanha campanha={campanha} role={role} refetch={refetch} permiteEditar={permiteEditar} />
        </TabPanel>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={1}>
          <Cidades
            campanha={campanha}
            refetch={campanhaQuery.loading}
          />
        </TabPanel>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={2}>
          <ImagensCampanha campanha={campanha} />
        </TabPanel>
      </Paper>
    </Grid>
  );
};

const styles = {
  tabPainel: {
    minHeight: 'calc(100vh - 185px)',
    margin: '10px 0',
    overflowY: 'auto',
  },
};

export default EditarCampanha;
