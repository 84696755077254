import {normalize} from 'polished';
import {createGlobalStyle} from 'styled-components';
import AktivGrotesk from './fonts/AktivGrotesk-Regular.ttf';
import AktivGroteskLight from './fonts/AktivGrotesk-Light.ttf';
import AktivGroteskMedium from './fonts/AktivGrotesk-Medium.ttf';
import AktivGroteskBold from './fonts/AktivGrotesk-Bold.ttf';
import {LIGHT_COLORS} from './colors/mui-colors';

export const GlobalStyle = createGlobalStyle`
  ${normalize()}

  @font-face {
    font-family: 'AktivGrotesk';
    src: url(${AktivGrotesk}) format('truetype');
  }

  @font-face {
    font-family: 'AktivGrotesk-Regular';
    src: url(${AktivGrotesk}) format('truetype');
  }

  @font-face {
    font-family: 'AktivGrotesk-Light';
    src: url(${AktivGroteskLight}) format('truetype');
  }

  @font-face {
    font-family: 'AktivGrotesk-Medium';
    src: url(${AktivGroteskMedium}) format('truetype');
  }

  @font-face {
    font-family: 'AktivGrotesk-Bold';
    src: url(${AktivGroteskBold}) format('truetype');
  }

  * {
    box-sizing: border-box;
    outline: none;
  }

  html, body, #root {
    height: 100%;
    background: ${() => LIGHT_COLORS.background};
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  body {
    font-size: 16px;
    line-height: 1.5;
    color: ${() => LIGHT_COLORS.text};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  .Toastify__toast--success {
    background-color: ${() => LIGHT_COLORS.green};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .Toastify__toast-icon > svg {
      fill: #fff !important;
    }

    .Toastify__close-button {
      color: #fff;
      width: 19px;
      height: 19px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1 !important;
      align-self: center;

      >svg {
        fill:${() => LIGHT_COLORS.white} !important;
        transform: scale(1.1);
      }
    }
  }

  .Toastify__toast--error {
    background-color: ${() => LIGHT_COLORS.cancel};
    color: #fff;

    .Toastify__toast-icon > svg {
      fill: #fff !important;
    }

    .Toastify__close-button {
      color: #fff;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      opacity: 1 !important;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;

      >svg {
        fill:${() => LIGHT_COLORS.white} !important;
        transform: scale(1.1);
      }
    }
  }

  .Toastify__toast--warning {
    background-color: ${() => LIGHT_COLORS.yellow};
    color: #fff;

    .Toastify__toast-icon>svg {
      fill: #fff !important;
    }

    .Toastify__close-button {
      color: #fff;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      opacity: 1 !important;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;

      >svg {
        fill:${() => LIGHT_COLORS.white} !important;
        transform: scale(1.1);
      }
    }
  }
  .Toastify__toast-container--bottom-center {
    width: 80%;
    margin-left: 30px;
  }

  .MuiTooltip-tooltip {
    font-size: 13px !important;
  }

  .map-container {
    height: 100%;
    width: 100%;
  }

  .map-ref {
    height: 100%;
  }

  table tr:nth-child(odd) {
    background-color: white;
  }

  table tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .gm-style .gm-style-iw {
    background: none;
    border-radius: 0;

    & > button {
      display: none !important;
    }
  }

  .gm-style .gm-style-iw-t::after {
    background: #657496;
  }

  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style .gm-style-iw-c {
    box-shadow: none;
    padding: 0 !important;
  }

  .iw-condutor-container {
    background-color: #657496;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;

    & .iw-condutor-avatar {
      display: flex;
      margin-right: 15px;
    }

    & .iw-condutor-info {
      & label {
        color: #afb7c9;
        font-size: 12px;
        margin-bottom: 3px;
        display: block;
      }

      & p {
        margin: 0 0 8px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .toolbar-action {
    z-index: 2;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
  .react-confirm-alert-overlay {
    z-index: 1999;
  }
  .tab-painel{
    min-height: calc(100vh - 185px);
    margin: 10px 0;
    overflow-y: auto;
  }
  .styled-scroll-transparent {
    &::-webkit-scrollbar {
      width: 7px;
      margin-left: 5px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 150, 255, 0.7);
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 150, 255, 1);
    }
  }
  .styled-scroll {
    &::-webkit-scrollbar {
      width: 4px;
      margin-left: 5px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(101, 116, 150, 0.15);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(101, 116, 150, 0.25);
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(15, 54, 108, 0.6);
    }
  }
  .styled-scroll-horizontal {
    &::-webkit-scrollbar {
      height: 4px;
      margin-top: 0px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 150, 255, 0.7);
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 150, 255, 1);
    }
  }
`;
