import React from 'react';
import FormularioSegmento from './formulario';

const CadastrarSegmento = () => {
  return (
    <FormularioSegmento />
  );
};

export default CadastrarSegmento;
