import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Paper, Grid, Box} from '@mui/material';
import {useMutation} from '@apollo/client';
import {ButtonComponent} from '../../components/mui-button';
import {
  CRIAR_MARCA_VEICULO,
  ATUALIZAR_MARCA_VEICULO,
} from '../../graphql/mutation';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {toastNotification} from '../../components/toastify';

const FormularioMarcaVeiculo = ({marca = {}}) => {
  const [marcaVeiculo, setMarcaVeiculo] = useState({});
  const [criarMarca] = useMutation(CRIAR_MARCA_VEICULO);
  const [atualizarMarca] = useMutation(ATUALIZAR_MARCA_VEICULO);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setMarcaVeiculo(marca);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marca.id]);

  async function handleSubmitDadosMarca() {
    try {
      setSubmitting(true);
      if (marcaVeiculo.id) {
        await atualizarMarca({
          variables: {
            marca: {
              id: marcaVeiculo.id,
              nome: marcaVeiculo.nome,
            },
          },
        });
      } else {
        await criarMarca({
          variables: {
            marca: {
              nome: marcaVeiculo.nome,
            },
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
      navigate('/marca-veiculo');
      toastNotification({
        message: 'Os dados cadastrados foram salvos com sucesso!',
        type: 'success',
      });
    }
  }

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          validationSchema={MarcaScheme}
          initialValues={{
            nome: marcaVeiculo.nome || '',
          }}
          enableReinitialize
          onSubmit={handleSubmitDadosMarca}
        >
          {({isSubmitting, handleSubmit}) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputV2
                    label="Marca"
                    value={marcaVeiculo.nome}
                    onChange={(e) =>
                      setMarcaVeiculo({...marcaVeiculo, nome: e.target.value})
                    }
                    className="input-filtro"
                  />
                </Grid>
                <Grid item xs={1}>
                  <ButtonComponent
                    sx={{width: '256px'}}
                    type="submit"
                    value={'Salvar'}
                    loading={submitting}
                    disabled={submitting}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

const MarcaScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
});

export default FormularioMarcaVeiculo;
