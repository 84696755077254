import React from 'react';
import {FIND_ALL_MOTORISTA as query} from '../../graphql/queries';
import {SelectBase} from './mui-base';

const getVariables = ({pageNumber, search = '', searchDTO = {}, pageSize = 20}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nome', sortDir: 'ASC'},
    searchDTO: {
      ...searchDTO,
      search
    }
  };
};

export const SelectMotoristas = (props) => {
  return (
    <SelectBase
      label="Motorista"
      responseObjectName="findAllMotorista"
      labelProp="nome"
      query={query}
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      {...props}
    />
  );
};
