import React, {useEffect, useState} from 'react';
import {Field, Formik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useQuery} from '@apollo/client';
import {Paper, Grid, Button, TableContainer, Table, TableHead, TableRow, TableCell, TablePagination, TableBody, Box} from '@mui/material';
import {Add, Edit} from '@mui/icons-material';
import {RoundTooltipButton, ButtonComponent} from '../../components/mui-button';
import {FIND_ALL_MODELO_VEICULO} from '../../graphql/queries';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {SelectMarcasVeiculo} from '../../containers/selects/mui-marca-veiculo';
import TableCellOrdered from '../../components/table-cell-ordered';
import inputsEvents from '../../utils/inputsEvents';
import {
  persistFiltrosModeloVeiculo,
  persistModeloVeiculo,
} from '../../redux/ModeloVeiculo/slice';

const columns = [
  {id: 'nome', label: 'Modelo'},
  {
    id: 'marca',
    label: 'Marca',
    campo: 'marca.nome',
    format: (marca) => (marca ? marca.nome : ''),
  },
];

const ConsultaModeloVeiculo = ({pageSize = 20}) => {
  const {
    filtrosModeloVeiculo,
    modeloVeiculo,
    totalModeloVeiculo,
    refetchModeloVeiculo,
  } = useSelector((store) => store.ModeloVeiculo);

  const [page, setPage] = useState(filtrosModeloVeiculo.page);
  const [rowsPerPage] = useState(pageSize);
  const [orderBy, setOrderBy] = useState(filtrosModeloVeiculo.orderBy);
  const [filtros, setFiltros] = useState(filtrosModeloVeiculo.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modelosQuery = useQuery(FIND_ALL_MODELO_VEICULO, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        marcaId: searchDTO.marca?.id,
      },
    },
  });

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (
      !modelosQuery.loading &&
      !modelosQuery.error &&
      modelosQuery.data?.modelos
    ) {
      dispatch(persistFiltrosModeloVeiculo({filtros, page, orderBy}));
      dispatch(persistModeloVeiculo(modelosQuery.data?.modelos));
    }
    if (refetchModeloVeiculo) modelosQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelosQuery, dispatch, refetchModeloVeiculo]);

  return (
    <Grid id="page-container" container>
      <Button
        id="button-green"
        startIcon={<Add style={{marginRight: 10}} />}
        onClick={() => navigate(`criar`)}
        toolbar="true"
      >
        Cadastrar novo
      </Button>
      <Grid item xs={12} onKeyUp={({key}) => inputsEvents.handleKeyUp(key, refetch)}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <InputV2
                  label="Modelo"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={SelectMarcasVeiculo}
                  label="Marca"
                  name="marca"
                  isClearable
                  onChange={(e) => {
                    setFiltros({...filtros, marca: e});
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  id="button"
                  value="Filtrar"
                  loading={modelosQuery.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                  <TableCell id="acoes" key="acoes" align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modeloVeiculo.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="right">
                        <Grid marginRight="-5px">
                          <RoundTooltipButton
                            id="icon-primary"
                            title={'Editar'}
                            onClick={() => navigate(`${row.id}/editar`)}
                            icon={<Edit />}
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalModeloVeiculo}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'próxima página',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ConsultaModeloVeiculo;
