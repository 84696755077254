import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';

const filtrosAnaliseCarteira = {
  filtros: {
    mesInicio: JSON.stringify(moment().startOf('year').toDate()),
    mesFim: JSON.stringify(moment().endOf('year').toDate()),
  },
  page: 0,
  orderBy: {campo: 'nomeFantasia', asc: true},
};

const initialState = {
  licencasList: [],
  analiseCarteiraList: [],
  filtrosLicencas: {
    filtros: {
      ativo: {value: true, label: 'Ativo'},
      role: {value: 'LICENCA_ADMINISTRADOR', label: 'Licença'},
    },
    page: 0,
    orderBy: {campo: 'nomeRazao', asc: true},
  },
  filtrosAnaliseCarteira,
  hasMore: false,
  totalLicencas: 0,
  refetchLicencas: false,
  hasMoreAnaliseCarteira: false,
  totalAnaliseCarteira: 0,
  refetchAnaliseCarteira: false,
  indexTab: 0,
};

const acaoAtualizarLicencas = (state) => {
  state.refetchLicencas = true;
};
const acaoAtualizarAnaliseCarteira = (state) => {
  state.refetchLicencas = true;
};

const acaoPersistLicencas = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.licencasList = content;
  state.hasMore = !last;
  state.totalLicencas = totalElements || 0;
  state.indexTab = 0;

  state.filtrosAnaliseCarteira = filtrosAnaliseCarteira;
};
const acaoPersistAnaliseCarteira = (state, action) => {
  const {contentComercialVendasMetricas, last, totalElements} = action.payload;

  state.analiseCarteiraList = contentComercialVendasMetricas;
  state.hasMoreAnaliseCarteira = !last;
  state.totalAbaliseCarteira = totalElements || 0;
};

const acaoPersistFiltrosLicencas = (state, action) => {
  state.filtrosLicencas = action.payload;
};
const acaoPersistFiltrosAnaliseCarteira = (state, action) => {
  state.filtrosAnaliseCarteira = action.payload;
};
const acaoPersistIndexTabLicencas = (state, action) => {
  state.indexTab = action.payload;
};

const resumeSlice = createSlice({
  name: 'licencas',
  initialState: initialState,
  reducers: {
    persistLicencas: acaoPersistLicencas,
    atualizarLicencas: acaoAtualizarLicencas,
    persistFiltrosLicencas: acaoPersistFiltrosLicencas,
    persistAnaliseCarteira: acaoPersistAnaliseCarteira,
    atualizarAnaliseCarteira: acaoAtualizarAnaliseCarteira,
    persistFiltrosAnaliseCarteira: acaoPersistFiltrosAnaliseCarteira,
    persistIndexTabLicencas: acaoPersistIndexTabLicencas,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistLicencas,
  atualizarLicencas,
  persistFiltrosLicencas,
  persistAnaliseCarteira,
  atualizarAnaliseCarteira,
  persistFiltrosAnaliseCarteira,
  persistIndexTabLicencas,
} = actions;
