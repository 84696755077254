import {useMutation, useQuery} from '@apollo/client';
import {Delete, Edit, Save} from '@mui/icons-material';
import {Box, Button, Grid} from '@mui/material';
import {Field, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {
  ButtonComponent,
  RoundTooltipButton,
} from '../../../components/mui-button';
import MultiSelectFormik from '../../../components/select/mui-multi-select-formik';
import {toastNotification} from '../../../components/toastify';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import {UPDATE_LICENCA_CIDADES} from '../../../graphql/mutation';
import {FIND_ALL_CIDADES} from '../../../graphql/queries';

const FormularioLicencaCidades = ({comercial = {}, refetch}) => {
  const [searchCidade, setSearchCidade] = useState('');
  const [licencaCidades, setLicencaCidades] = useState(
    comercial.licencaCidades || [],
  );
  const [cidades, setCidades] = useState([]);
  const [readOnly, setReadOnly] = useState(!!comercial?.id);

  const [updateCidades, {loading}] = useMutation(UPDATE_LICENCA_CIDADES);

  const cidadesQuery = useQuery(FIND_ALL_CIDADES, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: 30,
        sortField: 'nome',
        sortDir: 'ASC',
      },
      searchDTO: {search: searchCidade},
    },
  });

  useEffect(() => {
    if (cidadesQuery.loading) return;

    setCidades(
      cidadesQuery.data?.cidades?.content?.map((e) => ({
        ...e,
        value: e.id,
        label: e.nomeEUf,
      })),
    );
  }, [cidadesQuery]);

  const handleSubmitDados = (e) => {
    e.preventDefault();

    updateCidades({
      variables: {
        comercial: {
          id: comercial?.id,
          licencaCidades: licencaCidades.map((c) => ({
            id: c.id || undefined,
            comercial: {id: comercial.id},
            cidade: {
              id: c.cidade?.id,
            },
            cidadesRegiao: c.cidadesRegiao?.map((c) => ({id: c.id})),
          })),
        },
      },
    })
      .then(() => {
        setReadOnly(true);
        toastNotification({
          message: 'Cidades salvas!',
          type: 'success',
        });
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        alignContent="flex-start"
        sx={{minHeight: 'calc(100vh - 275px)'}}>
        {licencaCidades.map((item, index) => (
          <Formik
            key={item.id || index}
            validationSchema={ComercialScheme}
            initialValues={item}
            enableReinitialize>
            <Grid key={item.id || index} container item columnSpacing={2}>
              <Grid item xs={4}>
                <Field
                  component={SelectCidades}
                  value={item.cidade}
                  onChange={(e) => {
                    licencaCidades[index] = {...item, cidade: e};
                    setLicencaCidades([...licencaCidades]);
                  }}
                  name="cidade"
                  label="Cidade/Estado"
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={7.5}>
                <Field
                  component={MultiSelectFormik}
                  placeholder=""
                  label="Cidades de atuação"
                  name="cidadesRegiao"
                  value={item.cidadesRegiao}
                  onChange={(e) => {
                    licencaCidades[index] = {...item, cidadesRegiao: e};
                    setLicencaCidades([...licencaCidades]);
                  }}
                  onInputChange={(e) => setSearchCidade(e)}
                  isMulti
                  closeMenuOnSelect={false}
                  options={cidades}
                  sx={styles.multiselect}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={0.5}>
                <RoundTooltipButton
                  id="icon-cancel"
                  type="button"
                  title={'Excluir'}
                  onClick={() => {
                    licencaCidades.splice(index, 1);
                    setLicencaCidades([...licencaCidades]);
                  }}
                  icon={<Delete />}
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
          </Formik>
        ))}
        <Grid container justifyContent={'center'} p={4}>
          <Button
            id="button"
            sx={{width: '200px', visibility: readOnly ? 'hidden' : 'visible'}}
            onClick={() => {
              setLicencaCidades([...licencaCidades, {}]);
            }}>
            Adicionar cidade
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{marginTop: 'auto', padding: '30px 0 0 16px'}}>
        <Grid item xs={3} style={{marginRight: 'auto'}}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row">
            {readOnly && (
              <ButtonComponent
                sx={styles.buttonWrapper}
                type="button"
                value={'Editar'}
                icon={<Edit />}
                onClick={() => setReadOnly(false)}
              />
            )}
            {!readOnly && (
              <ButtonComponent
                type="button"
                sx={styles.buttonWrapper}
                onClick={handleSubmitDados}
                value={'Salvar'}
                loading={loading}
                icon={<Save />}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ComercialScheme = Yup.object().shape({
  cidade: Yup.object().nullable().required('Campo obrigatório'),
  cidadesAtuacao: Yup.array().nullable().required('Campo obrigatório'),
});

const styles = {
  buttonWrapper: {
    width: '256px',

    '@media (max-width: 960px)': {
      width: '100%',
    },
  },
  icon: {
    fontSize: '18px',
    marginLeft: '10px',
  },
  multiselect: {
    '& .react-select__multi-value': {
      borderRadius: '6px',
      color: '#afb7c9',
    },
    '& .react-select__multi-value__label': {
      color: '#657496',
    },
  },
};

export default FormularioLicencaCidades;
