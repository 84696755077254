import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  campanhas: [],
  index: 0,
  filtrosCampanha: {
    filtros: {
      campanhaSituacao: {value: 'ATIVA', label: 'Ativa'},
    },
    page: 0,
    orderBy: {campo: 'dataInicioVeiculacao', asc: false},
  },
  hasMore: false,
  totalCampanhas: 0,
  refetchCampanhas: false,
};

const acaoAtualizarCampanhas = (state) => {
  state.refetchCampanhas = true;
};

const acaoPersistCampanhas = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.campanhas = content;
  state.hasMore = !last;
  state.totalCampanhas = totalElements || 0;
  state.refetchCampanhas = false;
  state.index = 0;
};

const acaoPersistFiltrosCampanha = (state, action) => {
  state.index = 0;
  state.filtrosCampanha = action.payload;
};
const acaoPersistIndexTabCampanha = (state, action) => {
  state.index = action.payload;
};

const resumeSlice = createSlice({
  name: 'campanha',
  initialState: initialState,
  reducers: {
    persistCampanhas: acaoPersistCampanhas,
    atualizarCampanhas: acaoAtualizarCampanhas,
    persistFiltrosCampanha: acaoPersistFiltrosCampanha,
    persistIndexTabCampanha: acaoPersistIndexTabCampanha
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistCampanhas, atualizarCampanhas, persistFiltrosCampanha, persistIndexTabCampanha} =
  actions;
