import React from 'react';
import {Box, Grid} from '@mui/material';
import config from '../../config/config';

const ImagemAnunciante = ({fotoPerfil, width = '60px', height = '60px', token}) => {
  const src = `${config.AMAZON_S3_BASE_URL}${fotoPerfil}?access_token=${token}`;

  return (
    <Grid container alignItems="center" justifyContent="center" flexDirection="row">
      {fotoPerfil && (
        <Grid container sx={{width, height}}>
          <Box component="img" src={src} alt="" sx={styles.avatar} />
        </Grid>
      )}
    </Grid>
  );
};

const styles = {
  avatar: {
    background: '#d2d7e2',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
    cursor: 'pointer',
  }
};

export default ImagemAnunciante;