import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_BY_ID_ANUNCIO_IMAGEM_VEICULO} from '../../graphql/queries';
import FormularioValidacaoImagens from './formulario';
import {LoadingPage} from '../../components/loading-page';

const AprovarImagens = () => {
  const {anuncioImagemVeiculoId} = useParams();
  const [imagem, setImagem] = useState(undefined);

  const imagemQuery = useQuery(FIND_BY_ID_ANUNCIO_IMAGEM_VEICULO, {
    variables: {
      id: anuncioImagemVeiculoId,
    },
  });

  useEffect(() => {
    if (imagemQuery.loading) return;

    setImagem(imagemQuery.data.findByIdAnuncioImagemVeiculo || {});
  }, [imagemQuery]);

  const refetch = () => {
    imagemQuery.refetch();
  };

  if (imagemQuery.loading || !imagem?.id || imagemQuery.error) {
    return (
      <LoadingPage loading={imagemQuery.loading || !imagem?.id} error={imagemQuery.error} />
    );
  };
  return (
    <FormularioValidacaoImagens anuncio={imagem?.anuncio || {}} imagensDoVeiculo={imagem} refetch={refetch} />
  );
};

export default AprovarImagens;
