import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  pedidos: [],
  filtrosPedido: {
    filtros: {},
    page: 0,
    orderBy: {campo: 'dataInicio', asc: true}
  },
  hasMore: false,
  totalPedidos: 0,
  refetchPedidos: false,
};

const acaoAtualizarPedidos = state => {
  state.refetchPedidos = true;
};

const acaoPersistPedidos = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.pedidos = content;
  state.hasMore = !last;
  state.totalPedidos = totalElements || 0;
  state.refetchPedidos = false;
};

const acaoPersistFiltrosPedido = (state, action) => {
  state.filtrosPedido = action.payload;
};

const resumeSlice = createSlice({
  name: 'pedido',
  initialState: initialState,
  reducers: {
    persistPedidos: acaoPersistPedidos,
    atualizarPedidos: acaoAtualizarPedidos,
    persistFiltrosPedido: acaoPersistFiltrosPedido
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistPedidos, atualizarPedidos, persistFiltrosPedido} = actions;
