import React, {useEffect, useState} from 'react';
import {Button, Grid, Modal, Typography, Box} from '@mui/material';
import {useMutation} from '@apollo/client';
import moment from 'moment';
import {TextAreaV2} from '../../../components/textarea/mui-textarea';
import {UPDATE_ANUNCIO_OBSERVACAO_MONITORAMENTO} from '../../../graphql/mutation';
import {toastNotification} from '../../../components/toastify';

const ObservacaoAnuncioModal = ({openModal, anuncio, onClose, refetch}) => {
  const [salvando, setSalvando] = useState(false);
  const [observacao, setObservacao] = useState('');

  const [updateObservacao] = useMutation(
    UPDATE_ANUNCIO_OBSERVACAO_MONITORAMENTO,
  );

  useEffect(() => {
    if (!anuncio?.id) return;

    const administrador = JSON.parse(window.localStorage.getItem('usuario'));

    setObservacao(
      (anuncio.observacaoMonitoramento
        ? `${anuncio.observacaoMonitoramento}\n\n`
        : '') +
        `[${administrador?.nome}: ${moment().format('DD/MM/YY HH[h]mm')}]\n`,
    );
  }, [anuncio]);

  const salvarObservacao = () => {
    setSalvando(true);
    updateObservacao({
      variables: {
        anuncio: {
          id: anuncio.id,
        },
        observacao,
      },
    })
      .then(() => {
        onClose();
        refetch && refetch();
        toastNotification({message: 'A observação foi salva', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSalvando(false));
  };

  return (
    <Modal
      open={openModal}
      onClose={() => onClose()}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}>
      <Box sx={styles.modalBody}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={styles.modalTitle}>
              Motorista: {anuncio?.motorista?.nome}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextAreaV2
              label="Observação"
              value={observacao}
              rows={10}
              cols={100}
              onChange={(e) => {
                setObservacao(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sx={styles.modalButtonContainer}>
            <Button
              variant="contained"
              color="secondary"
              id="button-cancel"
              onClick={onClose}
              disabled={salvando}
              sx={{width: '185px', margin: '0px 15px'}}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              id="button"
              onClick={salvarObservacao}
              disabled={salvando}
              sx={{width: '185px'}}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalBody: {
    padding: '20px',
    background: '#fff',
    borderRadius: '5px',
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
  },
  modalTitle: {
    fontWeight: '700',
    fontSize: 13,
    color: '#afafaf',
  },
};

export default ObservacaoAnuncioModal;
