import React, {useEffect, useRef, useState} from 'react';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import {useQuery} from '@apollo/client';
import TableCellOrdered from '../../../components/table-cell-ordered';
import {FIND_ALL_COMERCIAL_RELATORIO} from '../../../graphql/queries';
import Objects from '../../../utils/objects';
import String from '../../../utils/string';
import ImpressaoCarteiraClientes from '../../../components/impressao/impressao-carteira-clientes';
import ReactToPrint from 'react-to-print';
import Data from '../../../utils/data';
import {RoundTooltipButton} from '../../../components/mui-button';
import {PrintOutlined} from '@mui/icons-material';

const pageStyle = `
@page { 
  size: landscape;
  margin: 5mm 10mm 5mm 10mm;
}

@media all {
  .page-footer {
    display: block !important;
  }
}
`;

const columns = [
  {id: 'nomeFantasia', label: 'Nome fantasia'},
  {id: 'nomeContato', label: 'Contato'},
  {
    id: 'telefone',
    label: 'Telefone',
    format: ({telefone}) => String.formatTelefone(telefone),
  },
  {id: 'email', label: 'Email'},
  {
    id: 'endereco',
    label: 'Cidade',
    format: ({endereco}) =>
      endereco?.cidade ? `${endereco.cidade.nome}/${endereco.cidade.uf}` : '',
  },
];

const anuncianteColumns = [
  {id: 'nomeFantasia', label: 'Nome fantasia'},
  {id: 'marketingNome', label: 'Contato'},
  {
    id: 'marketingTelefone',
    label: 'Telefone',
    format: ({marketingTelefone}) => String.formatTelefone(marketingTelefone),
  },
  {id: 'marketingEmail', label: 'Email'},
  {
    id: 'endereco',
    label: 'Cidade',
    format: ({endereco}) => endereco?.cidade?.nomeEUf || '',
  },
];

const ClientesPorAnunciante = ({searchDTO = {}}) => {
  const componentRef = useRef();

  const [loading, setLoading] = useState(false);
  const [comerciais, setComerciais] = useState(undefined);
  const [orderBy, setOrderBy] = useState({campo: 'nomeFantasia', asc: true});

  const comercialQuery = useQuery(FIND_ALL_COMERCIAL_RELATORIO, {
    variables: {
      searchDTO: {
        comercialId: searchDTO.comercial?.id || undefined,
        anuncianteId: searchDTO.anunciante?.id || undefined,
        estadoId: searchDTO.estado?.id || undefined,
        cidadeId: searchDTO.cidade?.id || undefined,
        segmentoAtuacao: Objects.jsToEntity(searchDTO.segmentoAtuacao),
      },
      pageable: {
        pageNumber: 0,
        pageSize: -1,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
    },
  });

  useEffect(() => {
    if (comercialQuery.loading || comercialQuery.error) return;

    setComerciais(
      comercialQuery?.data?.comercial?.content?.filter((c) => c.anunciantes?.length > 0)
        .map((c) => ({
          ...c,
          anunciantes: c.anunciantes.filter(
            (a) =>
              !searchDTO.segmentoAtuacao ||
              a.segmentoAtuacao?.id === searchDTO.segmentoAtuacao.value,
          ),
          agencias: c.agencias.map((a) => ({
            ...a,
            anunciantes: a.anunciantes.filter(
              (a1) =>
                !searchDTO.segmentoAtuacao ||
                a1.segmentoAtuacao?.id === searchDTO.segmentoAtuacao.value,
            ),
          })),
        })),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comercialQuery]);

  return (
    <TableContainer component={Paper}>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Carteira de clientes ${Data.dataFormat(searchDTO.mesInicio)}`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() =>
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintOutlined />}
            loading={loading}
            loadingColor="grey"
          />
        }
        content={() => componentRef.current}
      />
      <Table stickyHeader aria-label="sticky table" style={{maxWidth: '100%'}}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCellOrdered
                key={column.id}
                column={column}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {comerciais?.map((row) => {
            return (
              <React.Fragment key={row.id}>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  sx={styles.comercialRow}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{fontWeight: '700'}}
                      >
                        {column.format ? column.format(row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow sx={styles.anuncianteRow}>
                  <TableCell sx={styles.subTable} colSpan={6} padding="checkbox" style={{padding: 0}}>
                    <Table size="small" aria-label="purchases">
                      <TableBody>
                        {row.anunciantes?.map((anunciante) => (
                          <TableRow key={anunciante.id} sx={styles.anuncianteRow}>
                            {anuncianteColumns.map((column) => {
                              const value = anunciante[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{width: '20%', paddingLeft: '40px'}}>
                                  {column.format ? column.format(anunciante) : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={'total'}
            style={{cursor: 'pointer', backgroundColor: '#fff'}}
          >
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
      <Grid overflow="hidden" height="0px">
        <Grid ref={componentRef} background="#fff">
          <ImpressaoCarteiraClientes
            exibirPor={'ANUNCIANTE'}
            comerciais={comerciais}
          />
        </Grid>
      </Grid>
    </TableContainer>
  )
};

const styles = {
  subTable: {
    padding: '0 0 15px 0px',
  },
  subTableCell: {
    fontWeight: '700 !important',
  },
  subTableCellInit: {
    paddingLeft: '29px !important',
    whiteSpace: 'nowrap',
    '&:last-child': {
      whiteSpace: 'normal',
    },
  },
  subTableCellsecunday: {
    paddingLeft: '40px !important',
    whiteSpace: 'nowrap',
    '&:last-child': {
      whiteSpace: 'normal',
    },
  },
  comercialRow: {
    backgroundColor: '#ddd !important',
  },
  agenciaRow: {
    borderBottom: '2px solid #0000',
    backgroundColor: '#f5f5f5 !important',
  },
  anuncianteRow: {
    backgroundColor: '#fff !important',
  },
};

export default ClientesPorAnunciante;