import React, {useEffect, useState} from 'react'
import Cropper from 'react-easy-crop'
import {Modal, Typography, Button, Slider} from '@mui/material'
import {makeStyles, withStyles} from '@mui/styles'
import {Grid} from '@mui/material'
import {Autorenew, Close, Save} from '@mui/icons-material'
import getCroppedImg from './crop-image'
import {styles} from './styles'
import File from '../../utils/file'

const ImagemCrop = ({imagemId, open, onClose, onUploadImage, disabled}) => {
  const classes = useStyles();

  const [image, setImage] = useState(undefined);
  const [aspect, setAspect] = useState(4 / 3);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [imageSize, setImageSize] = useState(undefined);

  useEffect(() => {
    if (!imagemId) {
      setZoom(1);
      setRotation(0);
      return;
    }

    setImage(File.getImageAuth(imagemId));
  }, [imagemId])

  const showCroppedImage = async () => {
    try {
      const cropped = [90, 270].includes(rotation) ?
        {width: imageSize.naturalHeight, height: imageSize.naturalWidth, x: 0, y: 0} :
        {width: imageSize.naturalWidth, height: imageSize.naturalHeight, x: 0, y: 0}

      const croppedImage = await getCroppedImg(
        image,
        cropped,
        rotation
      );

      croppedImage.substring(croppedImage.indexOf(',') + 1);
      const type = croppedImage.substring(5, croppedImage.indexOf(';'));
      const file = File.b64toBlob(croppedImage.substring(croppedImage.indexOf(',') + 1), type);

      onUploadImage(file);
    } catch (e) {
      console.error(e);
    }
  }

  function handleChangeUploadImage(e) {
    const file = Array.from(e.target.files)[0];

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
    >
      <Grid container className={classes.modalBody}>
        <Close onClick={onClose} className={classes.closeIcon} />
        <Grid item className={`${classes.cropContainer} ${disabled ? classes.cropContainerDisabled : ''}`}>
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onZoomChange={setZoom}
            onMediaLoaded={(size) => {
              setAspect(size.naturalWidth / size.naturalHeight);
              setImageSize(size);
            }}
          />
        </Grid>
        <Grid item className={classes.controls} style={disabled ? {display: 'none'} : null}>
          <Grid item className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{root: classes.sliderLabel}}
            >
              Girar
            </Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={90}
              aria-labelledby="Rotation"
              classes={{root: classes.slider}}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </Grid>
          <Grid container item direction="row" justifyContent="end">
            <label htmlFor="input-file">
              <Button
                variant="contained"
                color="primary"
                classes={{root: classes.cropButton}}
                endIcon={<Autorenew />}
                component="span"
              >
                Trocar imagem
              </Button>
            </label>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              endIcon={<Save />}
              classes={{root: classes.cropButton}}
              style={{background: 'green'}}
            >
              Salvar
            </Button>
            <input
              id="input-file"
              type="file"
              accept="image/*"
              style={{width: '10px', opacity: 0, position: 'absolute'}}
              onChange={handleChangeUploadImage}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalBody: {
    position: 'relative',
    padding: '0px',
    background: '#fff',
    borderRadius: '6px',
    height: '80%',
    maxWidth: '60%',
    display: 'flex',
    flexDirection: 'column !important'
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 100px)',
    background: '#333',
    borderRadius: 6,

    '& .reactEasyCrop_CropAreaGrid': {
      visibility: 'hidden !important'
    }
  },
  cropContainerDisabled: {
    height: '100% !important',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    height: 100,
    width: '100%',
    padding: '20px 25px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column !important',
    width: '100%',

    '& .MuiSlider-root': {
      margin: '0 5px',
      maxWidth: 500,
    }
  },
  cropButton: {
    height: 40,
    textTransform: 'none !important',
    marginLeft: 10,
    width: 160
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    cursor: 'pointer',
    background: '#e0e0e0',
    borderRadius: '50%',
    padding: 3,
    height: 30, 
    width: 30,
    zIndex: 10
  }
}));

export default withStyles(styles)(ImagemCrop)