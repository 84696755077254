import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modeloVeiculo: [],
  filtrosModeloVeiculo: {
    filtros: {
    },
    page: 0,
    orderBy: {campo: 'nome', asc: true}
  },
  totalModeloVeiculo: 0,
  refetchModeloVeiculo: false
  
  
};

const acaoAtualizarModeloVeiculo = state => {
  state.refetchModeloVeiculo = true;
};

const acaoPersistModeloVeiculo = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.modeloVeiculo = content || [];
  state.hasMore = !last;
  state.totalModeloVeiculo = totalElements || 0;
  state.refetchModeloVeiculo = false;
};
const acaoPersistFiltrosModeloVeiculo = (state, action) => {
  state.filtrosModeloVeiculo = action.payload;
};

const resumeSlice = createSlice({
  name: 'modeloVeiculo',
  initialState: initialState,
  reducers: {
    persistModeloVeiculo: acaoPersistModeloVeiculo,
    atualizarModeloVeiculo: acaoAtualizarModeloVeiculo,
    persistFiltrosModeloVeiculo: acaoPersistFiltrosModeloVeiculo
  }
});


export const { actions, reducer } = resumeSlice;
export const { persistModeloVeiculo, atualizarModeloVeiculo, persistFiltrosModeloVeiculo } = actions;
