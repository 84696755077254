import React, {useEffect, useRef, useState} from 'react';
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableContainer,
  Box,
} from '@mui/material';
import {PrintOutlined} from '@mui/icons-material';
import {useQuery} from '@apollo/client';
import ReactToPrint from 'react-to-print';
import {Field, Formik} from 'formik';
import {ButtonComponent, RoundTooltipButton} from '../../../components/mui-button';
import {CONSULTA_KM_RODADO_CIDADE} from '../../../graphql/queries';
import Number from '../../../utils/number';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import {SelectEstados} from '../../../containers/selects/mui-estados';
import ImpressaoRelatorioCampanhasCidade from '../../../components/impressao/impressao-relatorio-campanhas-cidade';
import Data from '../../../utils/data';

const pageStyle = `
@page { 
  size: A4;
  margin: 5mm 10mm 5mm 10mm;
}

@media all {
  .page-footer {
    display: block !important;
  }
}
`;

const columns = [
  {id: 'cidadeNome', label: 'Cidade'},
  {id: 'campanhas', label: 'Campanhas'},
  {id: 'veiculos', label: 'Veículos'},
  {
    id: 'kmTotal',
    label: 'Km total',
    format: ({kmTotal}) => `${Number.formatQuilometro(kmTotal)} Km`,
  },
  {
    id: 'kmVeiculo',
    label: 'Km / período / veículo',
    format: ({kmTotal, veiculos}) =>
      `${Number.formatQuilometro(kmTotal / veiculos)} Km`,
  },
];

const ConsultaQuilometragemCidade = () => {
  const [loading, setLoading] = useState(false);
  const [filtros, setFiltros] = useState({});
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [cidades, setCidades] = useState([]);
  const [totais, setTotais] = useState({});

  const componentRef = useRef();

  const cidadesQuery = useQuery(CONSULTA_KM_RODADO_CIDADE, {
    variables: {
      estadoId: searchDTO.estado?.id || undefined,
      cidadeId: searchDTO.cidade?.id || undefined,
    },
  });

  const refetch = () => {
    setSearchDTO(filtros);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    if (cidadesQuery.loading) return;

    setCidades(cidadesQuery.data.cidades || []);
  }, [cidadesQuery]);

  useEffect(() => {
    let campanhas = 0;
    let veiculos = 0;
    let kmTotal = 0;
    let mediaNacional = 0;

    cidades.forEach((c) => {
      campanhas += c.campanhas;
      veiculos += c.veiculos;
      kmTotal += c.kmTotal;
      mediaNacional += c.kmTotal / c.veiculos;
    });

    setTotais({
      campanhas,
      veiculos,
      kmTotal,
      mediaNacional: mediaNacional / (cidades?.length || 1),
    });
  }, [cidades]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Campanhas por cidade ${Data.dataFormat(filtros.mesInicio)}`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() =>
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintOutlined />}
            loading={loading}
            loadingColor="grey"
          />
        }
        content={() => componentRef.current}
      />
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, estado: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, cidade: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  value="Filtrar"
                  loading={cidadesQuery.loading}
                  onClick={refetch}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cidades?.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.cidadeId}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={'total'}
                  style={{cursor: 'pointer'}}>
                  <TableCell id="footer">
                    {`Total: ${cidades.length} cidades`}
                  </TableCell>
                  <TableCell id="footer">
                    {`${totais?.campanhas || 0} campanhas`}
                  </TableCell>
                  <TableCell id="footer">
                    {`${totais?.veiculos || 0} veículos`}
                  </TableCell>
                  <TableCell id="footer" style={{whiteSpace: 'nowrap'}}>
                    {`${Number.formatQuilometro(totais?.kmTotal)} Km`}
                  </TableCell>
                  <TableCell id="footer" style={{whiteSpace: 'nowrap'}}>
                    {`${Number.formatQuilometro(totais?.mediaNacional)} Km`}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </TableContainer>
      </Grid>
      <Grid overflow="hidden" height="0px">
        <Grid ref={componentRef} background="#fff">
          <ImpressaoRelatorioCampanhasCidade
            cidades={cidades}
            totais={totais}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaQuilometragemCidade;
