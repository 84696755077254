import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {Paper, Grid, Box} from '@mui/material';
import {Save} from '@mui/icons-material';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../components/mui-button';
import {FIND_CONFIGURACOES} from '../../graphql/queries';
import {ATUALIZAR_CONFIGURACOES} from '../../graphql/mutation';

const ConfiguracoesForm = () => {
  const configuracoes = useQuery(FIND_CONFIGURACOES, {
    variables: {
      keys: ['impostos'],
    },
  });
  const [atualizaConfiguracoes] = useMutation(ATUALIZAR_CONFIGURACOES, {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    impostos: 0,
    test: 'bla',
  });
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    atualizaConfiguracoes({
      variables: {
        configuracoes: [
          {
            key: 'impostos',
            value: formData.impostos.toString(),
          },
        ],
      },
    }).then(() => {
      setIsSubmitting(false);
      navigate('/');
    });
  };

  const setFormDataKey = (key, value) => {
    let formDataBuffer = {
      ...formData,
    };

    formDataBuffer[key] = value;

    setFormData(formDataBuffer);
  };

  useEffect(() => {
    if (!configuracoes.data) {
      return;
    }

    for (const config of configuracoes.data.configuracoes) {
      setFormDataKey(config.key, config.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuracoes.data]);

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container item xs={12} spacing={2}>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <InputV2
                  name="impostos"
                  label="Impostos"
                  value={formData.impostos + '%'}
                  onChange={({target}) =>
                    setFormDataKey('impostos', Math.min(100, parseInt(target.value.replace(/\D+/, '') || '0')))
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" style={{marginTop: 16}}>
            <Grid item xs={2}>
              <ButtonComponent
                type="submit"
                value='Salvar'
                loading={isSubmitting}
                icon={<Save />}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default ConfiguracoesForm;
