import React, {useState} from 'react';
import {styled} from '@mui/styles';
import {useNavigate} from 'react-router-dom';
import {Paper, Grid} from '@mui/material';
import {Field, Formik} from 'formik';
import * as Yup from 'yup';
import {useMutation} from '@apollo/client';
import {ButtonComponent} from '../../components/mui-button';
import {ATUALIZAR_ESTADO} from '../../graphql/mutation';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import Number from '../../utils/number';

const FormularioEstado = ({estado = {}}) => {
  const [atualizarEstado] = useMutation(ATUALIZAR_ESTADO);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  async function handleSubmitDados(values) {
    try {
      setSubmitting(true);
      await atualizarEstado({
        variables: {
          estado: {
            id: estado.id,
            nome: values.nome,
            uf: values.uf,
            kmPadrao: Number.stringToFloat(values.kmPadrao),
            raioPadrao: Number.stringToFloat(values.raioPadrao),
          },
        },
      });

      navigate('/estados');
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          validationSchema={EstadoScheme}
          initialValues={{
            nome: estado.nome || '',
            uf: estado.uf || '',
            kmPadrao: Number.format(estado.kmPadrao || 0),
            raioPadrao: Number.format(estado.raioPadrao || 0),
          }}
          enableReinitialize
          onSubmit={handleSubmitDados}>
          {({isSubmitting, handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field component={InputV2Formik} name="nome" label="Nome" />
                </Grid>
                <Grid item xs={2}>
                  <Field component={InputV2Formik} name="uf" label="UF" />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="kmPadrao"
                    label="Km da Região"
                    mask="decimal"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="raioPadrao"
                    label="Raio da Região"
                    mask="decimal"
                  />
                </Grid>

                <Grid item xs={1}>
                  <ButtonComponent
                    sx={{width: '256px'}}
                    type="submit"
                    value={'Salvar'}
                    loading={submitting}
                    disabled={submitting}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

const EstadoScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  uf: Yup.string().required('Campo obrigatório'),
  kmPadrao: Yup.string().required('Campo obrigatório'),
});

const Form = styled('form')(() => { });

export default FormularioEstado;
