import {useQuery} from '@apollo/client';
import {Grid, Paper, Tab, Tabs} from '@mui/material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {LoadingPage} from '../../components/loading-page';
import TabPanel from '../../components/tab-painel';
import {FIND_BY_ID_COMERCIAL} from '../../graphql/queries';
import FormularioAnaliseCarteira from './formularios/analise-carteira';
import Arquivos from './formularios/arquivos';
import FormularioLicencaCidades from './formularios/cidades';
import FormularioCadastroComercial from './formularios/dados-cadastrais';
import FormularioMetasVendas from './formularios/metas-venda';

const EditarLicencas = () => {
  const {indexTab} = useSelector((store) => store.Licencas);
  const {licencaId} = useParams();

  const [tabIndex, setTabIndex] = useState(indexTab);

  const comercialQuery = useQuery(FIND_BY_ID_COMERCIAL, {
    variables: {
      id: licencaId,
    },
  });

  const refetch = () => {
    comercialQuery.refetch();
  };

  const {data = {}} = comercialQuery;
  const {findByIdComercial: comercial = {}} = data;
  const a11yProps = (index) => {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  };

  if (comercialQuery.loading || comercialQuery.error) {
    return (
      <LoadingPage
        loading={comercialQuery.loading}
        error={comercialQuery.error}
      />
    );
  }
  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Tabs
          value={tabIndex}
          onChange={(event, index) => setTabIndex(index)}
          variant="standard"
          aria-label="nav tabs example"
          className="tabs">
          <Tab
            iconPosition="start"
            className="tab-button"
            label={<p classes="tabLabel">Dados Cadastrais</p>}
            {...a11yProps(0)}
          />
          <Tab
            iconPosition="start"
            className="tab-button"
            label={<p classes="tabLabel">Cidades</p>}
            {...a11yProps(1)}
          />
          <Tab
            iconPosition="start"
            className="tab-button"
            label={<p classes="tabLabel">Metas de Venda</p>}
            {...a11yProps(2)}
          />
          <Tab
            iconPosition="start"
            className="tab-button"
            label={<p classes="tabLabel">Análise de Carteira</p>}
            {...a11yProps(3)}
          />
          <Tab
            iconPosition="start"
            className="tab-button"
            label={<p classes="tabLabel">Arquivos</p>}
            {...a11yProps(4)}
          />
        </Tabs>
        <TabPanel index={0} value={tabIndex} sx={styles.tabPainel}>
          <FormularioCadastroComercial comercial={comercial} />
        </TabPanel>
        <TabPanel index={1} value={tabIndex} sx={styles.tabPainel}>
          <FormularioLicencaCidades comercial={comercial} refetch={comercialQuery.refetch} />
        </TabPanel>
        <TabPanel index={2} value={tabIndex} sx={styles.tabPainel}>
          <FormularioMetasVendas comercial={comercial} />
        </TabPanel>
        <TabPanel
          index={3}
          value={tabIndex}
          sx={styles.tabPainel}
          boxPadding="0px">
          <FormularioAnaliseCarteira comercial={comercial} />
        </TabPanel>
        <TabPanel
          index={4}
          value={tabIndex}
          sx={styles.tabPainel}
          boxPadding="16px 2px">
          <Arquivos comercial={comercial} refetch={refetch} />
        </TabPanel>
      </Paper>
    </Grid>
  );
};

const styles = {
  tabPainel: {
    minHeight: 'calc(100vh - 190px)',
    margin: '10px 0',
    overflowY: 'auto',
  },
};

export default EditarLicencas;
