import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Formik} from 'formik';
import {useMutation} from '@apollo/client';
import {Grid, Paper} from '@mui/material';
import {Edit, Save, Delete} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import InputV2 from '../../components/input-v2/mui-input-v2';
import InputDate from '../../components/mui-input-date';
import {ATUALIZAR_ARQUIVO, DELETAR_ARQUIVO} from '../../graphql/mutation';
import {ButtonComponent} from '../../components/mui-button';
import {uploadAmazonS3Arquivo} from '../../services/UploadService';
import DragNDrop from '../../components/drag-n-drop';
import {toastNotification} from '../../components/toastify';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';

moment.locale('pt-br');

const FormmularioMateriaisVenda = ({arquivo = {}, refetch}) => {
  const [formData, setFormData] = useState({});

  const [submitting, setSubmitting] = useState(false);

  const [file, setFile] = useState(null);

  const [leitura, setLeitura] = useState(true);

  const [atualizarArquivo] = useMutation(ATUALIZAR_ARQUIVO);
  const [deleteArquivo] = useMutation(DELETAR_ARQUIVO);

  const navigate = useNavigate();

  async function handleSubmitDadosArquivos() {
    setSubmitting(true);

    if (!file && !formData.arquivo?.id && !formData.urlArquivo) {
      toastNotification({
        titulo: 'Você precisa adicionar um arquivo ou uma url de arquivo',
        type: 'warning',
      });
      setSubmitting(false);
      return;
    }
    let arquivoResponse = undefined;
    if (file) {
      try {
        arquivoResponse = await uploadAmazonS3Arquivo(file);
      } catch (error) {
        toastNotification({
          titulo: 'Não foi possível enviar os arquivos!',
          message: 'Tente novamente mais tarde.',
          type: 'error',
        });
      }
    }
    atualizarArquivo({
      variables: {
        arquivo: {
          id: arquivo.id,
          titulo: formData.titulo,
          urlArquivo: formData.urlArquivo,
          arquivo:
            arquivoResponse?.id || formData.arquivo?.id
              ? {
                id: arquivoResponse?.id || formData.arquivo?.id,
                nome: arquivoResponse?.name || formData.arquivo?.nome,
              }
              : undefined,
          descricao: formData.descricao,
        },
      },
    })
      .then(({data}) => {
        setLeitura(true);

        toastNotification({
          message: 'Os dados cadastrados foram salvos!',
          type: 'success',
        });
        refetch && refetch();
        navigate(`/materiais-venda/${data?.updateArquivo?.id}/editar`);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSubmitting(false));
  }

  const handleDeleteArquivo = () => {
    setSubmitting(true);
    deleteArquivo({
      variables: {
        arquivo: {
          id: arquivo.id,
        },
      },
    })
      .then(() => {
        setLeitura(true);
        navigate('/midia-kit');
        toastNotification({
          message: 'Os dados foram deletados com sucesso!',
          type: 'success',
        });
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    setFormData(() => arquivo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arquivo.id]);

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik initialValues={formData} enableReinitialize>
          <Grid container spacing={2} padding="0px !important">
            <Grid item md={arquivo.id ? 5 : 6} xs={12}>
              <InputV2
                name="titulo"
                label="Título"
                value={formData.titulo}
                onChange={({target}) =>
                  setFormData({...formData, titulo: target.value})
                }
                disabled={arquivo.id && leitura}
              />
            </Grid>
            <Grid item md={arquivo.id ? 5 : 6} xs={12}>
              <InputV2
                name="descricao"
                label="Descrição"
                value={formData.descricao}
                onChange={({target}) =>
                  setFormData({...formData, descricao: target.value})
                }
                disabled={arquivo.id && leitura}
              />
            </Grid>
            {arquivo.id && (
              <Grid item md={2} xs={12}>
                <InputDate
                  label="Data"
                  mame="dataAlteracao"
                  dateFormat={'dd/MM/yyyy'}
                  selected={moment(formData.dataAlteracao).toDate()}
                  type="date"
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <InputV2
                name="urlArquivo"
                label="URL do arquivo"
                value={formData.urlArquivo}
                onChange={({target}) =>
                  setFormData({...formData, urlArquivo: target.value})
                }
                disabled={arquivo.id && leitura}
              />
            </Grid>
            <Grid item xs={12}>
              <DragNDrop
                id={arquivo.arquivo?.id}
                file={file}
                onChange={setFile}
                onDelete={() => {
                  setFile(undefined);
                  setFormData((f) => ({...f, arquivo: undefined}));
                }}
                fileName={file?.name || formData.arquivo?.nome}
                disabled={arquivo.id && leitura}
                loadig={submitting}
              />
            </Grid>
          </Grid>
        </Formik>

        <Grid container rowSpacing={2} columnSpacing={0} mt="16px">
          <Grid item xs={12} sx={styles.sizeContainerButton}>
            {leitura && arquivo.id ? (
              <ButtonComponent
                sx={{width: '256px'}}
                id="button"
                endIcon={<Edit />}
                onClick={() => setLeitura(false)}
                disabled={submitting}
                value={'Editar'}
              />
            ) : (
              <ButtonComponent
                type="submit"
                id="button"
                sx={{width: '256px'}}
                loading={submitting}
                icon={<Save />}
                value={'Salvar'}
                onClick={handleSubmitDadosArquivos}
                disabled={submitting}
              />
            )}
            {arquivo.id && (
              <ButtonComponent
                id="button-cancel"
                sx={{width: '256px'}}
                loading={submitting}
                icon={<Delete />}
                onClick={() => confirmAlertMui({
                  title: 'Deseja mesmo excluir o arquivo?',
                  onConfirm: handleDeleteArquivo
                })}
                value={' Excluir Arquivo'}
                disabled={submitting}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const styles = {
  sizeContainerButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sizeContainerButtonFinal: {
    '@media (min-width: 600px)': {
      justifyContent: 'center !important',
      marginRight: '0px !important',
    },

    '@media (min-width: 1280px)': {
      justifyContent: 'flex-end !important',
      marginRight: '0px !important',
    },
  },
};

export default FormmularioMateriaisVenda;
