import React from 'react';
import { FIND_ALL_ANUNCIANTE as query } from '../../graphql/queries';
import { SelectBase } from './mui-base';

const getVariables = ({pageNumber, search = '', searchDTO = {}, pageSize = 20}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nomeFantasia', sortDir: 'ASC'},
    searchDTO: {
      ...searchDTO,
      search,
    }
  };
};

export const SelectAnunciantes = (props) => {
  return (
    <SelectBase
      label="Anunciante"
      responseObjectName="findAllAnunciante"
      labelProp="nomeFantasia"
      query={query}
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      {...props}
    />
  );
};
