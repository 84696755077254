import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Field} from 'formik';
import {useQuery, useMutation} from '@apollo/client';
import {Paper, Button, Grid, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination} from '@mui/material';
import {Visibility, Add, Delete} from '@mui/icons-material';
import {toastNotification} from '../../components/toastify';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';
import {
  persistMateriaisVenda,
  persistFiltrosMateriaisVenda,
  atualizarMateriaisVenda,
} from '../../redux/MateriaisVenda/slice';
import {FIND_ALL_ARQUIVOS} from '../../graphql/queries';
import {RoundTooltipButton, ButtonComponent} from '../../components/mui-button';
import {DELETAR_ARQUIVO} from '../../graphql/mutation';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import TableCellOrdered from '../../components/table-cell-ordered';

const columns = [
  {id: 'titulo', label: 'Título'},
  {
    id: 'descricao',
    label: 'Descrição',
  },
  {
    id: 'dataAlteracao',
    label: 'Data',
    format: (arquivo) =>
      arquivo?.dataAlteracao
        ? moment(arquivo?.dataAlteracao).format('DD/MM/YYYY')
        : '',
  },
];

const ConsultaMateriaisVenda = ({pageSize = 20}) => {
  const {materiaisVenda, totalMateriaisVenda, filtrosMateriaisVenda, refetchMateriaisVenda} =
    useSelector((store) => store.MateriaisVenda);

  const [page, setPage] = useState(filtrosMateriaisVenda.page);
  const [filtros, setFiltros] = useState(filtrosMateriaisVenda.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosMateriaisVenda.orderBy);
  const [submitting, setSubmitting] = useState(false);

  const [rowsPerPage] = useState(pageSize);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteArquivo] = useMutation(DELETAR_ARQUIVO);

  const arquivosQuery = useQuery(FIND_ALL_ARQUIVOS, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search || '',
      },
    },
  });

  useEffect(() => {
    if (!arquivosQuery.loading && !arquivosQuery.error && arquivosQuery.data) {
      dispatch(persistMateriaisVenda(arquivosQuery.data.findAllArquivos));
      dispatch(persistFiltrosMateriaisVenda({filtros, page, orderBy}));
    }
    if (refetchMateriaisVenda) {
      arquivosQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arquivosQuery, dispatch, refetchMateriaisVenda]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const handleDeleteArquivo = (dados) => {
    setSubmitting(true);
    deleteArquivo({
      variables: {
        arquivo: {
          id: dados.id,
        },
      },
    })
      .then(() => {
        dispatch(atualizarMateriaisVenda());
        toastNotification({
          message: 'Os dados foram deletados com sucesso!',
          type: 'success',
        });
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSubmitting(false));
  };

  return (
    <Grid id="page-container" container>
      <Button
        startIcon={<Add />}
        id="button-green"
        toolbar="true"
        onClick={() => navigate(`criar`)}
      >
        Cadastrar novo
      </Button>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Field
                  component={InputV2Formik}
                  label="Buscar"
                  name="search"
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value || ''})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  value="Filtrar"
                  fullWidth
                  onClick={() => refetch()}
                  loading={arquivosQuery.loading}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead tabIndex={-1}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell
                      id="acoes"
                      key="acoes"
                      align="center"
                      sx={styles.tableAcao}
                    >
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materiaisVenda.map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={index} align={column.align}>
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center" sx={styles.tableAcao}>
                          <RoundTooltipButton
                            title={'Visualizar'}
                            onClick={() => navigate(`${row.id}/editar`)}
                            icon={<Visibility />}
                            id="icon-primary"
                          />
                          <RoundTooltipButton
                            id="icon-cancel"
                            title={'Excluir'}
                            onClick={() =>
                              confirmAlertMui({
                                title: 'Deseja mesmo excluir o arquivo?',
                                onConfirm: () => handleDeleteArquivo(row)
                              })
                            }
                            icon={<Delete />}
                            loading={submitting}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalMateriaisVenda}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};
const styles = {
  tableAcao: {
    width: '150px',
  },
};
export default ConsultaMateriaisVenda;
