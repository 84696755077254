import React, {useState} from 'react';
import {Formik, Field} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Grid, Paper, Typography, Box} from '@mui/material';
import {useMutation} from '@apollo/client';
import {Edit, Save, Delete} from '@mui/icons-material';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {ButtonComponent} from '../../components/mui-button';
import {ATUALIZAR_OFICINA, DELETAR_OFICINA} from '../../graphql/mutation';
import {toastNotification} from '../../components/toastify';
import String from '../../utils/string';

const FormularioAgencia = ({oficina = {}, refetch}) => {
  const navigate = useNavigate();
  const {role} = useSelector((store) => store.Auth);

  const [readOnly, setReadOnly] = useState(!!oficina?.id);
  const [excluindo, setExcluindo] = useState(false);

  const [updateOficina] = useMutation(ATUALIZAR_OFICINA);
  const [deleteOficina] = useMutation(DELETAR_OFICINA);

  async function handleSubmitDadosOficina({cidade, ...values}) {
    delete values.cidadeNome;

    updateOficina({
      variables: {
        oficina: {
          ...values,
          id: oficina.id,
          cpfCnpj: String.removeSpecialChars(values.cpfCnpj),
          telefone: String.removeSpecialChars(values.telefone),
          cep: String.removeSpecialChars(values.cep),
          cidade: cidade?.value ? {id: cidade?.value} : null,
          contatoNome: values.contatoNome,
          contatoTelefone: String.removeSpecialChars(values.contatoTelefone),
          contatoEmail: values.contatoEmail,
        },
      },
    })
      .then(({data}) => {
        setReadOnly(true);
        toastNotification({
          message: 'Os dados cadastrados foram salvos',
          type: 'success',
        });
        if (!oficina?.id) navigate(`/oficinas/${data?.oficina?.id}/editar`);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  }

  async function handleDeleteDadosOficina() {
    setExcluindo(true);
    deleteOficina({
      variables: {
        oficina: {
          id: oficina.id,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'A oficina foi excluída!',
          type: 'success',
        });
        refetch && refetch();
        navigate(`/oficinas`);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(setExcluindo(false));
  }

  const showExcluir = () => {
    return role.includes('GERENTE_OPERACIONAL') || role.includes('ADMINISTRADOR');
  }

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          validationSchema={oficinaScheme}
          initialValues={{
            nome: oficina.nome || '',
            cpfCnpj: oficina.cpfCnpj || '',
            cep: oficina.cep || '',
            cidade: oficina.cidade || null,
            cidadeNome: oficina.cidade?.nomeEUf || '',
            logradouro: oficina?.logradouro || '',
            bairro: oficina?.bairro || '',
            numero: oficina?.numero || '',
            complemento: oficina?.complemento || '',
            telefone: oficina.telefone || '',
            email: oficina?.email || '',
            contatoNome: oficina?.contatoNome || '',
            contatoTelefone: oficina?.contatoTelefone || '',
            contatoEmail: oficina?.contatoEmail || '',
          }}
          enableReinitialize
          onSubmit={handleSubmitDadosOficina}
        >
          {({isSubmitting, handleSubmit}) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="nome"
                    label="Nome"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="cpfCnpj"
                    label="CPF / CNPJ"
                    mask="cpfCnpj"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="telefone"
                    label="Telefone"
                    mask="phone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="email"
                    label="E-mail"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="contatoNome"
                    label="Nome / Contato"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="contatoTelefone"
                    label="Telefone / Contato"
                    mask="phone"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="contatoEmail"
                    label="E-mail / Contato"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography id="formulario-subtitle">Endereço</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="cep"
                    label="CEP"
                    mask="cep"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputV2Formik}
                    name="bairro"
                    label="Bairro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  {readOnly ? (
                    <Field
                      component={InputV2Formik}
                      name="cidadeNome"
                      label="Cidade"
                      disabled
                    />
                  ) : (
                    <Field
                      component={SelectCidades}
                      name="cidade"
                      label="Cidade"
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputV2Formik}
                    name="numero"
                    label="Número"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Field
                    component={InputV2Formik}
                    name="logradouro"
                    label="Logradouro"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    component={InputV2Formik}
                    name="complemento"
                    label="Complemento"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Grid container>
                      <Grid item xs={6} style={{visibility: showExcluir() ? 'visible' : 'hidden'}}>
                        <ButtonComponent
                          id="button-cancel"
                          type="button"
                          value={'Excluir'}
                          icon={<Delete />}
                          loading={excluindo}
                          sx={styles.button}
                          onClick={handleDeleteDadosOficina}
                          disabled={!readOnly}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        display="flex"
                        justifyContent="flex-end">
                        {readOnly && (
                          <ButtonComponent
                            type="button"
                            value={'Editar'}
                            icon={<Edit />}
                            onClick={() => setReadOnly(false)}
                            sx={styles.button}
                          />
                        )}
                        {!readOnly && (
                          <ButtonComponent
                            type="submit"
                            value={'Salvar'}
                            icon={<Save />}
                            loading={isSubmitting}
                            sx={styles.button}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

const oficinaScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório'),
  cep: Yup.string().required('Campo obrigatório'),
  logradouro: Yup.string().required('Campo obrigatório'),
  numero: Yup.number().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  cidade: Yup.string().required('Campo obrigatório'),
});

const styles = {
  button: {
    width: '256px',
  },
};

export default FormularioAgencia;
