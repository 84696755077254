import React, {useState} from 'react';
import {Formik, Field} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {Grid, Paper, Box} from '@mui/material';
import {Save} from '@mui/icons-material';
import {ButtonComponent} from '../../components/mui-button';
import {CRIAR_ANUNCIO_IMAGEM_VEICULO} from '../../graphql/mutation';
import {IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE} from '../../constants/routes';
import {toastNotification} from '../../components/toastify';
import {uploadAmazonS3} from '../../services/UploadService';
import {LIGHT_COLORS} from '../../theme/colors/mui-colors';
import {SelectMotoristas} from '../../containers/selects/mui-motoristas';
import InputDate from '../../components/mui-input-date';
import SelectFormik from '../../components/select/mui-select-formik';
import UploadableFotoPerfil from '../../components/mui-uploadable-foto-perfil';
import {SelectCampanhas} from '../../containers/selects/mui-campanhas';
import Data from '../../utils/data';

const FormularioCadastrarValidacaoImagens = () => {
  const [tipoSelecionado, setTipoSelecionado] = useState('');
  const [modeloAnuncio, setModeloAnuncio] = useState(null);
  const [imagemOdometro, setImagemOdometro] = useState(null);
  const [imagemDiagonalDireitaFrontal, setImagemDiagonalDireita] =
    useState(null);
  const [imagemDiagonalEsquerdaTraseira, setImagemDiagonalEsquerda] =
    useState('');
  const [imagemEsquerda, setImagemEsquerda] = useState(null);
  const [imagemDireita, setImagemDireita] = useState(null);
  const [imagemFrente, setImagemFrente] = useState(null);
  const [imagemInterna, setImagemInterna] = useState(null);
  const [imagemTraseira, setImagemTraseira] = useState(null);
  const [loading, setLoading] = useState(false);

  const [criarAnuncioImagemVeiculo] = useMutation(CRIAR_ANUNCIO_IMAGEM_VEICULO);

  const navigate = useNavigate();

  const handleCheckImageFile = (e, name, type) => {
    if (type === 'file') {
      switch (name) {
        case 'imagemLateralEsquerda':
          return setImagemEsquerda((prev) => ({...prev, file: e}));
        case 'imagemLateralDireita':
          return setImagemDireita((prev) => ({...prev, file: e}));
        case 'imagemDiagonalEsquerdaTraseira':
          return setImagemDiagonalEsquerda((prev) => ({...prev, file: e}));
        case 'imagemDiagonalDireitaFrontal':
          return setImagemDiagonalDireita((prev) => ({...prev, file: e}));
        case 'imagemInterna':
          return setImagemInterna((prev) => ({...prev, file: e}));
        case 'imagemFrente':
          return setImagemFrente((prev) => ({...prev, file: e}));
        case 'imagemTraseira':
          return setImagemTraseira((prev) => ({...prev, file: e}));
        case 'imagemOdometro':
          return setImagemOdometro((prev) => ({...prev, file: e}));
        default:
          break;
      }
    }
    if (type === 'preview') {
      switch (name) {
        case 'imagemLateralEsquerda':
          return setImagemEsquerda((prev) => ({...prev, preview: e}));
        case 'imagemLateralDireita':
          return setImagemDireita((prev) => ({...prev, preview: e}));
        case 'imagemDiagonalEsquerdaTraseira':
          return setImagemDiagonalEsquerda((prev) => ({
            ...prev,
            preview: e,
          }));
        case 'imagemDiagonalDireitaFrontal':
          return setImagemDiagonalDireita((prev) => ({...prev, preview: e}));
        case 'imagemInterna':
          return setImagemInterna((prev) => ({...prev, preview: e}));
        case 'imagemFrente':
          return setImagemFrente((prev) => ({...prev, preview: e}));
        case 'imagemTraseira':
          return setImagemTraseira((prev) => ({...prev, preview: e}));
        case 'imagemOdometro':
          return setImagemOdometro((prev) => ({...prev, preview: e}));
        default:
          break;
      }
    }
  };

  const CheckTipo = (local) => {
    const modeloAnuncioArray = [`imagemOdometro`];
    const mensalTipoArray = [
      `imagemDiagonalDireitaFrontal`,
      `imagemDiagonalEsquerdaTraseira`,
      `imagemOdometro`,
    ];
    const modeloAnuncioLocalObject = {
      interno: [`imagemInterna`],
      laterais: [`imagemLateralEsquerda`, `imagemLateralDireita`],
      traseira: [`imagemTraseira`],
      frente: [`imagemFrente`],
      imagemOdometro: [`imagemOdometro`],
    };
    for (var key in modeloAnuncio) {
      if (modeloAnuncio[key] === true) {
        modeloAnuncioArray.push(key);
      }

    }

    if (tipoSelecionado === 'INSTALACAO') {
      for (var c = 0; c <= modeloAnuncioArray.length - 1; c++) {
        if (
          modeloAnuncioLocalObject[`${modeloAnuncioArray[c]}`].includes(local)
        )
          return true;
      }
      return false;
    }

    if (tipoSelecionado === 'MENSAL') {
      if (local !== `imagemInterna`) {
        return !!mensalTipoArray?.includes(local);
      }
      if (!!modeloAnuncioArray.includes('interno')) {
        return local === `imagemInterna`;
      }

      return false;
    }
  };
  function handleChangeUploadImage({event, nome}) {
    const file = Array.from(event.target.files)[0];
    handleCheckImageFile(file, nome, 'file');
    handlePreviewImage(file, nome);
  }

  function handlePreviewImage(file, name) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        handleCheckImageFile(reader.result, name, 'preview');
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }
  const formularioOk = (arrayTeste) => {
    if (arrayTeste.some((item) => !item === true)) return false;
    return true;
  };

  const ImagemValidacaoScheme = Yup.object().shape({
    motorista: Yup.object().required(),
    dataEnvio: Yup.date().required(),
    tipo: Yup.object().required(),
  });

  async function handleSubmit({motorista, dataEnvio, tipo}) {
    let imagemLateralEsquerdaResponse = null;
    let imagemLateralDireitaResponse = null;
    let imagemDiagonalEsquerdaTraseiraResponse = null;
    let imagemDiagonalDireitaFrontalResponse = null;
    let imagemInternaResponse = null;
    let imagemFrenteResponse = null;
    let imagemTraseiraResponse = null;
    let imagemOdometroResponse = null;

    if (
      !formularioOk([
        CheckTipo('imagemLateralEsquerda') ? imagemEsquerda : true,
        CheckTipo('imagemLateralDireita') ? imagemDireita : true,
        CheckTipo('imagemDiagonalEsquerdaTraseira')
          ? imagemDiagonalEsquerdaTraseira
          : true,
        CheckTipo('imagemDiagonalDireitaFrontal')
          ? imagemDiagonalDireitaFrontal
          : true,
        CheckTipo('imagemInterna') ? imagemInterna : true,
        CheckTipo('imagemFrente') ? imagemFrente : true,
        CheckTipo('imagemTraseira') ? imagemTraseira : true,
        CheckTipo('imagemOdometro') ? imagemOdometro : true,
      ])
    ) {
      toastNotification({
        titulo: 'Adicione todas as imagens!',
        type: 'error',
      });
      return;
    }
    setLoading(true);
    try {
      if (imagemEsquerda?.file) {
        imagemLateralEsquerdaResponse = await uploadAmazonS3(
          imagemEsquerda?.file,
        );
      }
      if (imagemDireita?.file) {
        imagemLateralDireitaResponse = await uploadAmazonS3(
          imagemDireita?.file,
        );
      }

      if (imagemDiagonalEsquerdaTraseira?.file) {
        imagemDiagonalEsquerdaTraseiraResponse = await uploadAmazonS3(
          imagemDiagonalEsquerdaTraseira?.file,
        );
      }

      if (imagemDiagonalDireitaFrontal?.file) {
        imagemDiagonalDireitaFrontalResponse = await uploadAmazonS3(
          imagemDiagonalDireitaFrontal?.file,
        );
      }

      if (imagemInterna?.file) {
        imagemInternaResponse = await uploadAmazonS3(imagemInterna?.file);
      }

      if (imagemFrente?.file) {
        imagemFrenteResponse = await uploadAmazonS3(imagemFrente?.file);
      }

      if (imagemTraseira?.file) {
        imagemTraseiraResponse = await uploadAmazonS3(imagemTraseira?.file);
      }
      if (imagemOdometro?.file) {
        imagemOdometroResponse = await uploadAmazonS3(imagemOdometro?.file);
      }
    } catch (error) {
      toastNotification({
        titulo: 'Não foi possível enviar os arquivos!',
        message: 'Tente novamente mais tarde.',
        type: 'error',
      });
      setLoading(false);
    }
    criarAnuncioImagemVeiculo({
      variables: {
        anuncioImagem: {
          anuncio: {
            id: motorista.anuncioAtivo.id,
          },
          data: Data.dataFormat(dataEnvio, 'YYYY-MM-DDTHH:mm'),
          tipo: tipo.value,
          imagemDiagonalDireitaFrontal:
            imagemDiagonalDireitaFrontalResponse?.id || undefined,
          imagemDiagonalEsquerdaTraseira:
            imagemDiagonalEsquerdaTraseiraResponse?.id || undefined,
          imagemFrente: imagemFrenteResponse?.id || undefined,
          imagemInterna: imagemInternaResponse?.id || undefined,
          imagemLateralDireita: imagemLateralDireitaResponse?.id || undefined,
          imagemLateralEsquerda: imagemLateralEsquerdaResponse?.id || undefined,
          imagemOdometro: imagemOdometroResponse?.id || undefined,
          imagemTraseira: imagemTraseiraResponse?.id || undefined,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'Dados salvos com sucesso!',
          type: 'success',
        });
        setLoading(false);
        navigate(IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  }
  const handleChangeTipo = (value) => {
    setImagemDiagonalDireita('');
    setImagemDiagonalEsquerda('');
    setImagemFrente('');
    setImagemInterna('');
    setImagemDireita('');
    setImagemEsquerda('');
    setImagemOdometro('');
    setImagemTraseira('');
    setTipoSelecionado(value);
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          initialValues={{
            motorista: null,
            dataEnvio: new Date(),
            tipo: null,
          }}
          validationSchema={ImagemValidacaoScheme}
          onSubmit={handleSubmit}>
          {({handleSubmit, values}) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Field
                    component={SelectCampanhas}
                    name="campanha"
                    label="Campanha"
                    searchDTO={{campanhaSituacoesIn: ['AGUARDANDO_INSTALACAO', 'ATIVA', 'ATIVA_PARCIAL']}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={({...props}) => (
                      <SelectMotoristas
                        searchDTO={{
                          possuiAnuncioAtivo: true,
                          campanhaId: values.campanha?.id,
                          instalacaoAgendada: true
                        }}
                        {...props}
                      />
                    )}
                    name="motorista"
                    label="Motorista"
                    onChange={(e) =>
                      setModeloAnuncio(e.anuncioAtivo.modeloAnuncio)
                    }
                    mensagemSuporte={'Campo obrigatório'}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={SelectFormik}
                    label="Tipo"
                    name="tipo"
                    options={[
                      {value: 'INSTALACAO', label: 'Instalação'},
                      {value: 'MENSAL', label: 'Mensal'},
                    ]}
                    onChange={(tipo) => handleChangeTipo(tipo.value)}
                    mensagemSuporte={'Campo obrigatório'}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={InputDate}
                    name="dataEnvio"
                    label="Data de Envio"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    {CheckTipo('imagemLateralEsquerda') && (
                      <Box sx={styles.imagemColumn}>
                        <UploadableFotoPerfil
                          name={'imagemLateralEsquerda'}
                          label={'Lateral esquerda'}
                          image={imagemEsquerda?.preview}
                          index={0}
                          setImage={setImagemEsquerda}
                          handleChangeUploadImage={(file) =>
                            handleChangeUploadImage({
                              event: file,
                              nome: 'imagemLateralEsquerda',
                            })
                          }
                        />
                      </Box>
                    )}
                    {CheckTipo('imagemLateralDireita') && (
                      <Box sx={styles.imagemColumn}>
                        <UploadableFotoPerfil
                          name={'imagemLateralDireita'}
                          label={'Lateral direita'}
                          image={imagemDireita?.preview}
                          setImage={setImagemDireita}
                          index={1}
                          handleChangeUploadImage={(file) =>
                            handleChangeUploadImage({
                              event: file,
                              nome: 'imagemLateralDireita',
                            })
                          }
                        />
                      </Box>
                    )}
                    {CheckTipo('imagemTraseira') && (
                      <Box sx={styles.imagemColumn}>
                        <UploadableFotoPerfil
                          name={'imagemTraseira'}
                          label={'Traseira'}
                          index={2}
                          image={imagemTraseira?.preview}
                          setImage={setImagemTraseira}
                          handleChangeUploadImage={(file) =>
                            handleChangeUploadImage({
                              event: file,
                              nome: 'imagemTraseira',
                            })
                          }
                        />
                      </Box>
                    )}
                    {CheckTipo('imagemFrente') && (
                      <Box sx={styles.imagemColumn}>
                        <UploadableFotoPerfil
                          name={'imagemFrente'}
                          label={'Frente'}
                          index={3}
                          image={imagemFrente?.preview}
                          setImage={setImagemFrente}
                          handleChangeUploadImage={(file) =>
                            handleChangeUploadImage({
                              event: file,
                              nome: 'imagemFrente',
                            })
                          }
                        />
                      </Box>
                    )}
                    {CheckTipo('imagemDiagonalDireitaFrontal') && (
                      <Box sx={styles.imagemColumn}>
                        <UploadableFotoPerfil
                          name={'imagemDiagonalDireitaFrontal'}
                          label={'Diagonal direita'}
                          index={4}
                          image={imagemDiagonalDireitaFrontal?.preview}
                          setImage={setImagemDiagonalDireita}
                          handleChangeUploadImage={(file) =>
                            handleChangeUploadImage({
                              event: file,
                              nome: 'imagemDiagonalDireitaFrontal',
                            })
                          }
                        />
                      </Box>
                    )}
                    {CheckTipo('imagemDiagonalEsquerdaTraseira') && (
                      <Box sx={styles.imagemColumn}>
                        <UploadableFotoPerfil
                          name={'imagemDiagonalEsquerdaTraseira'}
                          label={'Diagonal esquerda'}
                          index={5}
                          image={imagemDiagonalEsquerdaTraseira?.preview}
                          handleChangeUploadImage={(file) =>
                            handleChangeUploadImage({
                              event: file,
                              nome: 'imagemDiagonalEsquerdaTraseira',
                            })
                          }
                        />
                      </Box>
                    )}
                    {CheckTipo('imagemInterna') && (
                      <Box sx={styles.imagemColumn}>
                        <UploadableFotoPerfil
                          name={'imagemInterna'}
                          label={'Mídia interna'}
                          index={6}
                          image={imagemInterna?.preview}
                          setImage={setImagemInterna}
                          handleChangeUploadImage={(file) =>
                            handleChangeUploadImage({
                              event: file,
                              nome: 'imagemInterna',
                            })
                          }
                        />
                      </Box>
                    )}
                    <Box sx={styles.imagemColumn}>
                      <UploadableFotoPerfil
                        name={'imagemOdometro'}
                        label={'Odômetro'}
                        index={7}
                        image={imagemOdometro?.preview}
                        setImage={setImagemOdometro}
                        handleChangeUploadImage={(file) =>
                          handleChangeUploadImage({
                            event: file,
                            nome: 'imagemOdometro',
                          })
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={styles.containerButton}>
                  <ButtonComponent
                    value={'Salvar'}
                    type="submit"
                    icon={<Save />}
                    sx={styles.buttons}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

const styles = {
  buttons: {
    width: '256px',
  },
  imagemColumn: {
    padding: '0 10px',
    width: '-webkit-fill-available',
  },
  situacaoAprovado: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& label': {
      color: `${LIGHT_COLORS.green} !important`,
    },
    '& input': {
      '-webkit-text-fill-color': `${LIGHT_COLORS.green} !important`,
    },
    '& fieldset': {
      borderColor: `${LIGHT_COLORS.green} !important`,
    },
  },
  situacaoReprovado: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& label': {
      color: `${LIGHT_COLORS.cancel} !important`,
    },
    '& input': {
      '-webkit-text-fill-color': `${LIGHT_COLORS.cancel} !important`,
    },
    '& fieldset': {
      borderColor: `${LIGHT_COLORS.cancel} !important`,
    },
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};

export default FormularioCadastrarValidacaoImagens;
