import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Paper, Grid, Button, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination} from '@mui/material';
import {Add, Visibility} from '@mui/icons-material';
import {useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {Formik} from 'formik';
import {persistAdministrador, persistFiltrosAdministrador} from '../../redux/Administrador/slice';
import {FIND_ALL_ADMINISTRADOR} from '../../graphql/queries';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import TableCellOrdered from '../../components/table-cell-ordered';
import InputV2 from '../../components/input-v2/mui-input-v2';
import Select from '../../components/select/mui-select';
import inputsEvents from '../../utils/inputsEvents';
import {usuarioRole} from '../../constants/enum-labels';
const columns = [
  {id: 'nome', label: 'Nome'},
  {id: 'email', label: 'E-mail'},
  {
    id: 'perfil',
    label: 'Perfil',
    format: ({usuario}) => usuarioRole[usuario?.authorities?.at(0)?.nome],
    calculado: true
  },
  {
    id: 'ativo',
    label: 'Situação',
    format: ({ativo}) => (ativo ? 'Ativo' : 'Inativo'),
  },
];

const ConsultaAdministrador = ({pageSize = 20}) => {

  const {administradorList, totalAdministrador, refetchAdministrador, filtrosAdministrador} =
    useSelector((store) => store.Administrador);


  const [page, setPage] = useState(filtrosAdministrador.page);
  const [rowsPerPage] = useState(pageSize);
  const [orderBy, setOrderBy] = useState(filtrosAdministrador.orderBy);
  const [filtros, setFiltros] = useState(filtrosAdministrador.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const administradorQuery = useQuery(FIND_ALL_ADMINISTRADOR, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        ativo: searchDTO.ativo?.value,
      },
    },
  });

  useEffect(() => {
    if (!administradorQuery.loading && !administradorQuery.error) {
      dispatch(
        persistAdministrador(administradorQuery.data.findAllAdministrador),
      );
      dispatch(persistFiltrosAdministrador({filtros, orderBy, page}))
    }

    if (refetchAdministrador) {
      administradorQuery.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [administradorQuery, dispatch, refetchAdministrador]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  return (
    <Grid id="page-container" container>
      <Button
        startIcon={<Add />}
        id='button-green'
        toolbar='true'
        onClick={() => navigate(`criar`)}
      >
        Cadastrar novo
      </Button>
      <Grid item xs={12} onKeyUp={({key}) => inputsEvents.handleKeyUp(key, refetch)}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id='filtro-paper'>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <InputV2
                  label="Nome"
                  value={filtros.search || ''}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                  className="input-filtro"
                />
              </Grid>
              <Grid item
                xs={2}
              >
                <Select
                  label="Situação"
                  value={filtros.ativo}
                  placeholder="Digite a situação"
                  onChange={(e) => setFiltros({...filtros, ativo: e || null})}
                  options={[
                    {label: 'Todas'},
                    {value: true, label: 'Ativo'},
                    {value: false, label: 'Inativo'},
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  id="button"
                  value="Filtrar"
                  loading={administradorQuery.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        calculado={column.calculado}
                      />
                    ))}
                    <TableCell
                      id="acoes"
                      key="acoes"
                      align="center"
                    >
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {administradorList.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => navigate(`${row.id}/editar`)}
                        style={{cursor: 'pointer'}}>
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={index} align={column.align}>
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell
                          align="center"
                        >
                          <RoundTooltipButton
                            id='icon-primary'
                            title={'Visualizar'}
                            icon={<Visibility />}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalAdministrador}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'página anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};


export default ConsultaAdministrador;
