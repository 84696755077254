import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Field} from 'formik';
import ReactExport from 'react-export-excel';
import {useMutation, useQuery} from '@apollo/client';
import {Paper, Grid, Typography, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Box} from '@mui/material';
import {
  GetApp,
  Publish,
  Visibility,
  Close,
  Download,
} from '@mui/icons-material';
import {
  atualizarExtratoRepasse,
  persistExtratoRepasse,
  persistFiltrosExtratoRepasse,
} from '../../redux/ExtratoRepasse/slice';
import {FIND_ALL_MOVIMENTACOES_FINANCEIRAS} from '../../graphql/queries';
import Number from '../../utils/number';
import {
  ButtonComponent,
  RoundTooltipButton,
} from '../../components/mui-button';
import InputDate from '../../components/mui-input-date';
import Select from '../../components/select/mui-select';
import {SelectAnunciantes} from '../../containers/selects/mui-anunciantes';
import DetalhesExtrato from './detalhes';
import {SelectCampanhas} from '../../containers/selects/mui-campanhas';
import {SelectComercial} from '../../containers/selects/mui-comercial';
import DarBaixaExtrato from './dar-baixa';
import {
  BAIXAR_MULTIPLAS_MOVIMENTACOES,
  ESTORNAR_MULTIPLAS_MOVIMENTACOES,
  ESTORNO_BAIXA_MOVIMENTACAO_FINANCEIRA,
  REPROVAR_MULTIPLAS_MOVIMENTACOES,
  MARCAR_ERRO_MULTIPLAS_MOVIMENTACOES,
} from '../../graphql/mutation';
import {toastNotification} from '../../components/toastify';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';
import TableCellOrdered from '../../components/table-cell-ordered';
import {movimentacaoSituacao} from '../../constants/enum-labels';
import {SelectInstaladores} from '../../containers/selects/mui-instaladores';
import ReprovarExtrato from './reprovar-movimentacao';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import InputV2 from '../../components/input-v2/mui-input-v2';
import Data from '../../utils/data';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const formatPeriodo = (row) => {
  if (!row?.dataInicioPeriodo || !row?.dataFimPeriodo) return '';

  return (
    <>
      {Data.dataFormat(row.dataInicioPeriodo, 'DD/MM/YY')}<br />
      {Data.dataFormat(row.dataFimPeriodo, 'DD/MM/YY')}
    </>
  );
}

const columns = [
  {
    id: 'comercial',
    label: 'Comercial',
    campo: 'comercial.nomeRazao',
    minWidth: 145,
    format: ({value}) => value?.nomeRazao || ' ',
    destino: ['COMERCIAL', 'ROYALTIES'],
  },
  {
    id: 'licencaRecebimento',
    label: 'Recebedor',
    campo: 'licencaRecebimento.nomeRazao',
    minWidth: 145,
    format: ({value}) => value?.nomeRazao || 'Km Mídia',
    destino: ['ROYALTIES'],
  },
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'campanha.anunciante.nomeFantasia',
    minWidth: 145,
    format: ({row}) => row?.campanha?.anunciante?.nomeFantasia || ' ',
    destino: ['MOTORISTA', 'COMERCIAL', 'ANUNCIANTE', 'INSTALADOR', 'ROYALTIES'],
  },
  {
    id: 'campanha',
    label: 'Campanha',
    campo: 'campanha.titulo',
    format: ({value}) => `[${value?.numero}] ${value?.titulo}`,
    destino: ['MOTORISTA', 'COMERCIAL', 'ANUNCIANTE', 'INSTALADOR', 'ROYALTIES'],
  },
  {
    id: 'cidade',
    label: 'Cidade',
    campo: 'campanha.cidade.nome',
    format: ({row}) => row?.campanha?.cidade?.nomeEUf || ' ',
    destino: ['MOTORISTA', 'COMERCIAL', 'ANUNCIANTE', 'INSTALADOR'],
  },
  {
    id: 'nomeRecebedor',
    label: 'Nome do Recebedor',
    campo: 'motorista.nome',
    format: ({row}) => row?.motorista?.nome || ' ',
    destino: ['MOTORISTA'],
  },
  {
    id: 'nomeRecebedor',
    label: 'Nome do Recebedor',
    campo: 'motorista.nome',
    format: ({row}) => row?.instalador?.nome || ' ',
    destino: ['INSTALADOR'],
  },
  {
    id: 'nomeRecebedor',
    label: 'Nome do Motorista',
    campo: 'motorista.nome',
    format: ({row}) => {
      return row?.motorista?.nome || row?.anuncio?.motorista?.nome || ' ';
    },
    destino: ['INSTALADOR'],
  },
  {
    id: 'dataInicioPeriodo',
    label: 'Período',
    format: ({row}) => formatPeriodo(row),
    destino: ['MOTORISTA'],
  },
  {
    id: 'dataAprovacao',
    label: 'Aprovação',
    minWidth: 145,
    align: 'right',
    format: ({value}) => (value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : ' '),
    destino: ['MOTORISTA'],
  },
  {
    id: 'dataPrevisaoPgto',
    label: 'Vencimento',
    minWidth: 145,
    align: 'right',
    format: ({value}) => (value ? moment(value).format('DD/MM/YYYY') : ' '),
    destino: ['MOTORISTA', 'COMERCIAL', 'ANUNCIANTE', 'INSTALADOR'],
  },
  {
    id: 'dataPrevisaoPgto',
    label: 'Pagamento',
    minWidth: 145,
    align: 'right',
    format: ({value}) => (value ? moment(value).format('DD/MM/YYYY') : ' '),
    destino: ['ROYALTIES'],
  },
  {
    id: 'valor',
    label: 'Valor',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
    destino: ['MOTORISTA', 'COMERCIAL', 'ANUNCIANTE', 'INSTALADOR'],
  },
  {
    id: 'valorReferencia',
    label: 'Valor referência',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
    destino: ['ROYALTIES'],
  },
  {
    id: 'valor',
    label: 'Royalties',
    align: 'right',
    format: ({value}) => Number.currencyFormat(value),
    destino: ['ROYALTIES'],
  },
  {
    id: 'situacao',
    label: 'Situação',
    format: ({value}) => movimentacaoSituacao[value],
    destino: ['MOTORISTA', 'COMERCIAL', 'ANUNCIANTE', 'INSTALADOR'],
  },
];

const ConsultaExtratoRepasse = ({pageSize = 20}) => {
  const dispatch = useDispatch();

  const {
    filtrosExtrato,
    extratoRepasseList,
    totalExtratoRepasse,
    refetchExtratoRepasse,
  } = useSelector((store) => store.ExtratoRepasse);

  const [page, setPage] = useState(filtrosExtrato.page);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [filtros, setFiltros] = useState({
    ...filtrosExtrato.filtros,
    dataPrevisaoPagamentoInicio:
      filtrosExtrato.filtros?.dataPrevisaoPagamentoInicio &&
      moment(JSON.parse(filtrosExtrato.filtros?.dataPrevisaoPagamentoInicio))
        .startOf('month')
        .toDate(),
    dataInicial:
      filtrosExtrato.filtros?.dataInicial &&
      moment(JSON.parse(filtrosExtrato.filtros?.dataInicial))
        .startOf('month')
        .toDate(),
  });
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosExtrato.orderBy);
  const [selecionado, setSelecionado] = useState(undefined);
  const [openBaixa, setOpenBaixa] = useState(false);
  const [openReprovar, setOpenReprovar] = useState(false);
  const [isOpenDetalhes, setIsOpenDetalhes] = useState(false);
  const [selecionados, setSelecionados] = useState([]);
  const [chavesPix, setChavesPix] = useState([]);

  const [estornarBaixaMovimentacao] = useMutation(
    ESTORNO_BAIXA_MOVIMENTACAO_FINANCEIRA,
  );
  const [baixarMultiplasMovimentacoes, {loading: baixarLoading}] = useMutation(
    BAIXAR_MULTIPLAS_MOVIMENTACOES,
  );
  const [reprovarMultiplasMovimentacoes, {loading: reprovarLoading}] =
    useMutation(REPROVAR_MULTIPLAS_MOVIMENTACOES);
  const [estornarMultiplasMovimentacoes, {loading: estornarLoading}] =
    useMutation(ESTORNAR_MULTIPLAS_MOVIMENTACOES);
  const [errorMultiplasMovimentacoes, {loading: errorLoading}] =
    useMutation(MARCAR_ERRO_MULTIPLAS_MOVIMENTACOES);

  const extratoRepasseQuery = useQuery(FIND_ALL_MOVIMENTACOES_FINANCEIRAS, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        dataPrevisaoPagamentoInicio: searchDTO.dataPrevisaoPagamentoInicio
          ? moment(searchDTO.dataPrevisaoPagamentoInicio).format('YYYY-MM-DD')
          : undefined,
        dataInicial: searchDTO.dataInicial
          ? moment(searchDTO.dataInicial).format('YYYY-MM-DD')
          : undefined,
        statusMovimentacaoFinanceira: searchDTO.movimentacaoOrigem?.value === 'ROYALTIES' ? undefined : searchDTO.statusMovimentacaoFinanceira?.value,
        movimentacaoOrigem: searchDTO.movimentacaoOrigem?.value,
        campanhaId: searchDTO.campanha?.value,
        anuncianteId: searchDTO.anunciante?.value,
        comercialId: searchDTO.comercial?.value,
        search: searchDTO.search,
        instaladorId: searchDTO.instalador?.value,
        cidadeId: searchDTO.cidade?.value,
      },
    },
  });

  useEffect(() => {
    if (
      !extratoRepasseQuery.loading &&
      !extratoRepasseQuery.error &&
      extratoRepasseQuery.data
    ) {
      setSelecionados([]);
      dispatch(
        persistExtratoRepasse(extratoRepasseQuery.data?.movimentacoes || []),
      );
      dispatch(
        persistFiltrosExtratoRepasse({
          filtros: {
            ...filtros,
            dataPrevisaoPagamentoInicio:
              filtros.dataPrevisaoPagamentoInicio &&
              JSON.stringify(filtros.dataPrevisaoPagamentoInicio),
            dataInicial:
              filtros.dataInicial && JSON.stringify(filtros.dataInicial),
          },
          page,
          orderBy,
        }),
      );
    }
    if (refetchExtratoRepasse) {
      extratoRepasseQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extratoRepasseQuery, dispatch, refetchExtratoRepasse]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickExtrato = (movimentacaoFinanceira) => {
    setIsOpenDetalhes(true);
    setSelecionado(movimentacaoFinanceira);
  };

  const handleCloseDetalhes = () => {
    setIsOpenDetalhes(false);
    setSelecionado(undefined);
  };

  const handleClickBaixa = (movimentacao) => {
    setSelecionado(movimentacao);
    setOpenBaixa(true);
  };

  const handleClickReprovar = (movimentacao) => {
    setSelecionado(movimentacao);
    setOpenReprovar(true);
  };

  const handleEstornarBaixa = (movimentacao) => {
    setSelecionado(movimentacao);

    estornarBaixaMovimentacao({
      variables: {
        id: movimentacao.id,
      },
    })
      .then(() => {
        extratoRepasseQuery.refetch();
        toastNotification({message: 'A baixa foi estornada!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const handleClickBaixarMovimentacoes = () => {
    confirmAlertMui({
      message: `Deseja dar baixa em ${selecionados.length} movimentações?`,
      onConfirm: handleBaixarMovimentacoes,
      onCancel: () => null,
    });
  };

  const handleClickEstornarMovimentacoes = () => {
    confirmAlertMui({
      message: `Deseja estornar ${selecionados.length} movimentações?`,
      onConfirm: handleEstornarMovimentacoes,
      onCancel: () => null,
    });
  };

  const handleClickReprovarMovimentacoes = () => {
    confirmAlertMui({
      message: `Deseja reprovar ${selecionados.length} movimentações?`,
      onConfirm: handleReprovarMovimentacoes,
      onCancel: () => null,
    });
  };

  const handleClickErrorMovimentacoes = () => {
    confirmAlertMui({
      message: `Deseja marcar ${selecionados.length} movimentações como pagamento inválido?`,
      onConfirm: handleErrorMovimentacoes,
      onCancel: () => null,
    });
  };

  const handleBaixarMovimentacoes = () => {
    baixarMultiplasMovimentacoes({
      variables: {
        ids: selecionados,
      },
    })
      .then(({data}) => {
        extratoRepasseQuery.refetch();
        toastNotification({
          message: `${data.baixas} movimentações baixadas!`,
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const handleEstornarMovimentacoes = () => {
    estornarMultiplasMovimentacoes({
      variables: {
        ids: selecionados,
      },
    })
      .then(({data}) => {
        extratoRepasseQuery.refetch();
        toastNotification({
          message: `${data.estornos} movimentações estornadas!`,
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const handleErrorMovimentacoes = () => {
    errorMultiplasMovimentacoes({
      variables: {
        ids: selecionados,
      },
    })
      .then(({data}) => {
        extratoRepasseQuery.refetch();
        toastNotification({
          message: `${data.movimentacoes} movimentações alteradas!`,
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const handleReprovarMovimentacoes = () => {
    reprovarMultiplasMovimentacoes({
      variables: {
        ids: selecionados,
      },
    })
      .then(({data}) => {
        extratoRepasseQuery.refetch();
        toastNotification({
          message: `${data.reprovados} movimentações reprovadas!`,
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSelecionado(undefined));
  };

  const handleClickEstornar = (movimentacao) => {
    confirmAlertMui({
      message: 'Deseja estornar a baixa?',
      onConfirm: () => handleEstornarBaixa(movimentacao),
      onCancel: () => null,
    });
  };

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleDestinoChange = (event) => {
    var dataPrevisaoPagamentoInicio =
      filtros.dataPrevisaoPagamentoInicio || new Date();

    setFiltros({
      ...filtros,
      dataPrevisaoPagamentoInicio,
      movimentacaoOrigem: event,
    });
    setSearchDTO({
      ...searchDTO,
      dataPrevisaoPagamentoInicio,
      movimentacaoOrigem: event,
    });
  };
  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  useEffect(() => {
    if (chavesPix.length === 0) return;

    setTimeout(() => setChavesPix([]), 1500);

    // eslint-disable-next-line
  }, [chavesPix]);

  const handleSelectAll = () => {
    if (selecionados.length === extratoRepasseList.length) return setSelecionados([]);

    setSelecionados(extratoRepasseList.map((e) => e.id));
  };

  const exportarChavesPix = () => {
    setChavesPix(
      extratoRepasseList.map((e) => ({
        'pix': e.motorista?.dadoBancario?.chavePix?.toString(),
        'valor': Number.currencyFormat(e.valor)?.replace('R$', ''),
        'descricao': e.motorista?.nome,
      })),
    );
  };

  const reduceValorSelecionados = () => {
    const valor = extratoRepasseList.filter(e => selecionados.includes(e.id)).map(e => e.valor).reduce((v1, v2) => v1 + v2);
    return Number.currencyFormat(valor);
  }

  return (
    <Grid id="page-container" container>
      {filtros.movimentacaoOrigem?.value === 'MOTORISTA' && (
        <RoundTooltipButton
          size="small"
          id="icon-toolbar"
          title={'Exportar para Excel'}
          onClick={exportarChavesPix}
          icon={<Download />}
          loading={extratoRepasseQuery.loading}
          loadingColor="grey"
        />
      )}
      {chavesPix.length > 0 && (
        <ExcelFile hideElement={true} filename="Motoristas">
          <ExcelSheet data={chavesPix} name="Motoristas">
            <ExcelColumn label="Chave Pix" value="pix" />
            <ExcelColumn label="Valor" value="valor" />
            <ExcelColumn label="Descrição ( até 50 caracteres )" value="descricao" />
          </ExcelSheet>
        </ExcelFile>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Formik initialValues={filtros} enableReinitialize>
            <Paper id="filtro-paper">
              <Grid item container spacing={2}>
                {['COMERCIAL', 'ROYALTIES'].includes(filtros.movimentacaoOrigem?.value) && (
                  <Grid item xs={3}>
                    <Field
                      component={SelectComercial}
                      label="Comercial"
                      name="comercial"
                      isClearable
                      onChange={(e) => setFiltros({...filtros, comercial: e})}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <Field
                    component={SelectAnunciantes}
                    label="Anunciante"
                    name="anunciante"
                    isClearable
                    onChange={(e) => setFiltros({...filtros, anunciante: e})}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={SelectCidades}
                    label="Cidade"
                    name="cidade"
                    isClearable
                    onChange={(e) => setFiltros({...filtros, cidade: e})}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={({...props}) => (
                      <SelectCampanhas
                        searchDTO={{
                          anuncianteId: filtros.anunciante?.value,
                          cidadeCampanhaId: filtros.cidade?.value,
                        }}
                        {...props}
                      />
                    )}
                    label="Campanha"
                    name="campanha"
                    isClearable
                    onChange={(e) => setFiltros({...filtros, campanha: e})}
                  />
                </Grid>
                {filtros.movimentacaoOrigem?.value === 'MOTORISTA' && (
                  <Grid item xs={3}>
                    <InputV2
                      label="Motorista"
                      value={filtros.search}
                      name="motorista"
                      onChange={({target}) =>
                        setFiltros({...filtros, search: target.value})
                      }
                    />
                  </Grid>
                )}
                {filtros.movimentacaoOrigem?.value === 'INSTALADOR' && (
                  <Grid item xs={3}>
                    <Field
                      component={SelectInstaladores}
                      label="Instalador"
                      name="instalador"
                      isClearable
                      onChange={(e) => setFiltros({...filtros, instalador: e})}
                    />
                  </Grid>
                )}
                <Grid item xs={1} style={{marginLeft: 'auto'}}>
                  <ButtonComponent
                    id="button"
                    loading={extratoRepasseQuery.loading}
                    value={'Filtrar'}
                    onClick={() => refetch()}
                    fullWidth
                  />
                </Grid>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={3}>
                    <Select
                      options={[
                        {value: 'ANUNCIANTE', label: 'Anunciante'},
                        {value: 'COMERCIAL', label: 'Comercial'},
                        {value: 'INSTALADOR', label: 'Instalador'},
                        {value: 'MOTORISTA', label: 'Motorista'},
                        {value: 'ROYALTIES', label: 'Royalties'},
                      ]}
                      label="Destino Recebimento"
                      value={filtros.movimentacaoOrigem}
                      onChange={handleDestinoChange}
                    />
                  </Grid>
                  {filtros.movimentacaoOrigem?.value === 'MOTORISTA' && (
                    <Grid item xs={3}>
                      <InputDate
                        label="Período de competência"
                        selected={filtros.dataInicial}
                        dateFormat="MMMM/yyyy"
                        showMonthYearPicker
                        onChange={(e) => {
                          setFiltros({...filtros, dataInicial: e});
                        }}
                        isClearable
                      />
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <InputDate
                      label="Período de pagamento"
                      selected={filtros.dataPrevisaoPagamentoInicio}
                      dateFormat="MMMM/yyyy"
                      showMonthYearPicker
                      onChange={(e) => {
                        setFiltros({
                          ...filtros,
                          dataPrevisaoPagamentoInicio: e,
                        });
                        setSearchDTO({
                          ...searchDTO,
                          dataPrevisaoPagamentoInicio: e,
                        });
                      }}
                      isClearable
                    />
                  </Grid>
                  {filtros.movimentacaoOrigem?.value !== 'ROYALTIES' && (
                    <Grid item xs={2}>
                      <Select
                        options={[
                          {value: undefined, label: 'Todos'},
                          {value: 'PENDENTE', label: 'Pendente'},
                          {value: 'APROVADO', label: 'Aprovado'},
                          {value: 'FINALIZADO', label: 'Pago'},
                          {value: 'REPROVADO', label: 'Reprovado'},
                          {value: 'ERRO_PAGAMENTO', label: 'Erro no pagamento'},
                        ]}
                        label="Situação"
                        value={filtros.statusMovimentacaoFinanceira}
                        onChange={(e) => {
                          setFiltros({
                            ...filtros,
                            statusMovimentacaoFinanceira: e,
                          });
                          setSearchDTO({
                            ...searchDTO,
                            statusMovimentacaoFinanceira: e,
                          });
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Formik>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {selecionados.length > 0 && (
                <Grid sx={styles.acoesMassaContainer}>
                  <Typography sx={{marginRight: '8px'}}>Ações em massa:</Typography>
                  <ButtonComponent
                    id="button-green"
                    loading={baixarLoading}
                    value={'Dar baixa'}
                    onClick={handleClickBaixarMovimentacoes}
                    sx={styles.acoesMassaButton}
                  />
                  <ButtonComponent
                    id="button"
                    loading={estornarLoading}
                    value={'Estornar'}
                    onClick={handleClickEstornarMovimentacoes}
                    sx={styles.acoesMassaButton}
                  />
                  <ButtonComponent
                    id="button-cancel"
                    loading={reprovarLoading}
                    value={'Reprovar'}
                    onClick={handleClickReprovarMovimentacoes}
                    sx={styles.acoesMassaButton}
                  />
                  <ButtonComponent
                    id="button-yellow"
                    loading={errorLoading}
                    value={'Erro no pagamento'}
                    onClick={handleClickErrorMovimentacoes}
                    sx={styles.acoesMassaButton}
                  />
                  <Typography style={{marginLeft: 'auto'}}>
                    {`${selecionados.length} selecionados - Valor: ${reduceValorSelecionados()}`}
                  </Typography>
                </Grid>
              )}
              <Box overflow="auto">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key="acoes-check-all"
                        align="center"
                        sx={styles.checkBoxCell}
                      >
                        <Checkbox
                          color="primary"
                          checked={selecionados.length > 0 && selecionados.length === extratoRepasseList.length}
                          indeterminate={
                            selecionados.length > 0 &&
                            selecionados.length < extratoRepasseList.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      {columns
                        .filter((c) =>
                          c.destino.includes(filtros.movimentacaoOrigem.value),
                        )
                        .map((column) => (
                          <TableCellOrdered
                            key={column.id}
                            column={column}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            style={{minWidth: column.minWidth || 'auto'}}
                          />
                        ))}
                      <TableCell key="acoes" align="center" style={{fontWeight: '700'}}>
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {extratoRepasseList.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={
                            selecionados.includes(row.id)
                              ? {background: '#F0F5FF'}
                              : {background: '#FFF'}
                          }>
                          <TableCell
                            key="acoes-check-one"
                            align="center"
                            sx={styles.checkBoxCell}>
                            <Checkbox
                              color="primary"
                              checked={selecionados.includes(row.id)}
                              onClick={() => {
                                if (selecionados.includes(row.id))
                                  return setSelecionados((s) =>
                                    s.filter((id) => id !== row.id),
                                  );
                                setSelecionados((s) => s.concat(row.id));
                              }}
                            />
                          </TableCell>
                          {columns
                            .filter((c) =>
                              c.destino.includes(
                                filtros.movimentacaoOrigem.value,
                              ),
                            )
                            .map((column) => {
                              const value = row[column.id];
                              const licenciado = column.id === 'campanha' && row.campanha?.comercial?.role !== 'COMERCIAL';
                              return (
                                <TableCell key={column.id} align={column.align} sx={licenciado && styles.licenciado}>
                                  {column.format
                                    ? column.format({
                                      value,
                                      row,
                                      movimentacaoOrigem:
                                        filtros.movimentacaoOrigem,
                                    })
                                    : value}
                                </TableCell>
                              );
                            })}
                          <TableCell align="center" style={{minWidth: '180px'}}>
                            <Grid container spacing={0.5} justifyContent="center">
                              <Grid item xs={4}>
                                <RoundTooltipButton
                                  id="icon-primary"
                                  title={'Visualizar'}
                                  onClick={() => handleClickExtrato(row)}
                                  icon={<Visibility />}
                                />
                              </Grid>
                              {['FINALIZADO', 'REPROVADO'].includes(row.situacao) &&
                                (row.parcelaTipo !== 'ROYALTY_EXPORTACAO') && (
                                  <Grid item xs={4}>
                                    <RoundTooltipButton
                                      id="icon-cancel"
                                      title={'Estornar'}
                                      loading={selecionado?.id === row.id}
                                      onClick={() => handleClickEstornar(row)}
                                      icon={<Publish />}
                                    />
                                  </Grid>
                                )}
                              {['APROVADO', 'ERRO_PAGAMENTO'].includes(row.situacao) &&
                                (row.parcelaTipo !== 'ROYALTY_EXPORTACAO') && (
                                  <Grid item xs={4}>
                                    <RoundTooltipButton
                                      id="icon-green"
                                      title={'Dar baixa'}
                                      onClick={() => handleClickBaixa(row)}
                                      icon={<GetApp />}
                                    />
                                  </Grid>
                                )}
                              {['PENDENTE', 'APROVADO', 'ERRO_PAGAMENTO'].includes(
                                row.situacao,
                              ) && (
                                  <Grid item xs={4}>
                                    <RoundTooltipButton
                                      id="icon-cancel"
                                      title={'Reprovar'}
                                      onClick={() => handleClickReprovar(row)}
                                      icon={<Close />}
                                    />
                                  </Grid>
                                )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
              <TablePagination
                component="div"
                count={totalExtratoRepasse}
                rowsPerPage={rowsPerPage}
                page={page}
                rowsPerPageOptions={[20, 40, 50, 100]}
                onRowsPerPageChange={({target}) => setRowsPerPage(target?.value)}
                labelRowsPerPage="Linhas"
                backIconButtonProps={{
                  'aria-label': 'página anterior',
                }}
                nextIconButtonProps={{
                  'aria-label': 'próxima página',
                }}
                labelDisplayedRows={({from, to, count}) =>
                  `${from}-${to} de ${count}`
                }
                onPageChange={handleChangePage}
              />
            </TableContainer>
          </Grid>
        </Grid>
        <DetalhesExtrato
          openModal={isOpenDetalhes}
          onClose={handleCloseDetalhes}
          movimentacaoFinanceira={selecionado}
        />
        <DarBaixaExtrato
          isOpen={openBaixa}
          onClose={() => setOpenBaixa(false)}
          onBaixa={() => {
            setSelecionado(undefined);
            dispatch(atualizarExtratoRepasse());
          }}
          movimentacaoFinanceira={selecionado}
        />
        <ReprovarExtrato
          isOpen={openReprovar}
          onClose={() => setOpenReprovar(false)}
          onReprovar={() => {
            setSelecionado(undefined);
            dispatch(atualizarExtratoRepasse());
          }}
          movimentacaoFinanceira={selecionado}
        />
      </Grid>
    </Grid>
  );
};

const styles = {
  acoesMassaContainer: {
    height: 52,
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',

    '& p': {
      fontFamily: 'AktivGrotesk-Bold',
      fontSize: '0.875rem',
      color: '#000000de',
    },
  },
  acoesMassaButton: {
    minWidth: 120,
    width: 'fit-content',
    marginLeft: '8px',
    textTransform: 'none'
  },
  checkBoxCell: {
    width: '60px',
    padding: '0px',

    '& .MuiCheckbox-root': {
      color: '#CDCED9',
    },

    '& .Mui-checked': {
      color: '#1976d2 !important',
    },
  },
  licenciado: {
    color: theme => theme.palette.paterns.green
  }
};

export default ConsultaExtratoRepasse;
