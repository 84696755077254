import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  oficinas: [],
  filtrosOficinas: {
    filtros: {},
    page: 0,
    orderBy: {campo: 'nome', asc: true},
  },
  hasMore: false,
  totalOficinas: 0,
  refetchOficinas: false,
};

const acaoAtualizarOficinas = (state) => {
  state.refetchOficinas = true;
};

const acaoPersistOficinas = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.oficinas = content || [];
  state.hasMore = !last;
  state.totalOficinas = totalElements || 0;
};

const acaoPersistFiltrosOficinas = (state, action) => {
  state.filtrosOficinas = action.payload;
};

const resumeSlice = createSlice({
  name: 'oficina',
  initialState: initialState,
  reducers: {
    persistOficinas: acaoPersistOficinas,
    atualizarOficinas: acaoAtualizarOficinas,
    persistFiltrosOficinas: acaoPersistFiltrosOficinas,
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistOficinas, atualizarOficinas, persistFiltrosOficinas} =
  actions;
