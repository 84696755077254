import React from 'react';
import {Grid, Paper} from '@mui/material';
import FormularioCadastroLicencas from './formularios/dados-cadastrais';

const CadastrarLicencas = () => {
  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <FormularioCadastroLicencas />
      </Paper>
    </Grid>
  );
};

export default CadastrarLicencas;
