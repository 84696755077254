import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_BY_ID_MODELO_VEICULO} from '../../graphql/queries';
import FormularioModeloVeiculo from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarModeloVeiculo = () => {
  const {modeloId} = useParams();

  const modeloQuery = useQuery(FIND_BY_ID_MODELO_VEICULO, {
    variables: {
      id: modeloId
    }
  });

  const {data = {}} = modeloQuery;

  if (modeloQuery.loading || modeloQuery.error) {
    return (
      <LoadingPage loading={modeloQuery.loading} error={modeloQuery.error} />
    );
  }

  return (
    <FormularioModeloVeiculo modelo={data.modelo} />
  );
};

export default EditarModeloVeiculo;
