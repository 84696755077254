import React from 'react';
import FormularioNotificacao from './formulario';

const CadastrarNotificacao = () => {
  return (
    <FormularioNotificacao />
  );
};

export default CadastrarNotificacao;
