import React, {useEffect, useState} from 'react';
import Board from 'react-trello';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {Grid, CircularProgress, Paper} from '@mui/material';
import {useLazyQuery} from '@apollo/client';
import {Field, Formik} from 'formik';
import moment from 'moment';
import {FIND_ALL_PROPOSTA} from '../../graphql/queries';
import {ButtonComponent} from '../../components/mui-button';
import {SelectAnunciantes} from '../../containers/selects/mui-anunciantes';
import {SelectComercial} from '../../containers/selects/mui-comercial';
import Card from './card';
import Lane from './lane';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import Data from '../../utils/data';
import {persistFiltrosProposta} from '../../redux/Proposta/slice';
import Select from '../../components/select/mui-select';

const optionSituacao = [
  {value: 'ORCAMENTO', label: 'Prospecção'},
  {value: 'EM_NEGOCIACAO', label: 'Negociação'},
  {value: 'NEGOCIACAO_AVANCADA', label: 'Negociação avançada'},
  {value: 'AGUARDANDO_PI', label: 'Aguardando PI'},
  {value: 'AGUARDANDO_APROVACAO', label: 'Aguardando aprovação do diretor'},
  {value: 'APROVADO', label: 'Aprovado'},
  {value: 'REPROVADO', label: 'Reprovado'},
];

const pageable = {
  pageSize: 20,
  orderBy: [
    {campo: 'dataAlteracao', ordem: 'DESC'},
    {campo: 'anunciante.nomeFantasia', ordem: 'ASC'},
    {campo: 'id', ordem: 'ASC'},
  ],
};

const ConsultaProposta = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {token} = useSelector((state) => state.Auth);
  const {filtrosProposta} = useSelector((store) => store.Proposta);

  const [filtros, setFiltros] = useState({
    ...filtrosProposta.filtros,
    dataInicial:
      filtrosProposta.filtros?.dataInicial &&
      moment(filtrosProposta.filtros.dataInicial).toDate(),
  });
  const [searchDTO, setSearchDTO] = useState({
    anuncianteId: filtros.anunciante?.value,
    comercialId: filtros.comercial?.value,
    cidadeId: filtros.cidade?.value,
    dataInicial: filtros.dataInicial,
  });

  const [prospeccao, setProspeccao] = useState([]);
  const [negociacao, setNegociacao] = useState([]);
  const [negociacaoAvancada, setNegociacaoAvancada] = useState([]);
  const [aguardandoPi, setAguardandoPi] = useState([]);
  const [aguardandoAprovacao, setAguardandoAprovacao] = useState([]);
  const [reprovado, setReprovado] = useState([]);
  const [aprovado, setAprovado] = useState([]);
  const [data, setData] = useState({lanes: [{id: '', cards: [{id: ''}]}]});
  const [paginaProspeccao, setPaginaProspeccao] = useState(0);
  const [paginaNegociacao, setPaginaNegociacao] = useState(0);
  const [paginaNegociacaoAvancada, setPaginaNegociacaoAvancada] = useState(0);
  const [paginaAguardandoPi, setPaginaAguardandoPi] = useState(0);
  const [paginaAguardandoAprovacao, setPaginaAguardandoAprovacao] = useState(0);
  const [paginaReprovado, setPaginaReprovado] = useState(0);
  const [paginaAprovado, setPaginaAprovado] = useState(0);

  const [buscarProspeccao, prospeccaoQuery] = useLazyQuery(FIND_ALL_PROPOSTA);
  const [buscarNegociacao, negociacaoQuery] = useLazyQuery(FIND_ALL_PROPOSTA);
  const [buscarNegociacaoAvancada, negociacaoAvancadaQuery] =
    useLazyQuery(FIND_ALL_PROPOSTA);
  const [buscarAguardandoPi, aguardandoPiQuery] =
    useLazyQuery(FIND_ALL_PROPOSTA);
  const [buscarAguardandoAprovacao, aguardandoAprovacaoQuery] = useLazyQuery(FIND_ALL_PROPOSTA);
  const [buscarReprovado, reprovadoQuery] = useLazyQuery(FIND_ALL_PROPOSTA);
  const [buscarAprovado, aprovadoQuery] = useLazyQuery(FIND_ALL_PROPOSTA);

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };
  const components = {
    Card: Card,
    LaneHeader: Lane,
  };

  const handlePaginacao = (pagina, lane) => {
    onPageChange(lane);
    return new Promise(function (resolve, reject) {
      resolve();
    });
  };

  const onPageChange = (lane) => {
    switch (lane) {
      case 'ORCAMENTO':
        return (
          !prospeccaoQuery.loading &&
          !prospeccaoQuery.data?.propostas?.last &&
          setPaginaProspeccao(paginaProspeccao + 1)
        );
      case 'EM_NEGOCIACAO':
        return (
          !negociacaoQuery.loading &&
          !negociacaoQuery.data?.propostas?.last &&
          setPaginaNegociacao(paginaNegociacao + 1)
        );
      case 'NEGOCIACAO_AVANCADA':
        return (
          !negociacaoAvancadaQuery.loading &&
          !negociacaoAvancadaQuery.data?.propostas?.last &&
          setPaginaNegociacaoAvancada(paginaNegociacaoAvancada + 1)
        );
      case 'AGUARDANDO_PI':
        return (
          !aguardandoPiQuery.loading &&
          !aguardandoPiQuery.data?.propostas?.last &&
          setPaginaAguardandoPi(paginaAguardandoPi + 1)
        );
      case 'AGUARDANDO_APROVACAO':
        return (
          !aguardandoAprovacaoQuery.loading &&
          !aguardandoAprovacaoQuery.data?.propostas?.last &&
          setPaginaAguardandoAprovacao(paginaAguardandoAprovacao + 1)
        );
      case 'APROVADO':
        return (
          !aprovadoQuery.loading &&
          !aprovadoQuery.data?.propostas?.last &&
          setPaginaAprovado(paginaAprovado + 1)
        );
      case 'REPROVADO':
        return (
          !reprovadoQuery.loading &&
          !reprovadoQuery?.data?.propostas?.last &&
          setPaginaReprovado(paginaReprovado + 1)
        );
      default:
        return;
    }
  };

  const refetch = () => {
    const dataInicial = filtros.periodo?.value
      ? moment().subtract(filtros.periodo.value, 'days')
      : undefined;

    setSearchDTO({
      anuncianteId: filtros.anunciante?.value,
      comercialId: filtros.comercial?.value,
      cidadeId: filtros.cidade?.value,
      dataInicial,
    });

    dispatch(
      persistFiltrosProposta({
        filtros: {
          ...filtros,
          dataInicial: Data.dataFormat(dataInicial, 'YYYY-MM-DD') || undefined,
        },
      }),
    );
  };

  useEffect(() => {
    buscarProspeccao({
      variables: {
        pageable: {
          pageNumber: paginaProspeccao,
          ...pageable,
        },
        searchDTO: {
          ...searchDTO,
          orcamentoSituacao: 'ORCAMENTO',
          dataInicial:
            Data.dataFormat(searchDTO.dataInicial, 'YYYY-MM-DD') || undefined,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaProspeccao]);

  useEffect(() => {
    buscarNegociacao({
      variables: {
        pageable: {
          pageNumber: paginaNegociacao,
          ...pageable,
        },
        searchDTO: {
          ...searchDTO,
          orcamentoSituacao: 'EM_NEGOCIACAO',
          dataInicial:
            Data.dataFormat(searchDTO.dataInicial, 'YYYY-MM-DD') || undefined,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaNegociacao]);

  useEffect(() => {
    buscarNegociacaoAvancada({
      variables: {
        pageable: {
          pageNumber: paginaNegociacaoAvancada,
          ...pageable,
        },
        searchDTO: {
          ...searchDTO,
          orcamentoSituacao: 'NEGOCIACAO_AVANCADA',
          dataInicial:
            Data.dataFormat(searchDTO.dataInicial, 'YYYY-MM-DD') || undefined,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaNegociacaoAvancada]);

  useEffect(() => {
    buscarAguardandoPi({
      variables: {
        pageable: {
          pageNumber: paginaAguardandoPi,
          ...pageable,
        },
        searchDTO: {
          ...searchDTO,
          orcamentoSituacao: 'AGUARDANDO_PI',
          dataInicial:
            Data.dataFormat(searchDTO.dataInicial, 'YYYY-MM-DD') || undefined,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaAguardandoPi]);

  useEffect(() => {
    buscarAguardandoAprovacao({
      variables: {
        pageable: {
          pageNumber: paginaAguardandoAprovacao,
          ...pageable,
        },
        searchDTO: {
          ...searchDTO,
          orcamentoSituacao: 'AGUARDANDO_APROVACAO',
          dataInicial:
            Data.dataFormat(searchDTO.dataInicial, 'YYYY-MM-DD') || undefined,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaAguardandoAprovacao]);

  useEffect(() => {
    buscarAprovado({
      variables: {
        pageable: {
          pageNumber: paginaAprovado,
          ...pageable,
        },
        searchDTO: {
          ...searchDTO,
          orcamentoSituacao: 'APROVADO',
          dataInicial:
            Data.dataFormat(searchDTO.dataInicial, 'YYYY-MM-DD') || undefined,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaAprovado]);

  useEffect(() => {
    buscarReprovado({
      variables: {
        pageable: {
          pageNumber: paginaReprovado,
          ...pageable,
        },
        searchDTO: {
          ...searchDTO,
          orcamentoSituacao: 'REPROVADO',
          dataInicial:
            Data.dataFormat(searchDTO.dataInicial, 'YYYY-MM-DD') || undefined,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaReprovado]);

  useEffect(() => {
    if (prospeccaoQuery.loading) return;

    if (paginaProspeccao === 0)
      setProspeccao(prospeccaoQuery?.data?.propostas?.content || []);
    else
      setProspeccao((prev) => [
        ...prev,
        ...prospeccaoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospeccaoQuery]);

  useEffect(() => {
    if (negociacaoQuery.loading) return;

    if (paginaNegociacao === 0)
      setNegociacao(negociacaoQuery?.data?.propostas?.content || []);
    else
      setNegociacao((prev) => [
        ...prev,
        ...negociacaoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [negociacaoQuery]);
  useEffect(() => {
    if (negociacaoAvancadaQuery.loading) return;

    if (paginaNegociacaoAvancada === 0)
      setNegociacaoAvancada(
        negociacaoAvancadaQuery?.data?.propostas?.content || [],
      );
    else
      setNegociacaoAvancada((prev) => [
        ...prev,
        ...negociacaoAvancadaQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [negociacaoAvancadaQuery]);

  useEffect(() => {
    if (aguardandoPiQuery.loading) return;

    if (paginaAguardandoPi === 0)
      setAguardandoPi(aguardandoPiQuery?.data?.propostas?.content || []);
    else
      setAguardandoPi((prev) => [
        ...prev,
        ...aguardandoPiQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aguardandoPiQuery]);

  useEffect(() => {
    if (aguardandoAprovacaoQuery.loading) return;

    if (paginaAguardandoAprovacao === 0)
      setAguardandoAprovacao(aguardandoAprovacaoQuery?.data?.propostas?.content || []);
    else
      setAguardandoAprovacao((prev) => [
        ...prev,
        ...aguardandoAprovacaoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aguardandoAprovacaoQuery]);

  useEffect(() => {
    if (aprovadoQuery.loading) return;

    if (paginaAprovado === 0)
      setAprovado(aprovadoQuery?.data?.propostas?.content || []);
    else
      setAprovado((prev) => [
        ...prev,
        ...aprovadoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aprovadoQuery]);

  useEffect(() => {
    if (reprovadoQuery.loading) return;

    if (paginaReprovado === 0)
      setReprovado(reprovadoQuery?.data?.propostas?.content || []);
    else
      setReprovado((prev) => [
        ...prev,
        ...reprovadoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reprovadoQuery]);
  useEffect(() => {
    setData({
      lanes: [
        {
          id: optionSituacao[0].value || '',
          label: optionSituacao[0].label,
          total: prospeccaoQuery.data?.propostas?.totalElements || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
          },
          titleStyle: {
            borderBottom: '2px solid #007BE1',
            width: '240px',
            margin: '0px 15px 16px 15px',
          },
          cards: prospeccao?.map((item, index) => {
            return {
              id: item?.id || '',
              data: item,
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[1].value || '',
          label: optionSituacao[1].label,
          total: negociacaoQuery?.data?.propostas?.totalElements || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
            pointerEvent: negociacaoQuery.loading ? 'none' : 'all',
          },
          titleStyle: {
            borderBottom: '2px solid #2300F9',
            width: '240px',
            margin: '0px 15px 16px 15px',
          },
          cards: negociacao.map((item, index) => {
            return {
              id: item?.id || '',
              data: item,
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[2].value || '',
          label: optionSituacao[2].label,
          total: negociacaoAvancadaQuery?.data?.propostas?.totalElements || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
            pointerEvent: negociacaoAvancadaQuery.loading ? 'none' : 'all',
          },
          titleStyle: {
            borderBottom: '2px solid #2300F9',
            width: '240px',
            margin: '0px 15px 16px 15px',
          },
          cards: negociacaoAvancada.map((item, index) => {
            return {
              id: item?.id || '',
              data: item,
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[4].value || '',
          label: optionSituacao[4].label,
          total: aguardandoAprovacaoQuery?.data?.propostas?.totalElements || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
            pointerEvent: aguardandoAprovacaoQuery.loading ? 'none' : 'all',
          },
          titleStyle: {
            borderBottom: '2px solid #2300F9',
            width: '240px',
            margin: '0px 15px 16px 15px',
            maxWidth: '100%',
            whiteSpace: 'break-spaces',
          },
          labelTitleStyle: {
            lineHeight: '18px'
          },
          cards: aguardandoAprovacao.map((item, index) => {
            return {
              id: item?.id || '',
              data: item,
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[5].value || '',
          label: optionSituacao[5].label,
          total: aprovadoQuery.data?.propostas?.totalElements || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
            pointerEvent: aprovadoQuery.loading ? 'none' : 'all',
          },
          titleStyle: {
            borderBottom: '2px solid #25E18A',
            width: '240px',
            margin: '0px 15px 16px 15px',
          },
          cards: aprovado.map((item, index) => {
            return {
              id: item?.id || '',
              data: item,
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[6].value || '',
          label: optionSituacao[6].label,
          total: reprovadoQuery.data?.propostas?.totalElements || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
          },
          titleStyle: {
            borderBottom: '2px solid #FF5252',
            width: '240px',
            margin: '0px 15px 16px 15px',
          },
          cards: reprovado.map((item, index) => {
            return {
              id: item?.id || '',
              data: item,
              token: token,
            };
          }),
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prospeccao,
    negociacao,
    negociacaoAvancada,
    aguardandoPi,
    aprovado,
    reprovado,
  ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container sx={{paddingBottom: 0}}>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Select
                  label="Período"
                  value={filtros.periodo}
                  onChange={(e) =>
                    setFiltros({...filtros, periodo: e || null})
                  }
                  options={[
                    {label: 'Todas'},
                    {value: 30, label: '30 dias'},
                    {value: 60, label: '60 dias'},
                    {value: 90, label: '90 dias'},
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectAnunciantes}
                  label="Anunciante"
                  name="anunciante"
                  onChange={(e) =>
                    setFiltros({...filtros, anunciante: e || null})
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectComercial}
                  label="Comercial"
                  name="comercial"
                  onChange={(e) => setFiltros({...filtros, comercial: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={SelectCidades}
                  label="Cidade"
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  sx={styles.button}
                  value={'Filtrar'}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12} sx={styles.funilContainer}>
        <Board
          className={classes.containerBoard}
          draggable={false}
          laneDraggable={false}
          cardDraggable={false}
          cardDragClass={classes.cardDrag}
          onCardClick={(cardId) => navigate(`${cardId}/editar`)}
          onLaneScroll={handlePaginacao}
          hideCardDeleteIcon
          data={data}
          components={components}
        />
        <Grid container sx={styles.loadingContainer}>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {prospeccaoQuery.loading && (
              <CircularProgress width={30} height={30} />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {negociacaoQuery.loading && (
              <CircularProgress width={30} height={30} />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {negociacaoAvancadaQuery.loading && (
              <CircularProgress width={30} height={30} />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {aguardandoPiQuery.loading && (
              <CircularProgress width={30} height={30} />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {aprovadoQuery.loading && (
              <CircularProgress width={30} height={30} />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {reprovadoQuery.loading && (
              <CircularProgress width={30} height={30} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  containerProposta: {
    position: 'relative',
    padding: ' 0px',
    overflow: 'hidden',
    minHeight: 'calc(100vh - 61px)',
    height: '100%',
  },
  filtroContainer: {
    marginBottom: 15,
    backgroundColor: '#fff',
    padding: '20px 15px',
    borderRadius: '6px',

    '& .button': {
      marginTop: 24,
    },
    '& .react-datepicker-popper': {
      zIndex: 9,
    },
    '& .input-filtro': {
      marginBottom: 0,
    },
    '& .select': {
      marginBottom: 0,

      '& > div': {
        marginBottom: 0,
      },
    },
    '& .react-select__menu': {
      zIndex: 999,
    },
  },
  rowFiltroContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    maxWidth: '185px',
    minWidth: '90px !important',
  },
  loadingContainer: {
    position: 'aboslute',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 0,
    top: -4,
    minWidth: '1270px',
  },
  loadingIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '20%',
    marginTop: '-20%',
  },
  filtrosContainer: {
    maxHeight: '100px',
    minHeight: '100px'
  },
  funilContainer: {
    position: 'relative',
    overflowX: 'auto',
    height: theme => `calc(100vh - 88px - ${theme.toolbarHeight})`,
    paddingTop: '8px'
  }
};

const useStyles = makeStyles(() => ({
  cardDrag: {
    boxShadow: '3px 4px 13px rgba(0, 131, 230, 0.5)',
  },
  containerBoard: {
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
    height: '100%',
    minWidth: '1800px',
    padding: '0 15px !important',
    overflowY: 'visible !important',
    display: 'grid',

    '& .react-trello-lane ': {
      maxHeight: 'calc(100vh - 173px) !important',
      display: 'flex !important',
      margin: 0,
      width: '280px',
      alignItems: 'center',
      overflowY: 'auto',
      overflowX: 'hidden',

      '& >div': {
        '&::-webkit-scrollbar': {
          background: 'rgba(0, 0, 0, 0.0) !important',
          width: '0px',
        },
        '&:hover': {
          '&::-webkit-scrollbar': {
            background: 'rgba(0, 0, 0, 0.0) !important',
            minWidth: '6px !important',
            margin: '-16px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '20px',
            backgroundColor: '#dddfe0',
          },
        },
      },
    },
    '& .react-trello-card ': {
      minWidth: '240px',
      padding: '20px',
      borderRadius: '12px',
      margin: '0px 13px 16px 13px',

      '& >Header': {
        border: 'none',
        paddingBottom: '0px',
        marginBottom: '0px',
      },
    },
    '& .smooth-dnd-container *': {
      '& >article': {
        '&:hover': {
          boxShadow: ' 3px 4px 13px rgba(0, 131, 230, 0.5)',
        },
      },
    },
    '& .smooth-dnd-container.horizontal': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
    '& .smooth-dnd-container.vertical': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      minHeight: '55vh',
    },
  },
}));

export default ConsultaProposta;
