import React, {useState, useEffect, useRef} from 'react';
import {Paper, Grid} from '@mui/material';
import {useLazyQuery} from '@apollo/client';
import {Formik} from 'formik';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import {ButtonComponent} from '../../../components/mui-button';
import InputDate from '../../../components/mui-input-date';
import {FIND_ALL_MOTORISTA_PAGOS} from '../../../graphql/queries';
import ImpressaoRelatorioMotoristasPagos from '../../../components/impressao/impressao-relatorio-motoristas-pagos';
import inputsEvents from '../../../utils/inputsEvents';
import Data from '../../../utils/data';
const pageStyle = `
@page { 
  size: A4; 
  margin: 5mm 10mm 5mm 10mm; 
}

@media all {
  .page-footer {
    display: block !important;
  }
}
`;

const ConsultaMotoristasPagos = () => {
  const componentRef = useRef();
  const printButtonRef = useRef();
  const [movimentacoes, setMovimentacoes] = useState(undefined);
  const [mesReferencia, setMesReferencia] = useState(moment().startOf('month').toDate());
  const [mesCompetencia, setMesCompetencia] = useState(null);

  const [loadMotoristas, motoristasQuery] = useLazyQuery(
    FIND_ALL_MOTORISTA_PAGOS,
  );

  useEffect(() => {
    if (!motoristasQuery.loading && !motoristasQuery.error)
      setMovimentacoes(motoristasQuery?.data?.movimentacoes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motoristasQuery]);

  useEffect(() => {
    if (movimentacoes && !motoristasQuery.loading && !motoristasQuery.error)
      printButtonRef.current.click();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movimentacoes]);

  const consultarMotoristas = () => {
    if (!mesReferencia && !mesCompetencia) return;

    const dataPagamentoInicio = Data.dataFormat(mesReferencia, 'YYYY-MM-DD') || undefined;
    const dataInicial = Data.dataFormat(mesCompetencia, 'YYYY-MM-DD') || undefined;

    loadMotoristas({
      variables: {
        searchDTO: {
          dataInicial,
          dataPagamentoInicio
        }
      },
    });
  };

  return (
    <Grid id="page-container" container>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - ${mesReferencia
          ? moment(mesReferencia).format('DD/MM/YYYY')
          : 'Pagamentos'
          }`}
        trigger={() => (
          <button ref={printButtonRef} style={{display: 'none'}} />
        )}
        content={() => componentRef.current}
      />
      <Grid item xs={12}>
        <Formik enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={2} onKeyUp={({key}) => inputsEvents.handleKeyUp(key, consultarMotoristas)}>
                <InputDate
                  label="Período de pagamento"
                  selected={mesReferencia}
                  dateFormat="MMMM/yyyy"
                  showMonthYearPicker
                  isClearable
                  onChange={setMesReferencia}
                />
              </Grid>
              <Grid item xs={2} onKeyUp={({key}) => inputsEvents.handleKeyUp(key, consultarMotoristas)}>
                <InputDate
                  label="Período de competência"
                  selected={mesCompetencia}
                  dateFormat="MMMM/yyyy"
                  showMonthYearPicker
                  isClearable
                  onChange={setMesCompetencia}
                />
              </Grid>
              <Grid item xs={2} ml="auto">
                <ButtonComponent
                  value="Gerar relatório"
                  loading={motoristasQuery.loading}
                  onClick={consultarMotoristas}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid overflow="hidden" height="0px">
        <Grid ref={componentRef} background="#fff">
          <ImpressaoRelatorioMotoristasPagos
            movimentacoes={movimentacoes}
            periodo={mesReferencia}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaMotoristasPagos;
