import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import ConsultaInstalador from './consulta';
import EditarInstalador from './editar';
import CadastrarInstalador from './cadastrar';

const Instalador = () => {
  const {role} = useSelector((store) => store.Auth);
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaInstalador role={role} />} />
      <Route path={`/:instaladorId/editar`} element={<EditarInstalador role={role} />} />
      <Route path={`/criar`} element={<CadastrarInstalador role={role} />} />
    </Routes>
  );
};

export default Instalador;
