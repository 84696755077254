import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {LoadingPage} from '../../components/loading-page';
import {FIND_BY_ID_ORCAMENTO} from '../../graphql/queries';
import FormularioPedido from './formulario';

const EditarPedido = () => {
  const {pedidoId} = useParams();

  const pedidoQuery = useQuery(FIND_BY_ID_ORCAMENTO, {
    variables: {
      id: pedidoId,
    },
  });

  const pedido = pedidoQuery?.data?.orcamento;

  const refetch = () => {
    pedidoQuery.refetch();
  };

  if (pedidoQuery.loading || pedidoQuery.error) {
    return (
      <LoadingPage loading={pedidoQuery.loading} error={pedidoQuery.error} />
    );
  }
  return (
    <FormularioPedido pedido={pedido} refetch={refetch} />
  );
};

export default EditarPedido;
