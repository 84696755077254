import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {persistAnunciosAguardandoInstalacao} from '../../redux/AnunciosAguardandoInstalacao/slice';
import {FIND_ALL_CIDADES_CAMPANHA} from '../../graphql/queries';
import TableCellOrdered from '../../components/table-cell-ordered';
import {Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Grid, Box, TableContainer} from '@mui/material';

const columns = [
  {
    id: 'anunciante', label: 'Anunciante', campo: 'campanha.anunciante.nomeFantasia',
    format: ({anunciante}) => anunciante?.nomeFantasia
  },
  {
    id: 'titulo', label: 'Campanha', campo: 'campanha.titulo'
  },
  {
    id: 'cidade', label: 'Cidade', campo: 'cidade.nome',
    format: ({cidade}) => cidade.nomeEUf || ''
  },
  {
    id: 'modeloAnuncio', label: 'Modelo', campo: 'modeloAnuncio.nome',
    format: ({modeloAnuncio}) => modeloAnuncio?.nome
  },
  {
    id: 'anuncios', label: 'Pendentes',
    format: (campanha) => {
      const pendentes = campanha?.anuncios?.filter(a => ['AGUARDANDO_INSTALACAO', 'AGUARDANDO_ACEITE_MOTORISTA', 'AGUARDANDO_AGENDAMENTO_INSTALACAO', 'AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO'].includes(a.situacao)).length;
      return `${pendentes}/${campanha.quantidadeVeiculos}`
    }
  },
];

const searchDTODefault = {
  anuncioSituacaoIn: ['AGUARDANDO_INSTALACAO', 'AGUARDANDO_ACEITE_MOTORISTA', 'AGUARDANDO_AGENDAMENTO_INSTALACAO', 'AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO'],
};

const ConsultaCampanhas = ({pageSize = 20}) => {
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState({campo: 'campanha.titulo', asc: true})
  const [rowsPerPage] = useState(10);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    anunciosAguardandoInstalacao,
    totalAnunciosAguardandoInstalacao,
    refetchAnunciosAguardandoInstalacao
  } = useSelector(store => store.AnunciosAguardandoInstalacao);

  const campanhasQuery = useQuery(FIND_ALL_CIDADES_CAMPANHA, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        ...searchDTODefault
      }
    }
  });

  useEffect(() => {
    if (!campanhasQuery.loading && !campanhasQuery.error) {
      dispatch(persistAnunciosAguardandoInstalacao(campanhasQuery.data.cidades));
    }

    if (refetchAnunciosAguardandoInstalacao) {
      campanhasQuery.refetch();
    }
  }, [campanhasQuery, dispatch, refetchAnunciosAguardandoInstalacao]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {anunciosAguardandoInstalacao.map(row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => navigate(`${row.id}/anuncios`)}
                      sx={{cursor: 'pointer'}}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={index} align={column.align}>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalAnunciosAguardandoInstalacao}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page'
            }}
            nextIconButtonProps={{
              'aria-label': 'next page'
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ConsultaCampanhas;
