import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  emailContatoList: [],
  hasMore: false,
  totalEmailContato: 0,
  refetchEmailContato: false
};

const acaoAtualizarEmailContato = state => {
  state.refetchEmailContato = true;
};

const acaoPersistEmailContato = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.emailContatoList = content;
  state.hasMore = !last;
  state.totalEmailContato = totalElements || 0;
};

const resumeSlice = createSlice({
  name: 'emailContato',
  initialState: initialState,
  reducers: {
    persistEmailContato: acaoPersistEmailContato,
    atualizarEmailContato: acaoAtualizarEmailContato
  }
});

export const { actions, reducer } = resumeSlice;
export const { persistEmailContato, atualizarEmailContato } = actions;
