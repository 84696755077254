import {useMutation, useQuery} from '@apollo/client';
import {
  Add,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {ReactComponent as BuscaNaoEncontrada} from '../../../assets/images/busca-nao-encontrada.svg';
import {confirmAlertMui} from '../../../components/confirm-alert/mui-confirm-alert';
import {
  ButtonComponent,
  RoundTooltipButton,
} from '../../../components/mui-button';
import {toastNotification} from '../../../components/toastify';
import {EXCLUIR_COMERCIAL_METAS} from '../../../graphql/mutation';
import {FIND_ALL_COMERCIAL_METAS} from '../../../graphql/queries';
import Number from '../../../utils/number';
import ModalMetasVenda from '../modais/modal-metas-venda';

const FormularioMetasVendas = ({comercial = {}}) => {
  const [metas, setMetas] = useState([]);
  const [selecionarMeta, setSelecionarMeta] = useState({});

  const [openModal, setOpenModal] = useState(false);

  const metasQuery = useQuery(FIND_ALL_COMERCIAL_METAS, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: -1,
        orderBy: [
          {
            campo: 'ano',
            ordem: 'DESC',
          },
          {
            campo: 'metaCidade.cidade.nome',
            ordem: 'ASC',
          },
        ],
      },
      searchDTO: {
        comercialId: comercial?.id,
      },
    },
  });

  useEffect(() => {
    if (metasQuery.loading) return;
    setMetas(metasQuery.data?.metas?.content || []);
  }, [metasQuery]);

  const handleClickButtonEditar = (meta) => {
    setOpenModal(true);
    setSelecionarMeta(meta);
  };

  const handleClickCLose = () => {
    setOpenModal(false);
    setSelecionarMeta({});
  };

  if (metasQuery.loading) {
    return (
      <Grid container>
        <Stack
          direction="column"
          spacing={3}
          alignItems="center"
          justifyContent="center"
          sx={{width: '100%', height: 'calc( 100vh - 240px )'}}>
          <CircularProgress />
        </Stack>
      </Grid>
    );
  }

  return (
    <Box paddingTop="16px">
      <Button
        startIcon={<Add style={{marginRight: 10}} />}
        id="button-green"
        toolbar="true"
        onClick={() => setOpenModal(true)}>
        Cadastrar novo
      </Button>
      {metas.length > 0 && (
        <Paper>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              {metas.map((item, index) => (
                <TablecCollapse
                  meta={item}
                  openModal={() => handleClickButtonEditar(item)}
                  refetch={() => metasQuery.refetch()}
                  key={index}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
      {metas.length === 0 && (
        <Stack
          direction="column"
          spacing={3}
          alignItems="center"
          justifyContent="center"
          sx={{width: '100%', height: 'calc( 100vh - 240px )'}}>
          <BuscaNaoEncontrada width="50%" height="50%" />
          <Stack direction="column" spacing={1} alignItems="center">
            <Typography
              fontFamily="fontFamily.patternBold"
              fontSize="18px"
              color="#2e3a52">
              Nenhuma meta encontrada!
            </Typography>
          </Stack>
          <ButtonComponent
            icon={<Add style={{marginRight: 10}} />}
            id="button-green"
            onClick={() => setOpenModal(true)}
            sx={{width: '256px'}}
            value="Cadastrar novo"
          />
        </Stack>
      )}
      <ModalMetasVenda
        openModal={openModal}
        onClose={handleClickCLose}
        comercial={comercial}
        meta={selecionarMeta}
        refetch={metasQuery.refetch()}
      />
    </Box>
  );
};

export default FormularioMetasVendas;

const TablecCollapse = ({meta, index, openModal, refetch}) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [excluirComercialMetas] = useMutation(EXCLUIR_COMERCIAL_METAS);
  const handleClickExcluirMeta = () => {
    excluirComercialMetas({
      variables: {
        metas: {
          id: meta.id,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'Metas excluídas com sucesso!',
          type: 'success',
        });
        refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleClickOpenModalEditar = (event) => {
    event.stopPropagation();
    openModal();
  };
  const handleClickOpenModalExcluir = (event) => {
    event.stopPropagation();
    confirmAlertMui({
      title: 'Deseja deletar esta meta?',
      onConfirm: handleClickExcluirMeta,
    });
  };
  const primeiroTrimestre = ['janeiro', 'fevereiro', 'marco'];
  const segundoTrimestre = ['abril', 'maio', 'junho'];
  const terceiroTrimestre = ['julho', 'agosto', 'setembro'];
  const quartoTrimestre = ['outubro', 'novembro', 'dezembro'];

  const totalPrimeiroTrimestre =
    meta[primeiroTrimestre[0]] +
    meta[primeiroTrimestre[1]] +
    meta[primeiroTrimestre[2]];
  const totalSegundoTrimestre =
    meta[segundoTrimestre[0]] +
    meta[segundoTrimestre[1]] +
    meta[segundoTrimestre[2]];
  const totalTerceiroTrimestre =
    meta[terceiroTrimestre[0]] +
    meta[terceiroTrimestre[1]] +
    meta[terceiroTrimestre[2]];
  const totalQuartoTrimestre =
    meta[quartoTrimestre[0]] +
    meta[quartoTrimestre[1]] +
    meta[quartoTrimestre[2]];
  const anoTotal =
    totalPrimeiroTrimestre +
    totalSegundoTrimestre +
    totalTerceiroTrimestre +
    totalQuartoTrimestre;
  return (
    <React.Fragment key={meta.id}>
      <TableRow
        hover
        sx={styles.collapsableHead}
        role="checkbox"
        tabIndex={-1}
        onClick={() => setOpenCollapse(!openCollapse)}>
        <TableCell sx={{width: '50px'}}>
          {' '}
          <RoundTooltipButton
            onClick={() => setOpenCollapse(!openCollapse)}
            title={'Ver metas'}
            icon={openCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          />
        </TableCell>
        <TableCell align="left">
          <Typography fontFamily="fontFamily.patternBold">
            {meta.ano} - {meta.metaCidade?.cidade?.nomeEUf} - Total:{' '}
            {Number.currencyFormat(anoTotal)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <RoundTooltipButton
            id="icon-primary"
            title={'Editar meta'}
            icon={<Edit />}
            onClick={(e) => handleClickOpenModalEditar(e)}
          />
          <RoundTooltipButton
            id="icon-cancel"
            onClick={(e) => handleClickOpenModalExcluir(e)}
            title={'Excluir meta'}
            icon={<Delete />}
          />
        </TableCell>
      </TableRow>
      <TableRow sx={{backgroundColor: '#fff "important'}}>
        <TableCell style={{padding: 0}} colSpan={6}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box
              sx={{
                width: '100%',
                background: 'rgb(255 255 255 / 60%)',
                padding: '0px 20px 10px 20px',
              }}>
              <Stack direction="column">
                <InfoMetas
                  array={primeiroTrimestre}
                  total={totalPrimeiroTrimestre}
                  meta={meta}
                  trimestre="1º"
                />
                <InfoMetas
                  array={segundoTrimestre}
                  total={totalSegundoTrimestre}
                  meta={meta}
                  trimestre="2º"
                />
                <InfoMetas
                  array={terceiroTrimestre}
                  total={totalTerceiroTrimestre}
                  meta={meta}
                  trimestre="3º"
                />
                <InfoMetas
                  array={quartoTrimestre}
                  total={totalQuartoTrimestre}
                  meta={meta}
                  trimestre="4º"
                  v
                />
                <Divider sx={{width: '100%'}} />
                <Grid container justifyContent="space-between">
                  <Grid item xs={3} sx={{padding: '10px'}}>
                    <Typography
                      fontFamily="fontFamily.patternBold"
                      sx={{width: '100px'}}>
                      Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{padding: '10px'}}>
                    <Typography fontFamily="fontFamily.patternBold">
                      {Number.currencyFormat(anoTotal)}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const InfoMetas = ({array, meta, total, trimestre}) => {
  const correctLabel = (item) => {
    if (item === 'marco') {
      return 'março';
    }
    return item;
  };
  return (
    <Stack direction="column" sx={{margin: '20px 0px'}} spacing={2}>
      <Grid container>
        {array.map((item, index) => (
          <Grid item xs={3} padding={'10px'} key={index}>
            <Typography
              fontFamily="fontFamily.patternMedium"
              spacing={4}
              sx={{
                width: '100px',
                marginRight: '340px',
                textTransform: 'capitalize',
              }}
              key={index}>
              {correctLabel(item)}
            </Typography>
          </Grid>
        ))}
        <Grid item xs={1}>
          <Divider orientation="vertical" sx={{height: '100%'}} />
        </Grid>

        <Grid item xs={2} padding={'10px'}>
          <Typography
            fontFamily="fontFamily.patternBold"
            sx={{width: '100px', marginRight: '340px'}}>
            {trimestre} trimestre
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{width: '100%'}} />
        </Grid>

        {array.map((item, index) => (
          <Grid item xs={3} padding={'10px'} key={index}>
            <Typography fontFamily="fontFamily.pattern">
              {Number.currencyFormat(meta[item])}
            </Typography>
          </Grid>
        ))}
        <Grid item xs={1}>
          <Divider orientation="vertical" sx={{height: '100%'}} />
        </Grid>

        <Grid item xs={2} padding={'10px'}>
          <Typography fontFamily="fontFamily.patternBold">
            {Number.currencyFormat(total)}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};

const styles = {
  collapsableHead: {
    '& > *': {borderBottom: 'unset'},
    '&:hover': {
      backgroundColor: 'rgb(213 213 213 / 10%) !important',
    },
  },
};
