import React, {useState, useEffect, useRef} from 'react';
import {useQuery} from '@apollo/client';
import {Formik, Field} from 'formik';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Box
} from '@mui/material';
import {PrintOutlined} from '@mui/icons-material';
import {CONSULTA_RESULTADO_CAMPANHAS} from '../../../graphql/queries';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import {RoundTooltipButton, ButtonComponent} from '../../../components/mui-button';
import {SelectEstados} from '../../../containers/selects/mui-estados';
import Number from '../../../utils/number';
import InputDate from '../../../components/mui-input-date';
import ImpressaoResultadoCampanhas from '../../../components/impressao/impressao-resultado-campanhas';

const pageStyle = `
  @page {
    size: A4;
    margin: 5mm 10mm 5mm 10mm;
  }

  @media all {
    .page-footer {
      display: block !important;
    }
  }
`;

const columns = [
  {
    id: 'campanhas', label: 'Campanhas',
    format: value => Number.format(value)
  },
  {
    id: 'cidades', label: 'Cidades',
    format: value => Number.format(value)
  },
  {
    id: 'impactos', label: 'Impactos',
    format: value => Number.format(value)
  },
  {
    id: 'passageiros', label: 'Passageiros',
    format: value => Number.format(value)
  },
  {
    id: 'kmRodado', label: 'Km rodado',
    format: value => `${Number.formatQuilometro(value)} km`
  },
];

const ConsultaResultadoCampanhas = () => {
  const componentRef = useRef();

  const [filtros, setFiltros] = useState({
    mesInicio: moment().startOf('month').toDate(),
    mesFim: moment().endOf('month').toDate(),
  });
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [resultado, setResultado] = useState([]);
  const [loading, setLoading] = useState(false);

  const resultadoQuery = useQuery(CONSULTA_RESULTADO_CAMPANHAS, {
    variables: {
      searchDTO: {
        estadoId: searchDTO.estado?.value,
        cidadeId: searchDTO.cidade?.value,
        dataInicial: searchDTO.mesInicio
          ? moment(searchDTO.mesInicio).startOf('month').format('YYYY-MM-DD')
          : undefined,
        dataFinal: searchDTO.mesFim
          ? moment(searchDTO.mesFim).endOf('month').format('YYYY-MM-DD')
          : undefined,
        situacao: 'FINALIZADA'
      },
    },
  });

  useEffect(() => {
    if (resultadoQuery.loading || resultadoQuery.errors) return;

    setResultado(resultadoQuery.data?.resultado || {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultadoQuery]);

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const refetch = () => {
    setSearchDTO(filtros);
  }

  return (
    <Grid id="page-container" container>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Resultado das campanhas`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() => (
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintOutlined />}
            loading={loading}
            loadingColor="grey"
          />
        )}
        content={() => componentRef.current}
      />
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  label="Estado"
                  name="estado"
                  className="select"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.value}}
                      {...props}
                    />
                  )}
                  label="Cidade"
                  name="cidade"
                  className="select"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                />
              </Grid>
              <Grid item xs={3}>
                <InputDate
                  label="Período"
                  selected={filtros.mesInicio}
                  dateFormat="MMMM/yyyy"
                  showMonthYearPicker
                  selectsRange
                  startDate={filtros.mesInicio}
                  endDate={filtros.mesFim}
                  onChange={(e) => {
                    const [start, end] = e;
                    setFiltros((prev) => ({
                      ...prev,
                      mesInicio: start,
                      mesFim: end,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={2} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  value="Filtrar"
                  loading={resultado.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key="1"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    {columns.map((column, index) => {
                      const value = resultado[column.id];
                      return (
                        <TableCell key={index} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
      <Box overflow="hidden" height="0px">
        <Box ref={componentRef} background="#fff">
          <ImpressaoResultadoCampanhas resultado={resultado} dataInicio={filtros.mesInicio} dataFim={filtros.mesFim} />
        </Box>
      </Box>
    </Grid>
  );
};

export default ConsultaResultadoCampanhas;
