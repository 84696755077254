import React, {useEffect, useState} from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from '@mui/material';
import {useQuery} from '@apollo/client';
import {makeStyles} from '@mui/styles';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import {Collapse} from '@mui/material';
import moment from 'moment';
import {
  FIND_ALL_ANUNCIOS_TROCAS_MOTORISTA,
  FIND_ALL_TROCAS_MOTORISTA_BY_ANUNCIO,
} from '../../graphql/queries';

const Divisor = require('../../assets/images/divisor-relatorio.png');

function RowSecundary({anuncioTrocaItem}) {
  const [motorista, setMotorista] = useState([]);

  const trocasMotoristaByAnuncioQuery = useQuery(
    FIND_ALL_TROCAS_MOTORISTA_BY_ANUNCIO,
    {
      variables: {
        anuncio: {
          id: anuncioTrocaItem?.anuncioId || undefined,
        },
      },
    },
  );
  useEffect(() => {
    if (trocasMotoristaByAnuncioQuery.loading) return;
    setMotorista(
      trocasMotoristaByAnuncioQuery?.data?.findAllTrocasMotoristaByAnuncio ||
        [],
    );
  }, [trocasMotoristaByAnuncioQuery]);

  return (
    <React.Fragment key={anuncioTrocaItem.anuncioId}>
      <TableRow tabIndex={-1}>
        <TableCell colSpan={2}>{anuncioTrocaItem.motoristaNome}</TableCell>
        <TableCell>
          {moment(anuncioTrocaItem.dataTroca).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>{anuncioTrocaItem.numeroTrocas}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7}>
          <Collapse in={true}>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Motorista origem</TableCell>
                    <TableCell>Motorista destino</TableCell>
                    <TableCell>Situação na troca</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Motorista destino instalou?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {motorista.map((item, index) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={index}
                        sx={{
                          borderBottom: 'none !important',
                          height: '90px',
                          background: '#fff',
                        }}>
                        <TableCell>{item.motoristaOrigem?.nome}</TableCell>
                        <TableCell>{item.motoristaDestino?.nome}</TableCell>
                        <TableCell>{item.anuncioSituacao}</TableCell>
                        <TableCell>
                          {moment(item.dataTroca).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          {item.motoristaDestinoInstalado ? 'Sim' : 'Não'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function RowPrimary({campanhaTrocaItem}) {
  const [anuncioTroca, setAnuncioTroca] = useState([]);

  const classes = useStyles();
  const anunciosTrocasMotoristaQuery = useQuery(
    FIND_ALL_ANUNCIOS_TROCAS_MOTORISTA,
    {
      variables: {
        campanhaId: campanhaTrocaItem.campanhaId || null,
      },
    },
  );
  useEffect(() => {
    if (anunciosTrocasMotoristaQuery.loading) return;

    setAnuncioTroca(
      anunciosTrocasMotoristaQuery?.data?.findAllAnunciosTrocasMotorista || [],
    );
  }, [anunciosTrocasMotoristaQuery]);

  return (
    <React.Fragment key={campanhaTrocaItem.campanhaId}>
      <TableRow tabIndex={-1} className={classes.tableRow}>
        <TableCell>{campanhaTrocaItem.tituloCampanha}</TableCell>
        <TableCell>
          {campanhaTrocaItem.nomeCidade}/{campanhaTrocaItem.ufCidade}
        </TableCell>
        <TableCell>
          {moment(campanhaTrocaItem.dataTroca).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>{campanhaTrocaItem.numeroTrocas}</TableCell>
      </TableRow>
      <TableRow className={classes.tableRowPrincipal}>
        <TableCell colSpan={7}>
          <Collapse in={true}>
            <Box sx={{margin: '15px 0px'}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Motorista atual</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Trocas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anuncioTroca.map((item, index) => (
                    <RowSecundary anuncioTrocaItem={item} key={index} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ImpressaoRelatorioMotoristasSubstituidos = ({campanhaTroca}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.reportContainer}>
      <Grid item xs={12} style={{margin: '0 10px'}}>
        <img
          alt={'divisor'}
          style={{width: '100%', margin: '10px 0'}}
          src={Divisor}
        />
      </Grid>
      <Grid item xs={12} className={classes.reportHeader}>
        <Grid item xs={10}>
          <Typography
            className={classes.titleReport}
            style={{fontWeight: '700'}}>
            Motoristas Substituídos
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <KmMidiaLogo
            style={{width: '90%', height: '30px', marginBottom: 10}}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table stickyHeader aria-label="sticky table">
        <TableHead>
                <TableRow>
                  <TableCell>Campanha</TableCell>
                  <TableCell>Cidade</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Trocas</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
          {campanhaTroca.map((item, index) => (
                <RowPrimary campanhaTrocaItem={item} key={index} />
          ))}
           </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(() => ({
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  table: {
    paddingBottom: 100,
    breakInside: 'avoid',
    marginBottom: '50px',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 18,
    color: '#031663',
  },
  tableHead: {
    fontSize: '15px',
    color: '#000',
    fontWeight: '700',
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    height: '125px',
    top: '980px',
  },
  tableFooter: {
    cursor: 'default',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#000',
    zIndex: 999,
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 18,
    color: '#000',
    textAlign: 'center',
    fontWeight: '700',
  },
  footerLogo: {
    height: '20px',
    width: '120px',
    margin: '9px 20px 0px -5px',
  },
  tableRowPrincipal: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      paddingBottom: 5,
      fontSize: '12px',
    },
  },
  tableRow: {
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #E0E0E0',
      paddingBottom: 5,
      fontSize: '12px',
    },
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  footerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ' space-between',
    marginRight: '10px',
  },
  footerTextRodape: {
    fontSize: '11px',
  },
}));
export default ImpressaoRelatorioMotoristasSubstituidos;
