import React, {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {Grid, Typography, Box} from '@mui/material';
import {Save, Edit, Check, CameraAlt} from '@mui/icons-material';
import {ButtonComponent} from '../../../components/mui-button';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {TextAreaV2} from '../../../components/textarea/mui-textarea';
import motoristaStyles from '../styles';
import File from '../../../utils/file';
import {
  REPROVA_DOCUMENTO_VEICULO,
  REPROVA_IMAGENS_VEICULO,
  UPDATE_PERMITE_ALTERAR_VEICULO,
  UPDATE_VEICULO_MOTORISTA,
} from '../../../graphql/mutation';
import {toastNotification} from '../../../components/toastify';
import Select from '../../../components/select/mui-select';
import String from '../../../utils/string';
import {confirmAlertMui} from '../../../components/confirm-alert/mui-confirm-alert';

const DadosVeiculo = ({motorista = {}, alteracoes, handleClickImage, role}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [veiculoAtual, setVeiculoAtual] = useState({});
  const [fotoFrente, setFotoFrente] = useState(undefined);
  const [fotoTraseira, setFotoTraseira] = useState(undefined);
  const [fotoLateral, setFotoLateral] = useState(undefined);
  const [fotoDocumento, setFotoDocumento] = useState(undefined);
  const [aprovouFotoVeiculo, setAprovouFotoVeiculo] = useState(undefined);
  const [obsFotoVeiculo, setObsFotoVeiculo] = useState('');
  const [aprovouFotoDocumento, setAprovouFotoDocumento] = useState(undefined);
  const [obsFotoDocumento, setObsFotoDocumento] = useState('');
  const [rejeitandoDocumento, setRejeitandoDocumento] = useState(false);
  const [rejeitandoVeiculo, setRejeitandoVeiculo] = useState(false);

  const [reprovaImagens] = useMutation(REPROVA_IMAGENS_VEICULO);
  const [reprovaDocumento] = useMutation(REPROVA_DOCUMENTO_VEICULO);
  const [updateVeiculo, {loading: loadingUpdate}] = useMutation(UPDATE_VEICULO_MOTORISTA);
  const [updateAlterarVeiculo, {loading: loadingUpdateDados}] = useMutation(UPDATE_PERMITE_ALTERAR_VEICULO);

  useEffect(() => {
    if (!motorista.veiculoAtual) return;
    const veiculo = motorista.veiculoAtual;

    setVeiculoAtual({
      ...veiculo,
      pinturaOriginal:
        veiculo.pinturaOriginal === true
          ? 'Sim'
          : veiculo.pinturaOriginal === false
            ? 'Não'
            : '',
      possuiBorrachao: {
        value: veiculo.possuiBorrachao,
        label:
          veiculo.possuiBorrachao === true
            ? 'Sim'
            : veiculo.possuiBorrachao === false
              ? 'Não'
              : '',
      },
      tipoVeiculo: {
        value: veiculo.tipoVeiculo,
        label: String.capitalize(veiculo.tipoVeiculo),
      },
    });
  }, [motorista]);

  useEffect(() => {
    if (!veiculoAtual?.id) return;

    setFotoFrente(veiculoAtual.fotoFrente);
    setFotoTraseira(veiculoAtual.fotoTraseira);
    setFotoLateral(veiculoAtual.fotoLateral);
    setFotoDocumento(veiculoAtual.fotoDocumentoVeiculo);
    setAprovouFotoVeiculo(veiculoAtual.aprovouFotoVeiculo);
    setObsFotoVeiculo(veiculoAtual.obsFotoVeiculo || '');
    setAprovouFotoDocumento(veiculoAtual.aprovouFotoDocumentoVeiculo);
    setObsFotoDocumento(veiculoAtual.obsFotoDocumentoVeiculo || '');
  }, [veiculoAtual]);

  const handleClickReprovaImagens = () => {
    setRejeitandoVeiculo(true);
    reprovaImagens({
      variables: {
        obs: obsFotoVeiculo,
        veiculoId: motorista.veiculoAtual.id,
      },
    })
      .then(() => {
        toastNotification({
          message: 'As imagens foram reprovadas com sucesso!',
          type: 'success',
        });
        setAprovouFotoVeiculo(false);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setRejeitandoVeiculo(false));
  };

  const handleClickReprovaDocumento = () => {
    setRejeitandoDocumento(true);
    reprovaDocumento({
      variables: {
        obs: obsFotoDocumento,
        veiculoId: motorista.veiculoAtual.id,
      },
    })
      .then(() => {
        toastNotification({
          message: 'O documento do motorista foi reprovado com sucesso!',
          type: 'success',
        });
        setAprovouFotoDocumento(false);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setRejeitandoDocumento(false));
  };

  const handleUpdateVeiculo = () => {
    if (readOnly || !veiculoAtual?.id) return;

    updateVeiculo({
      variables: {
        veiculo: {
          id: veiculoAtual.id,
          tipoVeiculo: veiculoAtual.tipoVeiculo?.value || undefined,
          possuiBorrachao: veiculoAtual.possuiBorrachao?.value,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'Os dados cadastrados foram salvos!',
          type: 'success',
        });
        setReadOnly(true);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const isAtualizado = () => {
    const alterado = alteracoes
      ?.map((a) => a.veiculoAtual?.id !== veiculoAtual.id)
      ?.reduce((acc, atual) => acc || atual, false);
    return alterado ? {borderColor: '#00398E'} : null;
  };

  const isImagemAtualizada = (campo, entity, campoEntity) => {
    const alterado = alteracoes
      ?.map((a) => a.veiculoAtual[campo] !== entity[campoEntity || campo])
      ?.reduce((acc, atual) => acc || atual, false);
    return alterado ? {borderColor: '#00398E'} : null;
  };

  const handleClickHabilitarAlteracao = () => {
    confirmAlertMui({
      message: 'Permitir que o motorista altere os dados do veículo?',
      onConfirm: handleHabilitarAlteracao,
    });
  }

  const handleHabilitarAlteracao = () => {
    updateAlterarVeiculo({
      variables: {
        veiculoId: motorista.veiculoAtual.id
      }
    })
      .then(() => {
        toastNotification({
          message: 'Alteração habilitada!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  }

  const permiteAcoes = () => {
    return !['ROLE_GERENTE_COMERCIAL', 'ROLE_FINANCEIRO'].includes(role);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <InputV2
            label="Marca / Modelo"
            value={
              veiculoAtual?.modelo
                ? `${veiculoAtual.modelo.marca?.nome} / ${veiculoAtual.modelo.nome}`
                : ''
            }
            style={isAtualizado()}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputV2
            disabled
            label="Ano fabricação"
            value={veiculoAtual?.anoFabricacao || ''}
            style={isAtualizado()}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputV2
            disabled
            label="Ano modelo"
            value={veiculoAtual?.anoModelo || ''}
            style={isAtualizado()}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputV2
            disabled
            label="Placa"
            value={veiculoAtual?.placa}
            style={isAtualizado()}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputV2
            disabled
            label="Cor"
            value={veiculoAtual?.cor}
            style={isAtualizado()}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputV2
            disabled
            label="Pintura original"
            value={veiculoAtual?.pinturaOriginal}
            style={isAtualizado()}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Select
            label="Tipo de veículo"
            sx={styles.select}
            className={`select`}
            options={[
              {value: 'SEDAN', label: 'Sedan'},
              {value: 'HATCH', label: 'Hatch'},
            ]}
            value={veiculoAtual.tipoVeiculo}
            onChange={(e) => setVeiculoAtual((v) => ({...v, tipoVeiculo: e}))}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Select
            label="Possui borrachão"
            sx={styles.select}
            className={`select`}
            options={[
              {value: false, label: 'Não'},
              {value: true, label: 'Sim'},
            ]}
            value={veiculoAtual.possuiBorrachao}
            onChange={(e) =>
              setVeiculoAtual((v) => ({...v, possuiBorrachao: e}))
            }
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={3}>
          <Typography sx={styles.label}>Lateral esquerda</Typography>
          {fotoFrente ? (
            <div>
              <Box
                sx={styles.fotoContainer}
                style={isImagemAtualizada('fotoFrente', veiculoAtual)}>
                <img
                  style={styles.fotoImg}
                  src={File.getImageAuth(fotoFrente)}
                  alt="foto-frente"
                  onClick={() =>
                    handleClickImage(fotoFrente, 'Lateral esquerda')
                  }
                  onError={() => setFotoFrente(null)}
                />
              </Box>
            </div>
          ) : (
            <Box sx={styles.fotoContainer}>
              <CameraAlt />
              <p>Nenhuma foto disponível</p>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography sx={styles.label}>Lateral direita</Typography>
          {fotoLateral ? (
            <div>
              <Box
                sx={styles.fotoContainer}
                style={isImagemAtualizada('fotoLateral', veiculoAtual)}>
                <img
                  style={styles.fotoImg}
                  src={File.getImageAuth(fotoLateral)}
                  alt="foto-lateral"
                  onClick={() =>
                    handleClickImage(fotoLateral, 'Lateral direita')
                  }
                  onError={() => setFotoLateral(null)}
                />
              </Box>
            </div>
          ) : (
            <Box sx={styles.fotoContainer}>
              <CameraAlt />
              <p>Nenhuma foto disponível</p>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography sx={styles.label}>Traseira</Typography>
          {fotoTraseira ? (
            <div>
              <Box
                sx={styles.fotoContainer}
                style={isImagemAtualizada('fotoTraseira', veiculoAtual)}>
                <img
                  style={styles.fotoImg}
                  src={File.getImageAuth(fotoTraseira)}
                  alt="foto-traseira"
                  onClick={() => handleClickImage(fotoTraseira, 'Traseira')}
                  onError={() => setFotoTraseira(null)}
                />
              </Box>
            </div>
          ) : (
            <Box sx={styles.fotoContainer}>
              <CameraAlt />
              <p>Nenhuma foto disponível</p>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography sx={styles.label}>Documento do veículo</Typography>
          {fotoDocumento ? (
            <Grid
              sx={styles.fotoContainer}
              style={isImagemAtualizada('fotoDocumentoVeiculo', veiculoAtual)}>
              <img
                style={styles.fotoImg}
                src={File.getImageAuth(fotoDocumento)}
                alt="foto-documento-veiculo"
                onClick={() =>
                  handleClickImage(fotoDocumento, 'Documento do veículo')
                }
                onError={() => setFotoDocumento(null)}
              />
            </Grid>
          ) : (
            <Box sx={styles.fotoContainer}>
              <CameraAlt />
              <p>Nenhuma foto disponível</p>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          {motorista?.veiculoAtual?.id &&
            !aprovouFotoVeiculo &&
            motorista.situacao !== 'APROVADO' ? (
            <>
              <TextAreaV2
                label="Observação"
                placeholder="Digite uma observação para a rejeição"
                value={obsFotoVeiculo}
                onChange={(e) => {
                  setObsFotoVeiculo(e.target.value);
                  setAprovouFotoVeiculo(null);
                }}
              />
              {aprovouFotoVeiculo === null && (
                <ButtonComponent
                  id="button-cancel-empty"
                  value={'Reprovar'}
                  loading={rejeitandoVeiculo}
                  onClick={handleClickReprovaImagens}
                  disabled={rejeitandoVeiculo}
                />
              )}
              {aprovouFotoVeiculo === false && (
                <ButtonComponent
                  id="button-cancel-empty"
                  value="Reprovado"
                  disabled
                />
              )}
            </>
          ) : null}
        </Grid>
        <Grid item xs={12} md={3}>
          {motorista?.veiculoAtual?.id &&
            !aprovouFotoDocumento &&
            motorista.situacao !== 'APROVADO' ? (
            <>
              <TextAreaV2
                label={'Observação'}
                placeholder="Digite uma observação para a rejeição"
                value={obsFotoDocumento}
                onChange={(e) => {
                  setObsFotoDocumento(e.target.value);
                  setAprovouFotoDocumento(null);
                }}
              />
              {aprovouFotoDocumento === null && (
                <ButtonComponent
                  id="button-cancel-empty"
                  value={'Reprovar'}
                  onClick={handleClickReprovaDocumento}
                  disabled={rejeitandoDocumento}
                  loading={rejeitandoDocumento}
                />
              )}
              {aprovouFotoDocumento === false && (
                <ButtonComponent
                  id="button-cancel-empty"
                  value="Reprovado"
                  disabled
                />
              )}
            </>
          ) : null}
        </Grid>
      </Grid>
      {!['EXCLUIDO'].includes(motorista.situacao) && permiteAcoes() && (
        <Grid container mt="16px" item spacing={2} sx={styles.buttonContainer}>
          <Grid item sx={styles.buttonWrapper}>
            {motorista.anuncioAtivo && (
              <ButtonComponent
                type="button"
                value="Habilitar alteração"
                icon={<Check sx={styles.icon} />}
                onClick={handleClickHabilitarAlteracao}
                loading={loadingUpdateDados}
                disabled={!readOnly}
                sx={styles.buttonHabilitarEdicao}
              />
            )}
          </Grid>
          <Grid item sx={styles.buttonWrapper}>
            {readOnly && (
              <ButtonComponent
                type="button"
                value="Editar"
                icon={<Edit sx={styles.icon} />}
                onClick={() => setReadOnly(false)}
              />
            )}
            {!readOnly && (
              <ButtonComponent
                type="button"
                value="Salvar"
                icon={<Save sx={styles.icon} />}
                onClick={handleUpdateVeiculo}
                loading={loadingUpdate}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const styles = {
  ...motoristaStyles,

  select: {
    marginBottom: 0,
  },
  button: {
    width: '256px',
  },
  buttonContainer: {
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    width: '256px',
  },
  buttonHabilitarEdicao: {
    backgroundColor: theme => theme.palette.paterns.greenTertiary,
    ':hover': {
      backgroundColor: theme => theme.palette.paterns.greenTertiaryHover
    }
  }
};

export default DadosVeiculo;
