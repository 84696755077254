import React, {useState} from 'react';
import {Grid, Box} from '@mui/material';
import {styled} from '@mui/styles';
import {Edit, Save} from '@mui/icons-material';
import {Formik, Field} from 'formik';
import {useMutation} from '@apollo/client';
import InputV2Formik from '../../components/input-v2/mui-input-v2-formik';
import {ButtonComponent} from '../../components/mui-button';
import {SelectComercial} from '../../containers/selects/mui-comercial';
import SelectFormik from '../../components/select/mui-select-formik';
import Number from '../../utils/number';
import {
  UPDATE_SEGMENTO_ATUACAO,
  UPDATE_ANUNCIANTE_BY_ADMIN,
} from '../../graphql/mutation';
import {toastNotification} from '../../components/toastify';
import {SelectSegmentos} from '../../containers/selects/mui-segmento';
import {SelectExecutivos} from '../../containers/selects/mui-executivo';
import {meses} from '../../constants/meses';

const FormularioPerfilCliente = ({anunciante = {}, refetch}) => {
  const [readOnly, setReadOnly] = useState(!!anunciante?.id);
  const [filtros, setFiltros] = useState({
    ...anunciante,
    dataProgramacaoAnual: anunciante?.dataProgramacaoAnual
      ? {
        value: anunciante?.dataProgramacaoAnual.toUpperCase(),
        label: anunciante?.dataProgramacaoAnual,
      }
      : undefined,
    metaVenda: anunciante.metaVenda || undefined,
  });

  const [updateAnunciante] = useMutation(UPDATE_ANUNCIANTE_BY_ADMIN);
  const [createSegmento] = useMutation(UPDATE_SEGMENTO_ATUACAO);

  const handleSubmitDadosAnunciante = (
    {
      comercial,
      agencia,
      segmentoAtuacao,
      executivo,
      metaVenda,
      dataProgramacaoAnual,
    },
    {setSubmitting},
  ) => {
    updateAnunciante({
      variables: {
        anunciante: {
          id: anunciante.id,
          comercial: comercial?.value
            ? {
              id: comercial?.value,
            }
            : null,
          agencia: agencia?.value
            ? {
              id: agencia?.value,
            }
            : null,
          executivo: executivo?.label
            ? {
              id: executivo?.value,
              nome: executivo.label,
            }
            : null,
          segmentoAtuacao: segmentoAtuacao?.label
            ? {
              id: segmentoAtuacao?.value,
              descricao: segmentoAtuacao.label,
            }
            : null,
          metaVenda: metaVenda,
          dataProgramacaoAnual: dataProgramacaoAnual?.label || '',
        },
      },
    })
      .then(() => {
        setReadOnly(true);
        toastNotification({
          message: 'Os dados cadastrados foram salvos com sucesso!',
          type: 'success',
        });
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSubmitting(false));
  };

  const handleCreateSegmento = (descricao, setFieldValue) => {
    createSegmento({
      variables: {
        segmento: {
          descricao,
        },
      },
    })
      .then(({data}) => setFieldValue('segmentoAtuacao', data.segmento))
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Box>
      <Formik
        initialValues={{
          segmentoAtuacao: filtros.segmentoAtuacao || '',
          comercial: filtros.comercial,
          agencia: filtros.agencia,
          executivo: filtros.executivo,
          metaVenda: filtros.metaVenda,
          dataProgramacaoAnual: filtros?.dataProgramacaoAnual?.label
            ? filtros?.dataProgramacaoAnual
            : undefined,
        }}
        enableReinitialize
        onSubmit={handleSubmitDadosAnunciante}>
        {({values, isSubmitting, handleSubmit, setFieldValue}) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  component={SelectComercial}
                  name="comercial"
                  value={values.comercial}
                  label="Comercial"
                  disabled={readOnly}
                  onChange={(e) => setFiltros({...filtros, comercial: e})}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={({...props}) => (
                    <SelectExecutivos
                      searchDTO={{comercialId: filtros.comercial?.id || null}}
                      {...props}
                    />
                  )}
                  name="executivo"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectSegmentos}
                  name="segmentoAtuacao"
                  value={values.segmentoAtuacao || ''}
                  label="Segmento de atuação"
                  creatable
                  onCreateOption={(e) => handleCreateSegmento(e, setFieldValue)}
                  onChange={(e) => setFiltros({...filtros, segmentoAtuacao: e})}
                  isClearable
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={InputV2Formik}
                  name="metaVenda"
                  label="Meta de venda"
                  value={values?.metaVenda}
                  mask="moeda"
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      metaVenda: Number.currencyToFloat(e?.target?.value),
                    })
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectFormik}
                  name="dataProgramacaoAnual"
                  label="Data de programação anual"
                  disabled={readOnly}
                  onChange={(e) => {
                    setFiltros({...filtros, dataProgramacaoAnual: e});
                  }}
                  options={meses}
                />
              </Grid>
              <Grid item xs={12} style={{marginRight: 'auto'}}>
                {readOnly && (
                  <ButtonComponent
                    type="button"
                    sx={styles.buttonWrapper}
                    value={'Editar'}
                    icon={<Edit />}
                    onClick={() => setReadOnly(false)}
                  />
                )}
                {!readOnly && (
                  <ButtonComponent
                    type="submit"
                    sx={styles.buttonWrapper}
                    value={'Salvar'}
                    icon={<Save />}
                    loading={isSubmitting}
                  />
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const styles = {
  buttonContainer: {
    marginTop: 'auto',
  },
  buttonWrapper: {
    width: '256px',
  },
  icon: {
    fontSize: '18px',
    marginLeft: '10px',
  },

  novoSegmentoButton: {
    borderRadius: 4,
    margin: '24px 0px 0px -5px',
    paddingLeft: 10,
  },
  segmentoInput: {
    width: '100%',

    '& .input[disabled]': {
      backgroundColor: '#f5f6f9',
    },
  },
};

const Form = styled('form')(() => { });

export default FormularioPerfilCliente;
