import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_CIDADE_BY_ID} from '../../graphql/queries';
import FormularioCidade from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarCidade = () => {
  const {cidadeId} = useParams();

  const cidadeQuery = useQuery(FIND_CIDADE_BY_ID, {
    variables: {
      id: cidadeId,
    },
  });

  const {data = {}} = cidadeQuery;

  const refetch = () => {
    cidadeQuery.refetch();
  };

  if (cidadeQuery.loading || cidadeQuery.error) {
    return (
      <LoadingPage loading={cidadeQuery.loading} error={cidadeQuery.error} />
    );
  };

  return (
    <FormularioCidade cidade={data.cidade} refetch={refetch} />
  );
};

export default EditarCidade;
