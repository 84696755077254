import React, {useState} from 'react';
import {Paper, Tabs, Tab, Grid} from '@mui/material';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {useSelector} from 'react-redux';
import {FIND_BY_ID_ANUNCIANTE} from '../../graphql/queries';
import FormularioCadastroAnunciante from './formulario-dados-cadastrais';
import FormularioPerfilCliente from './formulario-perfil-cliente';
import FormularioHistoricoCompras from './formulario-historico-compras';
import {LoadingPage} from '../../components/loading-page';
import TabPanel from '../../components/tab-painel';

const EditarAnunciante = () => {
  const {indexTab} = useSelector((store) => store.Anunciante);

  const [tabIndex, setTabIndex] = useState(indexTab);

  const {anuncianteId} = useParams();

  const anuncianteQuery = useQuery(FIND_BY_ID_ANUNCIANTE, {
    variables: {
      id: anuncianteId,
    },
  });

  const {data = {}} = anuncianteQuery;
  const {findByIdAnunciante: anunciante = {}} = data;

  const refetch = () => {
    anuncianteQuery.refetch();
  };
  const a11yProps = (index) => {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  };

  if (anuncianteQuery.loading || anuncianteQuery.error) {
    return (
      <LoadingPage loading={anuncianteQuery.loading} error={anuncianteQuery.error} />
    );
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Tabs
          value={tabIndex}
          onChange={(event, index) => setTabIndex(index)}
          variant="standard"
          aria-label="nav tabs example"
          className="tabs">
          <Tab
            iconPosition="start"
            className="tab-button"
            label={<p classes="tabLabel">Dados Cadastrais</p>}
            {...a11yProps(0)}
          />
          <Tab
            iconPosition="start"
            className="tab-button"
            label={<p classes="tabLabel">Perfil do CLiente</p>}
            {...a11yProps(1)}
          />
          <Tab
            className="tab-button"
            iconPosition="start"
            label={<p classes="tabLabel">Histórico de compras</p>}
            {...a11yProps(2)}
          />
        </Tabs>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={0}>
          <FormularioCadastroAnunciante
            anunciante={anunciante}
            refetch={refetch}
          />
        </TabPanel>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={1}>
          <FormularioPerfilCliente
            anunciante={anunciante}
            refetch={refetch}
          />
        </TabPanel>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={2}>
          <FormularioHistoricoCompras anunciante={anunciante} />
        </TabPanel>
      </Paper>
    </Grid>
  );
};
const styles = {
  tabPainel: {
    minHeight: 'calc(100vh - 185px)',
    margin: '10px 0',
    overflowY: 'auto',
  },
};

export default EditarAnunciante;
