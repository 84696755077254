import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_BY_ID_ARQUIVO} from '../../graphql/queries';
import FormmularioMateriaisVenda from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarMateriaisVenda = () => {
  const {materiaisVendaId} = useParams();
  const arquivoQuery = useQuery(FIND_BY_ID_ARQUIVO, {
    variables: {
      id: materiaisVendaId,
    },
  });

  const {data = {}} = arquivoQuery;

  if (materiaisVendaId.loading || materiaisVendaId.error) {
    return (
      <LoadingPage loading={materiaisVendaId.loading} error={materiaisVendaId.error} />
    );
  }

  return (
    <FormmularioMateriaisVenda arquivo={data.arquivo} />
  );
};

export default EditarMateriaisVenda;
