import React, {useState, useMemo} from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Stack,
  TablePagination,
  Paper,
} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {Formik, Field} from 'formik';
import {useQuery} from '@apollo/client';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {COMERCIAL_VENDAS_METRICAS, TOTAL_COMERCIAL_VENDAS_METRICAS} from '../../../graphql/queries';
import TableCellOrdered from '../../../components/table-cell-ordered';
import InputDate from '../../../components/mui-input-date';
import {SelectAnunciantes} from '../../../containers/selects/mui-anunciantes';
import {ANUNCIANTES_ROUTE} from '../../../constants/routes';
import Number from '../../../utils/number';
import {
  persistFiltrosAnaliseCarteira,
  persistAnaliseCarteira,
  persistIndexTabLicencas,
} from '../../../redux/Licencas/slice';

const columns = [
  {
    id: 'nomeFantasia',
    label: 'Cliente',
  },
  {
    id: 'propostas',
    label: 'Nº propostas',
    format: (metricas) => Number.format(metricas?.propostas),
  },
  {
    id: 'campanhas',
    label: 'Nº de campanhas',
    format: (metricas) => Number.format(metricas?.campanhas),
  },
  {
    id: 'veiculos',
    label: 'Nº de carros vendidos',
    format: (metricas) => Number.format(metricas?.veiculos),
  },
  {
    id: 'veiculosCampanha',
    label: 'Média carros por campanha',
    format: (metricas) => Number.format(metricas?.veiculosCampanha),
  },
  {
    id: 'faturamento',
    label: 'Faturamento',
    format: (metricas) => Number.currencyFormat(metricas?.faturamento),
  },
  {
    id: 'mediaVeiculo',
    label: 'Ticket médio carro',
    format: (metricas) => Number.currencyFormat(metricas?.mediaVeiculo),
  },
  {
    id: 'mediaVenda',
    label: 'Ticket médio venda',
    format: (metricas) => Number.currencyFormat(metricas?.mediaVenda),
  },
  {
    id: 'metaVenda',
    label: 'Meta',
    format: (metricas) => Number.currencyFormat(metricas?.metaVenda),
  },
  {
    id: 'resultado',
    label: 'Resultado',
    format: (metricas) => Number.currencyFormat(metricas?.resultado),
  },
];

const FormularioAnaliseCarteira = ({comercial = {}, pageSize = 20}) => {
  const {analiseCarteiraList = [], filtrosAnaliseCarteira} = useSelector(
    (store) => store.Licencas,
  );

  const [page, setPage] = useState(filtrosAnaliseCarteira.page);
  const [orderBy, setOrderBy] = useState(filtrosAnaliseCarteira.orderBy);
  const [filtros, setFiltros] = useState({
    ...filtrosAnaliseCarteira.filtros,
    mesInicio: moment(JSON.parse(filtrosAnaliseCarteira.filtros.mesInicio))
      .startOf('month')
      .toDate(),
    mesFim: moment(JSON.parse(filtrosAnaliseCarteira.filtros.mesFim))
      .endOf('month')
      .toDate(),
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClickTableRow = (anunciante) => {
    dispatch(persistIndexTabLicencas(1));
    navigate(`${ANUNCIANTES_ROUTE}/${anunciante.id}/editar`);
  };

  const totalMetricasQuery = useQuery(TOTAL_COMERCIAL_VENDAS_METRICAS, {
    variables: {
      searchDTO: {
        comercialId: comercial?.id,
        dataInicial: filtros.mesInicio
          ? moment(filtros.mesInicio).startOf('month').format('YYYY-MM-DD')
          : undefined,
        dataFinal: filtros.mesFim
          ? moment(filtros.mesFim).endOf('month').format('YYYY-MM-DD')
          : undefined,

        anuncianteId: filtros.anunciante?.id || undefined,
      },
    },
  });
  const comercialVendasMetricasQuery = useQuery(COMERCIAL_VENDAS_METRICAS, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        orderBy: {
          campo: orderBy.campo,
          ordem: orderBy.asc ? 'ASC' : 'DESC',
        },
      },
      searchDTO: {
        comercialId: comercial?.id,
        dataInicial: filtros.mesInicio
          ? moment(filtros.mesInicio).startOf('month').format('YYYY-MM-DD')
          : undefined,
        dataFinal: filtros.mesFim
          ? moment(filtros.mesFim).endOf('month').format('YYYY-MM-DD')
          : undefined,

        anuncianteId: filtros.anunciante?.id || undefined,
      },
    },
  });

  const comercialVendasMetricas = comercialVendasMetricasQuery?.data?.comercialVendasMetricas || {};
  const totalMetricas = totalMetricasQuery?.data?.total || {};
  const {totalElements = 0} = comercialVendasMetricas;

  useMemo(() => {
    dispatch(persistAnaliseCarteira(comercialVendasMetricas));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comercialVendasMetricas]);

  useMemo(() => {
    dispatch(
      persistFiltrosAnaliseCarteira({
        filtros: {
          ...filtros,
          mesInicio: JSON.stringify(filtros.mesInicio),
          mesFim: JSON.stringify(filtros.mesFim),
        },
        page: page,
        orderBy,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filtros]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box paddingTop="16px">
      <Formik enableReinitialize initialValues={filtros}>
        <Stack direction="row" spacing={2}>
          <InputDate
            label="Período venda"
            selected={filtros.mesInicio}
            dateFormat="MMMM/yyyy"
            showMonthYearPicker
            selectsRange
            startDate={filtros.mesInicio}
            endDate={filtros.mesFim}
            onChange={(e) => {
              const [start, end] = e;
              setFiltros((prev) => ({
                ...prev,
                mesInicio: start,
                mesFim: end,
              }));
              setPage(0);
            }}
          />
          <Field
            component={({...props}) => (
              <SelectAnunciantes
                searchDTO={{comercialId: comercial?.id || null}}
                {...props}
              />
            )}
            label="Anunciante"
            name="anunciante"
            onChange={(e) => {
              setFiltros((prev) => ({...prev, anunciante: e}));
              setPage(0);
            }}
            isClearable
          />
        </Stack>
      </Formik>
      <Paper>
        <Table stickyHeader aria-label="sticky table">
          <TableHead tabIndex={-1}>
            <TableRow>
              <TableCell>Total: {totalElements}</TableCell>
              <TableCell>{Number.format(totalMetricas.propostas)}</TableCell>
              <TableCell>{Number.format(totalMetricas.campanhas)}</TableCell>
              <TableCell>{Number.format(totalMetricas.veiculos)}</TableCell>
              <TableCell>{Number.format(totalMetricas.veiculosCampanha)}</TableCell>
              <TableCell>{Number.currencyFormat(totalMetricas.faturamento)}</TableCell>
              <TableCell>{Number.currencyFormat(totalMetricas.mediaVeiculo)}</TableCell>
              <TableCell>{Number.currencyFormat(totalMetricas.mediaVenda)}</TableCell>
              <TableCell>{Number.currencyFormat(totalMetricas.metaVenda)}</TableCell>
              <TableCell>{Number.currencyFormat(totalMetricas.resultado)}</TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCellOrdered
                  key={column.id}
                  column={column}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {analiseCarteiraList.map((row, i) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={i}
                  onClick={() => handleClickTableRow(row)}
                  sx={{cursor: 'pointer'}}>
                  {columns.map((column, index) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={index} align={column.align}>
                        {column.format ? column.format(row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalElements}
          rowsPerPageOptions={[]}
          rowsPerPage={pageSize}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          labelDisplayedRows={({from, to, count}) =>
            `${from}-${to} de ${count}`
          }
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );
};

export default FormularioAnaliseCarteira;
