import React from 'react';
import {Routes, Route} from 'react-router-dom';
import CadastrarNotificacao from './cadastrar';
import ConsultaNotificacao from './consulta';
import EditarNotificacao from './editar';

const NotificarMotoristas = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaNotificacao />} />
      <Route path={`/criar`} element={<CadastrarNotificacao />} />
      <Route path={`/:notificacaoId/editar`} element={<EditarNotificacao />} />
    </Routes>
  );
};

export default NotificarMotoristas;
