import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Paper, Grid, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination} from '@mui/material';
import {OpenInNew, Visibility} from '@mui/icons-material';
import {useQuery} from '@apollo/client';
import {Field, Formik} from 'formik';
import {FIND_ALL_ANUNCIANTE} from '../../graphql/queries';
import String from '../../utils/string';
import {RoundTooltipButton, ButtonComponent} from '../../components/mui-button';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {
  persistAnunciantes,
  persistFiltrosAnunciante,
} from '../../redux/Anunciante/slice';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {SelectEstados} from '../../containers/selects/mui-estados';
import {SelectComercial} from '../../containers/selects/mui-comercial';
import TableCellOrdered from '../../components/table-cell-ordered';
import Select from '../../components/select/mui-select';
import {SelectSegmentos} from '../../containers/selects/mui-segmento';
import Data from '../../utils/data';
import usePainelService from '../../services/PainelService';

const columns = [
  {id: 'nomeFantasia', label: 'Nome Fantasia'},
  {
    id: 'segmentoAtuacao',
    label: 'Segmento',
    campo: 'segmentoAtuacao.descricao',
    format: (segmento) => segmento?.descricao,
    style: {width: '350px'}
  },
  {
    id: 'comercial',
    label: 'Comercial',
    campo: 'comercial.nomeRazao',
    format: (comercial) => comercial?.nomeRazao,
    style: {width: '350px'}
  },
  {
    id: 'dataCadastro',
    label: 'Data cadastro',
    format: (data) => Data.dataFormat(data),
    style: {width: '150px'}
  }
];

const ConsultaAnunciante = ({pageSize = 20}) => {
  const {anunciantes, totalAnunciantes, refetchAnunciantes, filtrosAnunciante} =
    useSelector((store) => store.Anunciante);
  const painelService = usePainelService();

  const [rowsPerPage] = useState(pageSize);
  const [page, setPage] = useState(filtrosAnunciante.page);
  const [orderBy, setOrderBy] = useState(filtrosAnunciante.orderBy);
  const [filtros, setFiltros] = useState(filtrosAnunciante.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [loadingAccessAnunciante, setLoadingAccessAnunciante] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const anuncianteQuery = useQuery(FIND_ALL_ANUNCIANTE, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        cpfCnpj: searchDTO.cpfCnpj,
        comercialId: searchDTO.comercial?.value,
        estadoId: searchDTO.estado?.value,
        cidadeId: searchDTO.cidade?.value,
        cadastroCompleto: searchDTO.cadastroCompleto?.value,
        segmentoAtuacao: searchDTO.segmentoAtuacao?.id
          ? {id: searchDTO.segmentoAtuacao?.id}
          : undefined,
        campanhaSituacoesIn: searchDTO.campanhaAtiva?.value
          ? ['ATIVA', 'ATIVA_PARCIAL']
          : undefined,
        campanhaSituacoesDiferenteDe:
          searchDTO.campanhaAtiva?.value === false
            ? ['ATIVA', 'ATIVA_PARCIAL']
            : undefined,
      },
    },
  });

  useEffect(() => {
    if (
      !anuncianteQuery.loading &&
      !anuncianteQuery.error &&
      anuncianteQuery.data
    ) {
      dispatch(persistAnunciantes(anuncianteQuery.data.findAllAnunciante));
      dispatch(persistFiltrosAnunciante({filtros, page, orderBy}));
    }
    if (refetchAnunciantes) {
      anuncianteQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anuncianteQuery, dispatch, refetchAnunciantes]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputV2
                  label="Nome fantasia / Razão social"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <InputV2
                  label="CNPJ"
                  value={filtros.cpfCnpj}
                  mask="cnpj"
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      cpfCnpj: String.removeSpecialChars(e.target.value),
                    })
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectComercial}
                  name="comercial"
                  onChange={(e) => setFiltros({...filtros, comercial: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Com campanha ativa"
                  options={[
                    {label: 'Todos'},
                    {value: true, label: 'Sim'},
                    {value: false, label: 'Não'},
                  ]}
                  value={filtros.campanhaAtiva}
                  onChange={(e) => setFiltros({...filtros, campanhaAtiva: e})}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={SelectSegmentos}
                  name="segmentoAtuacao"
                  label="Segmento de atuação"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, segmentoAtuacao: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Situação cadastro"
                  options={[
                    {
                      value: undefined,
                      label: 'Todos',
                    },
                    {
                      value: true,
                      label: 'Completo',
                    },
                    {
                      value: false,
                      label: 'Incompleto',
                    },
                  ]}
                  value={filtros.cadastroCompleto}
                  onChange={(e) =>
                    setFiltros({...filtros, cadastroCompleto: e})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  loading={anuncianteQuery.loading}
                  value="Filtrar"
                  onClick={() => refetch()}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        style={column.style}
                      />
                    ))}
                    <TableCell
                      id="acoes"
                      key="acoes"
                      align="center"
                      style={{width: '100px'}}
                    >
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anunciantes.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="row">
                            <RoundTooltipButton
                              id="icon-gray"
                              title={'Acessar painel'}
                              onClick={() => {
                                setLoadingAccessAnunciante(row);
                                painelService.acessarPainelAnunciante(row.id, setLoadingAccessAnunciante);
                              }}
                              loading={loadingAccessAnunciante?.id === row.id}
                              icon={<OpenInNew />}
                            />
                            <RoundTooltipButton
                              id="icon-primary"
                              title="Visualizar"
                              onClick={() => navigate(`${row.id}/editar`)}
                              icon={<Visibility />}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalAnunciantes}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'página anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaAnunciante;
