import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {Field, Formik} from 'formik';
import {useSelector, useDispatch} from 'react-redux';
import {Paper, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Box} from '@mui/material';
import {Edit, Refresh} from '@mui/icons-material';
import {ButtonWithIcon, RoundTooltipButton, ButtonComponent} from '../../components/mui-button';
import {FIND_ALL_CIDADES} from '../../graphql/queries';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {SelectEstados} from '../../containers/selects/mui-estados';
import TableCellOrdered from '../../components/table-cell-ordered';
import inputsEvents from '../../utils/inputsEvents';
import {persistFiltrosCidade, persistCidade, persistLoadingAtualizar} from '../../redux/Cidade/slice';
import {ATUALIZAR_METRICAS_CIDADES_IBGE} from '../../graphql/mutation';
import {toastNotification} from '../../components/toastify';

const columns = [
  {id: 'nome', label: 'Nome'},
  {
    id: 'estado',
    label: 'UF',
    campo: 'estado.uf',
    format: (estado) => (estado ? estado.uf : ''),
  },
];

const ConsultaCidades = ({pageSize = 20}) => {
  const {role} = useSelector((store) => store.Auth);
  const {totalCidade, filtrosCidade, cidade, refetchCidade, loadingAtualizar} = useSelector(
    (state) => state.Cidade,
  );
  const [page, setPage] = useState(filtrosCidade.page);
  const [rowsPerPage] = useState(pageSize);
  const [orderBy, setOrderBy] = useState(filtrosCidade.orderBy);
  const [filtros, setFiltros] = useState(filtrosCidade.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [atualizarCidades, atualizarQuery] = useMutation(ATUALIZAR_METRICAS_CIDADES_IBGE);
  const cidadesQuery = useQuery(FIND_ALL_CIDADES, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
        estadoId: searchDTO.estado?.id,
      },
    },
  });

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!cidadesQuery.loading && !cidadesQuery.error && cidadesQuery.data) {
      dispatch(persistFiltrosCidade({filtros, page, orderBy}));
      dispatch(persistCidade(cidadesQuery.data?.cidades || []));
    }
    if (refetchCidade) {
      cidadesQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cidadesQuery, dispatch, refetchCidade]);

  const atualizarMetricasCidade = async () => {
    if (atualizarQuery.loading || loadingAtualizar)
      return toastNotification({message: "A atualização já está sendo executada!", type: 'warning'});

    dispatch(persistLoadingAtualizar(true));
    toastNotification({message: "A atualização está sendo feita em segundo plano!", type: 'warning'});

    atualizarCidades()
      .then(({data}) => toastNotification({message: `${data.cidades} cidades atualizadas`, type: 'success'}))
      .catch((error) => toastNotification({message: error.message, type: 'error'}))
      .finally(() => dispatch(persistLoadingAtualizar(false)));
  }

  return (
    <Grid id="page-container" container>
      <ButtonWithIcon
        id='button-gray-blue'
        value="Atualizar métricas"
        loading={atualizarQuery.loading || loadingAtualizar}
        startIcon={<Refresh style={{marginRight: 10}} />}
        toolbar='true'
        onClick={() => atualizarMetricasCidade()}
        style={{visibility: role.includes('ADMINISTRADOR') ? 'visible' : 'hidden'}}
      />
      <Grid item xs={12} onKeyUp={({key}) => inputsEvents.handleKeyUp(key, refetch)}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <InputV2
                  label="Nome"
                  value={filtros.search || ''}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={SelectEstados}
                  label="Estado"
                  name="estado"
                  isClearable
                  onChange={(e) => {
                    setFiltros({...filtros, estado: e});
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  value="Filtrar"
                  loading={cidadesQuery.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                  <TableCell id="acoes" key="acoes" align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cidade.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => navigate(`${row.id}/editar`)}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="right">
                        <Grid marginRight="-5px">
                          <RoundTooltipButton
                            id="icon-primary"
                            title={'Editar'}
                            icon={<Edit />}
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalCidade}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'próxima página',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ConsultaCidades;
