import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  TableContainer,
} from '@mui/material';
import {Alarm, AttachMoney, WhatsApp} from '@mui/icons-material';
import {useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {ReactComponent as TrocarMotorista} from '../../assets/icons/trocar-motorista.svg';
import {FIND_ALL_ANUNCIOS} from '../../graphql/queries';
import {persistAnunciosDaCampanha} from '../../redux/AnuncioCampanha/slice';
import {RoundTooltipButton} from '../../components/mui-button';
import {anuncioSituacao} from '../../constants/enum-labels';
import String from '../../utils/string';
import TableCellOrdered from '../../components/table-cell-ordered';
import {LoadingPage} from '../../components/loading-page';
import AgendamentoModal from '../campanhas/modais/agendamento-modal';
import DetalhesMotoristaModal from '../campanhas/modais/detalhes-motorista-modal';

const columns = [
  {
    id: 'campanha',
    label: 'Campanha',
    campo: 'campanha.titulo',
    format: ({campanha}) => campanha?.titulo,
  },
  {
    id: 'nome',
    label: 'Nome',
    campo: 'motorista.nome',
    format: ({motorista}) => motorista.nome,
  },
  {
    id: 'veiculo',
    label: 'Marca/Modelo/Ano',
    campo: 'veiculo.modelo.marca.nome',
    format: ({veiculo}) =>
      veiculo?.modelo?.marca
        ? `${veiculo.modelo.marca.nome} - ${veiculo.modelo.nome} ${veiculo.anoFabricacao}/${veiculo.anoModelo}`
        : '-',
  },
  {
    id: 'cidade',
    label: 'Campanha cidade',
    format: ({campanha}) =>
      campanha?.cidade ? `${campanha.cidade.nome}/${campanha.cidade.uf}` : '-',
  },
  {
    id: 'situacao',
    label: 'Status do Anúncio',
    format: ({situacao}) => (situacao ? anuncioSituacao[situacao] : '-'),
  },
  {
    id: 'instalador',
    label: 'Instalador',
    campo: 'instalador.nome',
    format: ({instalador}) => instalador?.nome || ''
  },
  {
    id: 'dataAgendamentoInstalacao',
    label: 'Data Agendamento',
    format: ({dataAgendamentoInstalacao}) =>
      dataAgendamentoInstalacao
        ? moment(dataAgendamentoInstalacao).format('DD/MM/YY - HH:mm')
        : '-',
  },
];

const ConsultaAnuncios = ({pageSize = 20}) => {
  const {campanhaCidadeId} = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState({campo: 'motorista.nome', asc: true});
  const [openAgendamentoModal, setOpenAgendamentoModal] = useState(false);
  const [openDetalhesModal, setOpenDetalhesModal] = useState(false);
  const [anuncioSelecionado, setAnuncioSelecionado] = useState(undefined);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {anuncios, totalAnuncios, refetchAnuncios} = useSelector(
    (store) => store.AnuncioCampanha,
  );

  const anunciosQuery = useQuery(FIND_ALL_ANUNCIOS, {
    variables: {
      searchDTO: {
        campanhaCidadeId: campanhaCidadeId,
        anuncioSituacaoIn: ['AGUARDANDO_INSTALACAO', 'AGUARDANDO_ACEITE_MOTORISTA', 'AGUARDANDO_AGENDAMENTO_INSTALACAO', 'AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO'],
      },
      pageable: {
        pageNumber: page,
        pageSize,
        orderBy: [
          {
            campo: orderBy.campo,
            ordem: orderBy.asc ? 'ASC' : 'DESC',
          },
          {
            campo: 'motorista.nome',
            ordem: 'ASC',
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (!anunciosQuery.loading && !anunciosQuery.error) {
      dispatch(persistAnunciosDaCampanha(anunciosQuery.data.anuncios));
    }
    if (refetchAnuncios) {
      anunciosQuery.refetch();
    }
  }, [anunciosQuery, dispatch, refetchAnuncios]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleTrocarMotorista = (anuncio) => {
    navigate(
      `/campanhas/${campanhaCidadeId}/trocar-motorista/${anuncio.motorista.id}`,
    );
  };

  if (anunciosQuery.loading) {
    return (
      <LoadingPage loading={anunciosQuery.loading} />
    );
  }

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow >
                  {columns.map((column, index) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                  <TableCell id="acoes" key="acoes" align="center">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {anuncios.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      sx={{cursor: 'pointer'}}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() =>
                              navigate(`/motoristas/${row.motorista.id}`)
                            }>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="center">
                        <Box sx={styles.tableButtonsContainer}>
                          {[
                            'AGUARDANDO_ACEITE_MOTORISTA',
                            'AGUARDANDO_AGENDAMENTO_INSTALACAO',
                            'AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO',
                            'AGUARDANDO_INSTALACAO',
                            'AGUARDANDO_IMPRESSAO',
                          ].includes(row.situacao) && (
                              <RoundTooltipButton
                                title={'Agendar instalação'}
                                id="icon-gray"
                                onClick={() => {
                                  setAnuncioSelecionado(row);
                                  setOpenAgendamentoModal(true);
                                }}
                                icon={<Alarm />}
                              />
                            )}
                          {!['FINALIZADO', 'AGUARDANDO_REMOCAO'].includes(
                            row.situacao,
                          ) && (
                              <RoundTooltipButton
                                title={'Trocar motorista'}
                                id="icon-purple"
                                onClick={() => handleTrocarMotorista(row)}
                                icon={<TrocarMotorista />}
                              />
                            )}
                          <RoundTooltipButton
                            title={'Dados Bancários'}
                            id="icon-green-tertiary"
                            onClick={() => {
                              setAnuncioSelecionado(row);
                              setOpenDetalhesModal(true);
                            }}
                            icon={<AttachMoney />}
                          />
                          <RoundTooltipButton
                            title={String.formatCelular(
                              row.motorista?.celular,
                            )}
                            id="icon-whatsapp"
                            onClick={() =>
                              window.open(
                                `https://api.whatsapp.com/send?phone=${row.motorista?.celular}`,
                              )
                            }
                            icon={<WhatsApp />}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalAnuncios}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
      <AgendamentoModal
        openModal={openAgendamentoModal}
        anuncio={anuncioSelecionado}
        onClose={() => {
          setOpenAgendamentoModal(false);
          anunciosQuery.refetch();
        }}
      />
      <DetalhesMotoristaModal
        openModal={openDetalhesModal}
        onClose={() => setOpenDetalhesModal(false)}
        anuncio={anuncioSelecionado}
      />
    </Grid>
  );
};

const styles = {
  tableButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'default',
  },
};

export default ConsultaAnuncios;
