import React from 'react';
import FormularioOficina from './formulario';

const CadastrarOficina = () => {
  return (
    <FormularioOficina />
  );
};

export default CadastrarOficina;
