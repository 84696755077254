import React, {useState} from 'react';
import {Grid, Modal, Typography, Box} from '@mui/material';
import {TextAreaV2} from '../../../components/textarea/mui-textarea';
import {ButtonComponent} from '../../../components/mui-button';
import {useMutation} from '@apollo/client';
import {REPROVAR_MOTORISTA} from '../../../graphql/mutation';
import String from '../../../utils/string';
import {toastNotification} from '../../../components/toastify';
import {Check, Close} from '@mui/icons-material';
import motoristaStyles from '../styles';

const ReprovadoMotivoModal = ({
  openModal,
  onClose,
  refetch,
  motorista = {},
}) => {
  const [motivo, setMotivo] = useState('');
  const [reprovando, setReprovando] = useState(false);

  const [reprovarMotorista] = useMutation(REPROVAR_MOTORISTA);

  const reprovar = () => {
    if (!motorista?.id || String.isEmpty(motivo)) return;

    setReprovando(true);

    reprovarMotorista({
      variables: {
        motoristaId: motorista.id,
        motivo,
      },
    })
      .then(() => {
        toastNotification({
          message: 'O cadastro do motorista foi reprovado!',
          type: 'success',
        });
        refetch && refetch();
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setReprovando(false));
  };

  return (
    <Modal
      open={openModal}
      onClose={() => onClose()}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}
    >
      <Box sx={styles.modalBody}>
        <Typography sx={styles.modalTitle}>
          Motivo para reprovar o cadastro
        </Typography>
        <TextAreaV2
          sx={styles.textArea}
          placeholder="Digite uma observação"
          value={motivo}
          rows={6}
          onChange={({target}) => {
            setMotivo(target.value);
          }}
          style={{minWidth: '100%'}}
        />
        <Grid
          container
          mt="16px"
          spacing={2}
          sx={styles.modalButtonContainer}
        >
          <Grid item xs={12} md={4}>
            <ButtonComponent
              id="button-cancel"
              value="Cancelar"
              icon={<Close />}
              onClick={onClose}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ButtonComponent
              id="button"
              value="Reprovar"
              icon={<Check />}
              loading={reprovando}
              onClick={reprovar}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const styles = {
  ...motoristaStyles,
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    padding: '20px',
    background: '#fff',
    borderRadius: '5px',
    width: '80vw',
    maxWidth: '700px',
    display: 'flex',
    flexDirection: 'column',
  },
  modalTitle: {
    color: '#8995b1',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: '12px',
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5px',
  },
};

export default ReprovadoMotivoModal;
