import React, {useEffect, useState} from 'react';
import {FormControlLabel, Grid, Paper, Radio} from '@mui/material';
import {OpenInNew, Visibility} from '@mui/icons-material';
import {useLazyQuery, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  COUNT_ALL_ANUNCIOS_ATIVOS,
  COUNT_ALL_CAMPANHAS_ATIVAS,
  COUNT_ANUNCIOS_PARADOS_A_MAIS_DE,
  FIND_ALL_ANUNCIOS_SELECT,
  FIND_ALL_CAMPANHA_FALTANDO_MOTORISTAS,
  FIND_ALL_CIDADES_CAMPANHA,
  FIND_ALL_MOTORISTAS_HOME,
  FIND_ALL_MOTORISTAS_TOTAIS_HOME,
  FIND_ALL_PROPOSTA
} from '../../graphql/queries';
import Map from '../../google/Map';
import TransitLayer from '../../google/TransitLayer';
import InfoWindow from '../../google/InfoWindow';
import Marker from '../../google/Marker';
import {centroBrasil} from '../../utils/getPlaces';
import {ButtonOutlineComponent, RoundTooltipButton} from '../../components/mui-button';
import {
  ANUNCIOS_PARADOS_ROUTE,
  CAMPANHAS_FALTANDO_VEICULOS_ROUTE,
  CAMPANHAS_ROUTE,
  MOTORISTAS_ROUTE,
  PROPOSTAS_ROUTE,
  VEICULOS_PENDENTES_INSTALACAO_ROUTE,
  VEICULOS_PENDENTES_REMOCAO_ROUTE
} from '../../constants/routes';
import {persistFiltrosMotorista} from '../../redux/Motorista/slice';
import {motoristaSituacao} from '../../constants/enum-labels';
import {setFiltroHome} from '../../redux/Home/slice';
import Number from '../../utils/number';
import {persistFiltrosProposta} from '../../redux/Proposta/slice';
import {persistFiltrosCampanha} from '../../redux/Campanha/slice';
import usePainelService from '../../services/PainelService';
import {useHorizontalScroll} from '../../utils/hooks/use-horizontal-scroll';

const KmIcon = require('../../assets/icons/km-map-pin.png');

const variables = {
  pageable: {pageNumber: 0, pageSize: -1},
  searchDTO: {ativo: true, campanhaSituacoesIn: ['ATIVA', 'ATIVA_PARCIAL']}
}

const HomeGerenteOperacional = ({role}) => {
  const dispatch = useDispatch();
  const {filtroHome} = useSelector((state) => state.Home);
  const navigate = useNavigate();
  const horizontalRef = useHorizontalScroll();

  const isGerente = () => role.includes('GERENTE');

  const [filtro, setFiltro] = useState(filtroHome || isGerente() ? 'CAMPANHA' : 'TODOS');
  const [localizacoes, setLocalizacoes] = useState([]);

  const [loadMotoristas, motoristasQuery] = useLazyQuery(FIND_ALL_MOTORISTAS_HOME);
  const [loadTotais, {data: totais}] = useLazyQuery(FIND_ALL_MOTORISTAS_TOTAIS_HOME);
  const [loadCampanhas, campanhasQuery] = useLazyQuery(FIND_ALL_CIDADES_CAMPANHA);

  const anunciosParadosQuery = useQuery(COUNT_ANUNCIOS_PARADOS_A_MAIS_DE);
  const anunciosAtivosQuery = useQuery(COUNT_ALL_ANUNCIOS_ATIVOS);
  const campanhasAtivasQuery = useQuery(COUNT_ALL_CAMPANHAS_ATIVAS);

  const anunciosAguardandoInstalacaoQuery = useQuery(FIND_ALL_ANUNCIOS_SELECT, {
    variables: {
      pageable: {pageNumber: 0, pageSize: 1},
      searchDTO: {
        anuncioSituacaoIn: ['AGUARDANDO_INSTALACAO', 'AGUARDANDO_ACEITE_MOTORISTA', 'AGUARDANDO_AGENDAMENTO_INSTALACAO', 'AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO'],
      },
    },
  });

  const anunciosAguardandoRemocaoQuery = useQuery(FIND_ALL_ANUNCIOS_SELECT, {
    variables: {
      pageable: {pageNumber: 0, pageSize: 1},
      searchDTO: {
        anuncioSituacao: 'AGUARDANDO_REMOCAO',
      },
    },
  });

  const campanhasMotoristasQuery = useQuery(FIND_ALL_CAMPANHA_FALTANDO_MOTORISTAS);

  const propostaQuery = useQuery(FIND_ALL_PROPOSTA, {
    variables: {
      pageable: {pageNumber: 0, pageSize: 1},
      searchDTO: {orcamentoSituacao: 'AGUARDANDO_APROVACAO'}
    },
  });

  useEffect(() => {
    loadTotais();
    if (isGerente())
      loadCampanhas({variables});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadMotoristas({variables: {filtro}});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro]);

  const handeChangeFilter = value => {
    dispatch(setFiltroHome(value));
    if (value !== filtro)
      setLocalizacoes([]);
    setFiltro(value);
  }

  useEffect(() => {
    if (filtro === 'CAMPANHA' && !campanhasQuery.loading) {
      return setLocalizacoes(campanhasQuery.data?.cidades?.content
        ?.map(({anunciante, cidade, campanha}) => ({
          id: campanha.id,
          anuncianteId: anunciante.id,
          latitude: cidade?.latitude + (Math.random() / 100),
          longitude: cidade?.longitude + (Math.random() / 100),
          nome: anunciante?.nomeFantasia,
        })))
    }

    setLocalizacoes(motoristasQuery.data?.motoristas || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motoristasQuery, campanhasQuery]);

  return (
    <>
      <Grid container item xs={12}>
        <Grid
          ref={horizontalRef}
          container
          item
          xs={12}
          className="styled-scroll-horizontal"
          sx={styles.filtroContainer}
        >
          {isGerente() && (
            <FormControlLabel
              control={<Radio color="primaryPatern" />}
              checked={filtro === 'CAMPANHA'}
              label={`Campanhas ativas (${Number.format(new Set(campanhasQuery.data?.cidades?.content?.map(c => c?.campanha?.id)).size || 0)})`}
              onChange={({target}) => handeChangeFilter('CAMPANHA')}
              sx={filtro === 'CAMPANHA' ? styles.checkboxSelected : styles.checkbox}
            />
          )}
          <FormControlLabel
            control={<Radio color="primaryPatern" />}
            checked={filtro === 'TODOS'}
            label={`Motoristas (${Number.format(totais?.motoristas?.motoristas || 0)})`}
            onChange={({target}) => handeChangeFilter('TODOS')}
            sx={filtro === 'TODOS' ? styles.checkboxSelected : styles.checkbox}
          />
          <FormControlLabel
            control={<Radio color="primaryPatern" />}
            checked={filtro === 'ANUNCIO_ATIVO'}
            label={`Em campanha (${Number.format(totais?.motoristas?.motoristasEmCampanha || 0)})`}
            onChange={({target}) => handeChangeFilter('ANUNCIO_ATIVO')}
            sx={filtro === 'ANUNCIO_ATIVO' ? styles.checkboxSelected : styles.checkbox}
          />
          <FormControlLabel
            control={<Radio color="primaryPatern" />}
            checked={filtro === 'DISPONIVEIS'}
            label={`Disponíveis (${Number.format(totais?.motoristas?.motoristasDisponiveis || 0)})`}
            onChange={({target}) => handeChangeFilter('DISPONIVEIS')}
            sx={filtro === 'DISPONIVEIS' ? styles.checkboxSelected : styles.checkbox}
          />
          <FormControlLabel
            control={<Radio color="primaryPatern" />}
            checked={filtro === 'COMPLETO'}
            label={`Aguardando aprovação (${Number.format(totais?.motoristas?.motoristasPendentes || 0)})`}
            onChange={({target}) => handeChangeFilter('COMPLETO')}
            sx={filtro === 'COMPLETO' ? styles.checkboxSelected : styles.checkbox}
          />
          <FormControlLabel
            control={<Radio color="primaryPatern" />}
            checked={filtro === 'INCOMPLETO'}
            label={`Incompletos (${Number.format(totais?.motoristas?.motoristasIncompletos || 0)})`}
            onChange={({target}) => handeChangeFilter('INCOMPLETO')}
            sx={filtro === 'INCOMPLETO' ? styles.checkboxSelected : styles.checkbox}
          />
          <FormControlLabel
            control={<Radio color="primaryPatern" />}
            checked={filtro === 'INATIVO'}
            label={`Inativos (${Number.format(totais?.motoristas?.motoristasInativos || 0)})`}
            onChange={({target}) => handeChangeFilter('INATIVO')}
            sx={filtro === 'INATIVO' ? styles.checkboxSelected : styles.checkbox}
          />
          <FormControlLabel
            control={<Radio color="primaryPatern" />}
            checked={filtro === 'REPROVADO'}
            label={`Reprovados (${Number.format(totais?.motoristas?.motoristasReprovados || 0)})`}
            onChange={({target}) => handeChangeFilter('REPROVADO')}
            sx={filtro === 'REPROVADO' ? styles.checkboxSelected : styles.checkbox}
          />
        </Grid>
        <Grid item xs={12} sx={styles.mapaContainer}>
          <MapaHome localizacoes={localizacoes} filtro={filtro} />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={styles.acessoContainer}>
        <Grid item sx={styles.buttonAcessoContainer} >
          <ButtonOutlineComponent
            value={`${anunciosAguardandoInstalacaoQuery.data?.findAllAnuncios?.totalElements || '0'} Anúncios à instalar`}
            onClick={() => navigate(VEICULOS_PENDENTES_INSTALACAO_ROUTE)}
          />
        </Grid>
        <Grid item sx={styles.buttonAcessoContainer}>
          <ButtonOutlineComponent
            value={`${anunciosAguardandoRemocaoQuery.data?.findAllAnuncios?.totalElements || '0'} Anúncios à finalizar`}
            onClick={() => navigate(VEICULOS_PENDENTES_REMOCAO_ROUTE)}
          />
        </Grid>
        <Grid item sx={styles.buttonAcessoContainer}>
          <ButtonOutlineComponent
            value={`${anunciosParadosQuery.data?.totalAnunciosParadosAMaisDe || '0'} Veículos parados`}
            onClick={() => navigate(ANUNCIOS_PARADOS_ROUTE)}
          />
        </Grid>
        {isGerente() && (
          <Grid item sx={styles.buttonAcessoContainer}>
            <ButtonOutlineComponent
              value={`${propostaQuery.data?.propostas?.totalElements || '0'} Propostas em aprovação`}
              onClick={() => {
                dispatch(persistFiltrosProposta({
                  filtros: {},
                  page: 0, orderBy: {campo: 'dataCadastro', asc: false}
                }));
                navigate(PROPOSTAS_ROUTE)
              }}
            />
          </Grid>
        )}
        <Grid item sx={styles.buttonAcessoContainer}>
          <ButtonOutlineComponent
            title="Campanhas aguardando motoristas"
            value={`${campanhasMotoristasQuery.data?.campanhas?.length || '0'} Aguardando motoristas`}
            onClick={() => {
              navigate(CAMPANHAS_FALTANDO_VEICULOS_ROUTE)
            }}
          />
        </Grid>
        <Grid item sx={styles.buttonAcessoContainer}>
          <ButtonOutlineComponent
            value={`${anunciosAtivosQuery.data?.anuncios || '0'} Veículos em ${campanhasAtivasQuery.data?.campanhas || '0'} Campanhas`}
            onClick={() => {
              dispatch(persistFiltrosCampanha({
                filtros: {campanhaSituacao: {value: 'ATIVA', label: 'Ativa'}},
                page: 0, orderBy: {campo: 'dataInicioVeiculacao', asc: false}
              }));
              navigate(CAMPANHAS_ROUTE)
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

const MapaHome = ({localizacoes = [], filtro}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const painelService = usePainelService();

  const {filtrosMotorista} = useSelector((store) => store.Motorista);

  const [centerMapLatLng] = useState({
    lat: centroBrasil.lat,
    lng: centroBrasil.lng,
  });
  const [transitLayerEnabled] = useState(false);
  const [infoWindowSettings, setInfoWindowSettings] = useState({
    visible: false,
    dados: null,
    marker: null,
    map: null,
  });
  const [timeoutId, setTimeoutId] = useState(undefined);

  const dadosInfoWindow = infoWindowSettings?.dados ? infoWindowSettings.dados : null;

  const handleVisualizar = (dados) => {
    if (filtro === 'CAMPANHA') {
      return abrirCampanha(dados);
    }
    return consultarMotoristas(dados);
  }

  const abrirCampanha = (dados) => {
    navigate(`${CAMPANHAS_ROUTE}/${dados.id}/editar`);
  }

  const consultarMotoristas = (dados) => {
    var itemFiltro = {
      possuiAnuncioAtivo: {value: undefined, label: 'Todos'},
      situacao: {value: undefined, label: 'Todos'},
      motoristaTemIndicacoes: {value: undefined, label: 'Todos'},
      possuiBorrachao: {label: 'Todos'},
      tipoVeiculo: {label: 'Todos'},
      pinturaOriginal: {label: 'Todos'},
      estado: {
        value: dados.id,
        label: dados.nome,
      }
    };

    if (['CAMPANHA', 'DISPONIVEIS'].includes(filtro)) {
      itemFiltro = {
        ...itemFiltro,
        possuiAnuncioAtivo: filtro === 'ANUNCIO_ATIVO' ? {value: true, label: 'Sim'} : {value: false, label: 'Não'},
      }
    }
    if (!['TODOS', 'ANUNCIO_ATIVO', 'DISPONIVEIS'].includes(filtro)) {
      itemFiltro = {
        ...itemFiltro,
        situacao: {value: filtro, label: motoristaSituacao[filtro]},
      }
    }

    dispatch(
      persistFiltrosMotorista({
        ...filtrosMotorista,
        filtros: {
          ...itemFiltro,
        },
      }),
    );
    navigate(MOTORISTAS_ROUTE);
  }

  const handleMouseOut = () => {
    setTimeoutId(setTimeout((clearInfoWindow), 150));
  }

  const handleMouseIn = () => {
    clearTimeout(timeoutId);
  }

  const clearInfoWindow = () => {
    setInfoWindowSettings({
      visible: false,
      dados: null,
      marker: null,
      map: null,
    })
  }

  useEffect(() => {
    if (infoWindowSettings.visible) return;
    handleMouseIn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoWindowSettings]);

  return (
    <Grid container sx={styles.containerMapa} component={Paper} elevation={0}>
      <Map
        zoom={4}
        center={centerMapLatLng}
        locations={localizacoes}
        withBounds={localizacoes.length > 1 ? true : false}
        options={{
          mapTypeControl: false
        }}
      >
        <TransitLayer enabled={transitLayerEnabled} />
        <InfoWindow {...infoWindowSettings}>
          <div
            className="iw-condutor-container"
            onMouseLeave={clearInfoWindow}
            onMouseEnter={handleMouseIn}
          >
            <div className="iw-condutor-info">
              <div className="iw-condutor-info-nome">
                <p>{dadosInfoWindow?.nome}</p>
              </div>
              {filtro !== 'CAMPANHA' && (
                <div className="iw-condutor-info-nome">
                  <p>{`${Number.format(dadosInfoWindow?.motoristas)} motoristas`}</p>
                </div>
              )}
              <div className="iw-condutor-info-posicao">
                <Grid container direction="row" justifyContent="center">
                  {filtro === 'CAMPANHA' && (
                    <RoundTooltipButton
                      id="icon-gray"
                      title={'Acessar'}
                      icon={<OpenInNew />}
                      onClick={() => painelService.acessarPainelAnunciante(dadosInfoWindow.anuncianteId)}
                      tabIndex={-1}
                    />
                  )}
                  <RoundTooltipButton
                    id="icon-primary"
                    title={'Visualizar'}
                    icon={<Visibility />}
                    onClick={() => handleVisualizar(dadosInfoWindow)}
                    tabIndex={-1}
                  />
                </Grid>
              </div>
            </div>
          </div>
        </InfoWindow>
        {
          localizacoes &&
          localizacoes.map((localizacao, index) => {
            return (
              <Marker
                key={index}
                active={false}
                position={{
                  lat: localizacao.latitude,
                  lng: localizacao.longitude,
                }}
                icon={KmIcon}
                events={{
                  onMouseOver: (map, marker) => {
                    handleMouseIn();
                    clearInfoWindow();
                    setInfoWindowSettings({
                      visible: true,
                      marker,
                      map,
                      dados: {
                        ...localizacao,
                      },
                    });
                  },
                  onMouseOut: handleMouseOut
                }}
              />
            );
          })
        }
      </Map >
    </Grid>
  );
};

const checkboxBasic = {
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(0deg, #EFF6FD, #EFF6FD)',
  borderRadius: '6px',
  paddingRight: '9px',
  whiteSpace: 'nowrap',
  margin: '3px 16px 3px 0 !important',

  '.MuiFormControlLabel-label': {
    display: 'block',
    color: '#636363',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  '.MuiSvgIcon-root': {
    fill: '#0091FF !important'
  }
}

const styles = {
  acessoContainer: {
    justifyContent: 'flex-start',
    alignContent: 'center',
    marginTop: '8px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    height: '60px',
    padding: '12px 8px'
  },
  buttonAcessoContainer: {
    padding: '0 8px',
    maxWidth: '245px',
    width: 'calc(100% / 6)',
    '> button': {
      display: 'block',
      overflow: 'hidden',
      minWidth: '100%',
      fontWeight: 'bold',
      lineHeight: '15px',
    }
  },
  filtroContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'flex-star',
    height: '60px',
    overflowX: 'auto',
    overflowY: 'hidden',
    backgroundColor: '#fff',
    flexWrap: 'wrap',
    padding: '0px 22px',
    borderRadius: '4px',
    boxShadow: '1px 6px 12px rgb(128 152 213 / 20%)',
  },
  mapaContainer: {
    paddingTop: '8px',
    overflow: 'hidden',
    height: theme => `calc(100vh - 60px - 16px - ${theme.toolbarHeight} - 68px)`,
  },
  checkbox: {
    ...checkboxBasic
  },
  checkboxSelected: {
    ...checkboxBasic,
    background: '#031663 !important',

    '.MuiFormControlLabel-label': {
      display: 'block',
      color: '#fff !important',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    '.MuiSvgIcon-root': {
      fill: '#007BE1 !important'
    }
  },
  containerMapa: {
    borderRadius: '4px',
    backgroundColor: theme => theme.palette.paterns.white,
    padding: '0',
    height: '100%',
    cursor: 'default',
    overflow: 'hidden'
  },
}

export default HomeGerenteOperacional;