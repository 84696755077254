import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  extratoRepasseInstaladorList: [],
  hasMore: false,
  totalExtratoRepasseInstalador: 0,
  refetchExtratoRepasseInstalador: false,
};

const acaoAtualizarExtratoRepasseInstalador = state => {
  state.refetchExtratoRepasseInstalador = true;
};

const acaoPersistExtratoRepasseInstalador = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.extratoRepasseInstaladorList = content || [];
  state.hasMore = !last;
  state.totalExtratoRepasseInstalador = totalElements || 0;
  state.refetchExtratoRepasseInstalador = false;
};

const resumeSlice = createSlice({
  name: 'extratoRepasseInstalador',
  initialState: initialState,
  reducers: {
    persistExtratoRepasseInstalador: acaoPersistExtratoRepasseInstalador,
    atualizarExtratoRepasseInstalador: acaoAtualizarExtratoRepasseInstalador,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistExtratoRepasseInstalador,
  atualizarExtratoRepasseInstalador,
} = actions;
