import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useMutation, useQuery} from '@apollo/client';
import {Paper, Button, Grid, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination} from '@mui/material';
import {Edit, Delete, Add} from '@mui/icons-material';
import {FIND_ALL_NOTIFICACOES} from '../../graphql/queries';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import InputV2 from '../../components/input-v2/mui-input-v2';
import TableCellOrdered from '../../components/table-cell-ordered';
import {EXCLUIR_NOTIFICACAO} from '../../graphql/mutation';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';
import {toastNotification} from '../../components/toastify';
import inputsEvents from '../../utils/inputsEvents';
import {
  atualizarNotificacaoMotoristas,
  persistFiltrosNotificacaoMotoristas,
  persistNotificacaoMotoristas,
} from '../../redux/NotificacaoMotoristas/slice';

const columns = [
  {id: 'titulo', label: 'Titulo'},
  {id: 'mensagem', label: 'Mensagem'},
];

const ConsultaNotificacao = ({pageSize = 20}) => {
  const {
    filtrosNotificacaoMotoristas,
    notificacaoMotoristas,
    totalNotificacaoMotoristas,
    refetchNotificacaoMotoristas,
  } = useSelector((store) => store.NotificacaoMotoristas);

  const [page, setPage] = useState(filtrosNotificacaoMotoristas.page);
  const [rowsPerPage] = useState(10);
  const [filtros, setFiltros] = useState(filtrosNotificacaoMotoristas.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [excluirNotificacaoId, setExcluirNotificacaoId] = useState(null);
  const [orderBy, setOrderBy] = useState(filtrosNotificacaoMotoristas.orderBy);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [excluirNotificacao] = useMutation(EXCLUIR_NOTIFICACAO);

  const notificacoesQuery = useQuery(FIND_ALL_NOTIFICACOES, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
      },
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
    notificacoesQuery.refetch();
  };

  const handleClickExcluir = (notificacaoId) => {
    confirmAlertMui({
      message: "Deseja excluir a notificação?",
      onConfirm: () => handleExcluirNotificacao(notificacaoId),
    })
  }
  function handleExcluirNotificacao(notificacaoId) {
    setExcluirNotificacaoId(notificacaoId);
    excluirNotificacao({
      variables: {
        notificacao: {
          id: notificacaoId,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'A notificação foi exluída',
          type: 'success',
        });
        dispatch(atualizarNotificacaoMotoristas());
        refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setExcluirNotificacaoId(null));
  }

  useEffect(() => {
    if (
      notificacoesQuery.loading ||
      notificacoesQuery.erro ||
      !notificacoesQuery.data
    )
      return;

    dispatch(persistFiltrosNotificacaoMotoristas({filtros, page, orderBy}));
    dispatch(
      persistNotificacaoMotoristas(notificacoesQuery.data?.notificacoes),
    );

    if (refetchNotificacaoMotoristas) notificacoesQuery.refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificacoesQuery, dispatch, refetchNotificacaoMotoristas]);

  return (
    <Grid id="page-container" container>
      <Button
        id="button-green"
        toolbar="true"
        startIcon={<Add style={{marginRight: 10}} />}
        onClick={() => navigate(`criar`)}
      >
        Cadastrar novo
      </Button>
      <Grid item xs={12} onKeyUp={({key}) => inputsEvents.handleKeyUp(key, refetch)}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <InputV2
                  label="Texto"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  id="button"
                  value="Filtrar"
                  loading={notificacoesQuery.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                  <TableCell id="acoes" key="acoes" align="center">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notificacaoMotoristas.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="center">
                        <Grid container width="100px" spacing={0.5}>
                          <Grid item xs={6}>
                            <RoundTooltipButton
                              title={'Excluir'}
                              id="icon-cancel"
                              loading={excluirNotificacaoId === row.id}
                              icon={<Delete />}
                              onClick={() => handleClickExcluir(row.id)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <RoundTooltipButton
                              id="icon-primary"
                              title={'Editar'}
                              icon={<Edit />}
                              onClick={() =>
                                navigate(`${row.id}/editar`)
                              }
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalNotificacaoMotoristas || 0}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'próxima página',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ConsultaNotificacao;
