import {useLazyQuery} from '@apollo/client';
import {Description, OpenInNew, Visibility} from '@mui/icons-material';
import {Box, FormControlLabel, Grid, Paper, Radio} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RoundTooltipButton} from '../../components/mui-button';
import {
  CAMPANHAS_ROUTE,
  COMERCIAL_ROUTE,
  PROPOSTAS_ROUTE,
} from '../../constants/routes';
import InfoWindow from '../../google/InfoWindow';
import Map from '../../google/Map';
import Marker from '../../google/Marker';
import TransitLayer from '../../google/TransitLayer';
import {
  FIND_ALL_CIDADES_CAMPANHA,
  FIND_ALL_COMERCIAL,
} from '../../graphql/queries';
import {setFiltroHome} from '../../redux/Home/slice';
import {persistFiltrosProposta} from '../../redux/Proposta/slice';
import usePainelService from '../../services/PainelService';
import {centroBrasil} from '../../utils/getPlaces';

const KmIcon = require('../../assets/icons/km-map-pin.png');
const KmGreenIcon = require('../../assets/icons/km-map-pin-green.png');

const variables = {
  pageable: {pageNumber: 0, pageSize: -1},
  searchDTO: {
    ativo: true,
    campanhaSituacoesIn: ['ATIVA', 'ATIVA_PARCIAL'],
    comercialRoles: ['COMERCIAL', 'LICENCA_ADMINISTRADOR'],
  },
};

const HomeGerenteComercial = ({role}) => {
  const dispatch = useDispatch();
  const {filtroHome} = useSelector((state) => state.Home);

  const [filtro, setFiltro] = useState(
    filtroHome || role.includes('FINANCEIRO') ? 'campanha' : 'todos',
  );
  const [localizacoes, setLocalizacoes] = useState([]);

  const [loadCampanha, campanhaQuery] = useLazyQuery(FIND_ALL_CIDADES_CAMPANHA);
  const [loadComercial, comercialQuery] = useLazyQuery(FIND_ALL_COMERCIAL);
  const [loadLicencas, licencasQuery] = useLazyQuery(FIND_ALL_COMERCIAL);
  const [loadRepresentantes, representantesQuery] =
    useLazyQuery(FIND_ALL_COMERCIAL);

  useEffect(() => {
    loadCampanha({variables});
    loadComercial({variables});
    loadLicencas({
      variables: {
        ...variables,
        searchDTO: {ativo: true, comercialRoles: ['LICENCA_ADMINISTRADOR']},
      },
    });
    loadRepresentantes({
      variables: {
        ...variables,
        searchDTO: {ativo: true, comercialRoles: ['COMERCIAL']},
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filtro === 'campanha' && !campanhaQuery.loading) {
      setLocalizacoes(
        campanhaQuery.data?.cidades?.content?.map(
          ({anunciante, cidade, campanha}) => ({
            id: campanha.id,
            anuncianteId: anunciante.id,
            latitude: cidade?.latitude + Math.random() / 100,
            longitude: cidade?.longitude + Math.random() / 100,
            nome: anunciante?.nomeFantasia,
          }),
        ),
      );
    }

    if (filtro !== 'campanha') {
      let content = [];
      if (filtro === 'todos' && !comercialQuery.loading)
        content = comercialQuery.data?.findAllComercial?.content || [];
      if (filtro === 'licencas' && !licencasQuery.loading)
        content = licencasQuery.data?.findAllComercial?.content || [];
      if (filtro === 'representantes' && !representantesQuery.loading)
        content = representantesQuery.data?.findAllComercial?.content || [];

      const localizacoesComercial = content
        .filter((c) => c.role === 'COMERCIAL')
        ?.map(({id, nomeRazao, endereco, role}) => ({
          id,
          latitude: endereco?.cidade?.latitude + Math.random() / 1000,
          longitude: endereco?.cidade?.longitude + Math.random() / 1000,
          nome: nomeRazao,
          role,
        }));

      content
        .filter((c) => c.role !== 'COMERCIAL')
        ?.forEach((c) =>
          c.licencaCidades?.forEach((c1) => {
            localizacoesComercial.push({
              id: c.id,
              latitude: c1.cidade.latitude + Math.random() / 100,
              longitude: c1?.cidade?.longitude + Math.random() / 100,
              nome: c.nomeRazao,
              role: c.role,
            });
          }),
        );

      setLocalizacoes(localizacoesComercial);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtro,
    comercialQuery,
    licencasQuery,
    representantesQuery,
    campanhaQuery,
  ]);

  const handeChangeFilter = (value) => {
    dispatch(setFiltroHome(value));
    if (value !== filtro) setLocalizacoes([]);
    setFiltro(value);
  };

  return (
    <>
      <Grid container item xs={12} sx={styles.filtroContainer}>
        <Grid item xs={12} display="inline-flex">
          {!role.includes('FINANCEIRO') && (
            <Box>
              <FormControlLabel
                control={<Radio color="primaryPatern" />}
                checked={filtro === 'todos'}
                label={`Todos (${
                  comercialQuery.data?.findAllComercial?.totalElements || 0
                })`}
                onChange={() => handeChangeFilter('todos')}
                sx={
                  filtro === 'todos' ? styles.checkboxSelected : styles.checkbox
                }
              />
              <FormControlLabel
                control={<Radio color="primaryPatern" />}
                checked={filtro === 'licencas'}
                label={`Licenças (${
                  licencasQuery.data?.findAllComercial?.totalElements || 0
                })`}
                onChange={() => handeChangeFilter('licencas')}
                sx={
                  filtro === 'licencas'
                    ? styles.checkboxSelected
                    : styles.checkbox
                }
              />
              <FormControlLabel
                control={<Radio color="primaryPatern" />}
                checked={filtro === 'representantes'}
                label={`Representantes (${
                  representantesQuery.data?.findAllComercial?.totalElements || 0
                })`}
                onChange={() => handeChangeFilter('representantes')}
                sx={
                  filtro === 'representantes'
                    ? styles.checkboxSelected
                    : styles.checkbox
                }
              />
            </Box>
          )}
          <FormControlLabel
            control={<Radio color="primaryPatern" />}
            checked={filtro === 'campanha'}
            label={`Campanhas ativas (${
              new Set(
                campanhaQuery.data?.cidades?.content?.map(
                  (c) => c?.campanha?.id,
                ),
              ).size || 0
            })`}
            onChange={() => handeChangeFilter('campanha')}
            sx={
              filtro === 'campanha' ? styles.checkboxSelected : styles.checkbox
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={styles.mapaContainer}>
        <MapaHome localizacoes={localizacoes} filtro={filtro} />
      </Grid>
    </>
  );
};

const MapaHome = ({localizacoes = [], filtro}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const painelService = usePainelService();

  const {filtrosProposta} = useSelector((store) => store.Proposta);

  const [centerMapLatLng] = useState({
    lat: centroBrasil.lat,
    lng: centroBrasil.lng,
  });
  const [transitLayerEnabled] = useState(false);
  const [infoWindowSettings, setInfoWindowSettings] = useState({
    visible: false,
    dados: null,
    marker: null,
    map: null,
  });
  const [timeoutId, setTimeoutId] = useState(undefined);

  const handleOpenPainel = (item) => {
    if (filtro === 'campanha') {
      return painelService.acessarPainelAnunciante(item.anuncianteId);
    }
    return painelService.acessarPainelComercial(item.id);
  };

  const dadosInfoWindow =
    infoWindowSettings && infoWindowSettings.dados
      ? infoWindowSettings.dados
      : null;

  const consultarFunil = (dados) => {
    var itemFiltro = {
      comercial: {
        value: dados.id,
        label: dados.nome,
      },
    };

    if (filtro === 'anunciante') {
      itemFiltro = {
        anunciante: {
          value: dados.id,
          label: dados.nome,
        },
      };
    }

    dispatch(
      persistFiltrosProposta({
        ...filtrosProposta,
        filtros: {
          ...itemFiltro,
          periodo: {label: 'Todas'},
        },
      }),
    );
    navigate(PROPOSTAS_ROUTE);
  };

  const abrirFormulario = (item) => {
    if (filtro === 'campanha') {
      return abrirCampanha(item);
    }
    return abrirComercial(item);
  };

  const abrirComercial = (dados) => {
    navigate(`${COMERCIAL_ROUTE}/${dados.id}/editar`);
  };

  const abrirCampanha = (dados) => {
    navigate(`${CAMPANHAS_ROUTE}/${dados.id}/editar`);
  };

  const handleMouseOut = () => {
    setTimeoutId(setTimeout(clearInfoWindow, 150));
  };

  const handleMouseIn = () => {
    clearTimeout(timeoutId);
  };

  const clearInfoWindow = () => {
    setInfoWindowSettings({
      visible: false,
      dados: null,
      marker: null,
      map: null,
    });
  };

  useEffect(() => {
    if (infoWindowSettings.visible) return;
    handleMouseIn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoWindowSettings]);

  return (
    <Grid container sx={styles.containerMapa} component={Paper} elevation={0}>
      <Map
        zoom={4}
        center={centerMapLatLng}
        locations={localizacoes}
        withBounds={localizacoes.length > 1 ? true : false}
        options={{
          mapTypeControl: false,
        }}>
        <TransitLayer enabled={transitLayerEnabled} />
        <InfoWindow {...infoWindowSettings}>
          <div
            className="iw-condutor-container"
            onMouseLeave={clearInfoWindow}
            onMouseEnter={handleMouseIn}>
            <div className="iw-condutor-info">
              <div className="iw-condutor-info-nome">
                <p>{dadosInfoWindow?.nome}</p>
              </div>
              <div className="iw-condutor-info-posicao">
                <Grid container direction="row" justifyContent="center">
                  <RoundTooltipButton
                    id="icon-gray"
                    title={'Acessar'}
                    icon={<OpenInNew />}
                    onClick={() => handleOpenPainel(dadosInfoWindow)}
                    tabIndex={-1}
                  />
                  <RoundTooltipButton
                    id="icon-primary"
                    title={'Visualizar'}
                    icon={<Visibility />}
                    onClick={() => abrirFormulario(dadosInfoWindow)}
                    tabIndex={-1}
                  />
                  {filtro === 'comercial' && (
                    <RoundTooltipButton
                      id="icon-yellow"
                      title={'Funil de vendas'}
                      icon={<Description />}
                      onClick={() => consultarFunil(dadosInfoWindow)}
                      tabIndex={-1}
                    />
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </InfoWindow>
        {localizacoes &&
          localizacoes.map((localizacao, index) => {
            return (
              <Marker
                key={index}
                active={false}
                position={{
                  lat: localizacao.latitude,
                  lng: localizacao.longitude,
                }}
                icon={
                  localizacao?.role === 'LICENCA_ADMINISTRADOR'
                    ? KmGreenIcon
                    : KmIcon
                }
                events={{
                  onMouseOver: (map, marker) => {
                    handleMouseIn();
                    clearInfoWindow();
                    setInfoWindowSettings({
                      visible: true,
                      marker,
                      map,
                      dados: {
                        ...localizacao,
                      },
                    });
                  },
                  onMouseOut: handleMouseOut,
                }}
              />
            );
          })}
      </Map>
    </Grid>
  );
};

const checkboxBasic = {
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(0deg, #EFF6FD, #EFF6FD)',
  borderRadius: '6px',
  paddingRight: '9px',
  whiteSpace: 'nowrap',
  margin: '0 16px 0 0 !important',

  '.MuiFormControlLabel-label': {
    display: 'block',
    color: '#636363',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  '.MuiSvgIcon-root': {
    fill: '#0091FF !important',
  },
};

const styles = {
  filtroContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    height: '60px',
    overflowX: 'hidden',
    backgroundColor: '#fff',
    flexWrap: 'wrap',
    padding: '0px 22px',
    borderRadius: '4px',
    boxShadow: '1px 6px 12px rgb(128 152 213 / 20%)',
  },
  mapaContainer: {
    paddingTop: '8px',
    overflow: 'hidden',
    height: (theme) => `calc(100vh - 60px - 16px - ${theme.toolbarHeight})`,
  },
  containerMapa: {
    borderRadius: '4px',
    backgroundColor: (theme) => theme.palette.paterns.white,
    padding: '0',
    height: '100%',
    cursor: 'default',
    overflow: 'hidden',
  },
  checkbox: {
    ...checkboxBasic,
  },
  checkboxSelected: {
    ...checkboxBasic,
    background: '#031663 !important',

    '.MuiFormControlLabel-label': {
      display: 'block',
      color: '#fff !important',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    '.MuiSvgIcon-root': {
      fill: '#007BE1 !important',
    },
  },
};

export default HomeGerenteComercial;
