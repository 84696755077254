import React, {useEffect, useState} from 'react';

export default function Circle({
  maps,
  map,
  kmRaio,
  latitude,
  longitude,
  fillColor = '#1a9cff',
  strokeColor = '#0091ff',
  onClick,
}) {
  const [circleMap, setCircleMap] = useState();
  

  useEffect(() => {

    
    if (!circleMap && kmRaio && latitude && longitude) {
      
        const circle = new maps.Circle({
          strokeColor,
          strokeOpacity: 0.1,
          strokeWeight: 12,
          fillColor,
          fillOpacity: 0.2,
          map: map,
          center: {
            lat: latitude,
            lng: longitude,
          },
          radius: parseFloat(kmRaio, 10) * 1000,
        });
        circle.addListener("click", onClick)
        setCircleMap(circle)
        
    }
    
    if (circleMap && !kmRaio) {
      circleMap.setMap(null);
      
    }
    if (circleMap && (!latitude || !longitude)) {
      circleMap.setMap(null);
    }
    if (circleMap && kmRaio && latitude && longitude) {
      circleMap.setMap(map);
      circleMap.setRadius(parseFloat(kmRaio, 10) * 1000);
      circleMap.setCenter({
        lat: latitude,
        lng: longitude,
      });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmRaio, latitude, longitude]);

  return <></>;
}
