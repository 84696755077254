import React from 'react';
import {Grid, Typography, Box, Modal, Slide} from '@mui/material';
import {ArrowForward} from '@mui/icons-material';
import EdicaoOrcamento from './edicao-orcamento';
import {getSidebarWidth} from '../../../theme/mui-theme';

const ViewOrcamentoModal = ({openModal, orcamento, proposta, onClose, ...props}) => {
  return (
    <Modal
      componentsProps={{
        backdrop: {
          sx: {
            background: 'rgb(0, 0, 0, 0.0)',
          },
        },
      }}
      onClose={onClose}
      open={openModal}
    >
      <Slide direction="left" in={openModal} appear>
        <Box sx={styles.modal}>
          <Grid sx={styles.container}>
            <Grid sx={styles.header}>
              <ArrowForward sx={styles.arrowBack} onClick={onClose} />
              <Typography sx={styles.headerTitle}>
                Proposta
              </Typography>
            </Grid>
            <Grid sx={styles.detalhesContainer}>
              <EdicaoOrcamento orcamento={orcamento} proposta={proposta} onClose={onClose} />
            </Grid>
          </Grid>
        </Box>
      </Slide>
    </Modal>
  );
};

const styles = {
  modal: {
    width: () => `calc(100vw - ${getSidebarWidth()} - 8px) !important`,
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    background: '#fff',
    position: 'absolute',
    right: '0px',
    height: (theme) => `calc(100% - 8px - ${theme.toolbarHeight})`,
    top: (theme) => `calc(8px + ${theme.toolbarHeight})`,

    '@media only screen and (max-width: 64em)': {
      flexBasis: '100%',
      maxWidth: () => `calc(100vw - ${getSidebarWidth()} - 8px) !important`,
    },
  },
  container: {
    width: '100%',
  },
  header: {
    height: '45px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2rem 1rem',
  },
  arrowBack: {
    color: '#657496',
    cursor: 'pointer'
  },
  headerTitle: {
    fontSize: '18px',
    textAlign: 'left',
    color: '#657496',
    marginLeft: '10px',
  },
  detalhesContainer: {
    flexDirection: 'row',
    height: 'calc(100vh - 130px)',
  },
};

export default ViewOrcamentoModal;
