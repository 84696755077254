import {useMutation} from '@apollo/client';
import {
  Block,
  Check,
  Edit,
  OpenInNew,
  RequestQuoteOutlined,
  Save,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';
import DragNDrop from '../../components/drag-n-drop';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../components/mui-button';
import InputDate from '../../components/mui-input-date';
import Select from '../../components/select/mui-select';
import {TextAreaV2} from '../../components/textarea/mui-textarea';
import {toastNotification} from '../../components/toastify';
import {formasPagamento} from '../../constants/enum-labels';
import {
  GERAR_CAMPANHAS,
  REPROVAR_PEDIDO,
  UPDATE_VALOR_REAJUSTE,
} from '../../graphql/mutation';
import usePainelService from '../../services/PainelService';
import {uploadAmazonS3Arquivo} from '../../services/UploadService';
import {LIGHT_COLORS} from '../../theme/colors/mui-colors';
import Number from '../../utils/number';
import DataEmitirNotaModal from '../campanhas/modais/data-emitir-nota-modal';
import ModalMotivoReprovacao from '../campanhas/modais/modal-motivo-reprovacao';

const FormularioPedido = ({pedido = {}, refetch}) => {
  const navigate = useNavigate();
  const {role} = useSelector((store) => store.Auth);
  const painelService = usePainelService();

  const [formData, setFormData] = useState({});
  const [fieldsError, setFieldsError] = useState({});
  const [movimentacao, setMovimentacao] = useState(null);
  const [openDataNotaModal, setOpenDataNotaModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileProducao, setFileProducao] = useState(null);
  const [modalMotivo, setModalMotivo] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [reprovando, setReprovando] = useState(false);
  const [aprovando, setAprovando] = useState(false);
  const [salvando, setSalvando] = useState(false);

  const [aprovarPedido] = useMutation(GERAR_CAMPANHAS);
  const [reprovar] = useMutation(REPROVAR_PEDIDO);
  const [salvarPedido] = useMutation(UPDATE_VALOR_REAJUSTE);

  const filtrarParcelas = (movimentacao) => {
    return (
      movimentacao.parcelaTipo === 'VEICULACAO' &&
      movimentacao.tipo === 'CREDITO'
    );
  };

  const filtrarParcelasProducao = (movimentacao) => {
    return (
      movimentacao.parcelaTipo === 'PRODUCAO' && movimentacao.tipo === 'CREDITO'
    );
  };

  const calcularDataParcela = (dataParcela, parcela) => {
    const data = moment(dataParcela).add(parcela, 'M');
    const diaSemana = data.isoWeekday();

    if ([6, 7].includes(diaSemana))
      return data.isoWeekday(5).format('DD/MM/YYYY');

    return data.format('DD/MM/YYYY');
  };

  const getFormaPagamento = (formaPagamento) => {
    if (!formaPagamento) return null;

    return {value: formaPagamento, label: formasPagamento[formaPagamento]};
  };

  useEffect(() => {
    let valorTotalProducao = pedido.itens
      .map(
        (item) =>
          Number.stringToFloat(item.producaoPorVeiculo || 0) *
          item.quantidadeVeiculos,
      )
      .reduce((total, item) => total + item, 0);

    setFormData({
      ...pedido,
      producaoPorVeiculo: Number.currencyFormat(pedido.producaoPorVeiculo),
      dataInicio: !pedido.dataInicio
        ? undefined
        : moment(`${pedido.dataInicio}`).toDate(),
      movimentacaoFinanceiras: pedido.movimentacaoFinanceiras,
      observacoes: pedido.observacoes || '',
      valorReajuste: Number.currencyFormat(pedido.valorReajuste),
      desconto: Number.currencyFormat(pedido.desconto),
      comissaoAgencia: Number.currencyFormat(pedido.comissaoAgencia),
      comissaoAgenciaPercent: Number.format(
        Number.regraDeTresPorcentagem(
          pedido.comissaoAgencia,
          pedido.valorTotalVeiculacaoMensal,
        ),
      ),
      comissaoTerceiros: Number.currencyFormat(pedido.comissaoTerceiros),
      comissaoTerceirosPercent: Number.format(
        Number.regraDeTresPorcentagem(
          pedido.comissaoTerceiros,
          pedido.valorTotalVeiculacaoMensal - pedido.comissaoAgencia,
        ),
      ),
      faturamentoProprioProducao: pedido.faturamentoProprioProducao
        ? {value: true, label: 'Sim'}
        : {value: false, label: 'Não'},
      faturarPor:
        pedido?.faturarPor === 'VALOR_BRUTO'
          ? {value: 'VALOR_BRUTO', label: 'Valor bruto'}
          : {value: 'VALOR_LIQUIDO', label: 'Valor líquido'},
      formaPagamentoVeiculacao: getFormaPagamento(
        pedido?.formaPagamentoVeiculacao,
      ),
      formaPagamentoProducao: getFormaPagamento(pedido?.formaPagamentoProducao),
      dataEmitirNota:
        pedido?.movimentacaoFinanceiras?.filter(filtrarParcelas)[0]
          ?.dataEmitirNota,
      dataEmitirNotaProducao: pedido.movimentacaoFinanceiras?.filter(
        filtrarParcelasProducao,
      )[0]?.dataEmitirNota,
      parcelasVeiculacao: pedido.parcelasVeiculacao,
      parcelasProducao: pedido.parcelasProducao,
      vencimentoParcelas: new Date().getTime() + 60 * 60 * 24 * 10 * 1000,
      vencimentoParcelasProducao:
        new Date().getTime() + 60 * 60 * 24 * 10 * 1000,
      vencimentoParcelasArr: pedido.movimentacaoFinanceiras
        ?.filter(filtrarParcelas)
        .map((m) => new Date(`${m.dataPrevisaoPgto}T00:00:00.000-03:00`)),
      vencimentoParcelasProducaoArr: pedido.movimentacaoFinanceiras
        ?.filter(filtrarParcelasProducao)
        .map((m) => new Date(`${m.dataPrevisaoPgto}T00:00:00.000-03:00`)),
      entrada: '0',
      vencimentoEntrada: new Date().getTime() + 60 * 60 * 24 * 10 * 1000,
      refazParcelamento: false,
      valorTotalProducao,
      valorTotalCampanhaBruto:
        pedido.valorTotalVeiculacaoMensal * pedido.quantidadeMeses +
        valorTotalProducao +
        valorTotalProducao * 0.1 * pedido.quantidadeMeses,
    });
  }, [pedido]);

  useEffect(() => {
    if (readOnly) return;

    setFormData((f) => ({
      ...f,
      valorTotalCampanha: Number.currencyFormat(
        Number.currencyToFloat(f.valorTotalCampanhaBruto) -
          Number.currencyToFloat(formData.valorReajuste),
      ),
    }));

    setFieldsError((f) => ({
      ...f,
      valorReajuste: Number.currencyToFloat(formData.valorReajuste) > 10,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.valorReajuste]);

  const handleSalvarPedido = async () => {
    if (fieldsError.valorReajuste || salvando) return;
    setSalvando(true);

    let response = undefined;
    let responseProducao = undefined;
    try {
      if (file) {
        response = await uploadAmazonS3Arquivo(file);
      }
      if (formData.faturarProducao && fileProducao) {
        responseProducao = await uploadAmazonS3Arquivo(fileProducao);
      }
    } catch (error) {
      console.log(error);
    }

    salvarPedido({
      variables: {
        orcamento: {
          id: pedido.id,
          valorReajuste: Number.currencyToFloat(formData.valorReajuste),
          valorTotalCampanha: Number.currencyToFloat(
            formData.valorTotalCampanha,
          ),
          piVeiculacao:
            response?.id || formData.piVeiculacao?.id
              ? {id: response?.id || formData.piVeiculacao?.id}
              : undefined,
          piProducao:
            responseProducao?.id || formData.piProducao?.id
              ? {id: responseProducao?.id || formData.piProducao?.id}
              : undefined,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Pedido salvo!', type: 'success'});
        setReadOnly(true);
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSalvando(false));
  };

  const handleAprovarPedido = () => {
    setAprovando(true);

    aprovarPedido({
      variables: {
        orcamento: {
          id: pedido.id,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Campanhas geradas!', type: 'success'});
        navigate('/propostas');
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setAprovando(false));
  };

  const reprovarPedido = () => {
    setModalMotivo(true);
  };

  const modalReprovacaoCancelar = () => {
    setModalMotivo(false);
  };

  const reprovarPedidoSubmit = (motivo) => {
    setModalMotivo(false);
    setReprovando(true);

    reprovar({
      variables: {
        orcamento: {
          id: pedido.id,
          reprovadoMotivo: motivo,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'O pedido foi reprovado com sucesso',
          type: 'success',
        });
        navigate('/propostas');
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setReprovando(false));
  };

  const renderParcelasVeiculacao = () => {
    const valorTotalLiquido =
      formData.valorTotalVeiculacaoMensal * formData.quantidadeMeses -
      Number.stringToFloat(formData.comissaoAgencia);
    const veiculacao = Array.from(
      Array(Number.safeParseInt(formData.parcelasVeiculacao)).keys(),
    );
    const parcelaVeiculacao =
      formData.faturarPor?.value === 'VALOR_LIQUIDO'
        ? (valorTotalLiquido - Number.stringToFloat(formData.valorReajuste)) /
          formData.parcelasVeiculacao
        : (formData.valorTotalVeiculacaoMensal * formData.quantidadeMeses -
            Number.stringToFloat(formData.valorReajuste)) /
          formData.parcelasVeiculacao;

    return veiculacao.map((_, index) => (
      <Grid container key={index}>
        <Typography sx={styles.fontParcela}>
          {`${index + 1}ª. ${Number.currencyFormat(
            parcelaVeiculacao,
          )} - ${calcularDataParcela(
            formData.dataVencimentoVeiculacao,
            index,
          )}`}
        </Typography>
        <Box ml="auto">
          <Typography sx={styles.fontParcela}>
            {`${calcularDataParcela(
              formData.dataEmissaoNotaVeiculacao,
              index,
            )}`}
          </Typography>
        </Box>
      </Grid>
    ));
  };

  const renderParcelasProducao = () => {
    const producao = Array.from(
      Array(
        Math.max(
          Number.safeParseInt(formData.parcelasProducao),
          formData.quantidadeMeses,
        ),
      ).keys(),
    );
    const valorTotalProducao = Number.stringToFloat(
      formData?.valorTotalProducao,
    );
    const reservaTecnica = valorTotalProducao * 0.1;
    const parcelaProducao = valorTotalProducao / formData.parcelasProducao;

    return producao.map((_, index) => {
      const valor = index < formData.parcelasProducao ? parcelaProducao : 0;
      return (
        <Grid container key={index}>
          <Typography sx={styles.fontParcela}>
            {`${index + 1}ª. ${Number.currencyFormat(
              valor + reservaTecnica,
            )} - ${calcularDataParcela(
              formData.dataVencimentoProducao,
              index,
            )}`}
          </Typography>
          <Box ml="auto">
            <Typography sx={styles.fontParcela}>
              {`${calcularDataParcela(
                formData.dataEmissaoNotaProducao,
                index,
              )}`}
            </Typography>
          </Box>
        </Grid>
      );
    });
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik initialValues={pedido}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputV2
                name="anunciante"
                label="Cliente"
                value={formData.anunciante?.nomeFantasia}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputV2
                name="titulo"
                label="Título"
                value={formData.proposta?.titulo}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputV2
                name="anunciante"
                label="Comercial do anunciante"
                value={formData.anunciante?.comercial?.nomeRazao}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputV2
                name="comercial"
                label="Comercial da campanha"
                value={formData.comercial?.nomeRazao}
                style={
                  formData.anunciante?.comercial?.id !== formData.comercial?.id
                    ? {border: '2px solid #00398E'}
                    : null
                }
                endAdornment={
                  <OpenInNew
                    titleAccess="Acessar painel"
                    onClick={() =>
                      painelService.acessarPainelComercial(
                        formData.comercial?.id,
                      )
                    }
                    style={{cursor: 'pointer'}}
                  />
                }
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <InputV2
                name="quantidadeCidades"
                label="Nº de cidades"
                value={formData.itens?.length || 0}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <InputV2
                name="quantidadeVeiculos"
                label="Nº de carros"
                value={formData.totalVeiculos}
                disabled
              />
            </Grid>
            <Grid item md={3}>
              <InputV2
                label="Períodos de campanha"
                endAdornment={
                  <Typography width="150px">
                    de {formData?.diasPeriodo} dias
                  </Typography>
                }
                value={formData.quantidadeMeses}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <InputDate
                label="Data de inicío"
                selected={formData.dataInicio}
                minDate={new Date()}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <InputV2
                label="Valor mensal da veiculação"
                mask="moeda"
                value={Number.currencyFormat(
                  formData.valorTotalVeiculacaoMensal,
                )}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <InputV2
                label="Valor total da veiculação"
                mask="moeda"
                value={Number.currencyFormat(
                  formData.valorTotalVeiculacaoMensal *
                    formData.quantidadeMeses,
                )}
                disabled
              />
            </Grid>
            <Grid container item xs={4} spacing={0}>
              <Grid item xs={7}>
                <InputV2
                  label="Valor produção total"
                  mask="moeda"
                  value={Number.currencyFormat(formData?.valorTotalProducao)}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <InputV2
                  label="Reserva técnica"
                  mask="moeda"
                  value={Number.currencyFormat(
                    Number.safeParseFloat(formData.valorTotalProducao) *
                      0.1 *
                      formData.quantidadeMeses,
                  )}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container item md={4} xs={8} spacing={0}>
              <Grid item xs={6}>
                <InputV2
                  label="Comissão da agência/mês"
                  name="comissaoAgenciaPercent"
                  mask="porcento"
                  value={formData.comissaoAgenciaPercent}
                  defaultValue={0}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <InputV2
                  sx={{width: '350px'}}
                  label="Valor"
                  name="comissaoAgencia"
                  mask="moeda"
                  value={formData.comissaoAgencia}
                  defaultValue={0}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid item md={2} xs={4}>
              <InputV2
                label="Comissão da agência/total"
                mask="moeda"
                defaultValue={0}
                value={Number.currencyFormat(
                  Number.stringToFloat(formData.comissaoAgencia) *
                    formData.quantidadeMeses || 0,
                )}
                disabled
              />
            </Grid>
            <Grid container item md={4} xs={8} spacing={0}>
              <Grid item xs={6}>
                <InputV2
                  label="Comissão de terceiros/mês"
                  name="comissaoTerceirosPercent"
                  mask="porcento"
                  value={formData.comissaoTerceirosPercent}
                  defaultValue={0}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <InputV2
                  sx={{width: '350px'}}
                  label="Valor"
                  name="comissaoTerceiros"
                  mask="moeda"
                  value={formData.comissaoTerceiros}
                  defaultValue={0}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid item md={2} xs={4}>
              <InputV2
                label="Comissão de terceiros/total"
                mask="moeda"
                defaultValue={0}
                value={Number.currencyFormat(
                  Number.stringToFloat(formData.comissaoTerceiros) *
                    formData.quantidadeMeses || 0,
                )}
                disabled
              />
            </Grid>
            <Grid item md={5} xs={4}>
              <InputV2
                label="Valor total da campanha"
                mask="moeda"
                value={Number.currencyFormat(formData.valorTotalCampanhaBruto)}
                disabled
              />
            </Grid>
            <Grid item md={2} xs={4}>
              <InputV2
                label="Valor de reajuste"
                mask="moeda"
                value={formData.valorReajuste}
                onChange={({target}) =>
                  setFormData({
                    ...formData,
                    valorReajuste: Number.currencyFormat(target.value),
                  })
                }
                mensagemSuporte={'Valor máximo R$ 10,00'}
                error={fieldsError.valorReajuste}
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={5} xs={4}>
              <InputV2
                label="Valor reajustado da campanha"
                mask="moeda"
                value={Number.currencyFormat(formData.valorTotalCampanha)}
                disabled
              />
            </Grid>
            <Grid container item xs={12} sx={styles.cidades}>
              <Grid item xs={12}>
                <Box padding={'8px 0 8px 18px'}>
                  <Typography sx={styles.labelFaturamento}>Cidades</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {pedido.itens.map((item) => (
                  <OrcamentoItem key={item.key || item.id} item={item} />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextAreaV2
                label="Observações"
                name="observacoes"
                value={formData.observacoes}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box padding="10px 0px 11px 0px">
                <Typography sx={styles.labelFaturamento}>
                  Faturamento da veiculação
                </Typography>
              </Box>
              <Box pt={2}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <InputV2
                      label="Formas de Pagamento"
                      value={formData.formaPagamentoVeiculacao?.label}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputV2
                      name="parcelas"
                      label="Parcelas"
                      value={formData.parcelasVeiculacao}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      label="Faturar por"
                      options={[
                        {value: 'VALOR_BRUTO', label: 'Valor bruto'},
                        {value: 'VALOR_LIQUIDO', label: 'Valor líquido'},
                      ]}
                      value={formData?.faturarPor}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Box sx={styles.boxParcelamento}>
                  <Typography sx={styles.parcelamentoTitulo}>
                    Parcelamento
                  </Typography>
                  <Typography sx={styles.parcelamentoTitulo}>
                    Emissão NF
                  </Typography>
                </Box>
                {renderParcelasVeiculacao()}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={styles.box}>
                <Typography sx={styles.labelFaturamento}>
                  Faturamento da produção
                </Typography>
                <Checkbox
                  checked={formData.faturarProducao || false}
                  disabled
                />
              </Box>
              {formData.faturarProducao && (
                <Box pt={2}>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={5}>
                        <InputV2
                          label="Formas de Pagamento"
                          value={formData.formaPagamentoProducao?.label}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputV2
                          name="parcelasProducao"
                          label="Parcelas"
                          value={formData.parcelasProducao}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={styles.boxParcelamento}>
                    <Typography sx={styles.parcelamentoTitulo}>
                      Parcelamento
                    </Typography>
                    <Typography sx={styles.parcelamentoTitulo}>
                      Emissão NF
                    </Typography>
                  </Box>
                  {renderParcelasProducao()}
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      sx={styles.pedidoInsercaoHeader}
                      style={{marginRight: '12px'}}>
                      P.I. (Veiculação)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{minHeight: 140}}>
                    <DragNDrop
                      id={pedido.piVeiculacao?.id}
                      file={file}
                      onChange={setFile}
                      onDelete={() => {
                        setFile(undefined);
                        setFormData((f) => ({...f, piVeiculacao: undefined}));
                      }}
                      fileName={file?.name || formData.piVeiculacao?.nome}
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {formData.faturarProducao && (
              <Grid item xs={6}>
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography sx={styles.pedidoInsercaoHeader}>
                        A.P. (Produção)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{minHeight: 140}}>
                      <DragNDrop
                        id={pedido.piProducao?.id}
                        onChange={setFileProducao}
                        onDelete={() => {
                          setFileProducao(undefined);
                          setFormData((f) => ({
                            ...f,
                            piProducao: undefined,
                          }));
                        }}
                        file={fileProducao}
                        fileName={
                          fileProducao?.name || formData.piProducao?.nome
                        }
                        disabled={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        </Formik>
        <Grid container spacing={2} mt="50px">
          <Grid
            container
            item
            xs={12}
            sx={
              !['ROLE_ADMINISTRADOR', 'ROLE_GERENTE_COMERCIAL'].includes(
                role,
              ) && {display: 'none'}
            }>
            <Grid item xs={6}>
              <Box sx={styles.box} justifyContent="flex-start">
                {readOnly && (
                  <ButtonComponent
                    value={'Editar'}
                    id="button"
                    icon={<Edit sx={styles.icon} />}
                    sx={{marginRight: '5px', width: '256px'}}
                    onClick={() => setReadOnly(false)}
                    disabled={aprovando || reprovando}
                  />
                )}
                {!readOnly && (
                  <ButtonComponent
                    id="button"
                    sx={{width: '256px'}}
                    value={'Salvar'}
                    icon={<Save sx={styles.icon} />}
                    loading={salvando}
                    onClick={handleSalvarPedido}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={styles.box} justifyContent="flex-end">
                <ButtonComponent
                  value={'Reprovar'}
                  id="button-cancel"
                  icon={<Block sx={styles.icon} />}
                  loading={reprovando}
                  sx={{marginRight: '5px', width: '256px'}}
                  onClick={reprovarPedido}
                  disabled={reprovando || aprovando || !readOnly}
                />
                <ButtonComponent
                  id="button-green"
                  sx={{width: '256px'}}
                  value={'Aprovar'}
                  icon={<Check sx={styles.icon} />}
                  loading={aprovando}
                  onClick={() =>
                    confirmAlertMui({
                      title: 'Aprovar pedido?',
                      message: `Cliente: ${
                        pedido?.anunciante?.nomeFantasia
                      } \nCidades: ${pedido.itens?.length || 0} cidade${
                        pedido.itens?.length !== 1 ? 's' : ''
                      } \nTotal de veículos: ${pedido.itens
                        ?.map((i) => i.quantidadeVeiculos)
                        .reduce((total, item) => total + item, 0)} veículos.`,
                      onConfirm: handleAprovarPedido,
                    })
                  }
                  disabled={reprovando || aprovando || !readOnly}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <ModalMotivoReprovacao
          openModal={modalMotivo}
          submitMotivo={reprovarPedidoSubmit}
          cancelar={modalReprovacaoCancelar}
        />
        <DataEmitirNotaModal
          openModal={openDataNotaModal}
          movimentacao={movimentacao}
          onClose={() => {
            setOpenDataNotaModal(false);
            setMovimentacao(null);
          }}
          onSave={() => refetch && refetch()}
        />
      </Paper>
    </Grid>
  );
};

const OrcamentoItem = ({item = {}}) => {
  const [orcamentoItem, setOrcamentoItem] = useState({});
  const [openTabela, setOpenTabela] = useState(false);

  useEffect(() => {
    setOrcamentoItem({
      ...item,
      valorFinalVeiculo: Number.currencyFormat(
        (item.modeloAnuncio?.valorVenda || 0) -
          Number.stringToFloat(item.valorDescontoPorVeiculo),
      ),
      valorDescontoPorVeiculo: Number.currencyFormat(
        item.valorDescontoPorVeiculo,
      ),
      valorMensalVeiculo: Number.currencyFormat(
        item.modeloAnuncio?.valorVenda || 0,
      ),
      valorTotalCidade: Number.currencyFormat(item.valorTotalCidade),
      desconto: Number.regraDeTresPorcentagem(
        item.valorDescontoPorVeiculo,
        item.modeloAnuncio?.valorVenda,
        true,
      ),
    });
  }, [item]);

  return (
    <Formik enableReinitialize initialValues={orcamentoItem}>
      <Grid sx={styles.cidadeRow}>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={4}>
            <InputV2
              name="cidade"
              label="Cidade"
              value={orcamentoItem.cidade?.nomeEUf}
              disabled
            />
          </Grid>
          <Grid item xs={5} sx={{display: 'flex', flexWrap: 'nowrap'}}>
            <InputV2
              name="modeloAnuncio"
              label="Tipo do anúncio"
              title={orcamentoItem.modeloAnuncio?.nome}
              value={orcamentoItem.modeloAnuncio?.nome}
              endAdornment={
                <Button
                  type="button"
                  title={'Tabela de preços'}
                  sx={styles.tabelaButton}
                  onClick={() =>
                    setOpenTabela(
                      (open) => !open && orcamentoItem?.modeloAnuncio?.id,
                    )
                  }>
                  <RequestQuoteOutlined />
                </Button>
              }
              disabled
            />
            <div style={{position: 'relative'}}>
              {openTabela && (
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                  sx={styles.tabelaModal}>
                  <Box>
                    <Typography sx={styles.tabelaModalTitle}>
                      Produção
                    </Typography>
                    <Typography
                      sx={styles.tabelaModalDesc}
                      style={{color: '#0000'}}>
                      {' '}
                      -{' '}
                    </Typography>
                    <Typography sx={styles.tabelaModalValue}>
                      {`${Number.currencyFormat(
                        orcamentoItem?.modeloAnuncio
                          ?.valorInstaladorPorInstalacao,
                      )}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={styles.tabelaModalTitle}>
                      Veiculação
                    </Typography>
                    <Typography
                      sx={styles.tabelaModalDesc}
                      style={{color: '#0000'}}>
                      {' '}
                      -{' '}
                    </Typography>
                    <Typography sx={styles.tabelaModalValue}>
                      {`${Number.currencyFormat(
                        orcamentoItem?.modeloAnuncio?.valorVenda,
                      )}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={styles.tabelaModalTitle}>
                      Veiculação
                    </Typography>
                    <Typography sx={styles.tabelaModalDesc}>
                      (Valor recomendado)
                    </Typography>
                    <Typography sx={styles.tabelaModalValue}>
                      {`${
                        Number.format(
                          orcamentoItem?.modeloAnuncio?.tabelaTres,
                        ) || 0
                      } % - ${Number.currencyFormat(
                        ((100 - orcamentoItem?.modeloAnuncio?.tabelaTres) /
                          100) *
                          orcamentoItem?.modeloAnuncio?.valorVenda,
                      )}`}
                    </Typography>
                  </Box>
                </Stack>
              )}
            </div>
          </Grid>
          <Grid item xs={1}>
            <InputV2
              label="Qtd. carros"
              name="quantidadeVeiculos"
              value={orcamentoItem.quantidadeVeiculos || ''}
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Valor por veículo"
              name="valorMensalVeiculo"
              mask="moeda"
              value={orcamentoItem.valorMensalVeiculo || 0}
              disabled
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          spacing={1}
          sx={{marginTop: '8px !important'}}>
          <Grid item xs={3}>
            <Box sx={styles.box}>
              <InputV2
                label="Desconto"
                name="desconto"
                mask="porcento"
                value={orcamentoItem.desconto || 0}
                defaultValue={0}
                disabled
              />
              <InputV2
                sx={{width: '350px'}}
                label="Valor"
                name="valorDescontoPorVeiculo"
                mask="moeda"
                value={orcamentoItem.valorDescontoPorVeiculo || 0}
                defaultValue={0}
                disabled
              />
            </Box>
          </Grid>
          <Grid item xs={1.9}>
            <InputV2
              label="Valor final por veículo"
              name="valorFinalVeiculo"
              mask="moeda"
              value={orcamentoItem.valorFinalVeiculo || 0}
              disabled
            />
          </Grid>
          <Grid item xs={1.6}>
            <InputV2
              label="Valor total"
              name="valorTotalCidade"
              mask="moeda"
              value={orcamentoItem.valorTotalCidade || 0}
              disabled
            />
          </Grid>
          <Grid item xs={1.6}>
            <InputV2
              label="Produção por carro"
              name="producaoPorVeiculo"
              value={Number.currencyFormat(orcamentoItem.producaoPorVeiculo)}
              disabled
            />
          </Grid>
          <Grid item xs={1.6}>
            <InputV2
              label="Produção total"
              name="producaoTotal"
              value={Number.currencyFormat(
                Number.stringToFloat(orcamentoItem.producaoPorVeiculo || 0) *
                  (orcamentoItem.quantidadeVeiculos || 0),
              )}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </Formik>
  );
};

const styles = {
  tabelaButton: {
    borderRadius: '0px 4px 4px 0px',
    height: '40px',
    position: 'absolute',
    top: '0px',
    right: '0px',
    paddingLeft: 1,
    width: '40px',
    minWidth: '40px',
    backgroundColor: '#afb7c9',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#afb7c9',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row !important',
    alignItems: 'center',
  },
  boxParcelamento: {
    display: 'flex',
    flexDirection: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px',
  },
  fontParcela: {
    display: 'flex',
    fontSize: '12px',
    color: '#6c7b9b',
    marginRight: '15px',
    alignItems: 'center',
  },
  editInputDateButton: {
    margin: '0px 5px',
    height: 30,
    width: 30,
    minWidth: 30,
    borderRadius: 20,
    color: '#657496',
  },
  labelFaturamento: {
    color: (theme) => theme.palette.paterns.navyBlue,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  pedidoInsercaoHeader: {
    fontWeight: '700',
    fontSize: 16,
    lineHeight: '36px',
    color: (theme) => theme.palette.paterns.navyBlue,
  },
  icon: {
    fontSize: '18px',
    marginLeft: '10px',
  },
  tabelaModal: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '10px 20px',
    top: '50px',
    left: '-300px',
    width: 'max-content',
    height: 'max-content',
    background: '#f7f7f7',
    zIndex: 5,
    borderRadius: '6px',

    '&::before': {
      right: '30px',
      top: '-12px',
      transform: 'scaleX(2)',
      fontSize: 16,
      height: 5,
      content: "'\\25B2'",
      color: '#f7f7f7',
      position: 'absolute',
    },
  },
  tabelaModalTitle: {
    fontSize: 12,
  },
  tabelaModalDesc: {
    fontSize: 10,
    margin: '-4px 0 4px 0',
  },
  tabelaModalValue: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  parcelamentoTitulo: {
    margin: ' 0 10px 5px 0',
    color: (theme) => theme.palette.paterns.navyBlue,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  cidades: {
    background: '#e4e9f3bf',
    borderRadius: '6px',
    padding: '0px !important',
    margin: '20px 0px 16px 16px',
  },
  cidadeRow: {
    background: '#fff',
    padding: '16px 8px 10px 8px',
    borderRadius: '6px',
    margin: '0 8px 8px 8px',
    border: `1px solid ${LIGHT_COLORS.blueGraySecundary}`,
  },
  licenciadoButton: {
    width: '256px',
    color: LIGHT_COLORS.green,
    border: `1px solid ${LIGHT_COLORS.green}`,
    cursor: 'default',

    '&:hover': {
      border: `1px solid ${LIGHT_COLORS.greenHover}`,
      backgroundColor: 'none',
    },
  },
};

export default FormularioPedido;
