import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  extratoRepasseMotoristaList: [],
  hasMore: false,
  totalExtratoRepasseMotorista: 0,
  refetchExtratoRepasseMotorista: false
};

const acaoAtualizarExtratoRepasseMotorista = state => {
  state.refetchExtratoRepasseMotorista = true;
};

const acaoPersistExtratoRepasseMotorista = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.extratoRepasseMotoristaList = content;
  state.hasMore = !last;
  state.totalExtratoRepasseMotorista = totalElements || 0;
  state.refetchExtratoRepasseMotorista = false;
};

const resumeSlice = createSlice({
  name: 'extratoRepasseMotorista',
  initialState: initialState,
  reducers: {
    persistExtratoRepasseMotorista: acaoPersistExtratoRepasseMotorista,
    atualizarExtratoRepasseMotorista: acaoAtualizarExtratoRepasseMotorista
  }
});

export const { actions, reducer } = resumeSlice;
export const {
  persistExtratoRepasseMotorista,
  atualizarExtratoRepasseMotorista
} = actions;
