import React, {useState, useEffect} from 'react';
import {Grid, Paper} from '@mui/material';
import {Field, Formik} from 'formik';
import {ButtonComponent} from '../../../components/mui-button';
import {SelectComercial} from '../../../containers/selects/mui-comercial';
import {SelectEstados} from '../../../containers/selects/mui-estados';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import Select from '../../../components/select/mui-select';
import {SelectSegmentos} from '../../../containers/selects/mui-segmento';
import ClientesPorAgencia from './por-agencia';
import ClientesPorAnunciante from './por-anunciante';
import ClientesPorSegmento from './por-segmento';

const ConsultaCarteiraClientes = () => {
  const [filtros, setFiltros] = useState({exibirPor: {value: 'ANUNCIANTE', label: 'Anunciante'}});
  const [searchDTO, setSearchDTO] = useState(filtros);

  const refetch = () => {
    setSearchDTO(filtros);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  component={SelectComercial}
                  name="comercial"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, comercial: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, estado: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, cidade: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={SelectSegmentos}
                  name="segmentoAtuacao"
                  label="Segmento de atuação"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, segmentoAtuacao: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  name="exibirPor"
                  label="Exibir por"
                  value={filtros.exibirPor}
                  onChange={(e) => setFiltros({...filtros, exibirPor: e})}
                  options={[
                    {value: 'AGENCIA', label: 'Agência'},
                    {value: 'ANUNCIANTE', label: 'Anunciante'},
                    {value: 'SEGMENTO', label: 'Segmento'},
                  ]}
                />
              </Grid>
              <Grid item xs={1}>
                <ButtonComponent
                  value="Filtrar"
                  onClick={refetch}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12}>
        {filtros?.exibirPor?.value === 'AGENCIA' && (
          <ClientesPorAgencia searchDTO={searchDTO} />
        )}
        {filtros?.exibirPor?.value === 'ANUNCIANTE' && (
          <ClientesPorAnunciante searchDTO={searchDTO} />
        )}
        {filtros?.exibirPor?.value === 'SEGMENTO' && (
          <ClientesPorSegmento searchDTO={searchDTO} />
        )}
      </Grid>
    </Grid>
  );
};

export default ConsultaCarteiraClientes;
