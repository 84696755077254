import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaCampanha from './consulta';

const Campanhas = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaCampanha />} />
    </Routes>
  );
};

export default Campanhas;
