import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_ESTADO_BY_ID} from '../../graphql/queries';
import FormularioEstado from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarEstado = () => {
  const {estadoId} = useParams();

  const estadoQuery = useQuery(FIND_ESTADO_BY_ID, {
    variables: {
      id: estadoId,
    },
  });

  const {data = {}} = estadoQuery;

  if (estadoQuery.loading || estadoQuery.error) {
    return (
      <LoadingPage loading={estadoQuery.loading} error={estadoQuery.error} />
    );
  }
  return (
    <FormularioEstado estado={data.estado} />
  );
};

export default EditarEstado;
