import React, {useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Button} from '@mui/material';
import ConsultaProposta from './consulta';
import EditarProposta from './editar';
import SimulacaoOrcamentoModal from '../../containers/modais/cadastro-orcamento/simulacao-orcamento-modal';
import EditarPedido from '../pedidos/editar';

const Proposta = () => {
  const {role} = useSelector((store) => store.Auth);

  const [openSimulacao, setOpenSimulacao] = useState(false);

  return (
    <React.Fragment>
      <Routes>
        <Route exact path="/" element={<ConsultaProposta />} />
        <Route path={`/:propostaId/editar`} element={<EditarProposta />} />
        <Route path={`/:pedidoId/aprovar`} element={<EditarPedido />} />
      </Routes>
      <Button
        id="button-green"
        toolbar="true"
        onClick={() => setOpenSimulacao(true)}
        sx={{
          display: role.includes('OPERACIONAL') ? 'none' : 'inline-flex',
          width: 185,
        }}
      >
        Nova simulação
      </Button>
      <SimulacaoOrcamentoModal
        isOpen={openSimulacao}
        onClose={() => setOpenSimulacao(false)}
      />
    </React.Fragment>
  );
};

export default Proposta;
