import {useMutation} from '@apollo/client';
import {ArrowForward, Save} from '@mui/icons-material';
import {Box, Grid, Modal, Slide, Stack, Typography} from '@mui/material';
import moment from 'moment';
import React, {useMemo, useState} from 'react';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {
  ButtonComponent,
  RoundTooltipButton,
} from '../../../components/mui-button';
import MuiSelect from '../../../components/select/mui-select';
import {toastNotification} from '../../../components/toastify';
import {meses} from '../../../constants/meses';
import {UPDATE_COMERCIAL_METAS} from '../../../graphql/mutation';
import {getSidebarWidth} from '../../../theme/mui-theme';
import Number from '../../../utils/number';

const ModalMetasVenda = ({
  comercial = {},
  pageSize = 20,
  openModal,
  onClose,
  meta,
  refetch,
  ...props
}) => {
  const [updateComercialMetas, {loading}] = useMutation(UPDATE_COMERCIAL_METAS);

  const [errors, setErrors] = useState({});
  const [filtros, setFiltros] = useState({});
  const [trimestre, setTrimestre] = useState({});
  const [total, setTotal] = useState(0);

  const selectkey = (index) => {
    let valueTest = (index + 1) / 3;
    switch (valueTest) {
      case 1:
        return 'primeiro';
      case 2:
        return 'segundo';
      case 3:
        return 'terceiro';
      case 4:
        return 'quarto';
      default:
        return;
    }
  };

  const calcTrimestreAno = (dados) => {
    const primeiro =
      Number.currencyToFloat(dados.janeiro) +
      Number.currencyToFloat(dados.fevereiro) +
      Number.currencyToFloat(dados.marco);
    const segundo =
      Number.currencyToFloat(dados.abril) +
      Number.currencyToFloat(dados.maio) +
      Number.currencyToFloat(dados.junho);
    const terceiro =
      Number.currencyToFloat(dados.julho) +
      Number.currencyToFloat(dados.agosto) +
      Number.currencyToFloat(dados.setembro);
    const quarta =
      Number.currencyToFloat(dados.outubro) +
      Number.currencyToFloat(dados.novembro) +
      Number.currencyToFloat(dados.dezembro);

    setTrimestre({
      primeiro: Number.currencyFormat(primeiro),
      segundo: Number.currencyFormat(segundo),
      terceiro: Number.currencyFormat(terceiro),
      quarto: Number.currencyFormat(quarta),
    });
    setTotal(Number.currencyFormat(primeiro + segundo + terceiro + quarta));
  };

  useMemo(() => {
    setErrors({});
    setFiltros({
      ano: meta?.ano || moment().format('YYYY'),
      metaCidade: meta.metaCidade?.id && {
        ...meta.metaCidade,
        value: meta.metaCidade.id,
        label: meta.metaCidade.cidade.nomeEUf,
      },
      janeiro: Number.currencyFormat(meta.janeiro || 0),
      fevereiro: Number.currencyFormat(meta.fevereiro || 0),
      marco: Number.currencyFormat(meta.marco || 0),
      abril: Number.currencyFormat(meta.abril || 0),
      maio: Number.currencyFormat(meta.maio || 0),
      junho: Number.currencyFormat(meta.junho || 0),
      julho: Number.currencyFormat(meta.julho || 0),
      agosto: Number.currencyFormat(meta.agosto || 0),
      setembro: Number.currencyFormat(meta.setembro || 0),
      outubro: Number.currencyFormat(meta.outubro || 0),
      novembro: Number.currencyFormat(meta.novembro || 0),
      dezembro: Number.currencyFormat(meta.dezembro || 0),
    });
    calcTrimestreAno(meta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta]);

  const handleInputMes = (target, item) => {
    const newFiltro = {...filtros};
    newFiltro[`${item.label.toLowerCase().replace('ç', 'c')}`] = target.value;

    setFiltros((preview) => ({
      ...preview,
      janeiro: Number.currencyFormat(newFiltro.janeiro || 0),
      fevereiro: Number.currencyFormat(newFiltro.fevereiro || 0),
      marco: Number.currencyFormat(newFiltro.marco || 0),
      abril: Number.currencyFormat(newFiltro.abril || 0),
      maio: Number.currencyFormat(newFiltro.maio || 0),
      junho: Number.currencyFormat(newFiltro.junho || 0),
      julho: Number.currencyFormat(newFiltro.julho || 0),
      agosto: Number.currencyFormat(newFiltro.agosto || 0),
      setembro: Number.currencyFormat(newFiltro.setembro || 0),
      outubro: Number.currencyFormat(newFiltro.outubro || 0),
      novembro: Number.currencyFormat(newFiltro.novembro || 0),
      dezembro: Number.currencyFormat(newFiltro.dezembro || 0),
    }));
    calcTrimestreAno(newFiltro);
  };

  const handleInputTrimestre = (target, index) => {
    const mes = Number.currencyToFloat(target.value) / 3;
    const newTrimestre = {...trimestre};
    newTrimestre[selectkey(index)] = target.value;
    const {primeiro, segundo, terceiro, quarto} = newTrimestre;
    const totalAno =
      Number.currencyToFloat(primeiro) +
      Number.currencyToFloat(segundo) +
      Number.currencyToFloat(terceiro) +
      Number.currencyToFloat(quarto);

    switch (index + 1) {
      case 3:
        setFiltros((preview) => ({
          ...preview,
          janeiro: Number.currencyFormat(mes),
          fevereiro: Number.currencyFormat(mes),
          marco: Number.currencyFormat(mes),
        }));
        break;
      case 6:
        setFiltros((preview) => ({
          ...preview,
          abril: Number.currencyFormat(mes),
          maio: Number.currencyFormat(mes),
          junho: Number.currencyFormat(mes),
        }));
        break;
      case 9:
        setFiltros((preview) => ({
          ...preview,
          julho: Number.currencyFormat(mes),
          agosto: Number.currencyFormat(mes),
          setembro: Number.currencyFormat(mes),
        }));
        break;
      case 12:
        setFiltros((preview) => ({
          ...preview,
          outubro: Number.currencyFormat(mes),
          novembro: Number.currencyFormat(mes),
          dezembro: Number.currencyFormat(mes),
        }));
        break;

      default:
        break;
    }
    setTrimestre(newTrimestre);
    setTotal(Number.currencyFormat(totalAno));
  };

  const handleInputTotal = (target, index) => {
    const mes = Number.currencyToFloat(target.value) / 12;
    const trimestreValue = Number.currencyToFloat(target.value) / 4;
    const filtroValue = filtros;
    meses.forEach(
      (item) =>
        (filtroValue[`${item.label.toLowerCase().replace('ç', 'c')}`] =
          Number.currencyFormat(mes)),
    );

    setFiltros(filtroValue);

    setTrimestre({
      primeiro: Number.currencyFormat(trimestreValue),
      segundo: Number.currencyFormat(trimestreValue),
      terceiro: Number.currencyFormat(trimestreValue),
      quarto: Number.currencyFormat(trimestreValue),
    });
    setTotal(target.value);
  };

  const handleCLickCreateMeta = () => {
    if (!filtros.metaCidade || !filtros.ano)
      return setErrors({metaCidade: !filtros.metaCidade, ano: !filtros.ano});

    updateComercialMetas({
      variables: {
        metas: {
          comercial: {
            id: comercial.id,
          },
          id: meta.id || null,
          ano: parseInt(filtros.ano),
          metaCidade: filtros.metaCidade && {id: filtros.metaCidade.value},
          janeiro: Number.currencyToFloat(filtros.janeiro || 0),
          fevereiro: Number.currencyToFloat(filtros.fevereiro || 0),
          marco: Number.currencyToFloat(filtros.marco || 0),
          abril: Number.currencyToFloat(filtros.abril || 0),
          maio: Number.currencyToFloat(filtros.maio || 0),
          junho: Number.currencyToFloat(filtros.junho || 0),
          julho: Number.currencyToFloat(filtros.julho || 0),
          agosto: Number.currencyToFloat(filtros.agosto || 0),
          setembro: Number.currencyToFloat(filtros.setembro || 0),
          outubro: Number.currencyToFloat(filtros.outubro || 0),
          novembro: Number.currencyToFloat(filtros.novembro || 0),
          dezembro: Number.currencyToFloat(filtros.dezembro || 0),
        },
      },
    })
      .then(() => {
        toastNotification({
          message: `Metas ${
            meta.id ? 'atualizadas' : 'cadastradas'
          } com sucesso!`,
          type: 'success',
        });
        onClose && onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      componentsProps={{
        backdrop: {
          sx: {
            background: 'rgb(0, 0, 0, 0.0)',
          },
        },
      }}
      {...props}>
      <Slide direction="left" in={openModal} appear>
        <Box sx={styles.modal}>
          <Stack
            direction="row"
            justifyContent={'flex-start'}
            alignItems="center">
            <RoundTooltipButton
              onClick={onClose}
              title={'Sair'}
              icon={<ArrowForward />}
              sx={{color: '#939eb6'}}
            />
            <Typography
              fontSize={'20px'}
              fontFamily="fontFamily.patternBold"
              color="#939eb6">
              {meta.id ? 'Atualizar Meta' : 'Cadastrar Meta'}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            sx={(theme) => ({
              ...theme.styledScroll,
              overflowY: 'auto',
              overflowX: 'hidden',
              height: ' calc(100% - 40px)',
              padding: '20px',
            })}>
            <Grid
              container
              flexDirection="row"
              columnGap={2}
              sx={{marginTop: '16px'}}>
              <Grid item xl={1} xs={2}>
                <InputV2
                  label="Ano"
                  value={filtros.ano}
                  onChange={({target}) =>
                    setFiltros((preview) => ({...preview, ano: target.value}))
                  }
                  error={errors['ano']}
                  disabled={!!meta.id}
                />
              </Grid>
              <Grid item xl={2} xs={3}>
                <MuiSelect
                  label="Cidade"
                  options={comercial.licencaCidades?.map((c) => ({
                    ...c,
                    value: c.id,
                    label: c.cidade?.nomeEUf,
                  }))}
                  value={filtros.metaCidade}
                  onChange={(e) =>
                    setFiltros((preview) => ({...preview, metaCidade: e}))
                  }
                  errors={errors['metaCidade']}
                  disabled={!!meta?.metaCidade?.id}
                />
              </Grid>
              <Grid item xl={2} xs={3}>
                <InputV2
                  label="Total"
                  value={total}
                  mask="moeda"
                  onChange={({target}) => handleInputTotal(target)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {meses.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={3} key={index}>
                    <InputV2
                      value={
                        filtros[`${item.label.toLowerCase().replace('ç', 'c')}`]
                      }
                      mask="moeda"
                      label={item.label}
                      onChange={({target}) => handleInputMes(target, item)}
                      sx={{background: '#fff'}}
                    />
                  </Grid>
                  {(index + 1) % 3 === 0 && (
                    <Grid item xs={3}>
                      <InputV2
                        value={trimestre[selectkey(index)]}
                        mask="moeda"
                        label={`${(index + 1) / 3}º Trimestre`}
                        onChange={({target}) =>
                          handleInputTrimestre(target, index)
                        }
                        sx={{background: '#fff'}}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
            <Grid container flexDirection="row">
              <ButtonComponent
                type="submit"
                sx={styles.buttonWrapper}
                value={meta.id ? 'Salvar' : 'Cadastrar'}
                icon={<Save />}
                onClick={handleCLickCreateMeta}
                loading={loading}
              />
            </Grid>
          </Stack>
        </Box>
      </Slide>
    </Modal>
  );
};
const styles = {
  modal: {
    width: () => `calc(100vw - ${getSidebarWidth()} - 10px) !important`,
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    background: '#fff',
    position: 'absolute',
    right: '0px',
    padding: '20px',
    height: (theme) => `calc(100% - 5px - ${theme.toolbarHeight})`,
    top: (theme) => `calc(5px + ${theme.toolbarHeight})`,
  },

  buttonWrapper: {
    width: '200px',
    marginTop: '30px',
  },
};

export default ModalMetasVenda;
