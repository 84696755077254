import React, {useEffect, useState} from 'react';
import {Grid, Modal, Box} from '@mui/material';
import {useMutation} from '@apollo/client';
import InputDate from '../../../components/mui-input-date';
import {UPDATE_DATA_EMITIR_NOTA} from '../../../graphql/mutation';
import {toastNotification} from '../../../components/toastify';
import {ButtonComponent} from '../../../components/mui-button';
import Data from '../../../utils/data';

const DataEmitirNotaModal = ({openModal, movimentacao, onClose, onSave}) => {
  const [data, setData] = useState(null);
  const [dataVencimento, setDataVencimento] = useState(null);

  const [updateData] = useMutation(UPDATE_DATA_EMITIR_NOTA);

  const handleSave = () => {
    if (!movimentacao?.id || !data) return;

    updateData({
      variables: {
        movimentacaoId: movimentacao.id,
        data: Data.dataFormat(data, 'YYYY-MM-DD'),
        dataVencimento: Data.dataFormat(dataVencimento, 'YYYY-MM-DD'),
      },
    })
      .then(() => {
        onSave && onSave();
        toastNotification({message: 'As datas foram salvas!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(onClose);
  };

  useEffect(() => {
    if (!movimentacao?.id) {
      setData(null);
      setDataVencimento(null);
      return;
    }
    if (movimentacao?.dataEmitirNota)
      setData(Date.parse(`${movimentacao.dataEmitirNota}T00:00:00.000-03:00`));
    if (movimentacao?.dataPrevisaoPgto)
      setDataVencimento(Date.parse(`${movimentacao.dataPrevisaoPgto}T00:00:00.000-03:00`));


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <Modal
      open={openModal}
      onClose={() => onClose()}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}
    >
      <Box sx={styles.boxContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputDate
              label="Data vencimento:"
              selected={dataVencimento}
              dateFormat={'dd/MM/yyyy'}
              onChange={(date) => setDataVencimento(date)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputDate
              label=" Data emissão NF:"
              selected={data}
              dateFormat={'dd/MM/yyyy'}
              onChange={(date) => setData(date)}
            />
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <ButtonComponent
                id="button-cancel"
                value="Cancelar"
                onClick={() => onClose()}
              />
            </Grid>
            <Grid item xs={6}>
              <ButtonComponent
                id="button"
                value="Salvar"
                onClick={handleSave}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal >
  );
};

const styles = {
  boxContainer: {
    background: '#fff',
    width: '300px',
    padding: '20px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default DataEmitirNotaModal;
