import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import {REPROVAR_MOVIMENTACAO_FINANCEIRA} from '../../graphql/mutation';
import {toastNotification} from '../../components/toastify';
import {ModalWithInput} from '../../containers/modais/aviso-modal/modais-aviso';

const ReprovarExtrato = ({
  isOpen,
  onClose,
  onReprovar,
  movimentacaoFinanceira = {},
}) => {
  const [motivo, setMotivo] = useState('');
  const [reprovarLoading, setReprovarLoading] = useState(false);

  const [reprovarMovimentacaoFinanceira] = useMutation(
    REPROVAR_MOVIMENTACAO_FINANCEIRA,
  );

  const handleClickDarBaixa = () => {
    setReprovarLoading(true);
    reprovarMovimentacaoFinanceira({
      variables: {
        id: movimentacaoFinanceira.id,
        observacao: motivo.trim(),
      },
    })
      .then(() => {
        setMotivo('');
        onReprovar && onReprovar();
        onClose();
        toastNotification({
          message: 'A movimentação financeira foi reprovada!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setReprovarLoading(false));
  };

  return (
    <ModalWithInput
      openModal={isOpen}
      textArea
      titulo="Reprovar movimentação"
      label="Observação"
      mensagem="Digite o motivo da reprovação"
      onClose={onClose}
      onClick={handleClickDarBaixa}
      inputOnChange={setMotivo}
      inputValue={motivo}
      ButtonSecundary="Reprovar"
      loadingButton={reprovarLoading}
      idButtonSecundary="button-cancel"
      idButtonPrimary="button-primary-empty"
    />
  );
};

export default ReprovarExtrato;
