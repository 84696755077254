import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_BY_ID_MARCA_VEICULO} from '../../graphql/queries';
import FormularioMarcaVeiculo from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarMarcaVeiculo = () => {
  const {marcaId} = useParams();

  const marcaQuery = useQuery(FIND_BY_ID_MARCA_VEICULO, {
    variables: {
      id: marcaId,
    },
  });

  const {data = {}} = marcaQuery;

  if (marcaId.loading || marcaId.error) {
    return (
      <LoadingPage loading={marcaId.loading} error={marcaId.error} />
    );
  }

  return (
    <FormularioMarcaVeiculo marca={data.marca} />
  );
};

export default EditarMarcaVeiculo;
