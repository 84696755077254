import gql from 'graphql-tag';

export const TROCAR_SENHA = gql`
  mutation trocarSenha($novaSenha: String, $senhaAtual: String) {
    trocarSenha(novaSenha: $novaSenha, senhaAtual: $senhaAtual) {
      tokenClienteRegistrado
    }
  }
`;

export const ATUALIZAR_COMERCIAL = gql`
  mutation atualizarComercial($comercial: ComercialInput) {
    updateComercial(comercial: $comercial) {
      id
    }
  }
`;

export const CRIAR_COMERCIAL = gql`
  mutation criarComercial($comercial: ComercialInput) {
    comercial: createComercial(comercial: $comercial) {
      id
    }
  }
`;

export const UPDATE_LICENCA_CIDADES = gql`
  mutation updateLicencaCidades($comercial: ComercialInput) {
    comercial: updateLicencaCidades(comercial: $comercial) {
      id
    }
  }
`;

export const CRIAR_OFICINA = gql`
  mutation criarOficina($oficina: OficinaInput) {
    oficina: createOficina(oficina: $oficina) {
      id
    }
  }
`;

export const ATUALIZAR_OFICINA = gql`
  mutation atualizarOficina($oficina: OficinaInput) {
    oficina: updateOficina(oficina: $oficina) {
      id
    }
  }
`;

export const DELETAR_OFICINA = gql`
  mutation deleteOficina($oficina: OficinaInput) {
    deleteOficina(oficina: $oficina)
  }
`;

export const CRIAR_ADMINISTRADOR = gql`
  mutation createAdministrador($administrador: AdministradorInput) {
    createAdministrador(administrador: $administrador) {
      id
    }
  }
`;

export const ATUALIZAR_ADMINISTRADOR = gql`
  mutation updateAdministrador($administrador: AdministradorInput) {
    updateAdministrador(administrador: $administrador) {
      id
    }
  }
`;

export const ATUALIZAR_INSTALADOR = gql`
  mutation atualizarInstalador($instalador: InstaladorInput) {
    updateInstalador(instalador: $instalador) {
      id
    }
  }
`;

export const ATUALIZAR_CAMPANHA = gql`
  mutation atualizarCampanha($campanha: CampanhaInput, $parcelamento: ParcelamentoDTOInput) {
    updateCampanha(campanha: $campanha, parcelamento: $parcelamento) {
      id
    }
  }
`;

export const ATUALIZAR_MODELO_ANUNCIO = gql`
  mutation atualizarModeloAnuncio($modeloAnuncio: ModeloAnuncioInput) {
    updateModeloAnuncio(modeloAnuncio: $modeloAnuncio) {
      id
    }
  }
`;

export const CRIAR_INSTALADOR = gql`
  mutation criarInstalador($instalador: InstaladorInput) {
    createInstalador(instalador: $instalador) {
      id
    }
  }
`;

export const CRIAR_ANUNCIANTE = gql`
  mutation criarAnunciante($anunciante: AnuncianteInput) {
    createAnunciante(anunciante: $anunciante) {
      id
    }
  }
`;

export const CRIAR_CAMPANHA = gql`
  mutation criarCampanha($campanha: CampanhaInput) {
    createCampanha(campanha: $campanha) {
      id
    }
  }
`;
export const CRIAR_ANUNCIO_IMAGEM_VEICULO = gql`
  mutation createAnuncioImagemVeiculo($anuncioImagem: AnuncioImagemVeiculoInput) {
    createAnuncioImagemVeiculo(anuncioImagem: $anuncioImagem) {
      anuncio{
        id
      }
    }
  }
`;
export const APROVAR_MOTORISTA = gql`
  mutation AprovarMotorista($id: UUID) {
    aprovarMotorista(motoristaId: $id) {
      id
    }
  }
`;

export const RESETAR_SENHA = gql`
  mutation ResetarSenha($id: UUID) {
    resetarSenha(usuario: {id: $id})
  }
`;

export const TROCAR_MOTORISTA_DO_ANUNCIO = gql`
  mutation trocarMotoristaDoAnuncio(
    $motoristaDestino: UUID
    $motoristaOrigem: UUID
    $valorOrigem: BigDecimal
    $valorDestino: BigDecimal
  ) {
    trocarMotoristaDoAnuncio(
      motoristaDestino: $motoristaDestino
      motoristaOrigem: $motoristaOrigem
      valorOrigem: $valorOrigem
      valorDestino: $valorDestino
    )
  }
`;

export const APROVAR_IMAGENS_INSTALACAO = gql`
  mutation AprovarImagensInstalacao(
    $anuncio: AnuncioInput
    $imagensInstalacao: AnuncioImagemVeiculoInput
  ) {
    aprovarImagensInstalacao(
      anuncio: $anuncio
      imagensInstalacao: $imagensInstalacao
    ) {
      id
      data
      dataConfirmacao
      usuarioConfirmou {
        nome
      }
    }
  }
`;

export const APROVAR_IMAGENS_MENSAL = gql`
  mutation AprovarImagensMensal(
    $anuncio: AnuncioInput
    $imagensMensal: AnuncioImagemVeiculoInput
  ) {
    aprovarImagensMensal(anuncio: $anuncio, imagensMensal: $imagensMensal) {
      id
      data
      dataConfirmacao
      usuarioConfirmou {
        nome
      }
    }
  }
`;

export const REPROVAR_IMAGENS = gql`
  mutation ReprovarImagens(
    $anuncio: AnuncioInput
    $imagens: AnuncioImagemVeiculoInput
  ) {
    reprovarImagens(anuncio: $anuncio, imagens: $imagens) {
      id
      data
      dataConfirmacao
      usuarioConfirmou {
        nome
      }
    }
  }
`;
export const APROVAR_MOVIMENTACAO_MOTORISTA = gql`
  mutation aprovarMovimentacaoMotorista(
    $valor: BigDecimal
    $imagem: AnuncioImagemVeiculoInput
  ) {
    aprovarMovimentacaoMotorista(valor: $valor imagem: $imagem)
  }
`;

export const REPROVAR_MOVIMENTACAO_MOTORISTA = gql`
  mutation reprovarMovimentacaoMotorista($imagem: AnuncioImagemVeiculoInput) {
    reprovarMovimentacaoMotorista(imagem: $imagem)
  }
`;
export const INICIAR_CAMPANHA_MANUALMENTE = gql`
  mutation IniciarCampanhaManualmente(
    $campanhaCidade: CampanhaCidadeInput
    $motoristas: [MotoristaInput]
    $instalador: InstaladorInput
  ) {
    iniciarCampanhaManualmente(
      campanhaCidade: $campanhaCidade
      motoristas: $motoristas
      instalador: $instalador
    ) {
      id
    }
  }
`;

export const ADICIONAR_MOTORISTAS_CAMPANHA = gql`
  mutation adicionarMotoristasNaCampanha(
    $campanhaCidade: CampanhaCidadeInput
    $motoristas: [UUID]
  ) {
    adicionarMotoristasNaCampanha(
      campanhaCidade: $campanhaCidade
      motoristas: $motoristas
    ) {
      id
    }
  }
`;

export const APROVAR_IMAGENS_REMOCAO = gql`
  mutation AprovarImagensRemocao(
    $anuncio: AnuncioInput
    $imagensRemocao: AnuncioImagemVeiculoInput
  ) {
    aprovarImagensRemocao(anuncio: $anuncio, imagensRemocao: $imagensRemocao) {
      id
      data
      dataConfirmacao
      usuarioConfirmou {
        nome
      }
    }
  }
`;

export const REPROVAR_MOTORISTA = gql`
  mutation reprovarMotorista($motoristaId: UUID, $motivo: String) {
    reprovarMotorista(motoristaId: $motoristaId, motivo: $motivo) {
      id
    }
  }
`;

export const ESTORNO_BAIXA_MOVIMENTACAO_FINANCEIRA = gql`
  mutation EstornoBaixaMovimentacaoFinanceira($id: UUID) {
    estornoBaixaMovimentacaoFinanceira(id: $id) {
      id
    }
  }
`;

export const BAIXA_MOVIMENTACAO_FINANCEIRA = gql`
  mutation baixaMovimentacaoFinanceira($movimentacao: MovimentacaoFinanceiraInput) {
    baixaMovimentacaoFinanceira(movimentacao: $movimentacao) {
      id
    }
  }
`;

export const BAIXAR_MULTIPLAS_MOVIMENTACOES = gql`
  mutation baixarMultiplasMovimentacoes($ids: [UUID]) {
    baixas: baixarMultiplasMovimentacoes(ids: $ids)
  }
`;

export const REPROVAR_MULTIPLAS_MOVIMENTACOES = gql`
  mutation reprovarMultiplasMovimentacoes($ids: [UUID]) {
    reprovados: reprovarMultiplasMovimentacoes(ids: $ids)
  }
`;

export const ESTORNAR_MULTIPLAS_MOVIMENTACOES = gql`
  mutation estornarMultiplasMovimentacoes($ids: [UUID]) {
    estornos: estornarMultiplasMovimentacoes(ids: $ids)
  }
`;

export const MARCAR_ERRO_MULTIPLAS_MOVIMENTACOES = gql`
  mutation marcarErroMultiplasMovimentacoes($ids: [UUID]) {
    movimentacoes: marcarErroMultiplasMovimentacoes(ids: $ids)
  }
`;

export const REPROVAR_MOVIMENTACAO_FINANCEIRA = gql`
  mutation reprovarMovimentacaoFinanceira($id: UUID, $observacao: String) {
    reprovarMovimentacaoFinanceira(id: $id, observacao: $observacao) {
      id
    }
  }
`;

export const REPROVA_IMAGENS_VEICULO = gql`
  mutation ReprovaImagensVeiculo($veiculoId: UUID, $obs: String) {
    reprovaImagensVeiculo(veiculoId: $veiculoId, obs: $obs) {
      id
    }
  }
`;

export const REPROVA_DOCUMENTO_VEICULO = gql`
  mutation ReprovaDocumentoVeiculo($veiculoId: UUID, $obs: String) {
    reprovaDocumentoVeiculo(veiculoId: $veiculoId, obs: $obs) {
      id
    }
  }
`;

export const REPROVA_MOTORISTA_COMPROVANTE_RESIDENCIA = gql`
  mutation ReprovaMotoristaComprovanteResidencia(
    $motoristaId: UUID
    $obs: String
  ) {
    reprovaMotoristaComprovanteResidencia(
      motoristaId: $motoristaId
      obs: $obs
    ) {
      id
    }
  }
`;

export const REPROVA_MOTORISTA_CNH = gql`
  mutation ReprovaMotoristaCnh($motoristaId: UUID, $obs: String) {
    reprovaMotoristaCnh(motoristaId: $motoristaId, obs: $obs) {
      id
    }
  }
`;

export const CRIAR_AVALIACAO_MOTORISTA = gql`
  mutation criarAvaliacaoMotorista(
    $avaliacaoMotorista: AvaliacaoMotoristaInput
  ) {
    criarAvaliacaoMotorista(avaliacaoMotorista: $avaliacaoMotorista) {
      id
    }
  }
`;

export const INICIAR_CAMPANHA = gql`
  mutation IniciarCampanha(
    $campanha: CampanhaInput
    $instalador: InstaladorInput
  ) {
    iniciarCampanha(campanha: $campanha, instalador: $instalador) {
      id
    }
  }
`;

export const SUSPENDER_CAMPANHA = gql`
  mutation SuspenderCampanha($campanha: CampanhaInput) {
    suspenderCampanha(campanha: $campanha) {
      id
    }
  }
`;

export const CRIAR_EMAIL_CONTATO = gql`
  mutation criarEmailContato(
    $mensagem: String
    $telefone: String
    $titulo: String
    $email: String
    $tipoDuvida: TipoEmailContato
  ) {
    createEmailContato(
      emailContato: {
        mensagem: $mensagem
        telefone: $telefone
        titulo: $titulo
        email: $email
        tipoDuvida: $tipoDuvida
      }
    ) {
      id
    }
  }
`;

export const ATUALIZAR_CONFIGURACOES = gql`
  mutation salvarConfiguracoes($configuracoes: [ConfiguracaoInput]) {
    salvarConfiguracoes(configuracoes: $configuracoes) {
      key
      value
    }
  }
`;

export const APROVA_CAMPANHA = gql`
  mutation AprovaCampanha($campanha: CampanhaInput) {
    aprovarCampanha(campanha: $campanha) {
      id
    }
  }
`;

export const DESAPROVAR_CAMPANHA = gql`
  mutation desaprovarCampanha($campanha: CampanhaInput) {
    desaprovarCampanha(campanha: $campanha) {
      id
    }
  }
`;

export const REPROVAR_PEDIDO = gql`
  mutation reprovarPedido($orcamento: OrcamentoInput) {
    reprovarPedido(orcamento: $orcamento)
  }
`;

export const CORRIGIR_ODOMETRO_MENSAL = gql`
  mutation corrigirValorOdometroMensal(
    $imagensMensal: AnuncioImagemVeiculoInput
  ) {
    corrigirValorOdometroMensal(imagensMensal: $imagensMensal) {
      id
      data
      dataConfirmacao
      usuarioConfirmou {
        nome
      }
    }
  }
`;

export const FINALIZAR_ANUNCIO = gql`
  mutation finalizarAnuncio($anuncio: AnuncioInput) {
    finalizarAnuncio(anuncio: $anuncio) {
      id
      situacao
    }
  }
`;

export const GERAR_TOKEN_ANUNCIANTE = gql`
  mutation accessTokenAnuncianteByAdmin($id: UUID) {
    accessTokenAnuncianteByAdmin(id: $id) {
      accessToken
      tokenType
      expiresIn
      refreshToken
      scope
    }
  }
`;

export const GERAR_TOKEN_COMERCIAL = gql`
  mutation accessTokenComercialByAdmin($id: UUID) {
    accessTokenComercialByAdmin(id: $id) {
      accessToken
      tokenType
      expiresIn
      refreshToken
      scope
    }
  }
`;

export const CRIAR_MODELO_VEICULO = gql`
  mutation createModelo($modelo: ModeloInput) {
    createModelo(modelo: $modelo) {
      id
      nome
      marca {
        id
        nome
      }
    }
  }
`;

export const ATUALIZAR_MODELO_VEICULO = gql`
  mutation updateModelo($modelo: ModeloInput) {
    updateModelo(modelo: $modelo) {
      id
      nome
      marca {
        id
        nome
      }
    }
  }
`;

export const CRIAR_MARCA_VEICULO = gql`
  mutation createMarca($marca: MarcaInput) {
    createMarca(marca: $marca) {
      id
      nome
    }
  }
`;

export const ATUALIZAR_MARCA_VEICULO = gql`
  mutation updateMarca($marca: MarcaInput) {
    updateMarca(marca: $marca) {
      id
      nome
    }
  }
`;

export const SAVE_MOLDE_VEICULO = gql`
  mutation saveMoldeVeiculo($molde: MoldeVeiculoInput) {
    molde: saveMoldeVeiculo(molde: $molde) {
      id
      marca {
        id
        nome
      }
      modelo {
        id
        nome
      }
      anoFabricacao
      anoModelo
    }
  }
`;

export const DELETE_MOLDE_VEICULO = gql`
  mutation deleteMoldeVeiculo($molde: MoldeVeiculoInput) {
    deleteMoldeVeiculo(molde: $molde)
  }
`;

export const UPDATE_MOLDE_VEICULO = gql`
  mutation updateMoldeVeiculo($veiculo: VeiculoInput, $molde: UUID) {
    molde: updateMoldeVeiculo(veiculo: $veiculo, molde: $molde) {
      id
      moldeVeiculo {
        id
      }
    }
  }
`;

export const ENVIAR_EMAIL_MOLDES = gql`
  mutation enviarEmailMoldes(
    $campanha: CampanhaInput
    $anuncio: AnuncioInput
    $email: String
  ) {
    enviarEmailMoldes(campanha: $campanha, anuncio: $anuncio, email: $email)
  }
`;

export const ATUALIZAR_ESTADO = gql`
  mutation updateEstado($estado: EstadoInput) {
    updateEstado(estado: $estado) {
      id
      nome
      uf
    }
  }
`;

export const ATUALIZAR_CIDADE = gql`
  mutation updateCidade($cidade: CidadeInput) {
    updateCidade(cidade: $cidade) {
      id
      nome
    }
  }
`;

export const UPDATE_PEDIDO = gql`
  mutation updatePedido(
    $campanha: CampanhaInput
    $parcelamento: ParcelamentoDTOInput
  ) {
    updatePedido(campanha: $campanha, parcelamento: $parcelamento) {
      id
    }
  }
`;

export const ALTERAR_IMAGENS_VALIDACAO = gql`
  mutation trocarImagens($anuncioImagem: AnuncioImagemVeiculoInput) {
    imagens: trocarImagens(anuncioImagem: $anuncioImagem) {
      id
      imagemDiagonalDireitaFrontal
      imagemDiagonalEsquerdaTraseira
      imagemOdometro
      imagemLateralEsquerda
      imagemLateralDireita
      imagemInterna
      imagemFrente
      imagemTraseira
    }
  }
`;

export const AGENDAR_INSTALACAO_ANUNCIO = gql`
  mutation agendarInstalacaoAnuncio(
    $anuncio: AnuncioInput
    $dataHoraAgendamento: LocalDateTime
    $instalador: InstaladorInput
  ) {
    anuncio: agendarInstalacaoAnuncio(
      anuncio: $anuncio
      dataHoraAgendamento: $dataHoraAgendamento
      instalador: $instalador
    ) {
      id
    }
  }
`;

export const GERAR_NOVA_SENHA_COMERCIAL = gql`
  mutation gerarNovaSenhaUsuarioComercial($comercial: ComercialInput) {
    senha: gerarNovaSenhaUsuarioComercial(comercial: $comercial)
  }
`;

export const GERAR_NOVA_SENHA_INSTALADOR = gql`
  mutation gerarNovaSenhaUsuarioInstalador($instalador: InstaladorInput) {
    senha: gerarNovaSenhaUsuarioInstalador(instalador: $instalador)
  }
`;

export const UPDATE_OBSERVACAO_MOTORISTA = gql`
  mutation updateObservacaoMotorista($motoristaId: UUID, $observacao: String) {
    updateObservacaoMotorista(
      motoristaId: $motoristaId
      observacao: $observacao
    ) {
      id
    }
  }
`;

export const UPDATE_MOTORISTA_STATUS = gql`
  mutation updateMotoristaStatus(
    $motoristaId: UUID
    $situacao: MotoristaSituacao
  ) {
    updateMotoristaStatus(motoristaId: $motoristaId, situacao: $situacao) {
      id
    }
  }
`;

export const UPDATE_AVALIACAO_MOTORISTA = gql`
  mutation updateAvaliacaoMotorista(
    $avaliacaoMotorista: AvaliacaoMotoristaInput
  ) {
    updateAvaliacaoMotorista(avaliacaoMotorista: $avaliacaoMotorista) {
      id
    }
  }
`;

export const UPDATE_INDICACOES_PAGAS_MOTORISTA = gql`
  mutation updateIndicacoesPagasMotorista(
    $motoristaId: UUID
    $indicacoesPagas: Int
  ) {
    updateIndicacoesPagasMotorista(
      motoristaId: $motoristaId
      indicacoesPagas: $indicacoesPagas
    ) {
      id
    }
  }
`;

export const ESTENDER_CAMPANHA = gql`
  mutation estenderCampanha($campanha: CampanhaInput, $meses: BigInteger) {
    estenderCampanha(campanha: $campanha, meses: $meses) {
      id
    }
  }
`;

export const FORCAR_FINALIZAR_ANUNCIOS = gql`
  mutation forcarFinalizarAnuncios($campanha: CampanhaInput) {
    forcarFinalizarAnuncios(campanha: $campanha)
  }
`;

export const MOVIMENTACAO_NOTA_EMITIDA = gql`
  mutation marcarNotaEmitida($movimentacao: MovimentacaoFinanceiraInput) {
    marcarNotaEmitida(movimentacao: $movimentacao)
  }
`;
export const REVERTER_NOTA_EMITIDA = gql`
  mutation reverterNotaEmitida($movimentacao: MovimentacaoFinanceiraInput) {
    reverterNotaEmitida(movimentacao: $movimentacao)
  }
`;

export const UPDATE_DATA_EMITIR_NOTA = gql`
  mutation updateDataEmitirNota(
    $movimentacaoId: UUID
    $data: LocalDate
    $dataVencimento: LocalDate
  ) {
    updateDataEmitirNota(
      movimentacaoId: $movimentacaoId
      data: $data
      dataVencimento: $dataVencimento
    )
  }
`;

export const ENVIAR_NOTIFICAO_MOTORISTAS = gql`
  mutation enviarNotificaoMotoristas(
    $titulo: String
    $mensagem: String
    $searchDTO: SearchDTOInput
  ) {
    notificacoes: enviarNotificaoMotoristas(
      titulo: $titulo
      mensagem: $mensagem
      searchDTO: $searchDTO
    )
  }
`;

export const SALVAR_NOTIFICAO_MOTORISTAS = gql`
  mutation saveNotificacao($notificacao: NotificacaoInput) {
    notificacao: saveNotificacao(notificacao: $notificacao) {
      id
    }
  }
`;

export const EXCLUIR_NOTIFICACAO = gql`
  mutation excluirNotificacao($notificacao: NotificacaoInput) {
    excluirNotificacao(notificacao: $notificacao)
  }
`;

export const REVERTER_IMAGEM_ANUNCIO = gql`
  mutation reverterSituacaoImagens($anuncioImagem: AnuncioImagemVeiculoInput) {
    imagem: reverterSituacaoImagens(anuncioImagem: $anuncioImagem) {
      id
    }
  }
`;

export const UPDATE_ANUNCIO_OBSERVACAO_MONITORAMENTO = gql`
  mutation updateObservacaoMonitoramento(
    $anuncio: AnuncioInput
    $observacao: String
  ) {
    updateObservacaoMonitoramento(anuncio: $anuncio, observacao: $observacao)
  }
`;

export const SAVE_CIDADE_PONTO_INTERESSE = gql`
  mutation savePontoInteresse(
    $cidade: CidadeInput
    $pontoInteresse: CidadePontoInteresseInput
  ) {
    savePontoInteresse(cidade: $cidade, pontoInteresse: $pontoInteresse)
  }
`;

export const DELETE_CIDADE_PONTO_INTERESSE = gql`
  mutation deletePontoInteresse(
    $cidade: CidadeInput
    $pontoInteresse: CidadePontoInteresseInput
  ) {
    deletePontoInteresse(cidade: $cidade, pontoInteresse: $pontoInteresse)
  }
`;

export const SAVE_CIDADE_PALAVRA_CHAVE = gql`
  mutation savePalavraChave(
    $cidade: CidadeInput
    $palavraChave: CidadePalavraChaveInput
  ) {
    savePalavraChave(cidade: $cidade, palavraChave: $palavraChave)
  }
`;

export const DELETE_CIDADE_PALAVRA_CHAVE = gql`
  mutation deletePalavraChave(
    $cidade: CidadeInput
    $palavraChave: CidadePalavraChaveInput
  ) {
    deletePalavraChave(cidade: $cidade, palavraChave: $palavraChave)
  }
`;

export const UPDATE_COMERCIAL_AGENCIA_DO_ANUNCIANTE = gql`
  mutation updateComercialEAgenciaDoAnunciante($anunciante: AnuncianteInput) {
    updateComercialEAgenciaDoAnunciante(anunciante: $anunciante) {
      id
    }
  }
`;
export const UPDATE_ANUNCIANTE_BY_ADMIN = gql`
  mutation updateAnuncianteByAdmin($anunciante: AnuncianteInput) {
    updateAnuncianteByAdmin(anunciante: $anunciante) {
      id
    }
  }
`;
export const UPDATE_AGENCIA = gql`
  mutation updateAgencia($agencia: AgenciaInput) {
    updateAgencia(agencia: $agencia) {
      id
    }
  }
`;
export const EXCLUIR_AGENCIA_BY_ADMIN = gql`
mutation excluirAgenciaByAdmin($agencia: AgenciaInput){
  excluirAgenciaByAdmin(agencia: $agencia)
}
`;
export const GERAR_SENHA_ADMINISTRADOR = gql`
  mutation gerarSenhaAdminstrador($administrador: AdministradorInput) {
    gerarSenhaAdminstrador(administrador: $administrador)
  }
`;

export const CREATE_EVENTO_AGENDA = gql`
  mutation createEventoAgenda($eventoAgenda: EventoAgendaInput) {
    createEventoAgenda(eventoAgenda: $eventoAgenda) {
      id
    }
  }
`;
export const CREATE_COMERCIAL_METAS = gql`
  mutation createComercialMetas($metas: ComercialMetasInput) {
    createComercialMetas(metas: $metas) {
      comercial {
        id
      }
    }
  }
`;
export const UPDATE_COMERCIAL_METAS = gql`
  mutation updateComercialMetas($metas: ComercialMetasInput) {
    updateComercialMetas(metas: $metas) {
      id
    }
  }
`;
export const EXCLUIR_COMERCIAL_METAS = gql`
  mutation excluirComercialMetas($metas: ComercialMetasInput) {
    excluirComercialMetas(metas: $metas)
  }
`;

export const UPDATE_EVENTO_AGENDA = gql`
  mutation updateEventoAgenda($eventoAgenda: EventoAgendaInput) {
    updateEventoAgenda(eventoAgenda: $eventoAgenda) {
      id
    }
  }
`;

export const AJUSTAR_KM_PERIODO_MOTORISTA = gql`
  mutation ajustarQuilometrosDurantePeriodo(
    $anuncio: AnuncioInput
    $valorOdometro: BigDecimal
  ) {
    ajustarQuilometrosDurantePeriodo(
      anuncio: $anuncio
      valorOdometro: $valorOdometro
    )
  }
`;

export const UPDATE_VEICULO_MOTORISTA = gql`
  mutation updateVeiculoByAdmin($veiculo: VeiculoInput) {
    updateVeiculoByAdmin(veiculo: $veiculo)
  }
`;

export const UPDATE_PERMITE_ALTERAR_VEICULO = gql`
  mutation updatePermiteAlterarVeiculo($veiculoId: UUID) {
    updatePermiteAlterarVeiculo(veiculoId: $veiculoId)
  }
`;

export const UPDATE_SEGMENTO_ATUACAO = gql`
  mutation updateSegmentoAtuacao($segmento: SegmentoAtuacaoInput) {
    segmento: updateSegmentoAtuacao(segmento: $segmento) {
      id
      descricao
      value: id
      label: descricao
    }
  }
`;

export const DELETE_SEGMENTO_ATUACAO = gql`
  mutation deleteSegmentoAtuacao($segmento: SegmentoAtuacaoInput) {
    deleteSegmentoAtuacao(segmento: $segmento)
  }
`;

export const UPDATE_EXCLUIR_MOTORISTA = gql`
  mutation solicitarExclusaoMotorista($motorista: MotoristaInput) {
    solicitarExclusaoMotorista(motorista: $motorista)
  }
`;

export const CRIAR_ARQUIVO = gql`
  mutation createArquivo($marca: MarcaInput) {
    createArquivo(marca: $marca) {
      id
      nome
    }
  }
`;
export const ATUALIZAR_ARQUIVO = gql`
  mutation updateArquivo($arquivo: ArquivoInput) {
    updateArquivo(arquivo: $arquivo) {
      id
      titulo
    }
  }
`;
export const DELETAR_ARQUIVO = gql`
  mutation deleteArquivo($arquivo: ArquivoInput) {
    deleteArquivo(arquivo: $arquivo)
  }
`;

export const GERAR_CAMPANHAS_ORCAMENTO = gql`
  mutation gerarPedidosOrcamento(
    $orcamento: OrcamentoInput
    $campanha: CampanhaInput
    $parcelamento: ParcelamentoDTOInput
  ) {
    gerarPedidosOrcamento(orcamento: $orcamento, campanha: $campanha, parcelamento: $parcelamento)
  }
`;

export const GERAR_CAMPANHAS = gql`
  mutation gerarCampanha($orcamento: OrcamentoInput) {
    gerarCampanha(orcamento: $orcamento)
  }
`;

export const CADASTRAR_ARQUIVO_COMERCIAL = gql`
  mutation cadastrarArquivoComercial($arquivo: ArquivoInput) {
    cadastrarArquivoComercial(arquivo: $arquivo)
  }
`;

export const EXCLUIR_ARQUIVO_COMERCIAL = gql`
  mutation excluirArquivoComercial($arquivo: ArquivoInput) {
    excluirArquivoComercial(arquivo: $arquivo)
  }
`;

export const DELETE_ANUNCIANTE = gql`
  mutation deleteAnunciante($anunciante: AnuncianteInput) {
    deleteAnunciante(anunciante: $anunciante)
  }
`;

export const REPROVAR_CAMPANHAS = gql`
  mutation reprovarCampanhas($campanha: CampanhaInput, $motivo: String) {
    reprovarCampanhas(campanha: $campanha, motivo: $motivo)
  }
`;

export const UPDATE_VALOR_REAJUSTE = gql`
  mutation updateValorReajuste($orcamento: OrcamentoInput) {
    updateValorReajuste(orcamento: $orcamento) {
      id
    }
  }
`;

export const ATUALIZAR_METRICAS_CIDADES_IBGE = gql`
  mutation atualizarMetricasIBGE {
    cidades: atualizarMetricasIBGE
  }
`;

export const FINALIZAR_PROPOSTA = gql`
  mutation finalizarProposta($propostaId: UUID) {
    finalizarProposta(propostaId: $propostaId)
  }
`;

export const REPROVAR_PROPOSTA = gql`
  mutation reprovarProposta($propostaId: UUID) {
    reprovarProposta(propostaId: $propostaId)
  }
`;