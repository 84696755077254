import React from 'react';
import FormularioModeloVeiculo from './formulario';

const CadastrarModeloVeiculo = () => {
  return (
    <FormularioModeloVeiculo />
  );
};

export default CadastrarModeloVeiculo;
