import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  motoristas: [],
  filtrosMotorista: {
    filtros: {
      possuiAnuncioAtivo: {value: undefined, label: 'Todos'},
      situacao: {value: undefined, label: 'Todos'},
      motoristaTemIndicacoes: {value: undefined, label: 'Todos'},
      possuiBorrachao: {label: 'Todos'},
      tipoVeiculo: {label: 'Todos'},
      pinturaOriginal: {label: 'Todos'},
    },
    page: 0,
    orderBy: {campo: 'dataCadastro', asc: false}
  },
  hasMore: false,
  totalCampanhas: 0,
  refetchCampanhas: false,
};

const acaoAtualizarMotoristas = (state) => {
  state.refetchMotoristas = true;
};

const acaoPersistMotoristas = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.motoristas = content;
  state.hasMore = !last;
  state.totalMotoristas = totalElements || 0;
};

const acaoPersistFiltrosMotorista = (state, action) => {
  state.filtrosMotorista = action.payload;
};

const resumeSlice = createSlice({
  name: 'motorista',
  initialState: initialState,
  reducers: {
    persistMotoristas: acaoPersistMotoristas,
    atualizarMotoristas: acaoAtualizarMotoristas,
    persistFiltrosMotorista: acaoPersistFiltrosMotorista
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistMotoristas,
  atualizarMotoristas,
  persistFiltrosMotorista
} = actions;
