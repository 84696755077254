import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  marcasVeiculo: [],
  filtrosMarcasVeiculo: {
    filtros: {
    },
    page: 0,
    orderBy: {campo: 'nome', asc: true}
  },
  totalMarcasVeiculo: 0,
  refetchMarcasVeiculo: false
  
  
};

const acaoAtualizarMarcasVeiculo = state => {
  state.refetchMarcasVeiculo = true;
};

const acaoPersistMarcasVeiculo = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.marcasVeiculo = content || [];
  state.hasMore = !last;
  state.totalMarcasVeiculo = totalElements || 0;
  state.refetchMarcasVeiculo = false;
};
const acaoPersistFiltrosMarcasVeiculo = (state, action) => {
  state.filtrosMarcasVeiculo = action.payload;
};

const resumeSlice = createSlice({
  name: 'marcasVeiculos',
  initialState: initialState,
  reducers: {
    persistMarcasVeiculo: acaoPersistMarcasVeiculo,
    atualizarMarcasVeiculo: acaoAtualizarMarcasVeiculo,
    persistFiltrosMarcasVeiculo: acaoPersistFiltrosMarcasVeiculo
  }
});


export const { actions, reducer } = resumeSlice;
export const { persistMarcasVeiculo, atualizarMarcasVeiculo, persistFiltrosMarcasVeiculo } = actions;