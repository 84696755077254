import React from 'react';
import FormularioModeloAnuncio from './formulario';

const CadastrarModeloAnuncio = () => {
  return (
    <FormularioModeloAnuncio />
  );
};

export default CadastrarModeloAnuncio;
