const campanhaSituacao = {
  AGUARDANDO_APROVACAO: 'Aguardando aprovação',
  NAO_APROVADA: 'Não aprovada',
  AGUARDANDO_IMPRESSAO: 'Aguardando impressão',
  AGUARDANDO_INSTALACAO: 'Aguardando instalação',
  ATIVA: 'Ativa',
  ATIVA_PARCIAL: 'Ativa parcial',
  PENDENTE: 'Aguardando motoristas',
  SUSPENSA: 'Suspensa',
  CANCELADA: 'Cancelada',
  FINALIZADA: 'Finalizada',
};

const anuncioSituacao = {
  AGUARDANDO_TROCA_MOTORISTA: 'Aguardando troca de motorista',
  AGUARDANDO_ACEITE_MOTORISTA: 'Aguardando aceite do motorista',
  SUSPENSO: 'Suspenso',
  AGUARDANDO_IMPRESSAO: 'Aguardando impressão',
  AGUARDANDO_AGENDAMENTO_INSTALACAO: 'Aguardando agendamento da instalação',
  AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO:
    'Aguardando aceite do agendamento da instalação',
  AGUARDANDO_INSTALACAO: 'Aguardando instalação',
  AGUARDANDO_IMAGEM_INSTALACAO: 'Aguardando imagem de instalação',
  ATIVO: 'Ativo',
  AGUARDANDO_REMOCAO: 'Aguardando remoção',
  FINALIZAR: 'Finalizar',
  FINALIZADO: 'Finalizado',
};

const imagemAnuncioTipo = {
  INSTALACAO: 'Instalação',
  MENSAL: 'Mensal',
  REMOCAO: 'Remoção',
};

const motoristaSituacaoApp = {
  OK: 'Ok',
  ACEITAR_CAMPANHA: 'Aceitar a campanha',
  ACEITAR_AGENDAMENTO_INSTALACAO: 'Aceitar o agendamento da instalação',
  FOTO_MENSAL: 'Enviar foto mensal',
  REMOVER_ANUNCIO: 'Remover o anúncio',
  AGUARDANDO_TROCA_MOTORISTA: 'Aguardando troca do motorista',
  AGUARDANDO_IMPRESSAO: 'Aguardando impessão',
  AGUARDANDO_INSTALACAO: 'Aguardando instalação',
  AGUARDANDO_OUTRO_AGENDAMENTO_INSTALACAO:
    'Aguardando reagendamento da instalação',
  FOTO_COMPROVANTE_REPROVADO: 'Reenviar foto do comprovante',
  FOTO_VEICULO_REPROVADO: 'Reenviar fotos do veículo',
  FOTO_INSTALACAO: 'Enviar fotos de instalação',
};

const formasPagamento = {
  CARTAO: 'Cartão',
  BOLETO: 'Boleto',
  TRANSFERENCIA_BANCARIA: 'Transferência Bancária',
  PERMUTA: 'Permuta',
};

const tipoContaBancaria = {
  POUPANCA: 'Poupança',
  CORRENTE: 'Corrente',
};

const orcamentoSituacao = {
  ORCAMENTO: 'Prospecção',
  EM_NEGOCIACAO: 'Em negociação',
  NEGOCIACAO_AVANCADA: 'Negociação avançada',
  AGUARDANDO_PI: 'Aguardando PI',
  AGUARDANDO_APROVACAO: 'Aguardando aprovação',
  APROVADO: 'Aprovado',
  REPROVADO: 'Reprovado',
};

const motoristaSituacao = {
  APROVADO: 'Aprovado',
  ATUALIZADO: 'Atualizado',
  COMPLETO: 'Completo',
  INCOMPLETO: 'Incompleto',
  REPROVADO: 'Reprovado',
  INATIVO: 'Inativo',
  EXCLUIDO: 'Excluído',
};

const movimentacaoSituacao = {
  APROVADO: 'Aprovado',
  PENDENTE: 'Pendente',
  FINALIZADO: 'Pago',
  REPROVADO: 'Reprovado',
  ERRO_PAGAMENTO: 'Erro no pagamento',
};

const movimentacaoTipoParcela = {
  VEICULACAO: 'Veiculação',
  PRODUCAO: 'Produção',
};

const auncioImagemSituacao = {
  PENDENTE: 'Pendente',
  APROVADO: 'Aprovado',
  REPROVADO: 'Reprovado',
};

const faturarPor = {
  VALOR_BRUTO: 'Valor bruto',
  VALOR_LIQUIDO: 'Valor líquido',
};

const eventoSituacao = {
  PENDENTE: 'Pendente',
  CANCELADO: 'Cancelado',
  CONCLUIDO: 'Concluído',
  ATRASADO: 'Atrasado',
  REAGENDADO: 'Reagendado',
  AGENDADO: 'Agendado',
  PESSOAL: 'Pessoal',
};

const usuarioRole = {
  ROLE_PROSPECTOR: 'SDR',
  ROLE_COMERCIAL: 'Representante',
  ROLE_ADMINISTRADOR: 'Diretoria',
  ROLE_GERENTE_COMERCIAL: 'Dir. Comercial',
  ROLE_GERENTE_OPERACIONAL: 'Dir. Operacional',
  ROLE_FINANCEIRO: 'Financeiro',
  ROLE_OPERACIONAL: 'Operacional',
};

const anuncianteTipo = {
  ANUNCIANTE: 'Anunciante',
  AGENCIA: 'Agência',
};

const comercialRole = {
  COMERCIAL: 'Representante',
  LICENCA_ADMINISTRADOR: 'Licença',
  LICENCA_COMERCIAL: 'Executivo',
};

export {
  anuncianteTipo, anuncioSituacao, auncioImagemSituacao, campanhaSituacao, comercialRole, eventoSituacao, faturarPor, formasPagamento, imagemAnuncioTipo, motoristaSituacao, motoristaSituacaoApp, movimentacaoSituacao,
  movimentacaoTipoParcela, orcamentoSituacao, tipoContaBancaria, usuarioRole
};

