import React from 'react';
import {Grid, Typography} from '@mui/material';
import moment from 'moment';
import styles from '../styles';
import String from '../../../utils/string';
import {movimentacaoSituacao, tipoContaBancaria} from '../../../constants/enum-labels';
import Number from '../../../utils/number';

const DetalhesRoyalties = ({dados = {}}) => {
  const calcularTotal = (valor, meses) => {
    if (isNaN(valor) || isNaN(meses)) return ' - ';

    return Number.currencyFormat(valor * meses);
  };

  const filtrarParcelas = (movimentacao) => {
    return movimentacao.parcelaTipo === 'VEICULACAO'
      && movimentacao.tipo === "CREDITO";
  };

  const quantidadeParcelas = () => {
    return dados.campanha?.movimentacaoFinanceiras?.filter(filtrarParcelas).length
  };

  return (
    <Grid sx={styles.detalhesContainer} className={`styled-scroll`}>
      <Typography sx={styles.blockHeader}>
        Dados lançamento
      </Typography>
      <Grid container sx={styles.blockContainer}>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Comercial</Typography>
          <Typography sx={styles.inputValue}>
            {dados.campanha?.comercial?.nomeRazaoResponsavel || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Anunciante</Typography>
          <Typography sx={styles.inputValue}>
            {dados.campanha?.anunciante?.nomeFantasia || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Campanha</Typography>
          <Typography sx={styles.inputValue}>
            {`[${dados.campanha?.numero}] ${dados.campanha?.titulo}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Modelo anúncio</Typography>
          <Typography sx={styles.inputValue}>
            {dados.campanha?.modeloAnuncio?.nome || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Vencimento</Typography>
          <Typography sx={styles.inputValue}>
            {dados.dataPrevisaoPgto ? moment(dados.dataPrevisaoPgto).format('DD/MM/YYYY') : '-'}
          </Typography>
        </Grid>
        {dados.parcelaTipo !== 'ROYALTY_EXPORTACAO' && (
          <Grid item xs={3}>
            <Typography sx={styles.label}>Tipo</Typography>
            <Typography sx={styles.inputValue}>
              Crédito
            </Typography>
          </Grid>
        )}
        <Grid item xs={3}>
          <Typography sx={styles.label}>Total veiculação</Typography>
          <Typography sx={styles.inputValue}>
            {dados.campanha?.valorVeiculacao
              ? Number.currencyFormat(dados.campanha?.valorVeiculacao)
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Total agência</Typography>
          <Typography sx={styles.inputValue}>
            {calcularTotal(dados.campanha?.repasseAgencia, dados.campanha?.quantidadeMeses)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Total terceiros</Typography>
          <Typography sx={styles.inputValue}>
            {calcularTotal(dados.campanha?.repasseTerceiros, dados.campanha?.quantidadeMeses)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Comissão</Typography>
          {dados.parcelaTipo === 'ROYALTY_EXPORTACAO' && (
            <Typography sx={styles.inputValue}>
              {`${Number.format(dados.licencaRecebimento?.porcentagemImportacao)}%`}
            </Typography>
          )}
          {dados.parcelaTipo !== 'ROYALTY_EXPORTACAO' && (
            <Typography sx={styles.inputValue}>
              {`${Number.format(dados.comercial?.porcentagemValorRepasse)}%`}
            </Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Nº parcelas</Typography>
          <Typography sx={styles.inputValue}>
            {quantidadeParcelas() || '-'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={styles.label}>Valor</Typography>
          <Typography sx={styles.inputValue}>
            {dados.valor
              ? Number.currencyFormat(dados.valor) : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Typography sx={styles.blockHeader}>
        {dados?.tipo === 'CREDITO' ?
          'Dados pagador' : 'Dados recebedor'}
      </Typography>
      <Grid container sx={styles.blockContainer}>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Razão social</Typography>
          <Typography sx={styles.inputValue}>
            {dados.licencaRecebimento?.nomeRazao || 'Km Mídia'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Banco</Typography>
          <Typography sx={styles.inputValue}>
            {dados.licencaRecebimento?.dadoBancario?.banco ?
              `${dados.licencaRecebimento.dadoBancario.banco.codigoBanco} - ${dados.licencaRecebimento.dadoBancario.banco.nomeBanco}` : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Ag. / Conta</Typography>
          <Typography sx={styles.inputValue}>
            {`${dados.licencaRecebimento?.dadoBancario?.agencia || '-'} / ${dados.licencaRecebimento?.dadoBancario?.conta || '-'}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Tipo de Conta</Typography>
          <Typography sx={styles.inputValue}>
            {dados.licencaRecebimento?.dadoBancario?.tipoContaBancaria ?
              tipoContaBancaria[dados.licencaRecebimento?.dadoBancario?.tipoContaBancaria] : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Nome Favorecido</Typography>
          <Typography sx={styles.inputValue}>
            {dados.licencaRecebimento?.dadoBancario?.nomeFavorecido || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>CPF/CNPJ Favorecido</Typography>
          <Typography sx={styles.inputValue}>
            {String.formatCpfCnpj(dados.licencaRecebimento?.dadoBancario?.cpfCnpjFavorecido)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>Chave PIX</Typography>
          <Typography sx={styles.inputValue}>
            {dados.licencaRecebimento?.dadoBancario?.chavePix || '-'}
          </Typography>
        </Grid>
      </Grid>
      {['FINALIZADO', 'REPROVADO'].includes(dados.situacao) && (
        <>
          <Typography sx={styles.blockHeader}>
            Dados pagamento
          </Typography>
          <Grid>
            <Grid container sx={styles.blockContainer}>
              <Grid item xs={2}>
                <Typography sx={styles.label}>Data pagamento</Typography>
                <Typography sx={styles.inputValue}>
                  {dados.dataPgto ? moment(dados.dataPgto).format('DD/MM/YYYY') : ''}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.label}>Situação</Typography>
                <Typography sx={styles.inputValue}>
                  {movimentacaoSituacao[dados.situacao]}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.label}>
                  {dados.tipo === 'CREDITO' ? 'Valor Recebido' : 'Valor Pago'}
                </Typography>
                <Typography sx={styles.inputValue}>
                  {dados.valorPago ? Number.currencyFormat(dados.valorPago) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>Código do Comprovante</Typography>
                <Typography sx={styles.inputValue}>{dados.codigoComprovante || ''}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.label}>Usuário</Typography>
                <Typography sx={styles.inputValue}>{dados.usuarioBaixa?.nome || ''}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>Observação</Typography>
                <Typography sx={styles.inputValue}>{dados.obs || ''}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>)
      }
    </Grid>
  );
}

export default DetalhesRoyalties;