import React from 'react';
import {makeStyles} from '@mui/styles';
import {Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import moment from 'moment';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Number from '../../utils/number';
import 'moment/locale/pt-br';

const Divisor = require('../../assets/images/divisor-relatorio.png');

const ImpressaoRelatorioCampanhasCidade = ({cidades = [], totais = {}}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} className={classes.reportContainer}>
        <Grid item xs={12} style={{margin: '0 10px'}}>
          <img
            alt={'divisor'}
            style={{width: '100%', margin: '5px 0'}}
            src={Divisor}
          />
        </Grid>
        <Grid item xs={12} className={classes.reportHeader}>
          <Grid item xs={10}>
            <Typography
              className={classes.titleReport}
              style={{fontWeight: '700'}}>
              Campanhas por cidade
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <KmMidiaLogo style={{width: '80%'}} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{fontWeight: '700'}}>
                <TableCell className={classes.tableHead}>Cidade</TableCell>
                <TableCell className={classes.tableHead}>Campanhas</TableCell>
                <TableCell className={classes.tableHead}>Veículos</TableCell>
                <TableCell className={classes.tableHead}>Km total</TableCell>
                <TableCell className={classes.tableHead}>
                  Km / período / veículo
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cidades.map((item, index) => (
                <TableRow tabIndex={-1} key={`item${index}`}>
                  <TableCell className={classes.tableCell}>
                    {item.cidadeNome}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item.campanhas}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item.veiculos}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {Number.formatQuilometro(item.kmTotal)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {Number.formatQuilometro(
                      item.kmPeriodo / item.veiculos || 1,
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow tabIndex={-1}>
                <TableCell className={classes.tableHead}>
                  {`Total: ${cidades.length} cidades`}
                </TableCell>
                <TableCell className={classes.tableHead}>
                  {`${totais?.campanhas || 0} campanhas`}
                </TableCell>
                <TableCell className={classes.tableHead}>
                  {`${totais?.veiculos || 0} veículos`}
                </TableCell>
                <TableCell className={classes.tableHead}>
                  {`${Number.formatQuilometro(totais?.kmTotal)} Km`}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell style={{whiteSpace: 'nowrap', border: 'none'}}>
                  <Typography
                    className={classes.footerText}
                    style={{height: 50}}>
                    <br />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
        <Grid
          item
          xs={12}
          className={`${classes.footer} page-footer`}
          style={{display: 'none'}}>
          <Grid item xs={12} className={classes.footerLogoContainer}>
            <KmMidiaLogo className={classes.footerLogo} />
            <Typography className={classes.footerText}>
              Valorizando sua marca a cada KM rodado!
            </Typography>
          </Grid>
          <img
            alt={'divisor'}
            style={{width: '100%', margin: '10px 0'}}
            src={Divisor}
          />
          <Grid className={classes.footerTextContainer}>
            <Typography
              className={
                classes.footerTextRodape
              }>{`© Copyright ${moment().format(
              'YYYY',
            )}, KM Mídia CNPJ: 33.793.168/0001-50. Todos os direitos reservados.`}</Typography>
            <Typography
              className={classes.footerTextRodape}>{`Emissão: ${moment().format(
              'DD/MM/YYYY',
            )}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const tableCell = {
  fontSize: '10px',
  color: '#000',
};

const useStyles = makeStyles(() => ({
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 14,
    color: '#031663',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  footerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ' space-between',
    marginRight: '10px',
  },
  tableHead: {
    ...tableCell,
    fontWeight: '700',
  },
  tableCell: {
    ...tableCell,
  },
  table: {
    margin: '30px 0',
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '1010px',
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
    fontWeight: '700',
  },
  footerLogo: {
    height: '25px',
    width: '100px',
    margin: '10px 20px 0px -5px',
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  observacoes: {
    backgroundColor: '#cecece',
    breakInside: 'avoid',
    width: '100%',
    paddingBottom: 100,
    zIndex: 999,
  },
  footerTextRodape: {
    fontSize: '11px',
  },
}));

export default ImpressaoRelatorioCampanhasCidade;
