import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Formik} from 'formik';
import {useQuery} from '@apollo/client';
import {Paper, Button, Grid, Box, TableContainer, Table, TableHead, TableRow, TableBody, TablePagination, TableCell} from '@mui/material';
import {Add, Visibility} from '@mui/icons-material';
import {FIND_ALL_OFICINAS} from '../../graphql/queries';
import String from '../../utils/string';
import {RoundTooltipButton, ButtonComponent} from '../../components/mui-button';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {
  persistOficinas,
  persistFiltrosOficinas,
} from '../../redux/Oficina/slice';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {SelectEstados} from '../../containers/selects/mui-estados';
import TableCellOrdered from '../../components/table-cell-ordered';

const columns = [
  {id: 'nome', label: 'Nome'},

  {
    id: 'telefone',
    label: 'Telefone',
    format: (telefone) => String.format('(##) #####-####', telefone),
  },

  {
    id: 'cidade',
    label: 'Cidade',
    campo: 'cidade.nome',
    format: (cidade) => (cidade ? `${cidade.nome}/${cidade.uf}` : ' - '),
  },
];

const ConsultaOficina = ({pageSize = 20}) => {
  const {oficinas, totalOficinas, filtrosOficinas, refetchOficinas} =
    useSelector((store) => store.Oficina);
  const [rowsPerPage] = useState(pageSize);
  const [page, setPage] = useState(filtrosOficinas.page);
  const [orderBy, setOrderBy] = useState(filtrosOficinas.orderBy);
  const [filtros, setFiltros] = useState(filtrosOficinas.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const oficinasQuery = useQuery(FIND_ALL_OFICINAS, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        search: searchDTO.search || null,
        estadoId: searchDTO.estado?.value || null,
        cidadeId: searchDTO.cidade?.value || null,
      },
    },
  });

  useEffect(() => {
    if (!oficinasQuery.loading && !oficinasQuery.error && oficinasQuery.data) {
      dispatch(persistOficinas(oficinasQuery.data.oficinas || []));

      dispatch(persistFiltrosOficinas({filtros, page, orderBy}));
    }
    if (refetchOficinas) {
      oficinasQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oficinasQuery, dispatch, refetchOficinas]);

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container>
      <Button
        id="button-green"
        startIcon={<Add style={{marginRight: 10}} />}
        toolbar="true"
        onClick={() => navigate(`criar`)}
      >
        Cadastrar novo
      </Button>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputV2
                  label="Nome"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target.value})
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  className="select"
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  value="Filtrar"
                  loading={oficinasQuery.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell id="acoes" key="acoes" align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {oficinas.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <RoundTooltipButton
                            id="icon-primary"
                            title="Visualizar"
                            onClick={() =>
                              navigate(`${row.id}/editar`)
                            }
                            icon={<Visibility />}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalOficinas}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'página anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'próxima página',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaOficina;
