import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Grid, Typography, Box, Modal, FormControlLabel, Checkbox} from '@mui/material';
import {ButtonComponent} from '../../../components/mui-button';

const ModalCamposExportacao = ({open, onClose, onExportar}) => {
  const {role} = useSelector((store) => store.Auth);

  const [campos, setCampos] = useState({});

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiBackdrop-root': {
          opacity: '0.0 !Important',
        },
      }}
    >
      <Box sx={styles.containerModal}>
        <Typography sx={styles.titleMenu}>Campos:</Typography>
        <Grid container direction={'column'}>
          <FormControlLabel
            control={<Checkbox color="primaryPatern" />}
            checked={campos.nome || false}
            label="Nome"
            onChange={({target}) => setCampos({...campos, nome: target.checked})}
            sx={styles.checkbox}
          />
          {['ROLE_ADMINISTRADOR', 'ROLE_GERENTE_OPERACIONAL'].includes(role) && (
            <FormControlLabel
              control={<Checkbox color="primaryPatern" />}
              checked={campos.cpf || false}
              label="CPF"
              onChange={({target}) => setCampos({...campos, cpf: target.checked})}
              sx={styles.checkbox}
            />
          )}
          <FormControlLabel
            control={<Checkbox color="primaryPatern" />}
            checked={campos.celular || false}
            label="Celular"
            onChange={({target}) => setCampos({...campos, celular: target.checked})}
            sx={styles.checkbox}
          />
          <FormControlLabel
            control={<Checkbox color="primaryPatern" />}
            checked={campos.celularAdicional || false}
            label="Celular adicional"
            onChange={({target}) => setCampos({...campos, celularAdicional: target.checked})}
            sx={styles.checkbox}
          />
          <FormControlLabel
            control={<Checkbox color="primaryPatern" />}
            checked={campos.cidade || false}
            label="Cidade"
            onChange={({target}) => setCampos({...campos, cidade: target.checked})}
            sx={styles.checkbox}
          />
          <FormControlLabel
            control={<Checkbox color="primaryPatern" />}
            checked={campos.veiculo || false}
            label="Veículo"
            onChange={({target}) => setCampos({...campos, veiculo: target.checked})}
            sx={styles.checkbox}
          />
          {['ROLE_ADMINISTRADOR', 'ROLE_GERENTE_OPERACIONAL'].includes(role) && (
            <FormControlLabel
              control={<Checkbox color="primaryPatern" />}
              checked={campos.placaVeiculo || false}
              label="Placa veículo"
              onChange={({target}) => setCampos({...campos, placaVeiculo: target.checked})}
              sx={styles.checkbox}
            />
          )}
          <FormControlLabel
            control={<Checkbox color="primaryPatern" />}
            checked={campos.tipoVeiculo || false}
            label="Tipo veículo"
            onChange={({target}) => setCampos({...campos, tipoVeiculo: target.checked})}
            sx={styles.checkbox}
          />
        </Grid>
        <Grid sx={styles.buttonContainer}>
          <ButtonComponent
            id="button-primary-empty"
            value="Exportar"
            sx={styles.button}
            onClick={() => {
              onExportar && onExportar(campos);
              onClose && onClose();
            }}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

const styles = {
  containerModal: {
    width: '300px',
    position: 'absolute',
    zIndex: (theme) => theme.zIndex.modal,
    right: '20px',
    top: '48px',
    background: '#F7F8FA',
    boxShadow: ' 0px 2px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '8px 16px',
  },
  titleMenu: {
    fontWeight: 'bold',
    color: '#404040',
    marginBottom: '26px',
  },
  buttonContainer: {
    marginTop: '16px',
  },
  button: {
    width: '100%'
  }
};

export default ModalCamposExportacao;
