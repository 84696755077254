import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaMateriaisVenda from './consulta';
import CadastrarMateriaisVenda from './cadastrar';
import EditarMateriaisVenda from './editar';
const MateriaisVenda = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaMateriaisVenda />} />
      <Route path={`/criar`} element={<CadastrarMateriaisVenda />} />
      <Route path={`/:materiaisVendaId/editar`} element={<EditarMateriaisVenda />} />
    </Routes>
  );
};

export default MateriaisVenda;