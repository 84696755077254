import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Field} from 'formik';
import ReactExport from 'react-export-excel';
import {useLazyQuery, useQuery} from '@apollo/client';
import {Paper, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Box} from '@mui/material';
import {Download, WhatsApp} from '@mui/icons-material';
import {
  persistMotoristas,
  persistFiltrosMotorista,
} from '../../redux/Motorista/slice';
import {FIND_ALL_MOTORISTA} from '../../graphql/queries';
import InputV2 from '../../components/input-v2/mui-input-v2';
import Select from '../../components/select/mui-select';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {RoundTooltipButton, ButtonComponent} from '../../components/mui-button';
import String from '../../utils/string';
import {SelectEstados} from '../../containers/selects/mui-estados';
import TableCellOrdered from '../../components/table-cell-ordered';
import {motoristaSituacao} from '../../constants/enum-labels';
import Data from '../../utils/data';
import ModalCamposExportacao from './modal/campos-exportacao';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columnsExportacao = [
  {id: "nome", label: "Nome"},
  {id: "cpf", label: "CPF"},
  {id: "celular", label: "Celular"},
  {id: "celularAdicional", label: "Celular adicional"},
  {id: "cidade", label: "Cidade"},
  {id: "veiculo", label: "Veículo"},
  {id: "placaVeiculo", label: "Placa veículo"},
  {id: "tipoVeiculo", label: "Tipo veículo"},
];

const columns = [
  {id: 'nome', label: 'Nome'},
  {
    id: 'endereco',
    label: 'Cidade',
    campo: 'endereco.cidade.nome',
    format: (endereco) => endereco?.cidade?.nomeEUf || '-',
  },
  {
    id: 'veiculoAtual',
    label: 'Nome do Veículo',
    campo: 'veiculoAtual.modelo.nome',
    format: (veiculoAtual) => veiculoAtual?.modelo?.nome || '-',
  },
  {
    id: 'dataAlteracao',
    label: 'Data alteração',
    format: (dataAlteracao) => Data.dataFormat(dataAlteracao) || '-',
  },
  {
    id: 'notaAvaliacao',
    label: 'Nota',
    format: (nota) => parseFloat(nota || 0).toFixed(1),
  },
  {
    id: 'situacao',
    label: 'Cadastro',
    format: (situacao) => motoristaSituacao[situacao],
  },
];

const ConsultaMotoristas = ({pageSize = 20}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {motoristas, totalMotoristas, refetchMotoristas, filtrosMotorista} =
    useSelector((store) => store.Motorista);
  const {role} = useSelector((store) => store.Auth);

  const [page, setPage] = useState(filtrosMotorista.page);
  const [filtros, setFiltros] = useState(filtrosMotorista.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosMotorista.orderBy);
  const [motoristasDownload, setMotoristasDownload] = useState([]);
  const [openModalCampos, setOpenModalCampos] = useState(false);
  const [camposExportacao, setCamposExportacao] = useState({});
  const [rowsPerPage] = useState(pageSize);

  const [loadMotoristasDownload, loadMotoristasDownloadQuery] =
    useLazyQuery(FIND_ALL_MOTORISTA);

  const motoristasQuery = useQuery(FIND_ALL_MOTORISTA, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        estadoId: searchDTO.estado?.value,
        cidadeId: searchDTO.cidade?.value,
        nomeVeiculo: searchDTO.nomeVeiculo,
        nomeMotorista: searchDTO.nomeMotorista,
        telefone: String.isEmpty(searchDTO.telefone)
          ? undefined
          : String.removeSpecialChars(searchDTO.telefone),
        possuiAnuncioAtivo: searchDTO.possuiAnuncioAtivo?.value,
        situacao: searchDTO.situacao?.value,
        motoristaTemIndicacoes:
          searchDTO.motoristaTemIndicacoes?.value || undefined,
        possuiBorrachao: searchDTO.possuiBorrachao?.value,
        pinturaOriginal: searchDTO.pinturaOriginal?.value,
        tipoVeiculo: searchDTO.tipoVeiculo?.value || undefined,
      },
    },
  });

  useEffect(() => {
    if (
      !motoristasQuery.loading &&
      !motoristasQuery.error &&
      motoristasQuery.data
    ) {
      dispatch(persistMotoristas(motoristasQuery.data.findAllMotorista));
      dispatch(persistFiltrosMotorista({filtros, page, orderBy}));
    }
    if (refetchMotoristas) {
      motoristasQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motoristasQuery, dispatch, refetchMotoristas]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  useEffect(() => {
    if (
      loadMotoristasDownloadQuery?.loading ||
      loadMotoristasDownloadQuery?.error ||
      !loadMotoristasDownloadQuery?.data
    ) {
      setMotoristasDownload([]);
      return;
    }

    const motoristas =
      loadMotoristasDownloadQuery.data?.findAllMotorista?.content?.map(
        ({nome, cpf, celular, celularAdicional, endereco, veiculoAtual}) => {
          return {
            nome,
            cpf,
            celular,
            celularAdicional,
            cidade: endereco?.cidade?.nomeEUf,
            veiculo: `${veiculoAtual?.modelo?.marca?.nome} / ${veiculoAtual?.modelo?.nome}`,
            tipoVeiculo: veiculoAtual?.tipoVeiculo,
            placaVeiculo: veiculoAtual?.placa
          }
        }
      );

    setMotoristasDownload(motoristas);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMotoristasDownloadQuery]);

  const donwloadMotoristas = (campos) => {
    setCamposExportacao(campos);
    loadMotoristasDownload({
      variables: {
        pageable: {
          pageNumber: 0,
          pageSize: -1,
          sortField: orderBy.campo,
          sortDir: orderBy.asc ? 'ASC' : 'DESC',
        },
        searchDTO: {
          estadoId: searchDTO.estado?.value,
          cidadeId: searchDTO.cidade?.value,
          nomeVeiculo: searchDTO.nomeVeiculo,
          nomeMotorista: searchDTO.nomeMotorista,
          telefone: String.isEmpty(searchDTO.telefone)
            ? undefined
            : String.removeSpecialChars(searchDTO.telefone),
          possuiAnuncioAtivo: searchDTO.possuiAnuncioAtivo?.value,
          situacao: searchDTO.situacao?.value,
          motoristaTemIndicacoes:
            searchDTO.motoristaTemIndicacoes?.value || undefined,
          possuiBorrachao: searchDTO.possuiBorrachao?.value,
          pinturaOriginal: searchDTO.pinturaOriginal?.value,
          tipoVeiculo: searchDTO.tipoVeiculo?.value || undefined,
        },
      },
    });
  };

  const isDirComercial = () => {
    return role.includes('GERENTE_COMERCIAL');
  }
  const isFinanceiro = () => {
    return role.includes('FINANCEIRO');
  }

  return (
    <Grid id="page-container" container>
      <RoundTooltipButton
        size="small"
        id="icon-toolbar"
        title={'Exportar para Excel'}
        onClick={() => setOpenModalCampos(true)}
        icon={<Download />}
        loading={loadMotoristasDownloadQuery.loading}
        loadingColor='grey'
      />
      {motoristasDownload.length > 0 && (
        <ExcelFile hideElement={true} filename="Motoristas">
          <ExcelSheet data={motoristasDownload} name="Motoristas">
            {columnsExportacao.filter(c => camposExportacao[c.id])
              .map(c => (<ExcelColumn label={c.label} value={c.id} />))}
          </ExcelSheet>
        </ExcelFile>
      )}
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <InputV2
                  label="Nome do motorista"
                  value={filtros.nomeMotorista}
                  onChange={({target}) =>
                    setFiltros({...filtros, nomeMotorista: target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <InputV2
                  label="Telefone"
                  value={filtros.telefone}
                  onChange={({target}) =>
                    setFiltros({...filtros, telefone: target.value})
                  }
                  mask="phone"
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={SelectEstados}
                  label="Estado"
                  name="estado"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={(props) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.value || null}}
                      {...props}
                    />
                  )}
                  setSearchDTO={setSearchDTO}
                  label="Cidade"
                  name="cidade"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Situação do cadastro"
                  options={[
                    {label: 'Todos'},
                    {value: 'APROVADO', label: 'Aprovado'},
                    {value: 'COMPLETO', label: 'Completo'},
                    {value: 'ATUALIZADO', label: 'Atualizado'},
                    {value: 'INCOMPLETO', label: 'Incompleto'},
                    {value: 'REPROVADO', label: 'Reprovado'},
                    {value: 'INATIVO', label: 'Inativo'},
                  ]}
                  value={filtros.situacao}
                  onChange={(e) => setFiltros({...filtros, situacao: e})}
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Anúncio ativo"
                  options={[
                    {label: 'Todos'},
                    {value: false, label: 'Não'},
                    {value: true, label: 'Sim'},
                  ]}
                  value={filtros.possuiAnuncioAtivo}
                  onChange={(e) =>
                    setFiltros({...filtros, possuiAnuncioAtivo: e})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Tipo do veículo"
                  options={[
                    {label: 'Todos'},
                    {value: 'SEDAN', label: 'Sedan'},
                    {value: 'HATCH', label: 'Hatch'},
                    {value: 'NAO_INFORMADO', label: 'Não informado'},
                  ]}
                  value={filtros.tipoVeiculo}
                  onChange={(e) => setFiltros({...filtros, tipoVeiculo: e})}
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Possui borrachão"
                  options={[
                    {label: 'Todos'},
                    {value: true, label: 'Sim'},
                    {value: false, label: 'Não'},
                  ]}
                  value={filtros.possuiBorrachao}
                  onChange={(e) =>
                    setFiltros({...filtros, possuiBorrachao: e})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Pintura original"
                  options={[
                    {label: 'Todos'},
                    {value: true, label: 'Sim'},
                    {value: false, label: 'Não'},
                  ]}
                  value={filtros.pinturaOriginal}
                  onChange={(e) =>
                    setFiltros({...filtros, pinturaOriginal: e})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="Fez indicações"
                  options={[{label: 'Todos'}, {value: true, label: 'Sim'}]}
                  value={filtros.motoristaTemIndicacoes}
                  onChange={(e) =>
                    setFiltros({...filtros, motoristaTemIndicacoes: e})
                  }
                />
              </Grid>
              <Grid item xs={2} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  loading={motoristasQuery.loading}
                  onClick={() => refetch()}
                  value=" Filtrar"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    {!isFinanceiro() && !isDirComercial() && (
                      <TableCell id="acoes" key="acoes" align="center">
                        Ações
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {motoristas.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={{cursor: 'pointer'}}
                      >
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={index}
                              align={column.align}
                              onClick={() => !isDirComercial() && navigate(`${row.id}`)}
                            >
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        {!isFinanceiro() && !isDirComercial() && (
                          <TableCell align="center">
                            <RoundTooltipButton
                              id="icon-whatsapp"
                              title={String.formatCelular(row.celular)}
                              onClick={() =>
                                window.open(
                                  `https://api.whatsapp.com/send?phone=${row.celular}`,
                                )
                              }
                              icon={<WhatsApp />}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalMotoristas || 0}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
        <ModalCamposExportacao
          open={openModalCampos}
          onClose={() => setOpenModalCampos(false)}
          onExportar={donwloadMotoristas}
        />
      </Grid>
    </Grid>
  );
};

export default ConsultaMotoristas;
