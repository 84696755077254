import React from 'react';
import {Box, Modal, Typography, Stack} from '@mui/material';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../../components/mui-button';

const TrocarMotoristaModal = ({
  open,
  onClose,
  motoristaOriginal = '',
  novoMotorista = '',
  onConfirm,
  onCancel,
  valorMensalMotoristaAtual,
  valorMensalMotoristaNovo,
  setValorMensalMotorista,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description">
      <Box sx={styles.content}>
        <Stack direction="column" padding={'20px'} spacing={2}>
          <Typography fontFamily={'fontFamily.patternBold'}>
            Motorista original: {motoristaOriginal}
          </Typography>
          <InputV2
            mask="moeda"
            label="Valor a receber"
            onChange={({target}) =>
              setValorMensalMotorista((prev) => ({
                ...prev,
                substituido: target?.value,
              }))
            }
            value={valorMensalMotoristaAtual || 0}></InputV2>
          <Typography fontFamily={'fontFamily.patternBold'}>
            Novo motorista: {novoMotorista}
          </Typography>
          <InputV2
            mask="moeda"
            label="Valor a receber"
            value={valorMensalMotoristaNovo || 0}
            onChange={({target}) =>
              setValorMensalMotorista((prev) => ({
                ...prev,
                substituto: target?.value,
              }))
            }></InputV2>
          <Typography
            fontFamily={'fontFamily.pattern'}
            color="#b6b6b6"
            textAlign="center">
            Confirmar substituição?
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <ButtonComponent
              id="button-cancel"
              value="Cancelar"
              onClick={onCancel}
            />
            <ButtonComponent
              id="button-green"
              value="Aceitar"
              onClick={onConfirm}
            />
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

const styles = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};

export default TrocarMotoristaModal;
