const String = {
  format(mask, value) {
    if (mask == null || value == null) return value;

    let formatted = "";
    let next = 0;
    let ignore = 0;
    for (let i = 0; i < mask.length; i++) {
      if (i - ignore >= value.length) break;
      if (mask[i] === "#") formatted += value[next++];
      else {
        formatted += mask[i];
        ignore++;
      }
    }
    return formatted;
  },

  removeSpecialChars(value = '') {
    if (value == null || value.length < 1) return '';
    return value.toString().replace(/[^A-Z0-9]+/gi, "");
  },

  currencyMaskToFloat(value) {
    if (typeof value === "string" && value.trim().length > 0) {
      const newValue = value.replace(/[^,0-9]+/gi, "").replace(",", ".");

      return parseFloat(newValue);
    }

    return 0;
  },

  validaCPF(cpf) {
    if (this.isEmpty(cpf)) {
      return false;
    }

    const CPFS_INVALIDOS = [
      "00000000000",
      "11111111111",
      "22222222222",
      "33333333333",
      "44444444444",
      "55555555555",
      "66666666666",
      "77777777777",
      "88888888888",
      "99999999999"
    ];
    cpf = this.removeSpecialChars(cpf);
    if (CPFS_INVALIDOS.indexOf(cpf) > -1) return false;

    let soma = 0;
    for (let i = 1; i <= 9; i++)
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    let resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;

    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;

    return resto === parseInt(cpf.substring(10, 11));
  },

  validaTelefone(tel) {
    if (this.isEmpty(tel)) {
      return false;
    }

    const text = this.removeSpecialChars(tel);
    const length = text.trim().length;

    return length >= 10 && length <= 11;
  },

  removeCodigoPaisCelular(value) {
    if (this.isEmpty(value)) {
      return value;
    }

    const text = this.removeSpecialChars(value).trim();
    return text.length === 13 ? text.substring(2, text.length) : text;
  },

  stringify(objFromJson) {
    if (Array.isArray(objFromJson)) {
      return objFromJson.map(item => this.stringify(item));
    } else if (typeof objFromJson !== "object") {
      // not an object, stringify using native function
      return JSON.stringify(objFromJson);
    }

    let props = Object.keys(objFromJson)
      .map(key =>
        objFromJson[key] ? `${key}:${this.stringify(objFromJson[key])}` : ""
      )
      .join(",");

    return `{${props}}`;
  },

  capitalize(text) {
    if (!text) return '';
    text = text.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  },

  stringToLowerCase(string, index = 0) {
    return string.charAt(index) + string.slice(1).toLowerCase();
  },

  camelToSnakeCase(text) {
    const firstLetterUpperCase = text[0].toLowerCase() + text.slice(1);
    return firstLetterUpperCase.replace(/[A-Z]/g, letter => `_${letter}`).toLowerCase();
  },

  isEmpty(text) {
    if (typeof text !== "string") {
      return true;
    }

    if (text.trim().length === 0) {
      return true;
    }

    return false;
  },

  removeEmpty(obj) {
    const o = JSON.parse(JSON.stringify(obj)); // Clone source oect.

    Object.keys(o).forEach(key => {
      if (o[key] && typeof o[key] === "object")
        o[key] = this.removeEmpty(o[key]);
      // Recurse.
      else if (o[key] === undefined || o[key] === null) delete o[key]; // Delete undefined and null.
    });

    return o;
  },
  removeAcento(text) {
    text = text.toLowerCase();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
    return text;
  },

  truncate(str, length, ending) {
    if (typeof str !== "string") {
      return str;
    }
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    }

    return str;
  },

  numberMaskToFloat(value) {
    if (typeof value === "number") {
      return value;
    }
    return this.isEmpty(value) ? 0 : parseFloat(value.replace(",", "."));
  },

  formatCpfCnpj(value) {
    if (this.isEmpty(value)) {
      return '';
    }

    if (value.length === 11)
      return this.format('###.###.###-##', value)

    if (value.length === 14)
      return this.format('##.###.###/####-##', value)

    return value;
  },

  formatCelular(value) {
    if (this.isEmpty(value)) {
      return '';
    }

    return this.format('+## (##) #####-####', value)
  },
  formatTelefone(value) {
    if (this.isEmpty(value)) {
      return '';
    }

    return this.format('(##) #####-####', value)
  },
  formatPlaca(value) {
    if (this.isEmpty(value)) {
      return '';
    }

    return value.replace(' ', '').replace('-', '').toUpperCase()
  },
  transformCamelToSnakeCase(value = '') {
    return value.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase())
  }
};

export default String;
