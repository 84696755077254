import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  anunciosAguardandoRemocao: [],
  hasMore: false,
  totalAnunciosAguardandoRemocao: 0,
  refetchAnunciosAguardandoRemocao: false
};

const acaoAtualizarAnunciosAguardandoRemocao = state => {
  state.refetchAnunciosAguardandoRemocao = true;
};

const acaoPersistAnunciosAguardandoRemocao = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.anunciosAguardandoRemocao = content;
  state.hasMore = !last;
  state.totalAnunciosAguardandoRemocao = totalElements || 0;
};

const resumeSlice = createSlice({
  name: 'anunciosAguardandoRemocao',
  initialState: initialState,
  reducers: {
    persistAnunciosAguardandoRemocao: acaoPersistAnunciosAguardandoRemocao,
    atualizarAnunciosAguardandoRemocao: acaoAtualizarAnunciosAguardandoRemocao
  }
});

export const { actions, reducer } = resumeSlice;
export const {
  persistAnunciosAguardandoRemocao,
  atualizarAnunciosAguardandoRemocao
} = actions;
