import React from 'react';
import {Routes, Route} from 'react-router-dom';
import CadastrarModeloVeiculo from './cadastrar';
import ConsultaModeloVeiculo from './consulta';
import EditarModeloVeiculo from './editar';

const ModeloVeiculo = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaModeloVeiculo />} />
      <Route path={`/:modeloId/editar`} element={<EditarModeloVeiculo />} />
      <Route path={`/criar`} element={<CadastrarModeloVeiculo />} />
    </Routes>
  );
};

export default ModeloVeiculo;
