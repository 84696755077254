import {combineReducers} from 'redux';
import {AuthReducer as Auth} from './Auth';
import {ResumeReducer as Resume} from './Resume';
import {ReducerApollo as Apollo} from './Apollo';
import {ReducerCampanha as Campanha} from './Campanha';
import {ReducerCampanhaComVeiculoFaltando as CampanhaComVeiculoFaltando} from './CampanhaComVeiculoFaltando';
import {ReducerAnunciosAguardandoInstalacao as AnunciosAguardandoInstalacao} from './AnunciosAguardandoInstalacao';
import {ReducerAnunciosAguardandoRemocao as AnunciosAguardandoRemocao} from './AnunciosAguardandoRemocao';
import {ReducerAnunciosParados as AnunciosParados} from './AnunciosParados';
import {ReducerModeloAnuncio as ModeloAnuncio} from './ModeloAnuncio';
import {ReducerMotorista as Motorista} from './Motorista';
import {ReducerAnunciante as Anunciante} from './Anunciante';
import {ReducerComercial as Comercial} from './Comercial';
import {ReducerAdministrador as Administrador} from './Administrador';
import {ReducerInstalador as Instalador} from './Instalador';
import {ReducerEmailContato as EmailContato} from './EmailContato';
import {ReducerExtratoRepasse as ExtratoRepasse} from './ExtratoRepasse';
import {ReducerExtratoRepasseComercial as ExtratoRepasseComercial} from './ExtratoRepasseComercial';
import {ReducerExtratoRepasseMotorista as ExtratoRepasseMotorista} from './ExtratoRepasseMotorista';
import {ReducerExtratoRepasseInstalador as ExtratoRepasseInstalador} from './ExtratoRepasseInstalador';
import {ReducerValidacaoImagensInstalacao as ValidacaoImagensInstalacao} from './ValidacaoImagensInstalacao';
import {ReducerValidacaoImagens as ValidacaoImagens} from './ValidacaoImagens';
import {ReducerValidacaoImagensRemocao as ValidacaoImagensRemocao} from './ValidacaoImagensRemocao';
import {ReducerAnuncioCampanha as AnuncioCampanha} from './AnuncioCampanha';
import {ReducerAvaliacaoMotorista as AvaliacaoMotorista} from './AvaliacaoMotorista';
import {ReducerProposta as Proposta} from './Proposta';
import {ReducerPedido as Pedido} from './Pedido';
import {ReducerMonitorarCampanha as MonitorarCampanha} from './MonitorarCampanha';
import {ReducerAgencia as Agencia} from './Agencia';
import {ReducerOficina as Oficina} from './Oficina';
import {ReducerNotificacaoMotoristas as NotificacaoMotoristas} from './NotificacaoMotoristas';
import {ReducerMarcasVeiculo as MarcasVeiculo} from './MarcasVeiculo';
import {ReducerModeloVeiculo as ModeloVeiculo} from './ModeloVeiculo';
import {ReducerEstado as Estado} from './Estado';
import {ReducerCidade as Cidade} from './Cidade';
import {ReducerNotasFiscaisPendentes as NotasFiscaisPendentes} from './notas-fiscais-pendentes';
import {ReducerMateriaisVenda as MateriaisVenda} from './MateriaisVenda';
import {ReducerSidebar as Sidebar} from './Sidebar'
import {ReducerHome as Home} from './Home'
import {ReducerLicencas as Licencas} from './Licencas';

const appReducer = combineReducers({
  Auth,
  Resume,
  Apollo,
  Campanha,
  CampanhaComVeiculoFaltando,
  AnunciosAguardandoInstalacao,
  AnunciosAguardandoRemocao,
  ModeloAnuncio,
  Motorista,
  Anunciante,
  Comercial,
  Administrador,
  Instalador,
  EmailContato,
  ExtratoRepasse,
  ExtratoRepasseComercial,
  ExtratoRepasseInstalador,
  ExtratoRepasseMotorista,
  ValidacaoImagensInstalacao,
  ValidacaoImagensRemocao,
  AnunciosParados,
  AnuncioCampanha,
  ValidacaoImagens,
  AvaliacaoMotorista,
  Proposta,
  Pedido,
  MonitorarCampanha,
  Agencia,
  Oficina,
  NotificacaoMotoristas,
  MarcasVeiculo,
  ModeloVeiculo,
  Estado,
  Cidade,
  NotasFiscaisPendentes,
  MateriaisVenda,
  Sidebar,
  Home,
  Licencas
});

const rootReducer = (state, action) => {
  if (action.type === 'logoutSuccess') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer;