import React from 'react';
import {Grid, Typography, Modal, Slide, Box} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';
import String from '../../../utils/string';
import {tipoContaBancaria} from '../../../constants/enum-labels';
import Data from '../../../utils/data';

const DetalhesMotoristaModal = ({openModal, anuncio, onClose}) => {
  const motorista = anuncio?.motorista;
  const dadoBancario = motorista?.dadoBancario;

  return (
    <Modal open={openModal} onClose={onClose}>
      <Slide direction="left" in={openModal}>
        <Box sx={styles.modalBody}>
          <Box sx={styles.header}>
            <ArrowBack sx={styles.arrowBack} onClick={onClose} />
            <Typography sx={styles.modalTitle}>
              Detalhes do Motorista
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={styles.subTitle}>Dados pessoais</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={styles.label}>Motorista</Typography>
                <Typography sx={styles.value}>{motorista?.nome}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>Data Cadastro</Typography>
                <Typography sx={styles.value}>
                  {Data.dataFormat(motorista?.dataCadastro, 'DD/MM/YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>CPF</Typography>
                <Typography sx={styles.value}>
                  {String.format('###.###.###-##', motorista?.cpf)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={styles.label}>E-mail</Typography>
                <Typography sx={styles.value}>{motorista?.email}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={styles.label}>Celular</Typography>
                <Typography sx={styles.value}>
                  {String.formatCelular(motorista?.celular)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={styles.subTitle}>Dados bancários</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={styles.label}>Banco</Typography>
                <Typography sx={styles.value}>
                  {dadoBancario?.banco ? `${dadoBancario.banco.codigoBanco} - ${dadoBancario.banco.nomeBanco}` : ''}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.label}>Agência</Typography>
                <Typography sx={styles.value}>
                  {dadoBancario?.agencia}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={styles.label}>Conta</Typography>
                <Typography sx={styles.value}>{dadoBancario?.conta}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.label}>Tipo de Conta</Typography>
                <Typography sx={styles.value}>
                  {dadoBancario?.tipoContaBancaria ? tipoContaBancaria[dadoBancario?.tipoContaBancaria] : ''}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography sx={styles.label}>Nome favorecido</Typography>
                <Typography sx={styles.value}>
                  {dadoBancario?.nomeFavorecido}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography sx={styles.label}>CPF/CNPJ Favorecido</Typography>
                <Typography sx={styles.value}>
                  {String.formatCpfCnpj(dadoBancario?.cpfCnpjFavorecido)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={styles.label}>Chave PIX</Typography>
                <Typography sx={styles.value}>
                  {dadoBancario?.chavePix}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

const styles = {
  header: {
    height: '45px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowBack: {
    color: '#657496',
    cursor: 'pointer',
  },
  modalTitle: {
    fontSize: 18,
    textAlign: 'left',
    color: '#657496',
    marginLeft: 10,
  },
  subTitle: {
    color: '#0A2549',
    fontSize: 15,
    fontWeight: '500',
    backgroundColor: '#eef3f7',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    padding: '5px 20px',
    marginLeft: '-20px',
  },
  modalBody: {
    background: '#fff',
    position: 'absolute',
    right: '0px',
    borderTopLeftRadius: '6px',
    padding: '20px',
    marginTop: theme => `calc(${theme.toolbarHeight} + 8px)`,
    width: '700px',
    height: theme => `calc(100vh - ${theme.toolbarHeight} - 8px)`,
  },
  label: {
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 0,
    fontWeight: '500',
  },
  value: {
    fontSize: 14,
    textAlign: 'left',
    color: '#0A2549',
    marginTop: 0,
  },
};

export default DetalhesMotoristaModal;
