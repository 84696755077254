import React from 'react';
import InputV2 from '../input-v2/mui-input-v2';
import Select from './mui-multi-select';

const MultiSelectFormik = ({
  field,
  form: {setFieldValue, errors, touched},
  onChange,
  disabled,
  ...props
}) => {
  if (disabled) {
    const value = field?.value?.map((e) => e.label)?.join(' | ');
    return <InputV2 value={value} title={value} disabled label={props.label} />;
  }

  return (
    <Select
      {...field}
      {...props}
      onChange={(e) => {
        setFieldValue(field.name, e);
        onChange && onChange(e);
      }}
      value={field?.value}
      errors={errors[field.name] && touched[field.name] ? true : false}
      mensagemSuporte={
        errors[field.name] && touched[field.name] ? errors[field.name] : null
      }
    />
  );
};

export default MultiSelectFormik;
