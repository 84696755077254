import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {Formik, Field} from 'formik';
import {useQuery} from '@apollo/client';
import {Paper, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Box} from '@mui/material';
import {WhatsApp} from '@mui/icons-material';
import {
  persistMotoristas,
  persistFiltrosMotorista,
} from '../../../redux/Motorista/slice';
import {FIND_ALL_MOTORISTA} from '../../../graphql/queries';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import {RoundTooltipButton, ButtonComponent} from '../../../components/mui-button';
import String from '../../../utils/string';
import {SelectEstados} from '../../../containers/selects/mui-estados';
import TableCellOrdered from '../../../components/table-cell-ordered';
import {motoristaSituacao} from '../../../constants/enum-labels';

const columns = [
  {id: 'nome', label: 'Nome'},
  {
    id: 'endereco',
    label: 'Cidade',
    campo: 'endereco.cidade.nome',
    format: (endereco) =>
      endereco?.cidade ? `${endereco.cidade.nome}/${endereco.cidade.uf}` : '-',
  },
  {
    id: 'veiculoAtual',
    label: 'Nome do Veículo',
    campo: 'veiculoAtual.modelo.nome',
    format: (veiculoAtual) => veiculoAtual?.modelo?.nome || '-',
  },
  {
    id: 'dataCadastro',
    label: 'Data cadastro',
    format: (dataCompra) =>
      dataCompra ? moment(dataCompra).format('DD/MM/YYYY') : '-',
  },
  {
    id: 'situacao',
    label: 'Cadastro',
    format: (situacao) => motoristaSituacao[situacao],
  },
];

const ConsultaIndicados = ({pageSize = 20}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {motoristas, totalMotoristas, refetchMotoristas, filtrosMotorista} =
    useSelector((store) => store.Motorista);

  const [page, setPage] = useState(filtrosMotorista.page);
  const [filtros, setFiltros] = useState(filtrosMotorista.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [orderBy, setOrderBy] = useState(filtrosMotorista.orderBy);
  const [rowsPerPage] = useState(pageSize);

  const motoristasQuery = useQuery(FIND_ALL_MOTORISTA, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        estadoId: searchDTO.estado?.value,
        cidadeId: searchDTO.cidade?.value,
        nomeMotorista: searchDTO.nomeMotorista,
        motoristaFoiIndicado: true,
      },
    },
  });

  useEffect(() => {
    if (!motoristasQuery.loading && !motoristasQuery.error) {
      dispatch(persistMotoristas(motoristasQuery.data.findAllMotorista));
      dispatch(persistFiltrosMotorista({filtros, page, orderBy}));
    }
    if (refetchMotoristas) {
      motoristasQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motoristasQuery, dispatch, refetchMotoristas]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const refetch = () => {
    setPage(0);
    setSearchDTO(filtros);
  };
  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);
  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  label="Estado"
                  name="estado"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.value}}
                      {...props}
                    />
                  )}
                  label="Cidade"
                  name="cidade"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                />
              </Grid>
              <Grid item xs={3}>
                <InputV2
                  label="Nome do Motorista"
                  value={filtros.nomeMotorista}
                  onChange={({target}) =>
                    setFiltros({...filtros, nomeMotorista: target.value})
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  value="Filtrar"
                  loading={motoristasQuery.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell id="acoes" key="acoes">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {motoristas.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={{cursor: 'pointer'}}
                      >
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={index}
                              align={column.align}
                              onClick={() =>
                                navigate(`/motoristas/${row.id}`)
                              }>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <RoundTooltipButton
                            id="icon-whatsapp"
                            title={String.formatCelular(row.celular)}
                            onClick={() =>
                              window.open(
                                `https://api.whatsapp.com/send?phone=${row.celular}`,
                              )
                            }
                            icon={<WhatsApp />}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalMotoristas || 0}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultaIndicados;
