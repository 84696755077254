import {Box, Grid} from '@mui/material';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import Number from '../../../utils/number';

const CidadeItem = ({item = {}, readOnly, setValue}) => {
  const [cidadeItem, setCidadeItem] = useState({});

  useEffect(() => {
    setCidadeItem({
      ...item,
      valorPorVeiculo: Number.currencyFormat(item.valorPorVeiculo),
      valorDescontoPorVeiculo: Number.currencyFormat(item.desconto),
      valorBrutoVeiculo: Number.currencyFormat(item.valorBrutoVeiculo || 0),
      valorMensalMotorista: Number.currencyFormat(item.valorMensalMotorista),
      desconto: Number.regraDeTresPorcentagem(item.desconto, item.valorBrutoVeiculo, true),
      valorTotalCidade: Number.currencyFormat(item.valorPorVeiculo * item.quantidadeVeiculos)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id]);

  const setState = (state) => {
    setCidadeItem({...item, ...state});
    setValue({...item, ...state});
  };

  return (
    <Formik enableReinitialize initialValues={cidadeItem}>
      <Grid sx={styles.cidadeRow}>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={3.5}>
            <InputV2
              name="cidade"
              label="Cidade"
              value={cidadeItem.cidade?.nomeEUf}
              disabled
            />
          </Grid>
          <Grid item xs={3} sx={{display: 'flex', flexWrap: 'nowrap'}}>
            <InputV2
              name="modeloAnuncio"
              label="Tipo do anúncio"
              title={cidadeItem.modeloAnuncio?.nome}
              value={cidadeItem.modeloAnuncio?.nome}
              disabled
            />
          </Grid>
          <Grid item xs={1.5}>
            <InputV2
              label="Nº de carros"
              name="quantidadeVeiculos"
              value={cidadeItem.quantidadeVeiculos || ''}
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Valor do motorista"
              name="valorMensalMotorista"
              mask="moeda"
              value={cidadeItem.valorMensalMotorista || 0}
              onChange={({target}) =>
                setState({
                  ...cidadeItem,
                  valorMensalMotorista: target.value,
                })
              }
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Km por veículo"
              name="quantidadeKmMotoristaMes"
              value={Number.format(cidadeItem.quantidadeKmMotoristaMes)}
              onChange={({target}) =>
                setState({
                  ...cidadeItem,
                  quantidadeKmMotoristaMes: Number.currencyToFloat(target.value),
                })
              }
              disabled={readOnly}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          spacing={1}
          sx={{marginTop: '8px !important'}}>
          <Grid item xs={2}>
            <InputV2
              label="Valor por veículo"
              name="valorBrutoVeiculo"
              mask="moeda"
              value={cidadeItem.valorBrutoVeiculo || 0}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <Box sx={styles.box}>
              <InputV2
                label="Desconto"
                name="desconto"
                mask="porcento"
                value={cidadeItem.desconto || 0}
                defaultValue={0}
                disabled
              />
              <InputV2
                sx={{width: '350px'}}
                label="Valor"
                name="valorDescontoPorVeiculo"
                mask="moeda"
                value={cidadeItem.valorDescontoPorVeiculo || 0}
                defaultValue={0}
                disabled
              />
            </Box>
          </Grid>
          <Grid item xs={1.8}>
            <InputV2
              label="Valor final por veículo"
              name="valorPorVeiculo"
              mask="moeda"
              value={cidadeItem.valorPorVeiculo || 0}
              disabled
            />
          </Grid>
          <Grid item xs={1.8}>
            <InputV2
              label="Valor total"
              name="valorTotalCidade"
              mask="moeda"
              value={cidadeItem.valorTotalCidade || 0}
              disabled
            />
          </Grid>
          <Grid item xs={1.6}>
            <InputV2
              label="Produção por carro"
              name="instalacaoPorVeiculo"
              value={Number.currencyFormat(cidadeItem.instalacaoPorVeiculo)}
              disabled
            />
          </Grid>
          <Grid item xs={1.8}>
            <InputV2
              label="Produção total"
              name="producaoTotal"
              value={Number.currencyFormat(
                Number.stringToFloat(cidadeItem.instalacaoPorVeiculo || 0) *
                  (cidadeItem.quantidadeVeiculos || 0),
              )}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </Formik>
  );
};

const styles = {
  box: {
    display: 'flex',
    flexDirection: 'row !important',
    alignItems: 'center',
  },
  cidadeRow: {
    background: '#fff',
    padding: '16px 8px 10px 8px',
    borderRadius: '6px',
    margin: '0 8px 8px 8px',
    border: (theme) => `1px solid ${theme.palette.paterns.blueGraySecundary}`,
  },
};

export default CidadeItem;
