import { useQuery } from '@apollo/client';
import { PrintOutlined } from '@mui/icons-material';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import ImpressaoRelatorioImpactosTotais from '../../../components/impressao/impressao-relatorio-impactos-totais';
import { ButtonComponent, RoundTooltipButton } from '../../../components/mui-button';
import { SelectCidades } from '../../../containers/selects/mui-cidades';
import { SelectEstados } from '../../../containers/selects/mui-estados';
import { FIND_ALL_IMPACTOS_TODOS_MOTORISTAS } from '../../../graphql/queries';
import Number from '../../../utils/number';

const pageStyle = `
  @page {
    size: A4;
    margin: 5mm 10mm 5mm 10mm;
  }

  @media all {
    .page-footer {
      display: block !important;
    }
  }
`;

const columns = [
  {
    id: 'modeloAnuncio', label: 'Custos modelo de anúncio',
    format: ({modeloAnuncio}) => modeloAnuncio?.nome || ''
  },
  {
    id: 'impactos', label: 'Impactos por mês',
    format: ({impactos}) => Number.format(impactos),
  },
  {
    id: 'impactosDia', label: 'Impactos por dia',
    format: ({impactos}) => Number.format(Number.safeParseFloat(impactos) / 30),
  },
];

const ConsultaImpactosTotais = () => {
  const componentRef = useRef();

  const [filtros, setFiltros] = useState({});
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [impactos, setImpactos] = useState([]);
  const [loading, setLoading] = useState(false);

  const impactosQuery = useQuery(FIND_ALL_IMPACTOS_TODOS_MOTORISTAS, {
    variables: {
      search: {
        estadoId: searchDTO.estado?.value,
        cidadeId: searchDTO.cidade?.value,
      },
    },
  });

  useEffect(() => {
    if (impactosQuery.loading || impactosQuery.error) return;

    setImpactos(impactosQuery.data.impactos || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impactosQuery]);

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const refetch = () => {
    setSearchDTO(filtros);
  }

  return (
    <Grid id="page-container" container>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Impactos totais`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() => (
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintOutlined />}
            loading={loading}
            loadingColor="grey"
          />
        )}
        content={() => componentRef.current}
      />
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  label="Estado"
                  name="estado"
                  className="select"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, estado: e})}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.value}}
                      {...props}
                    />
                  )}
                  label="Cidade"
                  name="cidade"
                  className="select"
                  isClearable
                  onChange={(e) => setFiltros({...filtros, cidade: e})}
                />
              </Grid>
              <Grid item xs={2} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  value="Filtrar"
                  loading={impactosQuery.loading}
                  onClick={() => refetch()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {impactos.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={index} align={column.align}>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </Grid>
      <Box overflow="hidden" height="0px">
        <Box ref={componentRef} background="#fff">
          <ImpressaoRelatorioImpactosTotais impactos={impactos} />
        </Box>
      </Box>
    </Grid>
  );
};

export default ConsultaImpactosTotais;
