export const maringa = {lat: -23.421, lng: -51.9331};
export const centroBrasil = {lat: -13.888254654607433, lng: -50.84689243001579};

export default function getMarkers() {
  const cnt = 10;
  const markers = [];
  for (let i = 0; i < cnt; i++) {
    markers.push({
      id: i,
      title: 'marker: ' + i,
      latitude: maringa.latitude + 0.003 * i,
      longitude: maringa.longitude + 0.003 * i
    });
  }
  return markers;
}
