import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  anunciosList: [],
  hasMore: false,
  totalValidacaoImagensInstalacao: 0,
  refetchValidacaoImagensInstalacao: false,
};

const acaoAtualizarValidacaoImagensInstalacao = state => {
  state.refetchValidacaoImagensInstalacao = true;
};

const acaoPersistValidacaoImagensInstalacao = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.anunciosList = [...content];
  state.hasMore = !last;
  state.totalValidacaoImagensInstalacao = totalElements || 0;
};

const resumeSlice = createSlice({
  name: 'validacaoImagensInstalacao',
  initialState: initialState,
  reducers: {
    persistValidacaoImagensInstalacao: acaoPersistValidacaoImagensInstalacao,
    atualizarValidacaoImagensInstalacao: acaoAtualizarValidacaoImagensInstalacao,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistValidacaoImagensInstalacao,
  atualizarValidacaoImagensInstalacao,
} = actions;
