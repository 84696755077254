import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ConsultaAnunciante from './consulta';
import EditarAnunciante from './editar';

const Anunciante = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaAnunciante />} />
      <Route path={`/:anuncianteId/editar`} element={<EditarAnunciante />} />
    </Routes>
  );
};

export default Anunciante;
