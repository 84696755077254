import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaLicencas from './consulta';
import EditarLicencas from './editar';
import CadastrarLicencas from './cadastrar';

const Licencas = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaLicencas />} />
      <Route path={`/:licencaId/editar`} element={<EditarLicencas />} />
      <Route path={`/criar`} element={<CadastrarLicencas />} />
    </Routes>
  );
};

export default Licencas;
