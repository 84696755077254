import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  TableContainer,
} from '@mui/material';
import {useMutation, useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {AttachMoney, Close, WhatsApp, StarHalf} from '@mui/icons-material';
import {FIND_ALL_ANUNCIOS} from '../../graphql/queries';
import {FINALIZAR_ANUNCIO} from '../../graphql/mutation';
import {persistAnunciosDaCampanha} from '../../redux/AnuncioCampanha/slice';
import {RoundTooltipButton} from '../../components/mui-button';
import {toastNotification} from '../../components/toastify';
import String from '../../utils/string';
import DetalhesMotoristaModal from '../../views/campanhas/modais/detalhes-motorista-modal';
import TableCellOrdered from '../../components/table-cell-ordered';
import AvaliacaoModal from '../motoristas/modal/avaliacao-modal';
import {LoadingPage} from '../../components/loading-page';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';

const columns = [
  {
    id: 'campanha',
    label: 'Campanha',
    campo: 'campanha.titulo',
    format: ({campanha}) => campanha?.titulo,
  },
  {
    id: 'nome',
    label: 'Nome',
    campo: 'motorista.nome',
    format: ({motorista}) => motorista.nome,
  },
  {
    id: 'veiculo',
    label: 'Marca/Modelo/Ano',
    campo: 'veiculo.modelo.marca.nome',
    format: ({veiculo}) =>
      veiculo?.modelo?.marca
        ? `${veiculo.modelo.marca.nome} - ${veiculo.modelo.nome} ${veiculo.anoFabricacao}/${veiculo.anoModelo}`
        : '-',
  },
  {
    id: 'cidade',
    label: 'Campanha cidade',
    format: ({campanha}) =>
      campanha?.cidade ? `${campanha.cidade.nome}/${campanha.cidade.uf}` : '-',
  },
];

const ConsultaAnuncios = ({pageSize = 20}) => {
  const {campanhaCidadeId} = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [loadingFinalizar, setLoadingFinalizar] = useState(undefined);
  const [orderBy, setOrderBy] = useState({campo: 'motorista.nome', asc: true});
  const [openAvaliacaoModal, setOpenAvaliacaoModal] = useState(false);
  const [openDetalhesModal, setOpenDetalhesModal] = useState(false);
  const [anuncioSelecionado, setAnuncioSelecionado] = useState(undefined);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {anuncios, totalAnuncios, refetchAnuncios} = useSelector(
    (store) => store.AnuncioCampanha,
  );

  const anunciosQuery = useQuery(FIND_ALL_ANUNCIOS, {
    variables: {
      searchDTO: {
        campanhaCidadeId,
        anuncioSituacao: 'AGUARDANDO_REMOCAO',
      },
      pageable: {
        pageNumber: page,
        pageSize,
        orderBy: [
          {
            campo: orderBy.campo,
            ordem: orderBy.asc ? 'ASC' : 'DESC',
          },
          {
            campo: 'motorista.nome',
            ordem: 'ASC',
          },
        ],
      },
    },
  });

  const [finalizarAnuncio] = useMutation(FINALIZAR_ANUNCIO);

  useEffect(() => {
    if (!anunciosQuery.loading && !anunciosQuery.error) {
      dispatch(persistAnunciosDaCampanha(anunciosQuery.data.anuncios));
    }
    if (refetchAnuncios) {
      anunciosQuery.refetch();
    }
  }, [anunciosQuery, dispatch, refetchAnuncios]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickFinalizarAnuncio = (anuncio) => {

    confirmAlertMui({
      open: true,
      message: 'Deseja finalizar o anúncio?',
      onConfirm: () => handleFinalizarAnuncio(anuncio),
    })

  };

  const handleClickOpenAvaliacao = (anuncio) => {
    setAnuncioSelecionado(anuncio);
    setOpenAvaliacaoModal(true);
  };

  const handleClickCloseAvaliacao = () => {
    setAnuncioSelecionado(undefined);
    setOpenAvaliacaoModal(false);
    anunciosQuery.refetch();
  };

  const handleFinalizarAnuncio = (anuncio) => {
    setLoadingFinalizar(anuncio);
    finalizarAnuncio({
      variables: {
        anuncio: {
          id: anuncio.id,
        },
      },
    })
      .then(() => {
        anunciosQuery.refetch();
        handleClickOpenAvaliacao(anuncio);
        toastNotification({
          message: 'O anuncio foi finalizado com sucesso!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => {
        setLoadingFinalizar(undefined);
      });
  };
  if (!anunciosQuery.loading && anuncios.length === 0)
    return (
      <Grid item xs={12}>
        <Typography>{`Nenhum motorista ativo na campanha.`}</Typography>
      </Grid>
    );
  if (anunciosQuery.loading && anuncios.length === 0)
    return <LoadingPage loading={anunciosQuery.loading} />;

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCellOrdered
                      key={column.id}
                      column={column}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  ))}
                  <TableCell id="acoes" key="acoes" align="center">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {anuncios.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() =>
                              navigate(`/motoristas/${row.motorista.id}`)
                            }>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell
                        align="center"
                        className="table-button-container">
                        {['AGUARDANDO_REMOCAO'].includes(row.situacao) && (
                          <RoundTooltipButton
                            title={'Finalizar anúncio'}
                            onClick={() => handleClickFinalizarAnuncio(row)}
                            icon={<Close />}
                            loading={loadingFinalizar === row}
                            id="icon-cancel"
                          />
                        )}
                        <RoundTooltipButton
                          title={'Dados Bancários'}
                          id="icon-green-tertiary"
                          onClick={() => {
                            setAnuncioSelecionado(row);
                            setOpenDetalhesModal(true);
                          }}
                          icon={<AttachMoney />}
                        />
                        <RoundTooltipButton
                          title={'Avaliação'}
                          onClick={() => handleClickOpenAvaliacao(row)}
                          icon={<StarHalf />}
                          id="icon-yellow"
                        />
                        <RoundTooltipButton
                          title={String.formatCelular(row.motorista?.celular)}
                          id="icon-whatsapp"
                          onClick={() =>
                            window.open(
                              `https://api.whatsapp.com/send?phone=${row.motorista?.celular}`,
                            )
                          }
                          icon={<WhatsApp />}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalAnuncios}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
      <DetalhesMotoristaModal
        openModal={openDetalhesModal}
        onClose={() => setOpenDetalhesModal(false)}
        anuncio={anuncioSelecionado}
      />
      <AvaliacaoModal
        openModal={openAvaliacaoModal}
        anuncio={anuncioSelecionado}
        motorista={anuncioSelecionado?.motorista}
        onClose={handleClickCloseAvaliacao}
      />
    </Grid>
  );
};

export default ConsultaAnuncios;
