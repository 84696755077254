import {getSidebarWidth} from '../../theme/mui-theme';

const styles = {
  modal: {
    width: () => `calc(100vw - ${getSidebarWidth()} - 8px) !important`,
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    background: '#fff',
    position: 'absolute',
    right: '0px',
    height: (theme) => `calc(100vh - 8px - ${theme.toolbarHeight})`,
    top: (theme) => `calc(8px + ${theme.toolbarHeight})`,

    '@media only screen and (max-width: 64em)': {
      flexBasis: '100%',
      maxWidth: () => `calc(100vw - ${getSidebarWidth()} - 8px) !important`,
    },
  },
  modalBody: {
    overflow: 'hidden',
  },
  modalOverlay: {
    backgroundColor: '#fff0 !important',
  },
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
  },
  headerText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowBack: {
    color: '#657496',
    cursor: 'pointer',
  },
  headerTitle: {
    fontSize: '18px',
    textAlign: 'left',
    color: '#657496',
    marginTop: '4px',
    marginLeft: '10px',
  },
  detalhesContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    height: 'calc(100vh - 135px)',
    overflowX: 'hidden',
  },
  blockHeader: {
    fontSize: '16px',
    textAlign: 'left',
    color: '#657496',
    fontWeight: 'bold',
    background: '#0096FF1f',
    padding: '6px 15px',
    width: '100vw',
    borderRadius: '6px',
  },
  blockContainer: {
    padding: '0 15px',
    margin: '10px 0',
  },
  label: {
    fontSize: 12,
    textAlign: 'left',
    color: '#afb7c9',
    margin: '5px 0 0',
  },
  inputValue: {
    fontSize: 14,
    textAlign: 'left',
    color: '#657496',
    margin: '0 0 5px',
  },
};
export default styles;
