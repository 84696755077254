import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Block,
  CameraAlt,
  CancelOutlined,
  CheckCircle,
  Close,
  Key,
  PersonOff,
  Save,
  WhatsApp,
} from '@mui/icons-material';
import {Box, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {confirmAlertMui} from '../../../components/confirm-alert/mui-confirm-alert';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../../components/mui-button';
import {TextAreaV2} from '../../../components/textarea/mui-textarea';
import {toastNotification} from '../../../components/toastify';
import {
  motoristaSituacao,
  motoristaSituacaoApp,
} from '../../../constants/enum-labels';
import {
  REPROVA_MOTORISTA_CNH,
  RESETAR_SENHA,
  UPDATE_EXCLUIR_MOTORISTA,
  UPDATE_INDICACOES_PAGAS_MOTORISTA,
  UPDATE_MOTORISTA_STATUS,
  UPDATE_OBSERVACAO_MOTORISTA,
} from '../../../graphql/mutation';
import {
  COUNT_MOTORISTAS_INDICADOS,
  STATUS_MOTORISTA_APP,
} from '../../../graphql/queries';
import Data from '../../../utils/data';
import File from '../../../utils/file';
import String from '../../../utils/string';
import ReprovadoMotivoModal from '../modal/reprovado-motivo-modal';
import motoristaStyles from '../styles';

const DadosPessoais = ({
  motorista = {},
  alteracoes,
  handleClickImage,
  refetch,
  role,
}) => {
  const [statusApp, setStatusApp] = useState('');
  const [fotoPerfil, setFotoPerfil] = useState(motorista.fotoPerfil);
  const [fotoCnh, setFotoCnh] = useState(motorista.fotoCnh);
  const [aprovouFotoCnh, setAprovouFotoCnh] = useState(
    motorista.aprovouFotoCnh,
  );
  const [obsFotoCnh, setObsFotoCnh] = useState(motorista.obsFotoCnh || '');
  const [observacao, setObservacao] = useState(motorista.observacao || '');
  const [indicacoesPagas, setIndicacoesPagas] = useState(
    motorista.indicacoesPagas || 0,
  );
  const [rejeitandoFoto, setRejeitandoFoto] = useState(false);
  const [aprovando, setAprovando] = useState(false);
  const [inativando, setInativando] = useState(false);
  const [editing, setEditing] = useState(false);
  const [openReprovadoModal, setOpenReprovadoModal] = useState(false);
  const [indicacoes, setIndicacoes] = useState(0);

  const [loadStatusApp, statusAppQuery] = useLazyQuery(STATUS_MOTORISTA_APP);
  const [loadIndicacoes, indicacoesQuery] = useLazyQuery(
    COUNT_MOTORISTAS_INDICADOS,
  );
  const [resetarSenha] = useMutation(RESETAR_SENHA);
  const [updateMotoristaStatus] = useMutation(UPDATE_MOTORISTA_STATUS);
  const [reprovaMotoristaCnh] = useMutation(REPROVA_MOTORISTA_CNH);
  const [updateObservacao] = useMutation(UPDATE_OBSERVACAO_MOTORISTA);
  const [updateIndicacoesPagas] = useMutation(
    UPDATE_INDICACOES_PAGAS_MOTORISTA,
  );
  const [updateExcluirMotorista] = useMutation(UPDATE_EXCLUIR_MOTORISTA);

  useEffect(() => {
    if (!motorista?.id) return;

    loadStatusApp({variables: {motoristaId: motorista.id}});
    loadIndicacoes({variables: {motoristaId: motorista.id}});

    setFotoPerfil(motorista.fotoPerfil);
    setFotoCnh(motorista.fotoCnh);
    setObservacao(motorista.observacao);
    setAprovouFotoCnh(motorista.aprovouFotoCnh);
    setObsFotoCnh(motorista.obsFotoCnh || '');
    setIndicacoesPagas(motorista.indicacoesPagas || 0);
  }, [motorista, loadStatusApp, loadIndicacoes]);

  useEffect(() => {
    const status = statusAppQuery.data?.statusMotoristaApp?.status;
    if (status) {
      setStatusApp(motoristaSituacaoApp[status]);
    }
  }, [statusAppQuery]);

  useEffect(() => {
    if (indicacoesQuery.loading) return;

    const quantidadeIndicacoes = indicacoesQuery.data?.indicacoes || 0;
    setIndicacoes(quantidadeIndicacoes);
  }, [indicacoesQuery]);

  const handleClickReprovaMotoristaCnh = () => {
    setRejeitandoFoto(true);
    reprovaMotoristaCnh({
      variables: {
        obs: obsFotoCnh,
        motoristaId: motorista.id,
      },
    })
      .then(() => {
        setAprovouFotoCnh(false);
        refetch && refetch();
        toastNotification({
          message: 'A CNH do motorista foi reprovada!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setRejeitandoFoto(false));
  };

  const handleClickChangeStatus = (situacao, loadingCallback) => {
    loadingCallback(true);
    updateMotoristaStatus({
      variables: {
        motoristaId: motorista.id,
        situacao,
      },
    })
      .then(() => {
        toastNotification({
          message: 'O status desse cadastro foi alterado!',
          type: 'success',
        });
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => loadingCallback(false));
  };

  const handleClickGerarNovaSenha = () => {
    if (!motorista?.usuario?.id) {
      toastNotification({
        message: 'Motorista não possui um usuário cadastrado.',
        type: 'error',
      });
      return;
    }

    resetarSenha({
      variables: {
        id: motorista.usuario.id,
      },
    })
      .then(({data}) => {
        toastNotification({
          titulo: `Nova senha: ${data.resetarSenha}`,
          message: 'Copiada para área de transferência!',
          type: 'success',
          autoClose: false,
        });
        navigator.clipboard.writeText(data.resetarSenha);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleExcluirMotorista = () => {
    updateExcluirMotorista({
      variables: {
        motorista: {
          id: motorista?.id,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'O motorista será excluindo em 7 (sete) dias!',
          type: 'success',
        });
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleClickSalvar = async () => {
    setAprovando(true);
    try {
      await updateObservacao({
        variables: {
          motoristaId: motorista.id,
          observacao,
        },
      });
      await updateIndicacoesPagas({
        variables: {
          motoristaId: motorista.id,
          indicacoesPagas,
        },
      });

      toastNotification({message: 'Informações salvas!', type: 'success'});
      refetch && refetch();
      setEditing(false);
    } catch (error) {
      toastNotification({message: error.message, type: 'error'});
    } finally {
      setAprovando(false);
    }
  };

  const isAtualizado = (campo) => {
    const alterado = alteracoes
      ?.map((a) => a[campo])
      ?.reduce((acc, atual) => acc || atual, false);
    return alterado ? {borderColor: '#00398E'} : null;
  };

  const permiteAcoes = () => {
    return !['ROLE_GERENTE_COMERCIAL', 'ROLE_FINANCEIRO'].includes(role);
  };

  return (
    <Box>
      <Grid container mt="2px" spacing={2} mb="16px">
        <Grid item xs={12} md={4}>
          <InputV2 label="Próxima ação no app" value={statusApp} disabled />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputV2
            label="Nota"
            value={parseFloat(motorista.notaAvaliacao || '0').toFixed(1)}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputV2
            label="Data de cadastro"
            value={Data.dataFormat(motorista.dataCadastro, 'DD/MM/YYYY')}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputV2
            label="Situação"
            value={
              motorista.situacao ? motoristaSituacao[motorista.situacao] : ''
            }
            disabled
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Typography sx={styles.label}>Foto de perfil</Typography>
              </Grid>
              <Grid item xs={12}>
                {fotoPerfil ? (
                  <Box
                    sx={styles.fotoContainer}
                    style={isAtualizado('fotoPerfil_Mod')}>
                    <img
                      style={styles.fotoImg}
                      src={File.getImageAuth(fotoPerfil)}
                      alt="foto-perfil"
                      onClick={() =>
                        handleClickImage(fotoPerfil, 'Foto de perfil')
                      }
                      onError={() => setFotoPerfil(null)}
                    />
                  </Box>
                ) : (
                  <Box sx={styles.fotoContainer}>
                    <CameraAlt />
                    <p>Nenhuma foto disponível</p>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box mt="27px" mb="48px">
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <InputV2
                  label="Nome"
                  value={motorista.nome || ''}
                  style={isAtualizado('nome_Mod')}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputV2
                  label="Data de nascimento"
                  value={Data.dataFormat(motorista.dataNascimento)}
                  style={isAtualizado('dataNascimento_Mod')}
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <InputV2
                  label="CPF"
                  value={motorista.cpf}
                  mask="cpf"
                  style={isAtualizado('cpf_Mod')}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  cursor: 'pointer',
                }}>
                <Grid
                  onClick={() => {
                    if (!motorista.email) return;
                    toastNotification({
                      message: 'Copiado para área de transferência!',
                      type: 'success',
                      autoClose: 500,
                    });
                    navigator.clipboard.writeText(motorista.email);
                  }}
                  sx={styles.clipboardContainer}
                />
                <InputV2
                  label="E-mail"
                  value={motorista.email || ''}
                  style={isAtualizado('email_Mod')}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  cursor: 'pointer',
                }}>
                <Grid
                  onClick={() => {
                    toastNotification({
                      message: 'Copiado para área de transferência!',
                      type: 'success',
                      autoClose: 500,
                    });
                    navigator.clipboard.writeText(motorista.celular);
                  }}
                  sx={styles.clipboardContainer}
                />
                <InputV2
                  label="Celular"
                  value={String.removeCodigoPaisCelular(motorista.celular)}
                  mask="phone"
                  endAdornment={
                    <WhatsApp
                      sx={styles.whatsAppIcon}
                      onClick={() =>
                        window.open(
                          `https://api.whatsapp.com/send?phone=${motorista.celular}`,
                        )
                      }
                    />
                  }
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  cursor: 'pointer',
                }}>
                <Grid
                  onClick={() => {
                    if (!motorista.celularAdicional) return;
                    toastNotification({
                      message: 'Copiado para área de transferência!',
                      type: 'success',
                      autoClose: 500,
                    });
                    navigator.clipboard.writeText(motorista.celularAdicional);
                  }}
                  sx={styles.clipboardContainer}
                />
                <InputV2
                  label="Telefone contato"
                  value={String.removeCodigoPaisCelular(
                    motorista.celularAdicional,
                  )}
                  mask="phone"
                  endAdornment={
                    motorista.celularAdicional && (
                      <WhatsApp
                        sx={styles.whatsAppIcon}
                        onClick={() =>
                          window.open(
                            `https://api.whatsapp.com/send?phone=${motorista.celularAdicional}`,
                          )
                        }
                      />
                    )
                  }
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box>
            <Grid container spacing={0.1}>
              <Grid item xs={12}>
                <Typography sx={styles.label}>Foto da CNH</Typography>
              </Grid>
              <Grid item xs={12}>
                {fotoCnh ? (
                  <div>
                    <Box
                      sx={styles.fotoContainer}
                      style={isAtualizado('fotoCnh_Mod')}>
                      <img
                        style={styles.fotoImg}
                        src={File.getImageAuth(fotoCnh)}
                        alt="foto-cnh"
                        onClick={() => handleClickImage(fotoCnh, 'Foto da CNH')}
                        onError={() => setFotoCnh(null)}
                      />
                    </Box>
                  </div>
                ) : (
                  <Box sx={styles.fotoContainer}>
                    <CameraAlt />
                    <p>Nenhuma foto disponível</p>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                {!aprovouFotoCnh &&
                  motorista.situacao !== 'APROVADO' &&
                  permiteAcoes() && (
                    <Box mt="8px">
                      <Grid container mt="16px" spacing={0.5}>
                        <Grid item xs={12}>
                          <TextAreaV2
                            label="Observação"
                            placeholder="Digite uma observação sobre rejeição"
                            row={3}
                            value={obsFotoCnh || ''}
                            onChange={(e) => {
                              setObsFotoCnh(e.target.value);
                              setAprovouFotoCnh(null);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {aprovouFotoCnh === null && (
                            <ButtonComponent
                              value={'Reprovar'}
                              onClick={handleClickReprovaMotoristaCnh}
                              id="button-cancel-empty"
                              loading={rejeitandoFoto}
                              disabled={rejeitandoFoto}
                            />
                          )}
                          {aprovouFotoCnh === false && (
                            <ButtonComponent
                              id="button-cancel-empty"
                              value="Reprovado"
                              disabled
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box width="100%" mt="27px" mb="48px">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputV2
                  label="CNH"
                  value={motorista.cnh || ''}
                  style={isAtualizado('cnh_Mod')}
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputV2
                  label="Data de validade da CNH"
                  value={Data.dataFormat(motorista.dataValidadeCnh)}
                  style={isAtualizado('dataValidadeCnh_Mod')}
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextAreaV2
            sx={styles.textArea}
            placeholder="Digite uma observação"
            value={observacao || ''}
            onChange={({target}) => {
              setObservacao(target.value);
              setEditing(true);
            }}
            style={{minWidth: '100%'}}
          />
        </Grid>
        <Grid item xs={3}>
          <InputV2
            label="Indicado por"
            value={motorista.motoristaIndicacao?.nome}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <InputV2 label="Indicações aprovadas" value={indicacoes} disabled />
        </Grid>
        <Grid item xs={3}>
          <InputV2
            label="Indicações pagas"
            value={indicacoesPagas}
            onChange={({target}) => {
              setIndicacoesPagas(target.value);
              setEditing(true);
            }}
            disabled={!permiteAcoes()}
          />
        </Grid>
        <Grid item xs={3} marginBottom="12px">
          {!['EXCLUIDO'].includes(motorista.situacao) &&
            motorista?.dataExclusao && (
              <InputV2
                label="Data exclusão"
                value={Data.dataFormat(motorista?.dataExclusao)}
                disabled
              />
            )}
        </Grid>
        {['REPROVADO', 'INATIVO', 'ATUALIZADO'].includes(motorista.situacao) &&
          motorista.reprovadoMotivo && (
            <Grid item xs={12}>
              <Typography sx={styles.label}>Motivo da reprovação</Typography>
              <TextAreaV2
                sx={styles.textArea}
                value={motorista.reprovadoMotivo || ''}
                style={{minWidth: '100%'}}
                disabled
              />
            </Grid>
          )}
      </Grid>
      {!['EXCLUIDO'].includes(motorista.situacao) && permiteAcoes() && (
        <Grid container item spacing={2} sx={styles.buttonContainer}>
          {!['INATIVO'].includes(motorista.situacao) && (
            <Grid item sx={styles.buttonWrapperOne}>
              <ButtonComponent
                type="button"
                id="button-green-cancel"
                value="Inativar"
                loading={inativando}
                icon={<Block sx={styles.iconGreenCancel} />}
                onClick={() =>
                  handleClickChangeStatus('INATIVO', setInativando)
                }
                sx={styles.button}
                disabled={inativando || aprovando}
              />
            </Grid>
          )}
          {!motorista.dataExclusao && (
            <Grid item sx={styles.buttonWrapper}>
              <ButtonComponent
                type="button"
                value="Excluir motorista"
                icon={<PersonOff sx={styles.icon} />}
                onClick={() =>
                  confirmAlertMui({
                    title: motorista?.anuncioAtivo?.id
                      ? 'Motorista em campanha'
                      : '',
                    message: motorista?.anuncioAtivo?.id
                      ? 'Este motorista está em campanha e será excluído em 7 (sete) dias. Deseja continuar?'
                      : 'O motorista será excluído em 7 (sete) dias. Deseja continuar?',
                    onConfirm: handleExcluirMotorista,
                  })
                }
                sx={styles.button}
                id="button-cancel"
                disabled={editing}
              />
            </Grid>
          )}
          <Grid item sx={styles.buttonWrapper} style={{marginRight: 'auto'}}>
            <ButtonComponent
              type="button"
              value="Gerar nova senha"
              icon={<Key sx={styles.icon} />}
              onClick={handleClickGerarNovaSenha}
              id="button"
              disabled={editing}
            />
          </Grid>
          {editing && (
            <>
              <Grid item sx={styles.buttonWrapper}>
                <ButtonComponent
                  type="button"
                  value="Cancelar"
                  icon={<Close sx={styles.icon} />}
                  onClick={() => {
                    setObservacao(motorista?.observacao || '');
                    setIndicacoesPagas(motorista?.indicacoesPagas || 0);
                    setEditing(false);
                  }}
                  id="button-cancel"
                />
              </Grid>
              <Grid item sx={styles.buttonWrapper}>
                <ButtonComponent
                  type="button"
                  value="Salvar"
                  loading={aprovando}
                  icon={<Save sx={styles.icon} />}
                  onClick={handleClickSalvar}
                  id="button"
                  disabled={aprovando}
                />
              </Grid>
            </>
          )}
          {!editing && (
            <>
              <Grid item sx={styles.buttonWrapper}>
                <ButtonComponent
                  type="button"
                  value="Reprovar"
                  icon={<CancelOutlined sx={styles.icon} />}
                  onClick={() => setOpenReprovadoModal(true)}
                  id="button-cancel-empty"
                  disabled={inativando || aprovando}
                />
              </Grid>
              {!['APROVADO'].includes(motorista.situacao) && (
                <Grid item sx={styles.buttonWrapper}>
                  <ButtonComponent
                    type="button"
                    value="Aprovar"
                    loading={aprovando}
                    icon={<CheckCircle sx={styles.icon} />}
                    onClick={() =>
                      handleClickChangeStatus('APROVADO', setAprovando)
                    }
                    id="button"
                    disabled={inativando || aprovando}
                  />
                </Grid>
              )}
            </>
          )}
          <ReprovadoMotivoModal
            openModal={openReprovadoModal}
            motorista={motorista}
            refetch={refetch}
            onClose={() => setOpenReprovadoModal(false)}
          />
        </Grid>
      )}
    </Box>
  );
};

const styles = {
  ...motoristaStyles,

  buttonContainer: {
    marginTop: '16px !important',
  },
  buttonWrapper: {
    width: '230px',
  },
  buttonWrapperOne: {
    width: '230px',
    '@media (min-width: 600px)': {
      marginRight: 'auto !important',
    },

    '@media (min-width: 1280px)': {
      justifyContent: 'flex-end !important',
      marginRight: '0px !important',
    },
  },
  clipboardContainer: {
    position: 'absolute',
    height: '-webkit-fill-available',
    width: '-webkit-fill-available',
    zIndex: 10,
  },
};

export default DadosPessoais;
