import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  filtroHome: ''
};

const acaoSetFiltroHome = (state, action) => {
  state.filtroHome = action.payload;
};

const resumeSlice = createSlice({
  name: 'home',
  initialState: initialState,
  reducers: {
    setFiltroHome: acaoSetFiltroHome,
  },
});

export const {actions, reducer} = resumeSlice;
export const {setFiltroHome} = actions;
