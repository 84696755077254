import React from 'react';
import {FormControl, Grid, MenuItem, Select, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {usuarioRole} from '../constants/enum-labels';
import HomeGerenteComercial from './home/home-gerente-comercial';
import HomeOperacional from './home/home-operacional';
import {getSidebarWidth} from '../theme/mui-theme';
import {persistRole} from '../redux/Auth/slice';
import HomeGerenteOperacional from './home/home-gerente-operacional';

const roles = [
  {value: 'ROLE_ADMINISTRADOR', label: 'Diretoria'},
  {value: 'ROLE_GERENTE_COMERCIAL', label: 'Dir. Comercial'},
  {value: 'ROLE_GERENTE_OPERACIONAL', label: 'Dir. Operacional'},
  {value: 'ROLE_OPERACIONAL', label: 'Operacional'},
  {value: 'ROLE_FINANCEIRO', label: 'Financeiro'},
]

const Home = () => {
  const dispatch = useDispatch();
  const {role, usuario} = useSelector((store) => store.Auth);
  const roleOriginal = usuario?.authorities?.at(0)?.nome;

  const renderHome = () => {
    switch (role) {
      case 'ROLE_ADMINISTRADOR':
      case 'ROLE_GERENTE_COMERCIAL':
      case 'ROLE_FINANCEIRO': return <HomeGerenteComercial role={role} />;
      case 'ROLE_GERENTE_OPERACIONAL': return <HomeGerenteOperacional role={role} />
      case 'ROLE_OPERACIONAL': return <HomeOperacional role={role} />
      default: return null;
    }
  }

  const handleChangePerfil = ({target}) => {
    dispatch(persistRole(target.value));
  }

  return (
    <Grid id="page-container" container>
      <Grid sx={styles.tituloContainer}>
        {roleOriginal === 'ROLE_ADMINISTRADOR' && (
          <FormControl sx={{m: 0, minWidth: 120}}>
            <Select
              value={role}
              onChange={handleChangePerfil}
              sx={styles.select}
            >
              {roles.map(({value, label}) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {roleOriginal !== 'ROLE_ADMINISTRADOR' && (
          <Typography sx={styles.titulo}>{usuarioRole[role]}</Typography>
        )}
      </Grid>
      {renderHome()}
    </Grid>
  );
};

const styles = {
  tituloContainer: {
    position: 'absolute',
    top: '0px',
    padding: '16px 0',
    right: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '60px',
    width: () => `calc(100vw - ${getSidebarWidth()} - 100px)`,
    transition: ({transitions}) => transitions.create('width', {easing: transitions.easing.sharp, duration: transitions.duration.leavingScreen}),
    zIndex: 99
  },
  titulo: {
    color: '#00398e',
    fontSize: '18px !important',
    textAlign: 'left',
    fontWeight: 'bold !important',
  },
  filtroContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '30px',
    height: '100px',
    background: '#fff'
  },
  title: {
    color: '#0A2549',
    fontSize: '20px !important',
    textAlign: 'left',
    fontWeight: 'bold !important'
  },
  mapaContainer: {
    height: (theme) => `calc(100vh - 100px - ${theme.toolbarHeight})`,
  },
  checkbox: {
    padding: '0 20px',
    '.MuiFormControlLabel-label': {
      display: 'block',
      color: '#657496',
      fontSize: '14px',
      fontWeight: 'bold',
    }
  },
  select: {
    '> fieldset': {
      border: 'none'
    },
    '.MuiSelect-outlined': {
      padding: 0
    },
    '.MuiOutlinedInput-input': {
      color: '#00398e',
      fontSize: '18px !important',
      textAlign: 'left',
      fontWeight: 'bold !important',
    }
  }
}

export default Home;
