import {useMutation} from '@apollo/client';
import {
  Cancel,
  Delete,
  Edit,
  OpenInNew,
  Save,
  Visibility,
} from '@mui/icons-material';
import {Box, Checkbox, Grid, IconButton, Typography} from '@mui/material';
import {Field, Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';
import DragNDrop from '../../components/drag-n-drop';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../components/mui-button';
import InputDate from '../../components/mui-input-date';
import UploadableFotoPerfil from '../../components/mui-uploadable-foto-perfil';
import Select from '../../components/select/mui-select';
import {TextAreaV2} from '../../components/textarea/mui-textarea';
import {toastNotification} from '../../components/toastify';
import {
  campanhaSituacao,
  faturarPor,
  formasPagamento,
} from '../../constants/enum-labels';
import {SelectAnunciantes} from '../../containers/selects/mui-anunciantes';
import {
  ATUALIZAR_CAMPANHA,
  FORCAR_FINALIZAR_ANUNCIOS,
  REPROVAR_CAMPANHAS,
} from '../../graphql/mutation';
import usePainelService from '../../services/PainelService';
import {
  uploadAmazonS3,
  uploadAmazonS3Arquivo,
} from '../../services/UploadService';
import Data from '../../utils/data';
import Number from '../../utils/number';
import CidadeItem from './components/cidade-item';
import DataEmitirNotaModal from './modais/data-emitir-nota-modal';
import ModalMotivoReprovacao from './modais/modal-motivo-reprovacao';

moment.locale('pt-br');

const FormularioCampanha = ({campanha = {}, role, permiteEditar, refetch}) => {
  const navigate = useNavigate();
  const painelService = usePainelService();

  const [formData, setFormData] = useState({});
  const [formDataCidades, setFormDataCidades] = useState([]);
  const [movimentacao, setMovimentacao] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [openDataNotaModal, setOpenDataNotaModal] = useState(false);
  const [uploadBannerCampanha, setUploadBannerCampanha] = useState(null);
  const [previewBannerCampanha, setPreviewBannerCampanha] = useState(null);
  const [bannerCampanha, setBannerCampanha] = useState(campanha.bannerCampanha);
  const [file, setFile] = useState(null);
  const [fileProducao, setFileProducao] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [loadingAccessPainel, setLoadingAccessPainel] = useState(false);
  const [modalMotivo, setModalMotivo] = useState(false);

  const [atualizarCampanha] = useMutation(ATUALIZAR_CAMPANHA);
  const [finalizarCampanha] = useMutation(FORCAR_FINALIZAR_ANUNCIOS);
  const [reprovarCampanhas] = useMutation(REPROVAR_CAMPANHAS);

  const isOperacional = () => {
    return role.includes('OPERACIONAL');
  };
  const isOperacionalStyle = () => {
    if (isOperacional()) return {display: 'none'};

    return null;
  };

  const filtrarParcelas = (movimentacao) => {
    return (
      (!movimentacao.parcelaTipo ||
        movimentacao.parcelaTipo === 'VEICULACAO') &&
      movimentacao.tipo === 'CREDITO'
    );
  };

  const filtrarParcelasProducao = (movimentacao) => {
    return (
      movimentacao.parcelaTipo === 'PRODUCAO' && movimentacao.tipo === 'CREDITO'
    );
  };

  const getFormaPagamento = (formaPagamento) => {
    if (!formaPagamento) return null;

    return {value: formaPagamento, label: formasPagamento[formaPagamento]};
  };

  useEffect(() => {
    const campanhaVeiculacaoMensal = campanha.cidades
      ?.map((c) => c.valorPorVeiculo * c.quantidadeVeiculos)
      .reduce((acc, current) => acc + current, 0);

    const totalInstalacaoCampanha = campanha.cidades
      ?.map((c) => c.instalacaoPorVeiculo * c.quantidadeVeiculos)
      .reduce((acc, current) => acc + current, 0);

    setFormData({
      ...campanha,
      modeloAnuncio: campanha.modeloAnuncio,
      valorVeiculacaoMensal: campanhaVeiculacaoMensal,
      totalInstalacaoCampanha,
      movimentacaoFinanceiras: campanha.movimentacaoFinanceiras,
      valorRepasseInstaladorInstalacao: Number.currencyFormat(
        campanha.valorRepasseInstaladorInstalacao,
      ),
      valorMensalMotorista: Number.currencyFormat(
        campanha.valorMensalMotorista,
      ),
      descontoPercent: Number.regraDeTresPorcentagem(
        campanha.desconto,
        campanha.valorBrutoVeiculo,
        true,
      ),
      desconto: Number.currencyFormat(campanha.desconto),
      repasseAgenciaPercent: Number.regraDeTresPorcentagem(
        campanha.repasseAgencia,
        campanhaVeiculacaoMensal,
        true,
      ),
      repasseAgencia: Number.currencyFormat(campanha.repasseAgencia),
      repasseTerceirosPercent: Number.regraDeTresPorcentagem(
        campanha.repasseTerceiros,
        campanhaVeiculacaoMensal - campanha.repasseAgencia,
        true,
      ),
      repasseTerceiros: Number.currencyFormat(campanha.repasseTerceiros),
      formaDePagamento: getFormaPagamento(
        campanha.movimentacaoFinanceiras?.filter(filtrarParcelas)[0]
          ?.formaPagamento,
      ),
      formaDePagamentoProducao: getFormaPagamento(
        campanha.movimentacaoFinanceiras?.filter(filtrarParcelasProducao)[0]
          ?.formaPagamento,
      ),
      faturarPor: {
        value: campanha.faturarPor,
        label: faturarPor[campanha.faturarPor],
      },
      parcelas:
        campanha.movimentacaoFinanceiras?.filter(filtrarParcelas).length,
      parcelasProducao: campanha.movimentacaoFinanceiras?.filter(
        filtrarParcelasProducao,
      ).length,
      dataInicioVeiculacao: campanha.dataInicioVeiculacao
        ? moment(`${campanha.dataInicioVeiculacao}`).toDate()
        : ' ',
      refazParcelamento: false,
    });
    setFormDataCidades(campanha.cidades);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha]);

  useEffect(() => {
    if (readOnly) return;

    const kmMensal = formDataCidades
      ?.map((c) => c.quantidadeKmMotoristaMes * c.quantidadeVeiculos)
      .reduce((acc, current) => acc + current, 0);

    const veiculacaoMensal = formDataCidades
      ?.map((c) => c.valorPorVeiculo * c.quantidadeVeiculos)
      .reduce((acc, current) => acc + current, 0);

    const producaoTotal = formDataCidades
      ?.map((c) => c.instalacaoPorVeiculo * c.quantidadeVeiculos)
      .reduce((acc, current) => acc + current, 0);

    const repasseAgencia =
      veiculacaoMensal *
      (Number.stringToFloat(formData.repasseAgenciaPercent) / 100);
    const repasseTerceiros =
      (veiculacaoMensal - repasseAgencia) *
      (Number.stringToFloat(formData.repasseTerceirosPercent) / 100);

    setFormData({
      ...formData,
      kmTotal: kmMensal * formData.quantidadeMeses,
      valorVeiculacaoMensal: veiculacaoMensal,
      valorVeiculacao: veiculacaoMensal * formData.quantidadeMeses,
      parcelas: formData.quantidadeMeses,
      parcelasProducao: formData.quantidadeMeses,
      repasseAgencia: Number.currencyFormat(repasseAgencia),
      repasseTerceiros: Number.currencyFormat(repasseTerceiros),
      valor: veiculacaoMensal * formData.quantidadeMeses + producaoTotal,
      refazParcelamento: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.quantidadeMeses]);

  function handleChangeUploadBannerCampanha(e) {
    const file = Array.from(e.target.files)[0];
    setUploadBannerCampanha(file);
    handlePreviewBannerCampanha(file);
  }

  function handlePreviewBannerCampanha(file) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setPreviewBannerCampanha(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function handleSubmitDadosCampanha() {
    if (!campanha.id) return;

    setSubmitting(true);

    let bannerResponse = null;
    let arquivoResponse = null;
    let arquivoProdResponse = null;

    try {
      if (uploadBannerCampanha) {
        bannerResponse = await uploadAmazonS3(uploadBannerCampanha);
      }

      if (file) {
        arquivoResponse = await uploadAmazonS3Arquivo(file);
      }
      if (fileProducao) {
        arquivoProdResponse = await uploadAmazonS3Arquivo(fileProducao);
      }
    } catch (error) {
      toastNotification({
        titulo: 'Não foi possível enviar os arquivos!',
        message: 'Tente novamente mais tarde.',
        type: 'error',
      });
    }

    atualizarCampanha({
      variables: {
        campanha: {
          id: campanha.id,
          titulo: formData.titulo,
          bannerCampanha: bannerResponse?.id || campanha.bannerCampanha,
          anunciante: {id: formData.anunciante?.id},
          dataInicioVeiculacao: Data.dataFormat(
            formData.dataInicioVeiculacao,
            'YYYY-MM-DD',
          ),
          quantidadeMeses: formData.quantidadeMeses,
          repasseAgencia: Number.currencyToFloat(formData.repasseAgencia),
          repasseTerceiros: Number.currencyToFloat(formData.repasseTerceiros),
          valorVeiculacao: Number.currencyToFloat(formData.valorVeiculacao),
          valor: Number.currencyToFloat(formData.valor),
          observacoesRelatorios: formData.observacoesRelatorios,
          pedidoInsercao:
            arquivoResponse?.id || formData.pedidoInsercao?.id
              ? {id: arquivoResponse?.id || formData.pedidoInsercao?.id}
              : undefined,
          pedidoInsercaoProducao:
            arquivoProdResponse?.id || formData.pedidoInsercaoProducao?.id
              ? {
                  id:
                    arquivoProdResponse?.id ||
                    formData.pedidoInsercaoProducao?.id,
                }
              : undefined,
          formaDePagamento: formData.formaDePagamento?.value,
          formaDePagamentoProducao: formData.formaDePagamentoProducao?.value,
          faturarPor: formData.faturarPor?.value,
          faturarProducao: formData.faturarProducao,
          cidades: formDataCidades.map((c) => ({
            id: c.id,
            quantidadeKmMotoristaMes: Number.safeParseFloat(
              c.quantidadeKmMotoristaMes,
            ),
            quantidadeVeiculos: c.quantidadeVeiculos,
            valorMensalMotorista: Number.currencyToFloat(
              c.valorMensalMotorista,
            ),
          })),
        },
        parcelamento: {
          parcelas: formData.parcelas,
          parcelasProducao: formData.parcelasProducao,
          vencimentoParcelas:
            Data.dataFormat(
              formData.movimentacaoFinanceiras?.filter(filtrarParcelas)[0]
                ?.dataPrevisaoPgto,
              'YYYY-MM-DD',
            ) || undefined,
          vencimentoParcelasProducao:
            Data.dataFormat(
              formData.movimentacaoFinanceiras?.filter(
                filtrarParcelasProducao,
              )[0]?.dataPrevisaoPgto,
              'YYYY-MM-DD',
            ) || undefined,
          dataEmitirNota:
            Data.dataFormat(
              formData.movimentacaoFinanceiras?.filter(filtrarParcelas)[0]
                ?.dataEmitirNota,
              'YYYY-MM-DD',
            ) || undefined,
          dataEmitirNotaProducao:
            Data.dataFormat(
              formData.movimentacaoFinanceiras?.filter(
                filtrarParcelasProducao,
              )[0]?.dataEmitirNota,
              'YYYY-MM-DD',
            ) || undefined,
          refazParcelamento: formData.refazParcelamento,
        },
      },
    })
      .then(() => {
        setReadOnly(true);
        toastNotification({
          message: 'Campanha atualizada com sucesso!',
          type: 'success',
        });
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSubmitting(false));
  }

  const handleFinalizarCampanha = () => {
    if (!campanha?.id) return;

    setSubmitting(true);
    finalizarCampanha({
      variables: {
        campanha: {
          id: campanha.id,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'Campanha finalizada com sucesso!',
          type: 'success',
        });

        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSubmitting(false));
  };

  const handleClickFinalizar = () => {
    confirmAlertMui({
      title: 'Deseja realmente finalizar a campanha?',
      message:
        'Isso irá forçar a campanha a iniciar o processo de finalização.',
      onConfirm: handleFinalizarCampanha,
    });
  };

  const handleClickExcluir = () => {
    confirmAlertMui({
      title: 'Deseja realmente excluír a campanha?',
      message:
        'Isso irá excluir todas as cidades que compoem essa campanha e retornar a proposta para negociação.',
      onConfirm: () => setModalMotivo(true),
    });
  };

  const handleExcluirCampanha = (motivo) => {
    if (!campanha?.id) return;
    setModalMotivo(false);
    setSubmitting(true);
    reprovarCampanhas({
      variables: {
        campanha: {
          id: campanha.id,
        },
        motivo,
      },
    })
      .then(() => {
        toastNotification({
          message: 'Campanha excluída com sucesso!',
          type: 'success',
        });

        navigate(`/campanhas`);
      })
      .catch((error) => {
        toastNotification({message: error.message, type: 'error'});
        setSubmitting(false);
      });
  };

  const situacaoPermiteEdicao = () => {
    return (
      ['PENDENTE', 'AGUARDANDO_INSTALACAO'].includes(campanha.situacao) &&
      permiteEditar
    );
  };

  if (!formData.id) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <h2>Carregando...</h2>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box>
      <Formik initialValues={formData} enableReinitialize>
        <Grid container spacing={2} padding="0px !important">
          <Grid item xs={3}>
            <UploadableFotoPerfil
              image={previewBannerCampanha || bannerCampanha}
              setImage={setBannerCampanha}
              handleChangeUploadImage={handleChangeUploadBannerCampanha}
              sx={styles.fotoPerfil}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={9}>
            <Box marginTop="10px" marginBottom="32px">
              <Grid container spacing={2}>
                <Grid item xl={1} xs={2}>
                  <InputV2
                    name="numero"
                    label="Nº"
                    value={formData.numero}
                    disabled
                  />
                </Grid>
                <Grid item xl={8} md={7} xs={6}>
                  <InputV2
                    name="titulo"
                    label="Título"
                    value={formData.titulo}
                    onChange={({target}) =>
                      setFormData({...formData, titulo: target.value})
                    }
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xl={3} md={3} xs={4}>
                  <InputV2
                    name="situacao"
                    label="Situação"
                    value={campanhaSituacao[formData.situacao]}
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={9} xs={8}>
                  <Field
                    component={SelectAnunciantes}
                    name="anunciante"
                    label="Cliente"
                    value={formData.anunciante}
                    endAdornment={
                      <Visibility
                        titleAccess="Ver anunciante"
                        onClick={() =>
                          navigate(
                            `/anunciantes/${formData.anunciante?.id}/editar`,
                          )
                        }
                        style={{cursor: 'pointer'}}
                      />
                    }
                    disabled
                  />
                </Grid>
                <Grid item md={3} xs={4}>
                  <InputDate
                    name="dataInicio"
                    label="Data início"
                    selected={formData.dataInicioVeiculacao}
                    onChange={(event) =>
                      setFormData({...formData, dataInicioVeiculacao: event})
                    }
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={2} xs={4}>
            <InputV2
              label="Km total"
              value={Number.format(formData.kmTotal)}
              disabled
            />
          </Grid>
          <Grid item md={1.5} xs={4}>
            <InputV2
              name="quantidadeVeiculos"
              label="Nº de carros"
              value={formData.quantidadeVeiculos}
              disabled
            />
          </Grid>
          <Grid item md={2.5} xs={4}>
            <InputV2
              label="Tempo de campanha"
              endAdornment={
                <Typography width="150px">
                  de {formData?.diasPeriodo} dias{' '}
                </Typography>
              }
              value={formData.quantidadeMeses}
              onChange={({target}) =>
                setFormData((f) => ({
                  ...f,
                  quantidadeMeses: target.value,
                  refazParcelamento: true,
                }))
              }
              disabled={readOnly}
            />
          </Grid>
          <Grid item md={2} xs={6} style={isOperacionalStyle()}>
            <InputV2
              label="Veiculação por mês"
              mask="moeda"
              value={Number.currencyFormat(formData.valorVeiculacaoMensal)}
              disabled
            />
          </Grid>
          <Grid item md={2} xs={6} style={isOperacionalStyle()}>
            <InputV2
              label="Reajuste"
              mask="moeda"
              value={Number.currencyFormat(formData.valorReajuste)}
              disabled
            />
          </Grid>
          <Grid item md={2} xs={6} style={isOperacionalStyle()}>
            <InputV2
              label="Veiculação total"
              mask="moeda"
              value={Number.currencyFormat(formData.valorVeiculacao)}
              disabled
            />
          </Grid>
          <Grid item md={4} xs={7} style={isOperacionalStyle()}>
            <Box sx={styles.box}>
              <InputV2
                label="Agência por mês"
                name="repasseAgenciaPercent"
                mask="porcento"
                value={formData.repasseAgenciaPercent}
                defaultValue={0}
                disabled
              />
              <Box sx={{width: '300px'}}>
                <InputV2
                  label="Valor"
                  name="repasseAgencia"
                  mask="moeda"
                  value={formData.repasseAgencia}
                  defaultValue={0}
                  disabled
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={2} xs={5} style={isOperacionalStyle()}>
            <InputV2
              label="Agência total"
              mask="moeda"
              defaultValue={0}
              value={Number.currencyFormat(
                Number.stringToFloat(formData.repasseAgencia) *
                  formData.quantidadeMeses || 0,
              )}
              disabled
            />
          </Grid>
          <Grid item md={4} xs={7} style={isOperacionalStyle()}>
            <Box sx={styles.box}>
              <InputV2
                label="Terceiros por mês"
                name="repasseTerceirosPercent"
                mask="porcento"
                value={formData.repasseTerceirosPercent}
                defaultValue={0}
                disabled
              />
              <Box sx={{width: '300px'}}>
                <InputV2
                  label="Valor"
                  name="repasseTerceiros"
                  mask="moeda"
                  value={formData.repasseTerceiros}
                  defaultValue={0}
                  style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                  disabled
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={2} xs={5} style={isOperacionalStyle()}>
            <InputV2
              label="Terceiros total"
              mask="moeda"
              defaultValue={0}
              value={Number.currencyFormat(
                Number.stringToFloat(formData.repasseTerceiros) *
                  formData.quantidadeMeses || 0,
              )}
              disabled
            />
          </Grid>
          <Grid
            container
            item
            md={5}
            xs={6}
            spacing={0}
            style={isOperacionalStyle()}>
            <Grid item xs={7}>
              <InputV2
                label="Valor produção total"
                mask="moeda"
                value={Number.currencyFormat(formData?.totalInstalacaoCampanha)}
                disabled
              />
            </Grid>
            <Grid item xs={5}>
              <InputV2
                label="Reserva técnica"
                mask="moeda"
                value={Number.currencyFormat(
                  Number.safeParseFloat(formData?.totalInstalacaoCampanha) *
                    0.1 *
                    formData.quantidadeMeses,
                )}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item md={3} xs={4} style={isOperacionalStyle()}>
            <InputV2
              label="Valor total da campanha"
              mask="moeda"
              value={Number.currencyFormat(formData.valor)}
              disabled
            />
          </Grid>
          <Grid item md={3} xs={6} style={isOperacionalStyle()}>
            <InputV2
              label="Comercial"
              value={
                campanha.comercial?.comercialResponsavel?.nomeRazao ||
                campanha.comercial?.nomeRazao
              }
              disabled
            />
          </Grid>
          <Grid container item xs={12} sx={styles.cidades}>
            <Grid item xs={12}>
              <Box padding={'8px 0 8px 18px'}>
                <Typography sx={styles.labelFaturamento}>Cidades</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {formDataCidades.map((item, index) => (
                <CidadeItem
                  key={item.id}
                  item={item}
                  readOnly={readOnly}
                  setValue={(e) => {
                    formDataCidades[index] = e;
                    setFormDataCidades([...formDataCidades]);
                  }}
                />
              ))}
            </Grid>
          </Grid>
          {campanha.situacao === 'NAO_APROVADA' && (
            <Grid item md={12} xs={12}>
              <TextAreaV2
                label="Motivo da reprovação"
                value={campanha.mensagemReprovacao || ''}
                disabled
              />
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <TextAreaV2
              name="observacoesRelatorios"
              label="Observações para relatório"
              value={formData.observacoesRelatorios || ''}
              onChange={({target}) =>
                setFormData({
                  ...formData,
                  observacoesRelatorios: target.value,
                })
              }
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={6} style={isOperacionalStyle()}>
            <Box mt="18px">
              <Typography sx={styles.labelFaturamento}>
                Faturamento da veiculação
              </Typography>
            </Box>
            <Box mt="18px">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Select
                    label="Formas de Pagamento"
                    placeholder="Selecione"
                    options={[
                      {value: 'CARTAO', label: 'Cartão'},
                      {value: 'BOLETO', label: 'Boleto'},
                      {
                        value: 'TRANSFERENCIA_BANCARIA',
                        label: 'Transferência Bancária',
                      },
                      {value: 'PERMUTA', label: 'Permuta'},
                    ]}
                    value={formData.formaDePagamento}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        formaDePagamento: e,
                        refazParcelamento: true,
                      });
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputV2
                    name="parcelas"
                    label="Parcelas"
                    value={formData.parcelas}
                    onChange={({target}) =>
                      setFormData({
                        ...formData,
                        parcelas: target.value,
                        refazParcelamento: true,
                      })
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    label="Faturar por"
                    options={[
                      {value: 'VALOR_BRUTO', label: 'Valor bruto'},
                      {value: 'VALOR_LIQUIDO', label: 'Valor líquido'},
                    ]}
                    value={formData?.faturarPor}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        faturarPor: e,
                        refazParcelamento: true,
                      });
                    }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Box margin="18px 0px">
                <Grid container columnSpacing={20}>
                  <Grid item xs={6}>
                    <Typography sx={styles.parcelamentoTitulo}>
                      Parcelamento
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={styles.parcelamentoTitulo}>
                      Emissão NF
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                {formData?.movimentacaoFinanceiras
                  ?.filter(filtrarParcelas)
                  .map((m, index) => (
                    <Grid
                      container
                      key={m.id}
                      alignItems="center"
                      marginBottom="10px"
                      columnSpacing={20}>
                      <Grid item xs={6}>
                        <Typography sx={styles.itemParcela}>
                          {`${index + 1}ª) ${Number.currencyFormat(
                            m.valor,
                          )} - ${
                            m.dataPrevisaoPgto
                              ? moment(m.dataPrevisaoPgto).format('DD/MM/YYYY')
                              : '-'
                          }`}
                        </Typography>
                      </Grid>
                      <Grid item container xs={6} spacing={0.5}>
                        <Grid item xs={10}>
                          <InputDate
                            selected={
                              m?.dataEmitirNota
                                ? moment(m.dataEmitirNota).toDate()
                                : ''
                            }
                            disabled
                          />
                        </Grid>
                        {!m.notaEmitida && readOnly && (
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                setMovimentacao(m);
                                setOpenDataNotaModal(true);
                              }}>
                              <Edit style={{width: 20}} />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            alignItems="flex-start"
            style={isOperacionalStyle()}>
            <Box mt="15px">
              <Grid container sx={styles.gridLabelFaturamento}>
                <Grid item xs={4}>
                  <Typography sx={styles.labelFaturamento}>
                    Faturamento da produção
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Checkbox
                    checked={formData.faturarProducao || false}
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            {formData.faturarProducao && (
              <Box>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={6}>
                    <Select
                      label="Formas de Pagamento"
                      placeholder="Selecione"
                      options={[
                        {value: 'CARTAO', label: 'Cartão'},
                        {value: 'BOLETO', label: 'Boleto'},
                        {
                          value: 'TRANSFERENCIA_BANCARIA',
                          label: 'Transferência Bancária',
                        },
                        {value: 'PERMUTA', label: 'Permuta'},
                      ]}
                      value={formData.formaDePagamentoProducao}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputV2
                      name="parcelasProducao"
                      label="Parcelas"
                      value={formData.parcelasProducao}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Box margin="15px 0px">
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    columnSpacing={12}>
                    <Grid item xs={6}>
                      <Typography sx={styles.parcelamentoTitulo}>
                        Parcelamento
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={styles.parcelamentoTitulo}>
                        Emissão NF
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  {formData?.movimentacaoFinanceiras
                    ?.filter(filtrarParcelasProducao)
                    .map((m, index) => (
                      <Grid
                        container
                        key={m.id}
                        alignItems="center"
                        justifyContent="flex-start"
                        marginBottom="10px"
                        spacing={12}>
                        <Grid item xs={6} key={m.id}>
                          <Typography sx={styles.itemParcela}>
                            {`${index + 1}ª) ${Number.currencyFormat(
                              m.valor,
                            )} - ${
                              m.dataPrevisaoPgto
                                ? moment(m.dataPrevisaoPgto).format(
                                    'DD/MM/YYYY',
                                  )
                                : '-'
                            }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            <Grid container columnSpacing={0}>
                              <Grid item xs={10}>
                                <InputDate
                                  selected={
                                    m?.dataEmitirNota
                                      ? moment(m.dataEmitirNota).toDate()
                                      : ''
                                  }
                                  disabled
                                />
                              </Grid>
                              {!m.notaEmitida && readOnly && (
                                <Grid item xs={2}>
                                  <IconButton
                                    onClick={() => {
                                      setMovimentacao(m);
                                      setOpenDataNotaModal(true);
                                    }}>
                                    <Edit style={{width: 20}} />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={2}
            style={role === 'ROLE_OPERACIONAL' ? {display: 'none'} : null}>
            <Grid item xs={6}>
              <Typography sx={styles.pedidoInsercaoHeader}>
                P.I. (Veiculação)
              </Typography>
              <Grid item xs={12}>
                <DragNDrop
                  id={campanha.pedidoInsercao?.id}
                  file={file}
                  onChange={setFile}
                  onDelete={() => {
                    setFile(undefined);
                    setFormData((f) => ({...f, pedidoInsercao: undefined}));
                  }}
                  fileName={file?.name || formData.pedidoInsercao?.nome}
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
            {formData.faturarProducao && (
              <Grid item xs={6}>
                <Typography sx={styles.pedidoInsercaoHeader}>
                  A.P. (Produção)
                </Typography>
                <Grid item xs={12}>
                  <DragNDrop
                    id={campanha.pedidoInsercaoProducao?.id}
                    onChange={setFileProducao}
                    onDelete={() => {
                      setFileProducao(undefined);
                      setFormData((f) => ({
                        ...f,
                        pedidoInsercaoProducao: undefined,
                      }));
                    }}
                    fileName={
                      fileProducao?.name ||
                      formData.pedidoInsercaoProducao?.nome
                    }
                    file={fileProducao}
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Formik>
      <Grid container rowSpacing={2} columnSpacing={0} mt="16px">
        <Grid item lg={8} xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Grid container columnSpacing={1} rowSpacing={2}>
              {['ADMINISTRADOR', 'GERENTE'].contains(role) &&
                campanha.situacao !== 'FINALIZADA' && (
                  <>
                    {readOnly ? (
                      <Grid
                        item
                        lg={4}
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                        sx={styles.sizeContainerButton}>
                        <ButtonComponent
                          sx={{width: '256px'}}
                          id="button"
                          value="Editar"
                          endIcon={<Edit />}
                          onClick={() => setReadOnly(false)}
                          disabled={submitting}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        item
                        lg={4}
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                        sx={styles.sizeContainerButton}>
                        <ButtonComponent
                          type="submit"
                          id="button"
                          value="Salvar"
                          sx={{width: '256px'}}
                          loading={submitting}
                          icon={<Save />}
                          onClick={handleSubmitDadosCampanha}
                          disabled={submitting}
                        />
                      </Grid>
                    )}
                    {['ATIVA', 'ATIVA_PARCIAL'].includes(campanha.situacao) &&
                      !permiteEditar && (
                        <Grid
                          item
                          lg={4}
                          xs={12}
                          display="flex"
                          justifyContent="flex-end"
                          sx={styles.sizeContainerButton}>
                          <ButtonComponent
                            sx={{width: '256px'}}
                            type="button"
                            id="button-cancel"
                            loading={submitting}
                            value="Finalizar Campanha"
                            icon={<Cancel />}
                            onClick={handleClickFinalizar}
                            disabled={submitting || !readOnly}
                          />
                        </Grid>
                      )}
                  </>
                )}
              {situacaoPermiteEdicao() &&
                ['ROLE_ADMINISTRADOR', 'ROLE_GERENTE_COMERCIAL'].includes(
                  role,
                ) && (
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    sx={styles.sizeContainerButton}>
                    <ButtonComponent
                      sx={{width: '256px'}}
                      type="button"
                      value={'Excluir campanha'}
                      loading={submitting}
                      icon={<Delete />}
                      onClick={handleClickExcluir}
                      id="button-cancel"
                      disabled={submitting || !readOnly}
                    />
                  </Grid>
                )}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
          display="flex"
          justifyContent="flex-end"
          sx={styles.sizeContainerButtonFinal}>
          <ButtonComponent
            sx={{width: '256px'}}
            id="button-gray-blue"
            type="button"
            value="Painel anunciante"
            loading={loadingAccessPainel}
            icon={<OpenInNew />}
            onClick={() => {
              setLoadingAccessPainel(true);
              painelService.acessarPainelAnunciante(
                campanha.anunciante?.id,
                setLoadingAccessPainel,
              );
            }}
            disabled={submitting}
          />
        </Grid>
      </Grid>
      <DataEmitirNotaModal
        openModal={openDataNotaModal}
        movimentacao={movimentacao}
        onClose={() => {
          setOpenDataNotaModal(false);
          setMovimentacao(null);
        }}
        onSave={() => refetch && refetch()}
      />
      <ModalMotivoReprovacao
        openModal={modalMotivo}
        submitMotivo={handleExcluirCampanha}
        cancelar={() => setModalMotivo(false)}
      />
    </Box>
  );
};

const styles = {
  itemParcela: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    color: '#6c7b9b',
    marginBottom: '5px',
  },
  parcelamentoTitulo: {
    margin: '0 0 5px 0',
    color: (theme) => theme.palette.paterns.navyBlue,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  labelFaturamento: {
    color: (theme) => theme.palette.paterns.navyBlue,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  mapaContainer: {
    height: '36vh',
    marginBottom: '16px',

    ' @media (min-width: 1367px)': {
      height: '40vh',
      marginNottom: '24px',
    },
  },
  pedidoInsercaoHeader: {
    fontWeight: '700',
    fontSize: 16,
    lineHeight: '36px',
    color: (theme) => theme.palette.paterns.navyBlue,
  },
  gridLabelFaturamento: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    alignItems: 'center',
  },
  sizeContainerButton: {
    '@media (min-width: 600px)': {
      justifyContent: 'center !important',
    },

    '@media (min-width: 1280px)': {
      justifyContent: 'flex-end !important',
    },
  },
  sizeContainerButtonFinal: {
    '@media (min-width: 600px)': {
      justifyContent: 'center !important',
      marginRight: '0px !important',
    },

    '@media (min-width: 1280px)': {
      justifyContent: 'flex-end !important',
      marginRight: '0px !important',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cidades: {
    background: '#e4e9f3bf',
    borderRadius: '6px',
    padding: '0px !important',
    margin: '20px 0px 16px 16px',
  },
};

export default FormularioCampanha;
