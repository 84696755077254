import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Paper, Grid, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import {
  Visibility,
  WarningRounded,
} from '@mui/icons-material';
import {
  FIND_ALL_CAMPANHA_FALTANDO_MOTORISTAS,
} from '../../graphql/queries';
import {RoundTooltipButton} from '../../components/mui-button';
import {campanhaSituacao} from '../../constants/enum-labels';

const getPeriodoVeiculacao = (campanha) => {
  const dataInicioCampanha = campanha.dataInicioVeiculacao;

  if (!dataInicioCampanha) return;

  const dataInicio = moment(dataInicioCampanha);
  const dataFinal = moment(dataInicioCampanha)
    .add(campanha?.diasPeriodo * campanha?.quantidadeMeses || 0, 'd')
    .subtract(1, 'd');

  return `${dataInicio.format('DD/MM/YY')} - ${dataFinal.format('DD/MM/YY')}`;
};

const columns = [
  {
    id: 'anunciante',
    label: 'Anunciante',
    campo: 'anunciante.nomeFantasia',
    format: ({anunciante}) => anunciante?.nomeFantasia || ' - ',
  },
  {
    id: 'cidades',
    label: 'Cidades',
    format: ({cidades}) => (cidades.length || 0),
    calculado: true
  },
  {
    id: 'titulo',
    label: 'Título',
    format: ({titulo, numero}) => `[${numero}] ${titulo}`
  },
  {
    id: 'quantidadeVeiculos',
    label: 'Veículos',
    format: ({situacao, quantidadeVeiculos, quantidadeVeiculosNaCampanha}) => {
      if (situacao === 'PENDENTE' || quantidadeVeiculos === quantidadeVeiculosNaCampanha)
        return quantidadeVeiculos;

      return <>{`${quantidadeVeiculosNaCampanha}/${quantidadeVeiculos}`}<WarningRounded sx={styles.warningIcon} titleAccess='Faltam motoristas na campanha!' /></>
    },
    style: {whiteSpace: 'nowrap'}
  },
  {
    id: 'situacao',
    label: 'Situação',
    format: ({situacao}) => campanhaSituacao[situacao],
  },
  {
    id: 'periodoVeiculacao',
    label: 'Veículação',
    campo: 'dataInicioVeiculacao',
    format: (campanha) => getPeriodoVeiculacao(campanha),
  },
];

const ConsultaCampanhasFaltandoVeiculos = () => {
  const navigate = useNavigate();

  const [campanhas, setCampanhas] = useState([]);

  const campanhasQuery = useQuery(FIND_ALL_CAMPANHA_FALTANDO_MOTORISTAS);

  useEffect(() => {
    if (campanhasQuery.loading || campanhasQuery.errors)
      return;

    setCampanhas(campanhasQuery.data.campanhas);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhasQuery]);

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead tabIndex={-1}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell id="acoes" key="acoes" align="center">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campanhas.map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={index} align={column.align} style={column.style}>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="center">
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                        >
                          <RoundTooltipButton
                            title={'Visualizar'}
                            onClick={() =>
                              navigate(`campanhas/${row.id}/editar`)
                            }
                            icon={<Visibility />}
                            id="icon-primary"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const styles = {
  warningIcon: {
    color: (theme) => theme.palette.paterns.yellow,
    height: '15px',
    width: '15px',
    marginLeft: '4px',
    marginBottom: '-2px'
  }
};

export default ConsultaCampanhasFaltandoVeiculos;
