import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_BY_ID_NOTIFICACAO} from '../../graphql/queries';
import FormularioNotificacao from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarNotificacao = () => {
  const {notificacaoId} = useParams();

  const notificacaoQuery = useQuery(FIND_BY_ID_NOTIFICACAO, {
    variables: {
      id: notificacaoId,
    },
  });

  const {data = {}} = notificacaoQuery;
  const {notificacao} = data;

  const refetch = () => {
    notificacaoQuery.refetch();
  };

  if (notificacaoQuery.loading || notificacaoQuery.error) {
    return (
      <LoadingPage loading={notificacaoQuery.loading} error={notificacaoQuery.error} />
    );
  }

  return (
    <FormularioNotificacao notificacao={notificacao} refetch={refetch} />
  );
};

export default EditarNotificacao;
