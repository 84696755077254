import React from 'react';
import {Grid, Paper} from '@mui/material';
import FormularioCadastroComercial from './formularios/dados-cadastrais';

const CadastrarComercial = () => {
  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <FormularioCadastroComercial />
      </Paper>
    </Grid>
  );
};

export default CadastrarComercial;
