import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaMotoristas from './consulta';
import FormularioMotorista from './formulario';

const Motoristas = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaMotoristas />} />
      <Route path={`/:motoristaId`} element={<FormularioMotorista />} />
    </Routes>
  );
};

export default Motoristas;
