import styled from '@emotion/styled';
import React, {useState, useEffect} from 'react';
import Map from '../../google/Map';
import getPlaces from '../../utils/getPlaces';
import Circle from '../../google/Circle';
import Marker from '../../google/Marker';

const places = getPlaces();
const placeIndex = 0;

const Mapa = ({
  kmRaio,
  latitude,
  longitude,
  pontosInteresse = [],
  onMapClick,
  regiaoInteresse = [],
  palavraInteresse = [],
  deletePonto,
  invisibleRegiao,
  invisiblePalavra,
  onclick,
}) => {
  const kmRaioInt = parseInt(kmRaio, 10) || 0;
  const zoom = 10;
  const [palavras, setPalavras] = useState([...palavraInteresse]);

  useEffect(() => {
    const array = [];

    for (var c = 0; c <= palavraInteresse.length - 1; c++) {
      var indicePalavra = true;
      for (var k = 0; k <= invisiblePalavra.length - 1; k++) {
        if (invisiblePalavra[k]?.id === palavraInteresse[c]?.id) {
          indicePalavra = false;
        }
      }
      if (indicePalavra === true) {
        array.push(palavraInteresse[c]);
      }
    }
    setPalavras([...array]);
  }, [invisiblePalavra, palavraInteresse]);
  const [showCidadeRadius, setShowCidadeRadius] = useState(true);

  return (
    <MapContainer>
      <FloatingPanel>
        <Button
          onClick={() => setShowCidadeRadius(!showCidadeRadius)}
          style={{minWidth: 200}}>
          {`${showCidadeRadius ? 'Ocultar' : 'Exibir'} raio da cidade`}
        </Button>
      </FloatingPanel>
      <Map
        zoom={zoom}
        center={{
          lat: latitude || places[placeIndex].lat,
          lng: longitude || places[placeIndex].lng,
        }}
        onMapClick={onMapClick}>
        <Circle
          key="raioCidade"
          kmRaio={showCidadeRadius ? kmRaioInt : undefined}
          latitude={latitude || places[placeIndex].lat}
          longitude={longitude || places[placeIndex].lng}
        />
        {pontosInteresse?.map((raio, index) => {
          var indicePonto = true;

          for (var c = 0; c <= deletePonto.length - 1; c++) {
            if (deletePonto[c].id === raio.id) {
              indicePonto = false;

              break;
            }
          }
          return (
            <Circle
              key={index}
              kmRaio={indicePonto ? parseFloat(raio.kmRaio) : undefined}
              latitude={parseFloat(raio.latitude)}
              longitude={parseFloat(raio.longitude)}
              fillColor="#ff1a1a"
              strokeColor="#ff0000"
              onClick={() =>
                onclick({latitude: raio.latitude, longitude: raio.longitude})
              }
            />
          );
        })}
        {regiaoInteresse?.map((raio, index) => {
          var indice = true;

          for (var c = 0; c <= invisibleRegiao.length - 1; c++) {
            if (invisibleRegiao[c].id === raio.id) {
              indice = false;
              break;
            }
          }

          return (
            <Circle
              key={index}
              kmRaio={indice ? parseFloat(raio.kmRaio) : undefined}
              latitude={parseFloat(raio.latitude)}
              longitude={parseFloat(raio.longitude)}
              fillColor="rgb(27, 108, 57)"
              strokeColor="rgb(27, 108, 57)"
              onClick={() =>
                onclick({
                  latitude: parseFloat(raio.latitude),
                  longitude: parseFloat(raio.longitude),
                })
              }
            />
          );
        })}

        {palavras.map((p) =>
          (p.pontos || []).map((raio, index) => {
            return (
              <Marker
                key={index}
                active={false}
                title={raio.nome}
                position={{
                  lat: raio.latitude,
                  lng: raio.longitude,
                }}
                events={{}}
              />
            );
          }),
        )}
      </Map>
    </MapContainer>
  );
};

const MapContainer = styled.div`
  position: relative;
  height: 100%;
`;
const Button = styled.button`
  font-family: system-ui;
  font-size: 18px;
  line-height: normal;
  margin: 0;
  height: 40px;
  font-weight: 500;
  background: #ffffff00;
  border: none;
  box-shadow: -1px 0px 2px #00000033;
  color: #000;
`;
const FloatingPanel = styled.div`
  position: absolute;
  top: 10px;
  left: 180px;
  padding-left: 5px;
  background: #fff;
  z-index: 5;
  border-radius: 2px;
`;

export default Mapa;
