import {useMutation} from '@apollo/client';
import {Edit, Save} from '@mui/icons-material';
import {Box, Checkbox, FormControlLabel, Grid, Paper} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent} from '../../components/mui-button';
import {toastNotification} from '../../components/toastify';
import {ATUALIZAR_MODELO_ANUNCIO} from '../../graphql/mutation';
import {atualizarModeloAnuncios} from '../../redux/ModeloAnuncio/slice';
import Number from '../../utils/number';
import String from '../../utils/string';

const FormularioModeloAnuncio = ({modelo = {}}) => {
  const dispatch = useDispatch();
  const {role} = useSelector((store) => store.Auth);

  const [readOnly, setReadOnly] = useState(!!modelo?.id);
  const [modeloAnuncio, setModeloAnuncio] = useState({
    frente: false,
    laterais: false,
    lateraisRecorte: false,
    traseira: false,
    interno: false,
    interativo: false,
    ativo: false,
  });

  const [atualizarModeloAnuncio, {loading}] = useMutation(
    ATUALIZAR_MODELO_ANUNCIO,
  );

  const editarDisabled = () => {
    return (
      role.includes('OPERACIONAL') ||
      role.includes('FINANCEIRO')
    );
  };

  async function handleSubmitDadosModeloAnuncio() {
    if (String.isEmpty(modeloAnuncio.nome)) {
      toastNotification({message: 'O título é obrigatório!', type: 'error'});
      return;
    }

    const dadosModeloAnuncio = {
      id: modeloAnuncio.id,
      nome: modeloAnuncio.nome || '',
      descricao: modeloAnuncio.descricao || '',
      valorInstaladorPorInstalacao: Number.currencyToFloat(
        modeloAnuncio.valorInstaladorPorInstalacao,
      ),
      valorMotorista: Number.currencyToFloat(modeloAnuncio.valorMotorista),
      valorBonusInstalacao: Number.currencyToFloat(
        modeloAnuncio.valorBonusInstalacao,
      ),
      valorVenda: Number.currencyToFloat(modeloAnuncio.valorVenda),
      percentualImpactos: Number.currencyToFloat(
        modeloAnuncio.percentualImpactos,
      ),
      diasPeriodo: Number.stringToFloat(modeloAnuncio.diasPeriodo),
      frente: modeloAnuncio.frente || false,
      laterais: modeloAnuncio.laterais || false,
      lateraisRecorte: modeloAnuncio.lateraisRecorte || false,
      traseira: modeloAnuncio.traseira || false,
      interno: modeloAnuncio.interno || false,
      interativo: modeloAnuncio.interativo || false,
      ativo: modeloAnuncio.ativo || false,
      tabelaEspecial: Number.stringToFloat(modeloAnuncio.tabelaEspecial),
      tabelaUm: Number.stringToFloat(modeloAnuncio.tabelaUm),
      tabelaDois: Number.stringToFloat(modeloAnuncio.tabelaDois),
      tabelaTres: Number.stringToFloat(modeloAnuncio.tabelaTres),
    };

    atualizarModeloAnuncio({
      variables: {
        modeloAnuncio: {
          ...dadosModeloAnuncio,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'Os dados cadastrados foram salvos com sucesso',
          type: 'success',
        });
        dispatch(atualizarModeloAnuncios());
        setReadOnly(true);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  }

  const handleChangeField = (field, valor) => {
    setModeloAnuncio((prevState) => ({...prevState, [field]: valor}));
  };

  const handleChangeFieldValor = (field, percentField, valor, valorTotal) => {
    handleChangeField(field, valor);
    handleChangeField(
      percentField,
      Number.regraDeTresPorcentagem(valor, valorTotal, true),
    );
  };

  const handleChangeFieldPercent = (field, percentField, valor, valorTotal) => {
    handleChangeField(percentField, valor);
    handleChangeField(field, Number.regraDeTresValor(valorTotal, valor, true));
  };

  const handleChangeTabelaPercent = (
    field,
    percentField,
    valor,
    valorTotal,
  ) => {
    handleChangeField(percentField, valor);
    handleChangeField(
      field,
      Number.regraDeTresValor(
        valorTotal,
        100 - Number.stringToFloat(valor),
        true,
      ),
    );
  };

  useEffect(() => {
    if (!modelo?.id) return;

    setModeloAnuncio({
      ...modelo,
      valorMotorista: Number.currencyFormat(modelo.valorMotorista),
      valorBonusInstalacao: Number.currencyFormat(modelo.valorBonusInstalacao),
      valorVenda: Number.currencyFormat(modelo.valorVenda),
      valorInstaladorPorInstalacao: Number.currencyFormat(
        modelo.valorInstaladorPorInstalacao,
      ),
      porcentagemInstalacao: Number.regraDeTresPorcentagem(
        modelo.valorBonusInstalacao,
        modelo.valorMotorista,
        true,
      ),
      percentualImpactos: Number.format(modelo.percentualImpactos),
      diasPeriodo: Number.format(modelo.diasPeriodo),
      tabelaEspecial: Number.format(modelo.tabelaEspecial),
      tabelaUm: Number.format(modelo.tabelaUm),
      tabelaDois: Number.format(modelo.tabelaDois),
      tabelaTres: Number.format(modelo.tabelaTres),
      valorTabelaEspecial: Number.regraDeTresValor(
        modelo.valorVenda,
        100 - modelo.tabelaEspecial,
        true,
      ),
      valorTabelaUm: Number.regraDeTresValor(
        modelo.valorVenda,
        100 - modelo.tabelaUm,
        true,
      ),
      valorTabelaDois: Number.regraDeTresValor(
        modelo.valorVenda,
        100 - modelo.tabelaDois,
        true,
      ),
      valorTabelaTres: Number.regraDeTresValor(
        modelo.valorVenda,
        100 - modelo.tabelaTres,
        true,
      ),
    });
  }, [modelo]);

  useEffect(() => {
    if (readOnly) return;

    const valorBonusInstalacao = Number.regraDeTresValor(
      modeloAnuncio.porcentagemInstalacao,
      modeloAnuncio.valorMotorista,
      true,
    );
    setModeloAnuncio((prevState) => ({
      ...prevState,
      valorBonusInstalacao,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeloAnuncio.valorMotorista]);

  useEffect(() => {
    if (readOnly) return;

    const valorTabelaEspecial = Number.regraDeTresValor(
      100 - Number.safeParseFloat(modeloAnuncio.tabelaEspecial),
      modeloAnuncio.valorVenda,
      true,
    );
    const valorTabelaUm = Number.regraDeTresValor(
      100 - Number.safeParseFloat(modeloAnuncio.tabelaUm),
      modeloAnuncio.valorVenda,
      true,
    );
    const valorTabelaDois = Number.regraDeTresValor(
      100 - Number.safeParseFloat(modeloAnuncio.tabelaDois),
      modeloAnuncio.valorVenda,
      true,
    );
    const valorTabelaTres = Number.regraDeTresValor(
      100 - Number.safeParseFloat(modeloAnuncio.tabelaTres),
      modeloAnuncio.valorVenda,
      true,
    );

    setModeloAnuncio((prevState) => ({
      ...prevState,
      valorTabelaEspecial,
      valorTabelaUm,
      valorTabelaDois,
      valorTabelaTres,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeloAnuncio.valorVenda]);

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item md={4} xs={6}>
              <InputV2
                name="nome"
                label="Titulo"
                value={modeloAnuncio.nome}
                onChange={({target}) => handleChangeField('nome', target.value)}
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <InputV2
                name="descricao"
                label="Descrição"
                value={modeloAnuncio.descricao}
                onChange={({target}) =>
                  handleChangeField('descricao', target.value)
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={2} xs={4}>
              <InputV2
                name="percentualImpactos"
                label="Porcetagem de impactos"
                mask="porcento"
                value={modeloAnuncio.percentualImpactos || 0}
                onChange={({target}) =>
                  handleChangeField('percentualImpactos', target.value)
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={2} xs={4}>
              <InputV2
                name="diasPeriodo"
                label="Tempo de veiculação"
                mask="numero"
                endAdornment="dias"
                value={modeloAnuncio.diasPeriodo || 0}
                onChange={({target}) =>
                  handleChangeField('diasPeriodo', target.value)
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={4}>
              <InputV2
                name="valorVenda"
                label="Valor de venda"
                mask="moeda"
                value={modeloAnuncio.valorVenda}
                defaultValue={0}
                onChange={({target}) =>
                  handleChangeField('valorVenda', target.value)
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={4}>
              <Box sx={styles.box}>
                <InputV2
                  label="Valor recomendado"
                  name="tabelaTres"
                  mask="porcento"
                  value={modeloAnuncio.tabelaTres}
                  defaultValue={0}
                  onChange={({target}) =>
                    handleChangeTabelaPercent(
                      'valorTabelaTres',
                      'tabelaTres',
                      target.value,
                      modeloAnuncio.valorVenda,
                    )
                  }
                  disabled={readOnly}
                />
                <InputV2
                  name="valorTabelaTres"
                  label="Valor"
                  mask="moeda"
                  value={modeloAnuncio.valorTabelaTres}
                  defaultValue={0}
                  disabled
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <InputV2
                name="valorInstaladorPorInstalacao"
                label="Valor da produção"
                mask="moeda"
                value={modeloAnuncio.valorInstaladorPorInstalacao || 0}
                onChange={({target}) =>
                  handleChangeField(
                    'valorInstaladorPorInstalacao',
                    target.value,
                  )
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item lg={3} xs={6}>
              <InputV2
                name="valorMotorista"
                label="Valor do motorista"
                mask="moeda"
                value={modeloAnuncio.valorMotorista}
                defaultValue={0}
                onChange={({target}) =>
                  handleChangeField('valorMotorista', target.value)
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item xl={4} xs={6}>
              <Box sx={styles.box}>
                <InputV2
                  label={'Bonus de instalação'}
                  name="porcentagemInstalacao"
                  mask="porcento"
                  value={modeloAnuncio.porcentagemInstalacao}
                  defaultValue={0}
                  onChange={({target}) =>
                    handleChangeFieldPercent(
                      'valorBonusInstalacao',
                      'porcentagemInstalacao',
                      target.value,
                      modeloAnuncio.valorMotorista,
                    )
                  }
                  disabled={readOnly}
                />
                <InputV2
                  label="Valor"
                  name="valorBonusInstalacao"
                  mask="moeda"
                  value={modeloAnuncio.valorBonusInstalacao}
                  defaultValue={0}
                  onChange={({target}) =>
                    handleChangeFieldValor(
                      'valorBonusInstalacao',
                      'porcentagemInstalacao',
                      target.value,
                      modeloAnuncio.valorMotorista,
                    )
                  }
                  disabled={readOnly}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.componentesContainer}>
                <Grid container spacing={1} sx={styles.checboxContainer}>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.frente}
                        label="Capô"
                        onChange={({target}) =>
                          handleChangeField('frente', target.checked)
                        }
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.laterais}
                        label="Portas Laterais"
                        onChange={({target}) =>
                          handleChangeField('laterais', target.checked)
                        }
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.lateraisRecorte}
                        label="Portas Laterais (Recorte)"
                        onChange={({target}) =>
                          handleChangeField('lateraisRecorte', target.checked)
                        }
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.traseira}
                        label="Vidro Traseiro"
                        onChange={({target}) =>
                          handleChangeField('traseira', target.checked)
                        }
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.interno}
                        label="Mídia Interna"
                        onChange={({target}) =>
                          handleChangeField('interno', target.checked)
                        }
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={styles.box}>
                      <FormControlLabel
                        control={<Checkbox color="primaryPatern" />}
                        checked={modeloAnuncio.interativo}
                        label="Interativo"
                        onChange={({target}) =>
                          handleChangeField('interativo', target.checked)
                        }
                        sx={styles.checkbox}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.box}>
                <FormControlLabel
                  control={<Checkbox color="primaryPatern" />}
                  checked={modeloAnuncio.ativo}
                  label="Ativo"
                  onChange={({target}) =>
                    handleChangeField('ativo', target.checked)
                  }
                  sx={styles.checkbox}
                  disabled={readOnly}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              style={{visibility: editarDisabled() ? 'hidden' : 'visible'}}>
              {readOnly && (
                <ButtonComponent
                  type="button"
                  sx={styles.buttonWrapper}
                  value={'Editar'}
                  icon={<Edit />}
                  onClick={() => setReadOnly(false)}
                />
              )}
              {!readOnly && (
                <ButtonComponent
                  type="submit"
                  value={'Salvar'}
                  sx={styles.buttonWrapper}
                  icon={<Save />}
                  loading={loading}
                  onClick={handleSubmitDadosModeloAnuncio}
                  disabled={loading}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};
const styles = {
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabelaPrecoContainer: {
    position: 'relative',
    borderRadius: 'px',
    margin: '10px 0 30px 0 !important',
    border: '2px solid #0083e6',
    width: '100%',
    padding: '20px',
  },
  componentesContainer: {
    borderRadius: '6px',
    border: '2px solid #0083e6',
  },
  buttonWrapper: {
    width: '256px',
  },
  checkbox: {
    '.MuiFormControlLabel-label': {
      display: 'block',
      color: '#657496',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  tabelaTitulo: {
    position: 'absolute',
    backgroundColor: '#fff',
    color: '#0083e6',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '2px',
    top: '-14px',
    left: '15px',
  },
  checboxContainer: {
    justifyContent: 'space-between',
    padding: '0 30px 0 20px',
  },
};

export default FormularioModeloAnuncio;
