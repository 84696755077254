import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Formik, Field} from 'formik';
import {useQuery, useMutation} from '@apollo/client';
import {Paper, Grid, Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination} from '@mui/material';
import {ChevronRight} from '@mui/icons-material';
import {
  FIND_BY_DISPONIBILIDADE_MOTORISTA,
  FIND_BY_ID_MOTORISTA,
  FIND_CAMPANHA_CIDADE_BY_ID,
} from '../../graphql/queries';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {SelectCidades} from '../../containers/selects/mui-cidades';
import {RoundTooltipButton, ButtonComponent} from '../../components/mui-button';
import {TROCAR_MOTORISTA_DO_ANUNCIO} from '../../graphql/mutation';
import TableCellOrdered from '../../components/table-cell-ordered';
import {SelectEstados} from '../../containers/selects/mui-estados';
import {toastNotification} from '../../components/toastify';
import {motoristaSituacao} from '../../constants/enum-labels';
import Number from '../../utils/number';
import {LoadingPage} from '../../components/loading-page';
import TrocarMotoristaModal from './modais/trocar-motorista-modal';

const columns = [
  {id: 'nome', label: 'Nome'},
  {
    id: 'veiculoAtual',
    label: 'Marca/Modelo',
    campo: 'veiculoAtual.modelo.nome',
    format: (veiculoAtual) =>
      veiculoAtual?.modelo?.marca
        ? `${veiculoAtual.modelo.marca.nome} - ${veiculoAtual.modelo.nome}`
        : '-',
  },
  {
    id: 'veiculoAtual',
    label: 'Ano',
    campo: 'veiculoAtual.anoFabricacao',
    format: (veiculoAtual) =>
      veiculoAtual?.anoFabricacao && veiculoAtual?.anoModelo
        ? `${veiculoAtual.anoFabricacao}/${veiculoAtual.anoModelo}`
        : '-',
  },
  {
    id: 'cidadeAtuacao',
    label: 'Cidade',
    campo: 'cidadeAtuacao.nome',
    format: (cidade) => (cidade ? `${cidade?.nome}/${cidade?.uf}` : '-'),
  },
  {
    id: 'veiculoAtual',
    label: 'Pintura Original?',
    campo: 'veiculoAtual.pinturaOriginal',
    format: (veiculoAtual) => {
      if (veiculoAtual?.pinturaOriginal) return 'Sim';

      if (veiculoAtual?.pinturaOriginal === false) return 'Não';

      return '-';
    },
  },
  {
    id: 'notaAvaliacao',
    label: 'Nota',
    format: (notaAvaliacao) =>
      notaAvaliacao ? parseFloat(notaAvaliacao).toFixed(1) : ' - ',
  },
  {
    id: 'situacao',
    label: 'Cadastro',
    format: (situacao) => motoristaSituacao[situacao],
  },
];

const TrocarMotoristaCampanha = ({pageSize = 10}) => {
  const [filtros, setFiltros] = useState({search: ''});
  const [searchDTO, setSearchDTO] = useState({});
  const [confirmando, setConfirmando] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(pageSize);
  const [campanha, setCampanha] = useState({});
  const [motoristas, setMotoristas] = useState([]);
  const [totalMotoristas, setTotalMotoristas] = useState(0);
  const [motorista, setMotorista] = useState({});
  const [orderBy, setOrderBy] = useState({campo: 'notaAvaliacao', asc: false});
  const [selecionarMotorista, setSelecionarMotorista] = useState('');
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [valorMensalMotorista, setValorMensalMotorista] = useState({
    substituido: '',
    substituto: '',
  });

  const [trocarMotoristaDoAnuncio] = useMutation(TROCAR_MOTORISTA_DO_ANUNCIO);

  const {motoristaId, campanhaId} = useParams();
  const navigate = useNavigate();

  const motoristaQuery = useQuery(FIND_BY_ID_MOTORISTA, {
    variables: {
      id: motoristaId,
    },
  });

  const campanhaQuery = useQuery(FIND_CAMPANHA_CIDADE_BY_ID, {
    variables: {
      id: campanhaId,
    },
  });

  const motoristasQuery = useQuery(FIND_BY_DISPONIBILIDADE_MOTORISTA, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO,
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const refetch = () => {
    const newPage = 0;
    setPage(newPage);
    setSearchDTO({
      search: filtros.search,
      estadoId: filtros.estado?.value,
      cidadeAtuacaoId: filtros.cidade?.value
    });
  };

  const handleClickConfirmar = (motoristaDestino) => {
    if (confirmando) return;

    setConfirmando(true);
    trocarMotoristaDoAnuncio({
      variables: {
        motoristaDestino: motoristaDestino.id,
        motoristaOrigem: motorista.id,
        valorDestino: Number.stringToFloat(valorMensalMotorista.substituto),
        valorOrigem: Number.stringToFloat(valorMensalMotorista.substituido),
      },
    })
      .then(() => navigate(`/campanhas/${campanha.campanha.id}/editar`))
      .catch((error) => {
        setConfirmando(false);
        toastNotification({message: error.message, type: 'error'});
      });
  };

  useEffect(() => {
    if (campanhaQuery.loading) return;

    const campanhaCidade = campanhaQuery.data?.campanhaCidade;
    setCampanha(campanhaCidade);
    setFiltros(f => ({...f, cidade: campanhaCidade.cidade}));
    setSearchDTO(s => ({...s, cidadeAtuacaoId: campanhaCidade.cidade.id}));
  }, [campanhaQuery]);

  useEffect(() => {
    if (motoristaQuery.loading) return;

    setMotorista(motoristaQuery.data?.findByIdMotorista);
  }, [motoristaQuery]);

  useEffect(() => {
    if (motoristasQuery.loading) return;

    setMotoristas(motoristasQuery.data?.motoristas?.content || []);
    setTotalMotoristas(motoristasQuery.data?.motoristas?.totalElements || 0);
  }, [motoristasQuery]);

  if (motoristaQuery.loading || motoristaQuery.error) {
    return (
      <LoadingPage
        loading={motoristaQuery.loading}
        error={motoristaQuery.error}
      />
    );
  }

  if (campanhaQuery.loading || campanhaQuery.error) {
    return (
      <LoadingPage
        loading={campanhaQuery.loading}
        error={campanhaQuery.error}
      />
    );
  }

  const handleClickSelecionarMotorista = (motoristaDestino) => {
    setSelecionarMotorista(motoristaDestino);
    setOpenConfirmAlert(true);
    setValorMensalMotorista(() => ({
      substituido: '',
      substituto: motorista?.anuncioAtivo?.campanha?.valorMensalMotorista,
    }));
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputV2
                  label="Filtrar por Nome"
                  value={filtros.search}
                  onChange={(e) =>
                    setFiltros({...filtros, search: e.target?.value})
                  }
                  className="input-filtro"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectEstados}
                  label="Estado de Atuação"
                  name="estado"
                  value={filtros.estado}
                  className="select"
                  isClearable
                  onChange={(e) =>
                    setFiltros({...filtros, estado: e})
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectCidades}
                  label="Cidade de Atuação"
                  name="cidade"
                  value={filtros.cidade}
                  className="select"
                  isClearable
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      cidade: e,
                    })
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  id="button"
                  loading={motoristasQuery.loading}
                  value="Filtrar"
                  onClick={() => refetch()}
                  style={{minWidth: 0}}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box sx={styles.motoristaAtualContainer}>
              <Typography sx={styles.campanhaTitulo}>
                {`Campanha: ${campanha.titulo}`}
              </Typography>
              <Typography sx={styles.motoristaAtual}>
                Motorista atual: {motorista.nome}
              </Typography>
            </Box>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCellOrdered
                        key={index}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                    <TableCell id="acoes" key="acoes" align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {motoristas.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={index} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <RoundTooltipButton
                            id="icon-primary"
                            title="Selecionar"
                            onClick={() => handleClickSelecionarMotorista(row)}
                            icon={<ChevronRight />}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={totalMotoristas}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count}`
              }
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <TrocarMotoristaModal
        open={openConfirmAlert}
        motoristaOriginal={motorista.nome}
        novoMotorista={selecionarMotorista.nome}
        onClose={() => setOpenConfirmAlert(false)}
        onConfirm={() => handleClickConfirmar(selecionarMotorista)}
        onCancel={() => setOpenConfirmAlert(false)}
        valorMensalMotoristaAtual={valorMensalMotorista.substituido}
        valorMensalMotoristaNovo={valorMensalMotorista.substituto}
        setValorMensalMotorista={setValorMensalMotorista}
      />
    </Grid>
  );
};

const styles = {
  motoristaAtualContainer: {
    padding: '10px 15px',
    background: 'rgba(0,0,0,0.1)',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    justifyContent: 'space-between'
  },
  campanhaTitulo: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#0A2549',
  },
  motoristaAtual: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#0A2549',
  },
};

export default TrocarMotoristaCampanha;
