import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  anuncios: [],
  hasMore: false,
  totalAnuncios: 0,
  refetchAnuncios: false,
};

const acaoAtualizarAnunciosDaCampanha = state => {
  state.refetchAnuncios = true;
};

const acaoPersistAnunciosDaCampanha = (state, action) => {
  const {content, last, totalElements} = action.payload || {};

  state.anuncios = content || [];
  state.hasMore = !last;
  state.totalAnuncios = totalElements || 0;
};

const resumeSlice = createSlice({
  name: 'anuncioCampanha',
  initialState: initialState,
  reducers: {
    persistAnunciosDaCampanha: acaoPersistAnunciosDaCampanha,
    atualizarAnunciosDaCampanha: acaoAtualizarAnunciosDaCampanha,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistAnunciosDaCampanha,
  atualizarAnunciosDaCampanha,
} = actions;
