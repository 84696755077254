import React, {useState} from 'react';
import {Grid} from '@mui/material';
import InputV2 from '../../../components/input-v2/mui-input-v2';

const DadosBancarios = ({motorista = {}, alteracoes}) => {
  const [dadoBancario] = useState(motorista.dadoBancario || {});

  const isAtualizado = (campo, entity, campoEntity) => {
    const alterado = alteracoes
      ?.map((a) => a[campo] !== entity[campoEntity || campo])
      ?.reduce((acc, atual) => acc || atual, false);
    return alterado ? {borderColor: '#00398E'} : null;
  };

  const isIdAtualizado = (campo, entity, campoEntity) => {
    const alterado = alteracoes
      ?.map(
        (a) => a[campo]?.id && a[campo].id !== entity[campoEntity || campo].id,
      )
      ?.reduce((acc, atual) => acc || atual, false);
    return alterado ? {borderColor: '#00398E'} : null;
  };

  return (
    <Grid container mt="16px" spacing={2}>
      <Grid item xs={12} md={3}>
        <InputV2
          label="Banco"
          value={
            dadoBancario.banco
              ? `${dadoBancario.banco.codigoBanco} - ${dadoBancario.banco.nomeBanco}`
              : ''
          }
          style={isIdAtualizado('banco', dadoBancario)}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <InputV2
          label="Agência"
          value={dadoBancario?.agencia}
          style={isAtualizado('agencia', dadoBancario)}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <InputV2
          label="Tipo de conta"
          value={dadoBancario?.tipoContaBancaria}
          style={isAtualizado('tipoContaBancaria', dadoBancario)}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <InputV2
          label="Conta"
          value={dadoBancario?.conta}
          style={isAtualizado('conta', dadoBancario)}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputV2
          label="Chave PIX"
          value={dadoBancario?.chavePix}
          style={isAtualizado('chavePix', dadoBancario)}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputV2
          label="CPF/CNPJ do favorecido"
          value={dadoBancario?.cpfCnpjFavorecido}
          mask={
            dadoBancario &&
            dadoBancario.cpfCnpjFavorecido &&
            dadoBancario.cpfCnpjFavorecido.length > 11
              ? 'cnpj'
              : 'cpf'
          }
          style={isAtualizado('cpfCnpjFavorecido', dadoBancario)}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputV2
          label="Nome do favorecido"
          value={dadoBancario?.nomeFavorecido}
          style={isAtualizado('nomeFavorecido', dadoBancario)}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputV2
          label="Cidade"
          value={dadoBancario.cidade ? dadoBancario.cidade.nome : ''}
          style={isIdAtualizado('dadoBancarioCidade', dadoBancario, 'cidade')}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <InputV2
          label="Estado"
          value={dadoBancario.cidade ? dadoBancario.cidade.uf : ''}
          style={isIdAtualizado('dadoBancarioEstado', dadoBancario, 'estado')}
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default DadosBancarios;
