import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaEstados from './consulta';
import EditarEstado from './editar';

const Estados = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaEstados />} />
      <Route path={`/:estadoId/editar`} element={<EditarEstado />} />
    </Routes>
  );
};

export default Estados;
