import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaOficinas from './consulta';
import CadastrarOficina from './cadastrar';
import EditarOficina from './editar';


const Agencia = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaOficinas />} />
      <Route path={`/criar`} element={<CadastrarOficina />} />
      <Route path={`/:oficinaId/editar`} element={<EditarOficina />} />
    </Routes>
  );
};

export default Agencia;