import { useQuery } from '@apollo/client';
import { keyframes } from '@emotion/react';
import { Paid } from '@mui/icons-material';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonComponent } from '../../components/mui-button';
import TableCellOrdered from '../../components/table-cell-ordered';
import { meses } from '../../constants/meses';
import { PROPOSTAS_ROUTE } from '../../constants/routes';
import {
  CAMPANHAS_ANUNCIANTE_POR_MES_DO_ANO,
  CIDADES_CAMPANHAS_ANUNCIANTE,
  MODELO_ANUNCIO_CAMPANHAS_ANUNCIANTE,
} from '../../graphql/queries';
import { persistIndexTabAnunciante } from '../../redux/Anunciante/slice';
import { persistFiltrosProposta } from '../../redux/Proposta/slice';

const getGraficoAnimation = keyframes`
   0% {
    height: 0px;
  }
  
`;
const getAnimationText = keyframes`
   0% {
    bottom: 0px;
  }
 
`;

const columnsTabelaCidade = [
  {
    id: 'nome',
    label: 'Cidade',
    campo: 'nome',
    format: (cidade) => (cidade ? `${cidade.nome}/${cidade.uf}` : ' - '),
  },
  {
    id: 'campanhas',
    label: 'Campanhas',
  },
];
const columnsTabelaModeloAnuncio = [
  {
    id: 'nome',
    label: 'Custos modelo de anúncio',
    campo: 'nome',
  },
  {
    id: 'campanhas',
    label: 'Campanhas',
  },
];

const FormularioHistoricoCompras = ({anunciante = {}, refetch}) => {
  const [orderBy, setOrderBy] = useState('nome');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {filtrosProposta} = useSelector((store) => store.Proposta);

  const handleClickHistoryProposta = () => {
    dispatch(persistIndexTabAnunciante(2));
    dispatch(
      persistFiltrosProposta({
        ...filtrosProposta,
        filtros: {
          ...filtrosProposta.filtros,
          anunciante: {
            value: anunciante.id,
            label: anunciante.nomeFantasia,
          },
          periodo: {label: 'Todas'},
        },
      }),
    );
    navigate(PROPOSTAS_ROUTE);
  };

  const cidadeCampanhaQuery = useQuery(CIDADES_CAMPANHAS_ANUNCIANTE, {
    variables: {
      anunciante: {
        id: anunciante.id,
      },
    },
  });
  const modeloAnuncioCampanhaQuery = useQuery(
    MODELO_ANUNCIO_CAMPANHAS_ANUNCIANTE,
    {
      variables: {
        anunciante: {
          id: anunciante.id,
        },
      },
    },
  );
  const campanhaMesQuery = useQuery(CAMPANHAS_ANUNCIANTE_POR_MES_DO_ANO, {
    variables: {
      anunciante: {
        id: anunciante.id,
      },
    },
  });

  const {data = {}} = cidadeCampanhaQuery;
  const {cidadesCampanhasAnunciante = []} = data;
  const dataModelo = modeloAnuncioCampanhaQuery?.data;
  const {modeloAnuncioCampanhasAnunciante = []} = {...dataModelo};
  const dataCampanhasMes = campanhaMesQuery?.data;
  const {campanhasAnunciantePorMesDoAno = []} = {...dataCampanhasMes};

  const totalCidades = cidadesCampanhasAnunciante.reduce((previous, item) => {
    return previous + item.campanhas;
  }, 0);
  const totalModeloAnuncio = modeloAnuncioCampanhasAnunciante.reduce(
    (previous, item) => {
      return previous + item.campanhas;
    },
    0,
  );
  const totalCampanhaMes = campanhasAnunciantePorMesDoAno.reduce(
    (previous, item) => {
      return previous + item.count;
    },
    0,
  );

  const columnsGrafico = [];

  meses.forEach((item, index) => {
    const mesDoDado = campanhasAnunciantePorMesDoAno.filter(
      (item) => item.mes === index + 1,
    );

    columnsGrafico.push({
      title: meses[index].label,
      value: mesDoDado?.[0]?.count || 0,
    });
  });

  // Grafico utilizado na view
  const Grafico = ({total, columns = []}) => {
    return (
      <Stack sx={styles.containerExterno} direction="row">
        {columns.map((item, index) => (
          <Box key={index} sx={styles.columnContainer}>
            <Box sx={styles.columnSpace}>
              {item.value ? (
                <Box
                  sx={styles.textAnimation}
                  bottom={`${(item.value / total) * 100}% `}>
                  <Typography sx={styles.text}>{item.value}</Typography>
                </Box>
              ) : null}
              {item.value ? (
                <Box
                  sx={styles.columnGrafico}
                  height={`${(item.value / total) * 100}% `}
                />
              ) : null}
            </Box>
            <Typography sx={styles.text} marginTop="12px">
              {item?.title}
            </Typography>
          </Box>
        ))}
      </Stack>
    );
  };

  //container que será utilizado por mais de 1 vez em um componente pai
  const BoxFormularioHistoricoCompras = ({
    children,
    title,
    pageSize = 20,
    height = '500px',
  }) => {
    return (
      <Paper
        sx={(theme) => ({
          width: '100%',
          height: height,
          ...theme.styledScroll,
          overflowY: 'auto',
          marginTop: '0px !important',
        })}>
        <Stack padding="20px" height="100%">
          <Typography variant="h4" id="formulario-subtitle">
            {title}
          </Typography>
          {children}
        </Stack>
      </Paper>
    );
  };

  //Tabela que será utilizado por mais de 1 vez em um componente pai
  const TableComponent = ({arrayDados = [], columns = [], total}) => {
    return (
      <Table stickyHeader aria-label="sticky table">
        <TableHead tabIndex={-1}>
          <TableRow>
            {columns.map((column) => (
              <TableCellOrdered
                key={column.id}
                column={column}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {arrayDados &&
            arrayDados.map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column, index) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={index} align={column.align}>
                        {column.format ? column.format(row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
        <TableFooter id="footer-text">
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell>{total}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    );
  };
  return (
    <Stack spacing={8} direction="column">
      <ButtonComponent
        icon={<Paid />}
        value={'Acessar funil de vendas'}
        toolbar="true"
        id="button-gray-blue"
        sx={{width: '250px'}}
        onClick={handleClickHistoryProposta}></ButtonComponent>
      <BoxFormularioHistoricoCompras
        title={'Campanhas por mês'}
        height={'300px'}>
        <Grafico total={totalCampanhaMes} columns={columnsGrafico} />
      </BoxFormularioHistoricoCompras>
      <Stack direction="row" spacing={2}>
        <BoxFormularioHistoricoCompras title={'Campanhas por cidade'}>
          <TableComponent
            columns={columnsTabelaCidade}
            arrayDados={cidadesCampanhasAnunciante}
            total={totalCidades}
          />
        </BoxFormularioHistoricoCompras>
        <BoxFormularioHistoricoCompras
          title={'Campanhas por custos modelo de anúncio'}>
          <TableComponent
            columns={columnsTabelaModeloAnuncio}
            arrayDados={modeloAnuncioCampanhasAnunciante}
            total={totalModeloAnuncio}
          />
        </BoxFormularioHistoricoCompras>
      </Stack>
    </Stack>
  );
};

const styles = {
  containerExterno: {
    width: '100%',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'flex-end',
  },
  columnGrafico: {
    backgroundImage: 'linear-gradient(#0073cd, #0091e5)',
    borderRadius: '8px',
    width: '30px',
    animation: `${getGraficoAnimation} .6s ease-in-out`,
  },
  columnSpace: {
    backgroundColor: '#f7f7f7',
    borderRadius: '8px',
    width: '30px',
    position: 'relative',
    height: '80%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  textAnimation: {
    animation: `${getAnimationText} .6s ease-in-out`,
    width: '30px',
    position: 'absolute',
  },
  columnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '80px',
    flexDirection: 'column',
    height: '100%',
  },
  text: {
    textAlign: 'center',
    fontFamily: 'fontFamily.pattern',
    fontSize: '12px',
    color: '#898989',
  },
};

export default FormularioHistoricoCompras;
