import React, {useState} from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Collapse,
  TableHead,
  Box,
} from '@mui/material';
import {DeleteOutline, SaveOutlined} from '@mui/icons-material';
import {useMutation} from '@apollo/client';
import {
  ExpandMore,
  ExpandLess,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {RoundTooltipButton} from '../../components/mui-button';
import {
  SAVE_CIDADE_PONTO_INTERESSE,
  DELETE_CIDADE_PONTO_INTERESSE,
} from '../../graphql/mutation';
import {toastNotification} from '../../components/toastify';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';

const PontosInteresse = ({
  cidade,
  novoPonto,
  setNovoPonto,
  refetch,
  cidadeInteresse,
  tipo,
  setInvisiblePoints,
  invisiblePoints,
  setShowPoints,
  showPoints,
}) => {
  const [salvando, setSalvando] = useState(false);
  const [visible, setVisible] = useState(false);
  const [savePonto] = useMutation(SAVE_CIDADE_PONTO_INTERESSE);
  const [deletePonto] = useMutation(DELETE_CIDADE_PONTO_INTERESSE);

  const handleSavePonto = () => {
    if (!novoPonto.latitude || !novoPonto.longitude || !novoPonto.kmRaio)
      return;

    setSalvando(true);

    savePonto({
      variables: {
        cidade: {
          id: cidade.id,
        },
        pontoInteresse: {
          nome: novoPonto.nome,
          latitude: parseFloat(novoPonto.latitude),
          longitude: parseFloat(novoPonto.longitude),
          kmRaio: parseFloat(novoPonto.kmRaio),
          tipo,
        },
      },
    })
      .then(() => {
        setNovoPonto({});
        refetch && refetch();
        toastNotification({
          message: 'Os pontos de interesse foram salvos!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSalvando(false));
  };
  const handleDeletePonto = (pontoInteresse) => {
    if (!pontoInteresse?.id) return;

    delete pontoInteresse.__typename;

    deletePonto({
      variables: {
        cidade: {
          id: cidade.id,
        },
        pontoInteresse,
      },
    })
      .then(() => {
        refetch && refetch();
        toastNotification({
          message: 'Os pontos de interesse foram deletados!',
          type: 'success',
        });
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleMapDelete = (ponto) => {
    const pontoDel = [...invisiblePoints];

    var repeat = false;
    pontoDel.forEach((item) => {
      if (item.id === ponto.id) {
        repeat = true;
        pontoDel.splice(pontoDel.indexOf(item), 1);
      }
    });
    repeat
      ? setInvisiblePoints(pontoDel)
      : setInvisiblePoints([ponto, ...pontoDel]);

    if (showPoints === true) {
      setShowPoints(false);
    }
  };
  const handleMap = () => {
    if (showPoints) {
      setShowPoints(false);
      setInvisiblePoints([]);
    } else {
      setShowPoints(true);
      setInvisiblePoints([...cidadeInteresse]);
    }
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.table}>
        <Box sx={styles.collapseMenu}>
          {showPoints ? (
            <VisibilityOff sx={styles.visibilityOf} onClick={handleMap} />
          ) : (
            <Visibility sx={styles.visibility} onClick={handleMap} />
          )}

          <Box
            sx={styles.textButtons}
            onClick={() => (visible ? setVisible(false) : setVisible(true))}>
            <Typography sx={styles.label}>
              {tipo === 'REGIAO'
                ? 'Região de interesse'
                : 'Pontos de interesse'}
            </Typography>
            {visible ? (
              <ExpandLess sx={styles.arrow} />
            ) : (
              <ExpandMore sx={styles.arrow} />
            )}
          </Box>
        </Box>

        <Collapse in={visible}>
          <Box sx={styles.lista} className="scroll">
            <Table>
              <TableHead>
                <TableRow key="input" sx={styles.itemContainer}>
                  <TableCell sx={styles.inputCell}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <input
                          value={novoPonto.nome || ''}
                          placeholder="Descrição"
                          onChange={({target}) =>
                            setNovoPonto((p) => ({...p, nome: target.value}))
                          }
                          style={styles.input}
                        />
                      </Grid>
                      <Grid item xs={2} sx={styles.inputContainer}>
                        <input
                          value={novoPonto.latitude || ''}
                          placeholder="Latitude"
                          onChange={({target}) =>
                            setNovoPonto((p) => ({
                              ...p,
                              latitude: target.value,
                            }))
                          }
                          style={styles.input}
                        />
                      </Grid>
                      <Grid item xs={2} sx={styles.inputContainer}>
                        <input
                          value={novoPonto.longitude || ''}
                          placeholder="Longitude"
                          onChange={({target}) =>
                            setNovoPonto((p) => ({
                              ...p,
                              longitude: target.value,
                            }))
                          }
                          style={styles.input}
                        />
                      </Grid>
                      <Grid item xs={2} sx={styles.inputContainer}>
                        <input
                          value={novoPonto.kmRaio || ''}
                          placeholder="Raio"
                          onChange={({target}) =>
                            setNovoPonto((p) => ({...p, kmRaio: target.value}))
                          }
                          style={styles.input}
                        />
                      </Grid>
                    </Grid>
                    <RoundTooltipButton
                      type="button"
                      id="icon-primary"
                      title={'Salvar'}
                      icon={<SaveOutlined sx={styles.icon} />}
                      sx={styles.actionButton}
                      onClick={handleSavePonto}
                      disabled={salvando}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{...styles.itemLista, ...styles.tableBody}}>
                {cidadeInteresse?.map((ponto) => {
                  var active;
                  for (var c = 0; c <= invisiblePoints.length - 1; c++) {
                    if (ponto.id === invisiblePoints[c].id) {
                      active = true;
                      break;
                    }
                  }

                  return (
                    <TableRow
                      key={ponto.id}
                      className={`tableRow`}
                      sx={styles.itemTable}>
                      <TableCell key="nome" sx={styles.itemDescricao}>
                        {active ? (
                          <VisibilityOff
                            sx={styles.visibilityOf}
                            onClick={() => handleMapDelete(ponto)}
                          />
                        ) : (
                          <Visibility
                            sx={styles.visibility}
                            onClick={() => handleMapDelete(ponto)}
                          />
                        )}
                        {ponto.nome}
                      </TableCell>
                      <TableCell key="latitude" sx={styles.item}>
                        {ponto.latitude}
                      </TableCell>
                      <TableCell key="longitude" sx={styles.item}>
                        {ponto.longitude}
                      </TableCell>
                      <TableCell key="kmRaio" sx={styles.item}>
                        {ponto.kmRaio}
                      </TableCell>
                      <TableCell key="acoes" sx={styles.itemButton}>
                        <DeleteButton
                          onConfirm={() => handleDeletePonto(ponto)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

const DeleteButton = ({onConfirm}) => {
  const handleConfirmAlert = () => {
    confirmAlertMui({
      message: 'Deseja excluir?',
      onConfirm: onConfirm,
    });
  };

  return (
    <>
      <RoundTooltipButton
        type="button"
        title={'Excluir'}
        id="icon-cancel"
        icon={<DeleteOutline />}
        sx={styles.actionButton}
        onClick={handleConfirmAlert}
      />
    </>
  );
};

const styles = {
  container: {
    padding: '10px',
    '& .scroll': (theme) => theme.styledScroll,
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    color: '#657496',
    fontSize: '14px',
    marginBottom: '3px',
    marginRight: '10px',
  },
  table: {
    background: '#F3F3F3',
    borderRadius: '5px',
  },
  lista: {
    borderRadius: '6px',
    padding: '0 10px 10px',
    overflowY: 'auto',
    maxHeight: '340px',
  },
  tableBody: {
    '& tr:last-child': {
      border: '0',
      borderRadius: '5px',
    },
  },
  itemButton: {
    padding: '11px ',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemLista: {
    alignItems: 'center',

    margin: '0px 0px 10px 0px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #b5b5b5',
    background: 'none !important',

    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  itemTable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #b5b5b5',
    background: '#fff !important',

    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  itemHorario: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  itemDescricao: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0 16px 11px',
  },
  item: {
    width: '32%',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0 16px 35px',
  },
  excluirButton: {
    height: 30,
    width: 30,
    minWidth: 30,
    marginLeft: 10,
    backgroundColor: 'red',
  },

  inputContainer: {
    paddingLeft: 8,
  },
  input: {
    lineHeight: 1.43,
    fontSize: '0.875rem',
    border: 'none',
    background: '#fffffffa',
    width: '100%',
    height: 33,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 6,
    paddingLeft: 10,
  },
  actionButton: {
    height: '30px',
    width: '30px',
    minWidth: '30px',
    margin: '0 15px 0 10px',
    transform: 'scale(0.8)',
  },
  textButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: 0,
  },
  excluirModalContainer: {
    background: '#fff8',
    padding: 10,
    borderRadius: 14,
    marginRight: -21,
    zIndex: 3,
  },
  excluirModalLabel: {
    color: '#000',
    fontWeight: 'bold',
    marginBottom: 5,
  },
  excluirModalButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  confirmarExcluir: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 0,
    right: 40,
  },
  arrow: {
    color: '#999',
  },
  exluirModalArrow: {
    color: '#fff',
    height: 50,
    width: 50,
    marginTop: 22,
    zIndex: 2,
  },

  cancelCircleRounded: {
    cursor: 'pointer',
    backgroundColor: 'red',
  },
  checkCircleRounded: {
    cursor: 'pointer',
    backgroundColor: 'green',
  },
  inputCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
  },
  collapseMenu: {
    display: 'flex',
    flexDirection: ' row',
    justifyContent: 'space-between',
    padding: '10px',
  },
  visibility: {
    color: '#0083e6',
    marginRight: '10px',
  },
  visibilityOf: {
    color: '#AED8F7',
    marginRight: '10px',
  },
};

export default PontosInteresse;
