import React, {useEffect} from 'react';
import useGoogleMap from './useGoogleMap';

export default function Map({
  center,
  zoom,
  children,
  mapTypeId,
  events,
  locations = [],
  options,
  onMapClick
}) {
  const {maps, map, mapRef, loading} = useGoogleMap({
    zoom,
    center,
    events,
    mapTypeId,
    options
  });

  useEffect(() => {
    map && center && map.panTo(center);
    map && zoom && map.setZoom(zoom);

    map && onMapClick && map.addListener("click", onMapClick);


    if (map && locations instanceof Array && locations.length) {
      const bounds = new maps.LatLngBounds();
      //  Go through each...
      for (let i = 0, LtLgLen = locations.length; i < LtLgLen; i++) {
        //  And increase the bounds to take this point
        bounds.extend(
          new maps.LatLng(locations[i].latitude, locations[i].longitude),
        );
      }
      //  Fit these bounds to the map
      map.fitBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center.lat, center.lng, zoom, map]);

  return (
    <div className="map-container">
      <div ref={mapRef} className="map-ref" />
      {!loading &&
        React.Children.map(children, child => {
          return React.cloneElement(child, {map, maps});
        })}
    </div>
  );
}
