import React, {useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import {Grid, Modal, Typography, Box} from '@mui/material';
import moment from 'moment';
import {FIND_VALOR_ODOMETRO_ANTERIOR_DATA} from '../../../graphql/queries';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import Number from '../../../utils/number';
import {AJUSTAR_KM_PERIODO_MOTORISTA} from '../../../graphql/mutation';
import {toastNotification} from '../../../components/toastify';
import {ButtonComponent} from '../../../components/mui-button';
import {confirmAlertMui} from '../../../components/confirm-alert/mui-confirm-alert';

const AjustarKmModal = ({openModal, anuncio, onClose}) => {
  const [loading, setLoading] = useState(false);
  const [valorAnterior, setValorAnterior] = useState(undefined);
  const [valorOdometro, setValorOdometro] = useState(undefined);

  const [loadOdometroAnterior, valorAnteriorQuery] = useLazyQuery(
    FIND_VALOR_ODOMETRO_ANTERIOR_DATA,
  );
  const [ajustarKmPeriodo] = useMutation(AJUSTAR_KM_PERIODO_MOTORISTA);

  useEffect(() => {
    setValorOdometro(undefined);

    if (openModal && anuncio?.id) {
      loadOdometroAnterior({
        variables: {
          anuncio: {
            id: anuncio.id,
          },
          data: moment().format('YYYY-MM-DD'),
        },
      });
    }
  }, [openModal, anuncio, loadOdometroAnterior]);

  useEffect(() => {
    if (valorAnteriorQuery.loading) return;

    setValorAnterior(valorAnteriorQuery.data?.valor || 0);
  }, [valorAnteriorQuery]);

  const handleAjustarKmPeriodo = () => {
    const kmCalc =
      Number.stringToFloat(valorOdometro) - Number.stringToFloat(valorAnterior);

    confirmAlertMui({
      title: 'O valor está correto?',
      message: `Total no período ${Number.format(kmCalc)} km (de ${
        anuncio.kmTotalPorMes
      } km)`,
      onConfirm: ajutarKM,
    });
  };

  const ajutarKM = () => {
    setLoading(true);
    ajustarKmPeriodo({
      variables: {
        anuncio: {
          id: anuncio.id,
        },
        valorOdometro,
      },
    })
      .then(() => {
        toastNotification({message: 'O valor foi ajustado!', type: 'success'});
        onClose && onClose();
        
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setLoading(false));
  };

  return (
    <Box>
      <Modal
        open={openModal}
        onClose={() => onClose()}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        sx={styles.modal}>
        <Box sx={styles.modalBody}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography sx={styles.modalTitle}>
                Motorista: {anuncio?.motorista?.nome}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={styles.modalSubtitle}>
                Informe o valor do odômetro:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputV2
                value={valorOdometro}
                onChange={({target}) => setValorOdometro(target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={styles.valorAnterior}>
                Odômetro da última imagem aprovada:
                {Number.format(valorAnterior)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.box}>
                <ButtonComponent
                  value="Cancelar"
                  onClick={onClose}
                  disabled={loading}
                  id="button-cancel"
                  sx={{width: '185px', marginRight: '15px'}}
                />
                <ButtonComponent
                  id="button"
                  value="Enviar"
                  loading={loading}
                  onClick={handleAjustarKmPeriodo}
                  disabled={loading}
                  sx={{width: '185px'}}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      
    </Box>
  );
};
const styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '999 !important',
  },
  modalBody: {
    padding: '20px',
    background: '#fff',
    borderRadius: '5px',
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'default',
  },
  modalTitle: {
    fontWeight: '700',
    fontSize: 13,
    color: '#afafaf',
  },
  valorAnterior: {
    fontWeight: '500',
    fontSize: 13,
    color: '#000000de',
  },
  modalSubtitle: {
    fontWeight: '500',
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '5px',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
  },
};

export default AjustarKmModal;
