import {
  AccountBalanceWalletRounded,
  BuildRounded,
  CampaignRounded,
  DirectionsCarFilledRounded,
  FeedRounded,
  FlagRounded,
  HomeRounded,
  Inventory2Rounded,
  NearMeRounded,
} from '@mui/icons-material';
import React from 'react';
import {
  ADMINISTRADOR_ROUTE,
  ANUNCIANTES_ROUTE,
  CAMPANHAS_ROUTE,
  CIDADES_ROUTE,
  COMERCIAL_ROUTE,
  CONFIGURACOES_ROUTE,
  DASHBOARD_ROUTE,
  EXTRATO_REPASSE_ROUTE,
  FALE_CONOSCO_ROUTE,
  IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE,
  INSTALADOR_ROUTE,
  LICENCAS_ROUTE,
  MARCA_VEICULO_ROUTE,
  MATERIAIS_VENDA_ROUTE,
  MODELO_ANUNCIOS_ROUTE,
  MODELO_VEICULO_ROUTE,
  MOTORISTAS_ROUTE,
  NOTIFICAR_MOTORISTAS_ROUTE,
  OFICINAS_ROUTE,
  PROPOSTAS_ROUTE,
  RELATORIOS_ROUTE,
  SEGMENTO_ATUACAO_ROUTE,
  TABELA_PRECO_ROUTE,
} from './routes';

const styles = {
  campanhaIcon: {
    width: '30px',
    height: '30px',
    marginRight: '-6px',
  },
};

const administrador = [
  {
    title: 'Home',
    route: DASHBOARD_ROUTE,
    icon: <HomeRounded />,
  },
  {
    title: 'Painéis',
    icon: <NearMeRounded />,
    options: [
      {
        title: 'Anunciantes',
        route: ANUNCIANTES_ROUTE,
      },
      {
        title: 'Licenças',
        route: LICENCAS_ROUTE,
      },
      {
        title: 'Representantes',
        route: COMERCIAL_ROUTE,
      },
    ],
  },
  {
    title: 'Vendas',
    icon: <CampaignRounded sx={styles.campanhaIcon} />,
    options: [
      {
        title: 'Campanhas',
        route: CAMPANHAS_ROUTE,
      },
      {
        title: 'Funil de vendas',
        route: PROPOSTAS_ROUTE,
      },
      {
        title: 'Tabela de preços',
        route: TABELA_PRECO_ROUTE,
      },
    ],
  },
  {
    title: 'Relatórios',
    route: RELATORIOS_ROUTE,
    icon: <FeedRounded />,
  },
  {
    title: 'Cadastros',
    icon: <FlagRounded />,
    options: [
      {
        title: 'Cidades',
        route: CIDADES_ROUTE,
      },
      {
        title: 'Instaladores',
        route: INSTALADOR_ROUTE,
      },
      {
        title: 'Marcas de veículos',
        route: MARCA_VEICULO_ROUTE,
      },
      {
        title: 'Custos modelo de anúncio',
        route: MODELO_ANUNCIOS_ROUTE,
      },
      {
        title: 'Modelos de veículos',
        route: MODELO_VEICULO_ROUTE,
      },
      {
        title: 'Motoristas',
        route: MOTORISTAS_ROUTE,
      },
      {
        title: 'Oficinas',
        route: OFICINAS_ROUTE,
      },
      {
        title: 'Segmentos',
        route: SEGMENTO_ATUACAO_ROUTE,
      },
    ],
  },
  {
    title: 'Configurações',
    icon: <BuildRounded />,
    options: [
      {
        title: 'Contatos',
        route: FALE_CONOSCO_ROUTE,
      },
      {
        title: 'Usuários',
        route: ADMINISTRADOR_ROUTE,
      },
      {
        title: 'Imagens de validação',
        route: IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE,
      },
      {
        title: 'Impostos',
        route: CONFIGURACOES_ROUTE,
      },
      {
        title: 'Materiais de venda',
        route: MATERIAIS_VENDA_ROUTE,
      },
      {
        title: 'Notificar motoristas',
        route: NOTIFICAR_MOTORISTAS_ROUTE,
      },
    ],
  },
  {
    title: 'Movimentações financeiras',
    route: EXTRATO_REPASSE_ROUTE,
    icon: <AccountBalanceWalletRounded />,
  },
];

const opercaional = [
  {
    title: 'Home',
    route: DASHBOARD_ROUTE,
    icon: <HomeRounded />,
  },
  {
    title: 'Campanhas',
    icon: <CampaignRounded sx={styles.campanhaIcon} />,
    route: CAMPANHAS_ROUTE,
  },
  {
    title: 'Operações',
    icon: <BuildRounded />,
    options: [
      {
        title: 'Contatos',
        route: FALE_CONOSCO_ROUTE,
      },
      {
        title: 'Imagens de validação',
        route: IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE,
      },
      {
        title: 'Notificar motoristas',
        route: NOTIFICAR_MOTORISTAS_ROUTE,
      },
    ],
  },
  {
    title: 'Cadastros',
    icon: <FlagRounded />,
    options: [
      {
        title: 'Cidades',
        route: CIDADES_ROUTE,
      },
      {
        title: 'Instaladores',
        route: INSTALADOR_ROUTE,
      },
      {
        title: 'Materiais de venda',
        route: MATERIAIS_VENDA_ROUTE,
      },
      {
        title: 'Custos modelo de anúncio',
        route: MODELO_ANUNCIOS_ROUTE,
      },
      {
        title: 'Motoristas',
        route: MOTORISTAS_ROUTE,
      },
      {
        title: 'Oficinas',
        route: OFICINAS_ROUTE,
      },
    ],
  },
  {
    title: 'Movimentações financeiras',
    route: EXTRATO_REPASSE_ROUTE,
    icon: <AccountBalanceWalletRounded />,
  },
  {
    title: 'Relatórios',
    route: RELATORIOS_ROUTE,
    icon: <FeedRounded />,
  },
];

const financeiro = [
  {
    title: 'Home',
    route: DASHBOARD_ROUTE,
    icon: <HomeRounded />,
  },
  {
    title: 'Painéis',
    icon: <NearMeRounded />,
    options: [
      {
        title: 'Anunciantes',
        route: ANUNCIANTES_ROUTE,
      },
      {
        title: 'Licenças',
        route: LICENCAS_ROUTE,
      },
      {
        title: 'Representantes',
        route: COMERCIAL_ROUTE,
      },
    ],
  },
  {
    title: 'Vendas',
    icon: <CampaignRounded sx={styles.campanhaIcon} />,
    options: [
      {
        title: 'Campanhas',
        route: CAMPANHAS_ROUTE,
      },
      {
        title: 'Funil de vendas',
        route: PROPOSTAS_ROUTE,
      },
      {
        title: 'Tabela de preços',
        route: TABELA_PRECO_ROUTE,
      },
    ],
  },
  {
    title: 'Custos modelo de anúncio',
    route: MODELO_ANUNCIOS_ROUTE,
    icon: <Inventory2Rounded />,
  },
  {
    title: 'Motoristas',
    route: MOTORISTAS_ROUTE,
    icon: <DirectionsCarFilledRounded />,
  },
  {
    title: 'Relatórios',
    route: RELATORIOS_ROUTE,
    icon: <FeedRounded />,
  },
  {
    title: 'Movimentações financeiras',
    route: EXTRATO_REPASSE_ROUTE,
    icon: <AccountBalanceWalletRounded />,
  },
];

const gerenteComercial = [
  {
    title: 'Home',
    route: DASHBOARD_ROUTE,
    icon: <HomeRounded />,
  },
  {
    title: 'Painéis',
    icon: <NearMeRounded />,
    options: [
      {
        title: 'Anunciantes',
        route: ANUNCIANTES_ROUTE,
      },
      {
        title: 'Licenças',
        route: LICENCAS_ROUTE,
      },
      {
        title: 'Representantes',
        route: COMERCIAL_ROUTE,
      },
    ],
  },
  {
    title: 'Vendas',
    icon: <CampaignRounded sx={styles.campanhaIcon} />,
    options: [
      {
        title: 'Campanhas',
        route: CAMPANHAS_ROUTE,
      },
      {
        title: 'Funil de vendas',
        route: PROPOSTAS_ROUTE,
      },
      {
        title: 'Materiais de venda',
        route: MATERIAIS_VENDA_ROUTE,
      },
      {
        title: 'Custos modelo de anúncio',
        route: MODELO_ANUNCIOS_ROUTE,
      },
      {
        title: 'Motoristas',
        route: MOTORISTAS_ROUTE,
      },
      {
        title: 'Tabela de preços',
        route: TABELA_PRECO_ROUTE,
      },
    ],
  },
  {
    title: 'Relatórios',
    route: RELATORIOS_ROUTE,
    icon: <FeedRounded />,
  },
];

const gerenteOperacional = [
  {
    title: 'Home',
    route: DASHBOARD_ROUTE,
    icon: <HomeRounded />,
  },
  {
    title: 'Vendas',
    icon: <CampaignRounded sx={styles.campanhaIcon} />,
    options: [
      {
        title: 'Campanhas',
        route: CAMPANHAS_ROUTE,
      },
      {
        title: 'Funil de vendas',
        route: PROPOSTAS_ROUTE,
      },
    ],
  },
  {
    title: 'Relatórios',
    route: RELATORIOS_ROUTE,
    icon: <FeedRounded />,
  },
  {
    title: 'Cadastros',
    icon: <FlagRounded />,
    options: [
      {
        title: 'Cidades',
        route: CIDADES_ROUTE,
      },
      {
        title: 'Instaladores',
        route: INSTALADOR_ROUTE,
      },
      {
        title: 'Materiais de venda',
        route: MATERIAIS_VENDA_ROUTE,
      },
      {
        title: 'Marcas de veículos',
        route: MARCA_VEICULO_ROUTE,
      },
      {
        title: 'Custos modelo de anúncio',
        route: MODELO_ANUNCIOS_ROUTE,
      },
      {
        title: 'Modelos de veículos',
        route: MODELO_VEICULO_ROUTE,
      },
      {
        title: 'Motoristas',
        route: MOTORISTAS_ROUTE,
      },
      {
        title: 'Oficinas',
        route: OFICINAS_ROUTE,
      },
    ],
  },
  {
    title: 'Operações',
    icon: <BuildRounded />,
    options: [
      {
        title: 'Contatos',
        route: FALE_CONOSCO_ROUTE,
      },
      {
        title: 'Imagens de validação',
        route: IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE,
      },
      {
        title: 'Notificar motoristas',
        route: NOTIFICAR_MOTORISTAS_ROUTE,
      },
    ],
  },
  {
    title: 'Movimentações financeiras',
    route: EXTRATO_REPASSE_ROUTE,
    icon: <AccountBalanceWalletRounded />,
  },
];

const SidebarRoutes = {
  getSidebarRoutes: (role) => {
    switch (role) {
      case 'ROLE_ADMINISTRADOR':
        return administrador;
      case 'ROLE_OPERACIONAL':
        return opercaional;
      case 'ROLE_FINANCEIRO':
        return financeiro;
      case 'ROLE_GERENTE_COMERCIAL':
        return gerenteComercial;
      case 'ROLE_GERENTE_OPERACIONAL':
        return gerenteOperacional;
      default:
        return [];
    }
  },
};

export default SidebarRoutes;
