export const LOGIN_ROUTE = '/login';
export const ESQUECI_SENHA_ROUTE = '/esqueceu-sua-senha';
export const DASHBOARD_ROUTE = '/home';
export const CAMPANHAS_ROUTE = '/campanhas';
export const MODELO_ANUNCIOS_ROUTE = '/modelo-anuncios';
export const MOTORISTAS_ROUTE = '/motoristas';
export const ANUNCIANTES_ROUTE = '/anunciantes';
export const COMERCIAL_ROUTE = '/representantes';
export const ADMINISTRADOR_ROUTE = '/administrador';
export const INSTALADOR_ROUTE = '/instalador';
export const FALE_CONOSCO_ROUTE = '/fale-conosco';
export const VEICULOS_PENDENTES_INSTALACAO_ROUTE = '/veiculos-pendentes-instalacao';
export const VEICULOS_PENDENTES_REMOCAO_ROUTE = '/veiculos-pendentes-remocao';
export const EXTRATO_REPASSE_ROUTE = '/extrato-repasse';
export const ANUNCIOS_PARADOS_ROUTE = '/anuncios-parados';
export const IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE = '/imagens-pendentes-de-validacao';
export const CONFIGURACOES_ROUTE = '/configuracoes';
export const MARCA_VEICULO_ROUTE = '/marca-veiculo';
export const MODELO_VEICULO_ROUTE = '/modelo-veiculo';
export const ESTADOS_ROUTE = '/estados';
export const CIDADES_ROUTE = '/cidades';
export const PROPOSTAS_ROUTE = '/propostas';
export const RELATORIOS_ROUTE = '/relatorios';
export const NOTAS_FISCAIS_PENDENTES_ROUTE = '/notas-fiscais-pendentes';
export const NOTIFICAR_MOTORISTAS_ROUTE = '/notificar-motoristas';
export const MONITORAR_CAMPANHAS_ROUTE = '/monitorar-campanhas';
export const OFICINAS_ROUTE = '/oficinas';
export const SEGMENTO_ATUACAO_ROUTE = '/segmento-atuacao';
export const MATERIAIS_VENDA_ROUTE = '/materiais-venda';
export const TABELA_PRECO_ROUTE = '/tabela-preco';
export const LICENCAS_ROUTE = '/licencas';
export const CAMPANHAS_FALTANDO_VEICULOS_ROUTE = '/campanhas-faltando-veiculos';

export const routeLabel = {
  [LOGIN_ROUTE]: 'Login',
  [ESQUECI_SENHA_ROUTE]: 'Esqueceu seua senha',
  [DASHBOARD_ROUTE]: 'Home',
  [CAMPANHAS_ROUTE]: 'Campanhas',
  [MODELO_ANUNCIOS_ROUTE]: 'Custos modelos de anúncio',
  [MOTORISTAS_ROUTE]: 'Motoristas',
  [ANUNCIANTES_ROUTE]: 'Anunciantes',
  [COMERCIAL_ROUTE]: 'Representantes',
  [ADMINISTRADOR_ROUTE]: 'Usuários',
  [INSTALADOR_ROUTE]: 'Instaladores',
  [FALE_CONOSCO_ROUTE]: 'Contato',
  [VEICULOS_PENDENTES_INSTALACAO_ROUTE]: 'Veículos aguardando instalação',
  [VEICULOS_PENDENTES_REMOCAO_ROUTE]: 'Veículos aguardando remoção',
  [EXTRATO_REPASSE_ROUTE]: 'Financeiro',
  [ANUNCIOS_PARADOS_ROUTE]: 'Véiculos parados',
  [IMAGENS_PENDENTES_DE_VALIDACAO_ROUTE]: 'Imagens de validação',
  [CONFIGURACOES_ROUTE]: 'Configurações',
  [MARCA_VEICULO_ROUTE]: 'Marcas de veículos',
  [MODELO_VEICULO_ROUTE]: 'Modelos de veículos',
  [ESTADOS_ROUTE]: 'Estados',
  [CIDADES_ROUTE]: 'Cidades',
  [PROPOSTAS_ROUTE]: 'Propostas',
  [RELATORIOS_ROUTE]: 'Relatórios',
  [NOTAS_FISCAIS_PENDENTES_ROUTE]: 'Notas fiscais',
  [NOTIFICAR_MOTORISTAS_ROUTE]: 'Notificações',
  [MONITORAR_CAMPANHAS_ROUTE]: 'Monitoramento de campanhas',
  [OFICINAS_ROUTE]: 'Oficinas',
  [SEGMENTO_ATUACAO_ROUTE]: 'Segmentos de atuação',
  [MATERIAIS_VENDA_ROUTE]: 'Materiais de venda',
  [TABELA_PRECO_ROUTE]: 'Tabela de preços',
  [LICENCAS_ROUTE]: 'Licenças',
  [CAMPANHAS_FALTANDO_VEICULOS_ROUTE]: 'Campanhas aguardando veículos',
}