import {Box, Grid} from '@mui/material';
import React from 'react';
import {
  Navigate,
  Route,
  HashRouter as Router,
  Routes
} from 'react-router-dom';
import BannerBgImg from '../assets/images/banner-bg-man@3x.png';
import KmMidiaLogoImg from '../assets/images/km-midia@3x.png';
import {ESQUECI_SENHA_ROUTE, LOGIN_ROUTE} from '../constants/routes';
import LoginView from '../views/auth/login';
import RecuperarSenhaView from '../views/auth/recuperar-senha';

export const RouterAuthComponent = () => (
  <Grid sx={styles.fullWrapper}>
    <Grid sx={styles.bannerContainer}>
      <Box component="img" src={KmMidiaLogoImg} alt="kmmidia" sx={styles.kmMidiaLogo} />
    </Grid>
    <Grid sx={styles.container}>
      <Router style={{height: '100%'}}>
        <Routes>
          <Route path={LOGIN_ROUTE} element={<LoginView />} />
          <Route path={ESQUECI_SENHA_ROUTE} element={<RecuperarSenhaView />} />
          <Route path="*" element={<Navigate to={LOGIN_ROUTE} />} />
        </Routes>
      </Router>
    </Grid>
  </Grid>
);

const styles = {
  fullWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: '#fff',

    '@media (min-width: 1024px)': {
      flexDirection: 'row',
    }
  },
  bannerContainer: {
    width: '50%',
    height: '100%',
    background: `url(${BannerBgImg}) top center no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '@media (max-width: 1024px)': {
      width: '100%',
      height: '150px',
      background: `url(${BannerBgImg}) center -325px no-repeat`,
    }
  },
  container: {
    width: '100%',

    '@media (min-width: 1024px)': {
      width: '50%',
      height: '100%'
    }
  },
  kmMidiaLogo: {
    height: '60px',
    margin: 2,

    '@media (min-width: 1024px)': {
      height: 'auto',
      marginTop: '6%'
    }
  }
}