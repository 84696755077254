import React from 'react';
import styled from '@emotion/styled';
import MaskedInput from 'react-text-mask';
import {getMaskByType} from '../../utils/masks';

const getDefaultStyles = (type) => styled[type]`
  border: none !important;
  color: #657496;
  min-height: ${type === 'textarea' ? '80px' : '10px'};
  padding-top: ${type === 'textarea' ? '16px' : undefined};
  padding-bottom: ${type === 'textarea' ? '16px' : undefined};
  padding-right: ${(props) => (props.withRightElement ? '40px' : '9px')};
  padding-left: ${(props) => (props.withLeftElement ? '40px' : '9px')};
  width: 100%;
  outline: none;
  border-radius: 4px;
  font-size: 14px;
  ::placeholder {
    color: #afb7c9;
  }
  &:hover {
    border-color: #8995b1;
  }
  ${(props) => {
    if (props.disabled) {
      return `
        background-color: rgba(207, 212, 223, 0.2);
      `;
    }

    return `
      background-color: ${(props) => {
        return props.bgColor || 'transparent';
      }};
    `;
  }}
  ${(props) => {
    const campoPreenchido =
      typeof props.value === 'string' && props.value.trim().length > 0;

    if (props.error) {
      return `
          border: 1px solid #ff5252;
          &:hover {
            border-color: #ff5252;
          }
          &:focus,
          &:active {
            border-color: #ff5252;
          }
        `;
    }

    if (campoPreenchido && !props.disabled) {
      return `
        border: 1px solid #657496;
        &:focus,
        &:active {
          border: 1px solid #0083e6;
        }
      `;
    }

    return `
      border: 1px solid #afb7c9;
      &:focus,
      &:active {
        border-color: #1a9cff;
      }
    `;
  }};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const RightElement = styled.div`
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const LeftElement = styled.div`
  position: absolute;
  top: 0;
  left: 12px;
  bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Input = getDefaultStyles('input');
const Textarea = getDefaultStyles('textarea');

const InputBase = ({
  inputType,
  mask,
  wrapperStyle,
  renderRight = () => null,
  renderLeft = () => null,
  ...props
}) => {
  const leftElement = renderLeft();
  const rightElement = renderRight();

  const inputTypes = {
    input: () => {
      if (!mask) {
        return (
          <Input
            {...props}
            withRightElement={Boolean(rightElement)}
            withLeftElement={Boolean(leftElement)}
          />
        );
      }
      return (
        <MaskedInput
          mask={getMaskByType(mask, props.value)}
          placeholderChar={'\u2000'}
          {...props}
          render={(ref, props) => (
            <Input
              ref={(input) => ref(input)}
              {...props}
              withRightElement={Boolean(rightElement)}
              withLeftElement={Boolean(leftElement)}
            />
          )}
        />
      );
    },
    textarea: () => (
      <Textarea
        {...props}
        withRightElement={Boolean(rightElement)}
        withLeftElement={Boolean(leftElement)}
      />
    ),
  };

  return (
    <Wrapper style={wrapperStyle}>
      {leftElement && <LeftElement>{leftElement}</LeftElement>}
      {inputTypes[inputType || 'input']()}
      {rightElement && <RightElement>{rightElement}</RightElement>}
    </Wrapper>
  );
};

InputBase.defaultProps = {
  inputType: 'input',
  disabled: false,
  type: 'text',
  name: '',
  renderLeft: () => null,
  renderRight: () => null,
  onChange: () => {},
  onFocus: () => {},
  step: 1,
};

export default InputBase;
