export const meses = [
  {
    label: 'Janeiro',
    value: 'JANEIRO',
  },
  {
    label: 'Fevereiro',
    value: 'FEVEREIRO'
  },
  {
    label: 'Março',
    value: 'MARÇO',
  },
  {
    label: 'Abril',
    value: 'ABRIL',
  },
  {
    label: 'Maio',
    value: 'MAIO',
  },

  {
    label: 'Junho',
    value: 'JUNHO',
  },
  {
    label: 'Julho',
    value: 'JULHO',
  },
  {
    label: 'Agosto',
    value: 'AGOSTO',
  },
  {
    label: 'Setembro',
    value: 'SETEMBRO',
  },
  {
    label: 'Outubro',
    value: 'OUTUBRO',
  },
  {
    label: 'Novembro',
    value: 'NOVEMBRO',
  },
  {
    label: 'Dezembro',
    value: 'DEZEMBRO',
  },
];
