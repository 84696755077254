import React, {useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Grid,
  Typography,
  Slider,
  Box,
  Modal,
  Slide,
  useTheme,
  Tooltip,
} from '@mui/material';
import {ArrowForward, Close, Info, Save} from '@mui/icons-material';
import {FIND_AVALIACAO_MOTORISTA_BY_ANUNCIO} from '../../../graphql/queries';
import Number from '../../../utils/number';
import {TextAreaV2} from '../../../components/textarea/mui-textarea';
import {ButtonComponent} from '../../../components/mui-button';
import {toastNotification} from '../../../components/toastify';
import {
  CRIAR_AVALIACAO_MOTORISTA,
  UPDATE_AVALIACAO_MOTORISTA,
} from '../../../graphql/mutation';
import SwitchButton from '../../../components/input/switch-button';
import {getSidebarWidth} from '../../../theme/mui-theme';

const marks = [
  {value: 0.0, label: '0'},
  {value: 0.1, label: '1'},
  {value: 0.2, label: '2'},
  {value: 0.3, label: '3'},
  {value: 0.4, label: '4'},
  {value: 0.5, label: '5'},
  {value: 0.6, label: '6'},
  {value: 0.7, label: '7'},
  {value: 0.8, label: '8'},
  {value: 0.9, label: '9'},
  {value: 1.0, label: '10'},
];

const AvaliacaoModal = ({
  anuncio = {},
  motorista = {},
  openModal,
  onClose,
  refetch,
  ...props
}) => {
  const theme = useTheme();

  const [avaliacao, setAvaliacao] = useState({});
  const [salvando, setSalvando] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const [loadAvaliacao, avaliacaoQuery] = useLazyQuery(
    FIND_AVALIACAO_MOTORISTA_BY_ANUNCIO,
  );

  const [criarAvaliacao] = useMutation(CRIAR_AVALIACAO_MOTORISTA);
  const [updateAvaliacao] = useMutation(UPDATE_AVALIACAO_MOTORISTA);

  const getNotaKmRodado = () => {
    const {kmTotal, kmRodado} = anuncio;

    switch (true) {
      case kmRodado <= kmTotal - 400:
        return 0.0;
      case kmRodado <= kmTotal - 300:
        return 1.0;
      case kmRodado <= kmTotal - 200:
        return 2.0;
      case kmRodado <= kmTotal - 100:
        return 3.0;
      case kmRodado < kmTotal:
        return 4.0;
      case kmRodado >= kmTotal + 2000:
        return 6.0;
      case kmRodado >= kmTotal + 1000:
        return 5.5;
      case kmRodado >= kmTotal:
        return 5.0;
      default:
        return 0.0;
    }
  };

  const getNotaPessoal = () => {
    const {
      notaPreCampanha = 0,
      notaAdesivacao = 0,
      notaCampanha = 0,
      notaFinalizacao = 0,
    } = avaliacao;

    return notaPreCampanha + notaAdesivacao + notaCampanha + notaFinalizacao;
  };

  const getNotaFinal = () => {
    const {notaKmRodado = 0} = avaliacao;

    return notaKmRodado + getNotaPessoal();
  };

  useEffect(() => {
    if (openModal) {
      setScrollTop(
        document.body.scrollTop || document.documentElement.scrollTop,
      );
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      document.body.scrollTop = scrollTop;
      document.documentElement.scrollTop = scrollTop;
      setScrollTop(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    if (!anuncio?.id) return;

    loadAvaliacao({
      variables: {
        anuncio: {
          id: anuncio.id,
        },
      },
    });
  }, [anuncio, loadAvaliacao]);

  useEffect(() => {
    const nota = getNotaKmRodado();
    if (!avaliacaoQuery.data?.avaliacao) {
      setAvaliacao({notaKmRodado: nota, nota});
    } else setAvaliacao({...avaliacaoQuery.data.avaliacao, notaKmRodado: nota});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaliacaoQuery]);

  useEffect(() => {
    if (
      avaliacao?.notaKmRodado === null ||
      avaliacao?.notaKmRodado === undefined
    )
      return;

    const nota = getNotaFinal();
    const notaPessoal = getNotaPessoal();
    setAvaliacao((prev) => ({...prev, nota, notaPessoal}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    avaliacao.notaKmRodado,
    avaliacao.notaPreCampanha,
    avaliacao.notaAdesivacao,
    avaliacao.notaCampanha,
    avaliacao.notaFinalizacao,
  ]);

  const handleSaveAvaliacao = (variables) => {
    if (avaliacao.id) return updateAvaliacao(variables);
    else return criarAvaliacao(variables);
  };

  const handleClickSaveAvaliacao = () => {
    setSalvando(true);

    const variables = {
      avaliacaoMotorista: {
        id: avaliacao.id,
        nota: avaliacao.nota || 0,
        notaPessoal: avaliacao.notaPessoal || 0,
        notaAdesivacao: avaliacao.notaAdesivacao || 0,
        notaCampanha: avaliacao.notaCampanha || 0,
        notaFinalizacao: avaliacao.notaFinalizacao || 0,
        notaKmRodado: avaliacao.notaKmRodado || 0,
        notaPreCampanha: avaliacao.notaPreCampanha || 0,
        observacaoAdesivacao: avaliacao.observacaoAdesivacao,
        observacaoCampanha: avaliacao.observacaoCampanha,
        observacaoFinalizacao: avaliacao.observacaoFinalizacao,
        observacaoPreCampanha: avaliacao.observacaoPreCampanha,
        pinturaAvariada: avaliacao.pinturaAvariada,
        anuncio: {
          id: anuncio.id,
        },
        motorista: {
          id: motorista.id,
        },
      },
    };

    handleSaveAvaliacao({variables})
      .then(() => {
        toastNotification({message: 'A avaliação foi salva!', type: 'success'});
        refetch && refetch();
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setSalvando(false));
  };

  const handleClickClose = () => {
    setAvaliacao({});
    onClose();
  };

  return (
    <Modal open={openModal} onClose={handleClickClose}>
      <Slide direction="left" in={openModal}>
        <Grid
          container
          spacing={0.5}
          padding="30px 80px 30px 30px"
          sx={{...styles.modalBody, ...theme.styledScroll}}>
          <Grid item xs={12}>
            <Box sx={styles.box}>
              <ArrowForward sx={styles.arrowBack} onClick={handleClickClose} />
              <Typography sx={styles.headerTitle}>
                Avaliação do motorista:
              </Typography>
              <Typography sx={styles.headerTitle} style={{fontWeight: '700'}}>
                {motorista?.nome || ''}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography sx={styles.label}>Campanha</Typography>
            <Typography sx={styles.inputValue}>
              {anuncio.campanha?.titulo || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography sx={styles.label}>Meta de Km</Typography>
            <Typography sx={styles.inputValue}>
              {Number.format(anuncio.kmTotal)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography sx={styles.label}>Km rodado</Typography>
            <Typography sx={styles.inputValue}>
              {Number.format(anuncio.kmRodado)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Tooltip title={(<InfoNotaKm />)}>
              <Typography sx={styles.label}>Nota Km rodado <Info sx={styles.infoIcon} /></Typography>
            </Tooltip>
            <Typography sx={styles.inputValue}>
              {parseFloat(avaliacao.notaKmRodado || 0).toFixed(1)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={1}>
            <Typography sx={styles.label}>Nota final</Typography>
            <Typography sx={styles.inputValue}>
              {parseFloat(avaliacao.nota || 0).toFixed(1)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography sx={styles.label}>Pintura avariada</Typography>
            <Box sx={styles.switchContainer}>
              <Typography sx={styles.inputValue}>Não</Typography>
              <Box style={{margin: '0 8px'}}>
                <SwitchButton
                  checked={!!avaliacao.pinturaAvariada}
                  onChange={() =>
                    setAvaliacao((prev) => ({
                      ...prev,
                      pinturaAvariada: !avaliacao.pinturaAvariada,
                    }))
                  }
                />
              </Box>
              <Typography sx={styles.inputValue}>Sim</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <SlidersGroup
              label={'Pré campanha'}
              value={avaliacao.notaPreCampanha}
              observacao={avaliacao.observacaoPreCampanha}
              setValue={(value) =>
                setAvaliacao((prev) => ({...prev, notaPreCampanha: value}))
              }
              setObservacao={(value) =>
                setAvaliacao((prev) => ({
                  ...prev,
                  observacaoPreCampanha: value,
                }))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <SlidersGroup
              label={'Adesivação'}
              value={avaliacao.notaAdesivacao}
              observacao={avaliacao.observacaoAdesivacao}
              setValue={(value) =>
                setAvaliacao((prev) => ({...prev, notaAdesivacao: value}))
              }
              setObservacao={(value) =>
                setAvaliacao((prev) => ({...prev, observacaoAdesivacao: value}))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <SlidersGroup
              label={'Em campanha'}
              value={avaliacao.notaCampanha}
              observacao={avaliacao.observacaoCampanha}
              setValue={(value) =>
                setAvaliacao((prev) => ({...prev, notaCampanha: value}))
              }
              setObservacao={(value) =>
                setAvaliacao((prev) => ({...prev, observacaoCampanha: value}))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <SlidersGroup
              label={'Finalização'}
              value={avaliacao.notaFinalizacao}
              observacao={avaliacao.observacaoFinalizacao}
              setValue={(value) =>
                setAvaliacao((prev) => ({...prev, notaFinalizacao: value}))
              }
              setObservacao={(value) =>
                setAvaliacao((prev) => ({
                  ...prev,
                  observacaoFinalizacao: value,
                }))
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={styles.box}
            padding="0px 30px"
            justifyContent="flex-end">
            <Box>
              <ButtonComponent
                id="button-cancel"
                type="button"
                value="Cancelar"
                icon={<Close sx={styles.icon} />}
                onClick={handleClickClose}
                sx={{width: '256px', marginRight: '15px'}}
              />
              <ButtonComponent
                id="button"
                type="button"
                value="Salvar"
                sx={{width: '256px'}}
                icon={<Save sx={styles.icon} />}
                loading={salvando}
                onClick={handleClickSaveAvaliacao}
                disabled={salvando}
              />
            </Box>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
};

const SlidersGroup = ({label, value, setValue, observacao, setObservacao}) => {
  return (
    <Box sx={styleSlider.slidersContainer}>
      <Typography sx={styleSlider.label}>{label}</Typography>
      <Box sx={styleSlider.sliderWrapper}>
        <Slider
          defaultValue={0}
          value={value || 0}
          step={0.1}
          min={0}
          max={1}
          marks={marks}
          onChange={(event, value) => setValue(value)}
        />
      </Box>
      <TextAreaV2
        placeholder="Digite uma observação"
        value={observacao}
        onChange={({target}) => setObservacao(target.value)}
        style={{minWidth: '100%'}}
      />
    </Box>
  );
};

const InfoNotaKm = () => {
  return (
    <Box>
      <Typography>{`Km rodado <= meta - 400km`}: 0.0</Typography>
      <Typography>{`Km rodado <= meta - 300km`}: 1.0</Typography>
      <Typography>{`Km rodado <= meta - 200km`}: 2.0</Typography>
      <Typography>{`Km rodado <= meta - 100km`}: 3.0</Typography>
      <Typography>{`Km rodado <= meta`}: 4.0</Typography>
      <Typography>{`Km rodado >= meta`}: 5.0</Typography>
      <Typography>{`Km rodado >= meta + 1000km`}: 5.5</Typography>
      <Typography>{`Km rodado >= meta + 2000km`}: 6.0</Typography>
    </Box>
  );
};

const styleSlider = {
  slidersContainer: {
    margin: '10px 40px 10px 0px',
  },
  label: {
    fontSize: 14,
    textAlign: 'left',
    color: '#00106B',
    fontWeight: 'bold',
    margin: '5px 0 0',
  },
  sliderWrapper: {
    padding: '0 8px',
  },
  root: {
    margin: '10px 0 30px 8px',
    width: 'calc(100% - 16px)',
    backgroundColor: '#bfbfbf',
    height: 10,
    marginTop: -4,
  },
};

const styles = {
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalBody: {
    position: 'absolute',
    backgroundColor: '#fff',
    zIndex: (theme) => theme.zIndex.modal,
    width: () => `calc(100vw - ${getSidebarWidth()} - 8px) !important`,
    borderTopLeftRadius: '6px',
    right: '0px',
    padding: '20px',
    height: (theme) => `calc(100vh - 8px - ${theme.toolbarHeight})`,
    marginTop: (theme) => `calc(8px + ${theme.toolbarHeight})`,
  },
  headerText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowBack: {
    color: '#657496',
    cursor: 'pointer',
  },
  headerTitle: {
    fontSize: '18px',
    textAlign: 'left',
    color: '#657496',
    marginTop: '4px',
    marginLeft: '10px',
  },

  label: {
    fontSize: 14,
    textAlign: 'left',
    color: '#00106B',
    fontWeight: 'bold',
    margin: '5px 0 0',
  },
  inputValue: {
    fontSize: 16,
    textAlign: 'left',
    color: '#657496',
    margin: '0 0 5px',
  },
  slidersContainer: {
    padding: '0 30px',
    margin: '10px 0',
  },

  switchContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  icon: {
    fontSize: '18px',
    marginLeft: '10px',
  },
  infoIcon: {
    fontSize: '14px',
    marginBottom: '-2px'
  }
};

export default AvaliacaoModal;
