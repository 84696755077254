import React from 'react';
import FormularioMarcaVeiculo from './formulario';

const CadastrarMarcaVeiculo = () => {
  return (
    <FormularioMarcaVeiculo />
  );
};

export default CadastrarMarcaVeiculo;
