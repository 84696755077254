import React, {useState, useEffect, useRef} from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableContainer,
  Box,
} from '@mui/material';
import {useDispatch} from 'react-redux';
import {Download} from '@mui/icons-material';
import ReactExport from 'react-export-excel';
import {useQuery} from '@apollo/client';
import {Field, Formik} from 'formik';
import ReactToPrint from 'react-to-print';
import {ButtonComponent, RoundTooltipButton} from '../../../components/mui-button';
import moment from 'moment';
import {PrintOutlined} from '@mui/icons-material';
import InputDate from '../../../components/mui-input-date';
import {FIND_ALL_CAMPANHAS_VENDIDAS} from '../../../graphql/queries';
import ImpressaoRelatorioCampanhasVendidas from '../../../components/impressao/impressao-relatorio-campanhas-vendidas';
import {SelectComercial} from '../../../containers/selects/mui-comercial';
import {SelectAnunciantes} from '../../../containers/selects/mui-anunciantes';
import {SelectEstados} from '../../../containers/selects/mui-estados';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import TableCellOrdered from '../../../components/table-cell-ordered';
import Number from '../../../utils/number';
import {useNavigate} from 'react-router-dom';
import {SelectSegmentos} from '../../../containers/selects/mui-segmento';
import Data from '../../../utils/data';
import {persistFiltrosCampanha} from '../../../redux/Campanha/slice';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const pageStyle = `
  @page {
    size: landscape;
    margin: 5mm 10mm 5mm 10mm;
  }

  @media all {
    .page-footer {
      display: block !important;
    }
  }
`;

const columns = [
  {id: 'nomeFantasia', label: 'Anunciante'},
  {id: 'titulo', label: 'Campanha'},
  {
    id: 'competencia',
    label: 'Competência',
    campo: 'dataInicio',
    format: ({dataInicio}) => Data.dataFormat(dataInicio, 'MM/YYYY'),
  },
  {id: 'cidades', label: 'Cidades'},
  {id: 'veiculos', label: 'Veículos'},
  {
    id: 'valorVeiculo',
    label: 'V. veículo',
    format: ({valorVeiculo}) => Number.currencyFormat(valorVeiculo),
  },
  {
    id: 'precoBruto',
    label: 'T. bruto',
    campo: 'dataInicio',
    format: ({precoBruto}) => Number.currencyFormat(precoBruto),
  },
  {
    id: 'valorAgencia',
    label: 'Agência',
    format: ({valorAgencia}) => Number.currencyFormat(valorAgencia),
  },
  {
    id: 'valorTerceiros',
    label: 'Terceiros',
    format: ({valorTerceiros}) => Number.currencyFormat(valorTerceiros),
  },
  {
    id: 'precoLiquido',
    label: 'T. líquido',
    format: ({precoLiquido}) => Number.currencyFormat(precoLiquido),
  },
  {
    id: 'valorVeiculoLiquido',
    label: 'V. líquido / veículo',
    format: ({valorVeiculoLiquido}) =>
      Number.currencyFormat(valorVeiculoLiquido),
  },
];

const ConsultaCampanhasVendidas = () => {
  const navigate = useNavigate();

  const componentRef = useRef();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [campanhas, setCampanhas] = useState(undefined);
  const [orderBy, setOrderBy] = useState({
    campo: 'nomeFantasia',
    asc: true,
  });
  const [filtros, setFiltros] = useState({
    mesInicio: moment().startOf('month').toDate(),
    mesFim: moment().endOf('month').toDate(),
  });
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [vendas, setVendas] = useState([]);
  const [totais, setTotais] = useState(undefined);

  const campanhasQuery = useQuery(FIND_ALL_CAMPANHAS_VENDIDAS, {
    variables: {
      searchDTO: {
        dataInicial: searchDTO.mesInicio
          ? moment(searchDTO.mesInicio).startOf('month').format('YYYY-MM-DD')
          : undefined,
        dataFinal: searchDTO.mesFim
          ? moment(searchDTO.mesFim).endOf('month').format('YYYY-MM-DD')
          : undefined,
        comercialId: searchDTO.comercial?.id || undefined,
        anuncianteId: searchDTO.anunciante?.id || undefined,
        estadoId: searchDTO.estado?.id || undefined,
        cidadeId: searchDTO.cidade?.id || undefined,
        segmentoAtuacao: searchDTO.segmentoAtuacao?.id
          ? {id: searchDTO.segmentoAtuacao?.id}
          : undefined,
      },
      pageable: {
        pageNumber: 0,
        pageSize: -1,
        orderBy: [
          {
            campo: orderBy.campo,
            ordem: orderBy.asc ? 'ASC' : 'DESC',
          },
          {
            campo: 'dataInicio',
            ordem: 'ASC',
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (campanhasQuery.loading || campanhasQuery.error) return;

    setCampanhas(campanhasQuery?.data?.campanhas?.content);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhasQuery]);

  useEffect(() => {
    if (!campanhas || campanhas.length <= 0) return setTotais(undefined);

    const totalCampanhas = campanhas
      .map(({cidades}) => cidades)
      .reduce((acc = 0, value) => acc + parseFloat(value), 0);
    const totalVeiculos = campanhas
      .map(({veiculos}) => veiculos)
      .reduce((acc = 0, value) => acc + parseFloat(value), 0);
    const valorTotalBruto = campanhas
      .map(({precoBruto}) => precoBruto)
      .reduce((acc = 0, value) => acc + parseFloat(value), 0);
    const valorTotalAgencia = campanhas
      .map(({valorAgencia}) => valorAgencia)
      .reduce((acc = 0, value) => acc + parseFloat(value), 0);
    const valorTotalComercial = campanhas
      .map(({valorTerceiros}) => valorTerceiros)
      .reduce((acc = 0, value) => acc + parseFloat(value), 0);
    const valorTotalLiquido = campanhas
      .map(({precoLiquido}) => precoLiquido)
      .reduce((acc = 0, value) => acc + parseFloat(value), 0);

    setTotais({
      totalCampanhas: Number.format(totalCampanhas),
      totalVeiculos: Number.format(totalVeiculos),
      valorTotalBruto,
      valorTotalAgencia,
      valorTotalComercial,
      valorTotalLiquido,
    });
  }, [campanhas]);

  const refetch = () => {
    setSearchDTO(filtros);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };
  const handleCkickRow = (campanha) => {
    dispatch(
      persistFiltrosCampanha({
        filtros: {
          campanhaSituacao: {label: 'Todas'},
          anunciante: {
            value: campanha?.anuncianteId,
            label: campanha?.nomeFantasia,
          },
          search: campanha?.titulo,
        },
        page: 0,
        orderBy: {campo: 'dataInicioVeiculacao', asc: false},
      }),
    );
    navigate(`/campanhas`);
  };
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  const downloadVendas = () => {
    if (!campanhas) return;
    setVendas(
      campanhas.map((item) => ({
        anunciante: item.nomeFantasia,
        campanha: item.titulo,
        cidades: item.cidades,
        veiculos: item.veiculos,
        valorVeiculo: Number.currencyFormat(item.valorVeiculo),
        totalBruto: Number.currencyFormat(item.precoBruto),
        agencia: Number.currencyFormat(item.valorAgencia),
        terceiros: Number.currencyFormat(item.valorTerceiros),
        totalLiquido: Number.currencyFormat(item.precoLiquido),
        valorVeiculoLiquido: Number.currencyFormat(item.valorVeiculoLiquido),
      })),
    );
  };

  return (
    <Grid id="page-container" container>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Campanhas vendidas ${filtros.mesInicio
          ? moment(filtros.mesInicio).format('DD/MM/YYYY')
          : ''
          }`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() => (
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintOutlined />}
            loading={loading}
            loadingColor="grey"
          />
        )}
        content={() => componentRef.current}
      />
      <RoundTooltipButton
        size="small"
        id="icon-toolbar"
        title={'Exportar para Excel'}
        onClick={downloadVendas}
        icon={<Download />}
        sx={{right: '150px !important'}}
        loadingColor="grey"
      />
      {vendas.length > 0 && (
        <ExcelFile hideElement={true} filename="Vendas">
          <ExcelSheet data={vendas} name="Vendas">
            <ExcelColumn label="Anunciante" value="anunciante" />
            <ExcelColumn label="Campanha" value="campanha" />
            <ExcelColumn label="Cidades" value="cidades" />
            <ExcelColumn label="Veículos" value="veiculos" />
            <ExcelColumn label="V. veículo" value="valorVeiculo" />
            <ExcelColumn label="T. bruto" value="totalBruto" />
            <ExcelColumn label="Agência" value="agencia" />
            <ExcelColumn label="Terceiros" value="terceiros" />
            <ExcelColumn label="T. líquido" value="totalLiquido" />
            <ExcelColumn label="V. veículo líquido" value="valorVeiculoLiquido" />
          </ExcelSheet>
        </ExcelFile>
      )}
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <InputDate
                  label="Período venda"
                  selected={filtros.mesInicio}
                  dateFormat="MMMM/yyyy"
                  showMonthYearPicker
                  selectsRange
                  startDate={filtros.mesInicio}
                  endDate={filtros.mesFim}
                  onChange={(e) => {
                    const [start, end] = e;
                    setFiltros((prev) => ({
                      ...prev,
                      mesInicio: start,
                      mesFim: end,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectComercial}
                  name="comercial"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, comercial: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={({...props}) => (
                    <SelectAnunciantes
                      searchDTO={{comercialId: filtros.comercial?.id || null}}
                      {...props}
                    />
                  )}
                  label="Anunciante"
                  name="anunciante"
                  onChange={(e) => {
                    setFiltros((prev) => ({...prev, anunciante: e}));
                  }}
                  isClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={SelectSegmentos}
                  name="segmentoAtuacao"
                  label="Segmento de atuação"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, segmentoAtuacao: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={SelectEstados}
                  name="estado"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, estado: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={({...props}) => (
                    <SelectCidades
                      searchDTO={{estadoId: filtros.estado?.id || null}}
                      {...props}
                    />
                  )}
                  name="cidade"
                  onChange={(e) =>
                    setFiltros((prev) => ({...prev, cidade: e}))
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  value="Filtrar"
                  loading={campanhasQuery.loading}
                  onClick={refetch}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Box overflow="auto">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCellOrdered
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campanhas?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={{cursor: 'pointer'}}
                        onClick={() => handleCkickRow(row)}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={'total'}
                    style={{cursor: 'pointer'}}>
                    <TableCell id="footer">Total</TableCell>
                    <TableCell id="footer">
                      {`${campanhas?.length || 0} registros`}
                    </TableCell>
                    <TableCell />
                    <TableCell id="footer">
                      {totais?.totalCampanhas || 0}
                    </TableCell>
                    <TableCell id="footer">
                      {totais?.totalVeiculos || 0}
                    </TableCell>
                    <TableCell />
                    <TableCell id="footer">
                      {Number.currencyFormat(totais?.valorTotalBruto)}
                    </TableCell>
                    <TableCell id="footer">
                      {Number.currencyFormat(totais?.valorTotalAgencia)}
                    </TableCell>
                    <TableCell id="footer">
                      {Number.currencyFormat(totais?.valorTotalComercial)}
                    </TableCell>
                    <TableCell id="footer">
                      {Number.currencyFormat(totais?.valorTotalLiquido)}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableFooter>
              </Table>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
      <Box overflow="hidden" height="0px">
        <Box ref={componentRef} background="#fff">
          <ImpressaoRelatorioCampanhasVendidas
            campanhas={campanhas}
            periodo={{inicio: searchDTO?.mesInicio, fim: searchDTO?.mesFim}}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default ConsultaCampanhasVendidas;
