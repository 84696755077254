import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useLazyQuery, useQuery} from '@apollo/client';
import {Grid, Paper, Tab, Tabs} from '@mui/material';
import {
  AccountBalance,
  Campaign,
  DriveEta,
  LocationOn,
  Person,
} from '@mui/icons-material';
import {
  FIND_ALTERACOES_BY_ID_MOTORISTA,
  FIND_BY_ID_MOTORISTA,
} from '../../graphql/queries';
import {LoadingPage} from '../../components/loading-page';
import ImagemModal from '../../containers/modais/imagem-modal';
import TabPanel from '../../components/tab-painel';
import DadosPessoais from './abas/dados-pessoais';
import DadosEndereco from './abas/dados-endereco';
import DadosBancarios from './abas/dados-bancarios';
import DadosVeiculo from './abas/dados-veiculo';
import DadosCampanhas from './abas/dados-campanhas';

const FormularioMotorista = () => {
  const {role} = useSelector((store) => store.Auth);

  const {motoristaId} = useParams();

  const [tabIndex, setTabIndex] = useState(0);
  const [motorista, setMotorista] = useState({});
  const [alteracoes, setAlteracoes] = useState(undefined);
  const [imagemModal, setImagemModal] = useState({
    visible: false,
    id: null,
    titulo: '',
  });

  const motoristaQuery = useQuery(FIND_BY_ID_MOTORISTA, {
    variables: {
      id: motoristaId,
    },
  });

  const [loadAlteracoes, alteracoesQuery] = useLazyQuery(
    FIND_ALTERACOES_BY_ID_MOTORISTA,
  );

  useEffect(() => {
    if (motoristaQuery.loading || !!motoristaQuery.error) return;

    setMotorista(motoristaQuery.data?.findByIdMotorista || {});
  }, [motoristaQuery]);

  useEffect(() => {
    if (motorista.situacao !== 'ATUALIZADO') return setAlteracoes(undefined);

    loadAlteracoes({
      variables: {
        id: motoristaId,
      },
    });
  }, [motorista, loadAlteracoes, motoristaId]);

  useEffect(() => {
    if (alteracoesQuery.loading || !!alteracoesQuery.error) return;

    setAlteracoes(alteracoesQuery.data?.alteracoes);
  }, [alteracoesQuery]);

  const refetch = () => {
    motoristaQuery.refetch();
  };

  function handleClickImage(fotoId, titulo = '') {
    setImagemModal({
      visible: true,
      id: fotoId,
      titulo,
    });
  }

  const a11yProps = (index) => {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  };

  if (motoristaQuery.loading || motoristaQuery.error) {
    return (
      <LoadingPage loading={motoristaQuery.loading} error={motoristaQuery.error} />
    );
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Tabs
          value={tabIndex}
          onChange={(event, index) => setTabIndex(index)}
          variant="standard"
          aria-label="nav tabs example"
          className="tabs">
          <Tab
            className="tab-button"
            icon={<Person />}
            label={'Dados pessoais'}
            iconPosition="start"
            {...a11yProps(0)}
          />
          <Tab
            className="tab-button"
            icon={<LocationOn />}
            style={{zIndex: 4}}
            label={'Endereço'}
            iconPosition="start"
            {...a11yProps(1)}
          />
          <Tab
            className="tab-button"
            icon={<AccountBalance />}
            style={{zIndex: 3}}
            label={'Dados bancários'}
            iconPosition="start"
            {...a11yProps(2)}
          />
          <Tab
            className="tab-button"
            icon={<DriveEta />}
            style={{zIndex: 2}}
            label={'Dados do veículo'}
            iconPosition="start"
            {...a11yProps(3)}
          />
          <Tab
            className="tab-button"
            icon={<Campaign />}
            style={{
              zIndex: 1,
              visibility: role === 'ROLE_GERENTE_COMERCIAL' ? 'hidden' : 'visible'
            }}
            label={'Campanhas'}
            iconPosition="start"
            {...a11yProps(4)}
          />
        </Tabs>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={0}>
          <DadosPessoais
            motorista={motorista}
            handleClickImage={handleClickImage}
            refetch={refetch}
            alteracoes={alteracoes}
            role={role}
          />
        </TabPanel>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={1}>
          <DadosEndereco
            motorista={motorista}
            handleClickImage={handleClickImage}
            alteracoes={alteracoes}
            role={role}
          />
        </TabPanel>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={2}>
          <DadosBancarios motorista={motorista} alteracoes={alteracoes} />
        </TabPanel>
        <TabPanel sx={styles.tabPainel} value={tabIndex} index={3}>
          <DadosVeiculo
            motorista={motorista}
            handleClickImage={handleClickImage}
            alteracoes={alteracoes}
            role={role}
          />
        </TabPanel>
        <TabPanel
          sx={styles.tabPainel}
          boxPadding={0}
          value={tabIndex}
          index={4}
        >
          <DadosCampanhas
            motorista={motorista}
            refetchMotorista={refetch}
            alteracoes={alteracoes}
            role={role}
          />
        </TabPanel>
      </Paper>
      {imagemModal.visible && (
        <ImagemModal
          visible={imagemModal.visible}
          id={imagemModal.id}
          titulo={imagemModal.titulo}
          onClose={() => setImagemModal({visible: false, id: null, titulo: ''})}
        />
      )}
    </Grid>
  );
};
const styles = {
  tabPainel: {
    minHeight: 'calc(100vh - 190px)',
    overflowY: 'auto',

    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'inherit',
    },
  },
};

export default FormularioMotorista;
