import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  anunciosParados: [],
  hasMore: false,
  totalAnunciosParados: 0,
  refetchAnunciosParados: false
};

const acaoAtualizarAnunciosParados = state => {
  state.refetchAnunciosParados = true;
};

const acaoPersistAnunciosParados = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.anunciosParados = content;
  state.hasMore = !last;
  state.totalAnunciosParados = totalElements || 0;
};

const resumeSlice = createSlice({
  name: 'anunciosParados',
  initialState: initialState,
  reducers: {
    persistAnunciosParados: acaoPersistAnunciosParados,
    atualizarAnunciosParados: acaoAtualizarAnunciosParados
  }
});

export const { actions, reducer } = resumeSlice;
export const { persistAnunciosParados, atualizarAnunciosParados } = actions;
