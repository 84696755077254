import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaCarteiraClientes from './carteira-clientes/consulta';
import ConsultaRelatorios from './consulta';
import ConsultaMotoristasIndicacoes from './motoristas-indicacoes/consulta';
import ConsultaMotoristasIndicados from './motoristas-indicados/consulta';
import ConsultaMotoristasPagos from './motoristas-pagos/consulta';
import ConsultaQuilometragemCidade from './quilometragem-cidade/consulta';
import ConsultaCampanhasVendidas from './vendas/consulta';
import ConsultaSubstituidos from './motoristas-substituidos/consulta';
import ConsultaVeiculosOrcadosCidades from './veiculos-orcados-cidade/consulta';
import ConsultaNotasFiscaisPendentes from './notas-fiscais-pendentes/consulta';
import ConsultaImpactosTotais from './impactos-totais/consulta';
import ConsultaResultadoCampanhas from './resultado-campanhas/consulta';

const Relatorios = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaRelatorios />} />
      <Route path={`/motoristas-indicacoes`} element={<ConsultaMotoristasIndicacoes />} />
      <Route path={`/motoristas-indicados`} element={<ConsultaMotoristasIndicados />} />
      <Route path={`/motoristas-pagos`} element={<ConsultaMotoristasPagos />} />
      <Route path={`/campanhas-vendidas`} element={<ConsultaCampanhasVendidas />} />
      <Route path={`/carteira-clientes`} element={<ConsultaCarteiraClientes />} />
      <Route path={`/quilometragem-cidade`} element={<ConsultaQuilometragemCidade />} />
      <Route path={`/motoristas-substituidos`} element={<ConsultaSubstituidos />} />
      <Route path={`/veiculos-orcados-cidade`} element={<ConsultaVeiculosOrcadosCidades />} />
      <Route path={`/notas-fiscais-pendentes`} element={<ConsultaNotasFiscaisPendentes />} />
      <Route path={`/impactos-totais`} element={<ConsultaImpactosTotais />} />
      <Route path={`/campanhas-resultados`} element={<ConsultaResultadoCampanhas />} />
    </Routes>
  );
};

export default Relatorios;
