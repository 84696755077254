import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  instaladorList: [],
  filtrosInstalador: {
    filtros: {
      ativo: {label: 'Ativo', value: true},
    },
    page: 0,
    orderBy: {campo: 'nomeFantasia', asc: true},
  },
  hasMore: false,
  totalInstalador: 0,
  refetchInstalador: false,
};

const acaoAtualizarInstalador = (state) => {
  state.refetchInstalador = true;
};

const acaoPersistInstalador = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.instaladorList = content;
  state.hasMore = !last;
  state.totalInstalador = totalElements || 0;
};

const acaoPersistFiltrosInstalador = (state, action) => {
  state.filtrosInstalador = action.payload;
};

const resumeSlice = createSlice({
  name: 'instalador',
  initialState: initialState,
  reducers: {
    persistInstalador: acaoPersistInstalador,
    atualizarInstalador: acaoAtualizarInstalador,
    persistFiltrosInstalador: acaoPersistFiltrosInstalador,
  },
});

export const {actions, reducer} = resumeSlice;
export const {
  persistInstalador,
  atualizarInstalador,
  persistFiltrosInstalador,
} = actions;
