import React from 'react';
import {FIND_ALL_MARCA_VEICULO as query} from '../../graphql/queries';
import {SelectBase} from './mui-base';

const transformOptions = ({content}) => {
  return Array.isArray(content)
    ? content.map(item => ({
      ...item,
      label: item.nome,
      value: item.id
    }))
    : [];
};

const getVariables = ({pageNumber, search = '', pageSize = 20}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nome', sortDir: 'ASC'},
    searchDTO: {search},
  };
};

export const SelectMarcasVeiculo = (props) => {
  return (
    <SelectBase
      label="Marca"
      responseObjectName="marcas"
      transformOptions={transformOptions}
      query={query}
      getVariables={getVariables}
      {...props}
    />
  );
};
