import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeDrivers: 0,
  finishedDrivers: 0
};

const actionPersistSuccess = (state, action) => {};

const resumeSlice = createSlice({
  name: 'resume',
  initialState: initialState,
  reducers: {
    persistSuccess: actionPersistSuccess
  }
});

export const { actions, reducer } = resumeSlice;
export const { persistSuccess } = actions;

export const getResume = ({ email, password }) => async dispatch => {
  dispatch(persistSuccess(/**payload */));
};
