import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  notasFiscaisPendentes: [],
  filtrosNotasFiscaisPendentes: {
    filtros: {
        dataInicial: JSON.stringify(moment().startOf('month').toDate()),
        situacaoNota: {value: false, label: 'Pendentes'}
      },
    page: 0,
    orderBy: {campo: 'dataEmitirNota', asc: true}
  },
  totalNotasFiscaisPendentes: 0,
  refetchNotasFiscaisPendentes: false
  
  
};

const acaoAtualizarNotasFiscaisPendentes = state => {
  state.refetchNotasFiscaisPendentes = true;
};

const acaoPersistNotasFiscaisPendentes = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.notasFiscaisPendentes = content;
  state.hasMore = !last;
  state.totalNotasFiscaisPendentes = totalElements || 0;
  state.refetchNotasFiscaisPendentes = false;
};
const acaoPersistFiltrosNotasFiscaisPendentes = (state, action) => {
  state.filtrosNotasFiscaisPendentes = action.payload;
};

const resumeSlice = createSlice({
  name: 'notasFiscaisPendentes',
  initialState: initialState,
  reducers: {
    persistNotasFiscaisPendentes: acaoPersistNotasFiscaisPendentes,
    atualizarNotasFiscaisPendentes: acaoAtualizarNotasFiscaisPendentes,
    persistFiltrosNotasFiscaisPendentes: acaoPersistFiltrosNotasFiscaisPendentes
  }
});


export const { actions, reducer } = resumeSlice;
export const { persistNotasFiscaisPendentes, atualizarNotasFiscaisPendentes, persistFiltrosNotasFiscaisPendentes } = actions;
