import {useLazyQuery, useMutation} from '@apollo/client';
import {Check, Close, OpenInNew, Report, Visibility} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {Field, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {confirmAlertMui} from '../../components/confirm-alert/mui-confirm-alert';
import InputV2 from '../../components/input-v2/mui-input-v2';
import {ButtonComponent, RoundTooltipButton} from '../../components/mui-button';
import {TextAreaV2} from '../../components/textarea/mui-textarea';
import {toastNotification} from '../../components/toastify';
import ViewOrcamentoModal from '../../containers/modais/cadastro-orcamento/view-orcamento-modal';
import {SelectAnunciantes} from '../../containers/selects/mui-anunciantes';
import {SelectComercial} from '../../containers/selects/mui-comercial';
import {FINALIZAR_PROPOSTA, REPROVAR_PROPOSTA} from '../../graphql/mutation';
import {FIND_ALL_ORCAMENTOS_PROPOSTA} from '../../graphql/queries';
import usePainelService from '../../services/PainelService';
import Data from '../../utils/data';
import Number from '../../utils/number';

const FormularioProposta = ({proposta, refetch}) => {
  const painelService = usePainelService();
  const navigate = useNavigate();
  const {role} = useSelector((store) => store.Auth);

  const [readOnly] = useState(true);
  const [anunciante, setAnunciante] = useState(proposta.anunciante);
  const [viewOrcamentoOpen, setViewOrcamentoOpen] = useState(false);
  const [orcamentoSelecionado, setOrcamentoSelecionado] = useState(undefined);
  const [formData, setFormData] = useState(proposta);

  const [reprovarProposta] = useMutation(REPROVAR_PROPOSTA);
  const [finalizarProposta] = useMutation(FINALIZAR_PROPOSTA);

  useEffect(() => {
    if (!proposta?.id) return;

    setFormData(proposta);
  }, [proposta]);

  useEffect(() => {
    setViewOrcamentoOpen(!!orcamentoSelecionado);
  }, [orcamentoSelecionado]);

  const handleFinalizarPropostaClick = () => {
    confirmAlertMui({
      title: 'Deseja finalizar a proposta?',
      message:
        'Isso irá fechar a proposta e move-la APROVADO. Não será possível aprovar outros orçamentos dessa proposta.',
      onConfirm: handleFinalizarProposta,
    });
  };

  const handleReprovarPropostaClick = () => {
    confirmAlertMui({
      title: 'Deseja reprovar a proposta?',
      message:
        'Isso irá fechar a proposta e move-la REPROVADO. Não será possível aprovar outros orçamentos dessa proposta.',
      onConfirm: handleReprovarProposta,
    });
  };

  const handleFinalizarProposta = () => {
    finalizarProposta({
      variables: {
        propostaId: proposta?.id,
      },
    })
      .then(() => {
        toastNotification({message: 'Proposta aprovada!', type: 'success'});
        navigate(`/propostas`);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleReprovarProposta = () => {
    reprovarProposta({
      variables: {
        propostaId: proposta?.id,
      },
    })
      .then(() => {
        toastNotification({message: 'Proposta reprovada!', type: 'success'});
        navigate(`/propostas`);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid id="page-container">
      <Paper id="formulario-paper">
        <Formik
          validationSchema={OrcamentoScheme}
          enableReinitialize
          initialValues={{
            ...formData,
            anunciante,
          }}>
          {() => (
            <Box component="form" sx={styles.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Field
                    key="anunciante"
                    name="anunciante"
                    value={formData.anunciante}
                    placeholder="Selecione"
                    component={SelectAnunciantes}
                    onChange={(anunciante) => setAnunciante(anunciante)}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputV2
                    label="Título"
                    value={formData.titulo || ''}
                    onChange={({target}) =>
                      setFormData({...formData, titulo: target.value})
                    }
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    key="comercialResponsavel"
                    name="comercialResponsavel"
                    label="Comercial responsável"
                    value={formData.comercialResponsavel || formData.comercial}
                    placeholder="Selecione"
                    component={SelectComercial}
                    endAdornment={
                      <OpenInNew
                        titleAccess="Acessar painel"
                        onClick={() =>
                          painelService.acessarPainelComercial(
                            formData.comercialResponsavel?.id ||
                              formData.comercial?.id,
                          )
                        }
                        style={{cursor: 'pointer'}}
                      />
                    }
                    disabled={readOnly}
                  />
                </Grid>
                <Orcamentos
                  proposta={proposta}
                  readOnly={readOnly}
                  orcamento={orcamentoSelecionado}
                  setOrcamento={setOrcamentoSelecionado}
                  role={role}
                />
                <Grid item xs={12} style={{marginTop: 20}}>
                  <TextAreaV2
                    sx={styles.textAreaObservacoes}
                    name="observacao"
                    rows={5}
                    label="Observações"
                    value={formData.observacao || ''}
                    onChange={({target}) =>
                      setFormData({...formData, observacao: target.value})
                    }
                    disabled={readOnly}
                  />
                </Grid>
                {proposta.reprovadoMotivo && (
                  <Grid item xs={12}>
                    <TextAreaV2
                      sx={styles.textAreaObservacoes}
                      name="reprovadoMotivo"
                      label="Motivo da proposta ser reprovada"
                      value={proposta.reprovadoMotivo || ''}
                      disabled
                    />
                  </Grid>
                )}
                {proposta.situacao === 'AGUARDANDO_APROVACAO' &&
                  ['ROLE_ADMINISTRADOR', 'ROLE_GERENTE_COMERCIAL'].includes(
                    role,
                  ) && (
                    <Grid
                      container
                      justifyContent="space-between"
                      style={{marginTop: 'auto', padding: '24px 0 24px 16px'}}>
                      <Grid
                        container
                        item
                        sm
                        xs={12}
                        justifyContent={'space-between'}>
                        <ButtonComponent
                          id="button-cancel"
                          type="button"
                          value="Reprovar proposta"
                          icon={<Check />}
                          onClick={handleReprovarPropostaClick}
                          sx={styles.buttons}
                        />
                        <ButtonComponent
                          id="button-green"
                          type="button"
                          value="Finalizar proposta"
                          icon={<Check />}
                          onClick={handleFinalizarPropostaClick}
                          sx={styles.buttons}
                        />
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Box>
          )}
        </Formik>
        <ViewOrcamentoModal
          openModal={viewOrcamentoOpen}
          proposta={formData}
          orcamento={orcamentoSelecionado}
          onClose={() => {
            setViewOrcamentoOpen(false);
            setOrcamentoSelecionado(undefined);
          }}
        />
      </Paper>
    </Grid>
  );
};

const OrcamentoScheme = Yup.object().shape({
  anunciante: Yup.object()
    .shape({value: Yup.string()})
    .nullable()
    .required('Campo obrigatório'),
});

const Orcamentos = ({proposta = {}, orcamento, setOrcamento, role}) => {
  const navigate = useNavigate();

  const [orcamentos, setOrcamentos] = useState([]);

  const [loadOrcamentos, orcamentosQuery] = useLazyQuery(
    FIND_ALL_ORCAMENTOS_PROPOSTA,
  );

  useEffect(() => {
    if (!proposta.id) return;
    loadOrcamentos({
      variables: {
        proposta: {
          id: proposta.id,
        },
      },
    });
  }, [proposta, orcamento, loadOrcamentos]);

  useEffect(() => {
    if (orcamentosQuery.loading || orcamentosQuery.error) return;
    setOrcamentos(orcamentosQuery.data?.orcamentos || []);
  }, [orcamentosQuery]);

  const handleViewClick = (orcamento) => {
    if (
      ['ROLE_ADMINISTRADOR', 'ROLE_GERENTE_COMERCIAL'].includes(role) &&
      proposta.situacao === 'AGUARDANDO_APROVACAO' &&
      !orcamento?.dataAprovadoAdmin &&
      orcamento.situacao === 'APROVADO'
    )
      return navigate(`/propostas/${orcamento.id}/aprovar`);

    setOrcamento(orcamento);
  };

  if (orcamentosQuery.loading) {
    return (
      <Grid item xs={12} sx={styles.cidadesContainer}>
        <Grid item xs={12} sx={styles.criarOrcamentoContainer}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Box sx={styles.cidadesContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={styles.headerText}>Propostas</Typography>
          </Grid>
          <Grid item xs={12}>
            <Table id="table">
              <TableHead>
                <TableRow sx={styles.tableRow}>
                  <TableCell key="cidades" sx={styles.tableCellHeader}>
                    Cidades
                  </TableCell>
                  <TableCell key="veiculos" sx={styles.tableCellHeader}>
                    Veículos
                  </TableCell>
                  <TableCell key="periodo" sx={styles.tableCellHeader}>
                    Períodos
                  </TableCell>
                  <TableCell key="valor" sx={styles.tableCellHeader}>
                    Valor total
                  </TableCell>
                  <TableCell key="cadastro" sx={styles.tableCellHeader}>
                    Cadastro
                  </TableCell>
                  <TableCell
                    key="acoes"
                    sx={styles.tableCellHeader}
                    align="center"
                    style={{width: 100}}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orcamentos.map((orcamento) => (
                  <TableRow
                    key={orcamento.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={
                      orcamento.situacao === 'APROVADO'
                        ? {...styles.tableRow, ...styles.tableRowAprovado}
                        : {...styles.tableRow}
                    }>
                    <TableCell sx={styles.tableCell}>
                      {orcamento?.itens.length}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      {orcamento.itens
                        ?.map((i) => i.quantidadeVeiculos)
                        .reduce((total, item) => total + item, 0)}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      {`${orcamento.quantidadeMeses} de ${orcamento.diasPeriodo} dias`}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      {Number.currencyFormat(orcamento.valorTotalCampanha)}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      {Data.dataFormat(orcamento.dataCadastro, 'DD/MM/YY')}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Grid
                        style={{
                          position: 'relative',
                          display: 'flex',
                          padding: '0 10px',
                        }}>
                        <RoundTooltipButton
                          type="button"
                          title="Visualizar"
                          icon={<Visibility />}
                          onClick={() => handleViewClick(orcamento)}
                          sx={styles.visualizarIcon}
                        />
                        {orcamento.situacao === 'APROVADO' && (
                          <RoundTooltipButton
                            type="button"
                            title="Aprovado"
                            icon={<Check />}
                            sx={styles.statusIcon}
                            style={{color: '#55AB23'}}
                          />
                        )}
                        {orcamento.situacao === 'REPROVADO' && (
                          <RoundTooltipButton
                            type="button"
                            title="Reprovado"
                            icon={<Close />}
                            sx={styles.statusIcon}
                            style={{color: '#DC3131'}}
                          />
                        )}
                        {orcamento.reprovadoMotivo && (
                          <RoundTooltipButton
                            type="button"
                            title="Pedido reprovado"
                            icon={<Report />}
                            sx={styles.statusIcon}
                            style={{color: '#DC3131'}}
                          />
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

const styles = {
  headerText: {
    fontWeight: 700,
    color: (theme) => theme.palette.paterns.navyBlue,
    padding: '6px 8px',
  },
  tableCell: {
    padding: '0 16px !important',
    borderBottom: `1px solid #0004`,
  },
  tableCellHeader: {
    zIndex: '0 !important',
    fontFamily: 'AktivGrotesk-Medium',
    borderBottom: `1px solid #0004`,
  },
  tableRow: {
    cursor: 'default',
    backgroundColor: 'transparent !important',
  },
  criarOrcamentoContainer: {
    display: 'flex',
    borderRadius: '4px',
    padding: '1rem 0 !important',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cidadesContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: '#e4e9f3bf',
    padding: '8px',
    flexDirection: 'column',
    margin: '24px 0',
  },
  textAreaObservacoes: {
    paddingTop: '12px',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '80px',
    marginBottom: 0,
  },
  visualizarIcon: {
    color: '#0083e6 !important',
    background: 'transparent !important',
  },
  statusIcon: {
    position: 'absolute',
    background: 'transparent',
    top: 0,
    left: '45px',
    cursor: 'default',

    '& svg': {
      width: '0.8em',
      height: '0.8em',
    },
  },
  tableRowAprovado: {
    background: '#25e18a26 !important',
  },
  form: {
    minHeight: 'calc(100vh - 56px - 60px)',
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    maxWidth: '190px',
  },
};

export default FormularioProposta;
