import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ConsultaComercial from './consulta';
import EditarComercial from './editar';
import CadastrarComercial from './cadastrar';

const Comercial = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ConsultaComercial />} />
      <Route path={`/:comercialId/editar`} element={<EditarComercial />} />
      <Route path={`/criar`} element={<CadastrarComercial />} />
    </Routes>
  );
};

export default Comercial;
