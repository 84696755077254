const motoristaStyles = {
  fotoContainer: {
    width: '100%',
    height: '135px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(207, 212, 223, 0.2)',
    border: '1px solid #afb7c9',
    borderRadius: '4px',

    '& svg': {
      color: '#8995B1'
    },

    '& p': {
      color: '#8995b1',
      fontSize: 13,
      marginBottom: 0,
    }
  },
  fotoImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer'
  },
  label: {
    color: '#8995b1',
    fontSize: 14,
    fontWeight: 'bold',
  },
  reprovarFotoButton: {
    color: '#FF7C7C',
    borderColor: '#FF7C7C',
    marginBottom: 30,
  },
  textArea: {
    margin: '10px 0 0 0'
  },
  valuesContainer: {
    display: 'flex',
    alignContent: 'flex-start'
  },
  input: {
    marginBottom: 9
  },
  icon: {
    fontSize: '18px',
    marginLeft: '10px'
  },
}

export default motoristaStyles;