import {createTheme} from '@mui/material/styles';
import {LIGHT} from '../constants/theme';
import {LIGHT_COLORS} from './colors/mui-colors';
import {typography} from './mui-typography';
import {buttonsStyles} from './buttons-styles';
import {iconsButtonStyle} from './icons-button-style';
import {space} from './space';
import {zIndex} from './z-index'

export const theme = createTheme({
  // General
  mode: LIGHT,

  // Sizes & Spacing
  sidebarWidth: '80px',
  sidebarWidthOpen: '380px',
  toolbarHeight: '60px',
  boxBorderRadius: '4px',

  // Space
  space,
  // zIndex
  zIndex: {
    ...zIndex
  },
  components: {
    MuiSvgIcon: {
      variants: [
        {
          props: {id: 'icon-error'},
          style: {
            width: '15px !important',
            height: '15px !important',
            marginTop: '5px !important',
            fill: `${LIGHT_COLORS.cancel} !important`
          },
        }
      ]
    },
    //buttons
    MuiButton: {
      variants: [
        {
          props: {toolbar: 'true'},
          style: {
            position: 'absolute',
            top: '10px',
            right: 150,
            height: 36,
            whiteSpace: 'nowrap',
            margin: '0 5px',
            zIndex: zIndex.toolbar,
            padding: '15px',
          },
        },
        {
          props: {id: `button`},
          style: buttonsStyles.primary,
        },
        {
          props: {id: `button-primary-empty`},
          style: buttonsStyles.primaryEmpty,
        },
        {
          props: {id: `button-gray-blue`},
          style: buttonsStyles.blueGray,
        },
        {
          props: {id: `button-green`},
          style: buttonsStyles.green,
        },
        {
          props: {id: 'button-green-cancel'},
          style: buttonsStyles.greenCancel,
        },
        {
          props: {id: `button-cancel`},
          style: buttonsStyles.cancel,
        },
        {
          props: {id: `button-cancel-empty`},
          style: buttonsStyles.cancelEmpty,
        },
        {
          props: {id: `button-yellow`},
          style: buttonsStyles.yellow,
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: {id: 'icon-normal'},
          style: iconsButtonStyle.normal,
        },
        {
          props: {id: 'icon-primary'},
          style: iconsButtonStyle.primary,
        },
        {
          props: {id: 'icon-gray'},
          style: iconsButtonStyle.gray,
        },
        {
          props: {id: 'icon-cancel'},
          style: iconsButtonStyle.cancel,
        },
        {
          props: {id: 'icon-whatsapp'},
          style: iconsButtonStyle.greenSecundary,
        },
        {
          props: {id: 'icon-green'},
          style: iconsButtonStyle.green,
        },
        {
          props: {id: 'icon-green-tertiary'},
          style: iconsButtonStyle.greenTertiary,
        },
        {
          props: {id: 'icon-purple'},
          style: iconsButtonStyle.purple,
        },
        {
          props: {id: 'icon-yellow'},
          style: iconsButtonStyle.yellow,
        },
        {
          props: {id: 'icon-toolbar'},
          style: {
            top: '0px',
            margin: '10px 0 !important',
            position: 'absolute',
            right: 100,
            cursor: 'pointer',
            color: '#8995B0',
            zIndex: zIndex.toolbar,
            'svg': {
              height: '30px',
              width: '30px'
            }
          },
        },
      ],
    },

    //Grid
    MuiGrid: {
      variants: [
        {
          props: {id: 'tolbaar-button'},
          style: iconsButtonStyle.primary,
        },
        {
          props: {id: 'page-container'},
          style: {
            height: '100%',
            flexGrow: 1,
            padding: '8px',
            alignContent: 'flex-start',
            overflow: 'auto'
          },
        },
      ],
    },
    //Tab
    MuiTab: {
      variants: [
        {
          props: {className: 'tab-button'},
          style: {
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            backgroundColor: '#AFB7C9',
            color: '#fff',
            minWidth: '170px',
            marginRight: '4px',
            borderBottom: 'none',
            height: '45px',
            minHeight: '40px',
            zIndex: '5',
            margin: '4px 4px -4px 0px',
          },
        },
      ],
    },

    MuiTabs: {
      variants: [
        {
          props: {className: 'tabs'},
          style: {
            color: '#00106B',
            borderBottom: '5px solid #00106B',

            '& .MuiTab-wrapper': {
              textTransform: 'none',
              display: 'contents',
            },
            '& .MuiTabs-indicator': {
              display: 'none',
            },
            '& .MuiTabs-fixed': {
              marginBottom: '-1px !important'
            },
            '& .Mui-selected': {
              background: '#00106B',
              color: '#FFF !important',
              border: 'none',
              width: 195,
              marginTop: 0,
              marginBottom: 0,
              padding: 0,
            },
            '& .MuiTab-textColorInherit': {
              opacity: 1,
            },
            '& .MuiTabs-flexContainer': {
              overflowX: 'auto',
              overflowY: 'hidden',

              '&::-webkit-scrollbar': {
                height: '0px',
              },
            },
          },
        },
      ],
    },
    MuiTable: {
      variants: [
        {
          props: {id: 'table'},
          style: {
            "& .table-button-container": {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'default',
            }
          },
        },
      ],
    },
    //input
    MuiOutlinedInput: {
      variants: [
        {
          props: {id: 'input-v2'},
          style: {
            height: '40px',
            '&.-error': {
              '&>fieldset': {
                borderColor: `${LIGHT_COLORS.cancel} !important`,
              },
            },
            '&.withValue-error': {
              '&>fieldset': {
                borderColor: `${LIGHT_COLORS.cancel} !important`,
              },
            },
            '&.withValue': {
              '&>fieldset': {
                borderColor: '#007BE1 !important',
              },
            },
            '&.Mui-focused': {
              '&>fieldset': {
                border: '1px solid #657496 !important',
              },
            },
            '&.Mui-disabled': {
              '&>fieldset': {
                border: '1px solid #CDCDCD !important',
              },
            },
            '& .react-datepicker-popper': {
              zIndex: 999,
            },
            '& .react-select__menu': {
              zIndex: 999,
            },
          },
        },
        {
          props: {id: 'input-text-v2'},
          style: {
            '&.withValue': {
              '&>fieldset': {
                borderColor: '#007BE1 !important',
              },
            },
            '&.Mui-focused': {
              '&>fieldset': {
                border: '1px solid #657496 !important',
              },
            },
            '&.Mui-disabled': {
              '&>fieldset': {
                border: '1px solid #CDCDCD !important',
              },
            },
            '& .react-datepicker-popper': {
              zIndex: 999,
            },
            '& .react-select__menu': {
              zIndex: 999,
            },
            '&>textarea': {
              '&::-webkit-scrollbar': {
                width: '7px',
                marginLeft: '5px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'none',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#afb7c980',
                borderRadius: '100px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#afb7c9',
              },
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      variants: [
        {
          props: {id: 'input-label-v2'},
          style: {
            color: '#AFB7C8 ',
            fontFamily: `${typography.fontFamily.patternRegular} !important`,
            '&.MuiInputLabel-shrink': {
              color: '#007BE1 !important',
              fontFamily: `${typography.fontFamily.patternMedium} !important`,
            },
            '&.error': {
              position: 'absolute !important',
              top: '0px !important',
              left: '0px !important',
              color: `${LIGHT_COLORS.cancel} !important`,
            },
            '&.withValue': {
              position: 'absolute !important',
              top: '0px !important',
              left: '0px !important',
            },
            '&.Mui-focused': {
              color: '#657496 !important',
            },
            '&.Mui-disabled': {
              color: '#8995B0 !important',
            },
          },
        },
      ],
    },
    //TextField
    MuiTextField: {
      variants: [
        {
          props: {id: 'text'},
          style: {
            '&.withValue-error': {
              '&>fieldset': {
                borderColor: 'red !important',
              },
            },
            '&.withValue': {
              '&>fieldset': {
                borderColor: '#007BE1 !important',
              },
            },
            '&.Mui-focused': {
              '&>fieldset': {
                border: '1px solid #657496 !important',
              },
            },
            '&.Mui-disabled': {
              '&>fieldset': {
                border: '1px solid #CDCDCD !important',
              },
            },
          },
        },
      ],
    },
    //paper
    MuiPaper: {
      variants: [
        {
          props: {id: 'filtro-paper'},
          style: {
            marginBottom: '8px',
            backgroundColor: '#fff',
            padding: '16px',
            borderRadius: '4px',
            boxShadow: '1px 6px 12px rgb(128 152 213 / 20%)',
            '& .react-datepicker-popper': {
              zIndex: 999,
            },
            '& .react-select__menu': {
              zIndex: 999,
            },
          },
        },
        {
          props: {id: 'formulario-paper'},
          style: {
            padding: 20,
            '& .react-datepicker-popper': {
              zIndex: 999,
            },
            '& .react-select__menu': {
              zIndex: 999,
            },
          },
        },
      ],
    },
    MuiTableFooter: {
      variants: [
        {
          props: {id: 'footer-text'},
          style: {
            '& .MuiTableCell-root': {
              margin: '20px 0 0 0',
              fontSize: 14,
              color: '#000',
              fontWeight: '700'
            }

          }
        }
      ]
    },

    //paper
    MuiTypography: {
      variants: [
        {
          props: {id: 'formulario-subtitle'},
          style: {
            color: ' #00106B',
            fontSize: ' 14px',
            fontWeight: '900',
          },
        },
      ],
    },

    //Table
    MuiTableCell: {
      variants: [
        {
          props: {id: 'footer'},
          style: {
            cursor: 'default',
            fontWeight: 'bold',
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.87)',
            whiteSpace: 'nowrap',
          },
        },
        {
          props: {id: 'acoes'},
          style: {
            cursor: 'default',
            fontWeight: 'bold',
          },
        },
      ],
    },
  },

  // Colors
  palette: {
    yellow: {
      main: LIGHT_COLORS.yellow,
    },
    paterns: {...LIGHT_COLORS},
    primaryPatern: {
      main: LIGHT_COLORS.primary,
    },
    sidebarItemActive: {
      main: LIGHT_COLORS.sidebarItemActive,
    },
    sidebarItem: {
      main: LIGHT_COLORS.sidebarItem,
    },
    sidebarItemActiveBg: {
      main: LIGHT_COLORS.sidebarItemActiveBg,
    },
    sidebarBg: {
      main: LIGHT_COLORS.sidebarBg,
    },
    cancel: {
      main: LIGHT_COLORS.cancel,
    },
    green: {
      main: LIGHT_COLORS.green,
    },
    sidebarBorderRight: {
      main: LIGHT_COLORS.sidebarBorderRight,
    },
    situacaoAgendado: {
      main: LIGHT_COLORS.situacaoAgendado
    },
    situacaoPendente: {
      main: LIGHT_COLORS.situacaoPendente
    },
    situacaoPessoal: {
      main: LIGHT_COLORS.situacaoPessoal
    },
  },
  typography: {
    ...typography,
  },

  styledScroll: {
    '&::-webkit-scrollbar': {
      width: '7px',
      marginLeft: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(190, 214, 246, 0.5)',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 150, 255, 0.5)',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0, 150, 255, 1)',
    },
  },
});

export const getSidebarWidth = () => {
  const isOpen = window.localStorage.getItem("isSidebarOpen");
  if (isOpen === null || isOpen === 'true') {
    return theme.sidebarWidthOpen;
  }

  return theme.sidebarWidth;
};