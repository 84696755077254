import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FIND_BY_ID_INSTALADOR} from '../../graphql/queries';
import FormularioInstalador from './formulario';
import {LoadingPage} from '../../components/loading-page';

const EditarInstalador = ({role}) => {
  const {instaladorId} = useParams();

  const instaladorQuery = useQuery(FIND_BY_ID_INSTALADOR, {
    variables: {
      instalador: {
        id: instaladorId,
      },
    },
  });

  const {data = {}} = instaladorQuery;
  const {findByIdInstalador: instalador} = data;

  const refetch = () => {
    instaladorQuery.refetch();
  };

  if (instaladorQuery.loading || instaladorQuery.error) {
    return (
      <LoadingPage loading={instaladorQuery.loading} error={instaladorQuery.error} />
    );
  }

  return (
    <FormularioInstalador instalador={instalador} refetch={refetch} role={role} />
  );
};

export default EditarInstalador;
