import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  propostas: [],
  filtrosProposta: {
    filtros: {
      periodo: {value: 30, label: '30 dias'},
      dataInicial: moment().subtract(30, 'days').format('YYYY-MM-DD')
    },
    page: 0,
    orderBy: {campo: 'dataCadastro', asc: false}
  },
  hasMore: false,
  totalPropostas: 0,
  refetchPropostas: false,
};

const acaoAtualizarPropostas = state => {
  state.refetchPropostas = true;
};

const acaoPersistPropostas = (state, action) => {
  const {content, last, totalElements} = action.payload;

  state.propostas = content;
  state.hasMore = !last;
  state.totalPropostas = totalElements || 0;
  state.refetchPropostas = false;
};

const acaoPersistFiltrosProposta = (state, action) => {
  state.filtrosProposta = action.payload;
};

const resumeSlice = createSlice({
  name: 'proposta',
  initialState: initialState,
  reducers: {
    persistPropostas: acaoPersistPropostas,
    atualizarPropostas: acaoAtualizarPropostas,
    persistFiltrosProposta: acaoPersistFiltrosProposta
  },
});

export const {actions, reducer} = resumeSlice;
export const {persistPropostas, atualizarPropostas, persistFiltrosProposta} = actions;
