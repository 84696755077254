import React, {useEffect, useState} from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import {useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {persistEmailContato} from '../redux/EmailContato/slice';
import {FIND_ALL_EMAIL_CONTATO} from '../graphql/queries';

const columns = [
  {id: 'titulo', label: 'Título', minWidth: 170},
  {id: 'tipoDuvida', label: 'Tipo', minWidth: 170},
  {id: 'email', label: 'E-mail', minWidth: 170},
  {id: 'telefone', label: 'Telefone', minWidth: 170},
  {id: 'mensagem', label: 'Mensagem', minWidth: 170}
];

const FaleConosco = ({pageSize = 20}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(pageSize);

  const dispatch = useDispatch();
  const {
    emailContatoList,
    totalEmailContato,
    refetchEmailContato
  } = useSelector(store => store.EmailContato);

  const emailContatoQuery = useQuery(FIND_ALL_EMAIL_CONTATO, {
    variables: {
      pageable: {pageNumber: page, pageSize: rowsPerPage},
      searchDTO: {}
    }
  });

  useEffect(() => {
    if (emailContatoQuery.loading || emailContatoQuery.error) return;

    dispatch(persistEmailContato(emailContatoQuery.data.findAllEmailContato));

    if (refetchEmailContato) {
      emailContatoQuery.refetch();
    }
  }, [emailContatoQuery, dispatch, refetchEmailContato]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid id="page-container" container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Box overflow="auto">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{minWidth: column.minWidth}}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {emailContatoList.map(row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={totalEmailContato}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page'
            }}
            nextIconButtonProps={{
              'aria-label': 'next page'
            }}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} de ${count}`
            }
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default FaleConosco;
