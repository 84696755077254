import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  campanhasComVeiculosFaltando: [],
  hasMore: false,
  totalCampanhasComVeiculosFaltando: 0,
  refetchCampanhasComVeiculosFaltando: false
};

const acaoAtualizarCampanhasComVeiculosFaltando = state => {
  state.refetchCampanhasComVeiculosFaltando = true;
};

const acaoPersistCampanhasComVeiculosFaltando = (state, action) => {
  const { content, last, totalElements } = action.payload;

  state.campanhasComVeiculosFaltando = content;
  state.hasMore = !last;
  state.totalCampanhasComVeiculosFaltando = totalElements || 0;
  state.refetchCampanhasComVeiculosFaltando = false;
};

const resumeSlice = createSlice({
  name: 'campanhaComVeiculoFaltando',
  initialState: initialState,
  reducers: {
    persistCampanhasComVeiculosFaltando: acaoPersistCampanhasComVeiculosFaltando,
    atualizarCampanhasComVeiculosFaltando: acaoAtualizarCampanhasComVeiculosFaltando
  }
});

export const { actions, reducer } = resumeSlice;
export const {
  persistCampanhasComVeiculosFaltando,
  atualizarCampanhasComVeiculosFaltando
} = actions;
