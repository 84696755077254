import React from 'react';
import useGoogleMapMarker from './useGoogleMapMarker';

export default function Marker({
  position,
  type,
  maps,
  map,
  events,
  active = false,
  title,
  icon
}) {
  useGoogleMapMarker({
    position,
    type,
    maps,
    map,
    events,
    title,
    active,
    icon
  });

  return <></>;
}
